<template>
    <div>
        <PageHeader :items="breadcrumb">
            <div>{{ $t('offerDetails') }}</div>
            <template #side>
                <div v-if="!product && error">
                    <strong>{{ $t('goBackTo') }}</strong>: <b-link :to="parentRoute">
                        {{ $t(parentRoute.meta.title) }}
                    </b-link>
                </div>
                <b-skeleton v-if="!product && !error" animation="wave" width="100%" class="mb-0 mt-1 width-300" />
            </template>
        </PageHeader>
        <ErrorMessage v-if="!product && error" :value="error" />
        <div v-else class="wrapper d-flex gap-3">
            <div class="d-flex flex-column gap-3 flex-grow-1">
                <Details ref="Details" />
                <Edit ref="Edit" />
            </div>
            <div class="d-flex flex-column gap-3 flex-grow-1">
                <ProductImage ref="ProductImage" />
            </div>
        </div>
    </div>
</template>



<script>
    import PageHeader from '@/components/common/PageHeader.vue';
    import Details from './parts/Details.vue';
    import Edit from './parts/Edit.vue';
    import ProductImage from './parts/ProductImage.vue';

    export default {
        name: 'OffersDetails',
        components: {
            PageHeader,
            Details,
            Edit,
            ProductImage,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.id),
            };
        },
        computed: {
            apiParams() {
                return { urlParams: { offerId: this.id } };
            },
            product() {
                return this.$store.getters['offers/getOffer'];
            },
            error() {
                return this.$store.getters['offers/getError']('offer');
            },
            loading() {
                return this.$store.getters['offers/getLoading']('offer');
            },
            breadcrumb() {
                if (!this.product) return [];
                return [
                    { text: this.$t(this.parentRoute.meta.title), to: this.$route.params.parentUrl || this.parentRoute },
                    { text: this.product?.name || '---', active: true },
                ];
            },
            parentRoute() {
                return this.$route.matched.slice(-2)[0];
            },
        },
        methods: {
            fetchData() {
                this.$store.dispatch('offers/fetchOffer', this.apiParams);
            },
            clearStoreData() {
                this.$store.commit('offers/clearOffer');
            },
        },
        created() {
            this.clearStoreData();
            if (!this.product) this.fetchData();
        },
        destroyed() {
            this.clearStoreData();
            this.$store.commit('campaigns/clearOffers');
        },
        mounted() {
            window.scrollTo(0, 0);
        },
    };
</script>



<style lang="scss" scoped>
    @media (max-width: 1200px) {
        .wrapper {
            flex-wrap: wrap;
        }
    }
</style>
