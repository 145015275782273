import Vue from 'vue';

export default {
    successToast(...args) {
        Vue.prototype.$toastr.success(...args);
    },
    infoToast(...args) {
        Vue.prototype.$toastr.info(...args);
    },
    warningToast(...args) {
        Vue.prototype.$toastr.warning(...args);
    },
    errorToast(...args) {
        Vue.prototype.$toastr.error(...args);
    },
    closeToast(...args) {
        Vue.prototype.$toastr.close(...args);
    },
    removeToast(...args) {
        Vue.prototype.$toastr.remove(...args);
    },
};
