import Vue from 'vue';
import oauthService from '@/services/oauthService';
import Storage from '../Storage';

export const PROVIDERS = Object.freeze({
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
    APPLE: 'apple',
});

export const isProviderSupported = provider => Object.values(PROVIDERS).includes(provider);

const loginWithGoogle = async () => {
    const response = await Vue.prototype.$socialAuth.google.signIn();
    return { token: response.code, provider: PROVIDERS.GOOGLE };
};

const loginWithFacebook = async () => {
    const response = await Vue.prototype.$socialAuth.facebook.signIn();
    return { token: response.authResponse.accessToken, provider: PROVIDERS.FACEBOOK };
};

const loginWithApple = async () => {
    const response = await Vue.prototype.$socialAuth.apple.signIn();
    return { token: response.authorization.id_token, provider: PROVIDERS.APPLE };
};

const authenticate = async provider => {
    if (!isProviderSupported(provider)) throw new Error('Unsupported provider');

    try {
        let response;

        if (provider === PROVIDERS.GOOGLE) response = await loginWithGoogle();
        if (provider === PROVIDERS.FACEBOOK) response = await loginWithFacebook();
        if (provider === PROVIDERS.APPLE) response = await loginWithApple();

        // const oauthResponse = await oauthService.postLogin({ requestParams: response }).catch(error => {
        //     const apiError = error.error.response.data;
        //     // user doesn't have an account yet
        //     if (apiError.code === 401 && apiError.message === 'Bad credentials.') {
        //         return oauthService.postConnect({ requestParams: { ...response, role: 'ROLE_WIDGETER' } });
        //     }
        //     return error;
        // });

        const oauthResponse = await oauthService.postConnect({ requestParams: { ...response, role: 'ROLE_WIDGETER' }, headers: { withAuth: false } });

        window.localStorage.setItem(Storage.ACCESS_TOKEN, oauthResponse.token);
        window.localStorage.setItem(Storage.REFRESH_TOKEN, oauthResponse.refresh_token);
        window.localStorage.removeItem(Storage.IS_ADMIN);

        return oauthResponse;
    } catch (error) {
        console.log('error', error);
        const googleClosePopup = provider === PROVIDERS.GOOGLE && error.type === 'popup_closed';
        const facebookClosePopup = provider === PROVIDERS.FACEBOOK && !error.authResponse && error.status === 'unknown';
        if (googleClosePopup || facebookClosePopup) {
            return null;
        }
        throw error;
    }
};

export default {
    authenticate,
    PROVIDERS,
    isProviderSupported,
};
