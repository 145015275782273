<template>
    <div>
        <PageHeader>{{ $t('productSearch') }}</PageHeader>
        <Filters ref="Filters" />
        <Results ref="Results" />
    </div>
</template>



<script>
    import pickBy from 'lodash/pickBy';
    import Filters from './parts/Filters.vue';
    import Results from './parts/Results.vue';

    export default {
        name: 'ProductSearch',
        components: {
            Filters,
            Results,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                filters: {
                    ean: null,
                    name: null,
                    info: null,
                    code: null,
                    publisher: null,
                    search: null,
                },
                tableParams: {
                    perPage: 10,
                    currentPage: 1,
                    sortBy: 'id',
                    sortDirection: 'desc',
                },
            };
        },
        computed: {
            currentFilters() {
                return pickBy(this.filters, value => value !== null && value !== undefined && value !== '');
            },
        },
        methods: {
            search() {
                return this.$refs.Results.fetchData();
            },
            resetFilters() {
                Object.keys(this.filters).forEach(key => {
                    this.filters[key] = null;
                });
            },
        },
    };
</script>
