<template>
    <div>
        <PageHeader>
            {{ $t('widgeterReportsPages.title') }}
            <template #side>
                <div class="d-flex">
                    <MultiselectSpaces
                        v-model="pages"
                        class="width-500"
                        :disabled="isSpaceReportLoading"
                        @loading="value => isSpacesLoading = value"
                    />

                    <div class="mx-2"></div>

                    <TimeRangeDropdown v-model="timeRange" :disabled="isSpaceReportLoading || isSpacesLoading" />
                </div>
            </template>
        </PageHeader>

        <div class="row">
            <div class="col mb-4 overflow-hidden width-min-700 flex-grow-10">
                <MultiChartCard
                    :data="lineChartData"
                    :height="400"
                    :loading="isSpaceReportLoading"
                    :options="lineChartOptions"
                    @currentChart="setCurrentChart"
                />
            </div>
            <div class="col mb-4 overflow-hidden width-400 width-min-400 flex-grow-1">
                <PieChart :chart-data="pieChartData[currentChart].series" :loading="isSpaceReportLoading" />
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 mb-4">
                <SiteStatistics :space-ids="spaceIds || []" :time-range="timeRange || {}" />
            </div>
        </div>
    </div>
</template>



<script>
    import ReportsPages from '@/views/common/reports/pages/index.vue';

    export default {
        name: 'AdvertiserReportsPages',
        mixins: [ReportsPages],
        computed: {
            campaignId() {
                return this.$store.getters['campaigns/getCurrentCampaign']?.id;
            },
            requestParams() {
                return {
                    ...ReportsPages.computed.requestParams.bind(this)(),
                    showFirst: 5,
                };
            },
        },
        watch: {
            timeRange() {
                this.clearChartData();
                this.fetchSpaceReport();
            },
            campaignId() {
                this.pages = [];
            },
        },
    };
</script>
