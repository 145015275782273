<template>
    <div>

        <b-overlay :show="isLoading && !!items.length" rounded="sm" class="h-100">

            <div class="overflow-auto">
                <b-table
                    :items="items"
                    :fields="fields"
                    :busy="isLoading && !items.length"
                    small
                    show-empty
                    :empty-text="$t('noResult')"
                    class="mb-0 border-bottom"
                >
                    <template #table-busy>
                        <div class="d-flex justify-content-center my-2">
                            <b-spinner />
                        </div>
                    </template>

                    <template #cell(createdAt)="data">
                        {{ dateUtil.formatDate(data.item.createdAt) }}
                    </template>

                    <template #cell(action)="data">
                        <ButtonIcon
                            icon="x"
                            variant="link"
                            size="sm"
                            class="p-0 text-danger"
                            :disabled="isItemDeleted(data.item.id)"
                            :loading="isItemDeleted(data.item.id)"
                            @click="deleteItem(data.item.id)"
                        />
                    </template>

                </b-table>
            </div>

        </b-overlay>

        <div class="w-100 my-5"></div>

        <div class="d-flex flex-wrap gap-4">
            <div class="text-nowrap d-flex align-items-center">{{ $t('assignCategory') }}</div>
            <Multiselect
                v-model="category"
                :placeholder="$t('selectField.placeholder')"
                :options="categoriesOptions"
                track-by="id"
                label="name"
                select-label=""
                :loading="isCategoriesLoading"
                :disabled="isCategoriesLoading || isLoading || isAdded"
                class="w-auto flex-grow-1"
            />
            <ButtonIcon :disabled="!category || isLoading" :loading="isAdded" hide-label-while-loading class="width-min-100" @click="addItem()">{{ $t('add') }}</ButtonIcon>
        </div>

    </div>
</template>



<script>
    import { i18n } from '@/i18n/i18n';
    import dateUtil from '@/utils/date';
    import categoryService from '@/services/categoryService';
    import Multiselect from '@/components/common/Multiselect.vue';
    import { createItemKey } from '@/store/modules/moduleFactory';

    export default {
        name: 'Categories',
        components: {
            Multiselect,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                dateUtil,
                fields: [
                    {
                        key: 'name',
                        label: i18n.t('category'),
                    },
                    {
                        key: 'createdAt',
                        label: i18n.t('dateAdded'),
                    },
                    {
                        key: 'action',
                        label: i18n.t('remove'),
                    },
                ],
                category: null,
                isAdded: false,
                isCategoriesLoading: false,
                categories: [],
            };
        },
        computed: {
            campaignId() {
                return this.parentComponent?.item?.id;
            },
            items() {
                return this.$store.getters['campaigns/getCategories'];
            },
            error() {
                return this.$store.getters['campaigns/getError']('categories');
            },
            isBusy() {
                return this.$store.getters['campaigns/getBusy']('categories');
            },
            isLoading() {
                return this.$store.getters['campaigns/getLoading']('categories');
            },
            isItemBusy() {
                return id => this.$store.getters['campaigns/getBusyItem']('categories', createItemKey({ id, campaignId: this.campaignId }));
            },
            isItemUpdated() {
                return id => this.$store.getters['campaigns/getUpdatedItem']('categories', createItemKey({ id, campaignId: this.campaignId }));
            },
            isItemDeleted() {
                return id => this.$store.getters['campaigns/getDeletedItem']('categories', createItemKey({ id, campaignId: this.campaignId }));
            },
            categoriesOptions() {
                return [...(this.categories || [])].map(category => {
                    const $isDisabled = this.items.some(item => item.id === category.id); //eslint-disable-line
                    return { ...category, $isDisabled };
                });
            },
        },
        watch: {
            items() {
                this.parentComponent.item.categories = this.items;
            },
        },
        methods: {
            clearData() {
                this.$store.commit('campaigns/clearCategories');
            },
            fetchData() {
                this.$store.dispatch('campaigns/fetchCategories', { urlParams: { campaignId: this.campaignId } }).catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            deleteItem(id) {
                this.$bvModal
                    .msgBoxConfirm(this.$t('confirmYouWantDelete'), {
                        title: this.$t('confirm'),
                        okTitle: this.$t('yes'),
                        cancelTitle: this.$t('cancel'),
                        okVariant: 'danger',
                    })
                    .then(value => {
                        if (value) {
                            this.$store
                                .dispatch('campaigns/deleteCategories', { urlParams: { id, campaignId: this.campaignId } })
                                .then(this.fetchData)
                                .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
                        }
                    });
            },
            addItem() {
                this.isAdded = true;
                this.$store
                    .dispatch('campaigns/putCategories', { urlParams: { id: this.category.id, campaignId: this.campaignId } })
                    .then(() => {
                        this.category = null;
                        this.fetchData();
                    })
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')))
                    .finally(() => (this.isAdded = false));
            },
            async fetchCategories() {
                this.isCategoriesLoading = true;
                this.categories = await categoryService.getCategories().catch(() => {});
                this.isCategoriesLoading = false;
            },
        },
        created() {
            this.clearData();
            this.fetchData();
            this.fetchCategories();
        },
    };
</script>
