<template>
    <div>
        <page-header>
            {{ $t('api.title') }}
        </page-header>
        <space-provider>
            <SpaceSwitchCard />
            <card v-if="currentSpaceId" :header="$t('api.apiKeyFor', { space: currentSpaceName })" :loading="fetchApiTokenLoading">
                <b-alert :show="!apiToken" variant="light">
                    {{ $t('api.noData') }}
                </b-alert>

                <error-message v-model="error" />

                <text-field
                    v-if="apiToken"
                    v-model="apiToken.apiKey"
                    :label="$t('api.apiKey')"
                    disabled
                />
                <text-field
                    v-if="apiToken"
                    v-model="apiToken.apiSecret"
                    :label="$t('api.apiSecret')"
                    disabled
                />

                <button-default
                    class="mr-1"
                    :disabled="revokeApiTokenLoading"
                    :loading="generateApiTokenLoading"
                    @click="generate"
                >
                    {{ $t(apiToken ? 'api.regenerate' : 'api.generate') }}
                </button-default>
                <button-default
                    v-if="apiToken"
                    class="mr-1"
                    variant="danger"
                    :disabled="generateApiTokenLoading"
                    :loading="revokeApiTokenLoading"
                    @click="revoke"
                >
                    {{ $t('api.revoke') }}
                </button-default>
            </card>
        </space-provider>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import PageHeader from '@/components/common/PageHeader.vue';
    import Card from '@/components/common/Card.vue';
    import SpaceProvider from '@/components/common/SpaceProvider.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import SpaceSwitchCard from '@/components/common/SpaceSwitchCard.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import TextField from '@/components/common/TextField.vue';

    export default {
        name: 'Api',
        components: {
            TextField,
            ButtonDefault,
            SpaceSwitchCard,
            ErrorMessage,
            SpaceProvider,
            PageHeader,
            Card,
        },
        data() {
            return {
                error: null,
            };
        },
        computed: {
            currentSpaceName() {
                return this.currentSpace ? this.currentSpace.name : '';
            },
            ...mapState({
                currentSpaceId: state => state.space.currentSpaceId,
                fetchApiTokenLoading: state => state.space.loading.fetchApiToken,
                generateApiTokenLoading: state => state.space.loading.generateApiToken,
                revokeApiTokenLoading: state => state.space.loading.revokeApiToken,
                apiToken: state => state.space.apiToken,
            }),
            ...mapGetters({
                currentSpace: 'space/currentSpace',
            }),
        },
        watch: {
            currentSpaceId: {
                handler(spaceId) {
                    if (spaceId) {
                        this.getApiToken({ spaceId });
                    }
                },
                immediate: true,
            },
        },
        methods: {
            async getApiToken() {
                try {
                    this.error = null;
                    await this.fetchApiToken();
                } catch (e) {
                    this.error = e.message;
                }
            },
            async generate() {
                try {
                    this.error = null;
                    await this.generateApiToken();
                } catch (e) {
                    this.error = e.message;
                }
            },
            async revoke() {
                try {
                    this.error = null;
                    await this.revokeApiToken();
                } catch (e) {
                    this.error = e.message;
                }
            },
            ...mapActions({
                fetchApiToken: 'space/fetchApiToken',
                generateApiToken: 'space/generateApiToken',
                revokeApiToken: 'space/revokeApiToken',
            }),
        },
    };
</script>
