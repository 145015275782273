<template>
    <div>
        <PageHeader>{{ $t('widgetSettings.title2') }}</PageHeader>
        <SpaceSwitchCard @spaceId="setSpaceId" />
        <Card v-if="spaceId" :header="$t('widgetSettings.buyboxesFor')" :loading="pluginsLoading">
            <DataTable :items="items" :fields="fields" :pagination="false" sort-direction="asc">
                <template #cell(actions)="{ item }">
                    <router-link :to="{ name: 'ProductLandingPageSettingsEdit', params: { spaceId: spaceId, buyboxId: item.id } }">
                        <b-button variant="primary">
                            {{ $t('widgetSettings.editSettings') }}
                        </b-button>
                    </router-link>
                </template>
            </DataTable>
        </Card>
    </div>
</template>



<script>
    import SpaceSwitchCard from '@/components/common/SpaceSwitchCard.vue';
    import DataTable from '@/components/common/DataTable.vue';

    export default {
        name: 'Settings',
        components: {
            SpaceSwitchCard,
            DataTable,
        },
        data() {
            return {
                spaceId: null,
            };
        },
        computed: {
            pluginsLoading() {
                return this.$store.state.plugin.loading.fetchPlugins;
            },
            plugins() {
                return this.$store.state.plugin.plugins;
            },
            items() {
                return Object.values(this.plugins);
            },
            fields() {
                return [
                    {
                        label: this.$t('widgetSettings.category'),
                        key: 'category.name',
                    },
                    {
                        label: this.$t('widgetSettings.buyboxId'),
                        key: 'id',
                        class: 'text-right',
                    },
                    {
                        label: this.$t('widgetSettings.actions'),
                        key: 'actions',
                        class: 'text-right',
                    },
                ];
            },
        },
        watch: {
            spaceId() {
                if (this.spaceId) {
                    this.fetchPlugins();
                }
            },
        },
        methods: {
            setSpaceId(spaceId) {
                this.spaceId = spaceId;
            },
            fetchPlugins() {
                return this.$store.dispatch('plugin/fetchPlugins', { spaceId: this.spaceId });
            },
        },
    };
</script>
