export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';
export const AUTHENTICATE_REQUEST = 'AUTHENTICATE_REQUEST';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILURE = 'AUTHENTICATE_FAILURE';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const AUTHENTICATE_AS_REQUEST = 'AUTHENTICATE_AS_REQUEST';
export const AUTHENTICATE_AS_SUCCESS = 'AUTHENTICATE_AS_SUCCESS';
export const AUTHENTICATE_AS_FAILURE = 'AUTHENTICATE_AS_FAILURE';
export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const FETCH_USER_LIGHT_REQUEST = 'FETCH_USER_LIGHT_REQUEST';
export const FETCH_USER_LIGHT_SUCCESS = 'FETCH_USER_LIGHT_SUCCESS';
export const FETCH_USER_LIGHT_FAILURE = 'FETCH_USER_LIGHT_FAILURE';
export const FETCH_USER_FULL_REQUEST = 'FETCH_USER_FULL_REQUEST';
export const FETCH_USER_FULL_SUCCESS = 'FETCH_USER_FULL_SUCCESS';
export const FETCH_USER_FULL_FAILURE = 'FETCH_USER_FULL_FAILURE';
export const FETCH_USER_PROFILE_REQUEST = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
export const UPDATE_NOTIFICATIONS_SETTINGS_FAILURE = 'UPDATE_NOTIFICATIONS_SETTINGS_FAILURE';
export const UPDATE_NOTIFICATIONS_SETTINGS_REQUEST = 'UPDATE_NOTIFICATIONS_SETTINGS_REQUEST';
export const UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS = 'UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS';
export const UPDATE_PERSONAL_SETTINGS_REQUEST = 'UPDATE_PERSONAL_SETTINGS_REQUEST';
export const UPDATE_PERSONAL_SETTINGS_SUCCESS = 'UPDATE_PERSONAL_SETTINGS_SUCCESS';
export const UPDATE_PERSONAL_SETTINGS_FAILURE = 'UPDATE_PERSONAL_SETTINGS_FAILURE';
export const UPDATE_ADDRESS_SETTINGS_REQUEST = 'UPDATE_COMPANY_SETTINGS_REQUEST';
export const UPDATE_ADDRESS_SETTINGS_SUCCESS = 'UPDATE_COMPANY_SETTINGS_SUCCESS';
export const UPDATE_ADDRESS_SETTINGS_FAILURE = 'UPDATE_COMPANY_SETTINGS_FAILURE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_COMPLETE_REQUEST = 'RESET_PASSWORD_COMPLETE_REQUEST';
export const RESET_PASSWORD_COMPLETE_SUCCESS = 'RESET_PASSWORD_COMPLETE_SUCCESS';
export const RESET_PASSWORD_COMPLETE_FAILURE = 'RESET_PASSWORD_COMPLETE_FAILURE';
export const ACCEPT_TERMS_REQUEST = 'ACCEPT_TERMS_REQUEST';
export const ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_FAILURE = 'ACCEPT_TERMS_FAILURE';
export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE';
export const CLEAN_SPACES = 'CLEAN_SPACES';
export const FETCH_SPACES_REQUEST = 'FETCH_SPACES_REQUEST';
export const FETCH_SPACES_SUCCESS = 'FETCH_SPACES_SUCCESS';
export const FETCH_SPACES_FAILURE = 'FETCH_SPACES_FAILURE';
export const SET_CURRENT_SPACE = 'SET_CURRENT_SPACE';
export const FETCH_SPACE_ALLOWED_CAMPAIGNS_REQUEST = 'FETCH_SPACE_ALLOWED_CAMPAIGNS_REQUEST';
export const FETCH_SPACE_ALLOWED_CAMPAIGNS_SUCCESS = 'FETCH_SPACE_ALLOWED_CAMPAIGNS_SUCCESS';
export const FETCH_SPACE_ALLOWED_CAMPAIGNS_FAILURE = 'FETCH_SPACE_ALLOWED_CAMPAIGNS_FAILURE';
export const FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_REQUEST = 'FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_REQUEST';
export const FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_SUCCESS = 'FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_SUCCESS';
export const FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_FAILURE = 'FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_FAILURE';
export const ACTIVATE_PERMISSION_REQUEST = 'ACTIVATE_PERMISSION_REQUEST';
export const ACTIVATE_PERMISSION_SUCCESS = 'ACTIVATE_PERMISSION_SUCCESS';
export const ACTIVATE_PERMISSION_FAILURE = 'ACTIVATE_PERMISSION_FAILURE';
export const DEACTIVATE_PERMISSION_REQUEST = 'DEACTIVATE_PERMISSION_REQUEST';
export const DEACTIVATE_PERMISSION_SUCCESS = 'DEACTIVATE_PERMISSION_SUCCESS';
export const DEACTIVATE_PERMISSION_FAILURE = 'DEACTIVATE_PERMISSION_FAILURE';
export const FETCH_PERMISSIONS_REQUEST = 'FETCH_PERMISSIONS_REQUEST';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAILURE = 'FETCH_PERMISSIONS_FAILURE';
export const CREATE_SPACE_REQUEST = 'CREATE_SPACE_REQUEST';
export const CREATE_SPACE_SUCCESS = 'CREATE_SPACE_SUCCESS';
export const CREATE_SPACE_FAILURE = 'CREATE_SPACE_FAILURE';
export const UPDATE_SPACE_REQUEST = 'UPDATE_SPACE_REQUEST';
export const UPDATE_SPACE_SUCCESS = 'UPDATE_SPACE_SUCCESS';
export const UPDATE_SPACE_FAILURE = 'UPDATE_SPACE_FAILURE';
export const FETCH_SPACE_TYPES_REQUEST = 'FETCH_SPACE_TYPES_REQUEST';
export const FETCH_SPACE_TYPES_SUCCESS = 'FETCH_SPACE_TYPES_SUCCESS';
export const FETCH_SPACE_TYPES_FAILURE = 'FETCH_SPACE_TYPES_FAILURE';
export const FETCH_SPACE_STATUSES_REQUEST = 'FETCH_SPACE_STATUSES_REQUEST';
export const FETCH_SPACE_STATUSES_SUCCESS = 'FETCH_SPACE_STATUSES_SUCCESS';
export const FETCH_SPACE_STATUSES_FAILURE = 'FETCH_SPACE_STATUSES_FAILURE';
export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const FETCH_API_TOKEN_REQUEST = 'FETCH_API_TOKEN_REQUEST';
export const FETCH_API_TOKEN_SUCCESS = 'FETCH_API_TOKEN_SUCCESS';
export const FETCH_API_TOKEN_FAILURE = 'FETCH_API_TOKEN_FAILURE';
export const GENERATE_API_TOKEN_REQUEST = 'GENERATE_API_TOKEN_REQUEST';
export const GENERATE_API_TOKEN_SUCCESS = 'GENERATE_API_TOKEN_SUCCESS';
export const GENERATE_API_TOKEN_FAILURE = 'GENERATE_API_TOKEN_FAILURE';
export const REVOKE_API_TOKEN_REQUEST = 'REVOKE_API_TOKEN_REQUEST';
export const REVOKE_API_TOKEN_SUCCESS = 'REVOKE_API_TOKEN_SUCCESS';
export const REVOKE_API_TOKEN_FAILURE = 'REVOKE_API_TOKEN_FAILURE';
export const FETCH_COMMISSIONS_REQUEST = 'FETCH_COMMISSIONS_REQUEST';
export const FETCH_COMMISSIONS_SUCCESS = 'FETCH_COMMISSIONS_SUCCESS';
export const FETCH_COMMISSIONS_FAILURE = 'FETCH_COMMISSIONS_FAILURE';
export const FETCH_FINANCE_DOCUMENTS_REQUEST = 'FETCH_FINANCE_DOCUMENTS_REQUEST';
export const FETCH_FINANCE_DOCUMENTS_SUCCESS = 'FETCH_FINANCE_DOCUMENTS_SUCCESS';
export const FETCH_FINANCE_DOCUMENTS_FAILURE = 'FETCH_FINANCE_DOCUMENTS_FAILURE';
export const CREATE_BILL_REQUEST = 'CREATE_BILL_REQUEST';
export const CREATE_BILL_SUCCESS = 'CREATE_BILL_SUCCESS';
export const CREATE_BILL_FAILURE = 'CREATE_BILL_FAILURE';
export const SEND_INVOICE_REQUEST = 'SEND_INVOICE_REQUEST';
export const SEND_INVOICE_SUCCESS = 'SEND_INVOICE_SUCCESS';
export const SEND_INVOICE_FAILURE = 'SEND_INVOICE_FAILURE';
