<template>
    <LayoutAuth>
        <template #default>
            <b-form @submit.prevent>
                <FormValidator :validator="$v.form.password" v-slot="slotProps" class="mb-3">
                    <b-form-input
                        v-model="form.password"
                        :placeholder="$t('password').toLowerCase()"
                        type="password"
                        :state="slotProps.state"
                        :disabled="submitting"
                        @keyup.enter="handleSubmit()"
                    />
                </FormValidator>
                <FormValidator :validator="$v.form.passwordConfirm" v-slot="slotProps" class="mb-3">
                    <b-form-input
                        v-model="form.passwordConfirm"
                        :placeholder="$t('auth.repeatPassword').toLowerCase()"
                        type="password"
                        :state="slotProps.state"
                        :disabled="submitting"
                        @keyup.enter="handleSubmit()"
                    />
                </FormValidator>
                <b-form-group>
                    <ButtonIcon
                        :loading="submitting"
                        :flip-icon="true"
                        variant="brand"
                        size="lg"
                        class="btn-login"
                        block
                        @click="handleSubmit()"
                    >
                        <template #default>
                            {{ $t('next') }}
                        </template>
                        <template #icon>
                            <img src="@/assets/arrow-button.svg" alt="" width="45px" class="ml-2">
                        </template>
                    </ButtonIcon>
                </b-form-group>
            </b-form>
        </template>
        <template #page-title>
            {{ $t('auth.resetPassword') }}
        </template>
        <template #login-as-buttons></template>
        <template #cta-link>
            <router-link :to="{name: 'Login'}" class="text-brand">
                {{ $t('auth.backToTheLoginPage') }}
            </router-link>
        </template>
    </LayoutAuth>
</template>

<script>
    import { required, sameAs, minLength, maxLength } from 'vuelidate/lib/validators';
    import AuthMixin from './auth-mixin.vue';

    export default {
        name: 'ResetPasswordToken',
        mixins: [AuthMixin],
        props: {
            token: String,
        },
        data() {
            return {
                form: {
                    password: '',
                    passwordConfirm: '',
                },
            };
        },
        validations: {
            form: {
                password: { required, minLength: minLength(5), maxLength: maxLength(255) },
                passwordConfirm: { required, sameAs: sameAs('password') },
            },
        },
        methods: {
            formRequest() {
                return this.$store.dispatch('auth/resetPasswordComplete', { token: this.token, password: this.form.password });
            },
            requestSuccess() {
                this.resetForm();
                this.$toastr.success(this.$t('auth.pageResetPasswordToken.successMessage'));
            },
        },
    };
</script>
