<!-- eslint-disable max-len -->
<template>
    <div>
        <PageHeader>
            <template #side>
                <ButtonIcon variant="success" class="mr-2" :disabled="!canSeeProfilePreview" @click="seeLandingPage">
                    {{ $t('tipboxLandingSettings.seeProfile') }}
                </ButtonIcon>
            </template>
            {{ $t('tipboxLandingSettings.title') }}
        </PageHeader>

        <a href="" target="_blank">
            <p class="widget-description">
                <feather type="file-text" />
                {{ $t('tipboxLandingSettings.help') }}
            </p>
        </a>

        <SpaceSwitchCard @space="space = $event" />

        <card v-if="space" :loading="loading">
            <error-message v-model="error" />

            <b-overlay class="row">
                <div class="col-xl-12 max-w- d-flex flex-column">
                    <card
                        class="flex-grow-1 border-b"
                        :header="$t('tipboxLandingSettings.profileSettings')"
                        :sub-header="$t('tipboxLandingSettings.profileSettingsDescription')"
                        :loading="loading"
                    >
                        <div class="row">
                            <div class="col-xl-6 d-flex flex-column">
                                <text-field
                                    v-model="model.profileName"
                                    :label="$t('tipboxLandingSettings.profileName')"
                                    :validation="$v.model.profileName"
                                />

                                <TextareaField
                                    v-model="model.description"
                                    class="mt-2"
                                    :label="$t('tipboxLandingSettings.description')"
                                    :rows="5"
                                    :validation="$v.model.description"
                                />

                                <text-field
                                    v-model="model.amounts[0]"
                                    class="mt-2"
                                    :label="$t('tipboxLandingSettings.amounts')"
                                    placeholder="zł"
                                    :validation="$v.model.amounts[0]"
                                />
                                <text-field
                                    v-model="model.amounts[1]"
                                    placeholder="zł"
                                    :validation="$v.model.amounts[1]"
                                />
                                <text-field
                                    v-model="model.amounts[2]"
                                    placeholder="zł"
                                    :validation="$v.model.amounts[2]"
                                />

                                <!-- Your Links -->
                                <div class="mt-2 links">
                                    <p v-if="model.links && model.links.length === 0" class="link-text">{{ $t('tipboxLandingSettings.yourLinks') }}</p>

                                    <div v-for="(link, index) in model.links " :key="index" class="link">
                                        <text-field
                                            v-model="model.links[index].url"
                                            class="link-text"
                                            :placeholder="$t('tipboxLandingSettings.url')"
                                            :label="index === 0 ? $t('tipboxLandingSettings.yourLinks') : ''"
                                        />

                                        <select-field
                                            v-model="model.links[index].social"
                                            class="link-select"
                                            :label="index === 0 ? $t('tipboxLandingSettings.social') : ''"
                                            :options="socialOptions"
                                        />
                                    </div>

                                    <ButtonIcon variant="success" class="mb-2" @click="addLink">
                                        {{ $t('tipboxLandingSettings.addSocial') }}
                                    </ButtonIcon>
                                </div>
                            </div>

                            <div class="col-xl-6 d-flex flex-column">
                                <text-field
                                    v-model="model.slug"
                                    :validation="$v.model.slug"
                                    placeholder="tipbox.link/"
                                    prepend="tipbox.link/"
                                    :label="$t('tipboxLandingSettings.slug')"
                                />

                                <TextareaField
                                    v-model="model.thanks"
                                    class="mt-2"
                                    :label="$t('tipboxLandingSettings.thanks')"
                                    :rows="5"
                                    :validation="$v.model.thanks"
                                />


                                <ColorField
                                    v-model="model.color"
                                    class="mt-2"
                                    :label="$t('tipboxLandingSettings.color')"
                                />

                                <SelectField
                                    v-model="model.icon"
                                    :options="iconsOptions"
                                    class="mt-4"
                                    :clearable="true"
                                    :label="$t('tipboxLandingSettings.icon')"
                                />

                                <text-field
                                    v-model="model.gaId"
                                    class="mt-2"
                                    :label="$t('tipboxLandingSettings.gaId')"
                                    :validation="$v.model.gaId"
                                    placeholder="G-XXXXXXXXXX"
                                />
                            </div>
                        </div>
                    </card>
                </div>
            </b-overlay>

            <section class="submit-buttons">
                <button-default class="mr-2" :loading="loading" @click="handleSaveLanding">
                    {{ $t('save') }}
                </button-default>

                <ButtonIcon variant="success" :disabled="canSeeProfilePreview" @click="seeLandingPage">
                    {{ $t('tipboxLandingSettings.seeProfile') }}
                </ButtonIcon>
            </section>
        </card>
    </div>
</template>

<script>
    import { required, maxLength, decimal, minValue } from 'vuelidate/lib/validators';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import Card from '@/components/common/Card.vue';
    import TextField from '@/components/common/TextField.vue';
    import ColorField from '@/components/common/ColorField.vue';
    import SelectField from '@/components/common/SelectField.vue';
    import PageHeader from '@/components/common/PageHeader.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import TextareaField from '@/components/common/TextareaField.vue';
    import SpaceSwitchCard from '@/components/common/SpaceSwitchCard.vue';

    export default {
        name: 'TipboxLandingSettings',
        components: {
            TextareaField,
            ErrorMessage,
            ButtonDefault,
            PageHeader,
            TextField,
            ColorField,
            SelectField,
            Card,
            SpaceSwitchCard,
        },
        data() {
            return {
                space: null,
                error: null,
                loading: false,
                isInitialized: false,

                socials: [],
                model: {
                    profileName: '',
                    description: '',
                    amounts: ['5', '10', '15'],
                    slug: '',
                    color: '#5369f8',
                    links: [],
                    gaId: '',
                    thanks: '',
                },

                emojiCodes: [
                    '👋',
                    '👍',
                    '👏',
                    '💪',
                    '🦴',
                    '🌹',
                    '🌷',
                    '🌻',
                    '🪴',
                    '🌲',
                    '🌳',
                    '🍀',
                    '🍇',
                    '🍎',
                    '🍓',
                    '🫐',
                    '🍅',
                    '🥑',
                    '🥕',
                    '🌶️',
                    '🥦',
                    '🥜',
                    '🥐',
                    '🥨',
                    '🥯',
                    '🧇',
                    '🍔',
                    '🍟',
                    '🍕',
                    '🌭',
                    '🥗',
                    '🍿',
                    '🍣',
                    '🥟',
                    '🥠',
                    '🍦',
                    '🍨',
                    '🍩',
                    '🍪',
                    '🍰',
                    '🧁',
                    '🍬',
                    '🍭',
                    '🫖',
                    '🍷',
                    '🍺',
                    '☕',
                    '🧉',
                    '🚲',
                    '⛵',
                    '🚀',
                    '⌚',
                    '⚡',
                    '💧',
                    '🎃',
                    '🎄',
                    '🎈',
                    '✨',
                    '🎉',
                    '🎁',
                    '🏆',
                    '⚽',
                    '🎲',
                    '🛍️',
                ],
            };
        },
        validations() {
            return {
                model: {
                    profileName: { required, maxLength: maxLength(50) },
                    description: { maxLength: maxLength(500) },
                    amounts: {
                        0: { required, decimal, minValue: minValue(this.minAmount[0]) },
                        1: { required, decimal, minValue: minValue(this.minAmount[1]) },
                        2: { required, decimal, minValue: minValue(this.minAmount[2]) },
                    },
                    slug: { required, maxLength: maxLength(50) },
                    color: { required },
                    gaId: { maxLength: maxLength(50) },
                    thanks: { maxLength: maxLength(500) },
                },
            };
        },
        computed: {
            minAmount() {
                const min = 5;
                return {
                    0: min,
                    1: Math.max(min + 0.5, (Number.parseInt(this.model.amounts[0]) || 0) + 0.5),
                    2: Math.max(min + 1, (Number.parseInt(this.model.amounts[1]) || 0) + 0.5),
                };
            },
            iconsOptions() {
                return this.emojiCodes.map(emoji => ({ value: emoji, text: emoji }));
            },
            apiParams() {
                return {
                    requestParams: this.model,
                    urlParams: { spaceId: this.space?.id },
                };
            },
            socialOptions() {
                return this.socials.map(social => ({ value: social.value, text: social.label }));
            },

            canSeeProfilePreview() {
                return this.space && this.space.id && this.model.id;
            },
        },
        watch: {
            space: {
                handler() {
                    if (this.space) {
                        this.fetchTipboxLanding();
                    }
                },
                immediate: true,
            },
        },
        methods: {
            resetForm() {
                this.$v.$reset();
                this.model = {
                    profileName: '',
                    description: '',
                    amounts: ['', '', ''],
                    slug: '',
                    color: '#5369f8',
                    links: [],
                    gaId: '',
                    thanks: '',
                };
            },

            async handleSaveLanding() {
                this.$v.model.$touch();
                if (this.$v.model.$invalid) return;

                delete this.model.gaId;
                delete this.model.id;

                this.loading = true;
                if (this.isInitialized) {
                    try {
                        await this.$store.dispatch('tipbox/patchTipboxLandingSetting', this.apiParams);
                        this.$toastr.success(this.$t('dataSavedSuccessfully'));
                    } catch (error) {
                        this.$toastr.error(error.message, this.$t('errorOccurred'));
                    } finally {
                        this.loading = false;
                    }
                } else {
                    try {
                        await this.$store.dispatch('tipbox/postTipboxLandingSetting', this.apiParams);
                        this.$toastr.success(this.$t('dataSavedSuccessfully'));
                    } catch (error) {
                        this.$toastr.error(error.message, this.$t('errorOccurred'));
                    } finally {
                        this.loading = false;
                    }
                }
            },

            fetchTipboxLanding() {
                this.loading = true;
                return this.$store
                    .dispatch('tipbox/fetchTipboxLandingSetting', { urlParams: this.apiParams.urlParams })
                    .then(response => {
                        this.model = response;
                        this.isInitialized = true;
                        this.loading = false;
                    })
                    .catch(() => {
                        this.resetForm();
                        this.isInitialized = false;
                        this.loading = false;
                    });
            },

            fetchSocials() {
                return this.$store.dispatch('tipbox/fetchSocials').then(response => {
                    this.socials = response.items;
                });
            },

            addLink() {
                this.model.links.push({
                    url: '',
                    social: '',
                });
            },

            async seeLandingPage() {
                const previewUrl = new URL(process.env.VUE_APP_TIPBOX_LANDING_PREVIEW_URL);
                previewUrl.searchParams.append('slug', this.model.slug);

                window.open(previewUrl.href, '_blank');
            },
        },
        mounted() {
            this.fetchSocials();
        },
    };
</script>

<style scoped>
    .links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .widget-description {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: -12px;
        color: #5369f8;
    }

    .link {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        gap: 1rem;
    }

    .link-text {
        width: 60%;
        font-weight: 600;
    }

    .link-select {
        width: 40%;
    }
</style>
