<template>
    <div>
        <PageHeader>{{ $t('pagesList') }}</PageHeader>
        <Filters ref="Filters" />
        <Results ref="Results" />
    </div>
</template>

<script>
    import viewFiltersMixin from '@/mixins/viewFiltersMixin';
    import Filters from './parts/Filters.vue';
    import Results from './parts/Results.vue';

    const DEFAULT_FILTERS_PARAMS = Object.freeze({
        name: null,
        url: null,
        users: [],
        categories: [],
        type: null,
        userTypes: [],
        statuses: [],
    });
    const DEFAULT_TABLE_PARAMS = Object.freeze({
        perPage: 10,
        page: 1,
        sort: 'id',
        order: 'DESC',
    });

    export default {
        name: 'AdminWebpagesList',
        mixins: [viewFiltersMixin],
        components: {
            Filters,
            Results,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                ...this.initParams(DEFAULT_FILTERS_PARAMS, DEFAULT_TABLE_PARAMS),
                request: this.createRequest('space/spaces'),
                fields: [],
            };
        },
        methods: {
            fetchData(force = false) {
                return this.request.get(null, this.requestParams, !force);
            },
        },
    };
</script>
