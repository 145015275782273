<template>
    <b-overlay :show="loading" rounded="sm" class="h-100">
        <div class="card mb-0 h-100">
            <div class="card-body">
                <ErrorMessage :value="errorMessage" />
                <h4 class="header-title mt-0 mb-3">
                    {{ $t('finance.yourPayouts') }}
                </h4>
                <Chart
                    height="380"
                    type="bar"
                    class="apex-charts"
                    :series="series"
                    :options="options"
                />
            </div>
        </div>
    </b-overlay>
</template>



<script>
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import Chart from '@/components/common/Chart.vue';

    export default {
        name: 'FinanceChart',
        components: {
            ErrorMessage,
            Chart,
        },
        props: {
            series: {
                type: Array,
                default: () => [],
            },
            loading: {
                type: Boolean,
                default: false,
            },
            error: {
                type: [Error, Object, String],
                default: null,
            },
        },
        data() {
            return {
                options: {
                    colors: ['#5369f8'],
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top',
                            },
                        },
                    },
                    xaxis: {
                        title: {
                            text: this.$t('finance.totalMonthlyPayouts'),
                            offsetY: 7,
                            style: {
                                fontSize: 14,
                                fontWeight: 300,
                            },
                        },
                    },
                    tooltip: {
                        x: {
                            show: true,
                            format: 'MMMM y',
                        },
                        y: {
                            title: {
                                formatter: seriesName => '',
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            show: false,
                            formatter: val => this.$format.currency(val),
                        },
                    },
                },
            };
        },
        computed: {
            errorMessage() {
                return this.error?.message || this.error || null;
            },
        },
    };
</script>
