<template>
    <Card :header="$t('selectedSpace')" :loading="loading">
        <div class="row">
            <SpaceSwitch class="col-sm-12" :disabled="disabled" @loading="onLoading" @spaceId="onSpaceId" @space="onSpace" />
        </div>
    </Card>
</template>



<script>
    import Card from '@/components/common/Card.vue';
    import SpaceSwitch from '@/components/common/SpaceSwitch.vue';

    export default {
        name: 'SpaceSwitchCard',
        components: {
            Card,
            SpaceSwitch,
        },
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                loading: false,
                spaceId: null,
                space: null,
            };
        },
        methods: {
            onLoading(loading) {
                this.$emit('loading', loading);
                this.loading = loading;
            },
            onSpaceId(spaceId) {
                this.$emit('spaceId', spaceId);
                this.spaceId = spaceId;
            },
            onSpace(space) {
                this.$emit('space', space);
                this.space = space;
            },
        },
    };
</script>
