<template>
    <div>
        <PageHeader :items="breadcrumb">
            <template #side>
                <div v-if="!currentBuybox && pluginError">
                    <strong>{{ $t('goBackTo') }}</strong>: <b-link :to="parentRoute">
                        {{ $t(parentRoute.meta.title) }}
                    </b-link>
                </div>
                <b-skeleton v-if="!currentBuybox && !pluginError" animation="wave" width="100%" class="mb-0 mt-1 width-300" />
            </template>
        </PageHeader>

        <Card :header="$t('widget')" :loading="isPluginsLoading">
            <readonly-field class="mb-1" :label="$t('widgetSettings.buyboxId')" :value="buyboxId" row />
            <readonly-field class="mb-1" :label="$t('widgetSettings.category')" :value="currentBuyboxCategory" row />
            <readonly-field class="mb-1" :label="$t('widgetSettings.space')" :value="currentBuyboxSpace" row />
        </Card>

        <div class="row">
            <div class="col-xl-6 d-flex mb-4">
                <b-overlay :show="isPluginsLoading || isLandingPageLoading" rounded="sm" class="h-100 w-100">
                    <Card class="mb-0 flex-grow-1 h-100" :header="$t('appearance')" :sub-header="$t('personalizeYourLandingPageText')">
                        <b-form-group>
                            <template #label>
                                <Tooltip :text="$t('lpFaviconTooltip')">
                                    {{ $t('favicon') }}
                                </Tooltip>
                            </template>
                            <div class="d-flex">
                                <FormValidator class="d-flex flex-column flex-grow-1" :validator="$v.form.favicon" :msg-props="{attribute: $t('shopIcon')}" v-slot="slotProps">
                                    <div class="d-flex gap-2">
                                        <FormFile v-model="form.favicon" accept="image/*" show-thumbnails :state="slotProps.state" :disabled="isSubmitting" />
                                        <ImageLoader v-if="item && item.favicon" :src="item.favicon" class="my-auto" size="32px" />
                                    </div>
                                </FormValidator>
                            </div>
                        </b-form-group>

                        <b-form-group>
                            <template #label>
                                <Tooltip :text="$t('lpLogoTooltip')">
                                    {{ $t('logo') }}
                                </Tooltip>
                            </template>
                            <div class="d-flex">
                                <FormValidator class="d-flex flex-column flex-grow-1" :validator="$v.form.logo" :msg-props="{attribute: $t('shopLogo')}" v-slot="slotProps">
                                    <div class="d-flex gap-2">
                                        <FormFile v-model="form.logo" accept="image/*" show-thumbnails :state="slotProps.state" :disabled="isSubmitting" />
                                        <ImageLoader v-if="item && item.logo" :src="item.logo" class="my-auto" size="32px" />
                                    </div>
                                </FormValidator>
                            </div>
                        </b-form-group>

                        <FormValidator :validator="$v.form.url" form-group>
                            <template #label>
                                <Tooltip :text="$t('lpUrlTooltip')">
                                    {{ $t('urlAddress') }}
                                </Tooltip>
                            </template>
                            <template #default="slotProps">
                                <b-form-input v-model="form.url" :placeholder="$t('https')" :state="slotProps.state" :disabled="isSubmitting" />
                            </template>
                        </FormValidator>

                        <div class="py-2"></div>

                        <b-form-group>
                            <template #label>
                                <Tooltip :text="$t('lpLinksTooltip')">
                                    {{ $t('links') }}
                                </Tooltip>
                            </template>
                            <div class="d-flex flex-column gap-1">
                                <div v-for="(item, index) in form.links" :key="index" class="d-flex gap-3">
                                    <FormValidator class="d-flex flex-column flex-grow-1" :validator="$v.form[`links-${index}-anchor`]" v-slot="slotProps">
                                        <b-form-input v-model="form.links[index].anchor" :placeholder="$t('title')" :disabled="isSubmitting" :state="slotProps.state" />
                                    </FormValidator>
                                    <FormValidator class="d-flex flex-column flex-grow-5" :validator="$v.form[`links-${index}-url`]" v-slot="slotProps">
                                        <b-form-input v-model="form.links[index].url" :placeholder="$t('https')" :disabled="isSubmitting" :state="slotProps.state" />
                                    </FormValidator>
                                </div>
                            </div>
                        </b-form-group>

                        <div class="py-2"></div>

                        <FormValidator :validator="$v.form.text">
                            <template #label>
                                <Tooltip :text="$t('lpTextTooltip')">
                                    {{ $t('additionalText') }}
                                </Tooltip>
                            </template>
                            <template #default="slotProps">
                                <b-form-textarea v-model="form.text" :state="slotProps.state" :disabled="isSubmitting" maxlength="300" />
                            </template>
                        </FormValidator>

                        <div class="py-2"></div>

                        <b-form-group>
                            <template #label>
                                <Tooltip :text="$t('lpGaIdTooltip')">
                                    {{ $t('googleAnalyticsId') }}
                                </Tooltip>
                            </template>
                            <b-form-input v-model="form.gaId" :placeholder="$t('gaPlaceholder')" :disabled="isSubmitting" />
                        </b-form-group>

                        <div class="py-2"></div>

                        <ButtonIcon :loading="isSubmitting" :disabled="!currentBuybox" @click="submit()">
                            {{ $t('saveChanges') }}
                        </ButtonIcon>
                    </Card>
                </b-overlay>
            </div>
            <div class="col-xl-6 d-flex mb-4">
                <b-overlay :show="isPluginsLoading || isLandingPageLoading" rounded="sm" class="h-100 w-100">
                    <Card class="mb-0 flex-grow-1 h-100" no-padding>
                        <div>
                            <img src="@/assets/images/landing-page/file1.png" class="w-100">
                        </div>
                        <div class="d-flex justify-content-center m-3">
                            <a v-bind="form.url && !$v.form.url.$invalid ? { href: form.url, target: '_blank' } : {}">
                                <ImageLoader :src="logoUrl || (item && item.logo)" class="my-auto" width="200px" height="100px" />
                            </a>
                        </div>
                        <div class="px-5">
                            <div class="px-4">
                                <img src="@/assets/images/landing-page/file2.png" class="w-100">
                            </div>
                        </div>
                        <div class="d-flex justify-content-center gap-3 m-3">
                            <a v-for="(item, index) in form.links" :key="index" :href="item.url" target="_blank">{{ item.anchor }}</a>
                        </div>
                        <div class="d-flex justify-content-center gap-3 m-3">
                            {{ form.text }}
                        </div>
                    </Card>
                </b-overlay>
            </div>
        </div>
    </div>
</template>



<script>
    import pick from 'lodash/pick';
    import { serialize as objectToFormData } from 'object-to-formdata';
    import { helpers, required, maxLength } from 'vuelidate/lib/validators';
    import cloneDeep from 'lodash/cloneDeep';
    import PageHeader from '@/components/common/PageHeader.vue';
    import ReadonlyField from '@/components/common/ReadonlyField.vue';
    import FormValidator from '@/components/common/FormValidator.vue';
    import FormFile from '@/components/common/FormFile.vue';
    import ImageLoader from '@/components/common/ImageLoader.vue';
    import Tooltip from '@/components/common/Tooltip.vue';
    import { image, url } from '@/utils/validators';

    export default {
        name: 'Edit',
        components: {
            PageHeader,
            ReadonlyField,
            FormValidator,
            FormFile,
            ImageLoader,
            Tooltip,
        },
        props: {
            spaceId: {
                type: Number,
                required: true,
            },
            buyboxId: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                item: null,
                form: {
                    favicon: null,
                    logo: null,
                    url: '',
                    links: [
                        { url: '', anchor: '' },
                        { url: '', anchor: '' },
                        { url: '', anchor: '' },
                    ],
                    text: '',
                    gaId: '',
                },
                isSubmitting: false,
            };
        },
        validations() {
            return {
                form: {
                    favicon: { image: value => !helpers.req(value) || image(value) },
                    logo: { image: value => !helpers.req(value) || image(value) },
                    url: { required, url: value => !helpers.req(value) || url(value) },
                    text: { maxLength: maxLength(300) },
                    ...this.form.links.reduce(
                        (acc, item, index) => ({
                            ...acc,
                            ...{
                                [`links-${index}-anchor`]: {
                                    required: () => (item.url ? required(item.anchor) : true),
                                },
                                [`links-${index}-url`]: {
                                    required: () => (item.anchor ? required(item.url) : true),
                                    url: () => (item.url ? url(item.url) : true),
                                },
                            },
                        }),
                        {}
                    ),
                },
            };
        },
        computed: {
            pluginError() {
                return this.$store.state.plugin.error;
            },
            currentBuybox() {
                return this.plugins[this.buyboxId];
            },
            currentBuyboxCategory() {
                return this.currentBuybox?.category?.name || '-';
            },
            currentBuyboxSpace() {
                return this.currentBuybox?.space?.name || '-';
            },
            isPluginsLoading() {
                return this.$store.state.plugin.loading.fetchPlugins;
            },
            plugins() {
                return this.$store.state.plugin.plugins;
            },
            landingPage() {
                return this.$store.getters['landingPage/getLandingPage'];
            },
            isLandingPageLoading() {
                return this.$store.getters['landingPage/getLoading']('landingPage');
            },
            landingPageError() {
                return this.$store.getters['landingPage/getError']('landingPage')?.message;
            },
            breadcrumb() {
                if (!this.currentBuybox) return [];
                return [
                    { text: this.$t(this.parentRoute.meta.title), to: this.$route.params.parentUrl || this.parentRoute },
                    { text: this.currentBuybox?.id ? `${this.$t('space')} "${this.currentBuybox.space.name}" | ${this.$t('widget')} ID#${this.currentBuybox.id}` : '---', active: true },
                ];
            },
            parentRoute() {
                return this.$router.resolve({ name: 'ProductLandingPageSettings' }).resolved;
            },
            faviconUrl() {
                return this.form.favicon ? window.URL.createObjectURL(this.form.favicon) : '';
            },
            logoUrl() {
                return this.form.logo ? window.URL.createObjectURL(this.form.logo) : '';
            },
            urlParams() {
                return { id: this.currentBuybox.id };
            },
        },
        watch: {
            currentBuybox: {
                immediate: true,
                handler() {
                    if (this.currentBuybox.id) this.fetchLandingPage();
                },
            },
            landingPage() {
                this.item = this.landingPage;
            },
            item() {
                this.resetData();
                this.initFormValues();
            },
        },
        methods: {
            fetchPlugins() {
                return this.$store.dispatch('plugin/fetchPlugins', { spaceId: this.spaceId });
            },
            fetchLandingPage() {
                return this.$store.dispatch('landingPage/fetchLandingPage', { urlParams: this.urlParams });
            },
            submitLandingPage() {
                const apiParams = {
                    urlParams: this.urlParams,
                    requestParams: { ...pick(this.form, ['url', 'text', 'gaId']), links: this.form.links.filter(item => !!(item.url && item.anchor)) },
                };
                return this.$store.dispatch('landingPage/patchLandingPage', apiParams);
            },
            submitImages() {
                const apiParams = {
                    urlParams: this.urlParams,
                    requestParams: objectToFormData(pick(this.form, ['favicon', 'logo'])),
                    headers: { 'Content-Type': 'multipart/form-data' },
                };
                return this.$store.dispatch('landingPage/postImages', apiParams);
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) return;

                this.isSubmitting = true;

                const promises = [this.submitLandingPage()];
                if (this.form.favicon || this.form.logo) promises.push(this.submitImages());
                Promise.allSettled(promises).then(results => {
                    results.forEach((result, index) => {
                        if (result.status === 'fulfilled') {
                            this.item = result.value;
                            if (index === 0) this.$toastr.success(this.$t('dataSavedSuccessfully'));
                        } else if (result.status === 'rejected') {
                            this.$toastr.error(result.reason.message, this.$t('errorOccurred'));
                        }
                    });
                    this.isSubmitting = false;
                });
            },
            resetData() {
                const data = this.$options.data.bind(this)();
                Object.keys(data.form).forEach(key => {
                    if (this.form[key] !== undefined) this.form[key] = cloneDeep(data.form[key]);
                });
                this.$v.form.$reset();
            },
            initFormValues() {
                const form = {};
                form.links = cloneDeep(this.form.links);
                form.url = this.item.url;
                form.text = this.item.text;
                form.gaId = this.item.gaId;
                this.item.links.forEach((item, index) => (form.links[index] = item));
                this.form = form;
            },
        },
        created() {
            if (!this.currentBuybox) this.fetchPlugins();
        },
    };
</script>
