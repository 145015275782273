import { routes as routerRoutes } from '@/router/router';
import store from '@/store/store';
import { i18n } from '@/i18n/i18n';
import globalData from '@/store/globalData';

/* eslint-disable prettier-vue/prettier, semi */
const dashboard = {
    icon: 'home',
    get label() { return i18n.t('sidebar.dashboard') },
    to: { name: 'Dashboard' },
};
const widget = {
    icon: 'box',
    get label() { return i18n.t('sidebar.widget') },
    to: { name: 'Widget' },
};
const widgetSettings = {
    get label() { return i18n.t('sidebar.widgetSettings') },
    to: { name: 'WidgetSettings' },
};
const widgetGenerator = {
    get label() { return i18n.t('sidebar.generator') },
    to: { name: 'WidgetGenerator' },
};
const textLinks = {
    icon: 'external-link',
    get label() { return i18n.t('sidebar.textLinks') },
    to: { name: 'TextLinks' },
};
const productLandingPage = {
    icon: 'monitor',
    get label() { return i18n.t('sidebar.gdziekupicTo') },
    to: { name: 'ProductLandingPage' },
};
const productLandingPageSettings = {
    get label() { return i18n.t('sidebar.productLandingPageSettings') },
    to: { name: 'ProductLandingPageSettings' },
};
const productLandingPageGenerator = {
    get label() { return i18n.t('sidebar.productLandingPageGenerator') },
    to: { name: 'ProductLandingPageGenerator' },
};
const reports = {
    icon: 'activity',
    get label() { return i18n.t('sidebar.reports') },
    to: { name: 'Reports' },
};
const reportsStats = {
    get label() { return i18n.t('sidebar.stats') },
    to: { name: 'ReportsStats' },
};
const reportsTransactions = {
    get label() { return i18n.t('sidebar.transactions') },
    to: { name: 'ReportsTransactions' },
};
const reportsPages = {
    get label() { return i18n.t('sidebar.reportsPages') },
    to: { name: 'ReportsPages' },
};
const reportPages = {
    get label() { return i18n.t('sidebar.reportPages') },
    to: { name: 'ReportsPages' },
};
const reportShops = {
    get label() { return i18n.t('sidebar.reportShops') },
    to: { name: 'ReportsShops' },
};
const reportProducts = {
    get label() { return i18n.t('sidebar.reportProducts') },
    to: { name: 'ReportsProducts' },
};
const reportsConversion = {
    get label() { return i18n.t('sidebar.reportsConversion') },
    to: { name: 'ReportsConversion' },
};
const reportsIntegrationStatus = {
    get label() { return i18n.t('sidebar.reportsIntegrationStatus') },
    to: { name: 'ReportsIntegrationStatus' },
};
const reportsFeedImportSummary = {
    get label() { return i18n.t('sidebar.feedImportSummary') },
    to: { name: 'ReportsFeedImportSummary' },
};
const settings = {
    icon: 'settings',
    get label() { return i18n.t('sidebar.settings') },
    to: { name: 'Settings' },
};
const pages = {
    icon: 'layout',
    get label() { return i18n.t('sidebar.pages') },
    to: { name: 'Pages' },
};
const shops = {
    icon: 'shopping-cart',
    get label() { return i18n.t('sidebar.shops') },
    to: { name: 'Shops' },
};
const finance = {
    icon: 'trending-up',
    get label() { return i18n.t('sidebar.finance') },
    to: { name: 'Finance' },
};
const settingsApi = {
    get label() { return i18n.t('sidebar.api') },
    to: { name: 'SettingsApi' },
};
const settingsIntegrations = {
    get label() { return i18n.t('sidebar.integrations') },
    to: { name: 'SettingsIntegrations' },
};
const webpagesPublishers = {
    icon: 'file-text',
    get label() { return i18n.t('sidebar.webpagesPublishers') },
    to: { name: 'Webpages' },
};
const webpagesAdmin = {
    icon: 'file-text',
    get label() { return i18n.t('sidebar.webpagesAdmin') },
    to: { name: 'Webpages' },
};
const webpagesListAdmin = {
    icon: 'file-text',
    get label() { return i18n.t('pagesList') },
    to: { name: 'WebpagesList' },
};
const settingsShopPresentation = {
    get label() { return i18n.t('sidebar.shopPresentation') },
    to: { name: 'SettingsShopPresentation' },
};
const api = {
    icon: 'layers',
    get label() { return i18n.t('sidebar.api') },
    to: { name: 'Api' },
};
const offersCatalog = {
    icon: 'folder',
    get label() { return i18n.t('sidebar.offersCatalog') },
    to: { name: 'OffersCatalog' },
};
const catalog = {
    icon: 'folder',
    get label() { return i18n.t('sidebar.catalog') },
    to: { name: 'Catalog' },
};
const catalogProducts = {
    get label() { return i18n.t('sidebar.catalogProducts') },
    to: { name: 'CatalogProducts' },
};
const catalogManufacturers = {
    get label() { return i18n.t('sidebar.catalogManufacturers') },
    to: { name: 'CatalogManufacturers' },
};
const catalogBrands = {
    get label() { return i18n.t('sidebar.catalogBrands') },
    to: { name: 'CatalogBrands' },
};
const catalogCategories = {
    get label() { return i18n.t('sidebar.catalogCategories') },
    to: { name: 'CatalogCategories' },
};
const catalogModeration = {
    get label() { return i18n.t('sidebar.catalogModeration') },
    to: { name: 'CatalogModeration' },
};
const users = {
    icon: 'users',
    get label() { return i18n.t('sidebar.users') },
    to: { name: 'Users' },
};
const offers = {
    icon: 'search',
    get label() { return i18n.t('sidebar.offers') },
    to: { name: 'Offers' },
};
const settlements = {
    icon: 'dollar-sign',
    get label() { return i18n.t('sidebar.settlements') },
    to: { name: 'Settlements' },
};
const advertiserSettlements = {
    icon: 'dollar-sign',
    get label() { return i18n.t('sidebar.advertiserSettlements') },
    to: { name: 'AdvertiserSettlements' },
};
const advertiserSettlementsList = {
    icon: 'dollar-sign',
    get label() { return i18n.t('sidebar.advertiserSettlementsList') },
    to: { name: 'AdvertiserSettlementsList' },
};
const campaignsAdmin = {
    icon: 'shopping-cart',
    get label() { return i18n.t('sidebar.campaigns') },
    to: { name: 'Campaigns' },
};
const campaignsListAdmin = {
    icon: 'list',
    get label() { return i18n.t('list') },
    to: { name: 'CampaignsList' },
};
const producerProducts = {
    icon: 'box',
    get label() { return i18n.t('sidebar.producerProducts') },
    to: { name: 'ProducerProducts' },
};
const producerProductsImport = {
    icon: 'download-cloud',
    get label() { return i18n.t('sidebar.producerProductsImport') },
    to: { name: 'ProducerProductsImport' },
};
const Products = {
    icon: 'search',
    get label() { return i18n.t('sidebar.productSearch') },
    to: { name: 'Products' },
};
const transactions = {
    icon: 'list',
    get label() { return i18n.t('sidebar.transactions2') },
    to: { name: 'Transactions' },
};
const transactionImport = {
    get label() { return i18n.t('sidebar.transactionsImport') },
    to: { name: 'TransactionsImport' },
};
const transactionValidation = {
    get label() { return i18n.t('sidebar.transactionValidation') },
    to: { name: 'TransactionsValidation' },
};
const transactionCorrection = {
    get label() { return i18n.t('sidebar.transactionCorrection') },
    to: { name: 'TransactionsCorrection' },
};
const subscriptions = {
    get label() { return i18n.t('sidebar.subscriptions') },
    to: { name: 'FinanceSubscriptions' },
};
const adminFinanceSettlementsPublishers = {
    get label() { return i18n.t('sidebar.settlementsWithPublishers') },
    to: { name: 'AdminFinanceSettlementsPublishers' },
};
const bbAffiliateProgram = {
    icon: 'share-2',
    get label() { return i18n.t('sidebar.bbAffiliateProgram') },
    to: { name: 'BBAffiliateProgram' },
};
const adminTipbox = {
    icon: 'dollar-sign',
    get label() { return i18n.t('sidebar.tipbox') },
    to: { name: 'Tipbox' },
};
const adminTipboxTips = {
    get label() { return i18n.t('sidebar.tips') },
    to: { name: 'AdminTipboxTips' },
};
const adminTipboxVerification = {
    get label() { return i18n.t('sidebar.verification') },
    to: { name: 'AdminTipboxVerification' },
};
const adminTipboxSettlements = {
    get label() { return i18n.t('sidebar.settlements') },
    to: { name: 'AdminTipboxSettlements' },
};
const widgeterTipbox = {
    icon: 'dollar-sign',
    get label() { return i18n.t('sidebar.widgetTipbox') },
    to: { name: 'Tipbox' },
};
const widgeterTipboxTips = {
    get label() { return i18n.t('sidebar.tipboxTips') },
    to: { name: 'WidgeterTipboxTips' },
};
const widgeterTipboxSettlements = {
    get label() { return i18n.t('sidebar.tipboxSettlements') },
    to: { name: 'WidgeterTipboxSettlements' },
};
const widgeterTipboxSettings = {
    get label() { return i18n.t('sidebar.tipboxSettings') },
    to: { name: 'WidgeterTipboxSettings' },
};
const widgeterTipboxLink = {
    get label() { return i18n.t('sidebar.tipboxLink') },
    to: { name: 'WidgeterTipboxLink' },
};
/* eslint-enable prettier-vue/prettier, semi */

export function findRouteByName(routeName, routes) {
    // eslint-disable-next-line
    for (const route of routes) {
        if (route.name === routeName) {
            return route;
        }
        if (route.children) {
            const foundRoute = findRouteByName(routeName, route.children);
            if (foundRoute) {
                return foundRoute;
            }
        }
    }
    return null;
}

export function getFeatureByRouteName(routeName) {
    try {
        const route = findRouteByName(routeName, routerRoutes);
        return route?.meta?.feature || null;
    } catch (error) {
        return null;
    }
}

export function isRouteEnabled(item) {
    const routeFeatureFlag = getFeatureByRouteName(item.to.name);
    if (!routeFeatureFlag) return true;
    const flag = store.getters['auth/isFeatureFlagEnabled'](routeFeatureFlag);
    return flag;
}

export function getSidebar(sidebar) {
    return sidebar.reduce((acc, sidebarItem) => {
        const item = Array.isArray(sidebarItem) ? sidebarItem[0] : sidebarItem;
        const children = Array.isArray(sidebarItem) ? sidebarItem[1] : undefined;
        if (!isRouteEnabled(item)) return acc;
        if (!children?.length) return [...acc, { ...item }];
        const newChildren = getSidebar(children);
        const newItem = { ...item, children: newChildren };
        return [...acc, newItem];
    }, []);
}

export const sidebar = () => {
    /* eslint-disable */

    if (globalData.user.isRoleAdmin) {
        return getSidebar([
            [dashboard],
            [reports, [reportsStats, reportsTransactions, reportsConversion, reportsIntegrationStatus, reportsFeedImportSummary]],
            [catalog, [catalogProducts, catalogManufacturers, catalogBrands, catalogCategories, catalogModeration]],
            [users],
            [adminTipbox, [adminTipboxTips, adminTipboxVerification, adminTipboxSettlements]],
            [webpagesAdmin, [webpagesListAdmin]],
            [settlements, [advertiserSettlements, advertiserSettlementsList]],
            [offers],
            [campaignsAdmin, [campaignsListAdmin]],
            [producerProducts, [producerProductsImport]],
            [Products],
            [transactions, [transactionImport, transactionValidation, transactionCorrection]],
            [finance, [subscriptions, adminFinanceSettlementsPublishers]],
        ]);
    }

    if (globalData.user.isRoleWidgeter) {
        return getSidebar([
            [dashboard],
            [widget, [widgetGenerator, widgetSettings]],
            [widgeterTipbox, [widgeterTipboxTips, widgeterTipboxSettlements, widgeterTipboxSettings, widgeterTipboxLink]],
            [productLandingPage, [productLandingPageGenerator, productLandingPageSettings]],
            [textLinks],
            [reports, [reportsStats, reportsTransactions, reportPages, reportShops, reportProducts]],
            [pages],
            [shops],
            [finance],
            [settings, [settingsApi, settingsIntegrations]],
            [bbAffiliateProgram],
        ]);
    }

    if (globalData.user.isRoleAdvertiser) {
        return getSidebar([
            [dashboard],
            [webpagesPublishers],
            [reports, [reportsStats, reportsTransactions, reportsPages]],
            [settings, [settingsShopPresentation]],
            [api],
            [offersCatalog],
        ]);
    }

    return [];
    /* eslint-enable */
};

export default sidebar;
