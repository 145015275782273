<template>
    <b-alert
        :show="Boolean(value)"
        variant="danger"
        :dismissible="dismissible"
        @dismissed="$emit('dismissed')"
    >
        {{ message }} <span v-if="action" class="action" @click="onAction">{{ action }}</span>
    </b-alert>
</template>

<script>
    export default {
        name: 'ErrorMessage',
        props: {
            value: {
                type: [Error, String, Object],
            },
            dismissible: {
                type: Boolean,
                default: true,
            },
            action: {
                type: String,
            },
        },
        computed: {
            message() {
                return this.value?.message || this.value || this.$t('errorOccurred');
            },
        },
        methods: {
            onRetry() {
                this.$emit('retry');
            },
            onAction() {
                this.$emit('action');
            },
        },
    };
</script>

<style scoped>
    .action {
        cursor: pointer;
        text-decoration: underline;
    }
</style>
