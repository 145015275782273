import numberUtil from '@/utils/number';
import dateUtil from '@/utils/date';

/**
 * This object is assigned to Vue prototype as $format, so it's more convenient to format data in template
 * e.g. in tables headers
 */

export default {
    currency: numberUtil.formatCurrency,

    number(value) {
        return numberUtil.formatNumber(value);
    },

    percent(value, minimumFractionDigits = 2) {
        return numberUtil.formatPercent(value, { minimumFractionDigits });
    },

    date(value, dateFormat) {
        return dateUtil.formatDate(value, dateFormat);
    },

    dateAndHour(value, dateFormat, timeFormat) {
        return `${dateUtil.formatDate(value, dateFormat)} ${dateUtil.formatHour(value, timeFormat)}`;
    },

    seconds(value) {
        return dateUtil.secondsToString(value);
    },

    month(value) {
        return dateUtil.formatMonth(value);
    },

    param(value) {
        return value && decodeURIComponent(value);
    },
};
