<template>
    <b-modal
        :id="`modal-edit-${$parent.modalItemName}`"
        ref="modal"
        :title="$t('enterYourWebsiteDetails')"
        size="md"
        cancel-variant="light"
        ok-variant="primary"
        :cancel-title="$t('cancel')"
        :ok-title="$t('save')"
        :busy="submitting"
        static
        @ok.prevent="handleSubmit()"
        @show="resetForm()"
        @hidden="resetForm()"
    >
        <b-overlay :show="submitting" opacity="0" class="h-100">
            <b-form @submit.prevent>
                <FormValidator form-group :validator="$v.form.name" v-slot="slotProps" :label="$t('name')">
                    <b-form-input v-model="form.name" :placeholder="$t('eGMyBlog')" :state="slotProps.state" :disabled="submitting" />
                </FormValidator>
                <FormValidator form-group :validator="$v.form.url" v-slot="slotProps" :label="$t('url')">
                    <span v-if="item">
                        {{ item.url }}
                    </span>
                    <template v-else>
                        <b-form-input v-model="form.url" :placeholder="$t('https')" :state="slotProps.state" :disabled="submitting" />
                    </template>
                </FormValidator>
                <FormValidator form-group :validator="$v.form.categories" v-slot="slotProps" :label="$t('category')">
                    <Multiselect
                        :value="form.categories"
                        :multiple="true"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :fetch-options-function="fetchCategories"
                        :parse-options-function="parseCategories"
                        :state="slotProps.state"
                        :disabled="submitting"
                        @change="items => form.categories = items.map(v => v.value)"
                    />
                </FormValidator>
                <FormValidator form-group :validator="$v.form.type" v-slot="slotProps" :label="$t('type')">
                    <Multiselect
                        v-model="form.type"
                        :fetch-options-function="fetchSpaceTypes"
                        :parse-options-function="parseSpaceTypes"
                        :state="slotProps.state"
                        :disabled="submitting"
                    />
                </FormValidator>
            </b-form>
        </b-overlay>
    </b-modal>
</template>



<script>
    import cloneDeep from 'lodash/cloneDeep';
    import { required, url, minLength } from 'vuelidate/lib/validators';
    import { uniqueUrl } from '@/utils/validators';
    import FormValidator from '@/components/common/FormValidator.vue';
    import categoryService from '@/services/categoryService';
    import spaceService from '@/services/spaceService';
    import Multiselect from '@/components/common/Multiselect.vue';

    export default {
        name: 'EditItem',
        components: {
            FormValidator,
            Multiselect,
        },
        data() {
            return {
                item: null,
                form: {
                    name: '',
                    url: '',
                    categories: [],
                    type: null,
                },
                formInitData: {},
                submitting: false,
                error: null,
                EXCLUDED_URLS: ['facebook.com', 'instagram.com', 'tiktok.com', 'youtube.com', 'google.pl', 'google.com', 'allegro.pl', 'vinted.pl'],
                usedUrl: [],
            };
        },
        computed: {
            spacesUrlList() {
                return Object.values(this.$store.state.space.spaces)
                    .map(item => item.url)
                    .filter(url => url !== this.item?.url); // eslint-disable-line
            },
        },
        validations() {
            const excludedUrls = this.EXCLUDED_URLS;
            const validations = {
                form: {
                    name: { required },
                    categories: { required, minLength: minLength(1) },
                    type: { required },
                },
            };
            if (!this.item) {
                validations.form.url = { required, url, unique: value => uniqueUrl(value, [...this.spacesUrlList, ...this.usedUrl]), tooGeneralUrl: value => uniqueUrl(value, excludedUrls) };
            }
            return validations;
        },
        watch: {
            error(error) {
                if (error) {
                    if (error.error?.response?.data?.fields?.url?.message === 'api_errors.form.value_already_used') {
                        const vNodesMsg = this.$createElement('div', { domProps: { innerHTML: this.$t('youCannotAddSuchAnAddressBecauseSomeoneElseHasAlreadyReportedSuchASite') } });
                        this.$toastr.error(vNodesMsg);
                        this.usedUrl.push(this.form.url);
                    } else {
                        this.$toastr.error(error.message);
                    }
                }
            },
            item() {
                if (!this.item) return;
                this.form = {
                    name: this.item.name,
                    url: this.item.url,
                    categories: this.item.categories.map(item => item.id),
                    type: this.item.type,
                };
            },
        },
        methods: {
            resetForm() {
                Object.keys(this.form).forEach(key => {
                    if (key in this.formInitData) this.form[key] = this.formInitData[key];
                });
                this.$v.$reset();
            },
            resetError() {
                this.error = null;
            },
            setError(error) {
                this.error = error;
            },
            handleSubmit() {
                this.$v.$touch();
                if (this.$v.$invalid) return;
                this.submitting = true;
                this.error = null;
                this.formRequest()
                    .then(this.requestSuccess)
                    .catch(this.setError)
                    .finally(() => (this.submitting = false));
            },
            formRequest() {
                const data = { ...(this.item || {}), ...this.form };
                if (this.item) {
                    delete data.url;
                }
                if (typeof data.type === 'object') {
                    data.type = data.type.value;
                }
                const action = this.item ? 'space/updateSpace' : 'space/createSpace';
                return this.$store.dispatch(action, data);
            },
            requestSuccess(data) {
                const msg = this.item ? this.$t('pages.updateSuccess', { name: this.form.name }) : this.$t('pages.createSuccess', { name: this.form.name });
                this.$toastr.success(msg);
                this.$refs.modal.hide();
                this.resetForm();
                this.fetchSpaces({ force: true, cache: false });
                this.usedUrl = [];
            },
            fetchCategories() {
                return categoryService.getCategories();
            },
            parseCategories(data) {
                return data.map(({ id, name }) => ({ value: id, text: name }));
            },
            fetchSpaceTypes() {
                return spaceService.fetchSpaceTypes();
            },
            parseSpaceTypes(data) {
                return data?.items?.map(({ value, label }) => ({ value: String(value), text: label }));
            },
            fetchSpaces(params = {}) {
                return this.$store.dispatch('space/fetchSpaces', params);
            },
        },
        created() {
            this.formInitData = cloneDeep(this.form);
        },
        mounted() {
            this.$root.$on(`edit-${this.$parent.modalItemName}`, item => (this.item = cloneDeep(item)));
            this.$root.$on('bv::toast::hidden', this.resetError);
        },
        beforeDestroy() {
            this.$root.$off('bv::toast::hidden', this.resetError);
        },
    };
</script>
