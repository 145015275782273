<template>
    <b-overlay :show="loading" rounded="sm" class="h-100">
        <Card :header="$t('dashboard.yourBestPages')" class="mb-0 h-100">
            <div class="overflow-auto">
                <ErrorMessage :value="error" />
                <b-table
                    hover
                    :items="items"
                    :fields="fields"
                    show-empty
                    :empty-text="$t('noResult')"
                    :busy="loading"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                >
                    <template #table-busy>
                        <div class="d-flex justify-content-center my-2">
                            <b-spinner />
                        </div>
                    </template>
                    <template #cell(value)="data">
                        {{ $format.currency(data.item.value) }}
                    </template>
                </b-table>
            </div>
        </Card>
    </b-overlay>
</template>



<script>
    import Card from '@/components/common/Card.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import { chartDataGroupBy } from '@/utils/chart';

    export default {
        name: 'WidgetBestSpaces',
        components: {
            Card,
            ErrorMessage,
        },
        props: {
            timeRange: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                itemsToShow: 5,
                sortBy: 'commission',
                sortDesc: true,
            };
        },
        computed: {
            isRoleWidgeter() {
                return this.$store.getters['auth/isRoleWidgeter'];
            },
            spaceCount() {
                return this.$store.getters['space/spaceCount'];
            },
            campaignId() {
                return this.$store.getters['campaigns/getCurrentCampaign']?.id;
            },
            requestParams() {
                const feature = this.$store.getters['auth/isRoleAdvertiser'] ? 'amount' : 'publisher_commission';
                return {
                    startDate: this.timeRange.startDate,
                    endDate: this.timeRange.endDate,
                    feature,
                    groupBy: chartDataGroupBy(this.timeRange),
                    showFirst: this.itemsToShow,
                    campaigns: this.campaignId || '',
                };
            },
            apiParams() {
                return { requestParams: this.requestParams };
            },
            fields() {
                return [
                    {
                        key: 'name',
                        label: this.$t('dashboard.space'),
                    },
                    {
                        key: 'value',
                        label: this.$store.getters['auth/isRoleAdvertiser'] ? this.$t('dashboard.salesValue') : this.$t('dashboard.commission'),
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ];
            },
            loading() {
                return this.$store.getters['userStats/getLoading']('spaceReport');
            },
            spaces() {
                return this.$store.getters['userStats/getSpaceReport']?.pie_chart || [];
            },
            items() {
                return this.spaces.slice(0, this.itemsToShow).map(item => ({ name: item[0], value: item[1] })) || [];
            },
            error() {
                return this.$store.getters['userStats/getError']('spaceReport')?.message;
            },
        },
        watch: {
            apiParams: {
                deep: true,
                handler: 'fetchData',
            },
        },
        methods: {
            fetchData(params = {}) {
                if (this.isRoleWidgeter && !this.spaceCount) return;
                this.$store.dispatch('userStats/fetchSpaceReport', { ...this.apiParams, ...params });
            },
        },
    };
</script>
