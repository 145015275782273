/* eslint-disable no-shadow */
import Vue from 'vue';
import dateUtil from '@/utils/date';
import { TransactionStatus, translateTransactionStatus } from '../../services/transactionService';
import { i18n } from '@/i18n/i18n';
import categoriesService from '@/services/categoriesService';
import apiService from '@/services/apiService';

const FORMATS_REQUEST = 'FORMATS_REQUEST';
const FORMATS_SUCCESS = 'FORMATS_SUCCESS';
const FORMATS_CLEAN = 'FORMATS_CLEAN';
const FORMATS_ERROR = 'FORMATS_ERROR';

const COMPANY_DATA_SUCCESS = 'COMPANY_DATA_SUCCESS';
const COMPANY_DATA_ERROR = 'COMPANY_DATA_ERROR';

const getInitialState = () => ({
    categoriesFormats: {},
    companyData: {},
    lastRequestedCategoryId: null,
});

const mutations = {
    [FORMATS_CLEAN](state) {
        Object.assign(state, getInitialState());
    },
    [FORMATS_REQUEST](state, categoryId) {
        state.lastRequestedCategoryId = categoryId;
    },
    [FORMATS_SUCCESS](state, { categoryId, options }) {
        Vue.set(state.categoriesFormats, categoryId, options);
    },
    [FORMATS_ERROR](state, categoryId) {
        if (state.lastRequestedCategoryId === categoryId) {
            state.lastRequestedCategoryId = null;
        }
    },
    [COMPANY_DATA_SUCCESS](state, companyData) {
        state.companyData = companyData;
    },
    [COMPANY_DATA_ERROR](state) {
        state.companyData = {};
    },
};

const getters = {
    yearOptions() {
        return dateUtil.createYearOptions({ from: 2013 });
    },
    monthOptions() {
        return dateUtil.createMonthOptions();
    },
    transactionStatusOptions() {
        return [
            {
                value: TransactionStatus.NEW,
                text: translateTransactionStatus(TransactionStatus.NEW),
            },
            {
                value: TransactionStatus.ACCEPTED,
                text: translateTransactionStatus(TransactionStatus.ACCEPTED),
            },
            {
                value: TransactionStatus.REJECTED,
                text: translateTransactionStatus(TransactionStatus.REJECTED),
            },
        ];
    },
    adminTransactionStatusOptions(state, getters) {
        return [
            ...getters.transactionStatusOptions,
            {
                value: TransactionStatus.ABANDONED,
                text: translateTransactionStatus(TransactionStatus.ABANDONED),
            },
        ];
    },
    shopStyleOptions() {
        return [
            {
                value: 'icon_name',
                text: i18n.t('shopStyle.icon_name'),
            },
            {
                value: 'logo',
                text: i18n.t('shopStyle.logo'),
            },
        ];
    },
    formatOptions: state => categoryId => state.categoriesFormats[categoryId],
    companyData: state => state.companyData,
};

const actions = {
    async fetchFormatsOfCategory({ commit, state, getters }, { categoryId }) {
        if (getters.formatOptions(categoryId) || state.lastRequestedCategoryId === categoryId) {
            return;
        }
        commit(FORMATS_REQUEST);
        try {
            const formats = await categoriesService.fetchCategoryFormats(categoryId);
            const options = formats.map(format => ({
                value: format.id,
                text: format.categoryName,
            }));
            commit(FORMATS_SUCCESS, { categoryId, options });
        } catch (e) {
            commit(FORMATS_ERROR);
        }
    },

    async fetchCompanyData({ commit }) {
        try {
            const companyData = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/system-owner`);
            commit('COMPANY_DATA_SUCCESS', companyData.data);
        } catch (e) {
            commit('COMPANY_DATA_ERROR');
        }
    },
};

export default {
    namespaced: true,
    state: getInitialState(),
    getters,
    actions,
    mutations,
};
