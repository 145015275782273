<template>
    <Card :header="$t('filters')">
        <b-form-group :label="$t('space')" label-cols-sm="3">
            <MultiselectSpaces
                :value="parentComponent.filters.spaces"
                :multiple="false"
                :show-checkboxes="false"
                :clear-on-select="false"
                :close-on-select="true"
                :preserve-search="true"
                :placeholder="$t('selectField.placeholder')"
                :disabled="parentComponent.disabled"
                @loading="value => (isSpacesLoading = value)"
                @inputTrackBy="parentComponent.filters.spaces = $event"
            />
        </b-form-group>

        <b-form-group :label="$t('campaign')" label-cols-sm="3">
            <Multiselect
                :value="parentComponent.filters.campaigns"
                :fetch-options-function="fetchCampaigns"
                :parse-options-function="parseCampaigns"
                :placeholder="$t('selectField.placeholder')"
                :searchable="true"
                track-by="id"
                label="name"
                select-label=""
                :disabled="parentComponent.disabled"
                @loading="value => (isCampaignsLoading = value)"
                @inputTrackBy="parentComponent.filters.campaigns = $event"
            />
        </b-form-group>

        <b-form-group :label="$t('transactions.dateRange')" label-cols-sm="3">
            <FormDatepicker
                :value="[parentComponent.filters.from, parentComponent.filters.to]"
                :placeholder="$t('all')"
                :config="{ mode: 'range' }"
                :disabled="parentComponent.disabled"
                @from="parentComponent.filters.from = $event"
                @to="parentComponent.filters.to = $event"
            />
        </b-form-group>

        <b-form-group :label="$t('transactions.financeDocument')" label-cols-sm="3">
            <b-form-input v-model.number="parentComponent.filters.financeDocument" :disabled="parentComponent.disabled" />
        </b-form-group>

        <b-form-group :label="$t('status')" label-cols-sm="3">
            <b-form-checkbox-group v-model="parentComponent.filters.statuses" :options="transactionStatusOptions" :disabled="parentComponent.disabled" class="h-100 d-flex align-items-center" />
        </b-form-group>

        <b-form-group :label="$t('transId')" label-cols-sm="3">
            <b-form-input v-model="parentComponent.filters.transId" :disabled="parentComponent.disabled" />
        </b-form-group>

        <b-form-group :label="$t('id')" label-cols-sm="3">
            <b-form-input v-model="parentComponent.filters.id" :disabled="parentComponent.disabled" />
        </b-form-group>

        <b-form-group>
            {{ $t('transactions.count') }}:
            <b-spinner v-if="parentComponent.loading" variant="primary" small class="ml-1" />
            <span v-else class="text-primary">{{ $format.number(transactions.max) }}</span>
        </b-form-group>

        <ButtonIcon :disabled="parentComponent.disabled || isSpacesLoading || isCampaignsLoading" icon="filter" @click="parentComponent.filterData()">
            {{ $t('transactions.showTransactions') }}
        </ButtonIcon>

        <JsonToCsv
            class="ml-3"
            :items="exportData"
            :fields="exportFields"
            :labels="exportLabels"
            :name="exportFilename"
            :disabled="parentComponent.isItemsBusy"
        >
            {{ $t('transactions.exportTransactions') }}
        </JsonToCsv>
    </Card>
</template>

<script>
    import sortBy from 'lodash/sortBy';
    import transactionsService from '@/services/transactionsService';
    import spaceService from '@/services/spaceService';
    import Multiselect from '@/components/common/Multiselect.vue';
    import MultiselectSpaces from '@/components/common/MultiselectSpaces.vue';
    import FormDatepicker from '@/components/common/FormDatepicker.vue';
    import JsonToCsv from '@/components/common/JsonToCsv.vue';
    import { translateTransactionStatus } from '@/services/transactionService';

    export default {
        name: 'Filters',
        components: {
            Multiselect,
            MultiselectSpaces,
            FormDatepicker,
            JsonToCsv,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                isCampaignsLoading: false,
                isSpacesLoading: false,
            };
        },
        computed: {
            fields() {
                return [
                    { key: 'id', label: 'ID' },
                    { key: 'createdAt', label: this.$t('date') },
                    { key: 'transId', label: this.$t('identifier') },
                    { key: 'statusText', label: this.$t('status') },
                    { key: 'campaign.name', label: this.$t('campaign') },
                    { key: 'space.name', label: this.$t('space') },
                    { key: 'amount', label: this.$t('transactions.transactionAmount') },
                    { key: 'publisherCommission', label: `${this.$t('commission')} ${this.$t('transactions.commissions.publisher')}` },
                    { key: 'adminCommission', label: `${this.$t('commission')} ${this.$t('transactions.commissions.admin')}` },
                    { key: 'advertiserCommission', label: `${this.$t('commission')} ${this.$t('transactions.commissions.advertiser')}` },
                    { key: 'conversionTime', label: this.$t('conversionTime') },
                ];
            },
            transactionStatusOptions() {
                return this.$store.getters['options/transactionStatusOptions'];
            },
            transactions() {
                return this.$store.getters['transactions/getUserTransactions'];
            },
            exportFields() {
                return this.fields.map(item => item.key);
            },
            exportLabels() {
                return Object.fromEntries(this.fields.map(item => [item.key, item.label]));
            },
            exportFilename() {
                const from = this.parentComponent.filters.from ? `-from-${this.$format.date(this.parentComponent.filters.from)}` : '';
                const to = this.parentComponent.filters.to ? `-to-${this.$format.date(this.parentComponent.filters.to)}` : '';
                return `transactions${from}${to}.csv`;
            },
        },
        methods: {
            fetchCampaigns() {
                return spaceService.fetchCampaigns({
                    page: 1,
                    perPage: Number.MAX_SAFE_INTEGER,
                });
            },
            parseCampaigns(data) {
                return sortBy(data?.items || [], [item => item.name.toLowerCase()], ['asc']);
            },
            exportData() {
                const params = {
                    requestParams: {
                        ...this.parentComponent.requestParams,
                        sort: 'createdAt',
                        order: 'DESC',
                        page: 1,
                        perPage: Number.MAX_SAFE_INTEGER,
                        omitItems: 1,
                    },
                };
                return transactionsService
                    .getUserTransactions(params)
                    .then(data => this.formatDataItems(data.items))
                    .catch(error => this.$toastr.error(error.message));
            },
            formatDataItems(items) {
                return (
                    items?.map(item => ({
                        ...item,
                        createdAt: this.$format.dateAndHour(item.createdAt),
                        statusText: translateTransactionStatus(item.status),
                        publisherCommission: this.$format.currency(item.publisherCommission),
                        adminCommission: this.$format.currency(item.adminCommission),
                        advertiserCommission: this.$format.currency(item.advertiserCommission),
                        amount: this.$format.currency(item.amount),
                        conversionTime: this.$format.seconds(item.conversionTime),
                    })) || []
                );
            },
        },
    };
</script>
