<template>
    <ButtonIcon
        v-if="button"
        :id="idName"
        :loading="loading"
        :disabled="loading || $attrs.disabled"
        :icon="icon"
        :variant="variant"
        v-bind="$attrs"
        @click="prepareFile()"
    >
        <slot>{{ $t('download') }} {{ name }}</slot>
    </ButtonIcon>
    <component :is="tag" v-else :id="idName" @click="prepareFile">
        <slot>{{ $t('download') }} {{ name }}</slot>
    </component>
</template>



<script>
    import JsonCSV from 'vue-json-csv';
    import { cloneDeep, pickBy, mapKeys } from 'lodash';
    import flat from 'flat';
    import toastService from '@/services/toastService';
    import ButtonIcon from '@/components/common/ButtonIcon.vue';

    const JsonCSVComponent = cloneDeep(JsonCSV);
    delete JsonCSVComponent.props.data;

    const isType = (value, type) => typeof value === type; // eslint-disable-line

    export default {
        name: 'JsonToCsv',
        extends: JsonCSVComponent,
        components: {
            ButtonIcon,
        },
        props: {
            items: {
                type: [Array, Function],
                required: true,
            },
            delimiter: {
                type: String,
                default: ';',
            },
            tag: {
                type: String,
                default: 'button',
            },
            button: {
                type: Boolean,
                default: true,
            },
            icon: {
                type: String,
                default: 'download',
            },
            variant: {
                type: String,
                default: 'outline-primary',
            },
        },
        data() {
            return {
                data: [],
                loading: false,
            };
        },
        watch: {
            items: {
                immediate: true,
                handler() {
                    if (Array.isArray(this.items)) this.data = this.items;
                },
            },
            loading() {
                this.$emit('loading', this.loading);
            },
        },
        methods: {
            async prepareFile() {
                if (typeof this.items === 'function') {
                    this.loading = true;
                    this.data = await this.items();
                    this.loading = false;
                }
                if (!this.exportableData) {
                    toastService.successToast(this.$t('noDataToExport'), { variant: 'danger' });
                    return null;
                }
                return this.generate();
            },
            labelsFunctionGenerator() {
                if (!isType(this.labels, 'undefined') && !isType(this.labels, 'function') && !isType(this.labels, 'object')) {
                    throw new Error('Labels needs to be a function(value,key) or object.');
                }

                if (isType(this.labels, 'function')) return item => mapKeys(item, this.labels);

                if (isType(this.labels, 'object')) return item => mapKeys(item, (it, key) => this.labels[key] || key);

                return item => item;
            },
            fieldsFunctionGenerator() {
                if (!isType(this.fields, 'undefined') && !isType(this.fields, 'function') && !isType(this.fields, 'object') && !Array.isArray(this.fields)) {
                    throw new Error('Fields needs to be a function(value,key) or array.');
                }

                if (isType(this.fields, 'function') || (isType(this.fields, 'object') && !Array.isArray(this.fields))) return item => pickBy(item, this.fields);

                if (Array.isArray(this.fields)) return item => Object.fromEntries(this.fields.map(field => [field, flat(item)[field]]));

                return item => item;
            },
            cleaningData() {
                if (isType(this.fields, 'undefined') && isType(this.labels, 'undefined')) {
                    return this.data;
                }

                const fields = this.fieldsFunctionGenerator();
                const labels = this.labelsFunctionGenerator();

                return this.data.map(item => labels(flat(fields(item))));
            },
        },
    };
</script>
