<template>
    <div>
        <page-header>{{ $t('accountSettings.title') }}</page-header>

        <error-message v-model="accountError" />

        <page-loading v-if="accountLoading" />

        <div v-if="accountReady" class="row">
            <div class="col-xl-6 d-flex">
                <settings-personal class="flex-grow-1" />
            </div>

            <div class="col-xl-6 d-flex">
                <settings-address class="flex-grow-1" />
            </div>

            <div class="col-xl-6 d-flex">
                <settings-password class="flex-grow-1" />
            </div>

            <div class="col-xl-6 d-flex">
                <settings-notifications class="flex-grow-1" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import PageHeader from '@/components/common/PageHeader.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import PageLoading from '@/components/common/PageLoading.vue';
    import SettingsPersonal from './parts/SettingsPersonal.vue';
    import SettingsAddress from './parts/SettingsAddress.vue';
    import SettingsPassword from './parts/SettingsPassword.vue';
    import SettingsNotifications from './parts/SettingsNotifications.vue';

    export default {
        name: 'WidgeterAccountSettings',
        components: {
            SettingsNotifications,
            SettingsPassword,
            SettingsAddress,
            SettingsPersonal,
            PageLoading,
            ErrorMessage,
            PageHeader,
        },
        data() {
            return {
                accountError: null,
            };
        },
        computed: {
            accountReady() {
                return !this.accountError && !this.accountLoading;
            },
            ...mapState({
                accountLoading: state => state.auth.loading.fetchUserFull,
            }),
        },
        methods: {
            async getAccount() {
                try {
                    this.accountError = null;
                    await this.fetchUserFull();
                } catch (e) {
                    this.accountError = e.message;
                }
            },

            ...mapActions({
                fetchUserFull: 'auth/fetchUserFull',
            }),
        },
        created() {
            this.getAccount();
        },
    };
</script>
