<template>
    <b-modal
        :id="`modal-form-${modalId}`"
        ref="modal"
        :title="modalTitle"
        size="lg"
        cancel-variant="light"
        ok-variant="primary"
        :cancel-title="$t('cancel')"
        :ok-title="$t('save')"
        :busy="submitting"
        static
        @ok.prevent="handleSubmit()"
        @show="resetData()"
        @hidden="resetData()"
    >
        <b-overlay :show="submitting" opacity="0" class="h-100">
            <ErrorMessage :value="error" />
            <b-form @submit.prevent>
                <FormValidator :validator="$v.form.duplicates" form-group v-slot="slotProps" :label="$t('products')">
                    <Multiselect
                        v-model="form.duplicates"
                        :fetch-options-function="fetchProducts"
                        :parse-options-function="parseProducts"
                        :placeholder="$t('selectField.placeholder')"
                        asynchronous
                        searchable
                        show-selected
                        emit-initial-value
                        multiple
                        show-checkboxes
                        :clear-on-select="false"
                        :close-on-select="false"
                        track-by="id"
                        label="name"
                        select-label=""
                        :disabled="submitting"
                        :state="slotProps.state"
                        @loading="value => isProductsLoading = value"
                        @search-change="value => productsSearchText = value"
                    />
                </FormValidator>
            </b-form>
        </b-overlay>
    </b-modal>
</template>



<script>
    import { required } from 'vuelidate/lib/validators';
    import ModalFormMixin from '@/mixins/modalFormMixin';
    import catalogService from '@/services/catalogService';
    import Multiselect from '@/components/common/Multiselect.vue';
    import FormValidator from '@/components/common/FormValidator.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'ModalDuplicates',
        mixins: [ModalFormMixin],
        components: {
            Multiselect,
            FormValidator,
            ErrorMessage,
        },
        data() {
            return {
                modalId: 'catalog-products-duplicates',
                form: {
                    duplicates: null,
                },
                isProductsLoading: false,
                productsSearchText: '',
            };
        },
        validations() {
            return {
                form: {
                    duplicates: { required },
                },
            };
        },
        computed: {
            action() {
                return 'catalog/postDuplicates';
            },
            successMessage() {
                return this.$t('variantsHaveBeenAssigned');
            },
            errorMessage() {
                return this.$t('createError');
            },
            modalTitle() {
                return this.$t('youAssignVariantsToTheProduct', { name: this.nameOfEditItem });
            },
        },
        methods: {
            fetchProducts() {
                const requestParams = {
                    sort: 'name',
                    order: 'ASC',
                    page: 1,
                    perPage: Number.MAX_SAFE_INTEGER,
                    name: this.productsSearchText,
                };
                return catalogService.getProducts({ requestParams });
            },
            parseProducts(data) {
                return data?.items || [];
            },
            getDataStructure(data) {
                return {
                    ...data,
                    duplicates: (data.duplicates || []).map(item => item.id).join(','),
                };
            },
            afterSuccess() {
                this.$root.$emit('fetch-data-catalog-products');
            },
        },
    };
</script>
