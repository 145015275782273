let authData = null;
const appleAuth = {
    init(config) {
        if (config === undefined) {
            console.error('Undefined config for Apple API! Initialized failed!');
            return;
        }
        const el = document.getElementById('apple_auth_script');
        const createScript = () => {
            const providerScript = document.createElement('script');
            providerScript.addEventListener('load', window.AppleAsyncInit);
            providerScript.setAttribute('src', 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js');
            providerScript.setAttribute('async', true);
            providerScript.setAttribute('defer', 'defer');
            providerScript.setAttribute('id', 'apple_auth_script');
            document.head.appendChild(providerScript);
        };
        window.AppleAsyncInit = () => {
            window.AppleID.auth.init(config);
        };
        if (!el) createScript();
    },
    signIn() {
        return new Promise((resolve, reject) => {
            window.AppleID.auth
                .signIn()
                .then(response => {
                    authData = response;
                    resolve(response);
                })
                .catch(error => {
                    authData = null;
                    reject(error);
                });
        });
    },
    signOut() {
        return new Promise((resolve, reject) => {
            // window.AppleID.auth.signOut().then(resolve).catch(reject);
            reject(new Error("Apple's API does not have a method for logging out."));
        });
    },
    isSignedIn() {
        return !!authData?.authorization?.id_token;
    },
    getCurrentUser() {
        return authData;
    },
};

export default appleAuth;
