<template>
    <Card :header="$t('statByDay.title')">
        <ErrorMessage :value="error" />
        <b-table
            hover
            :items="items"
            :fields="fields"
            show-empty
            :empty-text="$t('noResult')"
            :busy="loading"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
        >
            <template #table-busy>
                <div class="d-flex justify-content-center my-2">
                    <b-spinner />
                </div>
            </template>
            <template #head(clicks)>
                {{ $t('clicks') }} <br>
                ({{ $t('lastXDays', { days: 30 }) }})
            </template>
            <template #head(amount)>
                {{ $t('sales') }} <br>
                ({{ $t('lastXDays', { days: 30 }) }})
            </template>
            <template #cell(name)="data">
                <b-link :href="data.item.url" target="_blank">
                    {{ data.item.url }}
                </b-link>
            </template>
            <template #cell(categories)="data">
                <ul class="m-0 p-0">
                    <li v-for="category in data.value" :key="category.id">
                        {{ category.name }}
                    </li>
                </ul>
            </template>
            <template #cell(type)="data">
                {{ translateSpaceTypes(data.value) }}
            </template>
            <template #cell(status)="data">
                <CampaignSpaceStatusBadge :status="data.value" />
            </template>
            <template #cell(action)="data">
                <template v-if="data.item.status === CampaignStatus.ACCEPTED">
                    <ButtonIcon size="sm" variant="danger" :loading="isItemBusy(data.item.id)" :hide-label-while-loading="true" @click="campaignSpaceAction(data.item.id, 'reject')">
                        {{ $t('reject') }}
                    </ButtonIcon>
                </template>
                <template v-if="data.item.status === CampaignStatus.REJECTED">
                    <ButtonIcon size="sm" variant="success" :loading="isItemBusy(data.item.id)" :hide-label-while-loading="true" @click="campaignSpaceAction(data.item.id, 'accept')">
                        {{ $t('accept') }}
                    </ButtonIcon>
                </template>
            </template>
        </b-table>
        <Pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" />
    </Card>
</template>



<script>
    import Pagination from '@/components/common/Pagination.vue';
    import CampaignSpaceStatusBadge from '@/components/common/CampaignSpaceStatusBadge.vue';
    import { CampaignStatus } from '@/services/spaceService';
    import campaignsService from '@/services/campaignsService';
    import toastService from '@/services/toastService';

    export default {
        name: 'Results',
        components: {
            Pagination,
            CampaignSpaceStatusBadge,
        },
        data() {
            return {
                fields: [
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true,
                    },
                    {
                        key: 'name',
                        label: this.$t('space'),
                        sortable: true,
                    },
                    {
                        key: 'categories',
                        label: this.$t('category'),
                    },
                    {
                        key: 'type',
                        label: this.$t('type'),
                        sortable: true,
                    },
                    {
                        key: 'clicks',
                        label: this.$t('clicksLastXDays', { days: 30 }),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'amount',
                        label: this.$t('salesLastXDays', { days: 30 }),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'status',
                        label: this.$t('status'),
                        sortable: true,
                    },
                    {
                        key: 'action',
                        label: '',
                    },
                ],
                perPage: 50,
                currentPage: 1,
                sortBy: 'amount',
                sortDesc: true,
                CampaignStatus,
                busyItems: [],
            };
        },
        computed: {
            campaignId() {
                return this.$store.getters['campaigns/getCurrentCampaign']?.id;
            },
            items() {
                return this.$store.getters['campaigns/getSpaces'];
            },
            error() {
                return this.$store.getters['campaigns/getError']('spaces');
            },
            loading() {
                return this.$store.getters['campaigns/getLoading']('spaces');
            },
            isItemBusy() {
                return id => !!this.busyItems.find(itemId => itemId === id);
            },
            spaceTypes() {
                return this.$store.state.space.spaceTypes;
            },
        },
        methods: {
            translateSpaceTypes(spaceId) {
                return this.spaceTypes?.[spaceId]?.label;
            },
            async campaignSpaceAction(spaceId, action) {
                this.addBusyItem(spaceId);
                try {
                    await campaignsService.putSpacesAction({ urlParams: { campaignId: this.campaignId, spaceId, action } });
                    const currentItem = this.items.find(item => item.id === spaceId);
                    let newStatus = currentItem.status;
                    if (action === 'reject') newStatus = CampaignStatus.REJECTED;
                    if (action === 'accept') newStatus = CampaignStatus.ACCEPTED;
                    currentItem.status = newStatus;
                    this.updateItems(currentItem);
                } catch (error) {
                    toastService.errorToast(error.message);
                }
                this.removeBusyItem(spaceId);
            },
            addBusyItem(id) {
                this.busyItems.push(id);
            },
            removeBusyItem(id) {
                const newSet = new Set(this.busyItems);
                newSet.delete(id);
                this.busyItems = Array.from(newSet);
            },
            updateItems(newItem) {
                const index = this.items.findIndex(item => item.id === newItem.id);
                if (index !== -1) {
                    const items = [...this.items];
                    this.$set(items, index, newItem);
                    this.$store.commit('campaigns/setSpaces', items);
                }
            },
            fetchSpaceTypes() {
                return this.$store.dispatch('space/fetchSpaceTypes');
            },
        },
        created() {
            this.fetchSpaceTypes();
        },
    };
</script>
