<template>
    <MultiChartCard
        class="mb-4"
        :data="chart"
        :height="300"
        :loading="loading"
        :options="chartOptions"
        :title="$t('dashboard.overview')"
        :range="timeRange.groupBy"
        @currentChart="setCurrentChart"
    />
</template>



<script>
    import { generateChartData } from '@/utils/chart';
    import { stringToColour } from '@/utils/util';
    import MultiChartCard from '@/components/common/MultiChartCard.vue';

    export default {
        name: 'Chart',
        components: {
            MultiChartCard,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                currentChart: 'clicks',
            };
        },
        computed: {
            isRoleWidgeter() {
                return this.$store.getters['auth/isRoleWidgeter'];
            },
            isRoleAdvertiser() {
                return this.$store.getters['auth/isRoleAdvertiser'];
            },
            isRoleAdmin() {
                return this.$store.getters['auth/isRoleAdmin'];
            },
            timeRange() {
                const { startDate, endDate, groupBy = 'day' } = this.parentComponent.filtersParams;
                if (!startDate || !endDate) return {};
                return { startDate, endDate, groupBy };
            },
            items() {
                return this.parentComponent.items?.reduce(
                    (acc, item) => [
                        ...acc,
                        {
                            ...item,
                            commission: Number.parseFloat((item.newCommission + item.acceptedCommission).toFixed(2)),
                            publisherCommission: Number.parseFloat((item.publisherNewCommission + item.publisherAcceptedCommission).toFixed(2)),
                        },
                    ],
                    []
                );
            },
            loading() {
                return this.parentComponent.loading;
            },
            chartSeries() {
                const data = {};
                this.items.forEach(item => {
                    const keys = Object.keys(item);
                    keys.forEach(key => {
                        if (key !== 'date') data[key] = [...(data[key] || []), [item.date, item[key]]];
                    });
                });
                return data;
            },
            chart() {
                if (this.isRoleAdmin) {
                    return {
                        clicks: this.getChartItem('clicks', this.$t('stat.clicks'), Math.round, 0),
                        transactions: this.getChartItem('transactions', this.$t('stat.transactions'), Math.round, 0),
                        publisherCommission: this.getChartItem('publisherCommission', this.$t('stat.publisherCommission'), this.$format.currency, 2),
                        commission: this.getChartItem('commission', this.$t('stat.ourCommission'), this.$format.currency, 2),
                    };
                }
                return {
                    clicks: this.getChartItem('clicks', this.$t('stat.clicks'), Math.round, 0),
                    transactions: this.getChartItem('transactions', this.$t('stat.transactions'), Math.round, 0),
                    commission: this.getChartItem('commission', this.$t('stat.commissions'), this.$format.currency, 2),
                };
            },
            chartCurrentColor() {
                return this.chart[this.currentChart].color;
            },
            chartOptions() {
                return {
                    chart: {
                        type: 'bar',
                    },
                    markers: { size: 0 },
                    colors: [this.chartCurrentColor],
                };
            },
        },
        methods: {
            setCurrentChart(currentChart) {
                this.currentChart = currentChart;
            },
            getChartItem(key, label, formatter = i => i, toFixedValue = 0) {
                return {
                    series: [{ data: generateChartData(this.timeRange, this.chartSeries[key], this.timeRange.groupBy), name: label }],
                    name: label,
                    color: stringToColour(key),
                    options: {
                        tooltip: { y: { formatter: value => formatter(value) } },
                        yaxis: { title: { text: '' }, labels: { formatter: value => (value === Infinity ? 1 : value).toFixed(toFixedValue) } },
                    },
                };
            },
        },
    };
</script>
