<template>
    <div>
        <PageHeader>
            {{ $t('widgeterSettingsIntegrations.title') }}
        </PageHeader>
        <div class="item-list">
            <div
                v-for="item in items"
                :key="item.id"
                class="item"
            >
                <IntegrationsItem :item="item" />
            </div>
        </div>
    </div>
</template>



<script>
    import PageHeader from '@/components/common/PageHeader.vue';
    import IntegrationsItem from './parts/IntegrationsItem.vue';
    import WordpressLogo from '@/assets/images/wordpress-logo.png';
    import WebhookLogo from '@/assets/images/webhook-logo.png';

    export default {
        name: 'WidgeterSettingsIntegrations',
        components: {
            PageHeader,
            IntegrationsItem,
        },
        data() {
            return {
                items: [
                    {
                        id: 1,
                        title: this.$t('widgeterSettingsIntegrations.wordpress.title'),
                        description: this.$t('widgeterSettingsIntegrations.wordpress.description'),
                        image: WordpressLogo,
                        url: this.$t('widgeterSettingsIntegrations.wordpress.url'),
                        target: '_blank',
                        buttonLabel: this.$t('widgeterSettingsIntegrations.wordpress.buttonLabel'),
                    },
                    {
                        id: 2,
                        title: this.$t('widgeterSettingsIntegrations.webhooks.title'),
                        description: this.$t('widgeterSettingsIntegrations.webhooks.description'),
                        image: WebhookLogo,
                        url: this.$t('widgeterSettingsIntegrations.webhooks.url'),
                        target: '_self',
                        buttonLabel: this.$t('widgeterSettingsIntegrations.webhooks.buttonLabel'),
                    },
                ],
            };
        },
    };
</script>



<style lang="scss" scoped>
    @import 'bootstrap/scss/functions';
    @import 'bootstrap/scss/variables';
    @import '@/design/_variables.scss';

    .item-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: $grid-gutter-width;
    }
</style>
