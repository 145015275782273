<template>
    <div>
        <error-message v-if="isResponseLimited" :dismissible="false" :value="$t('requestLimitedForPerformanceReasons')" />
        <DataTable
            v-model="selectedRows"
            :header="$t('searchResultsCount', { count: offers.max || 0 })"
            :error.sync="error"
            :items="items"
            :fields="fields"
            :total-rows="offers.max"
            :per-page.sync="tableParams.perPage"
            :current-page.sync="tableParams.page"
            :loading="loading"
            :busy-items="busyItems"
            no-sort-reset
            no-local-sorting
            selectable
            selectable-id-key="id"
            :per-page-values="[50, 100, 500, 1000]"
        >

            <template #cell(product)="data">
                <div class="d-flex flex-column gap-2">
                    <b-link v-if="data.item.product" :to="{ query: { product: data.item.product } }">
                        {{ data.item.product }}
                    </b-link>
                    <div class="info text-secondary font-size-12">
                        {{ data.item.offerId }}
                    </div>
                </div>
            </template>

            <template #cell(ean)="data">
                <b-link :to="{ query: { ean: data.item.ean } }">
                    {{ data.item.ean }}
                </b-link>
            </template>

            <template #cell(name)="data">
                <div class="d-flex">
                    <ImageLoader
                        :src="data.item.image"
                        alt=""
                        size="100"
                        image-height="auto"
                        class="mr-2 flex-shrink-0"
                    />
                    <div class="d-flex flex-column gap-2">
                        <b-link class="title" :to="createItemUrl(data.item)">
                            {{ data.item.name }}
                        </b-link>
                        <div class="info text-secondary font-size-12">
                            {{ data.item.categories.join(',') }}
                        </div>
                    </div>
                </div>
            </template>

            <template #cell(feed)="data">
                <div class="d-flex flex-column gap-2">
                    {{ data.item.feed }}
                    <div v-if="data.item.campaign" class="info">
                        {{ data.item.campaign.name }}
                    </div>
                </div>
            </template>

            <template #cell(price)="data">
                <div class="nowrap">
                    {{ $format.currency(data.item.price) }}
                </div>
            </template>

            <template #cell(type)="data">
                {{ getTypeLabel(data.item.type) }}<br>
                <template v-if="data.item.format">{{ data.item.format }}</template>
            </template>

            <template #cell(hidden)="data">
                <BadgeBoolean :value="data.item.hidden" true-value-badge-variant="danger" false-value-badge-variant="success" />
            </template>

            <template #cell(active)="data">
                <div class="d-flex flex-column gap-2">
                    <div><BadgeBoolean :value="data.item.active" :true-value-text="$t('available2')" :false-value-text="$t('unavailable2')" /></div>
                    <div class="info text-secondary font-size-12">
                        {{ $format.dateAndHour(data.item.lastAvailabilityChangedAt, 'yyyy-MM-dd') }}
                    </div>
                </div>
            </template>

            <template #afterTable>
                <div class="d-flex gap-3 align-items-center mt-3">
                    <TableAction :ids="selectedRows" @status="statusHandler" />
                </div>
            </template>

        </DataTable>
    </div>
</template>



<script>
    import slugify from 'slugify';
    import storageKeys from '@/Storage';
    import DataTable from '@/components/common/DataTable.vue';
    import ImageLoader from '@/components/common/ImageLoader.vue';
    import BadgeBoolean from '@/components/common/BadgeBoolean.vue';
    import TableAction from './ResultsTableAction.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'Results',
        components: {
            DataTable,
            ImageLoader,
            BadgeBoolean,
            TableAction,
            ErrorMessage,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                fields: [
                    {
                        key: 'product',
                        label: this.$t('productOfferId'),
                    },
                    {
                        key: 'ean',
                        label: this.$t('ean'),
                    },
                    {
                        key: 'name',
                        label: this.$t('name'),
                    },
                    {
                        key: 'info',
                        label: this.$t('info'),
                    },
                    {
                        key: 'code',
                        label: this.$t('code'),
                    },
                    {
                        key: 'feed',
                        label: this.$t('feedShop'),
                    },
                    {
                        key: 'type',
                        label: this.$t('offerType'),
                    },
                    {
                        key: 'price',
                        label: this.$t('price'),
                    },
                    {
                        key: 'hidden',
                        label: this.$t('hidden'),
                    },
                    {
                        key: 'active',
                        label: this.$t('availability'),
                    },
                ],
                selectedRows: [],
                busyItems: [],
                error: null,
            };
        },
        computed: {
            isResponseLimited() {
                return this.offers.limited;
            },
            typesAndFormats() {
                return this.$store.getters['offers/getTypesAndFormats'];
            },
            filters() {
                return this.parentComponent.filters;
            },
            tableParams() {
                return this.parentComponent.tableParams;
            },
            filtersParams() {
                return this.parentComponent.currentFilters;
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                    ...this.tableParams,
                };
            },
            offers() {
                return this.$store.getters['offers/getOffer'] || {};
            },
            items() {
                return (this.offers.items || []).map(item => ({
                    ...item,
                    name: item.name || '',
                    image: item.image || '',
                }));
            },
            loading() {
                return this.$store.getters['offers/getLoading']('offer');
            },
            isItemBusy() {
                return id => !!this.busyItems.find(itemId => itemId === id);
            },
            isItemsBusy() {
                return !!this.busyItems.length;
            },
        },
        watch: {
            tableParams: {
                deep: true,
                handler: 'fetchData',
            },
            selectedRows() {
                if (this.selectedRows.length) this.$toastr.close('noItemsSelected');
            },
            '$route.query': {
                immediate: true,
                handler: 'initFiltersAndFetchData',
            },
        },
        methods: {
            parseValue(val) {
                if (/^\d+$/.test(val)) return Number.parseInt(val);
                if (val === 'false') return false;
                if (val === 'true') return true;
                return val;
            },
            initFiltersAndFetchData() {
                this.parentComponent.resetFilters();
                Object.keys(this.$route.query).forEach(key => {
                    this.$set(this.parentComponent.filters, key, this.parseValue(this.$route.query[key]));
                });
                if (Object.keys(this.filtersParams).length) {
                    this.fetchData();
                } else {
                    this.clearStoreData();
                }
            },
            fetchData() {
                this.error = null;
                this.$store.dispatch('offers/fetchOffer', { requestParams: this.requestParams }).catch(error => (this.error = error));
            },
            clearStoreData() {
                this.$store.commit('offers/clearOffer');
            },
            createItemUrl(item) {
                const locale = window.localStorage.getItem(storageKeys.LOCALE) || 'en';
                const id = item.id;
                const slug = slugify(item.name, { replacement: '-', lower: true, locale });
                return {
                    name: 'OffersDetails',
                    params: { id, slug, sep: '-', parentUrl: this.$route.fullPath },
                    query: {},
                };
            },
            getTypeLabel(type) {
                return this.typesAndFormats.find(item => item.value === type)?.label || type;
            },
            statusHandler(status, data) {
                if (status === 'pending') {
                    this.busyItems = [...this.selectedRows];
                }
                if (status === 'success') {
                    this.busyItems = [];
                    this.selectedRows = [];
                    const offers = {
                        ...this.offers,
                        items: this.items.map(item => {
                            const newItem = data?.items?.find(dataItem => dataItem.id === item.id);
                            return newItem || item;
                        }),
                    };
                    this.$store.commit('offers/setOffer', offers);
                }
                if (status === 'error') {
                    this.busyItems = [];
                }
            },
        },
        created() {
            this.clearStoreData();
        },
        destroyed() {
            this.clearStoreData();
        },
    };
</script>
