import { isAdminRole } from '@/utils/util';

export const checkFeatureFlag = (featureName, userRole, userId) => {
    const featuresConfig = process.env.VUE_APP_FEATURES_CONFIG;
    let config;

    try {
        config = JSON.parse(featuresConfig.replace(/\\/g, ''));
    } catch (error) {
        console.error(error);
        return false;
    }

    const feature = config[featureName];

    if (feature === undefined) {
        return false;
    }

    if (feature.length === 0) {
        return true;
    }

    return feature.includes(userId) || isAdminRole(userRole);
};
