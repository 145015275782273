<template>
    <ul class="list-unstyled">
        <slot name="default"></slot>
    </ul>
</template>



<script>
    export default {
        name: 'SkeletonList',
        props: {
            loading: {
                type: Boolean,
                default: undefined,
            },
        },
    };
</script>
