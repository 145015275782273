import { i18n } from '@/i18n/i18n';
import apiService, { deleteFromCache } from './apiService';
import errorService from './errorService';

export const widgetVersions = ['latest', '5.1', '5.0'];

export const translateWidgetVersion = version => {
    switch (version) {
        case 'latest':
            return i18n.t('widgetSettings.latestVersion');
        default:
            return version;
    }
};

export const SortTypeToWidgetSort = Object.freeze({
    1: 'ascending',
    2: 'descending',
    3: 'random',
});

export default {
    async fetchPlugins(spaceId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/plugins`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async deletePlugin(buyboxId) {
        try {
            const response = await apiService.delete(`${process.env.VUE_APP_API_URL}/api/v1/buyboxes/${buyboxId}`);
            deleteFromCache(['/plugins', '/producer-plugin']);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProducerPlugin(spaceId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/producer-plugin`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchActiveCampaigns(buyboxId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/buyboxes/${buyboxId}/campaigns`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAvailableCampaigns(buyboxId, v2, filters) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/${v2 ? 'v2' : 'v1'}/buyboxes/${buyboxId}/available-campaigns`, filters);

            if (v2 && response?.data?.items) {
                const campaigns = [];
                Object.keys(response.data.items).forEach(key => {
                    campaigns.push(response.data.items[key]);
                });
                response.data = campaigns;
            }

            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAvailableExtensions() {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/extensions`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSortTypes() {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/buybox-sort-types`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async updatePlugin(buyboxId, data) {
        try {
            const response = await apiService.patch(`${process.env.VUE_APP_API_URL}/api/v1/buyboxes/${buyboxId}`, data);
            deleteFromCache(['/plugins', '/producer-plugin']);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async createPlugin(spaceId, data) {
        try {
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/buyboxes`, data);
            deleteFromCache(['/plugins', '/producer-plugin']);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async createCampaign(buyboxId, campaignId) {
        try {
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/buyboxes/${buyboxId}/campaigns/${campaignId}`);
            deleteFromCache(['/campaigns', '/available-campaigns']);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async updateCampaign(buyboxId, campaignId, data) {
        try {
            const response = await apiService.patch(`${process.env.VUE_APP_API_URL}/api/v1/buyboxes/${buyboxId}/campaigns/${campaignId}`, data);
            deleteFromCache(['/campaigns', '/available-campaigns']);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async deleteCampaign(buyboxId, campaignId) {
        try {
            const response = await apiService.delete(`${process.env.VUE_APP_API_URL}/api/v1/buyboxes/${buyboxId}/campaigns/${campaignId}`);
            deleteFromCache(['/campaigns', '/available-campaigns']);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async searchProducts(publisherId, search) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v2/described-products`, {
                publisher: publisherId,
                search,
            });
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async matchProductBuyboxes(spaceId, productId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/matched-buyboxes`, {
                product: productId,
            });
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};
