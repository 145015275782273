<template>
    <Card>
        <p class="font-italic">{{ $t('searchForOffersRecommendation') }}</p>

        <form class="d-flex flex-column gap-3" @keyup.enter="search()">

            <div class="d-flex flex-wrap gap-3">
                <b-form-group :label="$t('name')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.name" />
                </b-form-group>
                <b-form-group :label="$t('eanNumber')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.ean" />
                </b-form-group>
            </div>

            <div class="d-flex flex-wrap gap-3">
                <b-form-group :label="$t('authorDirectorProducer')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.info" />
                </b-form-group>
                <b-form-group :label="$t('productId')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.product" />
                </b-form-group>
            </div>

            <div class="d-flex flex-wrap gap-3">
                <b-form-group :label="$t('producerCode')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.code" />
                </b-form-group>
                <b-form-group :label="$t('imageUrl')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.image" />
                </b-form-group>
            </div>

            <div class="d-flex flex-wrap gap-2">
                <b-form-group :label="$t('shop')" class="flex-grow-1 flex-basis-0">
                    <Multiselect
                        :fetch-options-function="fetchCampaigns"
                        :parse-options-function="parseCampaigns"
                        :placeholder="$t('selectField.placeholder')"
                        :searchable="true"
                        track-by="id"
                        label="name"
                        select-label=""
                        :value="filters.campaigns"
                        @input="value => filters.campaigns = value ? value.id : null"
                    />
                </b-form-group>
                <b-form-group :label="$t('feedId')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.feed" />
                </b-form-group>
            </div>

            <div class="d-flex flex-wrap gap-3">
                <b-form-group :label="$t('storeCategoryTemplate')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.categoryRegex" />
                </b-form-group>
            </div>

            <div class="d-flex flex-wrap gap-3">
                <b-form-group :label="$t('offerType')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-select
                        v-model="filters.type"
                        :options="filtersOptions.type"
                        text-field="label"
                        value-field="value"
                        add-null-option
                        @change="() => (parentComponent.filters.format = null)"
                    />
                </b-form-group>
                <b-form-group :label="$t('format2')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-select
                        v-model="filters.format"
                        :options="filtersOptions.format"
                        :disabled="!filters.type"
                        text-field="categoryName"
                        value-field="id"
                        add-null-option
                    />
                </b-form-group>
            </div>

            <div class="d-flex flex-wrap gap-3">
                <b-form-group :label="$t('hidden')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-select v-model="filters.hidden" :options="filtersOptions.hidden" add-null-option />
                </b-form-group>
                <b-form-group :label="$t('availability')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-select v-model="filters.active" :options="filtersOptions.active" add-null-option />
                </b-form-group>
            </div>

            <div class="d-flex gap-3">
                <ButtonIcon :disabled="loading" @click="search()">
                    {{ $t('search2') }}
                </ButtonIcon>
                <ButtonIcon variant="light" :disabled="loading" @click="reset()">
                    {{ $t('clear') }}
                </ButtonIcon>
            </div>

        </form>

    </Card>
</template>



<script>
    import mapValues from 'lodash/mapValues';
    import spaceService from '@/services/spaceService';
    import Multiselect from '@/components/common/Multiselect.vue';

    export default {
        name: 'Filters',
        components: {
            Multiselect,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {};
        },
        computed: {
            filters() {
                return this.parentComponent.filters;
            },
            loading() {
                return this.$store.getters['offers/getLoading']('offer');
            },
            typesAndFormats() {
                return this.$store.getters['offers/getTypesAndFormats'];
            },
            filtersOptions() {
                return {
                    type: this.typesAndFormats,
                    format: this.typesAndFormats.find(item => item.value === this.filters.type)?.formats,
                    hidden: [
                        { value: true, text: this.$t('yes') },
                        { value: false, text: this.$t('no') },
                    ],
                    active: [
                        { value: true, text: this.$t('available2') },
                        { value: false, text: this.$t('unavailable2') },
                    ],
                };
            },
        },
        methods: {
            search() {
                const filters = this.parentComponent.currentFilters;
                if (Object.keys(filters).length) {
                    if (JSON.stringify(filters) !== JSON.stringify(mapValues(this.$route.query, this.parentComponent.$refs.Results.parseValue))) {
                        this.$router.push({ query: filters }).catch(() => {});
                    } else {
                        this.parentComponent.$refs.Results.fetchData();
                    }
                } else {
                    this.reset();
                }
            },
            reset() {
                this.parentComponent.resetFilters();
                this.$router.push({ query: {} }).catch(() => {});
                this.parentComponent.$refs.Results.clearStoreData();
            },
            fetchCampaigns() {
                return spaceService.fetchCampaigns();
            },
            parseCampaigns(data) {
                const items = data?.items || [];
                if (items.length) return [{ id: null, name: this.$t('all') }, ...items];
                return [...items];
            },
        },
    };
</script>
