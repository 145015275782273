<template>
    <div>
        <PageHeader>
            {{ $t('reportsProducts.title') }}
            <template #side>
                <div class="d-flex">
                    <MultiSelectField
                        v-model="pages"
                        :lazy="true"
                        variant="select"
                        class="width-250 mb-0"
                        :options="pagesMultiselectFormat"
                        :placeholder="$t('yourPages')"
                        :loading="isSpacesLoading"
                        :disabled="isCampaignsLoading"
                        :show-checkboxes="true"
                    >
                        <template slot="selection" slot-scope="{ values, isOpen }">
                            <span v-if="values.length && !isOpen" class="multiselect__single">{{ $t('yourPages') }} ({{ values.length }})</span>
                        </template>
                    </MultiSelectField>

                    <div class="mx-2"></div>

                    <MultiSelectField
                        v-model="shops"
                        :lazy="true"
                        variant="select"
                        class="width-250 mb-0"
                        :options="shopsMultiselectFormat"
                        :placeholder="$t('shops')"
                        :loading="isCampaignsLoading"
                        :show-checkboxes="true"
                    >
                        <template slot="selection" slot-scope="{ values, isOpen }">
                            <span v-if="values.length && !isOpen" class="multiselect__single">{{ $t('shops') }} ({{ values.length }})</span>
                        </template>
                    </MultiSelectField>

                    <div class="mx-2"></div>

                    <TimeRangeDropdown v-model="timeRange" :disabled="isCampaignsLoading || isAggregationSalesStatsLoading" />
                </div>
            </template>
        </PageHeader>
        <div class="row">
            <div class="col-xl-12 mb-4">
                <ProductsList :space-ids="pages || []" :campaign-ids="shops || []" :time-range="timeRange || {}" />
            </div>
        </div>
        <ModalChart />
    </div>
</template>



<script>
    import PageHeader from '@/components/common/PageHeader.vue';
    import TimeRangeDropdown from '@/components/common/TimeRangeDropdown.vue';
    import MultiSelectField from '@/components/common/MultiSelectField.vue';
    import ProductsList from './parts/ProductsList.vue';
    import ModalChart from './parts/ModalChart.vue';

    export default {
        name: 'ReportsProducts',
        components: {
            MultiSelectField,
            PageHeader,
            TimeRangeDropdown,
            ProductsList,
            ModalChart,
        },
        data() {
            return {
                timeRange: null,
                pages: [],
                shops: [],
                isInit: false,
            };
        },
        computed: {
            isSpacesLoading() {
                return this.$store.state.space.loading.fetchSpaces;
            },
            isCampaignsLoading() {
                return this.isSpacesLoading || this.$store.getters['campaigns/getLoading']('campaigns');
            },
            isAggregationSalesStatsLoading() {
                return this.$store.getters['userStats/getLoading']('aggregationSalesStats');
            },
            spaces() {
                return this.$store.state.space.spaces;
            },
            campaigns() {
                return this.$store.getters['campaigns/getCampaigns']?.items || [];
            },
            categories() {
                return Array.from(
                    new Set(
                        Object.values(this.spaces)
                            .filter(item => this.pages.includes(item.id))
                            .map(item => item.categories.map(({ id }) => id))
                            .flat()
                    )
                );
            },
            pagesMultiselectFormat() {
                return Object.values(this.spaces).map(({ id, name }) => ({ value: id, text: name }));
            },
            shopsMultiselectFormat() {
                return this.campaigns.map(({ id, name }) => ({ value: id, text: name }));
            },
        },
        watch: {
            campaigns() {
                this.updateSelectedShopsIfPagesWasChanged();
            },
            pages() {
                if (this.isInit) this.fetchCampaigns();
            },
        },
        methods: {
            async init() {
                await this.fetchSpaces();
                this.selectAllPages();
                await this.fetchCampaigns();
                this.selectAllShops();
                this.isInit = true;
            },
            async fetchSpaces() {
                const requestParams = {
                    sort: 'name',
                    order: 'ASC',
                };
                return this.$store.dispatch('space/fetchSpaces', { requestParams });
            },
            async fetchCampaigns() {
                const requestParams = {
                    sort: 'name',
                    order: 'ASC',
                    active: true,
                    allowedFor: this.pages.join(','),
                };
                return this.$store.dispatch('campaigns/fetchCampaigns', { requestParams });
            },
            selectAllPages() {
                this.pages = Object.values(this.spaces).map(({ id }) => parseInt(id));
            },
            selectAllShops() {
                this.shops = this.campaigns.map(({ id }) => parseInt(id));
            },
            setTimeRange(timeRange) {
                this.timeRange = timeRange;
            },
            updateSelectedShopsIfPagesWasChanged() {
                if (this.shops.length) {
                    const campaignIds = this.campaigns.map(({ id }) => id);
                    const isOldId = this.shops.some(id => !campaignIds.includes(id));
                    if (isOldId) this.shops = this.shops.filter(id => campaignIds.includes(id));
                }
            },
        },
        created() {
            this.init();
        },
    };
</script>
