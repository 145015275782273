<template>
    <b-modal
        :id="`modal-form-${modalId}`"
        ref="modal"
        :title="modalTitle"
        size="lg"
        cancel-variant="light"
        ok-variant="primary"
        :cancel-title="$t('cancel')"
        :ok-title="$t('save')"
        :busy="submitting"
        static
        @ok.prevent="handleSubmit()"
        @show="resetData()"
        @hidden="resetData()"
    >
        <b-overlay :show="submitting" opacity="0" class="h-100">
            <b-form @submit.prevent>
                <FormValidator :validator="$v.form.name" form-group v-slot="slotProps" :label="$t('name')">
                    <b-form-input v-model="form.name" :placeholder="$t('name')" :state="slotProps.state" :disabled="submitting" />
                </FormValidator>
            </b-form>
        </b-overlay>
    </b-modal>
</template>



<script>
    import { required } from 'vuelidate/lib/validators';
    import ModalFormMixin from '@/mixins/modalFormMixin';
    import FormValidator from '@/components/common/FormValidator.vue';

    export default {
        name: 'ModalForm',
        mixins: [ModalFormMixin],
        components: {
            FormValidator,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                modalId: 'catalog-manufacturers',
                createAction: 'catalog/postManufacturers',
                updateAction: 'catalog/patchManufacturers',
                form: {
                    name: '',
                },
            };
        },
        validations() {
            return {
                form: {
                    name: { required },
                },
            };
        },
        methods: {
            refreshData() {
                this.parentComponent.fetchData();
            },
        },
    };
</script>
