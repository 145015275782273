import { i18n } from '@/i18n/i18n';

export default Object.freeze({
    PRODUCER: 'ROLE_PRODUCER',
    WIDGETER: 'ROLE_WIDGETER',
    ADVERTISER: 'ROLE_ADVERTISER',
    ADMIN: 'ROLE_ADMIN',
    SUPERADMIN: 'ROLE_SUPERADMIN',
});

export const roleTranslate = role => (role ? i18n.t(`role.${role.replace('ROLE_', '').toLowerCase()}`) : '');
