<template>
    <section>
        <div class="row">
            <div class="col-xl-6 d-flex flex-column">
                <b-form-group :label="$t('name')" label-cols-sm="3">
                    <b-form-input v-model="settings.name" />
                </b-form-group>

                <b-form-group :label="$t('campaignsSettings.nameOnBuybox')" label-cols-sm="3">
                    <b-form-input v-model="settings.nameOnBuybox" />
                </b-form-group>

                <b-form-group :label="$t('url')" label-cols-sm="3">
                    <b-form-input v-model="settings.url" />
                </b-form-group>

                <b-form-group :label="$t('advertiser')" label-cols-sm="3">
                    <Multiselect
                        :value="settings.advertiser"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :show-no-results="true"
                        :show-no-options="true"
                        :preserve-search="true"
                        :show-checkboxes="false"
                        :multiple="false"
                        :show-selected="true"
                        :asynchronous="true"
                        :fetch-options-function="fetchAdvertisers"
                        :parse-options-function="parseAdvertisers"
                        :placeholder="$t('selectField.placeholder')"
                        track-by="id"
                        label="name"
                        :custom-label="option => (option.name ? `${option.name}` : `${option.profile.firstName} ${option.profile.lastName} (${option.username})`)"
                        select-label=""
                        @search-change="value => (advertisersSearchText = value)"
                        @input="value => (settings.advertiser = value)"
                    />
                </b-form-group>
            </div>

            <div class="col-xl-6 d-flex flex-column">
                <div class="row">
                    <div class="col-xl-4 d-flex flex-column">
                        <b-form-group :label="$t('campaignsSettings.isActive')" label-cols-sm="4">
                            <b-checkbox v-model="settings.isActive" switch size="lg" />
                        </b-form-group>
                        <b-form-group :label="$t('campaignsSettings.isHidden')" label-cols-sm="4">
                            <b-checkbox v-model="settings.isHide" switch size="lg" />
                        </b-form-group>
                    </div>
                    <div class="col-xl-4 d-flex flex-column">
                        <b-form-group :label="$t('campaignsSettings.textLinks')" label-cols-sm="4">
                            <b-checkbox v-model="settings.notForTextLinks" switch size="lg" />
                        </b-form-group>
                        <b-form-group :label="$t('campaignsSettings.filteredStatistics')" label-cols-sm="4">
                            <b-checkbox v-model="settings.filteredStatistics" switch size="lg" />
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>

        <div class="divider"></div>
        <div class="row">
            <div class="col-xl-6 d-flex flex-column">
                <b-form-group :label="$t('campaignsSettings.logo')" label-cols-sm="3">
                    <b-form-file v-model="settings.logo" accept="image/*" :placeholder="$t('noFilesSelected')" :browse-text="$t('selectFile')" />
                </b-form-group>

                <b-form-group :label="$t('campaignsSettings.icon')" label-cols-sm="3">
                    <b-form-file v-model="settings.icon" accept="image/*" :placeholder="$t('noFilesSelected')" :browse-text="$t('selectFile')" />
                </b-form-group>
            </div>

            <div class="col-xl-6 d-flex flex-column">
                <img v-if="settings.logo" :src="settings.logo" alt="Logo" class="img-icon">
                <img v-if="settings.icon" :src="settings.icon" alt="Icon" class="img-icon">
            </div>
        </div>
        <div class="divider"></div>

        <div class="row">
            <div class="col-xl-6 d-flex flex-column">
                <b-form-group :label="$t('campaignsSettings.utmy')" label-cols-sm="3">
                    <b-form-input v-model="settings.extraParam" />
                </b-form-group>

                <b-form-group :label="$t('campaignsSettings.cookie')" label-cols-sm="3">
                    <b-form-input v-model="settings.cookieLifetime" />
                </b-form-group>

                <b-form-group :label="$t('campaignsSettings.textFields')" label-cols-sm="3">
                    <b-form-input v-model="settings.textFields" />
                </b-form-group>
            </div>

            <div class="col-xl-6 d-flex flex-column"></div>
        </div>

        <div class="divider"></div>

        <div class="row">
            <div class="col-xl-6 d-flex flex-column">
                <b-form-group :label="$t('campaignsSettings.description')" label-cols-sm="3">
                    <b-form-textarea v-model="settings.description" rows="6" />
                </b-form-group>

                <b-form-group :label="$t('campaignsSettings.conditionDescription')" label-cols-sm="3">
                    <b-form-textarea v-model="settings.conditionDescription" rows="6" />
                </b-form-group>
            </div>
        </div>
        <div class="divider"></div>

        <div class="row">
            <div class="col-xl-6 d-flex flex-column">
                <b-form-group :label="$t('campaignsSettings.textLinkTargetUrlPattern')" label-cols-sm="3">
                    <b-form-input v-model="settings.textLinkTargetUrlPattern" />
                </b-form-group>

                <b-form-group :label="$t('campaignsSettings.textLinkRedirectUrlPattern')" label-cols-sm="3">
                    <b-form-input v-model="settings.textLinkRedirectUrlPattern" />
                </b-form-group>
            </div>

            <div class="col-xl-6 d-flex flex-column"></div>
        </div>

        <div class="divider"></div>

        <div class="row">
            <div class="col-xl-12 d-flex">
                <b-form-group :label="$t('campaignsSettings.visibleFor')" label-cols-sm="3">
                    <div class="d-flex flex-row align-items-center gap-4">
                        <b-checkbox v-for="(role, index) in userRoles" :key="index" :checked="!settings.hiddenForRoles.includes(role.id)" @change="toggleRoleVisibility(role)">
                            {{ role.name }}
                        </b-checkbox>
                    </div>
                </b-form-group>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 d-flex">
                <b-form-group :label="$t('campaignsSettings.shopOnlyForCampaigns')" label-cols-sm="4">
                    <div class="d-flex flex-col gap-4 mt-2">
                        <p v-for="(space, index) in spaces" :key="index">{{ space.name }} ({{ space.categories.length }})</p>
                    </div>

                    <div class="d-flex flex-row align-items-center gap-2">
                        <b-form-input v-model="newSpace" :placeholder="$t('campaignsSettings.addSpace')" />
                        <button-default @click="addSpace"> {{ $t('add') }} </button-default>
                    </div>
                </b-form-group>
            </div>
        </div>

        <section class="submit-buttons">
            <button-default :loading="submitting" @click="handleSave">
                {{ $t('save') }}
            </button-default>
        </section>
    </section>
</template>

<script>
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import Role from '@/Role';
    import userService from '@/services/userService';
    import campaignsService from '@/services/campaignsService';

    export default {
        name: 'Settings',
        components: {
            ButtonDefault,
            Multiselect,
        },
        data() {
            return {
                settings: { hiddenForRoles: [], ...this.parentComponent.item },
                spaces: [],
                userRoles: [],
                advertisersSearchText: '',
                submitting: false,
                error: null,
                newSpace: '',
            };
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },

        methods: {
            toggleRoleVisibility(role) {
                const index = this.settings.hiddenForRoles.indexOf(role.id);
                if (index > -1) {
                    this.settings.hiddenForRoles.splice(index, 1);
                } else {
                    this.settings.hiddenForRoles.push(role.id);
                }
            },

            async addSpace() {
                if (!this.newSpace) {
                    return;
                }

                const requestParams = { name: this.newSpace };
                const space = await campaignsService.createShowSpace({ urlParams: { campaignId: this.settings.id }, requestParams });
                this.spaces.push(space);
                this.newSpace = '';
            },

            async handleSave() {
                this.submitting = true;
                const requestParams = {
                    name: this.settings.name,
                    nameOnBuybox: this.settings.nameOnBuybox,
                    url: this.settings.url,
                    extraParam: this.settings.extraParam,
                    cookieLifetime: this.settings.cookieLifetime.toString(),
                    textFields: this.settings.textFields,
                    description: this.settings.description,
                    conditionDescription: this.settings.conditionDescription,
                    isActive: this.settings.isActive,
                    isHide: this.settings.isHide,
                    notForTextLinks: this.settings.notForTextLinks,
                    textLinkTargetUrlPattern: this.settings.textLinkTargetUrlPattern,
                    textLinkRedirectUrlPattern: this.settings.textLinkRedirectUrlPattern,
                    filteredStatistics: this.settings.filteredStatistics,
                    hiddenForRoles: this.settings.hiddenForRoles,
                };

                campaignsService
                    .patchCampaigns({ urlParams: { id: this.settings.id }, requestParams })
                    .then(data => {
                        this.$toastr.success(this.$t('dataSavedSuccessfully'));
                    })
                    .catch(error => {
                        this.error = error;
                    })
                    .finally(() => {
                        this.submitting = false;
                    });
            },
            fetchAdvertisers() {
                const requestParams = { search: this.advertisersSearchText, roles: [Role.ADVERTISER] };
                return userService.getUsers({ requestParams });
            },
            async fetchUserRoles() {
                this.userRoles = (await userService.getUserRoles()).items;
            },

            async fetchSpaces() {
                this.spaces = await campaignsService.getShowSpaces({ urlParams: { campaignId: this.settings.id } });
            },

            parseAdvertisers(data) {
                return data?.items || [];
            },
        },
        mounted() {
            this.fetchUserRoles().then(() => {
                if (this.parentComponent && this.parentComponent.item) {
                    this.settings = { ...this.parentComponent.item };
                    this.settings.hiddenForRoles = this.parentComponent.item.excludeForRoles.map(role => role.id) || [];
                }
            });

            this.fetchSpaces();
        },
    };
</script>

<style lang="scss" scoped>
    .divider {
        height: 1px;
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        background-color: #edeff4;
    }

    .submit-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        gap: 10px;
    }

    .img-icon {
        width: 40px;
        margin-bottom: 10px;
        height: 40px;
    }
</style>
import { add } from 'lodash';
