<template>
    <b-overlay :show="loading && product" rounded="sm" class="h-100">
        <Card class="m-0 shadow-none h-100">
            <div class="d-flex justify-content-center">
                <ImageLoader
                    v-if="product"
                    :src="product.image"
                    alt=""
                    width="350"
                    height="500"
                    image-width="auto"
                    image-height="auto"
                    class="flex-shrink-0 width-max-350 height-max-500 shadow"
                />
                <b-skeleton v-if="!product" width="350px" height="500px" class="flex-shrink-0" />
            </div>
        </Card>
    </b-overlay>
</template>



<script>
    import Card from '@/components/common/Card.vue';
    import ImageLoader from '@/components/common/ImageLoader.vue';

    export default {
        name: 'ProductImage',
        components: {
            Card,
            ImageLoader,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        computed: {
            product() {
                return this.parentComponent.product;
            },
            loading() {
                return this.parentComponent.loading;
            },
        },
    };
</script>



<style lang="scss" scoped></style>
