<template>
    <DataTable
        :header="$t('verifications')"
        :items="items"
        :fields="fields"
        no-sort-reset
        no-local-sorting  
        :pagination="false"
    >
        <template #table-busy>
            <div class="d-flex justify-content-center my-2">
                <b-spinner />
            </div>
        </template>

        <template #head(profile)>
            {{ $t('firstNameAndLastName') }} <br> {{ $t('company') }}
        </template>
        <template #cell(company)="data">
            <template v-if="data.item.paymentData">
                {{ data.item.user.company }} <br>
                {{ data.item.paymentData.street }} {{ data.item.paymentData.streetNumber }} <br>
                {{ data.item.paymentData.postcode }} {{ data.item.paymentData.city }} <br>
                {{ data.item.paymentData.bankAccount }}
            </template>
        </template>

        <template #cell(paymentData)="data">
            {{ data.item.transferAccount }} <br>
            {{ data.item.transferAddress }} <br>
            {{ data.item.transferAmount }} <br>
            {{ data.item.transferTitle }}
        </template>

        <template #cell(action)="data">
            <div class="d-flex gap-1 justify-content-end">
                <ButtonIcon
                    class="width-100"
                    size="sm"
                    :disabled="!data.item.user"
                    hide-label-while-loading
                    @click="verifyPublisher(data.item.user.id)"
                >
                    {{ $t('confirm') }}
                </ButtonIcon>
            </div>
        </template>
    </DataTable>
</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';

    export default {
        name: 'Results',
        components: {
            DataTable,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                verifiedUsersIds: [],
                fields: [
                    {
                        key: 'user.id',
                        label: this.$t('id'),
                    },
                    {
                        key: 'user.name',
                        label: this.$t('firstNameAndLastName'),
                    },
                    {
                        key: 'company',
                        label: this.$t('company'),
                    },
                    {
                        key: 'verificationCode',
                        label: this.$t('verificationCode'),
                    },
                    {
                        key: 'paymentData',
                        label: this.$t('paymentData'),
                    },
                    {
                        key: 'action',
                        label: '',
                        thClass: 'text-right ',
                    },
                ],
            };
        },
        computed: {
            items() {
                return (this.parentComponent.transactions || []).filter(transaction => !this.verifiedUsersIds.includes(transaction.user?.id));
            },
        },
        methods: {
            verifyPublisher(publisherId) {
                this.$store
                    .dispatch('userVerifications/putVerifications', { urlParams: { publisherId } })
                    .then(response => this.verifiedUsersIds.push(response.id))
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
        },
    };
</script>
