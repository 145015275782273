import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    SUBSCRIPTIONS: {
        URI: () => '/api/v1/report-subscriptions/{{id?}}',
        methods: ['get', 'patch', 'post'],
        cacheToClear: ['/api/v1/report-subscriptions'],
    },
    CANCEL: {
        URI: () => '/api/v1/report-subscriptions/{{id}}/cancel',
        methods: ['put'],
        cacheToClear: ['/api/v1/report-subscriptions'],
    },
    DEACTIVATE: {
        URI: () => '/api/v1/report-subscriptions/{{id}}/deactivate',
        methods: ['put'],
        cacheToClear: ['/api/v1/report-subscriptions'],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
