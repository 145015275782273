<template>
    <div id="wrapper">
        <div class="navbar navbar-expand navbar-custom">
            <div class="container-fluid">
                <AppLogo />
                <div class="mx-2"></div>
                <AdminPanelButton />
            </div>
        </div>

        <div class="content-page ml-0">
            <div class="container p-3">
                <b-row>
                    <b-col cols="12" md="6" offset-md="3">
                        <Card :header="$t('loginAs.title')" :loading="loading" class="m-0">
                            <ErrorMessage :value="error" />
                        </Card>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import AdminPanelButton from '@/components/common/AdminPanelButton.vue';
    import AppLogo from '@/components/AppLogo.vue';
    import Card from '@/components/common/Card.vue';

    export default {
        name: 'LoginAs',
        props: {
            token: String,
            isAdmin: Boolean,
        },
        components: {
            AppLogo,
            AdminPanelButton,
            Card,
            ErrorMessage,
        },
        data() {
            return {
                error: null,
            };
        },
        computed: {
            ...mapState({
                loading: state => state.auth.loading.authenticateAs,
            }),
        },
        methods: {
            async submit() {
                try {
                    this.error = null;
                    await this.authenticateAs({
                        token: this.token,
                        isAdmin: this.isAdmin,
                    });
                } catch (e) {
                    this.error = e.message;
                }
            },
            ...mapActions({
                authenticateAs: 'auth/authenticateAs',
            }),
        },
        created() {
            if (this.token) {
                this.submit();
            } else {
                this.error = this.$t('loginAs.missingToken');
            }
        },
    };
</script>

<style lang="scss" scoped>
    ::v-deep {
        .navbar-custom .logo {
            display: block;
        }
    }
</style>
