import financeService, { ENDPOINTS } from '@/services/financeService';
import moduleFactory from '@/store/modules/moduleFactory';
import financeModuleOLD from './financeModuleOLD';

const moduleStore = moduleFactory.build(ENDPOINTS, financeService);

export default {
    namespaced: true,
    state: {
        ...financeModuleOLD.state,
        ...moduleStore.state,
        loading: { ...financeModuleOLD.state.loading, ...moduleStore.state.loading },
    },
    getters: {
        ...financeModuleOLD.getters,
        ...moduleStore.getters,
    },
    mutations: {
        ...financeModuleOLD.mutations,
        ...moduleStore.mutations,
    },
    actions: {
        ...financeModuleOLD.actions,
        ...moduleStore.actions,
    },
};
