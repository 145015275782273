import { deburr, sortBy } from 'lodash';
import { getName, getNames, registerLocale } from 'i18n-iso-countries';
import { i18n, getLanguageFromLocale } from '../i18n/i18n';

export default {
    async loadCountriesLanguage(locale) {
        const language = getLanguageFromLocale(locale);

        return import(/* @vite-ignore */ /* webpackChunkName: "country-lang-[request]" */ `i18n-iso-countries/langs/${language}.json`)
            .then(async countryLangJson => registerLocale(countryLangJson.default))
            .catch(() => {
                console.error(`Country locale file for "${language}" not found.`);
            });
    },

    getCountryOptions() {
        const language = getLanguageFromLocale(i18n.locale);
        const allCountries = getNames(language);
        const options = Object.keys(allCountries).map(countryCode => ({
            value: countryCode,
            text: allCountries[countryCode],
        }));

        // deburr is used to handle accented characters during sort
        return sortBy(options, option => deburr(option.text));
    },

    getCountryName(code) {
        const language = getLanguageFromLocale(i18n.locale);
        return getName(code, language);
    },

    getBankAccountPrefixes() {
        const language = getLanguageFromLocale(i18n.locale);
        const allCountries = getNames(language);
        const options = Object.keys(allCountries).map(countryCode => ({
            value: countryCode,
            text: countryCode,
        }));
        return sortBy(options, option => deburr(option.text));
    },
};
