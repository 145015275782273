import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    LOGIN: {
        URI: () => '/api/oauth/login_check',
        methods: ['post'],
    },
    CONNECT: {
        URI: () => '/api/v1/oauth/connect',
        methods: ['post'],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
