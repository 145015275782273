import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';
import { EndpointError } from '@/services/errorService';
import globalData from '@/store/globalData';

export const STATUSES = Object.freeze({
    NEW: 1,
    ACCEPTED: 2,
    REJECTED: 3,
    ABANDONED: 4,
});

export const getStatusIdByActionName = statusName => {
    if (statusName === 'new') return STATUSES.NEW;
    if (statusName === 'accept') return STATUSES.ACCEPTED;
    if (statusName === 'reject') return STATUSES.REJECTED;
    if (statusName === 'abandon') return STATUSES.ABANDONED;
    return null;
};

export const ENDPOINTS = deepFreeze({
    USER_TRANSACTIONS: {
        URI: params => {
            if (globalData.user.isRoleWidgeter) return '/api/v1/publishers/{{userId}}/transactions';
            if (globalData.user.isRoleAdvertiser) return '/api/v1/reporters/{{userId}}/transactions';
            if (globalData.user.isRoleAdmin) return '/api/v1/admin-transactions';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    TRANSACTIONS_ACTION: {
        URI: params => {
            if (globalData.user.isRoleAdvertiser) return '/api/v1/campaigns/{{campaignId}}/transactions/{{action}}';
            if (params.id) return '/api/v1/transactions/{{id}}/{{action}}';
            return '/api/v1/transactions/{{action}}';
        },
        methods: ['post', 'put'],
        cacheToClear: ['/api/v1/publishers/\\d+/transactions', '/api/v1/reporters/\\d+/transactions', '/api/v1/admin-transactions'],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
