<template>
    <LayoutAuth>
        <template #default="props">
            <b-form @submit.prevent>
                <FormValidator :validator="$v.form.email" v-slot="slotProps" class="mb-3">
                    <b-form-input
                        v-model="form.email"
                        :placeholder="$t('emailAddress').toLowerCase()"
                        :state="slotProps.state"
                        :disabled="submitting || props.isLoggingSocial"
                        @keyup.enter="handleSubmit()"
                    />
                </FormValidator>
                <FormValidator :validator="$v.form.password" class="mb-3">
                    <template #default="slotProps">
                        <b-form-input
                            v-model="form.password"
                            :placeholder="$t('password').toLowerCase()"
                            type="password"
                            :state="slotProps.state"
                            :disabled="submitting || props.isLoggingSocial"
                            @keyup.enter="handleSubmit()"
                        />
                    </template>
                    <template #bottom>
                        <b-form-text class="text-right">
                            <router-link :to="{name: 'ResetPassword'}" class="text-brand">
                                <u>{{ $t('auth.forgotYourPassword') }}</u>
                            </router-link>
                        </b-form-text>
                    </template>
                </FormValidator>
                <b-form-group>
                    <ButtonIcon
                        :disabled="props.isLoggingSocial"
                        :loading="submitting"
                        :flip-icon="true"
                        variant="brand"
                        size="lg"
                        class="btn-login"
                        block
                        @click="handleSubmit()"
                    >
                        <template #default>
                            {{ $t('auth.logIn') }}
                        </template>
                        <template #icon>
                            <img src="@/assets/arrow-button.svg" alt="" width="45px" class="ml-2">
                        </template>
                    </ButtonIcon>
                </b-form-group>
            </b-form>
        </template>
        <template #page-title>
            {{ pageTitle }}
        </template>
        <template v-if="mode === 'WIDGETER'" #divider>
            <div class="divider">
                {{ $t('or') }}
            </div>
        </template>
        <template v-if="mode === 'WIDGETER'" #cta-text>
            {{ $t('auth.youDontHaveAnAccountYet') }}
        </template>
        <template v-if="mode === 'WIDGETER'" #cta-link>
            <router-link :to="{name: 'Register', query: $route.query}" class="text-brand">
                {{ $t('auth.registerForFree') }}
            </router-link>
        </template>
    </LayoutAuth>
</template>



<script>
    import { required } from 'vuelidate/lib/validators';
    import { mode } from '@/utils/util';
    import AuthMixin from './auth-mixin.vue';

    export default {
        name: 'Login',
        mixins: [AuthMixin],
        data() {
            return {
                form: {
                    email: '',
                    password: '',
                },
                mode,
            };
        },
        validations: {
            form: {
                email: { required },
                password: { required },
            },
        },
        computed: {
            userRoleShort() {
                return this.$store.getters['auth/getRoleShortName'];
            },
            pageTitle() {
                if (this.mode === 'WIDGETER') {
                    return this.$t('auth.widgeter.login');
                }
                if (this.mode === 'ADVERTISER') {
                    return this.$t('auth.advertiser.login');
                }
                return null;
            },
        },
        methods: {
            resetError() {
                this.error = null;
            },
            setError(error) {
                this.error = error;
            },
            formRequest() {
                return this.$store.dispatch('auth/authenticate', { username: this.form.email, password: this.form.password });
            },
            requestSuccess() {
                this.$router.push({ name: 'Dashboard', params: { role: this.userRoleShort } }).catch(() => {});
            },
        },
    };
</script>
