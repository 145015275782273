<template>
    <span :title="text" :style="ellipsisStyle">
        <slot></slot>
    </span>
</template>

<script>
    export default {
        name: 'Ellipsis',
        props: {
            maxWidth: {
                type: [Number, String],
            },
            maxLines: {
                type: Number,
                default: 1,
            },
        },
        computed: {
            text() {
                return this.$slots.default[0]?.text?.trim() || '';
            },
            ellipsisStyle() {
                const style = {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    boxOrient: 'vertical',
                };

                if (this.maxWidth !== undefined) {
                    style.maxWidth = typeof this.maxWidth === 'number' ? `${this.maxWidth}px` : this.maxWidth;
                }

                if (this.maxLines) {
                    style.lineClamp = this.maxLines;
                }

                return style;
            },
        },
    };
</script>
