<template>
    <row-labeled :row="row">
        <template v-if="label" #label>
            <span>{{ label }} <span v-if="isRequired" class="text-danger">*</span></span>
        </template>
        <template #sublabel>
            <small v-if="sublabel" class="form-text text-muted mb-2">{{ sublabel }}</small>
        </template>
        <template #content="{ htmUniqueId }">
            <multiselect
                v-if="variant === 'select'"
                :id="htmUniqueId"
                ref="multiselect"
                v-model="model"
                multiple
                :options="options"
                :max="maxSelected"
                :loading="loading"
                :disabled="disabled || loading"
                :placeholder="placeholder || $t('selectField.placeholder')"
                :search-placeholder="searchPlaceholder || $t('enterToSearch')"
                :select-label="$t('selectField.selectLabel')"
                :selected-label="$t('selectField.selectedLabel')"
                :deselect-label="$t('selectField.deselectLabel')"
                :select-group-label="$t('selectField.selectGroupLabel')"
                :deselect-group-label="$t('selectField.deselectGroupLabel')"
                :limit-text="limitText"
                :close-on-select="false"
                allow-empty
                track-by="value"
                label="text"
                :class="{ 'is-invalid': hasValidationErrors }"
                :show-checkboxes="showCheckboxes"
                :clear-on-select="false"
                @open="$emit('msOpen', $event); initValueOnOpen(); repositionDropdownInNextTick()"
                @close="$emit('msClose', $event); validate(); emitChange()"
                @input="$emit('msInput', $event); repositionDropdownInNextTick()"
                @select="$emit('msSelect', $event)"
            >
                <template slot="noResult">
                    {{ $t('selectField.noResult') }}
                </template>
                <template slot="noOptions">
                    {{ $t('selectField.noOptions') }}
                </template>
                <template slot="selection" slot-scope="props">
                    <slot name="selection" v-bind="props"></slot>
                </template>
                <template slot="selectAllHeader" slot-scope="props">
                    <slot name="selectAllHeader" v-bind="props"></slot>
                </template>
            </multiselect>

            <div v-else-if="variant === 'checkbox' || variant === 'switch'">
                <b-spinner v-if="loading" />
                <checkbox-field
                    v-for="option in options"
                    v-else
                    :key="option.value"
                    v-model="model"
                    :value-for-iteration="option"
                    :label="option.text"
                    :variant="variant"
                />
            </div>

            <div v-if="hasValidationErrors" class="invalid-feedback">
                {{ validationErrors.join(' ') }}
            </div>
        </template>
    </row-labeled>
</template>

<script>
    /* eslint-disable no-underscore-dangle */
    import isEqual from 'lodash/isEqual';
    import sortBy from 'lodash/sortBy';
    import Multiselect from '@/components/common/Multiselect.vue';
    import CheckboxField from '@/components/common/CheckboxField.vue';
    import RowLabeled from './RowLabeled.vue';
    import validatableFieldMixin from '../../mixins/validatableFieldMixin';

    function validateOption(option) {
        if (option.value === undefined || option.text === undefined) {
            const optionAsString = JSON.stringify(option, null, 4);
            console.error(`${optionAsString}\n option is invalid: value and text must be defined`);
            return false;
        }

        return true;
    }

    export default {
        name: 'MultiSelectField',
        mixins: [validatableFieldMixin],
        components: {
            CheckboxField,
            RowLabeled,
            Multiselect,
        },
        props: {
            /**
             * @model
             */
            value: {
                type: Array,
            },
            options: {
                type: Array,
                required: true,
                validator(options) {
                    return options.every(validateOption);
                },
            },
            label: {
                type: String,
            },
            sublabel: {
                type: String,
                required: false,
            },
            maxSelected: {
                type: Number,
                default: Infinity,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            variant: {
                type: String,
                validator(val) {
                    return ['select', 'checkbox', 'switch'].includes(val);
                },
                default: 'select',
            },
            placeholder: {
                type: String,
            },
            searchPlaceholder: {
                type: String,
            },
            row: {
                type: Boolean,
            },
            lazy: {
                type: Boolean,
                default: false,
            },
            showCheckboxes: {
                type: Boolean,
                default: false,
            },
            appendToBody: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                valueOnOpen: [],
                modelValue: [],
            };
        },
        computed: {
            model: {
                get() {
                    return this.options.filter(option => this.modelValue.includes(option.value));
                },
                set(selection) {
                    const inputValue = selection.map(option => option.value);
                    if (this.lazy) {
                        this.modelValue = inputValue;
                    } else {
                        this.$emit('input', inputValue);
                    }
                },
            },
            isOptionSelected() {
                return option => this.value.includes(option.value);
            },
        },
        watch: {
            value: {
                immediate: true,
                handler() {
                    this.modelValue = [...(this.value || [])];
                },
            },
        },
        methods: {
            limitText(count) {
                return this.$t('selectField.limitText', { count });
            },
            initValueOnOpen() {
                this.valueOnOpen = [...this.modelValue];
            },
            emitChange() {
                const valueWasChanged = !isEqual(sortBy(this.valueOnOpen), sortBy(this.modelValue));
                if (valueWasChanged) {
                    this.$emit('change', this.modelValue);
                    if (this.lazy) this.$emit('input', this.modelValue);
                }
                this.valueOnOpen = [];
            },
            repositionDropdown() {
                if (this.appendToBody) {
                    const ref = this.$refs.multiselect;
                    if (ref) {
                        const { top, height, left } = ref.$el.getBoundingClientRect();
                        ref.$refs.list.style.position = 'fixed';
                        ref.$refs.list.style.bottom = 'auto';
                        ref.$refs.list.style.top = `${top + height}px`;
                        ref.$refs.list.style.left = `${left}px`;
                        ref.$refs.list.style.width = 'auto';
                    }
                }
            },
            repositionDropdownInNextTick() {
                // nested to set the position after changing other fields - ugly but it works
                this.repositionDropdown();
                this.$nextTick(() => {
                    this.repositionDropdown();
                    this.$nextTick(this.repositionDropdown);
                });
            },
        },
        mounted() {
            window.addEventListener('scroll', this.repositionDropdown);
            window.addEventListener('resize', this.repositionDropdown);
        },
        destroyed() {
            window.removeEventListener('scroll', this.repositionDropdown);
            window.removeEventListener('resize', this.repositionDropdown);
        },
    };
</script>
