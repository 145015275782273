import Role from '@/Role';

export const isDevEnv = process.env.NODE_ENV === 'development';
const getMode = () => {
    if (window.location.origin === process.env.VUE_APP_ADMIN_URL || window.location.origin === 'http://localhost:8080') {
        return 'ADMIN';
    }
    if (window.location.origin === process.env.VUE_APP_ADVERTISER_URL) {
        return 'ADVERTISER';
    }
    if (window.location.origin === process.env.VUE_APP_WIDGETER_URL) {
        return 'WIDGETER';
    }
    return undefined;
};
export const mode = getMode();

export const isAdminRole = roleName => [Role.ADMIN, Role.SUPERADMIN, 'admin', 'superadmin'].includes(roleName);

export const isEmpty = value => value === undefined || value === null || value === '';

export const generateId = () => Math.random().toString(36).substring(2, 6);

export const hashFnv32a = (str, asString, seed) => {
    /* eslint-disable no-bitwise */
    let i;
    let l;
    let hval = seed === undefined ? 0x811c9dc5 : seed;

    for (i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    if (asString) {
        // Convert to 8 digit hex string
        return `0000000${(hval >>> 0).toString(16)}`.substr(-8);
    }
    return hval >>> 0;
    /* eslint-enable */
};

export const stringToColour = str => {
    /* eslint-disable no-bitwise */
    const hashStr = hashFnv32a(str).toString();
    let hash = 0;
    for (let i = 0; i < hashStr.length; i++) {
        hash = hashStr.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        colour += `00${value.toString(16)}`.substr(-2);
    }
    return colour;
    /* eslint-enable */
};

export default {
    isEmpty,
    generateId,
    hashFnv32a,
    stringToColour,
};
