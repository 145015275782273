var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{attrs:{"fields":_vm.fields,"items":_vm.parentComponent.items,"loading":_vm.parentComponent.loading,"error":_vm.parentComponent.error,"total-rows":_vm.parentComponent.requestData.max,"current-page":_vm.parentComponent.tableParams.page,"per-page":_vm.parentComponent.tableParams.perPage,"sort-by":_vm.parentComponent.tableParams.sort,"sort-direction":_vm.parentComponent.tableParams.order,"no-local-sorting":""},on:{"update:currentPage":function($event){return _vm.$set(_vm.parentComponent.tableParams, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.parentComponent.tableParams, "page", $event)},"update:perPage":function($event){return _vm.$set(_vm.parentComponent.tableParams, "perPage", $event)},"update:per-page":function($event){return _vm.$set(_vm.parentComponent.tableParams, "perPage", $event)},"update:sortBy":function($event){return _vm.$set(_vm.parentComponent.tableParams, "sort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.parentComponent.tableParams, "sort", $event)},"update:sortDirection":function($event){return _vm.$set(_vm.parentComponent.tableParams, "order", $event)},"update:sort-direction":function($event){return _vm.$set(_vm.parentComponent.tableParams, "order", $event)}},scopedSlots:_vm._u([{key:"cell(permission)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("subscriptionsPermissionOptions." + value)))+" ")]}},{key:"cell(createdAt)",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(_vm._s(_vm.$format.dateAndHour(value)))]:_vm._e()]}},{key:"cell(canceledAt)",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(_vm._s(_vm.$format.dateAndHour(value)))]:_vm._e()]}},{key:"cell(deactivatedAt)",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(_vm._s(_vm.$format.dateAndHour(value)))]:_vm._e()]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [_c('ReportSubscriptionsStatusBadge',{attrs:{"status":value,"label":_vm.$t(value)}})]}},{key:"cell(agreement)",fn:function(ref){
var value = ref.value;
return [_c('BadgeBoolean',{attrs:{"value":value}})]}},{key:"cell(space)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{attrs:{"href":item.space.url,"target":"_blank"}},[_vm._v(_vm._s(item.space.name))])]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex gap-1"},[_c('ButtonIcon',{attrs:{"size":"sm","variant":"warning","hide-label-while-loading":"","disabled":item.status === 'inactive' || item.status === 'canceled' || _vm.isItemBusy(item.id),"loading":_vm.isItemCanceled(item.id)},on:{"click":function($event){return _vm.cancelItem(item)}}},[_vm._v(" "+_vm._s(_vm.$t('cancelSubscription'))+" ")]),_c('ButtonIcon',{attrs:{"size":"sm","variant":"danger","hide-label-while-loading":"","disabled":item.status === 'inactive' || _vm.isItemBusy(item.id),"loading":_vm.isItemDeactivated(item.id)},on:{"click":function($event){return _vm.deactivateItem(item)}}},[_vm._v(" "+_vm._s(_vm.$t('deactivate'))+" ")]),_c('ButtonIcon',{attrs:{"size":"sm","variant":"success","hide-label-while-loading":"","disabled":_vm.isItemBusy(item.id),"loading":_vm.isItemChanged(item.id)},on:{"click":function($event){return _vm.changeItem(item)}}},[_vm._v(" "+_vm._s(_vm.$t('changeType'))+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }