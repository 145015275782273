<template>
    <div>

        <PageHeader>
            {{ $t('tipSettlements') }}
        </PageHeader>

        <div class="d-flex flex-column gap-4">

            <Stats ref="Stats" />

            <Payouts ref="Payouts" />

            <Invoices ref="Invoices" />

        </div>

    </div>
</template>



<script>
    import Stats from './parts/Stats.vue';
    import Payouts from './parts/Payouts.vue';
    import Invoices from './parts/Invoices.vue';

    export default {
        name: 'Settlements',
        components: {
            Stats,
            Payouts,
            Invoices,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {};
        },
    };
</script>



<style lang="scss" scoped></style>
