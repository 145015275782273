<template>
    <Multiselect v-bind="attrs" v-on="listeners">
        <template v-for="slotName in Object.keys($slots)" #[slotName]>
            <slot :name="slotName"></slot>
        </template>
        <template v-for="slotName in Object.keys($scopedSlots)" #[slotName]="slotProps">
            <slot :name="slotName" v-bind="slotProps"></slot>
        </template>
        <template slot="selection" slot-scope="{ values, isOpen }">
            <span v-if="values.length && !isOpen" class="multiselect__single">{{ $t('yourPages') }} ({{ values.length }})</span>
        </template>
        <template slot="selectAllHeader" slot-scope="{ values }">
            {{ $t('yourPages') }} ({{ values.length }})
        </template>
    </Multiselect>
</template>



<script>
    import Multiselect from '@/components/common/Multiselect.vue';
    import spaceService from '@/services/spaceService';

    export default {
        name: 'MultiselectSpaces',
        components: {
            Multiselect,
        },
        props: {
            lazy: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                searchText: '',
            };
        },
        computed: {
            attrs() {
                return {
                    'show-checkboxes': true,
                    'clear-on-select': false,
                    'close-on-select': false,
                    'show-selected': true,
                    'fetch-options-function': this.fetchSpaces,
                    'parse-options-function': this.parseOptions,
                    class: 'mb-0',
                    multiple: true,
                    placeholder: (this.isRoleAdvertiser || this.isRoleAdmin) ? this.$t('reportsPages.pages') : this.$t('yourPages'), // eslint-disable-line
                    asynchronous: (this.isRoleAdvertiser || this.isRoleAdmin) ? true : false, // eslint-disable-line
                    trackByLabel: this.$t('id'),
                    ...this.$attrs,
                };
            },
            listeners() {
                return {
                    ...this.$listeners,
                    input: (value, id) => {
                        if (!this.lazy) this.$emit('input', value, id);
                    },
                    change: (value, id) => {
                        this.$emit('input', value, id);
                        this.$emit('change', value, id);
                    },
                    'search-change': (search, id) => {
                        this.searchText = search;
                        this.$emit('searchChange', search, id);
                    },
                };
            },
            campaignId() {
                return this.$store.getters['campaigns/getCurrentCampaign']?.id;
            },
            isRoleAdvertiser() {
                return this.$store.getters['auth/isRoleAdvertiser'];
            },
            isRoleAdmin() {
                return this.$store.getters['auth/isRoleAdmin'];
            },
            requestParamsWidgeter() {
                return {
                    sort: 'name',
                    order: 'ASC',
                    page: 1,
                    perPage: Number.MAX_SAFE_INTEGER,
                    statuses: '1,2',
                };
            },
            requestParamsAdvertiser() {
                return {
                    sort: 'name',
                    order: 'ASC',
                    page: 1,
                    perPage: Number.MAX_SAFE_INTEGER,
                    allowedFor: this.campaignId,
                    search: this.searchText,
                    statuses: '1,2',
                };
            },
            requestParamsAdmin() {
                return {
                    sort: 'name',
                    order: 'ASC',
                    page: 1,
                    perPage: Number.MAX_SAFE_INTEGER,
                    allowedFor: this.campaignId,
                    search: this.searchText,
                    statuses: '1,2',
                };
            },
            requestParams() {
                if (this.isRoleAdvertiser) return this.requestParamsAdvertiser;
                if (this.isRoleAdmin) return this.requestParamsAdmin;
                return this.requestParamsWidgeter;
            },
            parseOptionsMapFn() {
                if (this.isRoleAdvertiser) return this.optionsMapAdvertiser;
                if (this.isRoleAdmin) return this.optionsMapAdmin;
                return this.optionsMapWidgeter;
            },
        },
        methods: {
            async fetchSpaces({ initialValue }) {
                if (initialValue) return Promise.resolve({});
                return spaceService.fetchSpaces(this.$store.getters['auth/userId'], this.requestParams);
            },
            optionsMapWidgeter(item) {
                return { value: item.id, text: item.name };
            },
            optionsMapAdvertiser(item) {
                return { value: item.id, text: `${item.name} (${item.url})` };
            },
            optionsMapAdmin(item) {
                return { value: item.id, text: item.name };
            },
            parseOptions(data) {
                const values = Object.values(data?.items || {});
                return values.map(this.parseOptionsMapFn);
            },
        },
    };
</script>
