<template>
    <DataTable
        hover
        :error="error"
        :items="items"
        :fields="fields"
        :total-rows="transactions.max"
        :per-page.sync="parentComponent.perPage"
        :sort-by.sync="parentComponent.sortBy"
        :sort-direction.sync="parentComponent.sortDirection"
        :current-page.sync="parentComponent.currentPage"
        :loading="loading"
        @row-clicked="previewItem"
    >
        <template #table-busy>
            <div class="d-flex justify-content-center my-2">
                <b-spinner />
            </div>
        </template>
        <template #head(createdAt)>
            {{ $t('date') }} <br> {{ $t('hour') }}
        </template>
        <template #head(transId)>
            {{ $t('identifier') }} <br> {{ $t('status') }}
        </template>
        <template #cell(createdAt)="data">
            {{ $format.dateAndHour(data.value) }}
        </template>
        <template #cell(transId)="data">
            <Ellipsis :max-width="200" :max-lines="2">
                {{ data.item.transId }}
            </Ellipsis>
            <TransactionStatusBadge v-if="data.item.status" :status="data.item.status" />
        </template>
        <template #cell(publisherCommission)="data">
            {{ $format.currency(data.value) }}
        </template>
        <template #cell(amount)="data">
            {{ $format.currency(data.value) }}
        </template>
        <template #cell(conversionTime)="data">
            {{ $format.seconds(data.value) }}
        </template>
    </DataTable>
</template>



<script>
    import TransactionStatusBadge from '@/components/common/TransactionStatusBadge.vue';
    import Ellipsis from '@/components/common/Ellipsis.vue';
    import DataTable from '@/components/common/DataTable.vue';

    export default {
        name: 'Results',
        components: {
            TransactionStatusBadge,
            Ellipsis,
            DataTable,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                fields: [
                    {
                        key: 'createdAt',
                        label: this.$t('date'),
                    },
                    {
                        key: 'transId',
                        label: this.$t('identifier'),
                    },
                    {
                        key: 'campaign.name',
                        label: this.$t('campaign'),
                    },
                    {
                        key: 'space.name',
                        label: this.$t('space'),
                    },
                    {
                        key: 'amount',
                        label: this.$t('transactions.transactionAmount'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'publisherCommission',
                        label: this.$t('commission'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'conversionTime',
                        label: this.$t('conversionTime'),
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'abpar1',
                        label: this.$t('abpar1'),
                    },
                    {
                        key: 'abpar2',
                        label: this.$t('abpar2'),
                    },
                    {
                        key: 'abpar3',
                        label: this.$t('abpar3'),
                    },
                ],
            };
        },
        computed: {
            transactions() {
                return this.$store.getters['transactions/getUserTransactions'];
            },
            items() {
                return this.transactions.items || [];
            },
            error() {
                return this.$store.getters['transactions/getError']('userTransactions');
            },
            loading() {
                return this.$store.getters['transactions/getLoading']('userTransactions');
            },
        },
        methods: {
            previewItem(item) {
                this.$root.$emit('bv::show::modal', `modal-preview-${this.parentComponent.modalItemName}`);
                this.$root.$emit(`preview-${this.parentComponent.modalItemName}`, item);
            },
        },
    };
</script>



<style lang="scss" scoped>
    ::v-deep {
        table {
            tr {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
</style>
