import Vue from 'vue';
import Vuex from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import storageKeys from '@/Storage';
import usercomService from '@/services/usercomService';
import layoutModule from './modules/layoutModule';
import authModule from './modules/authModule';
import spaceModule from './modules/spaceModule';
import pluginModule from './modules/pluginModule';
import optionsModule from './modules/optionsModule';
import financeModule from './modules/financeModule';
import userStatsModule from './modules/userStatsModule';
import conversionStatsModule from './modules/conversionStatsModule';
import transactionsModule from './modules/transactionsModule';
import campaignsModule from './modules/campaignsModule';
import campaignApiTokenModule from './modules/campaignApiTokenModule';
import categoryModule from './modules/categoryModule';
import campaignSpaceModule from './modules/campaignSpaceModule';
import offersModule from './modules/offersModule';
import linkGeneratorModule from './modules/linkGeneratorModule';
import productModule from './modules/productModule';
import catalogModule from './modules/catalogModule';
import landingPageModule from './modules/landingPageModule';
import userModule from './modules/userModule';
import userVerificationsModule from './modules/userVerificationsModule';
import tipTransactionsModule from './modules/tipTransactionsModule';
import tipboxModule from './modules/tipboxModule';
import producerProductModel from './modules/producerProductModel';
import advertiserSettlementsModule from './modules/advertiserSettlementsModule';
import productFeedsModule from './modules/productFeedsModule';
import transactionFileProcessTaskModule from './modules/transactionFileProcessTaskModule';
import reportSubscriptionsModule from './modules/reportSubscriptionsModule';
import globalData from './globalData';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
    strict: debug,
    state: {
        sessionInitialization: false,
        sessionInitializationError: false,
        spacesInitialization: false,
        spacesInitializationError: false,
        campaignsInitialization: false,
        campaignsInitializationError: false,
    },
    getters: {
        sessionInitialization: state => state.sessionInitialization,
        sessionInitializationError: state => state.sessionInitializationError,
        spacesInitialization: state => state.spacesInitialization,
        spacesInitializationError: state => state.spacesInitializationError,
        campaignsInitialization: state => state.campaignsInitialization,
        campaignsInitializationError: state => state.campaignsInitializationError,
    },
    mutations: {
        sessionInitialization: (state, payload) => (state.sessionInitialization = !!payload),
        sessionInitializationError: (state, payload) => (state.sessionInitializationError = payload),
        spacesInitialization: (state, payload) => (state.spacesInitialization = !!payload),
        spacesInitializationError: (state, payload) => (state.spacesInitializationError = payload),
        campaignsInitialization: (state, payload) => (state.campaignsInitialization = !!payload),
        campaignsInitializationError: (state, payload) => (state.campaignsInitializationError = payload),
        clearStoreState(state) {
            Object.entries(store.initialState).forEach(([moduleName, cleanState]) => {
                if (store.hasModule(moduleName)) {
                    Object.keys(state[moduleName]).forEach(key => {
                        state[moduleName][key] = cloneDeep(cleanState[key]);
                    });
                } else {
                    state[moduleName] = cleanState;
                }
            });
        },
    },
    plugins: [],
    modules: {
        layout: layoutModule,
        auth: authModule,
        space: spaceModule,
        plugin: pluginModule,
        options: optionsModule,
        finance: financeModule,
        userStats: userStatsModule,
        conversionStats: conversionStatsModule,
        transactions: transactionsModule,
        campaigns: campaignsModule,
        campaignApiToken: campaignApiTokenModule,
        category: categoryModule,
        campaignSpace: campaignSpaceModule,
        offers: offersModule,
        linkGenerator: linkGeneratorModule,
        product: productModule,
        catalog: catalogModule,
        landingPage: landingPageModule,
        user: userModule,
        userVerifications: userVerificationsModule,
        tipTransactions: tipTransactionsModule,
        tipbox: tipboxModule,
        producerProduct: producerProductModel,
        advertiserSettlements: advertiserSettlementsModule,
        productFeeds: productFeedsModule,
        transactionFileProcessTask: transactionFileProcessTaskModule,
        reportSubscriptions: reportSubscriptionsModule,
    },
});

store.watch(
    () => store.getters['campaigns/getAdvertiserCampaigns'],
    campaigns => {
        const currentCampaign = store.getters['campaigns/getCurrentCampaign'];
        if (campaigns?.length && !campaigns.find(item => item === currentCampaign)) {
            const currentCampaignId = parseInt(window.localStorage.getItem(storageKeys.CAMPAIGN_ID));
            const campaignFound = campaigns.find(item => item.id === currentCampaignId);
            const campaign = campaignFound || campaigns[0];
            store.commit('campaigns/setCurrentCampaign', campaign);
        }
    },
    { immediate: true }
);

if (usercomService.isUserComAvailable()) {
    store.watch(
        () => store.getters['auth/getUser'],
        (user, prevUser) => {
            if (user?.id !== prevUser?.id || !window.UE.isReady) {
                const userData = usercomService.getUserDataFromUser(user);
                usercomService.resetAuth(userData);
            } else if (user?.id) {
                const userData = usercomService.getUserDataFromUser(user);
                usercomService.clientUpdate(userData);
            }
        },
        { immediate: true }
    );
}

store.watch(
    () => store.getters['auth/getUser'],
    user => {
        globalData.user = user;
        if (globalData.user) {
            globalData.user.isAdmin = store.getters['auth/isAdmin'];
            globalData.user.isRoleProducer = store.getters['auth/isRoleProducer'];
            globalData.user.isRoleWidgeter = store.getters['auth/isRoleWidgeter'];
            globalData.user.isRoleAdvertiser = store.getters['auth/isRoleAdvertiser'];
            globalData.user.isRoleAdmin = store.getters['auth/isRoleAdmin'];
            globalData.user.roleName = store.getters['auth/getRoleName'];
            globalData.user.roleShortName = store.getters['auth/getRoleShortName'];
            globalData.user.isAuthenticated = store.getters['auth/isAuthenticated'];
            globalData.apiParams.userId = store.getters['auth/userId'];
            globalData.apiParams.userRole = store.getters['auth/getRoleShortName'];
        }
    },
    { immediate: true }
);

store.watch(
    () => store.getters['campaigns/getCurrentCampaign'],
    campaign => {
        globalData.campaign = campaign;
        if (globalData.campaign) {
            globalData.apiParams.campaignId = store.getters['campaigns/getCurrentCampaign'].id;
        }
    },
    { immediate: true }
);

export default store;
