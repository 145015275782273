<template>
    <Card :header="$t('tradeDoublerImport')">
        <ErrorMessage :value="error" />
        <FormValidator
            form-group
            :validator="$v.form.csv"
            v-slot="slotProps"
            :label="$t('file')"
            label-cols="12"
            class="form-item"
        >
            <FormFile
                v-model="form.csv"
                :disabled="busy"
                :state="slotProps.state"
                accept=".csv"
                :browse-text="$t('add')"
                :placeholder="$t('fileField.placeholder')"
            />
        </FormValidator>
        <ButtonIcon
            class="mr-auto width-min-150"
            variant="primary"
            :disabled="busy"
            :loading="busy"
            hide-label-while-loading
            @click="importTradedoubler()"
        >
            {{ $t('import') }}
        </ButtonIcon>

        <div class="w-100 mb-4"></div>

        <p class="mb-2">{{ $t('transactionImportProcedureLabel') }}</p>
        <code class="text-body font-size-13">
            Program Name;Program Prepayment Status;Time of visit;Time of event;Order nr;EPI EPI 1;EPI EPI 2;Product info ID;Product info #;
            Product info Product Value;Order Value;Commission Publisher;Product info (PLT programmes-only) Product ID;Product info (PLT programmes-only) Product Quantity;
            Product info (PLT programmes-only) Product Value;Product info (PLT programmes-only) Publisher Commission;Product info (PLT programmes-only) Pub Comm. %;ProductCommissionID
        </code>
    </Card>
</template>



<script>
    import deepFreeze from 'deep-freeze';
    import cloneDeep from 'lodash/cloneDeep';
    import { required } from 'vuelidate/lib/validators';
    import { serialize as objectToFormData } from 'object-to-formdata';
    import FormValidator from '@/components/common/FormValidator.vue';
    import FormFile from '@/components/common/FormFile.vue';

    const FORM_DEFAULT_DATA = deepFreeze({
        csv: null,
    });

    export default {
        name: 'FormTradedoubler',
        components: {
            FormValidator,
            FormFile,
        },
        data() {
            return {
                form: cloneDeep(FORM_DEFAULT_DATA),
            };
        },
        validations() {
            return {
                form: {
                    csv: { required },
                },
            };
        },
        computed: {
            busy() {
                return this.$store.getters['transactionFileProcessTask/getBusy']('tradedoubler');
            },
            error() {
                return this.$store.getters['transactionFileProcessTask/getError']('tradedoubler');
            },
        },
        methods: {
            importTradedoubler() {
                this.$v.form.$touch();
                if (this.$v.form.$invalid) return;
                this.$store.commit('transactionFileProcessTask/setError', { key: 'tradedoubler', value: null });
                const params = { requestParams: objectToFormData(this.form), headers: { 'Content-Type': 'multipart/form-data' } };
                this.$store
                    .dispatch('transactionFileProcessTask/postTradedoubler', params)
                    .then(() => {
                        this.$toastr.success(this.$t('transactionTradedoublerImportSuccessfully'));
                        this.form = cloneDeep(FORM_DEFAULT_DATA);
                        this.$v.form.$reset();
                        this.$emit('imported');
                    })
                    .catch(() => {});
            },
        },
    };
</script>
