<template>
    <div class="auth-page container-fluid">
        <div class="row w-100 min-height-100vh">
            <div class="col-sidebar col-12 col-sm-12 col-md-4 d-none d-md-flex">
                <div class="col-wrapper">
                    <AppLogo height="45px" />
                    <div class="my-4"></div>
                    <div class="hello-header">
                        <slot name="hello-header">
                            {{ $t('auth.helloHeader') }}
                        </slot>
                    </div>
                    <div class="hello-subheader">
                        <slot name="hello-subheader"></slot>
                    </div>
                    <template v-if="$te(`auth.${userType}.descriptionPoints`)">
                        <div class="my-3"></div>
                        <ul class="list">
                            <li v-for="point in $t(`auth.${userType}.descriptionPoints`)" :key="point">
                                {{ point }}
                            </li>
                        </ul>
                    </template>
                    <template v-if="$te(`auth.${userType}.bottomText`)">
                        <div class="my-4"></div>
                        <hr class="w-50 border-black ml-0 mr-auto">
                        <div class="my-2"></div>
                        <div class="bottom-text" v-html="$t(`auth.${userType}.bottomText`, { urlForShops: urlForShops })"></div>
                    </template>
                </div>
            </div>
            <div class="col-main col-12 col-sm-12 col-md-8">
                <div class="col-wrapper">
                    <AppLogo class="d-flex d-md-none mx-auto mb-4" height="45px" />
                    <div v-if="$slots['page-title']" class="h1">
                        <slot name="page-title"></slot>
                    </div>
                    <div v-if="$slots['page-title']" class="mb-2 mb-md-5"></div>
                    <slot :is-logging-social="isLoggingSocial"></slot>
                    <div v-if="$slots['divider']" class="my-3"></div>
                    <slot name="divider"></slot>
                    <div v-if="$slots['divider']" class="my-3"></div>
                    <slot v-if="!Object.keys($slots).includes('login-as-buttons')" name="login-as-buttons">
                        <div v-if="mode === 'WIDGETER'" class="d-flex flex-column gap-3">
                            <ButtonIcon
                                v-if="socialAuthGoogle"
                                variant="outline-black"
                                size="lg"
                                class="btn-login-as"
                                :disabled="isLoggingSocial"
                                :loading="socialAuthLogging.google"
                                @click="loginGoogle()"
                            >
                                <template #default>
                                    {{ $t('auth.loginWithGoogle') }}
                                </template>
                                <template #icon>
                                    <img src="@/assets/google-brands.svg" alt="" class="mr-3">
                                </template>
                            </ButtonIcon>
                            <ButtonIcon
                                v-if="socialAuthFacebook"
                                variant="outline-black"
                                size="lg"
                                class="btn-login-as"
                                :disabled="isLoggingSocial"
                                :loading="socialAuthLogging.facebook"
                                @click="loginFacebook()"
                            >
                                <template #default>
                                    {{ $t('auth.loginWithFacebook') }}
                                </template>
                                <template #icon>
                                    <img src="@/assets/facebook-f-brands.svg" alt="" class="mr-3">
                                </template>
                            </ButtonIcon>
                            <ButtonIcon
                                v-if="socialAuthApple"
                                variant="outline-black"
                                size="lg"
                                class="btn-login-as"
                                :disabled="isLoggingSocial"
                                :loading="socialAuthLogging.apple"
                                @click="loginApple()"
                            >
                                <template #default>
                                    {{ $t('auth.loginWithApple') }}
                                </template>
                                <template #icon>
                                    <img src="@/assets/apple-brands.svg" alt="" class="mr-3">
                                </template>
                            </ButtonIcon>
                        </div>
                    </slot>
                    <div v-if="!Object.keys($slots).includes('login-as-buttons') || $slots['login-as-buttons']" class="my-2"></div>
                    <slot name="cta">
                        <b-form-text class="text-black">
                            <span class="d-inline-block">
                                <slot name="cta-text"></slot>
                            </span>
                            <span v-if="$slots['cta-text'] && $slots['cta-link']" class="mx-1"></span>
                            <span class="d-inline-block">
                                <slot name="cta-link"></slot>
                            </span>
                        </b-form-text>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import upperFirst from 'lodash/upperFirst';
    import AppLogo from '@/components/AppLogo.vue';
    import { PROVIDERS } from '@/services/socialAuthService';
    import { mode } from '@/utils/util';

    export default {
        name: 'LayoutAuth',
        components: {
            AppLogo,
        },
        data() {
            return {
                urlForShops: `${process.env.VUE_APP_MARKETING_PAGE_URL}/dla-sklepow`,
                socialAuthLogging: Object.values(PROVIDERS).reduce((acc, currentVal) => {
                    const logging = { ...acc };
                    logging[currentVal] = false;
                    return logging;
                }, {}),
                socialAuthGoogle: process.env.VUE_APP_SOCIAL_AUTH_GOOGLE_ID,
                socialAuthFacebook: process.env.VUE_APP_SOCIAL_AUTH_FACEBOOK_ID,
                socialAuthApple: process.env.VUE_APP_SOCIAL_AUTH_APPLE_ID,
                userType: mode.toLowerCase(),
                mode,
                preselectedLoginProvider: this.$route.query.loginProvider,
            };
        },
        computed: {
            userRoleShort() {
                return this.$store.getters['auth/getRoleShortName'];
            },
            isLoggingSocial() {
                return Object.values(this.socialAuthLogging).some(item => item);
            },
        },
        methods: {
            loginGoogle() {
                this.socialLogin(PROVIDERS.GOOGLE);
            },
            loginFacebook() {
                this.socialLogin(PROVIDERS.FACEBOOK);
            },
            loginApple() {
                this.socialLogin(PROVIDERS.APPLE);
            },
            async socialLogin(provider) {
                this.socialAuthLogging[provider] = true;
                try {
                    await this.$store.dispatch('auth/authenticate', { socialAuth: provider });
                    this.$router.push({ name: 'Dashboard', params: { role: this.userRoleShort } }).catch(() => {});
                } catch (error) {
                    this.$toastr.error(this.$t('socialAuthLoginError', { provider: upperFirst(provider) }));
                }
                this.socialAuthLogging[provider] = false;
            },
            handleLoginEvent() {
                const provider = this.preselectedLoginProvider;
                if (provider && Object.values(PROVIDERS).includes(provider)) {
                    this.socialLoginSwitch(provider);
                    window.removeEventListener('click', this.handleLoginEvent);
                }
                this.preselectedLoginProvider = null;
            },
            socialLoginSwitch(provider) {
                if (provider === PROVIDERS.GOOGLE) {
                    this.loginGoogle();
                } else if (provider === PROVIDERS.FACEBOOK) {
                    this.loginFacebook();
                } else if (provider === PROVIDERS.APPLE) {
                    this.loginApple();
                }
            },
        },
        mounted() {
            if (this.preselectedLoginProvider) {
                window.addEventListener('click', this.handleLoginEvent);
                setTimeout(() => {
                    if (this.preselectedLoginProvider) {
                        this.handleLoginEvent();
                    }
                }, 200);
            }
        },
    };
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;900&display=swap');
    @import 'bootstrap/scss/functions';
    @import 'bootstrap/scss/variables';
    @import 'bootstrap/scss/mixins';
    @import '@/design/custom/_variables.scss';

    .min-height-100vh {
        min-height: 100vh;
    }

    html,
    body,
    .auth-page {
        width: 100%;
        min-height: 100vh;
    }

    .auth-page {
        $col-padding: map-get($spacers, 5);

        height: 100%;
        font-family: 'Roboto', sans-serif !important;
        font-size: $font-size-base;
        font-weight: 300;
        color: $black;
        background-color: $white;

        small {
            font-weight: 300;
        }

        * {
            font-family: 'Roboto', sans-serif !important;
        }

        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $black;
            font-weight: 900;
        }

        .form-control {
            padding: 1.75rem 1.5rem;
            @include font-size($input-font-size);
            border: 2px solid $black;
            font-weight: 300;

            &:focus {
                border-color: darken($brand, 7.5%);
            }
        }

        .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
            border-color: $gray-600;
        }

        .divider {
            width: 100%;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;

            &::before {
                width: 100%;
                height: 1px;
                content: '';
                display: inline-block;
                background: $black;
                margin-right: 10px;
            }

            &::after {
                width: 100%;
                height: 1px;
                content: '';
                display: inline-block;
                background: $black;
                margin-left: 10px;
            }
        }

        .btn-login {
            padding: 0.75rem 1.5rem;
        }

        .btn-login-as {
            font-size: $btn-font-size;
            padding: 0.75rem 1.5rem;
            width: 100%;
            max-width: 250px;
            margin-left: auto;
            margin-right: auto;

            img {
                height: 16px;
                transition: filter 0.15s ease-in-out;
            }

            &:hover {
                &:not(.disabled) {
                    img {
                        filter: brightness(0) invert(1);
                    }
                }

                &.disabled {
                    cursor: not-allowed;
                }
            }
        }

        .agreements {
            text-align: left;

            label,
            legend {
                font-size: 0.75rem;
                font-weight: normal;
            }
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            border-color: $brand;
            background-color: $brand;
        }

        .col-sidebar {
            flex-grow: 1;
            background-color: $accent;
            background-image: url('../assets/maziaj.png');
            background-repeat: no-repeat;
            background-position: bottom left;
            background-size: 40%;
            padding: $col-padding !important;

            a {
                color: $black;
                text-decoration: underline;

                &:hover {
                    color: $gray-800;
                }
            }

            .col-wrapper {
                display: flex;
                flex-direction: column;
            }
        }

        .hello-header {
            color: #fff;
            background: $brand;
            padding: map-get($spacers, 2);
            display: inline-block;
            margin-right: auto;
            font-size: 2rem;
            font-weight: bold;
        }

        .hello-subheader {
            display: inline-block;
            font-size: 1.75rem;

            strong {
                font-weight: 900;
            }
        }

        .list {
            padding: map-get($spacers, 3);
        }

        .col-main {
            flex-grow: 1;
            padding: $col-padding !important;

            .feedback {
                text-align: left;
            }

            .col-wrapper {
                max-width: 440px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                margin: auto;
            }
        }
    }
</style>
