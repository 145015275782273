<template>
    <div class="media user-profile mt-2 mb-2">
        <b-avatar :src="currentCampaign.logo" class="mr-2 shop-logo">
            <template v-if="!campaignsLoading">
                <feather v-if="!currentCampaign.logo" type="shopping-bag" class="text-dark" />
            </template>
            <b-skeleton v-else type="avatar" class="w-100 h-100" />
        </b-avatar>
        <div class="media-body">
            <h6 class="pro-user-name mt-0 mb-0" :title="currentCampaign.name">
                <template v-if="!campaignsLoading">
                    <template v-if="currentCampaign.id">
                        {{ currentCampaign.name }}
                    </template>
                    <template v-else>
                        ------
                    </template>
                </template>
                <b-skeleton v-else width="70%" />
            </h6>
            <span class="pro-user-desc">
                <template v-if="!campaignsLoading">
                    <template v-if="currentCampaign.isActive === true">{{ $t('active') }}</template>
                    <template v-else-if="currentCampaign.isActive === false">{{ $t('inactive') }}</template>
                    <template v-else>---</template>
                </template>
                <b-skeleton v-else width="40%" />
            </span>
        </div>
        <b-dropdown class="align-self-center flex-shrink-0" toggle-class="p-0">
            <template #button-content>
                <feather type="chevron-down" class="align-middle" />
            </template>
            <b-dropdown-item
                v-for="campaign in items"
                :key="campaign.id"
                :active="campaign === currentCampaign"
                :disabled="!campaign.id"
                @click="setCurrentCampaign(campaign)"
            >
                <span>{{ campaign.name }}</span>
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>



<script>
    export default {
        name: 'ShopSwitcher',
        computed: {
            campaignsLoading() {
                return this.$store.getters['campaigns/getLoading']('advertiserCampaigns');
            },
            campaigns() {
                return this.$store.getters['campaigns/getAdvertiserCampaigns'];
            },
            currentCampaign() {
                return this.$store.getters['campaigns/getCurrentCampaign'] || { id: 0 };
            },
            items() {
                return this.campaigns || [{ id: 0, name: `--${this.$t('noResult')}--` }];
            },
        },
        methods: {
            setCurrentCampaign(campaign) {
                this.$store.commit('campaigns/setCurrentCampaign', campaign);
            },
        },
    };
</script>



<style lang="scss" scoped>
    @import 'bootstrap/scss/functions';
    @import 'bootstrap/scss/variables';
    @import '@/design/_variables.scss';

    .user-profile {
        display: flex;
        padding: 15px 20px 15px 30px;

        .media-body {
            overflow: hidden;
        }

        .avatar-xs {
            display: none;
        }

        .pro-user-name {
            color: $menu-item-color-dark;
            font-weight: $font-weight-semibold;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .pro-user-desc {
            color: $menu-item-color-dark;
            text-transform: uppercase;
            font-size: 0.6rem;
            font-weight: 700;
            letter-spacing: 1px;
        }

        ::v-deep {
            .dropdown-toggle {
                background-color: rgba($menu-item-color-dark, 0.1);
                border-color: rgba($menu-item-color-dark, 0.1);

                &:hover {
                    background-color: rgba($menu-item-color-dark, 0.2);
                    border-color: rgba($menu-item-color-dark, 0.2);
                }
            }
        }
    }

    .shop-logo.b-avatar {
        background: #fff;

        ::v-deep {
            .b-avatar-img img {
                object-fit: contain;
            }
        }
    }
</style>
