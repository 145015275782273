import { get } from 'lodash';
import { i18n } from '@/i18n/i18n';
import apiService, { deleteFromCache } from './apiService';
import errorService from './errorService';
import store from '../store/store';
import { isAdminRole } from '@/utils/util';

const permissionsMap = {
    STATS_AVAILABILITY: 'stats_availability',
    STATS_PRICE_DISTRIBUTION: 'stats_price_distribution',
    STATS_SALES: 'stats_sales',
    STATS_CATEGORIES: 'stats_categories',
    STATS_PRODUCTS: 'stats_products',
    STATS_SHOPS: 'stats_shops',
};

export const CampaignStatus = Object.freeze({
    WAITING: '1',
    ACCEPTED: '2',
    REJECTED: '3',
    DISCONNECTED: '4',
});

export const translateCampaignStatus = status => {
    switch (status) {
        case CampaignStatus.WAITING:
            return i18n.t('campaignStatus.waiting');
        case CampaignStatus.ACCEPTED:
            return i18n.t('campaignStatus.accepted');
        case CampaignStatus.REJECTED:
            return i18n.t('campaignStatus.rejected');
        case CampaignStatus.DISCONNECTED:
            return i18n.t('campaignStatus.disconnected');
        default:
            return '-';
    }
};

export default {
    permissions: permissionsMap,

    demoSpace: {
        publisher: 'DEMO',
        releasingCommission: true,
        categories: [
            {
                id: 6,
                name: 'Electronics',
                isActive: true,
                dateCreate: '2016-02-09T00:00:00+00:00',
                dateUpdate: '2016-02-09T00:00:00+00:00',
            },
        ],
        id: 1,
        name: 'DEMO',
        url: 'http://100shoppers.com',
        description: '',
        type: '1',
        status: '2',
        createdAt: '2016-10-28T12:00:53+00:00',
        isDelete: false,
        permissions: Object.values(permissionsMap),
    },

    /**
     * Check if user has access to given resource
     * @param {string} permissionName - Name of permission
     * @return {boolean}
     */
    hasPermission(permissionName) {
        // if no permission name is not provided then resource is available
        if (!permissionName) {
            return true;
        }
        const permissions = store.getters['space/currentSpacePermissions'];
        // if there are no permissions available then resource is not available
        if (!permissions && permissions.length === 0) {
            return false;
        }
        // check if resource is in permissions list
        return permissions.includes(permissionName);
    },

    async fetchSpaces(userId, data, cache) {
        try {
            const userRoleShort = store.getters['auth/getRoleShortName'];
            const urlByRole = {
                widgeter: `${process.env.VUE_APP_API_URL}/api/v1/publishers/${userId}/spaces`,
                advertiser: `${process.env.VUE_APP_API_URL}/api/v1/reporters/${userId}/spaces`,
                admin: `${process.env.VUE_APP_API_URL}/api/v1/spaces`,
            };
            const url = isAdminRole(userRoleShort) ? urlByRole.admin : urlByRole[userRoleShort];
            const response = await apiService.get(url, data, null, null, cache);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async createSpace(publisherId, data) {
        const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/publishers/${publisherId}/spaces`, data);
        deleteFromCache([`/api/v1/publishers/${publisherId}/spaces`]);
        return response?.data;
    },

    async updateSpace(spaceId, data) {
        try {
            const response = await apiService.patch(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}`, data);
            deleteFromCache(['/api/v1/spaces$', `/api/v1/spaces/${spaceId}$`, '/api/v1/spaces\\?', '/api/v1/publishers/\\d+/spaces$', '/api/v1/publishers/\\d+/spaces\\?', '/api/v1/reporters/\\d+/spaces$', '/api/v1/reporters/\\d+/spaces\\?']);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSpace(spaceId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCampaigns({ spaceId, page, perPage, name, categories = [], statuses = [], userRole } = {}) {
        try {
            const userRoleShort = userRole || store.getters['auth/getRoleShortName'];
            const urlByRole = {
                widgeter: `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/campaigns`,
                advertiser: `${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/campaigns`,
                admin: `${process.env.VUE_APP_API_URL}/api/v1/campaigns`,
            };
            const url = isAdminRole(userRoleShort) ? urlByRole.admin : urlByRole[userRoleShort];
            const response = await apiService.get(url, {
                page,
                perPage,
                name,
                categories: categories.join(','),
                statuses: statuses.map(status => CampaignStatus[status]).join(','),
            });
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchAllowedCampaigns(spaceId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/allowed-campaigns`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchTextLinkCampaigns(spaceId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/textlink-campaigns`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchProducerProductCategories(spaceId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/producer-product-categories`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async activatePermission(spaceId, permission) {
        try {
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/subscriptions`, {
                permission,
            });
            deleteFromCache(['/api/v1/producers/\\d+/subscriptions']);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async deactivatePermission(subscriptionId) {
        try {
            const response = await apiService.put(`${process.env.VUE_APP_API_URL}/api/v1/subscriptions/${subscriptionId}/cancel`);
            deleteFromCache(['/api/v1/producers/\\d+/subscriptions']);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchPermissions(producerId, statuses) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/producers/${producerId}/subscriptions`, {
                statuses,
            });
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSpaceTypes() {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/space-types`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSpaceStatuses() {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/space-statuses`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchCategories() {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/categories`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchApiToken(spaceId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/api-token`, null, null, null, false);
            return response?.data;
        } catch (e) {
            if (get(e, 'response.data.code') === 404) {
                return null;
            }
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async generateApiToken(spaceId) {
        try {
            const response = await apiService.put(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/api-token`, null, null, null, false);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async revokeApiToken(spaceId) {
        try {
            const response = await apiService.delete(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/api-token`, null, null, null, false);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async acceptSpace(spaceId) {
        try {
            const response = await apiService.put(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/accept`);
            deleteFromCache(['/api/v1/spaces$', `/api/v1/spaces/${spaceId}$`, '/api/v1/spaces\\?', '/api/v1/publishers/\\d+/spaces$', '/api/v1/publishers/\\d+/spaces\\?', '/api/v1/reporters/\\d+/spaces$', '/api/v1/reporters/\\d+/spaces\\?']);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async rejectSpace(spaceId) {
        try {
            const response = await apiService.put(`${process.env.VUE_APP_API_URL}/api/v1/spaces/${spaceId}/reject`);
            deleteFromCache(['/api/v1/spaces$', `/api/v1/spaces/${spaceId}$`, '/api/v1/spaces\\?', '/api/v1/publishers/\\d+/spaces$', '/api/v1/publishers/\\d+/spaces\\?', '/api/v1/reporters/\\d+/spaces$', '/api/v1/reporters/\\d+/spaces\\?']);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchSpacePermissions(spaceId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/space-permissions`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    getStatusLabelType(statusId) {
        switch (Number.parseInt(statusId, 10)) {
            case 1:
                return 'info';
            case 2:
                return 'success';
            case 3:
                return 'error';
            default:
                return null;
        }
    },

    getPermissionStatusLabelType(status) {
        switch (status) {
            case 'active':
                return 'success';
            case 'canceled':
                return 'warning';
            case 'inactive':
                return 'default';
            default:
                return null;
        }
    },

    getPermissionContentKey(permission) {
        switch (permission) {
            case 'stats_availability':
                return {
                    title: 'permissionContent.title.reportsAvailability',
                    description: 'permissionContent.description.reportsAvailability',
                    url: 'producerReportsAvailability',
                };
            case 'stats_price_distribution':
                return {
                    title: 'permissionContent.title.reportsPriceDistribution',
                    description: 'permissionContent.description.reportsPriceDistribution',
                    // url: 'producerReportsPriceDistribution',
                };
            case 'stats_sales':
                return {
                    title: 'permissionContent.title.reportsSales',
                    description: 'permissionContent.description.reportsSales',
                    url: 'producerReportsSales',
                };
            case 'stats_categories':
                return {
                    title: 'permissionContent.title.reportsCategories',
                    description: 'permissionContent.description.reportsCategories',
                    url: 'producerReportsCategory',
                };
            case 'stats_products':
                return {
                    title: 'permissionContent.title.reportsProducts',
                    description: 'permissionContent.description.reportsProducts',
                    url: 'producerReportsProducts',
                };
            case 'stats_shops':
                return {
                    title: 'permissionContent.title.reportsShops',
                    description: 'permissionContent.description.reportsShops',
                    url: 'producerReportsShops',
                };
            default:
                return {};
        }
    },
};
