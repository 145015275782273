<template>
    <div class="position-relative">
        <flat-pickr
            ref="flatPickr"
            :value="value"
            class="form-control"
            v-bind="attrs"
            @input="onChangeDatePicker"
        />
        <button v-if="showClearButton" class="clear-button btn btn-link rounded-0 d-flex justify-content-center align-items-center h-100 position-absolute" @click="clearDate()">
            <feather type="x" size="12" class="clear-icon" />
        </button>
    </div>
</template>



<script>
    import { getFlapickrFormat, getFlatpickrLocale } from '@/i18n/i18n';

    export default {
        name: 'FormDatepicker',
        inheritAttrs: false,
        props: {
            value: {
                default: null,
                required: true,
                validator(value) {
                    return value === null || value instanceof Date || typeof value === 'string' || value instanceof String || value instanceof Array || typeof value === 'number';
                },
            },
            config: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                date: null,
            };
        },
        computed: {
            defaultConfig() {
                return {
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: getFlapickrFormat(this.$i18n.locale),
                    locale: getFlatpickrLocale(this.$i18n.locale),
                    maxDate: new Date(),
                };
            },
            pickerConfig() {
                return { ...this.defaultConfig, ...this.config };
            },
            attrs() {
                return { ...this.$attrs, config: this.pickerConfig };
            },
            isRangeMode() {
                return this.pickerConfig.mode === 'range';
            },
            showClearButton() {
                if (this.isRangeMode) return !!this.value?.[0];
                return !!this.value;
            },
        },
        methods: {
            clearDate() {
                this.$emit('input', null);
                this.$emit('from', null);
                this.$emit('to', null);
            },
            onChangeDatePicker(dateString) {
                let date = dateString;
                if (this.isRangeMode && typeof dateString === 'string') {
                    const sep = this.pickerConfig.locale.rangeSeparator;
                    date = dateString.split(sep);
                }
                if (date?.toString() !== this.value?.toString()) {
                    this.$emit('input', date);
                    if (this.isRangeMode) {
                        this.$emit('from', date?.[0] || null);
                        this.$emit('to', date?.[1] || null);
                    }
                }
            },
        },
    };
</script>



<style lang="scss" scoped>
    ::v-deep .clear-button {
        position: absolute;
        right: 1px;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
    }
</style>
