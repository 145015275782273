<template>
    <b-container class="m-auto error-404">
        <b-row>
            <b-col md="6" class="text-center text-md-right d-flex align-items-center">
                <div class="w-100 pr-md-3 mb-4 mb-md-0">
                    <img
                        src="../../../assets/logo-buybox.png"
                        alt="BUY.BOX"
                        class="logo"
                    >
                </div>
            </b-col>
            <b-col md="6" class="text-center text-md-left d-flex align-items-center">
                <div class="w-100 pl-md-3">
                    <div class="display-1">
                        {{ $t('dataNotLoaded.error') }}
                    </div>
                    <div class="h2">
                        {{ $t('dataNotLoaded.title') }}
                    </div>
                    <p>{{ $t('dataNotLoaded.text') }}</p>
                    <div class="d-flex">
                        <b-button variant="outline-secondary" :to="$route.path" @click="reloadPage">
                            {{ $t('dataNotLoaded.buttonText') }}
                        </b-button>
                        <div class="mx-1"></div>
                        <b-button variant="outline-secondary" :to="$route.path" @click="logout">
                            {{ $t('dataNotLoaded.loginAgain') }}
                        </b-button>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>



<script>
    export default {
        name: 'DataNotLoaded',
        methods: {
            reloadPage() {
                window.location.reload();
            },
            logout() {
                this.$store.dispatch('auth/logout');
            },
        },
    };
</script>



<style lang="scss" scoped>
    .logo {
        width: 30vw;
        max-width: 400px;
    }
</style>

