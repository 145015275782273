import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';

export default {
    name: 'ModalFormMixin',
    data() {
        return {
            item: null,
            form: {},
            submitting: false,
            error: null,
        };
    },
    validations() {
        return {
            form: {},
        };
    },
    computed: {
        itemId() {
            return this.item ? this.item.id : null;
        },
        action() {
            return this.itemId ? this.updateAction : this.createAction;
        },
        requestParams() {
            return this.getDataStructure(this.form);
        },
        apiParams() {
            const params = { requestParams: this.requestParams };
            if (this.itemId) params.urlParams = { id: this.itemId };
            return params;
        },
        successMessage() {
            return this.itemId ? this.$t('updateSuccess') : this.$t('createSuccess');
        },
        errorMessage() {
            return this.itemId ? this.$t('updateError') : this.$t('createError');
        },
        modalTitle() {
            return this.itemId ? this.$t('youEditX', { name: this.nameOfEditItem }) : this.$t('youAdd');
        },
        nameOfEditItem() {
            return this.item?.name || '';
        },
    },
    methods: {
        onInit(item) {
            this.resetData();
            this.initItem(item);
            this.initFormValues();
            this.afterInitFormValues();
        },
        resetData() {
            const data = this.$options.data.bind(this)();
            Object.keys(data).forEach(key => {
                if (this[key] !== undefined) this[key] = cloneDeep(data[key]);
            });
            this.$v.form.$reset();
        },
        initItem(item) {
            this.item = item || {};
        },
        setError(error) {
            this.error = error;
        },
        initFormValues() {
            const itemClone = cloneDeep(this.item);
            const itemPick = pick(itemClone, Object.keys(this.form));
            const formDataFormatted = this.formatFormData();
            this.form = { ...this.form, ...itemPick, ...formDataFormatted };
        },
        afterInitFormValues() {},
        formatFormData() {
            return {};
        },
        getDataStructure(data) {
            return data;
        },
        handleSubmitBefore() {},
        handleSubmit() {
            this.handleSubmitBefore();
            this.$v.form.$touch();
            if (this.$v.form.$invalid) return;
            this.submitting = true;
            this.error = null;
            this.formRequest()
                .then(this.requestSuccess)
                .catch(this.requestError)
                .finally(() => (this.submitting = false));
        },
        formRequest() {
            return this.$store.dispatch(this.action, this.apiParams);
        },
        afterError() {},
        afterSuccess(data) {},
        beforeSuccess(data) {
            return Promise.resolve();
        },
        requestError(error) {
            this.setError(error);
            // this.$toastr.error(error.message, this.errorMessage);
            this.afterError();
        },
        async requestSuccess(data) {
            await this.beforeSuccess(data);
            this.$toastr.success(this.successMessage);
            this.$refs.modal.hide();
            this.resetData();
            this.refreshData();
            this.afterSuccess(data);
        },
        refreshData() {
            this.$root.$emit(`fetch-data-${this.modalId}`);
        },
    },
    mounted() {
        this.$root.$on(`modal-form-${this.modalId}`, this.onInit);
    },
    beforeDestroy() {
        this.$root.$off(`modal-form-${this.modalId}`, this.onInit);
    },
};
