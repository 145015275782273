/* eslint-disable prettier-vue/prettier */
/* eslint-disable max-len */
export default {
    validator: {
        accepted: '"{attribute}" musi zostać zaakceptowany.',
        alpha_dash: 'Podana wartość może zawierać jedynie litery, cyfry i myślniki.',
        alpha_num: 'Podana wartość może zawierać jedynie litery i cyfry.',
        alpha: 'Podana wartość może zawierać jedynie litery.',
        between: '@:validator.type_between.numeric',
        boolean: 'Podana wartość musi mieć wartość prawda albo fałsz',
        confirmed: 'Potwierdzenie "{attribute}" nie zgadza się.',
        date_after_or_equal: 'Data musi być późniejsza lub równa niż {date}.',
        date_after: 'Data musi być późniejsza niż {date}.',
        date_before_or_equal: 'Data musi być wcześniejsza lub równa niż {date}.',
        date_before: 'Data musi być wcześniejsza niż {date}.',
        date_equals: 'Data musi być równa {date}.',
        date_format: 'Data nie jest w formacie {format}.',
        date: 'Podana wartość nie jest prawidłową datą.',
        decimal: 'Podana wartość musi być liczbą.',
        different: '"{attribute}" oraz "{other}" muszą się różnić.',
        digits_between: 'Podana wartość musi mieć od {min} do {max} cyfr.',
        digits: 'Podana wartość musi składać się z {digits} cyfr.',
        dimensions: '"{attribute}" ma niepoprawne wymiary.',
        email: 'Format e-mail jest nieprawidłowy.',
        ends_with: 'Podana wartość musi kończyć się jednym z następujących elementów: {values}.',
        file: '"{attribute}" musi być plikiem.',
        hexColor: 'Podana wartość musi być w formacie heksadecymalnym (np. #ffffff)',
        image: '"{attribute}" musi być obrazkiem.',
        integer: 'Podana wartość musi być liczbą całkowitą.',
        ip: 'Podana wartość musi być prawidłowym adresem IP.',
        ipAddress: '@:validator.ip',
        ipv4: 'Podana wartość musi być prawidłowym adresem IPv4.',
        ipv6: 'Podana wartość musi być prawidłowym adresem IPv6.',
        json: 'Podana wartość musi być poprawnym ciągiem znaków JSON.',
        maxLength: '@:validator.max.string',
        mimes: 'Podana wartość musi być plikiem typu {values}.',
        mimetypes: 'Podana wartość musi być plikiem typu {values}.',
        minLength: '@:validator.min.string',
        minValue: '@:validator.min.numeric',
        numeric: 'Podana wartość musi być liczbą.',
        password: 'Hasło jest nieprawidłowe.',
        required_if: 'Pole jest wymagane gdy wartość pola "{other}" jest podana.',
        required: 'Pole jest wymagane.',
        same: 'Pole "{attribute}" i "{other}" muszą być takie same.',
        sameAs: '@:validator.same',
        starts_with: 'Podana wartość musi zaczynać się od jednego z następujących elementów: {values}.',
        string: 'Podana wartość musi być ciągiem znaków.',
        timezone: 'Podana wartość musi być prawidłową strefą czasową.',
        tooGeneralUrl: 'Podany adres jest zbyt ogólny. Wpisz pełen adres url swojej strony lub profilu w social mediach.',
        unique: 'Podana wartość już występuje.',
        url: 'Podana wartość nie jest poprawnym adresem url.',
        valid: 'Pole ma prawidłową wartość.',
        min: {
            numeric: 'Podana wartość musi być nie mniejsza niż {min}.',
            file: 'Wielkość pliku musi mieć przynajmniej {min} kilobajtów.',
            string: 'Długość tekstu musi mieć przynajmniej {min} znaków.',
        },
        max: {
            numeric: 'Podana wartość nie może być większa niż {max}.',
            file: 'Wielkość pliku nie może być większa niż {max} kilobajtów.',
            string: 'Długość tekstu nie może mieć więcej niż {max} znaków.',
        },
        lt: {
            numeric: 'Podana wartość musi być mniejsza niż {value}.',
            file: 'Wielkość pliku musi być mniejsza niż {value} kilobajtów.',
            string: 'Długość tekstu musi być mniejsza niż {value} znaków.',
        },
        lte: {
            numeric: 'Podana wartość musi być mniejsza niż lub równy {value}.',
            file: 'Wielkość pliku musi być mniejsza niż lub równy {value} kilobajtów.',
            string: 'Długość tekstu musi być mniejsza niż lub równy {value} znaków.',
        },
        gt: {
            numeric: 'Podana wartość musi być większa niż {value}.',
            file: 'Wielkość pliku musi być większa niż {value} kilobajtów.',
            string: 'Długość tekstu musi być większa niż {value} znaków.',
        },
        gte: {
            numeric: 'Podana wartość musi być większa lub równy {value}.',
            file: 'Wielkość pliku musi być większa lub równy {value} kilobajtów.',
            string: 'Długość tekstu musi być większa lub równy {value} znaków.',
        },
        type_between: {
            numeric: 'Podana wartość musi zawierać się w granicach {min} - {max}.',
            file: 'Wielkość pliku musi zawierać się w granicach {min} - {max} kilobajtów.',
            string: 'Długość tekstu musi zawierać się w granicach {min} - {max} znaków.',
        },
        size: {
            numeric: 'Podana wartość musi mieć {size}.',
            file: 'Wielkość pliku musi mieć {size} kilobajtów.',
            string: 'Długość tekstu musi mieć {size} znaków.',
        },
    },
    validation: {
        required: 'To pole jest wymagane.',
        email: 'Proszę o podanie prawidłowego adresu email.',
        url: 'Proszę o podanie prawidłowego adresu URL.',
        sameAs: 'Proszę o podanie tej samej wartości ponownie.',
        regex: 'Ta wartość ma niepoprawny format.',
        numeric: 'Proszę o podanie prawidłowego numeru.',
        maxLength: 'Proszę o wpisanie do {max} znaków',
        hexColor: 'Proszę o podanie prawidłowego koloru HEX (np. #000000).',
        bankAccount: 'Proszę o podanie prawidłowego numeru konta bankowego.',
    },
    error: {
        default: 'Coś poszło nie tak. Spróbuj ponownie lub skontaktuj się z pomocą techniczną.',
        network: 'Nie można połączyć się z serwerem.',
        notFound: 'Nie można połączyć się z serwerem.',
        unauthorized: 'Brak wymaganych uprawnień.',
        ERROR: '@:error.default',
        BAD_CREDENTIALS: 'Błędne logowanie.',
        BAD_REQUEST: 'Niepoprawne dane.',
        INVALID_REQUEST_DATA: 'Niepoprawne dane.',
        AMOUNT_BELOW_MINIMUM: 'Kwota poniżej minimum.',
        endpoint: 'Endpoint nie istnieje.',
        endpointParams: 'Nie przekazano wymaganych parametrów.',
    },
    api_errors: {
        bad_credentials: {
            base: 'Błędne logowanie.',
        },
        conflict: {
            transaction: 'Transakcja została już zweryfikowana.',
        },
        not_found: {
            base: 'Nie znaleziono',
            brand: 'Marka nie istnieje',
            buybox: 'Widżet nie istnieje',
            campaign: 'Kampania nie istnieje',
            campaign_api_token: 'Kampania nie ma tokena API. Najpierw wygeneruj jeden.',
            campaign_not_related_buybox: 'Kampania nie jest powiązana z Buyboxem!',
            campaign_space: 'Relacja nie istnieje!',
            category: 'Kategoria nie istnieje',
            commission: 'Prowizja nie istnieje',
            cookie: 'Plik cookie nie istnieje',
            document: 'Dokument nie istnieje',
            manufacturer: 'Producent nie istnieje',
            offer: 'Oferta nie istnieje',
            offer_campaign: 'Kampania ofertowa nie istnieje',
            offer_feed: 'Kanał ofert nie istnieje',
            product: 'Produkt nie istnieje',
            report: 'Raport nie istnieje',
            space: 'Powierzchnia nie istnieje',
            space_no_buybox: 'Powierzchnia nie ma Buyboxa!',
            space_no_category: 'Powierzchnia nie ma kategorii!',
            space_no_token: 'Powierzchnia nie ma tokena API',
            space_token: 'Nie można znaleźć powierzchni',
            subscription: 'Subskrypcja nie istnieje',
            token: 'Token wygasł!',
            transaction: 'Transakcja nie istnieje',
            transaction_invalid_amount: 'Nieprawidłowa kwota transakcji!',
            user: 'Użytkownik nie istnieje',
        },
        unprocessable_entity: {
            amount_below_minimum: 'Kwota poniżej minimum',
            campaign_assign_buybox: 'Kampania nie może zostać przypisać do Buyboxa',
            campaign_assign_commission: 'Prowizja nie jest przypisana do kampanii!',
            email_not_confirmed: 'Email nie jest potwierdzony!',
            locked_user: 'Konto użytkownika jest zablokowane!',
            missing_personal_data: 'Brakujące dane osobowe',
            not_first_request_of_month: 'Możesz wnioskować tylko o jedną wypłatę w miesiącu!',
            space_accept: 'Nie możesz zaakceptować tej powierzchni!',
            space_reject: 'Nie możesz odrzucić tej powierzchni!',
            space_subscription: 'Powierzchnia ma aktywną subskrypcję',
            report_not_generated: 'Raport nie jest wygenerowany!',
            user: {
                email: {
                    exist: 'Użytkownik z nazwą użytkownika/adresem e-mail już istnieje!',
                },
                not: {
                    advertiser: 'Użytkownik nie jest reklamodawcą!',
                    publisher: 'Użytkownik nie jest wydawcą!',
                },
            },
            wrong_document_type: 'Nieprawidłowy typ dokumentu',
        },
        access_denied: {
            base: 'Brak dostępu.',
            feed: 'Nie możesz utworzyć kanału dla tego Buyboxa!',
            feature_disabled: 'Funkcja wyłączona',
            functionality_inactive: 'Funkcjonalność nie jest aktywna!',
            token: 'Token wygasł!',
        },
        http: {
            bi_api: 'Problem z API BI',
            database: 'Błąd bazy danych',
            not_implemented: 'Nie zaimplementowano',
        },
        bad_request: {
            wrong_xml_format: 'Nieprawidłowy format pliku XML!',
            not_xml: 'Nieprawidłowy format pliku!',
            amount_gross: "'brutto' nie może być negatywny!",
            amount_net: "'netto' nie może być negatywny!",
            amount_price_limit: 'Dolny limit ceny nie powinien przekraczać górnego limitu',
            campaign_id: 'Nie ustawiono ID kampanii!',
            cookie: 'Nie ustawiono ID pliku cookie!',
            email: 'E-mail nie może być pusty!',
            image_url: 'Nieprawidłowy URL obrazu',
            order: 'Nie ustawiono ID zamówienia!',
            product: {
                commission: {
                    id: 'Nie ustawiono ID prowizji za produkt!',
                },
                gross: 'Nie ustawiono wartości brutto produktu!',
                id: 'Nie ustawiono ID produktu!',
                net: 'Nie ustawiono wartości netto produktu!',
                quantity: 'Nie ustawiono ilości produktu!',
                specification: 'Nieprawidłowa specyfikacja produktów!',
            },
            quantity: {
                value: 'Ilość ma wartość ujemną!',
            },
            query: 'Puste zapytanie',
            refresh_token: 'Brak parametru wyszukiwania',
            required_parameters: 'Brak wymaganych parametrów!',
            response_format: 'Nieobsługiwany format odpowiedzi!',
            search: 'Brak parametru wyszukiwania',
            space_id: 'Nie ustawiono ID miejsca!',
            url_missing: 'Brak parametru adresu URL',
        },
        form: {
            should_be_false: 'Ta wartość powinna być fałszywa.',
            should_be_true: 'Ta wartość powinna być prawdziwa.',
            should_be_type: 'Ta wartość powinna być typu {type}.',
            should_be_blank: 'Ta wartość powinna być pusta.',
            select_wrong_choice: 'Wybrana wartość nie jest prawidłowym wyborem.',
            select_wrong_min_limit: 'Musisz wybrać co najmniej {limit}.',
            select_wrong_max_limit: 'Musisz wybrać co najwyżej {limit}.',
            wrong_values: 'Co najmniej jedna z podanych wartości jest nieprawidłowa.',
            field_not_expected: 'To pole nie było oczekiwane.',
            field_missing: 'Brak tego pola.',
            not_valid_date: 'Ta wartość nie jest prawidłową datą.',
            not_valid_datetime: 'Ta wartość nie jest prawidłową datą i godziną.',
            not_valid_email: 'Ta wartość nie jest prawidłowym adresem e-mail.',
            file_not_found: 'Nie znaleziono pliku.',
            file_not_readable: 'Plik nie jest czytelny.',
            file_not_readable_too_large: 'Plik jest za duży ({size} {suffix}). Dozwolony maksymalny rozmiar to {limit} {suffix}.',
            file_invalid_mimetype: 'Typ MIME pliku jest nieprawidłowy ({type}). Dozwolone typy MIME to {types}.',
            should_be_limit_max: 'Ta wartość powinna wynosić {limit} lub mniej.',
            should_be_long_max: 'Ta wartość jest za długa. Powinna mieć {limit} znaków lub mniej.',
            should_be_limit_min: 'Ta wartość powinna wynosić co najmniej {limit}.',
            should_be_long_min: 'Ta wartość jest za krótka. Powinna mieć {limit} znaków lub więcej.',
            not_blank: 'Ta wartość nie powinna być pusta.',
            not_null: 'Ta wartość nie powinna być pusta.',
            null: 'Ta wartość powinna być pusta.',
            not_valid: 'Ta wartość jest nieprawidłowa.',
            not_valid_time: 'Ta wartość nie jest poprawną godziną.',
            not_valid_url: 'Ta wartość nie jest prawidłowym adresem URL.',
            two_values_equal: 'Obie wartości powinny być równe.',
            file_large_limit: 'Plik jest za duży. Dozwolony maksymalny rozmiar to {limit} {suffix}.',
            file_large: 'Plik jest za duży.',
            file_not_uploaded: 'Nie udało się przesłać pliku.',
            not_valid_number: 'Ta wartość powinna być liczbą.',
            not_valid_image: 'Ten plik nie jest prawidłowym obrazem.',
            not_valid_ip: 'To nie jest prawidłowy adres IP.',
            not_valid_language: 'Ta wartość nie jest prawidłowym językiem.',
            not_valid_locale: 'Ta wartość nie jest poprawną lokalizacją.',
            not_valid_country: 'Ta wartość nie jest prawidłowym krajem.',
            already_used: 'Ta wartość jest już używana.',
            image_size_not_detected: 'Nie można wykryć rozmiaru obrazu.',
            image_width_too_big: 'Szerokość obrazu jest za duża ({width}px). Dozwolona maksymalna szerokość to {max_width}px.',
            image_width_too_small: 'Szerokość obrazu jest za mała ({width}px). Minimalna oczekiwana szerokość to {min_width}px.',
            image_height_too_big: 'Wysokość obrazu jest za duża ({height}px). Dozwolona maksymalna wysokość to {max_height}px.',
            image_height_too_small: 'Wysokość obrazu jest za mała ({height}px). Minimalna oczekiwana wysokość to {min_height}px.',
            should_be_user_current_password: 'Ta wartość powinna być aktualnym hasłem użytkownika.',
            should_have_exactly_limit: 'Ta wartość powinna mieć dokładnie {limit} znaków.',
            file_only_partially_uploaded: 'Plik został przesłany tylko częściowo.',
            file_no_uploaded: 'Żaden plik nie został przesłany.',
            no_temporary_folder: 'W php.ini nie skonfigurowano folderu tymczasowego.',
            temporary_cant_write: 'Nie można zapisać pliku tymczasowego na dysku.',
            PHP_extension_caused_upload_fail: 'Rozszerzenie PHP spowodowało niepowodzenie przesyłania.',
            should_contain_max: 'Ta kolekcja powinna zawierać co najmniej {limit} elementów.',
            should_contain_min: 'Ta kolekcja powinna zawierać {limit} elementów lub mniej.',
            should_contain_exactly: 'Ta kolekcja powinna zawierać dokładnie {limit} elementów.',
            not_valid_card_number: 'Nieprawidłowy numer karty.',
            not_valid_card_number_or_type: 'Nieobsługiwany typ karty lub nieprawidłowy numer karty.',
            not_valid_IBAN: 'To nie jest prawidłowy międzynarodowy numer konta bankowego (IBAN).',
            'not_valid_ISBN-10': 'Ta wartość nie jest prawidłowym numerem ISBN-10.',
            'not_valid_ISBN-13': 'Ta wartość nie jest prawidłowym numerem ISBN-13.',
            'not_valid_ISBN-10_SBN-13': 'Ta wartość nie jest ani poprawnym ISBN-10, ani poprawnym ISBN-13.',
            not_valid_ISSN: 'Ta wartość nie jest prawidłowym numerem ISSN.',
            not_valid_currency: 'Ta wartość nie jest prawidłową walutą.',
            should_be_equal: 'Ta wartość powinna być równa {compared_value}.',
            should_be_greater: 'Ta wartość powinna być większa niż {compared_value}.',
            should_be_greater_or_equal: 'This value should be greater than or equal to {compared_value}.',
            should_be_identical: 'Ta wartość powinna być identyczna z {compared_value_type} {compared_value}.',
            should_be_less: 'Ta wartość powinna być mniejsza niż {compared_value}.',
            should_be_less_or_equal: 'Ta wartość powinna być mniejsza lub równa {compared_value}.',
            should_not_be_qual: 'Ta wartość nie powinna być równa {compared_value}.',
            should_not_be_identical: 'Ta wartość nie powinna być identyczna z {compared_value_type} {compared_value}.',
            image_ratio_too_big: 'Współczynnik proporcji obrazu jest za duży ({ratio}). Dozwolony maksymalny współczynnik proporcji to {max_ratio}.',
            image_ratio_too_small: 'Współczynnik proporcji obrazu jest za mały ({ratio}). Minimalny oczekiwany współczynnik proporcji to {min_ratio}.',
            image_square: 'Obraz jest kwadratowy ({width}x{height}px). Kwadratowe obrazy są niedozwolone.',
            image_landscape_oriented: 'Obraz jest zorientowany poziomo ({width}x{height}px). Obrazy w orientacji poziomej są niedozwolone.',
            image_portrait_oriented: 'Obraz jest zorientowany pionowo ({width}x{height}px). Obrazy w orientacji pionowej są niedozwolone.',
            not_empty: 'Pusty plik jest niedozwolony.',
            wrong_host: 'Nie można znaleźć hosta.',
            wrong_charset: 'Ta wartość nie odpowiada oczekiwanemu zestawowi znaków {charset}.',
            not_valid_BIC: 'To nie jest prawidłowym kodem identyfikacyjny firmy (BIC).',
            error: 'Błąd',
            not_valid_UUID: 'To nie jest prawidłowy UUID.',
            should_be_multiple: 'Ta wartość powinna być wielokrotnością {compared_value}.',
            BIC_not_associated_IBAN: 'Ten kod identyfikacyjny firmy (BIC) nie jest powiązany z IBAN {iban}.',
            should_be_JSON: 'Ta wartość powinna być poprawnym JSON.',
            collection_should_contain_unique_elements: 'Ta kolekcja powinna zawierać tylko unikalne elementy.',
            should_be_positive: 'Ta wartość powinna być dodatnia.',
            should_be_positive_or_zero: 'Ta wartość powinna być dodatnia lub zerowa.',
            should_be_negative: 'Ta wartość powinna być ujemna.',
            should_be_negative_or_zero: 'Ta wartość powinna być ujemna lub zerowa.',
            not_valid_timezone: 'Ta wartość nie jest prawidłową strefą czasową.',
            password_leaked_use_another: 'To hasło zostało ujawnione w wyniku naruszenia danych, nie wolno go używać. Użyj innego hasła.',
            should_be_between: 'Ta wartość powinna wynosić od {min} do {max}.',
            not_valid_hostname: 'Ta wartość nie jest prawidłową nazwą hosta.',
            should_be_multiply: 'Liczba elementów w tej kolekcji powinna być wielokrotnością {compared_value}.',
            should_satisfy_following_constraints: 'Ta wartość powinna spełniać co najmniej jedno z następujących ograniczeń',
            elements_should_have_own_constraints: 'Każdy element tej kolekcji powinien spełniać swój własny zestaw ograniczeń.',
            not_valid_ISIN: 'Ta wartość nie jest prawidłowym międzynarodowym numerem identyfikacyjnym papierów wartościowych (ISIN).',
            should_not_contain_extra_fields: 'Formularz nie powinien zawierać dodatkowych pól.',
            not_valid_EAN: 'Ta wartość nie jest prawidłowym kodem EAN.',
            not_valid_regexp: 'Ta wartość nie jest prawidłowym wyrażeniem regularnym.',
            value_already_used: 'Ta wartość jest już wykorzystana.',
        },
        form_type: {
            birthdate: 'Proszę o podanie prawidłowej daty urodzenia.',
            checkbox: 'To pole ma nieprawidłową wartość.',
            choice: 'Wybrana opcja jest nieprawidłowa.',
            collection: 'Lista jest nieprawidłowa.',
            color: 'Proszę o wybranie prawidłowego koloru.',
            country: 'Proszę o wybranie prawidłowego kraju.',
            currency: 'Proszę o wybranie prawidłownej waluty.',
            date_interval: 'Proszę o wybranie prawidłowego zakresu dat.',
            date_time: 'Proszę o podanie prawidłowej daty i czasu',
            date: 'Proszę o podanie prawidłowej daty.',
            email: 'Proszę o podanie prawidłowego adresu email.',
            file: 'Proszę o wybranie prawidłowego pliku.',
            form: 'Ta wartość jest nieprawidłowa.',
            hidden: 'Ukryte pole jest nieprawidłowe.',
            integer: 'Proszę o podanie wartości liczbowej.',
            language: 'Proszę o wybranie prawidłowego języka.',
            locale: 'Proszę o wybranie prawidłowego regionu.',
            money: 'Proszę o podanie prawidłowej kwoty.',
            password: 'Hasło jest nieprawidłowe.',
            percentage: 'Proszę o podanie prawidłowej wartości procentowej.',
            radio: 'Proszę o wybranie prawidłowej opcji.',
            range: 'Proszę o wybranie prawidłowego zakresu.',
            repeated: 'Wartości nie pasują.',
            search: 'Proszę o podanie prawidłowego wyszukiwanego hasła.',
            time: 'Proszę o podanie prawidłowego czasu.',
            timezone: 'Proszę o wybranie prawidłowej strefy czsowej.',
            ulid: 'Proszę o podanie prawidłowego ULID.',
            url: 'Proszę o podanie prawidłowego URL.',
            week: 'Proszę o podanie prawidłowej wartosci tygodnia.',
        },
        producer_product: {
            ean_already_exists: 'Numer EAN istnieje już w katalogu produktów.',
            sku_already_exists: 'Numer SKU istnieje już w katalogu produktów.',
        },
        products_used_brand: 'Istnieją produkty przypisane do tej marki',
        category_parent: 'Nie możesz usunąć kategorii nadrzędnej',
        products_used_category: 'Istnieją produkty przypisane do tej kategorii',
        brands_used_manufacturer: 'Istnieją marki przypisane do tego producenta',
        variants_used_manufacturer: 'Istnieją warianty przypisane do tego producenta',
        variants_used_product: 'Istnieją marki przypisane do tego produktu',
    },
    language: {
        pl: 'Polski',
        en: 'Angielski',
        lt: 'Litewski',
        lv: 'Łotewski',
        et: 'Estoński',
        de: 'Niemiecki',
        dk: 'Duński',
        fi: 'Fiński',
        fr: 'Francuski',
        nl: 'Niderlandzki',
    },
    timeShort: {
        days: 'd',
        hours: 'g',
        minutes: 'm',
        seconds: 's',
        quarter: 'Q',
    },
    role: {
        producer: 'Producent',
        widgeter: 'Wydawca',
        advertiser: 'Reklamodawca',
        admin: 'Admin',
    },
    selectField: {
        placeholder: 'Wpisz nazwę, aby wyszukać',
        selectedLabel: '✔',
        selectGroupLabel: '✚',
        selectLabel: '✚',
        deselectLabel: '✖',
        deselectGroupLabel: '✖',
        limitText: 'i {count} więcej',
        noResult: '@:noResult',
        noOptions: 'Brak elementów',
        searchNotFoundText: 'Nie znaleziono elementów. Rozważ zmianę zapytania wyszukiwania.',
        noOptionsText: 'Lista jest pusta.',
        searchPlaceholder: 'Wpisz frazę aby wyszukać',
        selectedOptions: 'Wybrane opcje',
        previouslySelectedOptions: 'Wcześniej wybrane',
        minCharToSearchText: 'Wpisz min. {min} znaki',
        optionsLoadingText: 'Trwa wyszukiwanie...',
    },
    fileField: {
        browseText: 'Dodaj',
        dropPlaceholder: 'Upuść pliki tutaj',
        noDropPlaceholder: 'Nie dozwolony',
        placeholder: 'Dodaj plik',
    },
    sidebar: {
        dashboard: '@:dashboard.title',
        widget: 'Widżet BUY.BOX',
        widgetSettings: '@:widgetSettings.title',
        generator: '@:generator.title',
        textLinks: '@:textLinks.title',
        productLandingPage: 'LP produktowy',
        productLandingPageGenerator: 'Generator',
        productLandingPageSettings: 'Ustawienia',
        reports: 'Raporty',
        statByDay: '@:statByDay.title',
        statByMonth: '@:statByMonth.title',
        transactions: '@:transactions.title',
        reportsPages: '@:reportsPages.title',
        reportsShops: '@:reportsShops.title',
        reportsProducts: '@:reportsProducts.title',
        settings: 'Ustawienia',
        pages: '@:pages.title',
        shops: 'Sklepy',
        finance: '@:finance.title',
        api: '@:api.title',
        integrations: '@:widgeterSettingsIntegrations.title',
        webhook: 'Webhook',
        webpagesPublishers: '@:webpagesPublishers',
        productImport: '@:productImport',
        shopPresentation: '@:shopPresentation',
        shopLocations: '@:shopLocations',
        offersCatalog: '@:offersCatalog',
        catalog: 'Katalog',
        catalogProducts: 'Produkty',
        catalogManufacturers: 'Producenci',
        catalogBrands: 'Marki',
        catalogCategories: 'Kategorie',
        catalogModeration: '@:moderation',
        reportsConversion: '@:conversionReport',
        reportsIntegrationStatus: '@:integrationStatus',
        reportPages: 'Raport stron',
        reportShops: 'Raport sklepów',
        reportProducts: 'Raport produktów',
        gdziekupicTo: 'Gdziekupic.to',
        users: 'Użytkownicy',
        verifications: 'Weryfikacja',
        webpagesAdmin: '@:webpages',
        tipbox: 'tip.BOX',
        offers: '@:offerFinder',
        settlements: 'Rozliczenia',
        tipSettlements: 'Rozliczenia napiwków',
        campaigns: '@:campaigns',
        producerProducts: '@:producerProducts',
        producerProductsImport: 'Import',
        advertiserSettlements: 'Raport rozliczeń sklepów',
        advertiserSettlementsList: 'Lista faktur',
        productSearch: 'Wyszukiwarka produktów',
        transactionsImport: 'Import transakcji',
        transactions2: 'Transakcje',
        transactionValidation: 'Walidacja transakcji',
        settlementsWithPublishers: 'Rozliczenia z wydawcami',
        feedImportSummary: 'Podsumowanie importu feedów',
        tipboxLanding: 'Twój tipbox.link',
        subscriptions: 'Subskrypcje',
        bbAffiliateProgram: 'Program Partnerski BB',
        stats: 'Statystyki',
        tips: 'Napiwki',
        verification: 'Weryfikacja',
        widgetTipbox: 'Widget tip.BOX',
        tipboxTips: 'Wpłaty',
        tipboxSettlements: 'Rozliczenia',
        tipboxSettings: 'Widget i ustawienia',
        tipboxLink: 'Twój profil tipbox.link',
        transactionCorrection: 'Korekcja transakcji',
    },
    login: {
        title: 'Zaloguj się',
        welcome: 'Witaj ponownie',
        hint: 'Podaj adres email i hasło, aby zalogować się do panelu BUY.BOX.',
        rememberMe: 'Zapamiętaj mnie',
        resetPassword: 'Nie pamiętasz hasła?',
        enterLogin: 'Podaj login',
        enterPassword: 'Podaj hasło',
        password: 'Hasło',
        submit: 'Zaloguj',
        registerBefore: 'Nie masz jeszcze konta?',
        registerLink: 'Zarejestruj się',
        registerUrl: 'https://getbuybox.com/zarejestruj-sie/',
    },
    loginAs: {
        title: 'Logowanie do konta BUY.BOX',
        missingToken: 'Brak tokena autoryzującego.',
    },
    adminPanelButton: {
        label: 'Powrót do panelu admina',
    },
    acceptTerms: {
        title: 'Zmiana regulaminu',
        description: 'Informujemy, że zmianie uległy niektóre zasady funkcjonowania naszej platformy. Aby przejść dalej musisz zaakceptować {link}.',
        descriptionLink: 'nowy regulamin',
        accept: 'Akceptuj',
        cancel: 'Anuluj',
        url: 'regulamin',
    },
    dashboard: {
        title: 'Dashboard',
        impressions: 'Odsłony',
        clicks: 'Kliknięcia',
        transactions: 'Transakcje',
        commission: 'Prowizja',
        yourCommission: 'Twoja prowizja',
        newCommission: 'Nowa',
        confirmedCommission: 'Potwierdzona',
        toWithdraw: 'Do wypłaty',
        withdraw: 'Wypłać',
        overview: 'Przegląd',
        newCampaigns: 'Nowe sklepy',
        recentTransactions: 'Ostatnie transakcje',
        campaign: 'Sklep',
        space: 'Strona',
        category: 'Kategoria',
        amount: 'Kwota',
        rangeLast7Days: 'Ostatnie 7 dni',
        rangeCurrentWeek: 'Bieżący tydzień',
        rangeLast30days: 'Ostatnie 30 dni',
        rangeCurrentMonth: 'Bieżący miesiąc',
        yourBestPages: 'Twoje najlepsze strony',
        yourBestShops: 'Twoje najlepsze sklepy',
        yourBestProducts: 'Twoje najlepsze produkty',
        product: 'Produkt',
        unitsSold: 'Sprzedanych sztuk',
        to: 'do',
        salesValue: 'Wartość sprzedaży',
        ourCommission: 'Prowizja nasza',
        advertiserCommission: 'Prowizja wydawców',
        newPublishers: 'Nowych wydawców',
        newSpaces: 'Nowych powierzchni',
        lastAddedSpaces: 'Ostatnio dodane powierzchnie',
    },
    widgeterReportsShops: {
        title: 'Raport sklepów',
        campaigns: 'Statystyki sklepów',
        campaign: 'Sklep',
        impressions: 'Odsłony',
        clicks: 'Kliknięcia',
        transactions: 'Transakcje',
        commission: 'Prowizja',
        transactionAmount: 'Kwota transakcji',
    },
    widgeterReportsPages: {
        title: 'Raport stron',
        pageStats: 'Statystyki stron',
        space: 'Strona',
        impressions: 'Odsłony widżetu',
        clicks: 'Kliknięcia',
        ctr: 'CTR',
        transactions: 'Liczba transakcji',
        conversion: 'Konwersja',
        transactionAmount: 'Kwota transakcji',
        commission: 'Prowizja',
    },
    widgeterDropdown: {
        accountSettings: 'Ustawienia konta',
        logout: 'Wyloguj się',
    },
    resetPassword: {
        title: 'Zresetuj hasło',
        description: 'Podaj adres e-mail aby rozpocząć resetowanie hasła.',
        email: 'E-mail',
        submit: 'Dalej',
        back: 'Wróć do',
        backName: 'strony logowania',
        success: 'Wiadomość została wysłana na Twój adres e-mail, {email}. Postępuj zgodnie ze wskazówkami w e-mailu, aby zresetować hasło.',
    },
    resetPasswordComplete: {
        title: 'Nowe hasło',
        description: 'Wprowadź nowe hasło, aby zakończyć proces resetowania hasła.',
        password: 'Hasło',
        confirmPassword: 'Powtórz hasło',
        submit: 'Zapisz',
        back: 'Wróć do',
        backName: 'strony logowania',
        success: 'Nowe hasło zostało ustawione. Możesz teraz zalogować się do swojego konta za jego pomocą.',
    },
    authSidebar: {
        title: 'Zarabiaj z BUY.BOX',
        description: 'Wypróbuj darmowy widżet na swojego bloga lub serwis',
    },
    accountSettings: {
        title: 'Ustawienia konta',
    },
    accountSettingsPersonal: {
        title: 'Ustawienia osobiste',
        firstName: 'Imię',
        lastName: 'Nazwisko',
        email: 'E-mail',
        phone: 'Numer telefonu',
        sectionNotifications: 'Powiadomienia',
        notifications: 'Pakiet powiadomień',
        sectionLanguage: 'Język',
        language: 'Język',
        submit: '@:save',
        saveSuccess: 'Zaktualizowano ustawienia osobiste.',
    },
    accountSettingsAddress: {
        title: 'Ustawienia adresowe',
        companyAccount: 'Konto firmowe',
        companyName: 'Nazwa firmy',
        companyNip: 'NIP',
        street: 'Ulica',
        streetNumber: 'Numer budynku',
        apartmentNumber: 'Numer lokalu',
        city: 'Miasto',
        postcode: 'Kod pocztowy',
        country: 'Kraj',
        bankAccount: 'Numer konta bankowego',
        submit: '@:save',
        saveSuccess: 'Zaktualizowano ustawienia adresowe.',
    },
    accountSettingsPassword: {
        title: 'Ustawienia hasła',
        oldPassword: 'Stare hasło',
        newPassword: 'Nowe hasło',
        confirmPassword: 'Powtórz nowe hasło',
        submit: '@:save',
        saveSuccess: 'Zmieniono hasło.',
    },
    accountSettingsNotifications: {
        title: 'Ustawienia powiadomień',
        news: 'Aktualności i ważne informacje',
        isNotification: 'Aktualności i ważne informacje',
        programs: 'Nowe sklepy',
        promotions: 'Promocje w sklepach',
        display: 'Kreacje reklamowe',
        tips: 'Porady',
        submit: '@:save',
        saveSuccess: 'Zaktualizowano ustawienia powiadomień.',
        notifications: 'Chcę otrzymywać mailowe powiadomienia',
        isLocked: 'Blokada',
        demo: 'Demo',
    },
    campaignsSettings: {
        nameOnBuybox: 'Nazwa w widżecie',
        visibleFor: 'Widoczny dla',
        shopOnlyForCampaigns: 'Pokaż tylko dla powierzchni',
        addSpace: 'ID powierzchni',
        textLinkTargetUrlPattern: 'Wzorzec URL',
        textLinkRedirectUrlPattern: 'Wzorzec przekierowania',
        isActive: 'Aktywny',
        isHidden: 'Ukryty',
        textLinks: 'Linki tekstowe',
        filteredStatistics: 'Filtrowany',
        logo: 'Logo',
        icon: 'Ikona',
        utmy: 'UTM-y',
        cookie: 'Cookie Lifetime',
        textFields: 'Pole opisowe',
        description: 'Opis',
        conditionDescription: 'Warunki',
    },
    tipboxLandingSettings: {
        title: 'Twój tipbox.link',
        help: 'Zobacz jak korzystać z tipbox.link',
        seeProfile: 'Zobacz swój profil',
        profileName: 'Nazwa profilu',
        description: 'Opis profilu',
        thanks: 'Podziękowanie',
        amounts: 'Kwoty',
        yourLinks: 'Twoje linki',
        social: 'Rodzaj',
        addSocial: 'Dodaj kolejny link',
        profileSettings: 'Ustawienia twojego profilu',
        slug: 'Link do profilu',
        color: 'Kolor',
        url: 'Adres URL',
        icon: 'Ikonka',
        gaId: 'Google Analytics (opcjonalnie)',
        profileSettingsDescription: 'Jeżeli chcesz lepiej dopasować wygląd i działanie widżetu do swojej strony i wymagań, dodaj nową konfigurację. Dla każdej strony możesz dodać wiele konfiguracji i korzystać z nich w różnych miejscach.',
    },
    widgetSettings: {
        title: 'Konfiguracje',
        edit: 'Edytuj Nazwę',
        save: 'Zapisz',
        availableShops: 'Dostępne sklepy',
        description: 'Zobacz jak korzystać z konfiguracji',
        offersList: 'Lista ofert',
        shopName: 'Nazwa sklepu',
        hidePrices: 'Ukryj ceny',
        additionalSettings: 'Dodatkowe ustawienia',
        productDetails: 'Szczegóły produktu',
        hideProduct: 'Ukryj szczegóły produktu',
        all: 'Wszystkie',
        tabsTitle: 'Zakładki',
        activeShops: 'Wyświetlane w twoim widżecie',
        addToWidget: 'Dodaj do widżetu',
        commisions: 'Prowizje',
        priority: 'Waga',
        defaultFormat: 'Domyślny format',
        colors: 'Kolory',
        configuration: 'Konfiguracja',
        goBack: 'Powrót',
        configurations: 'Konfiguracje',
        generateConfiguration: 'Wygeneruj widżet dla produktu',
        details: 'Pokaż',
        default: 'Domyślna',
        setAsDefault: 'Ustaw jako domyślną',
        remove: 'Usuń',
        addConfiguration: 'Dodaj konfigurację',
        noConfigurations: 'Nie masz dodanej żadnej konfiguracji. Twój widżet korzysta obecnie z domyślnych ustawień. ',
        id: 'Identyfikator',
        configurationDescription:
            'Jeżeli chcesz lepiej dopasować wygląd i działanie widżetu do swojej strony i wymagań, dodaj nową konfigurację. Dla każdej strony możesz dodać wiele konfiguracji i korzystać z nich w różnych miejscach.',
        title2: 'Konfiguracje widgetu',
        addSettings: 'Dodaj konfigurację',
        editSettings: 'Edytuj ustawienia',
        buyboxesFor: 'Lista widżetów',
        name: 'Nazwa konfiguracji',
        categoryName: 'Nazwa kategorii',
        buyboxId: 'Identyfikator',
        actions: 'Akcje',
        appearance: 'Wygląd',
        appearanceSubheader: 'Dopasuj wygląd widżetu do swojej strony',
        category: 'Kategoria',
        space: '@:space',
        customStyles: 'Własne style',
        leadColor: 'Główny kolor',
        showProduct: 'Pokaż szczegóły produktu',
        showPrices: 'Pokazuj ceny',
        showTabs: 'Dziel formaty na zakładki',
        hideTabs: 'Nie dziel formatów na zakładki',
        prices: 'Ceny',
        isTab: 'Dziel formaty na zakładki',
        shopStyle: 'Prezentacja sklepu',
        sortType: 'Kolejność ofert',
        buttonLabel: 'Własna treść przycisku (maksymalnie {maxLength} znaków)',
        formats: 'Formaty',
        tabDefault: 'Domyślnie wyświetlana zakładka',
        rowCount: 'Maksymalna domyślna liczba wyświetlanych ofert',
        campaigns: 'Sklepy',
        campaignsSubheader:
            'Wybierz i przeciągnij sklepy, które chcesz prezentować w swoim widżecie. Jeżeli chcesz, żeby wybrane sklepy były wyświetlane na górze listy lub chcesz ustalić własną kolejność wyświetlania, ustaw im odpowiednie wagi.',
        campaignsSubheaderMobile:
            'Wybierz sklepy, które chcesz prezentować w swoim widżecie. Jeżeli chcesz, żeby wybrane sklepy były wyświetlane na górze listy lub chcesz ustalić własną kolejność wyświetlania, ustaw im odpowiednie wagi.',
        campaignsShown: 'Wyświetlane',
        campaignsNotShown: 'Niewyświetlane',
        updateAppearanceSuccess: 'Zaktualizowano ustawienia wyglądu.',
        campaignsListCreateSuccess: 'Dodano sklep "{name}".',
        campaignsListUpdateSuccess: 'Zaktualizowano ważność sklepu "{name}".',
        campaignsListDeleteSuccess: 'Usunięto sklep "{name}".',
        showAdditionalColumn: 'Pokaż dodatkową kolumnę',
        additionalColumn: 'Treść dodatkowej kolumny (HTML)',
        additionalColumnOnlyForSorted: 'Pokaż tylko dla sortowanych',
        version: 'Wersja widżetu',
        latestVersion: 'Najnowsza (zalecana)',
        language: 'Język widżetu',
        showCardAppearanceBtn: 'Dopasuj wygląd widżetu dla tego produktu',
    },
    pluginCampaignsList: {
        title: 'Ustawienia listy sklepów',
        noCampaigns: 'Brak dostępnych sklepów.',
        noActiveCampaigns: 'Wybrano wszystkie sklepy',
        noAvailableCampaigns: 'Wybrano wszystkie sklepy',
        activeCampaigns: 'Sklepy wyświetlane na twojej stronie',
        activeCampaignsDescription: 'Zmień kolejność ważności obok sklepu',
        availableCampaigns: 'Dostępne sklepy',
        availableCampaignsDescription: 'Wybierz sklepy, które chcesz wyświetlić, i przeciągnij je w lewo',
        createSuccess: 'Dodano sklep "{name}".',
        updateSuccess: 'Zaktualizowano ważność sklepu "{name}".',
        deleteSuccess: 'Usunięto sklep "{name}".',
    },
    generator: {
        title: 'Generator',
        title2: 'Wygeneruj widżet',
        title3: 'Wygeneruj landing page produktowy',
        cardProduct: '1. Wybierz produkt',
        cardProductDescription: 'Znajdź produkt, skonfiguruj wygląd widżetu, wygeneruj kod, a następnie wstaw go na swoją stronę.',
        cardSpace: '2. Wybierz stronę i konfigurację',
        cardSpaceDescription: 'Wybierz stronę oraz konfigurację',
        cardAppearance: '3. Dopasuj wygląd',
        cardAppearanceDescription: 'Znajdź produkt, skonfiguruj wygląd widżetu, wygeneruj kod, a następnie wstaw go na swoją stronę.',
        cardCode: '4. Wstaw na stronę',
        cardCodeDescription: 'Skopiuj wygenerowany kod i wstaw go na swoją stronę',
        previewHeader: 'Podgląd twojego widżetu',
        previewSubheader: 'Wybierz produkt i stronę, aby zobaczyć podgląd',
        previewSubheaderNoOffers: 'Dla wybranego produktu i strony nie znaleźliśmy żadnej oferty.',
        productSearchPlaceholder: 'Podaj nazwę lub EAN produktu',
        buyboxSearchCategory: 'Konfiguracja',
        buyboxSearchId: 'Identyfikator',
        widgetType: 'Rodzaj widżetu',
        widgetTypeTooltip:
            'Masz do wyboru cztery rodzaje widżetu. Może być on widoczny od razu na stronie (standardowy) lub pojawić się po kliknięciu w podlinkowany fragment tekstu lub button (popover, overlay).  W Podglądzie możesz sprawdzić każdy z nich.',
        language: 'Język widżetu',
        productName: 'Nazwa produktu',
        productInfo: 'Opis produktu',
        leadColor: 'Główny kolor',
        leadColorTooltip: 'Możesz zmienić kolor przycisku „Idź do sklepu”. Najlepiej użyj koloru, który dominuje na Twojej stronie lub zostaw ustawienia domyślne.',
        buttonLabel: 'Własna treść przycisku',
        buttonLabelTooltip: 'Możesz zmienić domyślne "Idź do sklepu" na dowolny tekst, np. "Kup teraz", "Kup online", "Kup produkt", itp.',
        shopStyle: 'Prezentacja sklepu',
        sortType: 'Kolejność ofert',
        rowCount: 'Maksymalna domyślna liczba wyświetlanych ofert',
        rowCountTooltip: 'W tym miejscu możesz podać liczbę sklepów, która ma się wyświetlać w widżecie. Dodatkowe oferty sklepów będą widoczne po kliknięciu w "Pokaż więcej".',
        showProduct: 'Pokaż szczegóły produktu',
        showProductTooltip: 'Ta funkcja włącza lub wyłącza w widżecie prezentację produktu (zdjęcie, nazwa i opis).',
        showPrices: 'Pokazuj ceny',
        showPricesTooltip: 'Ta funkcja włącza lub wyłącza w widżecie kolumnę z ceną.',
        next: 'Dalej',
        copyCode: 'Skopiuj kod',
        copied: 'Skopiowano kod',
        helperDescription: 'Znajdź produkt, skonfiguruj wygląd widżetu, wygeneruj kod, a następnie wstaw go na swoją stronę.',
        helperDescription2: 'Znajdź produkt, skopiuj wygenerowany adres URL, a następnie wstaw go na swoją stronę.',
        seeHowToDoIt: 'Zobacz jak korzystać z generatora',
        runTheTutorial: 'Uruchom samouczek',
        learnAboutWidgetTypes: 'Poznaj rodzaje widżetu',
        codeWarning: '{warn} Jeżeli na jednej podstronie wstawiasz kilka widżetów, to {code} wstaw tylko jeden raz.',
        codeWarningWarn: 'Uwaga!',
        liveDemo: 'Live demo',
        liveDemoUrl: 'https://getbuybox.com/jak-widzet-buybox-moze-wygladac-na-twojej-stronie/',
        htmlTag: 'Tag HTML uruchamiający widżet',
        buyboxSearchCategoryNoResults:
            'Brak ofert do wyświetlenia – produkt jest dostępny w sklepach, których nie wyświetlasz w widgecie. <a href="https://docs.getbuybox.com/ustawienia/" target="_blank">Zobacz jak zmienić ustawienia</a>',
        correct_form_data_to_proceed: 'Popraw dane formularza, aby przejść dalej.',
    },
    widgetType: {
        widget: 'Standardowy',
        popover: 'Popover',
        overlay: 'Overlay',
        logosOnly: 'Logo',
    },
    widgetPreview: {
        show: 'Sprawdź, gdzie kupić',
        noOffers: 'Brak ofert',
        pageTitle: 'Podgląd Widżetu',
    },
    textLinks: {
        title: 'Linki tekstowe',
        title2: 'Generator linków',
        header: 'Utwórz link tekstowy',
        subHeader: 'Wygeneruj link do dowolnej podstrony w sklepie internetowym (strona główna, promocja, lista produktów).',
        subHeaderLink: 'https://docs.getbuybox.com/linki-tekstowe/',
        subHeaderLinkText: 'Zobacz jak to zrobić',
        abpar: '@:abpar',
        space: 'Twoja strona',
        campaign: 'Sklep',
        targetUrl: 'Docelowy adres URL',
        optional: 'Opcjonalnie',
        generatedLink: 'Twój gotowy link',
        openLink: 'Otwórz link',
        copyLink: 'Kopiuj link',
        shareLink: 'Podziel się',
        copied: 'Skopiowano link {link}',
        isSameDomainError: 'Domena docelowego adresu URL musi odpowiadać domenie sklepu.',
    },
    stat: {
        header: 'Filtry',
        space: '@:space',
        campaign: '@:campaign',
        year: 'Rok',
        month: 'Miesiąc',
        date: 'Data',
        clicks: 'Kliknięcia',
        transactions: 'Transakcje',
        conversion: 'Konwersja',
        epc: 'EPC',
        newCommission: 'Otwarte prowizje',
        acceptedCommission: 'Zaakceptowane prowizje',
        newPublisherCommission: 'Prowizja wydawców Otwarta',
        acceptedPublisherCommission: 'Prowizja wdawców Zaakceptowana',
        newOurCommission: 'Prowizja nasza Otwarta',
        acceptedOurCommission: 'Prowizja nasza Zaakceptowana',
        filter: '@:filter',
        noResult: '@:noResult',
        publisherCommission: 'Prowizja wydawców',
        ourCommission: 'Prowizja nasza',
        commissionOpened: 'Otwarta',
        commissionAccepted: 'Zaakceptowana',
        commission: 'Prowizja',
        commissions: 'Prowizje',
    },
    statByDay: {
        title: 'Statystyki dzienne',
    },
    statByMonth: {
        title: 'Statystyki miesięczne',
    },
    transactions: {
        title: 'Transakcje',
        list: 'Lista transakcji',
        filters: '@:filters',
        space: '@:space',
        campaign: 'Sklep',
        dateRange: 'Zakres dat',
        financeDocument: 'Płatność',
        status: 'Status',
        hour: 'Godzina',
        showTransactions: 'Pokaż transakcje',
        exportTransactions: 'Eksportuj do Excela',
        count: 'Liczba transakcji dla zastosowanych filtrów',
        counting: 'Trwa obliczanie liczby dostępnych transakcji...',
        exportRejectedNoItems: 'Brak transakcji do wyeksportowania. Zmień ustawienia filtrowania i spróbuj ponownie.',
        exportRejectedTooMuchItems:
            'Przekroczyłeś maksymalną liczbę {limit} transakcji do pobrania w ramach pojedynczego eksportu. Zmień ustawienia filtrowania i spróbuj ponownie.',
        id: 'Identyfikator',
        date: 'Data',
        transactionAmount: 'Kwota transakcji',
        commissionAmount: 'Prowizja',
        conversionTime: 'Czas konwersji',
        productsList: 'Lista produktów',
        productsListTransaction: 'ID transakcji: {id} ({campaign})',
        productName: 'Nazwa produktu',
        quantity: 'Sztuk',
        price: 'Wartość',
        publisherCommission: 'Prowizja',
        total: 'Razem',
        productNoInfo: 'Brak danych o produkcie',
        abpar: '@:abpar',
        commissions: {
            publisher: 'Wydawcy',
            admin: 'Nasza',
            advertiser: 'Sklepu',
        },
        details: 'Szczegóły',
        withSelected: 'Z zaznaczonymi',
    },
    transactionStatus: {
        new: 'Nowa',
        accepted: 'Zaakceptowana',
        rejected: 'Odrzucona',
        abandoned: 'Porzucona',
    },
    shopStyle: {
        icon_name: 'Ikona plus nazwa sklepu',
        logo: 'Logo sklepu',
    },
    reportsPages: {
        title: 'Raporty stron',
        pages: 'Strony',
    },
    reportsShops: {
        title: 'Raporty sklepów',
    },
    pages: {
        title: 'Twoje strony',
        name: 'Nazwa',
        namePlaceholder: 'Nazwa strony',
        url: 'Url',
        urlPlaceholder: 'np. https://moja-strona.pl',
        description: 'Opis',
        descriptionPlaceholder: 'Dwa, trzy zdania opisujące Twoją stronę',
        categories: 'Kategorie',
        type: 'Typ',
        status: 'Status',
        actions: 'Akcje',
        confirm: 'Zapisz',
        cancel: 'Anuluj',
        create: 'Dodaj nową stronę',
        edit: 'Edyduj stronę „{name}”',
        createSuccess: 'Strona „{name}” została utworzona.',
        updateSuccess: 'Strona „{name}” została zaktualizowana.',
        updateWebhookSuccess: 'Webhook dla strony „{name}” został zaktualizowany.',
    },
    widgeterShops: {
        title: 'Sklepy',
        subtitle: 'Wybierz stronę dla której chcesz sprawdzić dostępne sklepy i warunki, np. wysokość prowizji. W tym miejscu możesz także pobrać feed produktowy.',
        filters: 'Filtry',
        campaignName: '@:campaign',
        campaignNamePlaceholder: 'Wpisz nazwę, aby wyszukać',
        category: 'Kategoria',
        status: 'Status',
        filter: 'Filtruj',
        shopsList: 'Lista sklepów',
        id: 'ID',
        shop: 'Sklep',
        commission: 'Warunki',
        textLinks: 'Linki tekstowe',
        transactionDetectTime: 'Czas wykrywania transakcji',
        transactionDetectTimeInDays: '{count} dzień | {count} dni',
        productFeed: 'Feed produktowy',
        feedCopied: 'Skopiowano URL',
        copyFeedUrl: 'Skopiuj URL',
        showFeeds: 'Pokaż',
        cps: 'CPS',
        selectWebsite: 'Wybierz stronę',
    },
    campaignStatus: {
        waiting: 'Oczekujący',
        accepted: 'Zaakceptowany',
        rejected: 'Odrzucony',
        disconnected: 'Rozłączony',
    },
    availableStatus: {
        yes: 'Tak',
        no: 'Nie',
    },
    finance: {
        title: 'Finanse',
        accountSummary: 'Podsumowanie konta',
        paymentHistory: 'Historia płatności',
        newCommission: 'Nowa prowizja',
        acceptedCommission: 'Prowizja potwierdzona przez reklamodawców',
        toPayCommission: 'Prowizja gotowa do wypłaty',
        payOut: 'wypłać',
        date: 'Data',
        documentNumber: 'Numer dokumentu',
        paymentDate: 'Termin płatności',
        amount: 'Kwota',
        status: 'Status',
        transactions: 'Transakcje',
        showTransactions: 'pokaż',
        createBillSuccess: 'Zlecono wypłatę prowizji.',
        sendInvoiceSuccess: 'Zapisano fakturę.',
        yourPayouts: 'Twoje wypłaty',
        totalMonthlyPayouts: 'Suma miesięcznych wypłat',
    },
    financeDocumentStatus: {
        new: 'Nowa',
        accepted: 'Zaakceptowana',
        rejected: 'Odrzucona',
        paid: 'Zapłacona',
        abandoned: 'Porzucona',
    },
    paymentGeneratorCompany: {
        title: 'Nowa płatność',
        companyOnly: 'Strona dostępna tylko dla kont firmowych.',
        unavailable: 'Przepraszamy, ale w tym momencie wypłata nie jest możliwa. Spróbuj ponownie za chwilę. ',
        payoutThresholdNotReached: 'Przepraszamy, ale w tym momencie wypłata nie jest możliwa. Na Twoim koncie nie ma jeszcze wystarczających środków do wypłaty.',
        prepareTitle: '1. Przygotuj dokument',
        prepareDescription: 'Wystaw fakturę zgodnie z poniższymi danymi i wygeneruj plik PDF.',
        recipient: 'Odbiorca:',
        issueDate: 'Data wystawienia:',
        dueDate: 'Termin płatności:',
        dueInDays: '21 dni',
        netPrice: 'Kwota netto:',
        comments: 'Usługa:',
        commentContent: 'Wynajem powierzchni reklamowej',
        sendTitle: '2. Prześlij nam gotowy dokument',
        invoiceNumber: 'Numer twojego dokumentu',
        invoicePdf: 'PDF',
        confirm: 'Zapisz',
    },
    paymentGeneratorPrivate: {
        title: 'Nowa płatność',
        description: 'Potwierdź proszę, że Twoje dane osobowe są aktualne oraz, że chcesz wystawić na te dane rachunek z dzisiejszą datą.',
        missingData: 'Przed wygenerowaniem płatności proszę uzupełnić dane w {link}.',
        missingDataLink: 'ustawieniach konta',
        cancel: 'Anuluj',
        confirm: 'Potwierdź',
    },
    api: {
        title: 'API',
        apiKeyFor: 'Klucz API dla {space}',
        apiKey: 'Klucz API',
        apiSecret: 'Sekret API',
        generate: 'Generuj',
        regenerate: 'Regeneruj',
        revoke: 'Unieważnij',
        noData: 'Brak wygenerowanego klucza API',
        generateSuccess: 'Klucz API został wygenerowany.',
        revokeSuccess: 'Klucz API został unieważniony.',
    },
    save: 'Zapisz',
    noResult: 'Brak wyników',
    filters: 'Filtry',
    filter: 'Filtruj',
    all: 'Wszystkie',
    space: 'Strona',
    advertisingSpace: 'Strona (powierzchnia)',
    campaign: 'Sklep',
    campaigns: 'Sklepy',
    abpar: 'Parametr {number}',
    widget: 'Widżet',
    dated: 'z dnia',
    yes: 'Tak',
    no: 'Nie',
    shops: 'Sklepy',
    yourPages: 'Twoje strony',
    timeRangeDropdown: {
        custom: 'Własny',
        last7Days: 'Ostatnie 7 dni',
        last14Days: 'Ostatnie 14 dni',
        last30days: 'Ostatnie 30 dni',
        today: 'Dzisiaj',
        yesterday: 'Wczoraj',
        currentWeek: 'Bieżący tydzień',
        lastWeek: 'Poprzedni tydzień',
        currentMonth: 'Bieżący miesiąc',
        lastMonth: 'Poprzedni miesiąc',
        currentQuarter: 'Bieżący kwartał',
        lastQuarter: 'Poprzedni kwartał',
        currentYear: 'Bieżący rok',
        lastYear: 'Poprzedni rok',
    },
    widgeterSettingsIntegrations: {
        title: 'Integracje',
        wordpress: {
            title: 'Wtyczka do Wordpressa',
            description:
                'Wtyczka do WordPress pozwala w kilku prostych krokach zintegrować Twoją stronę internetową z BUY.BOX. Umożliwia ona między innymi dodawanie w prosty i szybki sposób widżetów BUY.BOX na stronie bloga, bez konieczności logowania się w naszym panelu.',
            url: 'https://docs.getbuybox.com/wtyczka-do-wordpressa/',
            buttonLabel: 'Dowiedz się więcej',
        },
        webhooks: {
            title: 'Webhook',
            description:
                'Webhooki to mechanizm do komunikacji pomiędzy naszą usługą a zewnętrznymi systemami. Pozwala on przesłać dane (np. o nowych transakchach) na tzw. callback URL, bez konieczności każdorazowego odpytywania naszego API. Możesz to wykorzystać do integracji np. poprzez usługę <a href="https://zapier.com/">Zapier</>.',
            url: { name: 'SettingsWebhooks' },
            buttonLabel: 'Skonfiguruj',
        },
    },
    modalAddPage: {
        header: 'Dodaj swoją stronę',
        content:
            'Aby rozpocząć przygodę z naszą platformą dodaj swoją stronę (blog, portal, profil w mediach społecznościowych itp.), na której będziesz korzystać z naszych narzędzi. Zajmie to Tobie mniej niż minutę.',
        button: 'Dodaj stronę',
    },
    pageNotFound: {
        title: 'Zgubiłeś się',
        text: 'Strona, której szukasz nie istnieje. Możesz jednak kliknąć poniższy przycisk, aby powrócić do strony głównej.',
        buttonText: 'Wróć do strony głównej',
    },
    pageForbidden: {
        title: 'Zgubiłeś się',
        text: 'Strona jest niedostępna pod tym adresem.',
        widgeterLink: 'Przejdź do panelu wydawcy',
        advertiserLink: 'Przejdź do panelu reklamodawcy',
    },
    accessDenied: {
        title: 'Odmowa dostępu',
        text: 'Nie masz uprawnień do oglądania tej strony.',
        buttonText: 'Wróć do strony głównej',
    },
    reportsProducts: {
        title: 'Raport produktowy',
        products: 'Produkty',
        salesValue: 'Wartość sprzedaży',
    },
    productLandingPage: {
        title: 'LP produktowy',
    },
    productLandingPageGenerator: {
        title: 'Wygeneruj Gdziekupic.to',
        helperDescription: 'Z Generatorem Gdziekupic.to utwórz link do podstrony produktu. Użytkownik, który kliknie w link zostanie przeniesiony na podstronę z listą sklepów internetowych w których można kupić dany produkt.',
        helperDescription2: 'Znajdź produkt, skopiuj wygenerowany adres URL, a następnie wstaw go na swoją stronę, np. w relacji na Instagramie, Facebooku, YouTubie czy w treści artykułu.',
        seeHowToDoIt: 'Zobacz jak to zrobić',
        takeALookAtHelp: 'Zajrzyj do pomocy',
        watchVideo: 'Obejrzyj wideo',
        cardStep3Title: 'Skopiuj adres URL',
        copyUrl: 'Skopiuj adres',
        copied: 'Skopiowano adres URL',
        previewHeader: 'Podgląd twojego LP',
    },
    productLandingPageSettings: {
        title: 'Ustawienia',
    },
    productLandingPageSettingsEdit: {
        title: 'Ustawienia',
    },
    confirmEmail: {
        title: 'Potwierdź email',
        missingToken: 'Brak tokena weryfikującego.',
        invalidToken: 'Konto zostało już wcześniej aktywowane lub błędnie skopiowano link aktywacyjny do przeglądarki.',
        success: 'Potwierdzono email.',
    },
    SpaceTypes: {
        webpage: 'Portal',
        socialMedia: 'Social media',
        blog: 'Blog',
        mobileApp: 'Aplikacja mobilna, widżety, rozszerzenia',
        mailingDatabase: 'Baza mailingowa',
        couponsCodesPromotions: 'Kupony rabatowe, promocje',
    },
    dataNotLoaded: {
        error: 'Błąd',
        title: 'Nie załadowano danych',
        text: 'Nie udało się załadować niezbędnych danych do prawidłowego działania aplikacji. Odśwież stronę lub spróbuj zalogować się ponownie, aby rozwiązać ten problem.',
        buttonText: 'Odśwież stronę',
        loginAgain: 'Zaloguj się ponownie',
    },
    auth: {
        widgeter: {
            login: 'Zaloguj się',
            descriptionPoints: [
                'Łatwa i efektywna monetyzacja treści na stronie internetowej.',
                'Wysokie prowizje od sprzedaży.',
                'Ponad 500 sklepów i kilka milionów ofert produktów.',
                'Forma reklamy, który jest przyjazna dla internautów.',
                'Wysokie wskaźniki klikalności i konwersji.',
                'Wygodny interfejs użytkownika.',
            ],
        },
        advertiser: {
            login: 'Zaloguj się do konta sklepu',
            descriptionPoints: [
                'Łączymy strony www i blogi z twoim sklepem.',
                'Skracamy drogę internautów do twojego sklepu.',
                'Wyświetlamy twoje aktualne oferty tuż przy rekomendacjach produktów na stronach i w social mediach.',
                'Rozbudowane dane na temat transakcji i zachowań internautów.',
                'Szeroki zasięg działania widgetu BUY.BOX.',
                'Wysokie wskaźniki CTR i CR.',
            ],
            bottomText:
                'Jeżeli <strong>prowadzisz sklep</strong> i chcesz go dołączyć do naszej platformy, <a href="{urlForShops}" target="_blank"><strong>przejdź tutaj</strong></a>.',
        },
        helloHeader: 'Witaj ponownie',
        registerWithGoogle: 'Zarejestruj przez Google',
        loginWithGoogle: 'Zaloguj przez Google',
        loginWithFacebook: 'Zaloguj przez Facebook',
        loginWithApple: 'Zaloguj przez Apple',
        forgotYourPassword: 'Nie pamiętasz hasła?',
        logIn: 'Zaloguj się',
        youDontHaveAnAccountYet: 'Nie masz jeszcze konta?',
        registerForFree: 'Zarejestruj się za darmo',
        agreements: {
            rules: 'Znam i akceptuję <a href="{urlRules}" target="_blank" class="text-brand">regulamin świadczenia usługi</a> oraz <a href="{urlPrivacyPolicy}" target="_blank" class="text-brand">politykę prywatności</a>.',
            newsletter: 'Zgadzam się na otrzymywanie na podany adres e-mail newslettera zawierającego aktualności i ważne informacje o funkcjonowaniu usługi.',
        },
        register: 'Zarejestruj się',
        resetPassword: 'Zresetuj hasło',
        backToTheLoginPage: 'Powrót na stronę logowania',
        repeatPassword: 'powtórz hasło',
        pageRegister: {
            pageTitle: 'Utwórz konto',
            helloHeader: 'Załóż konto',
            helloSubheader: 'i korzystaj <strong>za darmo!</strong>',
            ctaText: 'Masz już konto?',
            successMessage: 'Rejestracja przebiegła pomyślnie. Zostałeś zalogowany do panelu.',
        },
        pageResetPassword: {
            successMessage: 'Na podany adres email został wysłany link do ustawienia nowego hasła.',
        },
        pageResetPasswordToken: {
            successMessage: 'Nowe hasło zostało ustawione. Możesz teraz zalogować się do swojego konta za jego pomocą.',
        },
        onlyAdminLoginInfo: 'Informujemy, że logowanie na tej stronie działa tylko dla konta administratora.',
        onlyWidgeterLoginInfo: 'Informujemy, że logowanie na tej stronie działa tylko dla konta wydawcy.',
        onlyAdvertiserLoginInfo: 'Informujemy, że logowanie na tej stronie działa tylko dla konta reklamodawcy.',
    },
    toolTypes: {
        widget: 'widżet',
        lp: 'landing page produktowy',
        linki: 'link',
    },
    noData: 'Brak danych',
    noDataToExport: 'Brak danych do exportu',
    selectAll: 'Zaznacz wszystkie',
    deselectAll: 'Odznacz wszystkie',
    selectAllVisible: 'Zaznacz wszystkie widoczne',
    deselectAllVisible: 'Odznacz wszystkie widoczne',
    enterToSearch: 'Wpisz, aby wyszukać',
    errorOccurred: 'Wystąpił błąd',
    selectedSpace: 'Wybrana strona',
    productImport: 'Import produktów',
    shopPresentation: 'Prezentacja sklepu',
    shopLocations: 'Lokalizacje sklepów',
    download: 'Pobierz',
    close: 'Zamknij',
    cancel: 'Anuluj',
    active: 'Aktywny',
    inactive: 'Nieaktywny',
    loading: 'Wczytywanie',
    selectPageToViewStores: 'Wybierz stronę, aby zobaczyć sklepy',
    shopLogo: 'Logo sklepu',
    verifications: 'Weryfikacja użytkowników',
    verifiyAccount: 'Zweryfikuj konto',
    shopIcon: 'Ikona sklepu',
    shopLogoDescription:
        'Wgraj plik PNG o wymiarach 250x50px z przezroczystym tłem. Logo będzie używane w opisie Twojego sklepu w panelu wydawcy oraz w widżecie w wersji z logotypami.',
    shopIconDescription: 'Wgraj plik PNG o wymiarach 32x32px z przezroczystym tłem. Ikona będzie używana w widżecie.',
    noFilesSelected: 'Brak zaznaczonych plików',
    selectFile: 'Wybierz plik',
    saveChanges: 'Zapisz zmiany',
    dataSavedSuccessfully: 'Dane zapisane pomyślnie',
    offersCatalog: 'Katalog ofert',
    findProduct: 'Znajdź produkt',
    search: 'Szukaj',
    verificationCode: 'Kod',
    name: 'Nazwa',
    ean: 'EAN',
    isbn: 'ISBN',
    eanisbn: 'EAN/ISBN',
    price: 'Cena',
    available: 'Dostępny',
    unavailable: 'Niedostępny',
    aggregated: 'Zagregowany',
    lastUpdate: 'Ostatnia aktualizacja',
    offerDetails: 'Szczegóły oferty',
    offerId: 'ID oferty',
    storeOfferId: 'ID oferty sklepu',
    offerType: 'Typ oferty',
    aggregation: 'Agregacja',
    priceAndAvailability: 'Cena i dostępność',
    availability: 'Dostępność',
    priceAndAvailabilityWillBeOverwritten: 'Pamiętaj, że cena i dostępność zostaną nadpisane podczas następnej automatycznej aktualizacji',
    code: 'Kod',
    day: 'Dzień',
    days: 'Dni',
    sales: 'Sprzedaż',
    webpagesPublishers: 'Strony (wydawcy)',
    status: 'Status',
    category: 'Kategoria',
    searchUrlPlaceholder: 'Wpisz adres URL, aby wyszukać',
    searchNamePlaceholder: 'Wpisz nazwę, aby wyszukać',
    lastDays: 'ostatnie {days} dni',
    clicks: 'Kliknięcia',
    type: 'Typ',
    pagesList: 'Lista stron',
    localeNotFound: 'Nie znaleziono pliku języka dla "{lang}"',
    selectOption: '-- Wybierz -- ',
    year: 'Rok',
    month: 'Miesiąc',
    date: 'Data',
    identifier: 'Identyfikator',
    amount: 'Kwota',
    commission: 'Prowizja',
    conversionTime: 'Czas konwersji',
    abpar1: 'Parametr 1',
    abpar2: 'Parametr 2',
    abpar3: 'Parametr 3',
    shop: 'Sklep',
    campaignShort: 'Sklep',
    enterUrlToSearch: 'wpisz adres URL, aby wyszukiwać',
    clicksLastXDays: 'Kliknięcia (ostatnie {days} dni)',
    salesLastXDays: 'Sprzedaże (ostatnie {days} dni)',
    reject: 'Odrzuć',
    accept: 'Zaakceptuj',
    lastXDays: 'ostatnie {days} dni',
    url: 'URL',
    actions: 'Akcje',
    enterYourWebsiteDetails: 'Podaj dane swojej strony',
    eGMyBlog: 'np. Mój Blog',
    https: 'https://',
    or: 'lub',
    emailAddress: 'Adres email',
    password: 'Hasło',
    firstName: 'Imię',
    next: 'Dalej',
    termsAndConditions: 'Regulamin',
    goBackTo: 'Wróć do',
    targetUrl: 'Docelowy URL',
    preparedTextLink: 'Gotowy link tekstowy',
    yourRecentlyGeneratedLinks: 'Twoje ostatnio wygenerowane linki',
    loginSuccess: 'Zostałeś pomyślnie zalogowany.',
    websiteName: 'Nazwa strony',
    websiteUrl: 'Adres URL strony',
    select: 'Wybierz',
    websiteType: 'Typ strony',
    invalidRequestData: 'Podane dane są nieprawidłowe',
    product: 'Produkt',
    addWebsite: 'Dodaj stronę',
    selectProduct: 'Wybierz produkt',
    customAppearance: 'Dopasuj wygląd',
    optional: 'Opcjonalnie',
    selectTool: 'Wybierz narzędzie',
    toolTypeWidget: 'Widżet BUY.BOX',
    toolTypeLinks: 'Link tekstowy',
    toolTypeLP: 'Landing page produktowy',
    skipConfiguratorAndGoToPanel: 'Pomiń konfigurator i przejdź do panelu',
    insertWidget: 'Wstaw widżet na stronę',
    insertTextLink: 'Wstaw link na stronę',
    done: 'Gotowe',
    selectAnotherTool: 'Wybierz inne narzędzie',
    goToPanel: 'Przejdź do panelu',
    quickStart: 'Szybki start',
    id: 'ID',
    hour: 'Godzina',
    details: 'Szczegóły',
    value: 'Wartość',
    doNothing: 'Nie rób nic',
    acceptTransactions: 'Zaakceptuj transakcje',
    rejectTransactions: 'Odrzuć transakcje',
    noTransactionsSelected: 'Nie wybrano żadnych transakcji',
    typesOfOffers: {
        content_book: 'Książka',
        content_movie: 'Film',
        content_game: 'Gra',
        content_music: 'Muzyka',
        product: 'Produkt',
    },
    showPreview: 'Zobacz podgląd',
    catalogProductsTitle: 'Katalog - Produkty',
    author: 'Autor',
    brand: 'Marka',
    description: 'Opis',
    source: 'Źródło',
    categories: 'Kategorie',
    edit: 'Edytuj',
    catalog: 'Katalog',
    products: 'Produkty',
    filesUpdatedAt: 'Ostatnia aktualizacja feedów',
    createSuccess: 'Dane zostały zapisane.',
    updateSuccess: 'Dane zostały zaktualizowane.',
    createError: 'Wystąpił błąd podczas zapisu danych.',
    updateError: 'Wystąpił błąd podczas aktualizacji danych.',
    youEditX: 'Edycja: "{name}"',
    youAdd: 'Dodaj nowy',
    confirm: 'Potwierdź',
    confirmYouWantDelete: 'Czy na pewno chcesz usunąć?',
    brands: 'Marki',
    manufacturer: 'Producent',
    manufacturers: 'Producenci',
    parent: 'Rodzic',
    addNew: 'Dodaj nowy',
    favicon: 'Favicon',
    logo: 'Logo',
    urlAddress: 'Adres URL',
    links: 'Linki',
    title: 'Tytuł',
    additionalText: 'Dodatkowy tekst',
    googleAnalyticsId: 'Identyfikator Google Analytics',
    gaPlaceholder: 'UA-XXXXXX-X',
    titleAndUrl: 'Tytuł i URL',
    appearance: 'Wygląd',
    personalizeYourLandingPageText: 'Spersonalizuj swój landing page dodając logo, własne linki oraz dodatkowy tekst.',
    lpFaviconTooltip: 'Ikona wyświetlana na pasku przeglądarki. Rekomendowane proporcję 1:1. Maksymalna wielkość pliku to 150KB.',
    lpLogoTooltip:
        'Twoje logo, które będzie wyświetlane na górze strony na widżetem. Rekomendowane wymiary to maksymalnie 200px szerokości i 100px wysokości. Maksymalna wielkość pliku to 1MB.',
    lpUrlTooltip: 'Adres URL, na który będą kierowani internauci po kliknięciu w Twoje logo.',
    lpLinksTooltip:
        'Linki umieszczone pod widżetem prowadzące np. do Twojej polityki prywatności, regulaminu czy profili w social mediach. Tytuł to treść linku wyświetlana na stronie (np. Polityka Prywatności).',
    lpTextTooltip:
        'Twój własny tekst, który wstawimy na samym dole strony. Możesz tutaj dodać np. teks marketingowy lub informacje o korzystaniu z cookiesów. Maksymalna liczba znaków to 300.',
    lpGaIdTooltip: 'Identyfikator Twjego kodu śledzącego Google Analytics. Dodaj, jeżeli chcesz śledzić statystyki Twojego LP Produktowego.',
    whatsNew: 'Co nowego?',
    goNext: 'Przejdż dalej',
    conversionReport: 'Raport konwersji',
    registrations: 'Rejestracje',
    conversionFunnel: 'Lejek konwersji',
    newPublishers: 'Nowi wydawcy',
    publishersWithThePageAdded: 'Wydawcy z dodaną stroną',
    publishersWithClicks: 'Wydawcy z kliknięciami',
    publishersWithTransactions: 'Wydawcy z transakcjami',
    publishersWithTipboxPayments: 'Wydawcy z wpłatami z tipBOXa',
    changeProduct: 'Zmień produkt',
    goBack: 'Powrót',
    webhook: 'Webhook',
    webhooks: 'Webhooks',
    webhookUrl: 'Webhook URL',
    lastName: 'Nazwisko',
    companyName: 'Nazwa firmy',
    userRole: 'Rola użytkownika',
    userList: 'Lista użytkowników',
    dateAdded: 'Data dodania',
    login2: 'Login',
    email: 'Email',
    firstNameAndLastName: 'Imię i Nazwisko',
    company: 'Firma',
    role2: 'Rola',
    addUser: 'Dodaj użytkownika',
    show: 'Pokaż',
    go: 'Idź',
    go2: 'Przejdź',
    userDetails: 'Szczegóły użytkownika',
    userName: 'Nazwa użytkownika',
    blockade: 'Blokada',
    acceptanceOfRegulations: 'Akceptacja regulaminu',
    notifications: 'Powiadomienia',
    deleted: 'Usunięty',
    demo: 'Demo',
    created: 'Utworzone',
    phoneNumber: 'Numer telefonu',
    address: 'Adres',
    country: 'Kraj',
    bankAccount: 'Numer konta bankowego',
    companyAccount: 'Konto firmowe',
    taxId: 'NIP',
    language2: 'Język',
    users: 'Użytkownicy',
    selectWebsite: 'Wybierz stronę',
    only: 'Tylko',
    listOfPaymentsTips: 'Wpłaty',
    overview: 'Przegląd',
    paymentsDonations: 'Wpłaty (darowizny)',
    webpages: 'Strony',
    user: 'Użytkownik',
    publisher: 'Wydawca',
    producer: 'Producent',
    pageStatus: 'Status strony',
    userType: 'Typ użytkownika',
    amazonTrackingId: 'Amazon Tracking ID',
    fullName: 'Imię i Nazwisko',
    pageType: 'Typ strony',
    productDetails: 'Szczegóły produktu',
    tracking: 'Tracking',
    permissions: 'Uprawnienia',
    enabled: 'Włączony',
    catalogManufacturersTitle: '@:catalog - @:manufacturers',
    exportToExcel: 'Eksportuj do Excela',
    noOffersToDisplay: 'Brak ofert do wyświetlenia',
    selectPageAndCategory: 'Wybierz stronę i kategorię',
    tipbox: 'tip.BOX',
    tipboxWidgetSettings: 'Ustawienia widżetu tip.BOX',
    tipboxWidget: 'Widżet tip.BOX',
    insertOnPage: 'Wstaw na stronę',
    ifYouLikedThisPost: 'Jeżeli spodobał się Tobie ten wpis, możesz mnie docenić ofiarując mi drobny napiwek. Z góry dziękuję.',
    copyTheCodeAndInsertItIntoYourPage: 'Skopiuj kod i wstaw go na swoją stronę w miejscu, w którym ma wyświetlać się widżet.',
    tipboxForm: {
        label: {
            amounts: 'Kwoty napiwków',
            header: 'Treść nagłówka',
            description: 'Treść zachęty',
            thanks: 'Treść podziękowania',
            headerColor: 'Kolor nagłówka',
            textColor: 'Kolor tekstu',
            buttonColor: 'Kolor przycisków',
            buttonTextColor: 'Kolor tekstu przycisków',
            frameColor: 'Kolor ramki',
            backgroundColor: 'Kolor tła',
            icon: 'Ikonka',
        },
        placeholder: {
            amounts: ['5', '10', '15'],
            header: 'Doceń moją pracę',
            description: 'Jeżeli spodobał się Tobie ten wpis, możesz mnie docenić ofiarując mi drobny napiwek. Z góry dziękuję.',
            thanks: 'Dziękuję za napiwek',
            headerColor: '#000000',
            textColor: '#000000',
            buttonColor: '#e32e32',
            buttonTextColor: '#ffffff',
            frameColor: '#000000',
            backgroundColor: '#ffffff',
        },
    },
    paymentStatus: 'Status płatności',
    nameOfTheDonor: 'Imię i nazwisko darczyńcy',
    emailOfTheDonor: 'Adres e-mail darczyńcy',
    depositAmount: 'Kwota wpłaty',
    moderation: 'Moderacja',
    statusCreated: 'Utworzony',
    statusClosed: 'Zamknięty',
    statusReopen: 'Ponownie otwarty',
    statusWontDo: 'Nie do zrobienia',
    comment: 'Komentarz',
    youAssignVariantsToTheProduct: 'Przypisujesz warianty do produktu: "{name}"',
    variantsHaveBeenAssigned: 'Warianty zostały przypisane',
    assignVariants: 'Przypisz warianty',
    remove: 'Usuń',
    paymentId: 'ID płatności',
    ourCommission: 'Nasza prowizja',
    operatorsCommission: 'Prowizja operatora',
    tipTransactionStatuses: {
        created: 'Stworzona',
        pending: 'Nieopłacona',
        correct: 'Opłacona',
        payout_requested: 'Wymagana wypłata',
        paid: 'Wypłacona',
        error: 'Błędna',
        returned: 'Zwrócona',
    },
    offerFinder: 'Wyszukiwarka ofert',
    searchForOffers: 'Wyszukiwanie ofert',
    eanNumber: 'Numer EAN',
    search2: 'Wyszukaj',
    searchResults: 'Wyniki wyszukiwania',
    searchResultsCount: 'Wyniki wyszukiwania ({count})',
    authorDirectorProducer: 'Autor / Reżyser / Producent',
    productId: 'ID produktu',
    producerCode: 'Kod producenta',
    imageUrl: 'URL obrazka',
    feedId: 'ID feedu',
    storeCategoryTemplate: 'Wzorzec kategorii sklepu',
    format2: 'Format',
    hidden: 'Ukryty',
    productOfferId: 'ID produktu / oferty',
    info: 'Info',
    feedShop: 'Feed / Sklep',
    forSelected: 'Dla zaznaczonych',
    createNewProduct: 'Utwórz nowy produkt',
    transferToAnotherProduct: 'Przenieść do innego produktu',
    unlinkProduct: 'Usuń powiązanie z produktem',
    changeProductType: 'Zmień typ produktu',
    changeOrHidden: 'Zmień czy ukryte',
    changeAvailability: 'Zmień dostępność',
    edit2: 'Edycja',
    idOfShop: 'ID sklepowe',
    updated: 'Zaktualizowane',
    available2: 'Dostępne',
    unavailable2: 'Niedostępne',
    hidden2: 'Ukryte',
    reset: 'Resetuj',
    settlements: 'Rozliczenia',
    tipSettlements: 'Rozliczenia napiwków',
    donationsInThePreviousMonth: 'Darowizny w poprzednim miesiącu',
    publishersInThePreviousMonth: 'Wydawców w poprzednim miesiącu',
    revenueInThePreviousMonth: 'Przychód w poprzednim miesiącu',
    revenueInPreviousMonth: 'Przychód w poprzednim miesiącu',
    withdrawalOrders: 'Zlecenia wypłaty',
    invoices: 'Faktury',
    noPayoutsSelected: 'Nie wybrano żadnych zleceń wypłaty',
    downloadTransferFile: 'Pobierz plik przelewów',
    transferFile: 'Plik przelewów',
    markAsSettled: 'Oznacz jako rozliczone',
    downloadSelected: 'Pobierz zaznaczone',
    noInvoicesSelected: 'Nie wybrano żadnych faktur',
    publisherRecipient: 'Wydawca (odbiorca)',
    documentNumber: 'Numer dokumentu',
    netAmount: 'Kwota netto',
    issueDate: 'Data wystawienia',
    paymentAmount: 'Kwota płatności',
    donationsInThisMonth: 'Darowizny w tym miesiącu',
    donationsInThisYear: 'Darowizny w tym roku',
    donationsReadyForWithdrawal: 'Darowizny gotowe do wypłaty',
    monthlySummaryOfDonations: 'Miesięczne zestawienie darowizn',
    document: 'Dokument',
    invoice: 'Faktura',
    donations: 'Darowizny',
    paymentOfDonations: 'Wypłata darowizn',
    change: 'Zmień',
    payOut: 'Wypłać',
    pleaseConfirmDataCorrect: 'Potwierdź proszę, że poniższe dane są poprawne.',
    verificationModal: {
        header: 'Aby zweryfikować swój rachunek bankowy, na który będziemy przelewać zgromadzone darowizny, wykonaj przelew zgodnie z poniższymi danymi.',
        footer: 'Pamiętaj, że dane nadawcy przelewu muszą być takie same, jak podane w ustawieniach Twojego konta. Weryfikacja konta może potrwać do 3 dni roboczych. ',
    },
    paymentData: 'Dane z przelewu',
    paymentWasAccepted: 'Wypłata została przyjęta.',
    payouts: 'Wypłaty',
    noItemsSelected: 'Nie wybrano elementów!',
    noParametersSelected: 'Nie wybrano parametrów!',
    clear: 'Wyczyść',
    requestExecutedSuccessfully: 'Żądanie wykonane pomyślnie',
    requestLimitedForPerformanceReasons: 'Zapytanie jest zbyt ogólne. Wyniki wyszukiwania są niepełne ze względu na wydajność. Spróbuj bardziej szczegółowego zapytania.',
    searchForOffersRecommendation: 'Zaleca się unikać używania “Wzorcu kategorii sklepu“, “ID produktu” oraz “Formatu“ z “Nazwą“ oraz “Autorem / Reżyserem / Producentem“',
    productWithIdDoesNotExist: 'Produkt o podanym ID nie istnieje',
    integrationStatus: 'Status integracji',
    shopId: 'ID sklepu',
    shopName: 'Nazwa sklepu',
    testedUrl: 'Testowany URL',
    lastCookie: 'Ostatnie cookie',
    ratioClicksToCookies: 'Proporcja kliknięć do cookie',
    lastTransaction: 'Ostatnia transakcja',
    campaignId: 'ID sklepu',
    spaceId: 'ID powierzchni',
    companyNip: 'NIP',
    street: 'Ulica',
    streetNumber: 'Numer budynku',
    apartmentNumber: 'Numer lokalu',
    city: 'Miasto',
    postcode: 'Kod pocztowy',
    policies: 'Regulamin',
    acceptTermsAndConditions: 'Akceptuję regulamin',
    tipboxPolicies: 'Przeczytałem i akceptuję <a href="{url}" target="_blank" class="text-body text-decoration-underline">regulamin usługi tip.BOX</a>',
    tipboxPoliciesRequired: 'Wymagana jest akceptacja regulaminu usługi tip.BOX',
    theNew: 'Nowość',
    createValuableContentAndCollectTipsFromYourUsers: 'Twórz wartościowe treści i zbieraj napiwki od swoich użytkowników',
    hereWeGo: 'Zaczynamy',
    tipboxPoliciesAccepted: 'Regulaminu usługi tip.BOX został zaakceptowany',
    completeYourDetails: 'Uzupełnij swoje dane',
    companyBeforeActivatingTheServiceCheckAndUpdateYourData: 'Przed aktywowaniem usługi sprawdź i zaktualizuj swoje dane (nazwa firmy, adres, numer NIP, numer konta bankowego).',
    privateBeforeActivatingTheServiceCheckAndUpdateYourData:
        'Przed aktywowaniem usługi sprawdź i zaktualizuj swoje dane (imię i nazwisko, adres zamieszkania oraz numer konta bankowego).',
    goToAccountSettings: 'Przejdź do ustawiń konta',
    list: 'Lista',
    campaignsList: 'Lista sklepów',
    advertiser: 'Reklamodawca',
    conditions: 'Warunki',
    campaignDetails: 'Szczegóły sklepu',
    commissionId: 'Identyfikator prowizji',
    addPercentCommission: 'Dodaj nową prowizję procentową',
    addAmountCommission: 'Dodaj nową prowizję kwotową',
    publisherCommission: 'Prowizja wydawcy',
    adminCommission: 'Nasza prowizja',
    producerProducts: 'Produkty producenta',
    producerProductsImport: 'Produkty producenta - import',
    producerProductsImportDescription: 'Prześlij plik w formacie JSON aby zaimportować produkty',
    importFile: 'Importuj plik',
    dataImportedSuccessfully: 'Dane zaimportowane pomyślnie',
    importResult: 'Wynik importu',
    importedProducts: 'Zaimportowane produkty',
    matchedProducts: 'Dopasowane produkty',
    newProducts: 'Nowe produkty',
    newCategories: 'Nowe kategorie',
    removedCategories: 'Usunięte kategorie',
    feed: 'Feed',
    parser: 'Parser',
    offers: 'Oferty',
    update: 'Aktualizacja',
    assignCategory: 'Przypisz kategorię',
    add: 'Dodaj',
    deleteUser: 'Usuń użytkownika',
    firstAndLastName: 'Imię i nazwisko',
    bankAccount2: 'Konto bankowe',
    settled: 'Rozliczona',
    notSettled: 'Nierozliczona',
    grossAmount: 'Kwota brutto',
    invoiceNetAmount: 'Kwota netto na fakturze',
    invoiceCurrentNetAmount: 'Kwota netto z rozliczenia',
    paidAmount: 'Kwota rozliczona',
    dueDate: 'Termin płatności',
    toBePaid: 'Do zapłaty',
    settleInvoice: 'Rozlicz fakturę',
    abandonInvoice: 'Porzuć fakturę',
    invoiceCreatedAt: 'Data wystawienia',
    invoiceDueDate: 'Termin płatności',
    invoiceNumber: 'Numer faktury',
    newInvoice: 'Nowa faktura',
    invoicePositions: 'Pozycje faktury (Sklepy)',
    shopsList: 'Lista sklepów',
    currentSettlement: 'Kwota bieżącego rozliczenia',
    pagesDetails: 'Szczegóły strony',
    gaApiSecret: 'Klucz API Google Analytics',
    gaSpace: 'ID powierzchni Google Analytics',
    settings: 'Ustawienia',
    weight: 'Waga',
    productFeeds: 'Feedy produktowe',
    socialAuthLoginError: 'Wystąpił błąd podczas logowania za pomocą konta {provider}',
    locations: 'Lokalizacje',
    adminCampaignsDetailsTabs: {
        settings: 'Ustawienia',
        categories: 'Kategorie',
        commissions: 'Warunki',
        feeds: 'Feed',
        locations: 'Lokalizacje',
    },
    activate: 'Aktywuj',
    deactivate: 'Deaktywuj',
    processAgain: 'Przetwórz ponownie',
    returnToListView: 'Wróć do widoku listy',
    created2: 'Utworzony',
    updated2: 'Aktualizowany',
    report: 'Raport',
    processingStatus: 'Status przetworzenia',
    feedStatus: 'Status feedu',
    xmlFile: 'Plik XML',
    open: 'otwórz',
    authorization: 'Autoryzacja',
    format: 'Format',
    redirectUrl: 'URL przekierowań',
    publishers: 'Wydsawcy',
    producers: 'Producenci',
    parameterName: 'Nazwa parametru',
    ruleFeedPrice: 'Reguła feed/cena',
    processingOptions: 'Opcje przetwarzania',
    queryOptions: 'Opcje zapytania',
    transactions2: 'Transakcje',
    transactionImport: 'Import transakcji',
    csvImport: 'Import z pliku CSV',
    tradeDoublerImport: 'Import TradeDoubler',
    allegroImport: 'Import Allegro',
    csvFilesList: 'Lista plików CSV',
    file: 'Plik',
    import: 'Importuj',
    delete: 'Usuń',
    processed: 'Przetworzony',
    unprocessed: 'Nieprzetworzony',
    createdAt: 'Data utworzenia',
    canceledAt: 'Data anulowania',
    deactivatedAt: 'Data deaktywacji',
    processedAt: 'Data przetworzenia',
    sentAt: 'Data wysłania',
    transactionImportProcedureLabel: 'Procedura oczekuje pliku CSV (znak ";" jako separator) z wierszem nagłówka i z polami podanymi poniżej:',
    transactionImportSuccessfully: 'Plik został pomyślnie załadowany.',
    transactionAllegroImportSuccessfully: 'Plik został pomyślnie załadowany.',
    transactionTradedoublerImportSuccessfully: 'Plik został pomyślnie załadowany.',
    transId: 'ID Transakcji',
    showAll: 'Pokaż wszystkie',
    productSearch: 'Wyszukiwarka produktów',
    audit: 'Audyt',
    transactionValidation: 'Walidacja transakcji',
    validate: 'Waliduj',
    options: 'Opcje',
    settlementsWithPublishers: 'Rozliczenia z wydawcami',
    accountType: 'Rodzaj konta',
    privateType: 'Prywatne',
    companyType: 'Firmowe',
    dateRange: 'Zakres dat',
    showTransaction: 'Pokaż transakcje',
    withSelected: 'Z zaznaczonymi',
    productAudit: 'Audyt produktu',
    outsourceAggregation: 'Zleć agregację',
    error2: 'Błąd',
    feedImportSummary: 'Podsumowanie importu feedów',
    feedsWaitingForFileDownload: 'Feedy oczekujące na pobranie pliku',
    feedsWaitingForProcessing: 'Feedy oczekujące na przetworzenie',
    feedsBeingProcessed: 'Feedy w trakcie przetwarzania',
    successfullyProcessedFeeds: 'Feedy pomyślnie przetworzone',
    canceledFeeds: 'Feedy anulowane',
    inactiveFeeds: 'Feedy nieaktywne',
    updatedAt: 'Data aktualizacji',
    processStartedAt: 'Rozpoczęcie przetwarzania',
    processEndedAt: 'Zakończenie przetwarzania',
    itemsProcessed: 'Przetworzone pozycje',
    reAggregateSuccessfully: 'Produkt został zakolejkowany do agregacji.',
    productAwaitingAggregation: 'Produkt oczekuje na agregację. Na efekty trzeba poczekać od kilku do nawet kilkudziesięciu minut.',
    finance2: 'Finanse',
    subscriptions: 'Subskrypcje',
    agreement: 'Umowa',
    canceled: 'Anulowany',
    cancelSubscription: 'Anuluj subskrypcję',
    changeType: 'Zmień typ',
    reportSubscriptionList: 'Lista subskrypcji raportów',
    bbAffiliateProgram: 'Program Partnerski BB',
    buyboxAffiliateProgram: 'Program partnerski BUY.BOX',
    buyboxAffiliateProgramRecommendOurServices: 'Polecaj nasze usługi innym i zgarniaj <strong>50 zł dodatkowej prowizji</strong> za każdego poleconego użytkownika, który założy konto i uzbiera pierwsze 50 zł prowizji do wypłaty.',
    yourReferralLinks: 'Twoje linki polecające',
    theseAreYourUniqueRecommendingLinksToOurPlatform: 'To twoje unikalne linki polecające do naszej platformy.',
    forEachPageYouAddedInThePanelWeHavePreparedASeparateRecommendationLink: 'Dla każdej dodanej przez Ciebie strony w panelu, np. strony www, Instagrama, Facebooka przygotowaliśmy osobny link polecający. Dzięki temu możesz śledzić, która ze strona przynosi najlepsze efekty. Skopiuj link dla wybranej strony i działaj.',
    advertisingMaterialsToDownload: 'Materiały reklamowe do pobrania',
    downloadMaterials: 'Pobierz materiały',
    toWhomToRecommendTheBuyBox: 'Komu polecać platformę BUY.BOX?',
    whatAreTheConditionsForReceivingRemuneration: 'Jakie są warunki otrzymania wynagrodzenia?',
    whereCanICheckTheEffectsOfMyActions: 'Gdzie mogę sprawdzać efekty moich działań?',
    faq: 'FAQ',
    referralLinkWasCopied: 'Link polecający został skopiowany',
    website: 'Strona',
    link: 'Link',
    clickToCopy: 'Kliknij, aby skopiować',
    interval: 'Interwał',
    quarter: 'Kwartał',
    statistics: 'Statystyki',
    dailyStatistics: 'Statystyki dzienne',
    monthlyStatistics: 'Statystyki miesięczne',
    quarterlyStatistics: 'Statystyki kwartalne',
    yearlyStatistics: 'Statystyki roczne',
    week: 'Tydzień',
    settle: 'Rozlicz',
    firstNameOfTheDonor: 'Imię Darczyńcy',
    lastNameOfTheDonor: 'Nazwisko Darczyńcy',
    shopPanel: 'Panel sklepu',
    createSubscription: 'Utwórz subskrypcje',
    newSubscription: 'Nowa subskrypcja',
    addNewSubscription: 'Dodaj nową subskrypcję',
    reportSubscriptionsCreateSuccessfully: 'Subskrypcja została pomyślnie utworzona',
    entitlement: 'Uprawnienie',
    subscriptionsPermissionOptions: {
        campaign_analysis: 'analiza kampanii',
        stats_availability: 'dostępność produktów',
        stats_categories: 'statystyki kategorii',
        stats_competition_report: 'raport konkurencji',
        stats_price_distribution: 'rozkład cen',
        stats_products: 'statystyki produktów',
        stats_sales: 'statystyki sprzedaży',
        stats_shops: 'statystyki sklepów',
    },
    numberOfOffersToDisplay: 'Liczba ofert do wyświetlenia',
    offerLifetime: 'Czas życia ofert',
    inHours: 'W godzinach',
    youCannotAddSuchAnAddressBecauseSomeoneElseHasAlreadyReportedSuchASite: 'Nie możesz dodać takiego adresu, ponieważ ktoś inny już zgłosił taką stronę. Jeżeli uważasz, że to błąd i posiadasz prawa do tej strony, <a href="javascript:void(0)" onclick="window.userengage(\'widget.open\')" style="color:white;text-decoration:underline">wyślij nam wiadomość</a>',
    permission: 'Uprawnienie',
    transactionCorrection: 'Korekcja transakcji',
    widgeterPagesSubheaderText: 'Dodaj co najmniej jedną stronę (blog, serwis internetowy, profil w mediach społecznościowych itp.), na której chcesz wykorzystywać nasze narzędzia.',
    takeALookAtHelp: 'Zajrzyj do pomocy',
    watchVideo: 'Obejrzyj wideo',
    seeHowToUseTheGenerator: 'Zobacz jak korzystać z generatora',
    seeHowToDoIt: 'Zobacz jak to zrobić',
    conversionReportChartName: {
        registration: 'Rejestracje',
        space: 'Wydawcy z dodaną stroną',
        click: 'Wydawcy z kliknięciami',
        transaction: 'Wydawcy z transakcjami',
        tipTransaction: 'Wydawcy z wpłatami z tipBOXa',
    },
    publishersList: 'Lista wydawców',
    widgeterGeneratorSubheaderText: 'Wygeneruj widżet dla pojedynczego produktu i wstaw na swoją stronę www, np. pod recenzją produktu. Widżet wyświetli użytkownikom w jakich sklepach mogą kupić dany produkt.',
};
