<template>
    <Card :header="$t('reportsProducts.products')">
        <b-table
            hover
            :items="items"
            :fields="fields"
            show-empty
            :empty-text="$t('noResult')"
            :busy="loading"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-direction.sync="sortDirection"
            :sort-desc="sortDirection === 'desc'"
            @context-changed="fetchData"
        >
            <template #table-busy>
                <div class="d-flex justify-content-center my-2">
                    <b-spinner />
                </div>
            </template>
            <template #cell(name)="props">
                <div class="d-flex align-items-center">
                    <span class="mr-2">{{ props.item.name }}</span>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="d-inline-flex p-1 ml-auto mb-auto flex-shrink-0"
                        @click.stop.prevent="showChart(props.item, spaceIds, campaignIds, timeRange)"
                    >
                        <feather type="bar-chart-2" size="10" />
                    </b-button>
                </div>
            </template>
        </b-table>
        <div class="d-flex justify-content-between align-items-start">
            <JsonToCsv
                class="btn btn-outline-primary d-inline-flex  align-items-center mb-3"
                :items="exportData"
                :fields="exportFields"
                :labels="exportLabels"
                :name="exportFilename"
                :disabled="!canFetchData || exportDataLoading"
                @loading="exportDataLoading = $event"
            >
                {{ $t('transactions.exportTransactions') }}
            </JsonToCsv>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="right"
                pills
                :disabled="loading"
                class="pagination-rounded"
                @input="fetchData"
            />
        </div>
    </Card>
</template>



<script>
    import Card from '@/components/common/Card.vue';
    import JsonToCsv from '@/components/common/JsonToCsv.vue';
    import userStatsService from '@/services/userStatsService';

    export default {
        name: 'ProductsList',
        components: {
            Card,
            JsonToCsv,
        },
        props: {
            spaceIds: {
                type: Array,
                required: true,
            },
            campaignIds: {
                type: Array,
                required: true,
            },
            timeRange: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                fields: [
                    {
                        key: 'name',
                        label: this.$t('dashboard.product'),
                    },
                    {
                        key: 'quantity',
                        label: this.$t('dashboard.unitsSold'),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'amount',
                        label: this.$t('reportsProducts.salesValue'),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'publisherCommission',
                        label: this.$t('widgeterReportsShops.commission'),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ],
                perPage: 50,
                currentPage: 1,
                sortBy: 'quantity',
                sortDirection: 'desc',
                exportDataLoading: false,
            };
        },
        computed: {
            loading() {
                return this.$store.getters['userStats/getLoading']('aggregationSalesStats') || this.$store.state.space.loading.fetchSpaces || this.$store.getters['campaigns/getLoading']('campaigns');
            },
            data() {
                return this.$store.getters['userStats/getAggregationSalesStats'];
            },
            items() {
                return this.formatDataItems(this.data.items);
            },
            totalRows() {
                return this.data.max || this.perPage;
            },
            sortByParam() {
                if (this.sortBy === 'quantity') return 'total_quantity';
                if (this.sortBy === 'amount') return 'total_amount';
                if (this.sortBy === 'publisherCommission') return 'publisher_commission';
                return 'total_quantity';
            },
            sortDirectionParam() {
                return this.sortDirection.toUpperCase();
            },
            exportFields() {
                return this.fields.map(item => item.key);
            },
            exportLabels() {
                return Object.fromEntries(this.fields.map(item => [item.key, item.label]));
            },
            exportFilename() {
                return `reports-products-${this.timeRange.startDate}-${this.timeRange.endDate}.csv`;
            },
            requestParams() {
                return {
                    spaces: this.spaceIds.join(','),
                    campaigns: this.campaignIds.join(','),
                    startDate: this.timeRange.startDate,
                    endDate: this.timeRange.endDate,
                    sort: this.sortByParam,
                    order: this.sortDirectionParam,
                    page: this.currentPage,
                    perPage: this.perPage,
                };
            },
            canFetchData() {
                return this.timeRange.startDate && this.timeRange.endDate && this.spaceIds.length && this.campaignIds.length;
            },
        },
        watch: {
            campaignIds() {
                this.fetchData();
            },
            timeRange() {
                this.fetchData();
            },
        },
        methods: {
            formatDataItems(items) {
                return (
                    items?.map(item => ({
                        ...item,
                        publisherCommission: this.$format.currency(item.publisherCommission),
                        amount: this.$format.currency(item.amount),
                    })) || []
                );
            },
            fetchData() {
                if (!this.canFetchData) return;
                this.$store.dispatch('userStats/fetchAggregationSalesStats', { requestParams: this.requestParams });
            },
            exportData() {
                if (!this.canFetchData) return [];
                return userStatsService.getAggregationSalesStats({ requestParams: { ...this.requestParams, perPage: Number.MAX_SAFE_INTEGER } }).then(data => this.formatDataItems(data.items));
            },
            showChart(item, spaceIds, campaignIds, timeRange) {
                this.$root.$emit('bv::show::modal', 'modal-chart');
                this.$root.$emit('modal-chart-item', { item, spaceIds, campaignIds, timeRange });
            },
        },
    };
</script>
