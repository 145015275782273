/*
    Apply this mixin for components that uses DefaultTable under the hood,
    and pass TABLE_REF ref to DefaultTable.
    This will allow parent component to reach DefaultTable and use its methods, like exportData.
*/

const TABLE_REF = 'TABLE_REF';

export default {
    data() {
        return {
            TABLE_REF,
        };
    },
    methods: {
        exportData(...data) {
            this.$refs[TABLE_REF].exportData(...data);
        },
    },
};
