<template>
    <DataTable
        v-model="selectedRows"
        :header="$t('invoices')"
        :error="error || downloadError"
        :items="items"
        :fields="fields"
        :total-rows="invoices.max"
        :per-page.sync="tableConfig.perPage"
        :sort-by.sync="tableConfig.sortBy"
        :sort-direction.sync="tableConfig.sortDirection"
        :current-page.sync="tableConfig.currentPage"
        :loading="loading"
        :busy-items="busyItems"
        no-sort-reset
        no-local-sorting
        selectable
        selectable-id-key="id"
        selectable-item-key="downloadable"
    >

        <template #cell(publisher)="data">
            {{ data.item.publisher.name }} {{ data.item.publisher.email ? `(${data.item.publisher.email})` : '' }}
        </template>

        <template #cell(createdAt)="data">
            {{ $format.date(data.item.createdAt, 'dd-MM-yyyy') }}
        </template>

        <template #cell(netAmount)="data">
            {{ $format.currency(data.value) }}
        </template>

        <template #headerSide>
            <TimeRangeDropdown v-model="filters.timeRange" :disabled="loading" :options="['last7Days', 'last30days', 'currentWeek', 'currentMonth', 'today', 'custom']" />
        </template>

        <template #innerPagination>
            <div class="d-flex gap-3 align-items-center">
                <ButtonIcon icon="download" :disabled="!items.length || loading || isItemsBusy || !selectedRows.length" :loading="downloading" flip-icon @click="download()">
                    {{ $t('downloadSelected') }}
                </ButtonIcon>
            </div>
        </template>

    </DataTable>
</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';
    import TimeRangeDropdown from '@/components/common/TimeRangeDropdown.vue';
    import exportService from '@/services/exportService';

    export default {
        name: 'Invoices',
        components: {
            DataTable,
            TimeRangeDropdown,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                filters: {
                    timeRange: null,
                },
                tableConfig: {
                    perPage: 50,
                    currentPage: 1,
                    sortBy: 'createdAt',
                    sortDirection: 'desc',
                },
                fields: [
                    {
                        key: 'publisher',
                        label: this.$t('publisherRecipient'),
                    },
                    {
                        key: 'number',
                        label: this.$t('documentNumber'),
                    },
                    {
                        key: 'createdAt',
                        label: this.$t('issueDate'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'netAmount',
                        label: this.$t('netAmount'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                ],
                busyItems: [],
                selectedRows: [],
            };
        },
        computed: {
            invoices() {
                return this.$store.getters['tipbox/getInvoices'];
            },
            items() {
                return this.invoices.items || [];
            },
            error() {
                return this.$store.getters['tipbox/getError']('invoices');
            },
            loading() {
                return this.$store.getters['tipbox/getLoading']('invoices');
            },
            downloading() {
                return this.$store.getters['tipbox/getLoading']('invoicesDownload');
            },
            downloadError() {
                return this.$store.getters['tipbox/getError']('invoicesDownload');
            },
            isItemBusy() {
                return id => !!this.busyItems.find(itemId => itemId === id);
            },
            isItemsBusy() {
                return !!this.busyItems.length;
            },
            filtersParams() {
                const filters = {};
                if (this.filters.timeRange?.startDate) filters.from = this.filters.timeRange.startDate;
                if (this.filters.timeRange?.endDate) filters.to = this.filters.timeRange.endDate;
                return filters;
            },
            tableParams() {
                return {
                    page: this.tableConfig.currentPage,
                    perPage: this.tableConfig.perPage,
                };
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                    ...this.tableParams,
                };
            },
        },
        watch: {
            selectedRows() {
                if (this.selectedRows.length) this.$toastr.close('noInvoicesSelected');
            },
            requestParams: {
                immediate: true,
                handler: 'fetchData',
            },
        },
        methods: {
            async download() {
                if (!this.selectedRows.length) {
                    this.$toastr.info(this.$t('noInvoicesSelected'), null, { id: 'noInvoicesSelected' });
                } else {
                    await this.downloadInvoices();
                }
            },
            async downloadInvoices() {
                this.busyItems = [...this.selectedRows];
                return this.$store
                    .dispatch('tipbox/fetchInvoicesDownload', { requestParams: { invoices: this.selectedRows.join(',') }, responseType: 'blob' })
                    .then(response => {
                        const data = window.URL.createObjectURL(response);
                        const name = `${this.$t('invoices')}-${this.selectedRows.join('-')}`;
                        exportService.exportData(data, name, 'zip');
                    })
                    .catch(() => {})
                    .finally(() => (this.busyItems = []));
            },
            fetchData() {
                this.$store.dispatch('tipbox/fetchInvoices', { requestParams: this.requestParams }).catch(() => {});
            },
        },
    };
</script>
