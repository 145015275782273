<template>
    <div>

        <PageHeader>
            {{ $t('transactionCorrection') }}
        </PageHeader>

        <FormCsv request-endpoint="transactionFileProcessTask/correction" :button-label="$t('import')" @imported="fetchTasks()" />

        <ListCsv ref="refListCsv" />

    </div>
</template>



<script>
    import FormCsv from '../parts/FormCsv.vue';
    import ListCsv from '../parts/ListCsv.vue';

    export default {
        name: 'TransactionsCorrection',
        components: {
            FormCsv,
            ListCsv,
        },
        data() {
            return {};
        },
        methods: {
            fetchTasks() {
                this.$refs.refListCsv?.fetchTasks();
            },
        },
    };
</script>
