<template>
    <Card :header="$t('csvFilesList')">
        <DataTable
            :items="tasks.items || []"
            :fields="fields"
            :total-rows="tasks.max"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :loading="loading"
            no-local-sorting
        >
            <template #cell(date)="{item}">
                <div class="nowrap">{{ $format.dateAndHour(item.createdAt) }}</div>
                <div v-if="item.processedAt" class="nowrap">{{ $format.dateAndHour(item.processedAt) }}</div>
            </template>
            <template #cell(status)="{value}">
                <b-skeleton v-if="loadingTaskStatuses" animation="wave" width="80px" height="18px" class="mb-0" />
                <TaskStatusBadge v-else :status="value" :label="taskStatusesObject[value]" />
            </template>
            <template #cell(type)="{value}">
                <b-skeleton v-if="loadingTaskTypes" animation="wave" width="80px" height="18px" class="mb-0" />
                <span v-else>{{ taskTypesObject[value] }}</span>
            </template>
            <template #cell(comment)="{item}">
                <pre v-if="item.options" class="bg-soft-secondary border border-dark rounded p-2">{{ item.options }}</pre>
                <div v-if="item.comment">{{ item.comment }}</div>
                <div v-if="item.originalFilename" class="badge badge-soft-dark">{{ item.originalFilename }}</div>
            </template>
            <template #cell(action)="{item}">
                <div class="d-flex flex-wrap gap-1 justify-content-end">
                    <ButtonIcon
                        :class="{'opacity-30': !item.isReportDownloadable}"
                        size="sm"
                        class="width-80"
                        hide-label-while-loading
                        :loading="isItemDownloadReport(item.id)"
                        :disabled="!item.isReportDownloadable || isItemDeleted(item.id)"
                        @click="item.isReportDownloadable ? itemDownload(item.id) : null"
                    >
                        {{ $t('report') }}
                    </ButtonIcon>
                    <ButtonIcon
                        size="sm"
                        variant="danger"
                        class="width-80"
                        hide-label-while-loading
                        :loading="isItemDeleted(item.id)"
                        :disabled="isItemDownloadReport(item.id)"
                        @click="itemDelete(item.id)"
                    >
                        {{ $t('delete') }}
                    </ButtonIcon>
                </div>
            </template>
        </DataTable>
    </Card>
</template>



<script>
    import { createItemKey } from '@/store/modules/moduleFactory';
    import TaskStatusBadge from '@/components/common/TaskStatusBadge.vue';
    import DataTable from '@/components/common/DataTable.vue';
    import exportService from '@/services/exportService';

    export default {
        name: 'ListCsv',
        components: {
            TaskStatusBadge,
            DataTable,
        },
        data() {
            return {
                perPage: 10,
                currentPage: 1,
            };
        },
        computed: {
            tasks() {
                return this.$store.getters['transactionFileProcessTask/getTasks'];
            },
            taskTypes() {
                return this.$store.getters['transactionFileProcessTask/getTaskTypes'];
            },
            taskStatuses() {
                return this.$store.getters['transactionFileProcessTask/getTaskStatuses'];
            },
            taskTypesObject() {
                return this.taskTypes.reduce((acc, item) => ({ ...acc, [item.value]: item.label }), {});
            },
            taskStatusesObject() {
                return this.taskStatuses.reduce((acc, item) => ({ ...acc, [item.value]: item.label }), {});
            },
            loading() {
                return this.$store.getters['transactionFileProcessTask/getLoading']('tasks');
            },
            loadingTaskTypes() {
                return this.$store.getters['transactionFileProcessTask/getLoading']('taskTypes');
            },
            loadingTaskStatuses() {
                return this.$store.getters['transactionFileProcessTask/getLoading']('taskStatuses');
            },
            fields() {
                return [
                    {
                        key: 'date',
                        label: `${this.$t('sentAt')}\n${this.$t('processedAt')}`,
                        thClass: 'pre',
                    },
                    {
                        key: 'createdBy.name',
                        label: this.$t('user'),
                    },
                    {
                        key: 'type',
                        label: this.$t('type'),
                    },
                    {
                        key: 'comment',
                        label: `${this.$t('options')}\n${this.$t('comment')}`,
                        thClass: 'pre',
                    },
                    {
                        key: 'status',
                        label: this.$t('status'),
                    },
                    {
                        key: 'action',
                        label: '',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ];
            },
            requestParams() {
                return {
                    ...this.tableParams,
                };
            },
            tableParams() {
                return {
                    page: this.currentPage,
                    perPage: this.perPage,
                    sort: 'createdAt',
                    order: 'DESC',
                };
            },
            isItemDeleted() {
                return id => this.$store.getters['transactionFileProcessTask/getDeletedItem']('tasks', createItemKey({ id }));
            },
            isItemDownloadReport() {
                return id => this.$store.getters['transactionFileProcessTask/getBusyItem']('downloadReport', createItemKey({ id }));
            },
        },
        watch: {
            tableParams: {
                immediate: true,
                handler: 'fetchTasks',
            },
        },
        methods: {
            fetchTasks() {
                this.$store.dispatch('transactionFileProcessTask/fetchTasks', { requestParams: this.requestParams }).catch(() => {});
            },
            itemDownload(id) {
                this.$store
                    .dispatch('transactionFileProcessTask/fetchDownloadReport', { urlParams: { id } })
                    .then(data => {
                        exportService.exportData(`data:text/csv;charset=utf-8,${data}`, `csv-${id}`, 'csv');
                    })
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            itemDelete(id) {
                this.$bvModal
                    .msgBoxConfirm(this.$t('confirmYouWantDelete'), {
                        title: this.$t('confirm'),
                        okTitle: this.$t('yes'),
                        cancelTitle: this.$t('cancel'),
                        okVariant: 'danger',
                    })
                    .then(value => {
                        if (value) {
                            this.$store
                                .dispatch('transactionFileProcessTask/deleteTasks', { urlParams: { id } })
                                .then(this.fetchTasks)
                                .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
                        }
                    });
            },
            fetchTaskTypes() {
                this.$store.dispatch('transactionFileProcessTask/fetchTaskTypes').catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            fetchTaskStatuses() {
                this.$store.dispatch('transactionFileProcessTask/fetchTaskStatuses').catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
        },
        created() {
            if (!this.taskTypes?.length) this.fetchTaskTypes();
            if (!this.taskStatuses?.length) this.fetchTaskStatuses();
        },
    };
</script>

