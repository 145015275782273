<template>
    <b-overlay :show="loading" rounded="sm" class="h-100">
        <div class="card mb-0 h-100">
            <div class="card-body p-0">
                <ErrorMessage :value="errorMessage" class="m-3" />
                <div class="media p-3">
                    <div class="media-body">
                        <span class="text-muted text-uppercase font-size-12 font-weight-bold">{{ title }}</span>
                        <h2 class="mb-0">
                            {{ value }}
                        </h2>
                    </div>
                    <div class="align-self-center">
                        <slot></slot>
                        <span v-if="icon">
                            <feather :type="`${icon}`" class="icon-lg align-middle icon-dual" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>



<script>
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'WidgetStat',
        props: {
            title: {
                type: String,
                default: '',
            },
            value: {
                type: [Number, String],
                default: 0,
            },
            icon: {
                type: String,
                default: '',
            },
            loading: {
                type: Boolean,
                default: false,
            },
            error: {
                type: [Error, Object, String],
                default: null,
            },
        },
        components: {
            ErrorMessage,
        },
        computed: {
            errorMessage() {
                return this.error?.message || this.error || null;
            },
        },
    };
</script>
