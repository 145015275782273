import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';
import { EndpointError } from '@/services/errorService';
import globalData from '@/store/globalData';

export const ENDPOINTS = deepFreeze({
    TRANSACTIONS: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publishers/{{userId}}/tip-transactions';
            if (params.userRole === 'admin' || params.userRole === 'superadmin') return '/api/v1/tip-transactions/{{id?}}';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    CHART: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publishers/{{userId}}/tip-transactions-chart';
            if (params.userRole === 'admin' || params.userRole === 'superadmin') return '/api/v1/tip-transactions-chart';
            throw new EndpointError();
        },
        methods: ['get'],
        default: [],
    },
    STATUSES: {
        URI: () => '/api/v1/tip-transaction-statuses',
        methods: ['get'],
        default: [],
        formatter: data => data?.items,
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
