<template>
    <div>locations</div>
</template>



<script>
    export default {
        name: 'Locations',
        data() {
            return {};
        },
    };
</script>



<style lang="scss" scoped></style>
