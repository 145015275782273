import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';
import { EndpointError } from '@/services/errorService';
import globalData from '@/store/globalData';

export const ENDPOINTS = deepFreeze({
    IMPORT: {
        URI: () => {
            if (globalData.user.isRoleAdmin) return '/api/v1/spaces/{{spaceId}}/producer-products/import';
            throw new EndpointError();
        },
        methods: ['post'],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
