<template>
    <div>
        <PageHeader>{{ $t('webpagesPublishers') }}</PageHeader>
        <StatFilters />
        <StatResults />
    </div>
</template>



<script>
    import StatFilters from './parts/Filters.vue';
    import StatResults from './parts/Results.vue';

    export default {
        name: 'PublishersPages',
        components: {
            StatFilters,
            StatResults,
        },
    };
</script>
