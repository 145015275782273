import { parseObject } from 'query-types';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEqual from 'lodash/isEqual';
import createRequest from '@/utils/createRequest';

const DEFAULT_FILTERS_PARAMS = Object.freeze({});
const DEFAULT_TABLE_PARAMS = Object.freeze({
    perPage: 50,
    page: 1,
    sort: 'createdAt',
    order: 'DESC',
});

export default {
    provide() {
        return {
            parentComponent: this,
        };
    },
    data() {
        return {
            request: null,
        };
    },
    computed: {
        requestParamsFilters() {
            return pickBy(this.filtersParams, value => value !== undefined && value !== null && value.toString() !== '');
        },
        requestParamsTable() {
            return this.tableParams;
        },
        requestParams() {
            return {
                ...this.requestParamsFilters,
                ...this.requestParamsTable,
            };
        },
        isItemsBusy() {
            return !!this.$refs?.Results?.isItemsBusy;
        },
        disabled() {
            return this.loading || this.isItemsBusy;
        },
        requestData() {
            return this.request?.data || {};
        },
        items() {
            if (Array.isArray(this.requestData)) return this.requestData;
            if (Array.isArray(this.requestData?.items)) return this.requestData.items;
            return [];
        },
        error() {
            return this.request?.error;
        },
        loading() {
            return this.request?.loading;
        },
    },
    watch: {
        'tableParams.perPage': {
            handler(value) {
                this.watchTableParamsHandler('perPage', value);
            },
        },
        'tableParams.page': {
            handler(value) {
                this.watchTableParamsHandler('page', value);
            },
        },
        'tableParams.sort': {
            handler(value) {
                this.watchTableParamsHandler('sort', value);
            },
        },
        'tableParams.order': {
            handler(value) {
                this.watchTableParamsHandler('order', value);
            },
        },
        '$route.query': {
            handler(newQuery, oldQuery) {
                if (!this.$route.meta.isUpdated) this.initDataFromQuery();
                const paramsKeys = [...Object.keys(this.defaultFiltersParams), ...Object.keys(this.defaultTableParams)];
                const newQueryParams = pick(newQuery, paramsKeys);
                const oldQueryParams = pick(oldQuery, paramsKeys);
                if (!isEqual(newQueryParams, oldQueryParams)) this.fetchData();
                delete this.$route.meta.isUpdated;
            },
        },
    },
    methods: {
        updateUrl() {
            const query = { ...this.requestParamsFilters, ...this.requestParamsTable };
            this.$router.push({ query }).catch(() => {});
            this.$route.meta.isUpdated = true;
        },
        watchTableParamsHandler(name, value) {
            const queryParamValue = this.$route.query[name]?.toString() || this.defaultTableParams[name]?.toString();
            if (value?.toString() !== queryParamValue) {
                this.updateUrl();
            }
        },
        resetFiltersParams() {
            this.filtersParams = cloneDeep(this.defaultFiltersParams);
            this.updateUrl();
        },
        initDataFromQuery() {
            const { filtersParams, tableParams, filters } = this.initParams(this.defaultFiltersParams, this.defaultTableParams);
            this.filtersParams = filtersParams;
            this.tableParams = tableParams;
            this.filters = filters;
        },
        filterData() {
            this.filtersParams = cloneDeep(this.filters);
            this.updateUrl();
        },
        resetData() {
            this.resetFiltersParams();
            this.filters = cloneDeep(this.filtersParams);
        },
        fetchData(force = false) {
            if (!this.request) return Promise.resolve(null);
            return this.request.get({ requestParams: this.requestParams, cache: !force });
        },
        reloadData() {
            this.fetchData(true);
        },
    },
    beforeCreate() {
        this.initParams = (defaultFiltersParams = DEFAULT_FILTERS_PARAMS, defaultTableParams = DEFAULT_TABLE_PARAMS) => {
            const queryParams = parseObject(this.$route.query);
            const defaultFiltersParamsClone = cloneDeep(defaultFiltersParams);
            const defaultTableParamsClone = cloneDeep(defaultTableParams);
            const filtersParams = { ...defaultFiltersParamsClone, ...pick(queryParams, Object.keys(defaultFiltersParamsClone)) };
            const tableParams = { ...defaultTableParamsClone, ...pick(queryParams, Object.keys(defaultTableParamsClone)) };
            return {
                defaultFiltersParams: Object.freeze(defaultFiltersParamsClone),
                defaultTableParams: Object.freeze(defaultTableParamsClone),
                filtersParams,
                tableParams,
                filters: cloneDeep(filtersParams),
            };
        };
        this.createRequest = createRequest;
    },
    created() {
        this.fetchData();
    },
};
