<template>
    <b-overlay :show="loading && product" rounded="sm" class="h-100">
        <Card :header="$t('offerDetails')" class="m-0 shadow-none">
            <ul class="list-unstyled">
                <li class="mb-3">
                    <strong class="d-inline-block width-min-200">{{ $t('offerId') }}</strong>
                    <span class="d-inline-block width-min-150">
                        <span v-if="product">{{ product.id }}</span>
                        <b-skeleton v-if="!product" animation="wave" width="80%" class="mb-0 d-inline-block" />
                    </span>
                </li>
                <li class="mb-3">
                    <strong class="d-inline-block width-min-200">{{ $t('storeOfferId') }}</strong>
                    <span class="d-inline-block width-min-150">
                        <span v-if="product">{{ product.offerId }}</span>
                        <b-skeleton v-if="!product" animation="wave" width="50%" class="mb-0 d-inline-block" />
                    </span>
                </li>
                <li class="mb-3">
                    <strong class="d-inline-block width-min-200">{{ $t('lastUpdate') }}</strong>
                    <span class="d-inline-block width-min-150">
                        <span v-if="product">{{ dateFormat(product.updatedAt, 'yyyy-MM-dd HH:mm') }}</span>
                        <b-skeleton v-if="!product" animation="wave" width="100%" class="mb-0 d-inline-block" />
                    </span>
                </li>
                <li class="mb-3">
                    <strong class="d-inline-block width-min-200">{{ $t('offerType') }}</strong>
                    <span class="d-inline-block width-min-150">
                        <span v-if="product">{{ product.typeTranslated }}</span>
                        <b-skeleton v-if="!product" animation="wave" width="70%" class="mb-0 d-inline-block" />
                    </span>
                </li>
                <li class="mb-3">
                    <strong class="d-inline-block width-min-200">{{ $t('aggregation') }}</strong>
                    <span class="d-inline-block width-min-150">
                        <BadgeBoolean v-if="product" :value="product.aggregated" />
                        <b-skeleton v-if="!product" animation="wave" width="30%" class="mb-0 d-inline-block" />
                    </span>
                </li>
            </ul>
        </Card>
    </b-overlay>
</template>



<script>
    import { format as dateFormat } from 'date-fns';
    import Card from '@/components/common/Card.vue';
    import BadgeBoolean from '@/components/common/BadgeBoolean.vue';

    export default {
        name: 'Details',
        components: {
            Card,
            BadgeBoolean,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        computed: {
            product() {
                return this.parentComponent.product;
            },
            loading() {
                return this.parentComponent.loading;
            },
        },
        methods: {
            dateFormat,
        },
    };
</script>
