import { deburr, orderBy } from 'lodash';
import toastService from '@/services/toastService';
import Storage from '@/Storage';
import { i18n } from '../../i18n/i18n';
import spaceService, { CampaignStatus, translateCampaignStatus } from '../../services/spaceService';
import {
    ACTIVATE_PERMISSION_FAILURE,
    ACTIVATE_PERMISSION_REQUEST,
    ACTIVATE_PERMISSION_SUCCESS,
    CLEAN_SPACES,
    CREATE_SPACE_FAILURE,
    CREATE_SPACE_REQUEST,
    CREATE_SPACE_SUCCESS,
    DEACTIVATE_PERMISSION_FAILURE,
    DEACTIVATE_PERMISSION_REQUEST,
    DEACTIVATE_PERMISSION_SUCCESS,
    FETCH_API_TOKEN_FAILURE,
    FETCH_API_TOKEN_REQUEST,
    FETCH_API_TOKEN_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
    FETCH_CATEGORIES_REQUEST,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_PERMISSIONS_FAILURE,
    FETCH_PERMISSIONS_REQUEST,
    FETCH_PERMISSIONS_SUCCESS,
    FETCH_SPACE_ALLOWED_CAMPAIGNS_FAILURE,
    FETCH_SPACE_ALLOWED_CAMPAIGNS_REQUEST,
    FETCH_SPACE_ALLOWED_CAMPAIGNS_SUCCESS,
    FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_FAILURE,
    FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_REQUEST,
    FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_SUCCESS,
    FETCH_SPACE_STATUSES_FAILURE,
    FETCH_SPACE_STATUSES_REQUEST,
    FETCH_SPACE_STATUSES_SUCCESS,
    FETCH_SPACE_TYPES_FAILURE,
    FETCH_SPACE_TYPES_REQUEST,
    FETCH_SPACE_TYPES_SUCCESS,
    FETCH_SPACES_FAILURE,
    FETCH_SPACES_REQUEST,
    FETCH_SPACES_SUCCESS,
    GENERATE_API_TOKEN_FAILURE,
    GENERATE_API_TOKEN_REQUEST,
    GENERATE_API_TOKEN_SUCCESS,
    REVOKE_API_TOKEN_FAILURE,
    REVOKE_API_TOKEN_REQUEST,
    REVOKE_API_TOKEN_SUCCESS,
    SET_CURRENT_SPACE,
    UPDATE_SPACE_FAILURE,
    UPDATE_SPACE_REQUEST,
    UPDATE_SPACE_SUCCESS,
} from '../mutationTypes';

const spaceIdFromStorage = Number.parseInt(window.localStorage.getItem(Storage.SPACE_ID), 10);
const initialSpaceId = !Number.isNaN(spaceIdFromStorage) ? spaceIdFromStorage : null;

const initialState = {
    spacesIds: [],
    spaces: {},
    total: 0,
    currentSpaceId: initialSpaceId,
    allowedCampaignsIds: [],
    allowedCampaigns: {},
    producerProductCategoriesIds: [],
    producerProductCategories: {},
    permissionsIds: [],
    permissions: {},
    spaceTypesIds: [],
    spaceTypes: {},
    spaceStatusesIds: [],
    spaceStatuses: {},
    categoriesIds: [],
    categories: {},
    apiToken: null,
    spacesInitialized: false,
    loading: {
        fetchSpaces: false,
        createSpace: false,
        updateSpace: false,
        fetchAllowedCampaigns: false,
        fetchProducerProductCategories: false,
        activatePermission: false,
        deactivatePermission: false,
        fetchPermissions: false,
        fetchSpaceTypes: false,
        fetchSpaceStatuses: false,
        fetchCategories: false,
        fetchApiToken: false,
        generateApiToken: false,
        revokeApiToken: false,
    },
    error: {
        fetchSpaces: null,
    },
};

const getters = {
    spaceCount: state => state.total,

    // eslint-disable-next-line no-shadow
    hasOnlyOneSpace: (state, getters) => getters.spaceCount === 1,

    getSpace: state => spaceId => state.spaces[spaceId],

    currentSpaceId: state => state.currentSpaceId,

    currentSpace: state => {
        if (!state.currentSpaceId) {
            return null;
        }
        return state.spaces[state.currentSpaceId];
    },

    // eslint-disable-next-line no-shadow
    isDemo: (state, getters) => {
        const space = getters.currentSpace;
        if (!space) {
            return false;
        }
        return space.id === spaceService.demoSpace.id;
    },

    // eslint-disable-next-line no-shadow
    currentSpacePermissions: (state, getters) => {
        const space = getters.currentSpace;
        if (!space) {
            return [];
        }
        return space.permissions || [];
    },

    spaceOptions: state =>
        state.spacesIds.map(spaceId => ({
            value: state.spaces[spaceId].id,
            text: state.spaces[spaceId].name,
        })),

    allowedCampaignOptions: state =>
        state.allowedCampaignsIds.map(campaignId => ({
            value: state.allowedCampaigns[campaignId].id,
            text: state.allowedCampaigns[campaignId].name,
        })),

    spaceTypeOptions: state =>
        state.spaceTypesIds.map(spaceTypeId => ({
            value: state.spaceTypes[spaceTypeId].value,
            text: state.spaceTypes[spaceTypeId].label,
        })),

    categoryOptions: state =>
        state.categoriesIds.map(categoryId => ({
            value: state.categories[categoryId].id,
            text: state.categories[categoryId].name,
        })),

    getProducerProductCategories: state =>
        state.producerProductCategoriesIds.map(categoryId => ({
            value: state.producerProductCategories[categoryId].id,
            text: state.producerProductCategories[categoryId].name,
        })),

    campaignStatusOptions: () =>
        Object.keys(CampaignStatus).map(statusId => ({
            value: statusId,
            text: translateCampaignStatus(CampaignStatus[statusId]),
        })),

    getSpacesInitialized: state => state.spacesInitialized,
};

const actions = {
    cleanSpaces({ commit }) {
        commit(CLEAN_SPACES);
    },

    async fetchSpaces({ commit, state, rootState, dispatch }, payload = {}) {
        try {
            // prevent multiple concurrent requests
            if (state.loading.fetchSpaces) {
                return;
            }

            const { force, requestParams, disableStatuses, cache } = payload;

            if (!force && state.spacesIds && state.spacesIds.length > 0) {
                return;
            }

            commit(FETCH_SPACES_REQUEST);
            const requestData = {};
            requestData.statuses = disableStatuses ? '1,2,3' : '1,2'; // 1 - new, 2 - accepted, 3 - rejected
            const spacesResult = await spaceService.fetchSpaces(rootState.auth.user.id, { ...requestData, ...requestParams }, cache);
            const { items } = spacesResult;
            let { max } = spacesResult;

            const demoEnabled = rootState.auth.user && rootState.auth.user.demo;
            if (demoEnabled) {
                items.push(spaceService.demoSpace);
                max += 1;
            }

            commit(FETCH_SPACES_SUCCESS, {
                items,
                total: max,
            });

            // check if spaces list contains existing currentSpaceId
            if (!items || items.length === 0) {
                // if no spaces found then make currentSpaceId null
                dispatch('setCurrentSpace', { spaceId: null });
            } else if (!items.find(space => space.id === state.currentSpaceId)) {
                // if currentSpaceId is not found in spaces list then set it to first available space
                dispatch('setCurrentSpace', { spaceId: items[0].id });
            }
            // eslint-disable-next-line consistent-return
            return spacesResult;
        } catch (e) {
            commit(FETCH_SPACES_FAILURE, e);
            throw e;
        }
    },

    async createSpace({ commit, rootState, dispatch }, payload = {}) {
        try {
            const { name, url, categories, type, description = '...' } = payload;

            commit(CREATE_SPACE_REQUEST);

            await spaceService.createSpace(rootState.auth.user.id, {
                name,
                url,
                categories,
                type,
                description,
            });

            commit(CREATE_SPACE_SUCCESS);

            dispatch('fetchSpaces', { force: true, cache: false });
        } catch (e) {
            commit(CREATE_SPACE_FAILURE);
            throw e;
        }
    },

    async updateSpace({ commit }, payload = {}) {
        try {
            const { id, name, url, categories, type } = payload;

            commit(UPDATE_SPACE_REQUEST);

            const space = await spaceService.updateSpace(id, {
                name,
                url,
                categories,
                type,
            });

            commit(UPDATE_SPACE_SUCCESS, { space });
        } catch (e) {
            commit(UPDATE_SPACE_FAILURE);
            throw e;
        }
    },

    async updateSpaceWebhook({ commit }, payload = {}) {
        try {
            const { id, webhook } = payload;

            commit(UPDATE_SPACE_REQUEST);

            const space = await spaceService.updateSpace(id, {
                webhook,
            });

            commit(UPDATE_SPACE_SUCCESS, { space });
            return space;
        } catch (e) {
            commit(UPDATE_SPACE_FAILURE);
            throw e;
        }
    },

    setCurrentSpace({ commit }, payload) {
        const { spaceId } = payload;

        commit(SET_CURRENT_SPACE, {
            spaceId,
        });

        // dispatch('products/cleanProducts', null, { root: true });
        // dispatch('dashboard/cleanDashboard', null, { root: true });
        // dispatch('plugin/cleanPlugin', null, { root: true });
        // dispatch('reportsProducts/clean', null, { root: true });
        // dispatch('reportsCategory/clean', null, { root: true });
        // dispatch('reportsSales/clean', null, { root: true });
        // dispatch('reportsShops/clean', null, { root: true });

        // store space ID for use between page refresh
        if (spaceId) {
            window.localStorage.setItem(Storage.SPACE_ID, spaceId);
        } else {
            window.localStorage.removeItem(Storage.SPACE_ID);
        }
    },

    async fetchAllowedCampaigns({ commit, state }, payload = {}) {
        try {
            const { force } = payload;
            const spaceId = state.currentSpaceId;

            if (!spaceId) {
                return;
            }

            if (!force && state.allowedCampaignsIds && state.allowedCampaignsIds.length > 0) {
                return;
            }

            commit(FETCH_SPACE_ALLOWED_CAMPAIGNS_REQUEST);

            const allowedCampaigns = await spaceService.fetchAllowedCampaigns(spaceId);

            commit(FETCH_SPACE_ALLOWED_CAMPAIGNS_SUCCESS, {
                items: allowedCampaigns,
            });
        } catch (e) {
            commit(FETCH_SPACE_ALLOWED_CAMPAIGNS_FAILURE);
            throw e;
        }
    },

    async fetchProducerProductCategories({ commit, state }, payload = {}) {
        try {
            const { force } = payload;
            const spaceId = state.currentSpaceId;

            if (!spaceId) {
                return;
            }

            if (!force && state.producerProductCategoriesIds && state.producerProductCategoriesIds.length > 0) {
                return;
            }

            commit(FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_REQUEST);

            const producerProductCategories = await spaceService.fetchProducerProductCategories(spaceId);

            commit(FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_SUCCESS, {
                items: producerProductCategories,
            });
        } catch (e) {
            commit(FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_FAILURE);
            throw e;
        }
    },

    async activatePermission({ commit, state }, payload = {}) {
        try {
            const { permissionName } = payload;
            const spaceId = state.currentSpaceId;

            if (!spaceId) {
                return;
            }

            commit(ACTIVATE_PERMISSION_REQUEST);
            const permissionData = await spaceService.activatePermission(spaceId, permissionName);
            commit(ACTIVATE_PERMISSION_SUCCESS, {
                spaceId,
                permissionData,
            });
            this._vm.$toast.info(i18n.t('activateReportModal.success', { name: i18n.t(`permissionContent.name.${permissionName}`) }), { color: 'success' });
        } catch (e) {
            commit(ACTIVATE_PERMISSION_FAILURE);
            throw e;
        }
    },

    async deactivatePermission({ commit, state }, payload = {}) {
        try {
            const { permissionId, permissionName } = payload;
            const spaceId = state.currentSpaceId;

            if (!spaceId || !permissionId || !permissionName) {
                return;
            }

            commit(DEACTIVATE_PERMISSION_REQUEST);
            const permissionData = await spaceService.deactivatePermission(permissionId, permissionName);
            commit(DEACTIVATE_PERMISSION_SUCCESS, {
                permissionData,
            });
            this._vm.$toast.info(i18n.t('cancelSubscriptionModal.success', { name: i18n.t(`permissionContent.name.${permissionName}`) }), { color: 'success' });
        } catch (e) {
            commit(DEACTIVATE_PERMISSION_FAILURE);
            throw e;
        }
    },

    async fetchPermissions({ commit, state, rootState }) {
        try {
            const userId = rootState.auth.user.id;
            const spaceId = state.currentSpaceId;

            if (!spaceId || !userId) {
                return;
            }

            commit(FETCH_PERMISSIONS_REQUEST);
            const reqStatuses = 'active,canceled';
            const response = await spaceService.fetchPermissions(userId, reqStatuses);
            const spacePermissions = response.items.filter(permission => permission.space.id === spaceId);
            const permissions = Object.keys(spaceService.permissions).map(key => {
                const name = spaceService.permissions[key];
                const userPermission = spacePermissions.find(item => item.permission === name);
                if (userPermission) {
                    return userPermission;
                }
                return {
                    permission: name,
                    status: 'inactive',
                };
            });
            commit(FETCH_PERMISSIONS_SUCCESS, {
                permissions,
            });
        } catch (e) {
            commit(FETCH_PERMISSIONS_FAILURE);
            throw e;
        }
    },

    async fetchSpaceTypes({ commit, state }, payload = {}) {
        try {
            const { force } = payload;

            if (!force && state.spaceTypesIds && state.spaceTypesIds.length > 0) {
                return;
            }

            commit(FETCH_SPACE_TYPES_REQUEST);
            const spaceTypes = await spaceService.fetchSpaceTypes();

            commit(FETCH_SPACE_TYPES_SUCCESS, {
                items: spaceTypes.items || [],
            });
        } catch (e) {
            commit(FETCH_SPACE_TYPES_FAILURE);
            throw e;
        }
    },

    async fetchSpaceStatuses({ commit, state }, payload = {}) {
        try {
            const { force } = payload;

            if (!force && state.spaceStatusesIds && state.spaceStatusesIds.length > 0) {
                return;
            }

            commit(FETCH_SPACE_STATUSES_REQUEST);
            const spaceStatuses = await spaceService.fetchSpaceStatuses();

            commit(FETCH_SPACE_STATUSES_SUCCESS, {
                items: spaceStatuses,
            });
        } catch (e) {
            commit(FETCH_SPACE_STATUSES_FAILURE);
            throw e;
        }
    },

    async fetchCategories({ commit, state }, payload = {}) {
        try {
            const { force } = payload;

            if (!force && state.categoriesIds && state.categoriesIds.length > 0) {
                return;
            }

            commit(FETCH_CATEGORIES_REQUEST);
            let categories = await spaceService.fetchCategories();

            if (categories && categories.length > 0) {
                categories = categories.filter(cat => cat.isActive === true);
            }

            commit(FETCH_CATEGORIES_SUCCESS, {
                items: categories,
            });
        } catch (e) {
            commit(FETCH_CATEGORIES_FAILURE);
            throw e;
        }
    },

    async fetchApiToken({ commit, state }) {
        try {
            const spaceId = state.currentSpaceId;

            if (!spaceId) {
                return;
            }

            commit(FETCH_API_TOKEN_REQUEST);
            const apiToken = await spaceService.fetchApiToken(spaceId);

            commit(FETCH_API_TOKEN_SUCCESS, { apiToken });
        } catch (e) {
            commit(FETCH_API_TOKEN_FAILURE);
            throw e;
        }
    },

    async generateApiToken({ commit, state }) {
        try {
            const spaceId = state.currentSpaceId;

            if (!spaceId) {
                return;
            }

            commit(GENERATE_API_TOKEN_REQUEST);
            const apiToken = await spaceService.generateApiToken(spaceId);

            commit(GENERATE_API_TOKEN_SUCCESS, { apiToken });
            toastService.successToast(i18n.t('api.generateSuccess'));
        } catch (e) {
            commit(GENERATE_API_TOKEN_FAILURE);
            throw e;
        }
    },

    async revokeApiToken({ commit, state }) {
        try {
            const spaceId = state.currentSpaceId;

            if (!spaceId) {
                return;
            }

            commit(REVOKE_API_TOKEN_REQUEST);
            await spaceService.revokeApiToken(spaceId);

            commit(REVOKE_API_TOKEN_SUCCESS);
            toastService.successToast(i18n.t('api.revokeSuccess'));
        } catch (e) {
            commit(REVOKE_API_TOKEN_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // CLEAN_SPACES
    // -----------------------------------------
    [CLEAN_SPACES](state) {
        state.spacesIds = [];
        state.spaces = {};
        state.total = 0;
        state.currentSpaceId = null;
        state.allowedCampaignsIds = [];
        state.allowedCampaigns = {};
        state.producerProductCategoriesIds = [];
        state.producerProductCategories = {};
        state.permissionsIds = [];
        state.permissions = {};
        state.spaceTypesIds = [];
        state.spaceTypes = {};
        state.spaceStatusesIds = [];
        state.spaceStatuses = {};
        state.categoriesIds = [];
        state.categories = {};
        state.apiToken = null;
        state.loading.fetchSpaces = false;
        state.loading.createSpace = false;
        state.loading.updateSpace = false;
        state.loading.fetchAllowedCampaigns = false;
        state.loading.fetchProducerProductCategories = false;
        state.loading.activatePermission = false;
        state.loading.deactivatePermission = false;
        state.loading.fetchPermissions = false;
        state.loading.fetchSpaceTypes = false;
        state.loading.fetchSpaceStatuses = false;
        state.loading.fetchCategories = false;
        state.loading.fetchApiToken = false;
        state.loading.generateApiToken = false;
        state.loading.revokeApiToken = false;
    },

    // -----------------------------------------
    // FETCH_SPACES
    // -----------------------------------------
    [FETCH_SPACES_REQUEST](state) {
        state.loading.fetchSpaces = true;
        state.error.fetchSpaces = null;
    },
    [FETCH_SPACES_SUCCESS](state, payload) {
        const { items, total } = payload;
        const spacesIds = [];
        const spaces = {};
        items.forEach(space => {
            spacesIds.push(space.id);
            spaces[space.id] = space;
        });
        state.loading.fetchSpaces = false;
        state.error.fetchSpaces = null;
        state.spacesIds = spacesIds;
        state.spaces = spaces;
        state.total = total;
    },
    [FETCH_SPACES_FAILURE](state, error) {
        state.loading.fetchSpaces = false;
        state.error.fetchSpaces = error;
    },

    // -----------------------------------------
    // SET_CURRENT_SPACE
    // -----------------------------------------
    [SET_CURRENT_SPACE](state, payload) {
        const { spaceId } = payload;
        state.currentSpaceId = spaceId;
        state.campaignsIds = [];
        state.campaigns = {};
        state.producerProductCategoriesIds = [];
        state.producerProductCategories = {};
    },

    // -----------------------------------------
    // CREATE_SPACE
    // -----------------------------------------
    [CREATE_SPACE_REQUEST](state) {
        state.loading.createSpace = true;
    },
    [CREATE_SPACE_SUCCESS](state) {
        state.loading.createSpace = false;
    },
    [CREATE_SPACE_FAILURE](state) {
        state.loading.createSpace = false;
    },

    // -----------------------------------------
    // UPDATE_SPACE
    // -----------------------------------------
    [UPDATE_SPACE_REQUEST](state) {
        state.loading.updateSpace = true;
    },
    [UPDATE_SPACE_SUCCESS](state, payload) {
        const { space } = payload;
        state.loading.updateSpace = false;
        state.spaces = {
            ...state.spaces,
            [space.id]: space,
        };
    },
    [UPDATE_SPACE_FAILURE](state) {
        state.loading.updateSpace = false;
    },

    // -----------------------------------------
    // FETCH_SPACE_ALLOWED_CAMPAIGNS
    // -----------------------------------------
    [FETCH_SPACE_ALLOWED_CAMPAIGNS_REQUEST](state) {
        state.loading.fetchAllowedCampaigns = true;
    },
    [FETCH_SPACE_ALLOWED_CAMPAIGNS_SUCCESS](state, payload) {
        const { items } = payload;
        const allowedCampaignsIds = [];
        const allowedCampaigns = {};
        const orderedItems = orderBy(items, [item => deburr(item.name.toLowerCase())], ['asc']);
        orderedItems.forEach(campaign => {
            allowedCampaignsIds.push(campaign.id);
            allowedCampaigns[campaign.id] = campaign;
        });
        state.loading.fetchAllowedCampaigns = false;
        state.allowedCampaignsIds = allowedCampaignsIds;
        state.allowedCampaigns = allowedCampaigns;
    },
    [FETCH_SPACE_ALLOWED_CAMPAIGNS_FAILURE](state) {
        state.loading.fetchAllowedCampaigns = false;
    },

    // -----------------------------------------
    // FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES
    // -----------------------------------------
    [FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_REQUEST](state) {
        state.loading.fetchProducerProductCategories = true;
    },
    [FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_SUCCESS](state, payload) {
        const { items } = payload;
        const producerProductCategoriesIds = [];
        const producerProductCategories = {};
        const orderedItems = orderBy(items, [item => deburr(item.name.toLowerCase())], ['asc']);
        orderedItems.forEach(campaign => {
            producerProductCategoriesIds.push(campaign.id);
            producerProductCategories[campaign.id] = campaign;
        });
        state.loading.fetchProducerProductCategories = false;
        state.producerProductCategoriesIds = producerProductCategoriesIds;
        state.producerProductCategories = producerProductCategories;
    },
    [FETCH_SPACE_PRODUCER_PRODUCT_CATEGORIES_FAILURE](state) {
        state.loading.fetchProducerProductCategories = false;
    },

    // -----------------------------------------
    // ACTIVATE_PERMISSION
    // -----------------------------------------
    [ACTIVATE_PERMISSION_REQUEST](state) {
        state.loading.activatePermission = true;
    },
    [ACTIVATE_PERMISSION_SUCCESS](state, payload) {
        const { spaceId, permissionData } = payload;
        const { space, ...permission } = permissionData;
        const permissions = state.spaces[spaceId].permissions || [];
        state.loading.activatePermission = false;
        state.spaces[spaceId] = {
            ...state.spaces[spaceId],
            permissions: [...permissions, permission.permission],
        };
        state.permissions = {
            ...state.permissions,
            [permission.permission]: permission,
        };
    },
    [ACTIVATE_PERMISSION_FAILURE](state) {
        state.loading.activatePermission = false;
    },

    // -----------------------------------------
    // DEACTIVATE_PERMISSION
    // -----------------------------------------
    [DEACTIVATE_PERMISSION_REQUEST](state) {
        state.loading.deactivatePermission = true;
    },
    [DEACTIVATE_PERMISSION_SUCCESS](state, payload) {
        const { permissionData } = payload;
        const { space, ...permission } = permissionData;
        state.loading.deactivatePermission = false;
        state.permissions = {
            ...state.permissions,
            [permission.permission]: permission,
        };
    },
    [DEACTIVATE_PERMISSION_FAILURE](state) {
        state.loading.deactivatePermission = false;
    },

    // -----------------------------------------
    // FETCH_PERMISSIONS
    // -----------------------------------------
    [FETCH_PERMISSIONS_REQUEST](state) {
        state.loading.fetchPermissions = true;
    },
    [FETCH_PERMISSIONS_SUCCESS](state, payload) {
        const { permissions } = payload;
        const permissionsIds = [];
        const permissionsById = {};
        permissions.forEach(permission => {
            const { space, ...permissionData } = permission;
            permissionsIds.push(permission.permission);
            permissionsById[permission.permission] = permissionData;
        });
        state.loading.fetchPermissions = false;
        state.permissionsIds = permissionsIds;
        state.permissions = permissionsById;
    },
    [FETCH_PERMISSIONS_FAILURE](state) {
        state.loading.fetchPermissions = false;
    },

    // -----------------------------------------
    // FETCH_SPACE_TYPES
    // -----------------------------------------
    [FETCH_SPACE_TYPES_REQUEST](state) {
        state.loading.fetchSpaceTypes = true;
    },
    [FETCH_SPACE_TYPES_SUCCESS](state, payload) {
        const { items } = payload;
        const spaceTypesIds = [];
        const spaceTypes = {};
        items.forEach(type => {
            spaceTypesIds.push(type.value);
            spaceTypes[type.value] = type;
        });
        state.loading.fetchSpaceTypes = false;
        state.spaceTypesIds = spaceTypesIds;
        state.spaceTypes = spaceTypes;
    },
    [FETCH_SPACE_TYPES_FAILURE](state) {
        state.loading.fetchSpaceTypes = false;
    },

    // -----------------------------------------
    // FETCH_SPACE_STATUSES
    // -----------------------------------------
    [FETCH_SPACE_STATUSES_REQUEST](state) {
        state.loading.fetchSpaceStatuses = true;
    },
    [FETCH_SPACE_STATUSES_SUCCESS](state, payload) {
        const { items } = payload;
        const spaceStatusesIds = [];
        const spaceStatuses = {};
        items.forEach(status => {
            spaceStatusesIds.push(status.value);
            spaceStatuses[status.value] = status;
        });
        state.loading.fetchSpaceStatuses = false;
        state.spaceStatusesIds = spaceStatusesIds;
        state.spaceStatuses = spaceStatuses;
    },
    [FETCH_SPACE_STATUSES_FAILURE](state) {
        state.loading.fetchSpaceStatuses = false;
    },

    // -----------------------------------------
    // FETCH_CATEGORIES
    // -----------------------------------------
    [FETCH_CATEGORIES_REQUEST](state) {
        state.loading.fetchCategories = true;
    },
    [FETCH_CATEGORIES_SUCCESS](state, payload) {
        const { items } = payload;
        const categoriesIds = [];
        const categories = {};
        items.forEach(category => {
            categoriesIds.push(category.id);
            categories[category.id] = category;
        });
        state.loading.fetchCategories = false;
        state.categoriesIds = categoriesIds;
        state.categories = categories;
    },
    [FETCH_CATEGORIES_FAILURE](state) {
        state.loading.fetchCategories = false;
    },

    // -----------------------------------------
    // FETCH_API_TOKEN
    // -----------------------------------------
    [FETCH_API_TOKEN_REQUEST](state) {
        state.loading.fetchApiToken = true;
    },
    [FETCH_API_TOKEN_SUCCESS](state, payload) {
        const { apiToken } = payload;
        state.loading.fetchApiToken = false;
        state.apiToken = apiToken;
    },
    [FETCH_API_TOKEN_FAILURE](state) {
        state.loading.fetchApiToken = false;
        state.apiToken = null;
    },

    // -----------------------------------------
    // GENERATE_API_TOKEN
    // -----------------------------------------
    [GENERATE_API_TOKEN_REQUEST](state) {
        state.loading.generateApiToken = true;
    },
    [GENERATE_API_TOKEN_SUCCESS](state, payload) {
        const { apiToken } = payload;
        state.loading.generateApiToken = false;
        state.apiToken = apiToken;
    },
    [GENERATE_API_TOKEN_FAILURE](state) {
        state.loading.generateApiToken = false;
    },

    // -----------------------------------------
    // REVOKE_API_TOKEN
    // -----------------------------------------
    [REVOKE_API_TOKEN_REQUEST](state) {
        state.loading.revokeApiToken = true;
    },
    [REVOKE_API_TOKEN_SUCCESS](state) {
        state.loading.revokeApiToken = false;
        state.apiToken = null;
    },
    [REVOKE_API_TOKEN_FAILURE](state) {
        state.loading.revokeApiToken = false;
    },

    setSpacesInitialized(state, payload) {
        state.spacesInitialized = !!payload;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
