<template>
    <b-modal
        :id="`modal-preview-${$parent.modalItemName}`"
        ref="modal"
        :title="$t('transactions.productsList')"
        size="xl"
        :hide-footer="true"
    >
        <template v-if="item">
            <p class="sub-header">
                {{ $t('transactions.productsListTransaction', { id: item.transId, campaign: item.campaign.name }) }}
            </p>
            <b-table
                hover
                :items="item.items"
                :fields="fields"
                show-empty
                :empty-text="$t('noResult')"
                foot-clone
                no-footer-sorting
            >
                <template #cell(name)="data">
                    <a :href="data.item.url" target="_blank">{{ data.value || $t('transactions.productNoInfo') }}</a>
                </template>
                <template #cell(amount)="data">
                    {{ $format.currency(data.value) }}
                </template>
                <template #cell(publisherCommission)="data">
                    {{ $format.currency(data.value) }}
                </template>
                <template #foot(name)>
                    {{ $t('transactions.total') }}
                </template>
                <template #foot(quantity)>
                    {{ sumBy(item.items, 'quantity') }}
                </template>
                <template #foot(amount)>
                    {{ $format.currency(sumBy(item.items, 'amount')) }}
                </template>
                <template #foot(publisherCommission)>
                    {{ $format.currency(sumBy(item.items, 'publisherCommission')) }}
                </template>
            </b-table>
        </template>
    </b-modal>
</template>



<script>
    import sumBy from 'lodash/sumBy';

    export default {
        name: 'ItemPreview',
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                item: null,
                fields: [
                    {
                        key: 'name',
                        label: this.$t('transactions.productName'),
                        sortable: true,
                    },
                    {
                        key: 'quantity',
                        label: this.$t('transactions.quantity'),
                        sortable: true,
                    },
                    {
                        key: 'amount',
                        label: this.$t('value'),
                        sortable: true,
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'publisherCommission',
                        label: this.$t('commission'),
                        sortable: true,
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                ],
            };
        },
        methods: {
            sumBy,
            onItem(item) {
                this.item = item;
            },
        },
        mounted() {
            this.$root.$on(`preview-${this.parentComponent.modalItemName}`, this.onItem);
        },
        beforeDestroy() {
            this.$root.$off(`preview-${this.parentComponent.modalItemName}`, this.onItem);
        },
    };
</script>
