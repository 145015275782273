<template>
    <div>
        <h4 class="header-title mt-0 mb-4 d-flex align-items-center">
            {{ $t('productFeeds') }}
            <!-- <ButtonIcon class="ml-auto" variant="success" @click="createFeed()">
                {{ $t('addNew') }}
            </ButtonIcon> -->
        </h4>

        <div class="overflow-auto">
            <b-table
                :items="items"
                :fields="fields"
                :busy="isLoading && !items.length"
                small
                show-empty
                :empty-text="$t('noResult')"
                class="mb-0 border-bottom"
            >
                <template #table-busy>
                    <div class="d-flex justify-content-center my-2">
                        <b-spinner />
                    </div>
                </template>

                <template #cell(updatedAt)="data">
                    {{ dateUtil.formatDate(data.item.updatedAt) }}
                </template>

                <template #cell(status)="data">
                    {{ feedStatuses[data.item.status] }}
                </template>

                <template #cell(action)="data">
                    <ButtonIcon size="sm" @click="showItem(data.item)">{{ $t('show') }}</ButtonIcon>
                </template>

            </b-table>
        </div>
    </div>
</template>



<script>
    import dateUtil from '@/utils/date';

    export default {
        name: 'AdminCampaignsFeedView',
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                dateUtil,
                feed: null,
                isAdded: false,
            };
        },
        computed: {
            fields() {
                return [
                    { key: 'name', label: this.$t('name') },
                    { key: 'format', label: this.$t('format') },
                    { key: 'parser', label: this.$t('parser') },
                    { key: 'updatedAt', label: this.$t('update') },
                    { key: 'status', label: this.$t('status') },
                    { key: 'report', label: this.$t('report') },
                    { key: 'action', label: '', tdClass: 'text-right', thClass: 'text-right' },
                ];
            },
            campaignId() {
                return this.parentComponent?.item?.id;
            },
            items() {
                return this.parentComponent?.item?.feeds || [];
            },
            error() {
                return this.$store.getters['campaigns/getError']('feeds');
            },
            isLoading() {
                return this.$store.getters['campaigns/getLoading']('feeds');
            },
            feedStatuses() {
                return this.$store.getters['offers/getFeedStatuses'].reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {});
            },
        },
        methods: {
            fetchFeedStatuses() {
                this.$store.dispatch('offers/fetchFeedStatuses').catch(() => {});
            },
            createFeed() {
                this.$router.push({ name: 'CampaignsDetailsTabCreate', params: this.$route.params, query: this.$route.query });
            },
            showItem(feed) {
                const { id, sep, slug, tab } = this.$route.params;
                this.$router.push({ name: 'CampaignsDetailsTabEdit', params: { id, sep, slug, tab, itemId: feed.id } });
            },
        },
        created() {
            this.fetchFeedStatuses();
        },
    };
</script>



<style lang="scss" scoped></style>
