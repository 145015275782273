import camelCase from 'camelcase';
import apiService, { deleteFromCache } from './apiService';
import globalData from '@/store/globalData';
import { EndpointParamsError } from '@/services/errorService';

export const parseStringParams = function parseStringParams(string, params = {}, throwError = true) {
    if (typeof string !== 'string') return '';
    let parsedString = string;
    const regex = /{{(.+?)}}/g;
    let match;
    let errorParams = null;

    // eslint-disable-next-line no-cond-assign
    while ((match = regex.exec(string)) !== null) {
        const isRequiredParam = !match[1].includes('?');
        const paramString = match[0];
        const paramKey = match[1].replace('?', '');
        parsedString = parsedString.replace(paramString, params[paramKey] ?? '');

        /* Error handling */
        if (isRequiredParam && !(typeof params[paramKey] === 'string' || typeof params[paramKey] === 'number')) {
            if (!errorParams) errorParams = [];
            errorParams.push({
                key: paramKey,
                value: params[paramKey],
                endpoint: string,
                parsed: parsedString,
            });
        }
    }

    if (throwError && errorParams) throw new EndpointParamsError(errorParams);

    return parsedString.replace(/\/$/, '');
};

export default {
    build(endpoints) {
        const fns = {};

        Object.entries(endpoints).forEach(([name, { methods, formatter, defaultParams, cache = true, cacheToClear = [] }]) => {
            const pascalCaseName = camelCase(name, { pascalCase: true });

            methods.forEach(type => {
                fns[`${type}${pascalCaseName}`] = async ({ requestParams, urlParams = globalData.apiParams, headers, responseType, cache: useCache } = {}) => {
                    try {
                        if (type !== 'get') {
                            const endpointsUrlToRemoveFromCache = [`${endpoints[name].URI(urlParams)}$`, `${endpoints[name].URI(urlParams)}\\?`, `${endpoints[name].URI(urlParams)}/\\?`];
                            const urlsToRemoveFromCache = [...endpointsUrlToRemoveFromCache, ...cacheToClear].map(url => parseStringParams(url, urlParams));
                            deleteFromCache(urlsToRemoveFromCache);
                        }
                    } catch (error) {
                        // error
                    }
                    try {
                        const baseUrl = process.env.VUE_APP_API_URL;
                        const url = parseStringParams(endpoints[name].URI(urlParams), urlParams);
                        const params = requestParams || defaultParams?.[type];
                        const requestCache = typeof useCache === 'boolean' ? useCache : cache;
                        const response = await apiService[type](`${baseUrl}${url}`, params, headers, responseType, requestCache);
                        const data = formatter && typeof formatter === 'function' ? formatter(response?.data) : response?.data;
                        return data;
                    } catch (error) {
                        if (error instanceof EndpointParamsError) {
                            console.log(`%c${error.message.toUpperCase()}`, 'color: red; font-size: 16px');
                            console.table(error.params);
                        } else {
                            throw error;
                        }
                    }
                    return null;
                };
            });
        });

        return fns;
    },
};
