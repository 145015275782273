import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';
import { EndpointError } from '@/services/errorService';
import globalData from '@/store/globalData';

export const ENDPOINTS = deepFreeze({
    IMPRESSIONS_STATS: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/impressions-stats';
            if (params.userRole === 'advertiser') return '/api/v1/campaigns/{{campaignId}}/impressions-stats';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    CLICKS_STATS: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/clicks-stats';
            if (params.userRole === 'advertiser') return '/api/v1/campaigns/{{campaignId}}/clicks-stats';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    ORDERS_STATS: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/orders-stats';
            if (params.userRole === 'advertiser') return '/api/v1/campaigns/{{campaignId}}/orders-stats';
            if (params.userRole === 'admin' || params.userRole === 'superadmin') return '/api/v1/admin-stats/orders';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    COMMISSION_STATS: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/commission-stats';
            if (params.userRole === 'advertiser') return '/api/v1/campaigns/{{campaignId}}/reporter-commission-stats';
            if (params.userRole === 'admin' || params.userRole === 'superadmin') return '/api/v1/admin-stats/admin-commission';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    PUBLISHER_QUANTITY_STATS: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'admin' || params.userRole === 'superadmin') return '/api/v1/admin-stats/publisher-quantity';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    SPACES_QUANTITY_STATS: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'admin' || params.userRole === 'superadmin') return '/api/v1/admin-stats/spaces-quantity';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    CAMPAIGN_REPORT: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/campaign-report';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    CAMPAIGN_STATS: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/campaign-stats';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    SPACE_REPORT: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/space-report';
            if (params.userRole === 'advertiser') return '/api/v1/reporters/{{userId}}/space-report';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    SPACE_STATS: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/space-stats';
            if (params.userRole === 'advertiser') return '/api/v1/reporters/{{userId}}/space-stats';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    TOP_PRODUCTS_QUANTITY: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/top-products-quantity';
            if (params.userRole === 'advertiser') return '/api/v1/reporters/{{userId}}/top-products-quantity';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    AGGREGATION_SALES_STATS: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/aggregation-sales-stats';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    AGGREGATION_SALES_CHART: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/aggregation-sales-chart';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    AMOUNT_STATS: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'advertiser') return '/api/v1/campaigns/{{campaignId}}/amount-stats';
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/amount-stats';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    GENERAL_STATS: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'widgeter') return '/api/v1/publisher/{{userId}}/general-stats';
            if (params.userRole === 'advertiser') return '/api/v1/reporters/{{userId}}/general-stats';
            if (params.userRole === 'admin' || params.userRole === 'superadmin') return '/api/v1/general-stats';
            throw new EndpointError();
        },
        methods: ['get'],
        default: [],
    },
    ORDERS_CHART: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'admin' || params.userRole === 'superadmin') return '/api/v1/admin-stats/orders-chart';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    ADMIN_COMMISSION_CHART: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'admin' || params.userRole === 'superadmin') return '/api/v1/admin-stats/admin-commission-chart';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
    ADVERTISER_COMMISSION_CHART: {
        URI: (params = globalData.apiParams) => {
            if (params.userRole === 'admin' || params.userRole === 'superadmin') return '/api/v1/admin-stats/advertiser-commission-chart';
            throw new EndpointError();
        },
        methods: ['get'],
        default: {},
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
