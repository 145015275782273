<template>
    <b-overlay :show="loading && product" rounded="sm" class="h-100">
        <Card :header="$t('edit2')" class="m-0 shadow-none h-100">

            <ErrorMessage v-if="error && error.errorCode !== 'INVALID_REQUEST_DATA'" :value="error" />

            <b-form-group :label="$t('offerType')">
                <div class="d-flex gap-3">
                    <FormValidator :validator="$v.form.type" v-slot="slotProps">
                        <Multiselect
                            :value="filtersOptions.type.find(item => item.value === form.type)"
                            :options="filtersOptions.type"
                            select-label=""
                            selected-label=""
                            deselect-label=""
                            class="width-200 d-inline-block"
                            :disabled="isDisabled"
                            :state="slotProps.state"
                            @input="form.type = $event.value"
                        />
                    </FormValidator>
                    <FormValidator :validator="$v.form.format" v-slot="slotProps">
                        <Multiselect
                            v-if="form.type.startsWith('content')"
                            :value="filtersOptions.format.find(item => item.value === form.format)"
                            :options="filtersOptions.format"
                            select-label=""
                            selected-label=""
                            deselect-label=""
                            class="width-200 d-inline-block"
                            :disabled="isDisabled"
                            :state="slotProps.state"
                            @input="form.format = $event.value"
                        />
                    </FormValidator>
                </div>
            </b-form-group>

            <FormValidator form-group :validator="$v.form.product" :label="$t('productId')" v-slot="slotProps">
                <b-form-input v-model="form.product" :disabled="isDisabled" :state="slotProps.state" class="width-400" placeholder="35696432" />
            </FormValidator>

            <FormValidator form-group :validator="$v.form.price" :label="$t('price')" v-slot="slotProps">
                <div class="d-flex align-items-center gap-2">
                    <b-form-input v-model="form.price" :disabled="isDisabled" :state="slotProps.state" class="width-300" placeholder="28,50" /> PLN
                </div>
            </FormValidator>

            <FormValidator form-group :validator="$v.form.active" :label="$t('available2')" v-slot="slotProps">
                <b-form-radio-group v-model="form.active" :options="filtersOptions.yesno" :disabled="isDisabled" :state="slotProps.state" class="h-100 d-flex align-items-center" />
            </FormValidator>

            <FormValidator form-group :validator="$v.form.hidden" :label="$t('hidden2')" v-slot="slotProps">
                <b-form-radio-group v-model="form.hidden" :options="filtersOptions.yesno" :disabled="isDisabled" :state="slotProps.state" class="h-100 d-flex align-items-center" />
            </FormValidator>

            <b-form-group>
                <div class="d-flex gap-2">
                    <ButtonIcon :loading="isSubmitting" :disabled="isDisabled" @click="submit()">
                        {{ $t('save') }}
                    </ButtonIcon>
                    <ButtonIcon :disabled="isDisabled" variant="light" @click="resetForm()">
                        {{ $t('reset') }}
                    </ButtonIcon>
                </div>
            </b-form-group>

        </Card>
    </b-overlay>
</template>



<script>
    import { required, decimal } from 'vuelidate/lib/validators';
    import pick from 'lodash/pick';
    import Card from '@/components/common/Card.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import FormValidator from '@/components/common/FormValidator.vue';

    export default {
        name: 'Edit',
        components: {
            Card,
            Multiselect,
            FormValidator,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.id),
                form: {
                    type: null,
                    format: null,
                    product: null,
                    price: null,
                    active: null,
                    hidden: null,
                },
                isSubmitting: false,
                formValidations: {
                    price: { required, decimal },
                },
            };
        },
        validations() {
            return { form: this.formValidations };
        },
        computed: {
            isDisabled() {
                return !this.product || this.isSubmitting || this.loadingTypesAndFormats;
            },
            product() {
                return this.parentComponent.product;
            },
            loading() {
                return this.parentComponent.loading;
            },
            error() {
                return this.$store.getters['offers/getError']('offer');
            },
            loadingTypesAndFormats() {
                return this.$store.getters['offers/getLoading']('typesAndFormats');
            },
            requestParams() {
                const params = {};
                if (this.form.type) params.type = this.form.type;
                if (this.form.format) params.format = this.filtersOptions.format.find(item => item.value === this.form.format)?.id;
                if (this.form.product) params.product = this.form.product;
                if (this.form.price) params.price = this.form.price;
                if (typeof this.form.active === 'boolean') params.active = this.form.active;
                if (typeof this.form.hidden === 'boolean') params.hidden = this.form.hidden;
                return params;
            },
            urlParams() {
                return { offerId: this.id };
            },
            apiParams() {
                return {
                    requestParams: this.requestParams,
                    urlParams: this.urlParams,
                };
            },
            typesAndFormats() {
                return this.$store.getters['offers/getTypesAndFormats'];
            },
            filtersOptions() {
                return {
                    type: this.typesAndFormats.map(item => ({ value: item.value, text: item.label })),
                    format: this.typesAndFormats.find(item => item.value === this.form.type)?.formats.map(item => ({ value: item.prefix, text: item.categoryName, id: item.id })),
                    yesno: [
                        { value: true, text: this.$t('yes') },
                        { value: false, text: this.$t('no') },
                    ],
                };
            },
        },
        watch: {
            product: {
                immediate: true,
                deep: true,
                handler() {
                    this.initFormData();
                },
            },
        },
        methods: {
            fetchTypesAndFormats() {
                this.$store.dispatch('offers/fetchTypesAndFormats').catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            initFormData() {
                if (this.product) {
                    const itemPick = pick(this.product, Object.keys(this.form));
                    this.form = { ...this.form, ...itemPick };
                }
            },
            resetForm() {
                this.initFormData();
                this.$v.$reset();
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) return;
                this.isSubmitting = true;
                this.$store
                    .dispatch('offers/patchOffer', this.apiParams)
                    .then(product => {
                        this.resetForm();
                        this.$toastr.success(this.$t('dataSavedSuccessfully'));
                        this.$store.commit('offers/setOffer', product);
                    })
                    .catch(error => {
                        Object.keys(error?.error?.response?.data?.fields).forEach(key => {
                            const field = error.error.response.data.fields[key];
                            const msg = this.$t(field.message);
                            const fieldWrongValue = JSON.stringify(this.form[key]);
                            this.$set(this.formValidations, key, {
                                [msg]: val => {
                                    const fieldCurrentValue = JSON.stringify(val);
                                    const isValid = fieldCurrentValue !== fieldWrongValue;
                                    return isValid;
                                },
                            });
                            this.$v.$touch();
                        });
                    })
                    .finally(() => {
                        this.isSubmitting = false;
                    });
            },
        },
        created() {
            this.fetchTypesAndFormats();
        },
    };
</script>
