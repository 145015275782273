<template>
    <b-modal
        id="modal-donations-to-payout"
        ref="modal"
        :title="$t('paymentOfDonations')"
        @show="resetData()"
        @hidden="resetData()"
    >

        <ErrorMessage :value="error" class="mb-3" />

        <div>
            <p>{{ $t('pleaseConfirmDataCorrect') }}</p>

            <div v-if="loadingUserProfile">
                <b-skeleton animation="wave" width="20%" />
                <b-skeleton animation="wave" width="25%" />
                <b-skeleton animation="wave" width="15%" />
                <b-skeleton animation="wave" width="35%" />
            </div>

            <div v-else>
                {{ user.fullName }}<br>
                {{ user.street }} {{ user.streetNumber }}{{ user.apartmentNumber && ` / ${user.apartmentNumber}` }}<br>
                {{ user.postcode }} {{ user.city }}<br><br>
                {{ $t('bankAccount') }}:<br>
                {{ user.bankAccount }}
            </div>
        </div>

        <template #modal-footer>
            <ButtonIcon :disabled="loadingUserProfile || busy" variant="outline-primary" class="width-100" :to="{ name: 'AccountSettings' }">
                {{ $t('change') }}
            </ButtonIcon>
            <ButtonIcon
                :disabled="loadingUserProfile"
                :loading="busy"
                variant="primary"
                class="width-100"
                hide-label-while-loading
                @click="payout()"
            >
                {{ $t('confirm') }}
            </ButtonIcon>
        </template>

    </b-modal>
</template>



<script>
    import tipboxService from '@/services/tipboxService';

    export default {
        name: 'ModalDonationsToPayout',
        data() {
            return {
                busy: false,
                error: null,
            };
        },
        computed: {
            user() {
                return this.$store.getters['auth/getUser'];
            },
            loadingUserProfile() {
                return this.$store.state.auth.loading.fetchUserProfile;
            },
        },
        methods: {
            resetData() {
                this.error = null;
            },
            payout() {
                this.busy = true;
                return tipboxService
                    .postPayouts()
                    .then(response => {
                        this.$toastr.success(this.$t('paymentWasAccepted'));
                        this.$root.$emit('bv::hide::modal', 'modal-donations-to-payout');
                        this.$emit('payout-success');
                    })
                    .catch(error => (this.error = error))
                    .finally(() => (this.busy = false));
            },
            fetchUserProfile() {
                this.$store.dispatch('auth/fetchUserProfile').catch(() => {});
            },
        },
        created() {
            this.fetchUserProfile();
        },
    };
</script>
