var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{scopedSlots:_vm._u([{key:"side",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('ButtonIcon',{attrs:{"disabled":_vm.pluginsLoading || !_vm.spaceId,"icon":"plus"},on:{"click":function($event){return _vm.addConfiguration()}}},[_vm._v(" "+_vm._s(_vm.$t('widgetSettings.addSettings'))+" ")])],1)]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('widgetSettings.title2'))+" ")]),_c('space-provider',[_c('SpaceSwitchCard',{on:{"spaceId":_vm.setSpaceId}}),(_vm.spaceId)?_c('card',{attrs:{"loading":_vm.pluginsLoading}},[_c('table-default',{attrs:{"items":_vm.pluginsList,"headers":[
                    {
                        label: _vm.$t('widgetSettings.name'),
                        value: function (item) { return item.name; },
                    },
                    {
                        label: _vm.$t('widgetSettings.buyboxId'),
                        value: function (item) { return item.id; },
                    },
                    {
                        label: _vm.$t('widgetSettings.actions'),
                        slot: 'actions',
                    } ]},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'WidgetSettingsEdit', params: { buyboxId: item.id } }}},[_c('button-default',[_vm._v(" "+_vm._s(_vm.$t('widgetSettings.editSettings'))+" ")])],1),_c('ButtonIcon',{staticClass:"margin-left",attrs:{"variant":"danger","icon":"trash-2"},on:{"click":function($event){return _vm.onDeletePlugin(item.id)}}})]}}],null,false,2042525495)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }