<template>
    <b-badge v-if="value === true" :variant="trueValueBadgeVariant">
        {{ trueValueText }}
    </b-badge>
    <b-badge v-else-if="value === false" :variant="falseValueBadgeVariant">
        {{ falseValueText }}
    </b-badge>
</template>



<script>
    import { i18n } from '@/i18n/i18n';

    export default {
        name: 'BadgeBoolean',
        props: {
            value: {
                type: Boolean,
                default: undefined,
            },
            trueValueText: {
                type: String,
                default: i18n.t('yes'),
            },
            falseValueText: {
                type: String,
                default: i18n.t('no'),
            },
            trueValueBadgeVariant: {
                type: String,
                default: 'success',
            },
            falseValueBadgeVariant: {
                type: String,
                default: 'danger',
            },
        },
    };
</script>
