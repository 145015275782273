<template>
    <DataTable
        v-model="selectedRows"
        :header="$t('shopsList')"
        :error="error"
        :items="items"
        :fields="fields"
        :loading="loading"
        no-sort-reset
        no-local-sorting
        :per-page.sync="parentComponent.perPage"
        :sort-by.sync="parentComponent.sortBy"
        :sort-direction.sync="parentComponent.sortDirection"
        :current-page.sync="parentComponent.currentPage"
        :total-rows="invoices.max"
        selectable
        selectable-id-key="id"
    >
        <template #cell(currentSettlement)="data">
            {{ $format.currency(data.value) }}
        </template>

        <template #cell(action)="data">
            <div class="d-flex gap-1 justify-content-end align-items-start">
                <ButtonIcon class="flex-shrink-0" size="sm" hide-label-while-loading :loading="loading" @click="createNewInvoice(data.item)">
                    {{ $t('newInvoice') }}
                </ButtonIcon>
            </div>
        </template>

        <template #afterTable>
            <div class="d-flex gap-3 align-items-center mt-4">
                <Multiselect
                    v-model="tableAction"
                    :options="tableActionOptions"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    class="width-250 d-inline-block"
                />
                <b-button variant="primary" :disabled="!tableAction || !tableAction.value || !items.length || loading || !selectedRows.length" @click="initTableAction()">
                    {{ $t('save') }}
                </b-button>
            </div>
        </template>
    </DataTable>
</template>

<script>
    import DataTable from '@/components/common/DataTable.vue';
    import Multiselect from '@/components/common/Multiselect.vue';

    export default {
        name: 'Invoices',
        components: {
            DataTable,
            Multiselect,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                tableConfig: {},
                fields: [
                    {
                        key: 'campaign.name',
                        label: this.$t('shop'),
                    },
                    {
                        key: 'advertiser.company',
                        label: this.$t('advertiser'),
                    },

                    {
                        key: 'currentSettlement',
                        label: this.$t('currentSettlement'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                        sortable: true,
                    },
                    {
                        key: 'action',
                        label: '',
                        thClass: 'text-right ',
                    },
                ],
                selectedRows: [],

                tableAction: null,
                tableActionOptions: [
                    { value: null, text: this.$t('select') },
                    { value: 'newInvoice', text: this.$t('newInvoice') },
                ],
            };
        },
        computed: {
            invoices() {
                return this.$store.getters['advertiserSettlements/getSettlements'];
            },
            items() {
                if (!this.invoices || !Array.isArray(this.invoices.items)) return [];

                return this.invoices.items.map(item => ({
                    ...item,
                    id: (item.advertiser?.id || null) + (item.campaign?.id || null),
                }));
            },
            error() {
                return this.$store.getters['advertiserSettlements/getError']('settlements');
            },
            loading() {
                return this.$store.getters['advertiserSettlements/getLoading']('settlements');
            },
        },
        methods: {
            formatCampaigns(item) {
                return item.campaign.name || '';
            },

            createNewInvoice(item) {
                this.$router.push({
                    name: 'AdvertiserSettlementsCreate',
                    params: { item },
                });
            },

            initTableAction() {
                if (!this.selectedRows.length) return;

                if (this.tableAction.value === 'newInvoice') {
                    // Extract selected items (whole objects) based on the IDs in selectedRows
                    const selectedItems = this.items.filter(item => this.selectedRows.includes(item.id));
                    this.$router.push({
                        name: 'AdvertiserSettlementsCreate',
                        params: { items: selectedItems },
                    });
                }
            },
        },
    };
</script>
