<template>
    <div class="tooltip-wrapper">
        <slot></slot>
        <template v-if="text">
            <feather
                :id="uuid"
                type="help-circle"
                v-bind="$attrs"
                :size="$attrs.size || 16"
                :class="{'ml-1': $slots.default}"
                class="flex-shrink-0"
            />
            <b-tooltip :target="uuid" :boundary="boundary || 'body'" placement="right" :fallback-placement="['bottom']" :custom-class="`tooltip-width-${width}`">
                {{ text }}
            </b-tooltip>
        </template>
    </div>
</template>



<script>
    import { v4 as uuidv4 } from 'uuid';

    export default {
        name: 'Tooltip',
        props: {
            text: {
                type: String,
                required: true,
            },
            width: {
                type: Number,
                default: 300,
                validator: value => [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000].includes(value),
            },
        },
        data() {
            return {
                uuid: uuidv4(),
                boundary: document.querySelector('#wrapper .content-page .content'),
            };
        },
    };
</script>



<style lang="scss" scoped>
    .tooltip-wrapper {
        display: inline-flex;

        ::v-deep {
            i.feather {
                color: $brand;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
</style>
