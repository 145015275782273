<template>
    <li class="mb-3 d-flex">
        <strong class="d-inline-block mr-1">{{ label }}:</strong>
        <span class="d-inline-flex align-items-center width-min-150">
            <slot v-if="!isLoading" name="default"></slot>
            <b-skeleton v-if="isLoading" animation="wave" :width="width" class="mb-0 d-inline-block" />
        </span>
    </li>
</template>



<script>
    export default {
        name: 'SkeletonItem',
        props: {
            label: {
                type: String,
                required: true,
            },
            loading: {
                type: Boolean,
                default: undefined,
            },
            width: {
                type: [Number, String],
                default: '50%',
            },
        },
        computed: {
            parentLoading() {
                if (this.$parent.$options.name === 'SkeletonList') return this.$parent.loading;
                return false;
            },
            isLoading() {
                if (this.loading !== undefined) return this.loading;
                return this.parentLoading;
            },
        },
    };
</script>
