<template>
    <div>

        <PageHeader :items="breadcrumb">
            <div class="d-flex gap-3 align-items-center">
                <div>{{ $t('productAudit') }}</div>
                <div class="d-flex gap-2 align-items-center">
                    <ButtonIcon variant="success" :to="{ name: 'ProductsItemShow', params: { id: id, parentUrl: $route.params.parentUrl } }">
                        {{ $t('show') }}
                    </ButtonIcon>
                    <ButtonIcon variant="warning" :loading="busyReAggregate" hide-label-while-loading @click="reAggregate()">
                        {{ $t('outsourceAggregation') }}
                    </ButtonIcon>
                </div>
            </div>
        </PageHeader>

        <div class="d-flex flex-column gap-3">

            <DataTable
                :header="$t('details')"
                :items="fetchProduct"
                :fields="fieldsProduct"
                :pagination="false"
                no-provider-sorting
                stacked
                :hover="false"
            >
                <template #table-header="{items}">
                    <b-alert v-if="items[0] && items[0].status === 1" show variant="light">{{ $t('productAwaitingAggregation') }}</b-alert>
                </template>
                <template #cell(status)="{value}">
                    <b-spinner v-if="!aggregationStatuses && !aggregationStatusesError" small />
                    <template v-else-if="!aggregationStatusesError">{{ aggregationStatuses[value] }}</template>
                    <template v-else>{{ value }}</template>
                </template>
                <template #cell(updatedAt)="{item}">
                    {{ $format.dateAndHour(item.updatedAt) }}
                </template>
            </DataTable>

            <DataTable
                :items="fetchOffers"
                :fields="fieldsOffers"
                :pagination="false"
                no-provider-sorting
            >
                <template #header="{items}">
                    {{ $t('offers') }} ({{ items.length }})
                </template>
            </DataTable>

        </div>

    </div>

</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';
    import { createItemKey } from '@/store/modules/moduleFactory';

    export default {
        name: 'ProductsItemAudit',
        components: {
            DataTable,
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.id),
            };
        },
        computed: {
            fieldsOffers() {
                return [
                    { key: 'ean', label: this.$t('ean') },
                    { key: 'name', label: this.$t('name') },
                    { key: 'info', label: this.$t('info') },
                ];
            },
            fieldsProduct() {
                return [
                    { key: 'status', label: this.$t('status'), tdClass: 'text-left' },
                    { key: 'updatedAt', label: this.$t('updatedAt'), tdClass: 'text-left' },
                ];
            },
            apiParams() {
                return { urlParams: { id: this.id } };
            },
            busyReAggregate() {
                return this.$store.getters['product/getBusyItem']('productReAggregate', createItemKey({ id: this.id }));
            },
            breadcrumb() {
                return [
                    { text: this.$t(this.parentRoute.meta.title), to: this.$route.params.parentUrl || this.parentRoute },
                    { text: `${this.id}` || '---', active: true },
                ];
            },
            parentRoute() {
                return this.$route.matched.slice(-2)[0];
            },
            aggregationStatuses() {
                return this.$store.getters['product/getAggregationStatuses']?.reduce((acc, item) => ({ ...acc, [item.value]: item.label }), {});
            },
            aggregationStatusesError() {
                return this.$store.getters['product/getError']('productAggregationStatuses');
            },
        },
        methods: {
            fetchAggregationStatuses() {
                return this.$store.dispatch('product/fetchAggregationStatuses').catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            fetchProduct() {
                return this.$store.dispatch('product/fetchProducts', this.apiParams).then(response => (response ? [response] : []));
            },
            fetchOffers(ctx, callback) {
                return this.$store.dispatch('product/fetchProductDistinctOffers', this.apiParams).then(response => response?.items || []);
            },
            reAggregate() {
                this.$store
                    .dispatch('product/putProductReAggregate', this.apiParams)
                    .then(() => this.$toastr.success(this.$t('reAggregateSuccessfully')))
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            getUrlOffer(item) {
                return {
                    name: 'OffersDetails',
                    params: { id: item.id },
                    query: {},
                };
            },
        },
        created() {
            this.fetchAggregationStatuses();
        },
    };
</script>
