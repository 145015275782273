<template>
    <div class="badge" :class="statusClass">
        {{ translatedStatusName }}
    </div>
</template>



<script>
    import { FinanceDocumentStatus, translateFinanceDocumentStatus } from '@/services/financeService';

    export default {
        name: 'FinanceDocumentStatusBadge',
        props: {
            status: {
                type: [String, Number],
                required: true,
            },
        },
        computed: {
            translatedStatusName() {
                return translateFinanceDocumentStatus(this.status.toString());
            },
            statusClass() {
                switch (this.status.toString()) {
                    case FinanceDocumentStatus.NEW:
                        return 'badge-soft-primary';
                    case FinanceDocumentStatus.ACCEPTED:
                        return 'badge-soft-info';
                    case FinanceDocumentStatus.REJECTED:
                        return 'badge-soft-danger';
                    case FinanceDocumentStatus.PAID:
                        return 'badge-soft-success';
                    case FinanceDocumentStatus.ABANDONED:
                        return 'badge-soft-warning';
                    default:
                        throw new Error('Unsupported transaction status');
                }
            },
        },
    };
</script>
