<template>
    <div>
        <PageHeader>
            {{ $t('api.title') }}
        </PageHeader>
        <b-overlay :show="loading" rounded="sm" class="h-100">
            <Card :header="$t('api.apiKeyFor', { space: currentCampaign.name })">
                <ErrorMessage :value="error" />

                <b-alert :show="!apiToken.id" variant="light">
                    {{ $t('api.noData') }}
                </b-alert>

                <TextField
                    v-if="apiToken.id"
                    v-model="apiToken.apiKey"
                    :label="$t('api.apiKey')"
                    disabled
                />

                <TextField
                    v-if="apiToken.id"
                    v-model="apiToken.apiSecret"
                    :label="$t('api.apiSecret')"
                    disabled
                />

                <ButtonIcon
                    class="mr-1"
                    variant="primary"
                    :disabled="isItemBusy"
                    :loading="isItemUpdated"
                    @click="putApiToken"
                >
                    {{ $t(apiToken.id ? 'api.regenerate' : 'api.generate') }}
                </ButtonIcon>

                <ButtonIcon
                    v-if="apiToken.id"
                    class="mr-1"
                    variant="danger"
                    :disabled="isItemBusy"
                    :loading="isItemDeleted"
                    @click="deleteApiToken"
                >
                    {{ $t('api.revoke') }}
                </ButtonIcon>
            </Card>
        </b-overlay>
    </div>
</template>



<script>
    import PageHeader from '@/components/common/PageHeader.vue';
    import Card from '@/components/common/Card.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import TextField from '@/components/common/TextField.vue';
    import { createItemKey } from '@/store/modules/moduleFactory';

    export default {
        name: 'Api',
        components: {
            PageHeader,
            Card,
            ErrorMessage,
            TextField,
        },
        data() {
            return {};
        },
        computed: {
            apiParams() {
                return {
                    urlParams: {
                        campaignId: this.campaignId,
                    },
                };
            },
            currentCampaign() {
                return this.$store.getters['campaigns/getCurrentCampaign'];
            },
            campaignId() {
                return this.currentCampaign?.id;
            },
            apiToken() {
                return this.$store.getters['campaignApiToken/getCampaignsApiToken'];
            },
            error() {
                return this.$store.getters['campaignApiToken/getError']('campaignsApiToken')?.message;
            },
            loading() {
                return this.$store.getters['campaignApiToken/getLoading']('campaignsApiToken');
            },
            busy() {
                return this.$store.getters['campaignApiToken/getBusy']('campaignsApiToken');
            },
            isItemBusy() {
                return this.$store.getters['campaignApiToken/getBusyItem']('campaignsApiToken', createItemKey(this.apiParams.urlParams));
            },
            isItemUpdated() {
                return this.$store.getters['campaignApiToken/getUpdatedItem']('campaignsApiToken', createItemKey(this.apiParams.urlParams));
            },
            isItemDeleted() {
                return this.$store.getters['campaignApiToken/getDeletedItem']('campaignsApiToken', createItemKey(this.apiParams.urlParams));
            },
        },
        watch: {
            campaignId: {
                handler() {
                    if (this.campaignId) this.getApiToken();
                },
                immediate: true,
            },
        },
        methods: {
            getApiToken() {
                this.$store.dispatch('campaignApiToken/fetchCampaignsApiToken', this.apiParams).catch(() => {});
            },
            putApiToken() {
                this.$store.dispatch('campaignApiToken/putCampaignsApiToken', this.apiParams).then(data => this.$store.commit('campaignApiToken/setCampaignsApiToken', data));
            },
            deleteApiToken() {
                this.$store.dispatch('campaignApiToken/deleteCampaignsApiToken', this.apiParams).then(data => this.$store.commit('campaignApiToken/setCampaignsApiToken', {}));
            },
        },
    };
</script>
