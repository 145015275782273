<template>
    <div class="badge" :class="statusClass">
        {{ translatedStatusName }}
    </div>
</template>

<script>
    import { CampaignStatus, translateCampaignStatus } from '@/services/spaceService';

    export default {
        name: 'StatusBadge',
        props: {
            status: {
                type: String,
                required: true,
            },
        },
        computed: {
            translatedStatusName() {
                return translateCampaignStatus(this.status);
            },
            statusClass() {
                switch (this.status) {
                    case CampaignStatus.WAITING:
                        return 'badge-soft-info';
                    case CampaignStatus.ACCEPTED:
                        return 'badge-soft-success';
                    case CampaignStatus.REJECTED:
                        return 'badge-soft-danger';
                    case CampaignStatus.DISCONNECTED:
                        return 'badge-soft-warning';
                    default:
                        throw new Error('Unsupported campaign status');
                }
            },
        },
    };
</script>

<style lang="scss" scoped></style>
