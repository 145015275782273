<template>
    <div>
        <PageHeader>{{ $t('transactions.list') }}</PageHeader>
        <Filters ref="Filters" />
        <Results ref="Results" />
        <ItemPreview ref="ItemPreview" />
    </div>
</template>



<script>
    import { format as dateFormat, addDays } from 'date-fns';
    import viewFiltersMixin from '@/mixins/viewFiltersMixin';
    import Filters from './parts/Filters.vue';
    import Results from './parts/Results.vue';
    import ItemPreview from './parts/ItemPreview.vue';

    const DATE_FORMAT = 'yyyy-MM-dd';
    const DEFAULT_FILTERS_PARAMS = Object.freeze({
        campaigns: null,
        spaces: null,
        from: dateFormat(addDays(Date.now(), -30), DATE_FORMAT),
        to: dateFormat(Date.now(), DATE_FORMAT),
        statuses: [],
        financeDocument: null,
        transId: null,
        id: null,
    });

    export default {
        name: 'ReportsTransactions',
        mixins: [viewFiltersMixin],
        components: {
            Filters,
            Results,
            ItemPreview,
        },
        data() {
            return {
                ...this.initParams(DEFAULT_FILTERS_PARAMS),
                request: this.createRequest('transactions/userTransactions'),
                modalItemName: 'admin-reports-transactions-item',
            };
        },
    };
</script>
