<template>
    <b-overlay :show="loading" rounded="sm" class="h-100">
        <div class="card mb-0 h-100">
            <div class="card-body">
                <div class="d-flex flex-wrap" :class="{'flex-column': navTabs}">
                    <h5 class="card-title header-title flex-grow-10 mb-3">
                        {{ title }}
                    </h5>

                    <ul v-if="navTabs || Object.keys(data).length > 1" class="nav card-nav mb-3" :class="{'nav-tabs': navTabs}">
                        <li v-for="chartType in Object.keys(data)" :key="chartType" class="nav-item">
                            <a class="nav-link" :class="{'active': chartType === currentChart}" href="javascript: void(0);" @click="setCurrentChart(chartType)">
                                <h6 class="fs-14" :class="{'text-muted': chartType !== currentChart}">{{ data[chartType].name }}</h6>
                                <h3 v-if="navTabs">{{ data[chartType].value }}</h3>
                            </a>
                        </li>
                    </ul>
                </div>

                <Chart
                    ref="chart"
                    :type="type"
                    :height="height"
                    :series="chartSeries"
                    :options="chartOptions"
                    :loading="loading"
                    :range="range"
                />
            </div>
        </div>
    </b-overlay>
</template>



<script>
    import merge from 'lodash/merge';
    import Chart from '@/components/common/Chart.vue';

    export default {
        name: 'MultiChartCard',
        components: {
            Chart,
        },
        props: {
            data: {
                type: Object,
                required: true,
            },
            options: {
                type: Object,
                default: () => {},
            },
            height: {
                type: [Number, String],
                default: 276,
            },
            type: {
                type: String,
                default: '',
            },
            loading: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                default: '',
            },
            navTabs: {
                type: Boolean,
                default: false,
            },
            range: {
                type: String,
            },
        },
        data() {
            return {
                currentChart: Object.keys(this.data)?.[0],
            };
        },
        computed: {
            chartSeries() {
                return this.data[this.currentChart]?.series || [];
            },
            currentChartOptions() {
                return this.data[this.currentChart]?.options || {};
            },
            chartOptions() {
                const baseOptions = {
                    yaxis: {
                        title: {
                            text: this.data[this.currentChart]?.name,
                            style: {
                                fontWeight: 300,
                            },
                        },
                    },
                };
                return merge({}, baseOptions, this.options, this.currentChartOptions);
            },
        },
        watch: {
            currentChart() {
                this.$emit('currentChart', this.currentChart);
            },
        },
        methods: {
            setCurrentChart(type) {
                this.currentChart = type;
            },
        },
    };
</script>
