<template>
    <div>
        <ErrorMessage v-if="error" :value="error" />

        <div v-for="(commission, index) in commissions" :key="index">
            <b-row class="mt-4">
                <b-col>
                    <template v-if="!commission.id">
                        <div class="d-flex align-items-center">
                            <strong class="mr-2">{{ index + 1 }}.</strong>
                            <b-form-input v-model="commission.description" />
                        </div>
                    </template>
                    <template v-else>
                        <strong>{{ index + 1 }}. {{ commission.description }} </strong>
                    </template>
                </b-col>
            </b-row>

            <b-row class="mt-2">
                <b-col sm="12" md="8">
                    <template v-if="commission.type == 'amount'">
                        <b-row>
                            <b-col class="mr-4">
                                <b-form-group :label="$t('publisherCommission')">
                                    <b-input-group>
                                        <b-form-input
                                            v-model="commission.publisherAmount"
                                            type="number"
                                            min="0"
                                            placeholder="0"
                                        />
                                        <b-input-group-append>
                                            <span class="input-group-text">zł</span>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <b-col>
                                <b-form-group :label="$t('adminCommission')">
                                    <b-input-group>
                                        <b-form-input
                                            v-model="commission.adminAmount"
                                            type="number"
                                            min="0"
                                            placeholder="0"
                                        />
                                        <b-input-group-append>
                                            <span class="input-group-text">zł</span>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </template>
                    <template v-if="commission.type == 'percent'">
                        <b-row>
                            <b-col class="mr-4">
                                <b-form-group :label="$t('publisherCommission')">
                                    <b-input-group>
                                        <b-form-input
                                            v-model="commission.publisherPercent"
                                            type="number"
                                            min="0"
                                            max="100"
                                            placeholder="0"
                                        />
                                        <b-input-group-append>
                                            <span class="input-group-text">%</span>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group :label="$t('adminCommission')">
                                    <b-input-group>
                                        <b-form-input
                                            v-model="commission.adminPercent"
                                            type="number"
                                            min="0"
                                            max="100"
                                            placeholder="0"
                                        />
                                        <b-input-group-append>
                                            <span class="input-group-text">%</span>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </template>
                </b-col>

                <b-col class="d-flex flex-column justify-content-end">
                    <b-row class="d-flex justify-content-end">
                        <ButtonIcon
                            v-if="commission.id"
                            class="button-width"
                            size="sm"
                            variant="danger "
                            :disabled="loading"
                            @click="removeCommissions(commission, index)"
                        >
                            {{ $t('remove') }}
                        </ButtonIcon>

                        <ButtonIcon
                            v-else
                            size="sm"
                            class="button-width"
                            variant="danger"
                            :disabled="loading"
                            @click="cancelCommission(index)"
                        >
                            {{ $t('cancel') }}
                        </ButtonIcon>
                    </b-row>
                    <b-row class=" mt-1 d-flex justify-content-end">
                        <ButtonIcon
                            v-if="commission.id"
                            class="button-width"
                            size="sm"
                            variant="success"
                            :disabled="loading"
                            @click="updateCommissions(commission, index)"
                        >
                            {{ $t('save') }}
                        </ButtonIcon>
                        <ButtonIcon
                            v-else
                            size="sm"
                            class="button-width"
                            variant="success"
                            :disabled="loading"
                            @click="createCommissions(commission, index)"
                        >
                            {{ $t('save') }}
                        </ButtonIcon>
                    </b-row>
                </b-col>
            </b-row>

            <b-row v-if="commission.id">
                <b-col>
                    <p>{{ $t('commissionId') }} <strong class="ml-2">{{ commission.id }}</strong></p>
                </b-col>
            </b-row>

            <div class="divider"></div>
        </div>



        <b-row class="mt-4">
            <b-col class="d-flex flex-column flex-md-row gap-3">
                <ButtonIcon size="sm" class="sm-w-100" @click="addPercentCommission">
                    {{ $t('addPercentCommission') }}
                </ButtonIcon>

                <ButtonIcon size="sm" class="sm-w-100" @click="addAmountCommission">
                    {{ $t('addAmountCommission') }}
                </ButtonIcon>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import campaignsService from '@/services/campaignsService';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'Commissions',
        components: {
            ErrorMessage,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.id),
                loading: false,
                commissions: [],
                error: null,
            };
        },
        watch: {
            commissions() {
                this.$set(this.parentComponent.item, 'commissions', this.commissions);
            },
        },
        methods: {
            loadCommissions() {
                this.$store.commit('campaigns/setLoading', { key: 'campaignsCommissions', value: true });
                this.loading = true;
                this.error = null;

                campaignsService
                    .getCampaignsCommissions({ urlParams: { id: this.id } })
                    .then(data => (this.commissions = data.items))
                    .catch(error => {
                        this.error = error;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.$store.commit('campaigns/setLoading', { key: 'campaignsCommissions', value: false });
                    });
            },
            createCommissions(commission, index) {
                this.loading = true;
                this.error = null;

                const requestParams = {
                    publisherPercent: commission.publisherPercent,
                    adminPercent: commission.adminPercent,
                    publisherAmount: commission.publisherAmount,
                    adminAmount: commission.adminAmount,
                    description: commission.description,
                };
                campaignsService
                    .postCampaignsCommissions({ urlParams: { id: this.id }, requestParams })
                    .then(data => {
                        this.$set(this.commissions, index, data);
                        this.$toastr.success(this.$t('dataSavedSuccessfully'));
                    })
                    .catch(error => {
                        this.error = error;
                    })
                    .then(() => (this.loading = false));
            },

            updateCommissions(commission, index) {
                this.loading = true;
                this.error = null;

                const requestParams = {
                    publisherPercent: commission.publisherPercent,
                    adminPercent: commission.adminPercent,
                    publisherAmount: commission.publisherAmount,
                    adminAmount: commission.adminAmount,
                    description: commission.description,
                };
                campaignsService
                    .patchCommissions({ urlParams: { id: commission.id }, requestParams })
                    .then(data => {
                        this.$set(this.commissions, index, data);
                        this.$toastr.success(this.$t('dataSavedSuccessfully'));
                    })
                    .catch(error => {
                        this.error = error;
                    })
                    .then(() => (this.loading = false));
            },

            cancelCommission(index) {
                this.$delete(this.commissions, index);
            },

            removeCommissions(commission, index) {
                this.loading = true;
                this.error = null;

                campaignsService
                    .deleteCommissions({ urlParams: { id: commission.id } })
                    .then(data => {
                        this.$delete(this.commissions, index);
                        this.$toastr.success(this.$t('dataSavedSuccessfully'));
                    })
                    .catch(error => {
                        this.error = error;
                    })
                    .then(() => (this.loading = false));
            },

            addPercentCommission() {
                this.commissions.push({ type: 'percent', publisherPercent: 0, adminPercent: 0, description: null });
            },

            addAmountCommission() {
                this.commissions.push({ type: 'amount', publisherAmount: 0, adminAmount: 0, description: null });
            },
        },
        created() {
            this.loadCommissions();
        },
    };
</script>

<style scoped>
    .divider {
        height: 1px;
        width: 100%;
        margin-top: 2rem;
        background-color: #edeff4;
    }

    .button-width {
        width: 10rem;
    }
</style>
