<template>
    <div>
        <page-header>
            {{ $t('paymentGeneratorCompany.title') }}
        </page-header>
        <space-provider>
            <card :loading="isDataLoading">
                <error-message v-model="error" class="mx-3" />
                <b-alert :show="!isPaymentAvailable && !error" variant="info">
                    <template v-if="isToPaidAvailable">
                        {{ $t('paymentGeneratorCompany.payoutThresholdNotReached') }}
                    </template>
                    <template v-else>
                        {{ $t('paymentGeneratorCompany.unavailable') }}
                    </template>
                </b-alert>
                <div v-if="isPaymentAvailable && !error">
                    <form novalidate @submit.prevent="handleSubmit">
                        <h3>{{ $t('paymentGeneratorCompany.prepareTitle') }}</h3>
                        <p>{{ $t('paymentGeneratorCompany.prepareDescription') }}</p>
                        <h5 class="mt-4">
                            {{ $t('paymentGeneratorCompany.recipient') }}
                        </h5>
                        <p>
                            {{ company.companyName }}<br>
                            {{ company.street }}<br>
                            {{ company.postcode }} {{ company.city }}<br>
                            {{ $t('accountSettingsAddress.companyNip') }}: {{ company.companyNip }}<br>
                            {{ countryName }}<br>
                        </p>
                        <h5>{{ $t('paymentGeneratorCompany.issueDate') }}</h5>
                        <p>{{ $format.date(issueDate) }}</p>
                        <h5>{{ $t('paymentGeneratorCompany.dueDate') }}</h5>
                        <p>{{ $t('paymentGeneratorCompany.dueInDays') }} ({{ $format.date(dueDate) }})</p>
                        <h5>{{ $t('paymentGeneratorCompany.netPrice') }}</h5>
                        <p>{{ commissionsFormatted.toPaidFormatted }}</p>
                        <h5>{{ $t('paymentGeneratorCompany.comments') }}</h5>
                        <p>{{ $t('paymentGeneratorCompany.commentContent') }}</p>

                        <h3 class="mt-5">
                            {{ $t('paymentGeneratorCompany.sendTitle') }}
                        </h3>
                        <text-field
                            v-model="invoiceNumber"
                            class="col-sm-12 col-xl-8"
                            :validation="$v.invoiceNumber"
                            :label="$t('paymentGeneratorCompany.invoiceNumber')"
                            :maxlength="255"
                            row
                        />
                        <file-field
                            v-model="invoicePdf"
                            class="col-sm-12 col-xl-8"
                            :validation="$v.invoicePdf"
                            :label="$t('paymentGeneratorCompany.invoicePdf')"
                            accepted-formats=".pdf"
                            row
                        />

                        <error-message
                            v-model="saveError"
                            class="mx-3"
                            :action="showAccountSettingsLink"
                            @action="$router.push({ name: 'AccountSettings' })"
                        />
                        <div class="mt-2">
                            <button-default class="mt-5" :loading="sendInvoiceLoading" type="submit">
                                {{ $t('paymentGeneratorCompany.confirm') }}
                            </button-default>
                        </div>
                    </form>
                </div>
            </card>
        </space-provider>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { required } from 'vuelidate/lib/validators';
    import { PAYOUT_THRESHOLD } from '@/services/financeService';
    import countryService from '@/services/countryService';
    import dateUtil from '@/utils/date';
    import Card from '@/components/common/Card.vue';
    import PageHeader from '@/components/common/PageHeader.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import TextField from '@/components/common/TextField.vue';
    import FileField from '@/components/common/FileField.vue';
    import SpaceProvider from '@/components/common/SpaceProvider.vue';

    export default {
        name: 'PaymentGeneratorCompany',
        components: {
            SpaceProvider,
            FileField,
            TextField,
            ButtonDefault,
            ErrorMessage,
            PageHeader,
            Card,
        },
        validations: {
            invoiceNumber: { required },
            invoicePdf: { required },
        },
        data() {
            return {
                error: null,
                saveError: null,
                invoiceNumber: '',
                invoicePdf: null,
                isDataLoading: false,
            };
        },
        computed: {
            company() {
                return this.$store.getters['options/companyData'];
            },
            isPayoutThresholdNotReached() {
                return !this.error && this.commissions && this.isToPaidAvailable && !this.isPaymentAvailable && !this.isDataLoading;
            },
            isToPaidAvailable() {
                return this.commissions?.toPaid !== null && this.commissions?.toPaid !== undefined;
            },
            isPaymentAvailable() {
                return this.commissions && this.commissions.toPaid >= PAYOUT_THRESHOLD;
            },
            commissionsFormatted() {
                if (!this.commissions) {
                    return null;
                }
                return {
                    ...this.commissions,
                    toPaidFormatted: this.$format.currency(this.commissions.toPaid),
                };
            },
            issueDate() {
                return new Date();
            },
            showAccountSettingsLink() {
                if (this.saveError && this.saveError === 'unprocessable_entity.missing_personal_data') {
                    return this.$t('accountSettings.title');
                }
                return false;
            },
            dueDate() {
                return dateUtil.addDays(this.issueDate, 21);
            },
            countryName() {
                return countryService.getCountryName(this.company.country);
            },
            ...mapState({
                user: state => state.auth.user,
                fetchUserProfileLoading: state => state.auth.loading.fetchUserProfile,
                fetchCommissionsLoading: state => state.finance.loading.fetchCommissions,
                commissions: state => state.finance.commissions,
                sendInvoiceLoading: state => state.finance.loading.sendInvoice,
            }),
        },
        watch: {
            isPayoutThresholdNotReached(val) {
                if (val === true) {
                    this.$toastr.info(this.$t('paymentGeneratorCompany.payoutThresholdNotReached'));
                    this.$router.replace({ name: 'Finance' });
                }
            },
        },
        methods: {
            ...mapActions({
                fetchCompanyData: 'options/fetchCompanyData',
            }),
            getData() {
                this.isDataLoading = true;
                this.error = null;
                Promise.all([this.fetchUserProfile(), this.fetchCommissions()])
                    .then(response => {
                        if (!response[0].isCompany) {
                            return Promise.reject(new Error(this.$t('paymentGeneratorCompany.companyOnly')));
                        }
                        return null;
                    })
                    .catch(e => (this.error = e.message))
                    .finally(() => (this.isDataLoading = false));
            },
            async handleSubmit() {
                try {
                    this.saveError = null;
                    this.$v.$touch();
                    if (this.$v.$invalid) {
                        return;
                    }
                    await this.sendInvoice({
                        invoiceNumber: this.invoiceNumber,
                        invoicePdf: this.invoicePdf,
                    });
                    this.$router.push({ name: 'Finance' });
                } catch (e) {
                    this.saveError = e.message;
                }
            },
            ...mapActions({
                fetchUserProfile: 'auth/fetchUserProfile',
                fetchCommissions: 'finance/fetchCommissions',
                sendInvoice: 'finance/sendInvoice',
            }),
        },
        created() {
            this.getData();
            this.fetchCompanyData();
        },
    };
</script>

<style lang="scss" scoped></style>
