<script>
    export default {
        name: 'TransitionExpand',
        functional: true,
        render(createElement, context) {
            const data = {
                props: {
                    name: 'expand',
                },
                on: {
                    beforeEnter(element) {
                        if (context.listeners.teBeforeEnter) context.listeners.teBeforeEnter(element);
                    },
                    enter(element) {
                        if (context.listeners.teEnter) context.listeners.teEnter(element);
                        const { width } = getComputedStyle(element);

                        /* eslint-disable no-param-reassign */
                        element.style.width = width;
                        element.style.position = 'absolute';
                        element.style.visibility = 'hidden';
                        element.style.height = 'auto';
                        /* eslint-enable */

                        const { height } = getComputedStyle(element);

                        /* eslint-disable no-param-reassign */
                        element.style.width = null;
                        element.style.position = null;
                        element.style.visibility = null;
                        element.style.height = 0;
                        /* eslint-enable */

                        // Force repaint to make sure the
                        // animation is triggered correctly.
                        // eslint-disable-next-line no-unused-expressions
                        getComputedStyle(element).height;

                        setTimeout(() => {
                            // eslint-disable-next-line no-param-reassign
                            element.style.height = height;
                        });
                    },
                    afterEnter(element) {
                        if (context.listeners.teAfterEnter) context.listeners.teAfterEnter(element);
                        // eslint-disable-next-line no-param-reassign
                        element.style.height = 'auto';
                    },
                    enterCancelled(element) {
                        if (context.listeners.teEnterCancelled) context.listeners.teEnterCancelled(element);
                    },
                    beforeLeave(element) {
                        if (context.listeners.teBeforeLeave) context.listeners.teBeforeLeave(element);
                    },
                    leave(element) {
                        if (context.listeners.teLeave) context.listeners.teLeave(element);
                        const { height } = getComputedStyle(element);

                        // eslint-disable-next-line no-param-reassign
                        element.style.height = height;

                        // Force repaint to make sure the
                        // animation is triggered correctly.
                        // eslint-disable-next-line no-unused-expressions
                        getComputedStyle(element).height;

                        setTimeout(() => {
                            // eslint-disable-next-line no-param-reassign
                            element.style.height = 0;
                        });
                    },
                    afterLeave(element) {
                        if (context.listeners.teAfterLeave) context.listeners.teAfterLeave(element);
                    },
                    leaveCancelled(element) {
                        if (context.listeners.teLeaveCancelled) context.listeners.teLeaveCancelled(element);
                    },
                },
            };

            return createElement('transition', data, context.children);
        },
    };
</script>

<style scoped>
    * {
        will-change: height;
        transform: translateZ(0);
        backface-visibility: hidden;
        perspective: 1000px;
    }
</style>

<style>
    .expand-enter-active,
    .expand-leave-active {
        transition: height 0.3s ease;
        overflow: hidden;
    }

    .expand-enter,
    .expand-leave-to {
        height: 0;
    }
</style>
