<template>
    <b-badge :variant="getBadgeVariant(role)" class="soft">
        {{ roleTranslate(role) }}
    </b-badge>
</template>



<script>
    import Role, { roleTranslate } from '@/Role';

    export default {
        name: 'BadgeRole',
        props: {
            role: {
                type: String,
                default: null,
                validator(val) {
                    return Object.values(Role).includes(val);
                },
            },
        },
        data() {
            return {
                roleTranslate,
            };
        },
        methods: {
            getBadgeVariant(role) {
                if (role === Role.WIDGETER) return 'primary';
                if (role === Role.ADVERTISER) return 'danger';
                if (role === Role.PRODUCER) return 'success';
                return 'secondary';
            },
        },
    };
</script>
