<template>
    <DataTable
        :header="$t('userList')"
        :error="error"
        :items="items"
        :fields="fields"
        :total-rows="users.max"
        :per-page.sync="parentComponent.perPage"
        :sort-by.sync="parentComponent.sortBy"
        :sort-direction.sync="parentComponent.sortDirection"
        :current-page.sync="parentComponent.currentPage"
        :loading="loading"
        no-sort-reset
        no-local-sorting
    >
        <template #table-busy>
            <div class="d-flex justify-content-center my-2">
                <b-spinner />
            </div>
        </template>

        <template #head(profile)>
            {{ $t('firstNameAndLastName') }} <br> {{ $t('company') }}
        </template>

        <template #cell(createdAt)="data">
            {{ $format.dateAndHour(data.item.createdAt, 'yyyy-MM-dd') }}
        </template>

        <template #cell(lastName)="data">
            {{ data.item.profile.firstName }} {{ data.item.profile.lastName }} <br>
            {{ data.item.profile.companyName }}
        </template>

        <template #cell(role)="data">
            <BadgeRole :role="data.item.role" />
        </template>

        <template #cell(action)="data">
            <div class="d-flex gap-1 justify-content-end align-items-start">
                <ButtonIcon
                    class="flex-shrink-0"
                    size="sm"
                    variant="warning"
                    hide-label-while-loading
                    :disabled="isItemBusy(data.item.id)"
                    :loading="isItemUpdated(data.item.id)"
                    :to="createItemUrl(data.item)"
                >
                    {{ $t('details') }}
                </ButtonIcon>
                <ButtonIcon
                    class="flex-shrink-0"
                    size="sm"
                    variant="secondary"
                    hide-label-while-loading
                    :disabled="isItemBusy(data.item.id)"
                    :loading="isTokenLoading(data.item.id)"
                    @click="loginAs(data.item)"
                >
                    {{ $t('go2') }}
                </ButtonIcon>
            </div>
        </template>
    </DataTable>
</template>



<script>
    import slugify from 'slugify';
    import DataTable from '@/components/common/DataTable.vue';
    import BadgeRole from '@/components/common/BadgeRole.vue';
    import { createItemKey } from '@/store/modules/moduleFactory';
    import storageKeys from '@/Storage';

    export default {
        name: 'Results',
        components: {
            DataTable,
            BadgeRole,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                fields: [
                    {
                        key: 'id',
                        label: this.$t('id'),
                        sortable: true,
                    },
                    {
                        key: 'createdAt',
                        label: this.$t('dateAdded'),
                        sortable: true,
                    },
                    {
                        key: 'username',
                        label: this.$t('login2'),
                    },
                    {
                        key: 'email',
                        label: this.$t('email'),
                        sortable: true,
                    },
                    {
                        key: 'lastName',
                        label: this.$t('name'),
                        sortable: true,
                    },
                    {
                        key: 'role',
                        label: this.$t('role2'),
                    },
                    {
                        key: 'action',
                        label: '',
                        thClass: 'text-right ',
                    },
                ],
            };
        },
        computed: {
            users() {
                return this.$store.getters['user/getUsers'] || {};
            },
            items() {
                return this.users.items || [];
            },
            error() {
                return this.$store.getters['user/getError']('users');
            },
            loading() {
                return this.$store.getters['user/getLoading']('users');
            },
            isTokenLoading() {
                return id => this.$store.getters['user/getBusyItem']('userToken', createItemKey({ id }));
            },
            isItemBusy() {
                return id => {
                    const users = this.$store.getters['user/getBusyItem']('users', createItemKey({ id }));
                    const publishers = this.$store.getters['user/getBusyItem']('publishers', createItemKey({ id }));
                    const producers = this.$store.getters['user/getBusyItem']('producers', createItemKey({ id }));
                    const reporters = this.$store.getters['user/getBusyItem']('reporters', createItemKey({ id }));
                    return users || publishers || producers || reporters;
                };
            },
            isItemUpdated() {
                return id => {
                    const users = this.$store.getters['user/getUpdatedItem']('users', createItemKey({ id }));
                    const publishers = this.$store.getters['user/getUpdatedItem']('publishers', createItemKey({ id }));
                    const producers = this.$store.getters['user/getUpdatedItem']('producers', createItemKey({ id }));
                    const reporters = this.$store.getters['user/getUpdatedItem']('reporters', createItemKey({ id }));
                    return users || publishers || producers || reporters;
                };
            },
            user() {
                return this.$store.state.user;
            },
        },
        methods: {
            async loginAs(user) {
                return this.$store.dispatch('auth/loginAs', user).catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            createItemUrl(item) {
                const locale = window.localStorage.getItem(storageKeys.LOCALE) || 'en';
                const id = item.id;
                const slug = slugify(item.username, { replacement: '-', lower: true, locale });
                return {
                    name: 'UsersDetails',
                    params: { id, slug, sep: '-', parentUrl: this.$route.fullPath },
                    query: {},
                };
            },
        },
    };
</script>
