import axios from 'axios';
import * as Sentry from '@sentry/vue';
import qs from 'qs';
import { cacheAdapterEnhancer, Cache } from 'axios-extensions';
import { i18n } from '../i18n/i18n';
import Storage from '../Storage';
import errorService, { ApiError } from './errorService';

// axios default settings
axios.defaults.timeout = 1000 * 60 * 5; // 5 min
axios.defaults.paramsSerializer = params => qs.stringify(params);

let cancelSource = axios.CancelToken.source();
const cancelTokenRequestInterceptor = config => ({ ...config, cancelToken: cancelSource.token });

const CACHE_LIFETIME = 1000 * 60 * 60; // 60 minutes
const CACHE_CAPACITY = 1000;
const requestCache = new Cache({ ttl: CACHE_LIFETIME, max: CACHE_CAPACITY });

const axiosInstance = axios.create({
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, { defaultCache: requestCache }),
    cache: requestCache,
});

axiosInstance.interceptors.request.use(cancelTokenRequestInterceptor);

export const deleteFromCache = url => {
    const keys = axiosInstance.defaults.cache.keyList.filter(key => key && [url].flat().some(u => !!key.match(new RegExp(u))));
    keys.forEach(key => axiosInstance.defaults.cache.delete(key));
};

export default {
    abortApiRequests() {
        cancelSource.cancel('Abort request!');
        cancelSource = axios.CancelToken.source();
        axiosInstance.interceptors.request.use(cancelTokenRequestInterceptor);
    },
    async handleError(e) {
        if (axios.isCancel(e)) return;

        if (e.response?.status >= 500) {
            Sentry.captureException(e);
        }

        if (e.response && e.response.status && e.response.status === 401) {
            if (e.response.config.url.includes('login-as')) {
                throw new Error(i18n.t('error.unauthorized'));
            } else {
                if (!window.IS_TEST) {  // eslint-disable-line
                    const store = await (async () => await (() => import(/* @vite-ignore */ '../store/store').then(data => data.default).catch(() => {}))())(); // eslint-disable-line
                    if (store.getters['auth/isAuthenticated']) store.dispatch('auth/logout');
                }
            }
        }

        const formattedError = errorService.getFormattedError(e);
        throw new ApiError('ApiError', formattedError.statusCode, formattedError.message, formattedError.errorCode, formattedError.error);
    },

    authHeader(paramHeaders = {}) {
        const headers = { ...(paramHeaders || {}) };

        if (paramHeaders?.withAuth !== false) {
            const accessToken = window.localStorage.getItem(Storage.ACCESS_TOKEN);

            if (accessToken) {
                headers.Authorization = `Bearer ${accessToken}`;
            }
        }

        delete headers.withAuth;

        return headers;
    },

    async get(url, data, headerOptions, responseType, cache) {
        try {
            return await axiosInstance.get(url, {
                params: data,
                headers: this.authHeader(headerOptions),
                responseType,
                cache,
            });
        } catch (e) {
            return this.handleError(e);
        }
    },

    async post(url, data, headerOptions) {
        try {
            return await axiosInstance.post(url, data, { headers: this.authHeader(headerOptions) });
        } catch (e) {
            return this.handleError(e);
        }
    },

    async put(url, data) {
        try {
            return await axiosInstance.put(url, data, { headers: this.authHeader() });
        } catch (e) {
            return this.handleError(e);
        }
    },

    async patch(url, data) {
        try {
            return await axiosInstance.patch(url, data, { headers: this.authHeader() });
        } catch (e) {
            return this.handleError(e);
        }
    },

    async delete(url, data) {
        try {
            return await axiosInstance.delete(url, {
                data,
                headers: this.authHeader(),
            });
        } catch (e) {
            return this.handleError(e);
        }
    },
};
