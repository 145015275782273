<template>
    <div id="wrapper">
        <div class="navbar navbar-expand navbar-custom">
            <div class="container-fluid">
                <AppLogo />
            </div>
        </div>
        <div class="content-page ml-0">
            <div class="container p-3">
                <b-row>
                    <b-col cols="12" md="6" offset-md="3">
                        <Card :header="$t('login.title')" class="m-0">
                            <b-form class="d-flex flex-column gap-3" @submit.prevent>
                                <FormValidator :validator="$v.form.email" v-slot="slotProps">
                                    <b-form-input
                                        v-model="form.email"
                                        :placeholder="$t('emailAddress').toLowerCase()"
                                        :state="slotProps.state"
                                        :disabled="submitting"
                                        @keyup.enter="handleSubmit()"
                                    />
                                </FormValidator>
                                <FormValidator :validator="$v.form.password" v-slot="slotProps">
                                    <b-form-input
                                        v-model="form.password"
                                        :placeholder="$t('password').toLowerCase()"
                                        type="password"
                                        :state="slotProps.state"
                                        :disabled="submitting"
                                        @keyup.enter="handleSubmit()"
                                    />
                                </FormValidator>
                                <ButtonIcon
                                    :loading="submitting"
                                    :flip-icon="true"
                                    variant="brand"
                                    size="lg"
                                    class="btn-login"
                                    block
                                    @click="handleSubmit()"
                                >
                                    <template #default>
                                        {{ $t('auth.logIn') }}
                                    </template>
                                    <template #icon>
                                        <img src="@/assets/arrow-button.svg" alt="" width="45px" class="ml-2">
                                    </template>
                                </ButtonIcon>
                            </b-form>
                        </Card>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>



<script>
    import { required } from 'vuelidate/lib/validators';
    import AppLogo from '../../../components/AppLogo.vue';
    import AuthMixin from '../../common/auth/auth-mixin.vue';

    export default {
        name: 'LoginAdmin',
        mixins: [AuthMixin],
        components: {
            AppLogo,
        },
        data() {
            return {
                form: {
                    email: '',
                    password: '',
                },
            };
        },
        validations: {
            form: {
                email: { required },
                password: { required },
            },
        },
        computed: {
            userRoleShort() {
                return this.$store.getters['auth/getRoleShortName'];
            },
        },
        methods: {
            resetError() {
                this.error = null;
            },
            setError(error) {
                this.error = error;
            },
            formRequest() {
                return this.$store.dispatch('auth/authenticate', { username: this.form.email, password: this.form.password });
            },
            requestSuccess() {
                this.$router.push({ name: 'Dashboard', params: { role: this.userRoleShort } }).catch(() => {});
            },
        },
    };
</script>
