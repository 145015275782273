import camelCase from 'camelcase';

export const createItemKey = params => (typeof params === 'string' ? params : params ? Object.entries(params).toString() : null); // eslint-disable-line

function buildState(entities) {
    const state = {
        loading: {},
        busy: {},
        errors: {},
        busyItem: {},
        deletedItem: {},
        updatedItem: {},
    };

    Object.keys(entities).forEach(name => {
        const camelCaseName = camelCase(name);
        state[camelCaseName] = null;
        state.loading[camelCaseName] = false;
        state.busy[camelCaseName] = false;
        state.errors[camelCaseName] = null;
        state.busyItem[camelCaseName] = {};
        state.deletedItem[camelCaseName] = {};
        state.updatedItem[camelCaseName] = {};
    });

    return state;
}

function buildGetters(entities) {
    const getters = {};

    Object.keys(entities).forEach(name => {
        const camelCaseName = camelCase(name);
        const pascalCaseName = camelCase(name, { pascalCase: true });
        const defaultValue = entities[name].default !== undefined ? entities[name].default : {};
        getters[`get${pascalCaseName}`] = state => state[camelCaseName] || defaultValue;
        getters.getRequest = state => type => {
            const camelCaseType = camelCase(type);
            const pascalCaseType = camelCase(type, { pascalCase: true });
            return {
                get loading() {
                    return state.loading[camelCaseType];
                },
                get error() {
                    return state.errors[camelCaseType];
                },
                get data() {
                    return state[camelCaseType];
                },
                request: async payload => {
                    const response = await getters.dispatch(`fetch${pascalCaseType}`, payload);
                    return {
                        response,
                        loading: state.loading[camelCaseType],
                        error: state.errors[camelCaseType],
                        data: state[camelCaseType],
                    };
                },
            };
        };
    });

    return {
        ...getters,
        getLoading: state => type => state.loading[type],
        getBusy: state => type => state.busy[type],
        getError: state => type => state.errors[type],
        getBusyItem: state => (type, params) => {
            const itemKey = createItemKey(params);
            if (itemKey) return state.busyItem[type][itemKey];
            return false;
        },
        getDeletedItem: state => (type, params) => {
            const itemKey = createItemKey(params);
            if (itemKey) return state.deletedItem[type][itemKey];
            return false;
        },
        getUpdatedItem: state => (type, params) => {
            const itemKey = createItemKey(params);
            if (itemKey) return state.updatedItem[type][itemKey];
            return false;
        },
    };
}

function buildMutations(entities) {
    const mutations = {};

    Object.keys(entities).forEach(name => {
        const camelCaseName = camelCase(name);
        const pascalCaseName = camelCase(name, { pascalCase: true });
        const defaultValue = entities[name].default !== undefined ? entities[name].default : {};
        mutations[`set${pascalCaseName}`] = (state, payload) => {
            state[camelCaseName] = payload;
        };
        mutations[`clear${pascalCaseName}`] = state => {
            state[camelCaseName] = defaultValue;
        };
    });

    return {
        ...mutations,
        setLoading(state, { key, value }) {
            state.loading[key] = !!value;
        },
        setBusy(state, { key, value }) {
            state.busy[key] = !!value;
        },
        setError(state, { key, value }) {
            state.errors[key] = value;
        },
        setBusyItem(state, { key, itemKey, value }) {
            const createdItemKey = createItemKey(itemKey);
            if (createdItemKey) {
                if (value) {
                    state.busyItem[key] = { ...state.busyItem[key], [createdItemKey]: !!value };
                } else {
                    delete state.busyItem[key][createdItemKey];
                    state.busyItem[key] = { ...state.busyItem[key] };
                }
            }
        },
        setDeletedItem(state, { key, itemKey, value }) {
            const createdItemKey = createItemKey(itemKey);
            if (createdItemKey) {
                if (value) {
                    state.deletedItem[key] = { ...state.deletedItem[key], [createdItemKey]: !!value };
                } else {
                    delete state.deletedItem[key][createdItemKey];
                    state.deletedItem[key] = { ...state.deletedItem[key] };
                }
            }
        },
        setUpdatedItem(state, { key, itemKey, value }) {
            const createdItemKey = createItemKey(itemKey);
            if (createdItemKey) {
                if (value) {
                    state.updatedItem[key] = { ...state.updatedItem[key], [createdItemKey]: !!value };
                } else {
                    delete state.updatedItem[key][createdItemKey];
                    state.updatedItem[key] = { ...state.updatedItem[key] };
                }
            }
        },
    };
}

function buildActions(entities, service) {
    const actions = {};

    Object.entries(entities).forEach(([name, { methods }]) => {
        const camelCaseName = camelCase(name);
        const pascalCaseName = camelCase(name, { pascalCase: true });

        if (methods.includes('get')) {
            actions[`fetch${pascalCaseName}`] = async (context, payload) => {
                let data = entities[name].default !== undefined ? entities[name].default : {};
                const itemKey = createItemKey(payload?.urlParams);
                context.commit('setLoading', { key: camelCaseName, value: true });
                context.commit('setBusy', { key: camelCaseName, value: true });
                context.commit('setBusyItem', { key: camelCaseName, itemKey, value: true });
                context.commit('setError', { key: camelCaseName, value: null });
                let response = null;
                try {
                    data = await service[`get${pascalCaseName}`](payload);
                    context.commit(`set${pascalCaseName}`, data);
                    response = data;
                } catch (error) {
                    context.commit(`clear${pascalCaseName}`);
                    context.commit('setError', { key: camelCaseName, value: { ...error, message: error.message, requestType: 'get' } });
                    response = error;
                }
                context.commit('setLoading', { key: camelCaseName, value: false });
                context.commit('setBusy', { key: camelCaseName, value: false });
                context.commit('setBusyItem', { key: camelCaseName, itemKey, value: false });
                if (response instanceof Error) throw response;
                return response;
            };
        }

        if (methods.includes('put')) {
            actions[`put${pascalCaseName}`] = async (context, payload) => {
                const itemKey = createItemKey(payload?.urlParams);
                context.commit('setBusy', { key: camelCaseName, value: true });
                context.commit('setBusyItem', { key: camelCaseName, itemKey, value: true });
                context.commit('setUpdatedItem', { key: camelCaseName, itemKey, value: true });
                context.commit('setError', { key: camelCaseName, value: null });
                let response = null;
                try {
                    const data = await service[`put${pascalCaseName}`](payload);
                    response = data;
                } catch (error) {
                    context.commit('setError', { key: camelCaseName, value: { ...error, message: error.message, requestType: 'put' } });
                    response = error;
                }
                context.commit('setBusy', { key: camelCaseName, value: false });
                context.commit('setBusyItem', { key: camelCaseName, itemKey, value: false });
                context.commit('setUpdatedItem', { key: camelCaseName, itemKey, value: false });
                if (response instanceof Error) throw response;
                return response;
            };
        }

        if (methods.includes('post')) {
            actions[`post${pascalCaseName}`] = async (context, payload) => {
                const itemKey = createItemKey(payload?.urlParams);
                context.commit('setBusy', { key: camelCaseName, value: true });
                context.commit('setBusyItem', { key: camelCaseName, itemKey, value: true });
                context.commit('setError', { key: camelCaseName, value: null });
                let response = null;
                try {
                    const data = await service[`post${pascalCaseName}`](payload);
                    response = data;
                } catch (error) {
                    context.commit('setError', { key: camelCaseName, value: { ...error, message: error.message, requestType: 'post' } });
                    response = error;
                }
                context.commit('setBusy', { key: camelCaseName, value: false });
                context.commit('setBusyItem', { key: camelCaseName, itemKey, value: false });
                if (response instanceof Error) throw response;
                return response;
            };
        }

        if (methods.includes('patch')) {
            actions[`patch${pascalCaseName}`] = async (context, payload) => {
                const itemKey = createItemKey(payload?.urlParams);
                context.commit('setBusy', { key: camelCaseName, value: true });
                context.commit('setBusyItem', { key: camelCaseName, itemKey, value: true });
                context.commit('setUpdatedItem', { key: camelCaseName, itemKey, value: true });
                context.commit('setError', { key: camelCaseName, value: null });
                let response = null;
                try {
                    const data = await service[`patch${pascalCaseName}`](payload);
                    response = data;
                } catch (error) {
                    context.commit('setError', { key: camelCaseName, value: { ...error, message: error.message, requestType: 'patch' } });
                    response = error;
                }
                context.commit('setBusy', { key: camelCaseName, value: false });
                context.commit('setBusyItem', { key: camelCaseName, itemKey, value: false });
                context.commit('setUpdatedItem', { key: camelCaseName, itemKey, value: false });
                if (response instanceof Error) throw response;
                return response;
            };
        }

        if (methods.includes('delete')) {
            actions[`delete${pascalCaseName}`] = async (context, payload) => {
                const itemKey = createItemKey(payload?.urlParams);
                context.commit('setBusy', { key: camelCaseName, value: true });
                context.commit('setBusyItem', { key: camelCaseName, itemKey, value: true });
                context.commit('setDeletedItem', { key: camelCaseName, itemKey, value: true });
                context.commit('setError', { key: camelCaseName, value: null });
                let response = null;
                try {
                    const data = await service[`delete${pascalCaseName}`](payload);
                    response = data;
                } catch (error) {
                    context.commit('setError', { key: camelCaseName, value: { ...error, message: error.message, requestType: 'delete' } });
                    response = error;
                }
                context.commit('setBusy', { key: camelCaseName, value: false });
                context.commit('setBusyItem', { key: camelCaseName, itemKey, value: false });
                context.commit('setDeletedItem', { key: camelCaseName, itemKey, value: false });
                if (response instanceof Error) throw response;
                return response;
            };
        }
    });

    return actions;
}

function addResponseToGetters(getters) {
    return {
        ...getters,
        getRequest: (state, originalGetters) => type => {
            const originalGetter = originalGetters[`get${camelCase(type, { pascalCase: true })}`];
            return {
                get loading() {
                    return originalGetters.getLoading(type);
                },
                get error() {
                    return originalGetters.getError(type);
                },
                get data() {
                    return originalGetter(state);
                },
                async request(payload) {
                    const response = await originalGetters.dispatch(`fetch${camelCase(type, { pascalCase: true })}`, payload);
                    return {
                        response,
                        loading: originalGetters.getLoading(type),
                        error: originalGetters.getError(type),
                        data: originalGetter(state),
                    };
                },
            };
        },
    };
}

export default {
    build(entities, service) {
        const state = buildState(entities);
        const getters = buildGetters(entities);
        const mutations = buildMutations(entities);
        const actions = buildActions(entities, service);
        const augmentedGetters = addResponseToGetters(getters);

        return {
            state,
            getters: augmentedGetters,
            mutations,
            actions,
        };
    },
};
