<template>
    <div>
        <PageHeader>
            {{ $t('newInvoice') }}
        </PageHeader>

        <Card>
            <ErrorMessage :value="error" />

            <form @keyup.enter="createInvoice()">
                <b-form-group :label="$t('advertiser')" label-cols-sm="2">
                    <p v-if="isPreselected">{{ form.advertiser.name }} ({{ form.advertiser.company }})</p>

                    <Multiselect
                        v-else
                        :clear-on-select="true"
                        :close-on-select="true"
                        :show-no-results="true"
                        :show-no-options="true"
                        :preserve-search="true"
                        :show-checkboxes="false"
                        :multiple="false"
                        :show-selected="true"
                        :asynchronous="true"
                        :fetch-options-function="fetchAdvertisers"
                        :parse-options-function="parseAdvertisers"
                        :placeholder="$t('selectField.placeholder')"
                        track-by="id"
                        label="name"
                        :custom-label="option => `${option.profile.firstName} ${option.profile.lastName} (${option.username})`"
                        select-label=""
                        :disabled="loading"
                        @search-change="value => (advertiserSearchText = value)"
                        @input="value => (form.advertiser = value)"
                    />
                </b-form-group>

                <b-form-group :label="$t('invoiceCreatedAt')" label-cols-sm="2">
                    <FormDatepicker v-model="form.createdAt" :placeholder="$t('all')" :disabled="loading" />
                </b-form-group>

                <b-form-group :label="`${$t('invoiceDueDate')} (${$t('days')})`" label-cols-sm="2">
                    <b-form-input v-model="form.dueDateDays" :disabled="loading" :placeholder="$t('days')" type="number" />
                </b-form-group>

                <b-form-group :label="$t('invoiceNumber')" label-cols-sm="2">
                    <b-form-input v-model="form.number" />
                </b-form-group>

                <section v-if="campaigns" class="mt-10">
                    <div v-for="(item, index) in form.items" :key="index" class="row pt-3 border-t">
                        <b-form-group :label="index + 1 + '. ' + $t('invoicePositions')" class="col-5">
                            <Multiselect
                                :clear-on-select="true"
                                :close-on-select="true"
                                :show-no-results="true"
                                :show-no-options="true"
                                :show-checkboxes="false"
                                :multiple="false"
                                :show-selected="true"
                                :asynchronous="true"
                                :options="campaigns"
                                track-by="id"
                                label="name"
                                select-label=""
                                :placeholder="form.items[index].campaignValue ? form.items[index].campaignValue.name : $t('selectField.placeholder')"
                                :disabled="loading || !form.advertiser"
                                @input="value => (form.items[index].campaign = value.value)"
                            />
                        </b-form-group>

                        <b-form-group
                            v-if="form.items[index].currentSettlement"
                            :label="$t('invoiceCurrentNetAmount')"
                            class="col-2"
                        >
                            <b-input-group>
                                <b-form-input v-model="form.items[index].currentSettlement" readonly disabled />
                                <b-input-group-append>
                                    <b-input-group-text class="bg-transparent font-weight-bold">
                                        zł
                                    </b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>

                        <b-form-group :label="$t('invoiceNetAmount')" class="col-2">
                            <b-input-group>
                                <b-form-input v-model="form.items[index].settlement" />

                                <b-input-group-append>
                                    <b-input-group-text class="bg-transparent font-weight-bold">
                                        zł
                                    </b-input-group-text>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>

                        <b-form-group label="‎" class="col-1">
                            <ButtonIcon variant="danger" :loading="loading" @click="form.items.splice(index, 1)">
                                {{ $t('remove') }}
                            </ButtonIcon>
                        </b-form-group>
                    </div>

                    <ButtonIcon :loading="loading" @click="addNewItem()">
                        {{ $t('add') }}
                    </ButtonIcon>
                </section>

                <b-form-group label="PDF" label-cols-sm="2">
                    <ButtonIcon hide-label-while-loading :loading="loading" @click="triggerUpload">
                        {{ $t('selectFile') }}
                    </ButtonIcon>
                </b-form-group>

                <input ref="fileUploader" type="file" style="display: none" @change="uploadFile">

                <div class="d-flex gap-3">
                    <ButtonIcon :loading="loading" :disabled="!isFormValid" @click="createInvoice()">
                        {{ $t('save') }}
                    </ButtonIcon>
                </div>
            </form>
        </Card>
    </div>
</template>

<script>
    import { serialize as objectToFormData } from 'object-to-formdata';
    import Multiselect from '@/components/common/Multiselect.vue';
    import Role from '@/Role';
    import userService from '@/services/userService';
    import FormDatepicker from '@/components/common/FormDatepicker.vue';

    export default {
        name: 'AdvertiserSettlementsList',
        components: {
            Multiselect,
            FormDatepicker,
        },
        data() {
            return {
                advertisersSearchText: '',
                fileToUpload: null,
                isPreselected: false,
                campaigns: [],
                form: {
                    advertiser: null,
                    number: '',
                    createdAt: new Date().toISOString().substr(0, 10),
                    dueDateDays: '',
                    pdf: '',
                    items: [
                        {
                            campaign: 0,
                            settlement: 0,
                        },
                    ],
                },
            };
        },
        computed: {
            error() {
                return this.$store.getters['advertiserSettlements/getError']('newReporterInvoice') || this.$store.getters['advertiserSettlements/getError']('reporterCampaigns');
            },
            loading() {
                return this.$store.getters['advertiserSettlements/getLoading']('newReporterInvoice');
            },
            isFormValid() {
                return !!(this.form.advertiser && this.form.number && this.form.createdAt && this.form.dueDateDays);
            },
        },
        watch: {
            'form.advertiser.id': {
                async handler(value) {
                    if (value) {
                        const response = await this.fetchCampaigns();
                        this.campaigns = response.items.map(item => ({ value: item.id, name: item.name }));
                    }
                },
            },
        },

        methods: {
            fetchAdvertisers() {
                const requestParams = { search: this.advertiserSearchText, roles: [Role.ADVERTISER] };
                return userService.getUsers({ requestParams });
            },
            parseAdvertisers(data) {
                return data?.items || [];
            },
            fetchCampaigns() {
                const advertiserId = this.form.advertiser.id;

                return this.$store.dispatch('advertiserSettlements/fetchReporterCampaigns', { urlParams: { advertiserId } });
            },

            createInvoice() {
                const formData = new FormData();
                const advertiserId = this.form.advertiser.id;
                formData.append('pdf', this.fileToUpload);

                // Calculate dueDate by adding dueDateDays to createdAt
                const createdAtDate = new Date(this.form.createdAt);
                createdAtDate.setDate(createdAtDate.getDate() + Number(this.form.dueDateDays));
                const dueDate = createdAtDate.toISOString().substr(0, 10);

                this.$store
                    .dispatch('advertiserSettlements/postNewReporterInvoice', {
                        urlParams: { advertiserId },
                        requestParams: objectToFormData(
                            {
                                items: this.form.items.map(item => ({ campaign: item.campaign, settlement: item.settlement })),
                                dueDate,
                                createdAt: this.form.createdAt,
                                number: this.form.number,
                            },
                            { indices: true },
                            formData
                        ),
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(() => {
                        this.$toastr.success(this.$t('dataSavedSuccessfully'));
                        this.$router.push({ name: 'AdvertiserSettlementsList' });
                    });
            },

            triggerUpload() {
                this.$refs.fileUploader.click();
            },

            uploadFile(event) {
                this.fileToUpload = event.target.files[0];
            },

            addNewItem() {
                this.form.items.push({
                    campaign: 0,
                    settlement: 0,
                });
            },
        },
        mounted() {
            const { item, items } = this.$route.params;

            console.log(item, items);

            if (item) {
                this.form.advertiser = {
                    id: item.advertiser.id,
                    name: item.advertiser.name,
                    company: item.advertiser.company,
                };
                this.form.items[0] = {
                    campaign: item.campaign.id,
                    settlement: item.currentSettlement,
                    currentSettlement: item.currentSettlement,
                    campaignValue: { value: item.campaign.id, name: item.campaign.name },
                };

                this.isPreselected = true;
            }
            // If an array of items is passed
            if (items && items.length) {
                this.advertiserSearchText = items[0].advertiser.name;

                this.form.advertiser = {
                    id: items[0].advertiser.id,
                    name: items[0].advertiser.name,
                    company: items[0].advertiser.company,
                };

                this.form.items = items.map(selectedItem => ({
                    campaign: selectedItem.campaign.id,
                    settlement: selectedItem.currentSettlement,
                    currentSettlement: selectedItem.currentSettlement,
                    campaignValue: { value: selectedItem.campaign.id, name: selectedItem.campaign.name },
                }));

                this.isPreselected = true;
            }
        },
    };
</script>

<style scoped>
    .border-t {
        border-top: 1px solid #e2e8f0;
    }

    .mt-10 {
        margin-top: 50px;
        margin-bottom: 25px;
    }
</style>
