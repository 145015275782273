<template>
    <b-overlay :show="$store.getters['finance/getLoading']('fetchCommissions')" rounded="sm" class="h-100">
        <WidgetOverview
            class="mb-0 h-100"
            :title="$t('dashboard.yourCommission')"
            :items="items"
        >
            <ErrorMessage :value="commissionsError" />
            <template v-if="showPayoutButton" #action_commission_to_paid>
                <b-button variant="primary" :to="{ name: 'Finance' }">
                    {{ $t('dashboard.withdraw') }}
                </b-button>
            </template>
        </WidgetOverview>
    </b-overlay>
</template>



<script>
    import WidgetOverview from '@/components/common/WidgetOverview.vue';
    import { PAYOUT_THRESHOLD } from '@/services/financeService';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'WidgetCommission',
        components: {
            WidgetOverview,
            ErrorMessage,
        },
        data() {
            return {
                commissionsError: null,
            };
        },
        computed: {
            isRoleWidgeter() {
                return this.$store.getters['auth/isRoleWidgeter'];
            },
            spaceCount() {
                return this.$store.getters['space/spaceCount'];
            },
            commissions() {
                return this.$store.getters['finance/getCommissions'];
            },
            items() {
                return [
                    {
                        title: this.$t('dashboard.newCommission'),
                        value: this.$format.currency(this.commissions?.open || 0),
                    },
                    {
                        title: this.$t('dashboard.confirmedCommission'),
                        value: this.$format.currency(this.commissions?.accepted || 0),
                    },
                    {
                        title: this.$t('dashboard.toWithdraw'),
                        value: this.$format.currency(this.commissions?.toPaid || 0),
                        name: 'commission_to_paid',
                    },
                ];
            },
            showPayoutButton() {
                return this.commissions?.toPaid >= PAYOUT_THRESHOLD;
            },
        },
        methods: {
            fetchData(params = {}) {
                if (this.isRoleWidgeter && !this.spaceCount) return;
                this.$store.dispatch('finance/fetchCommissions', params).catch(error => (this.commissionsError = error?.message));
            },
        },
        created() {
            this.fetchData();
        },
    };
</script>
