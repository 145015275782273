<template>
    <div>
        <div class="d-flex">
            <ButtonIcon class="mb-2" variant="outline-primary" icon="chevron-left" @click="back()">
                {{ $t('returnToListView') }}
            </ButtonIcon>
        </div>

        <div v-if="isLoading" class="d-flex justify-content-center">
            <b-spinner />
        </div>

        <template v-else>

            <PageHeader>
                {{ $t('feed') }} {{ feed.name && `"${feed.name}"` }}
                <template #side>
                    <div class="d-flex gap-3">
                        <ButtonIcon
                            v-if="feed.active"
                            class="width-min-100"
                            variant="danger"
                            :loading="isActiveUpdated"
                            :disabled="isBusy"
                            hide-label-while-loading
                            @click="patchActiveState(false)"
                        >
                            {{ $t('deactivate') }}
                        </ButtonIcon>
                        <ButtonIcon
                            v-else
                            class="width-min-100"
                            variant="success"
                            :loading="isActiveUpdated"
                            :disabled="isBusy"
                            hide-label-while-loading
                            @click="patchActiveState(true)"
                        >
                            {{ $t('activate') }}
                        </ButtonIcon>
                        <ButtonIcon
                            class="min-w-300"
                            variant="primary"
                            hide-label-while-loading
                            :loading="isProcessUpdated"
                            :disabled="isBusy"
                            @click="reprocess()"
                        >
                            {{ $t('processAgain') }}
                        </ButtonIcon>
                    </div>
                </template>
            </PageHeader>

            <form class="d-flex flex-column gap-4">

                <div class="grid-template">

                    <div class="border"></div>

                    <div class="label">{{ $t('created2') }}</div>
                    <div class="value add-after">{{ $format.dateAndHour(feed.createdAt, 'dd-MM-yyyy', 'HH:mm:ss') }}</div>
                    <div></div>

                    <div class="label">{{ $t('updated2') }}</div>
                    <div class="value add-after">{{ $format.dateAndHour(feed.updatedAt, 'dd-MM-yyyy', 'HH:mm:ss') }}</div>
                    <div></div>

                    <div class="label">{{ $t('processStartedAt') }}</div>
                    <div class="value add-after">{{ $format.dateAndHour(feed.offerFeed.processStartedAt, 'dd-MM-yyyy', 'HH:mm:ss') }}</div>
                    <div></div>

                    <template v-if="feed.offerFeed.processEndedAt">
                        <div class="label">{{ $t('processEndedAt') }}</div>
                        <div class="value add-after">{{ $format.dateAndHour(feed.offerFeed.processEndedAt, 'dd-MM-yyyy', 'HH:mm:ss') }}</div>
                        <div></div>
                    </template>

                    <div class="label">{{ $t('report') }}</div>
                    <div class="value add-after">{{ feed.offerFeed.report }}</div>
                    <div></div>

                    <div class="label">{{ $t('processingStatus') }}</div>
                    <div class="value add-after">{{ feedStatuses[feed.offerFeed.status] }}</div>
                    <div></div>

                    <div class="label">{{ $t('feedStatus') }}</div>
                    <div class="value add-after">
                        <template v-if="feed.active">{{ $t('active') }}</template>
                        <template v-else>{{ $t('inactive') }}</template>
                    </div>
                    <div></div>

                    <div class="border"></div>

                    <div class="label">{{ $t('name') }}</div>
                    <div class="value add-after">
                        <FormValidator :validator="$v.form.name" form-group v-slot="slotProps" :attribute="$t('name')">
                            <b-form-input v-model="form.name" :disabled="isBusy" :state="slotProps.state" />
                        </FormValidator>
                    </div>
                    <div></div>

                    <div class="label">{{ $t('xmlFile') }}</div>
                    <div class="value">
                        <FormValidator :validator="$v.form.productFeedFile.xmlUrl" form-group v-slot="slotProps" :attribute="$t('xmlFile')">
                            <b-form-input v-model="form.productFeedFile.xmlUrl" :disabled="isBusy" :state="slotProps.state" />
                        </FormValidator>
                    </div>
                    <div>
                        <ButtonIcon
                            icon="external-link"
                            variant="link"
                            size="sm"
                            flip-icon
                            target="_blank"
                            :href="form.productFeedFile.xmlUrl"
                        >
                            {{ $t('open') }}
                        </ButtonIcon>
                    </div>

                    <div class="label">{{ $t('authorization') }} ({{ $t('optional') }})</div>
                    <div class="value">
                        <b-form-input v-model="form.productFeedFile.httpAuthUsername" placeholder="użytkownik" :disabled="isBusy" />
                        <b-form-input v-model="form.productFeedFile.httpAuthPassword" placeholder="hasło" :disabled="isBusy" />
                    </div>
                    <div></div>

                    <div class="label">{{ $t('format') }}</div>
                    <div class="value add-after">
                        <FormValidator :validator="$v.form.categoryItems" form-group v-slot="slotProps" :attribute="$t('format')">
                            <b-form-select
                                v-model="form.categoryItems"
                                :options="categoryItems"
                                add-null-option
                                :disabled="isBusy"
                                text-field="categoryName"
                                value-field="id"
                                :state="slotProps.state"
                            />
                        </FormValidator>
                    </div>
                    <div></div>

                    <div class="label">{{ $t('parser') }}</div>
                    <div class="value add-after">
                        <FormValidator :validator="$v.form.parser" form-group v-slot="slotProps" :attribute="$t('parser')">
                            <b-form-select
                                v-model="form.parser"
                                :options="feedParsers"
                                add-null-option
                                :disabled="isBusy"
                                text-field="label"
                                value-field="value"
                                :state="slotProps.state"
                            />
                        </FormValidator>
                    </div>
                    <div></div>

                    <div class="label">{{ $t('redirectUrl') }} ({{ $t('publishers') }})</div>
                    <div class="value add-after"><b-form-input v-model="form.extensionName" :disabled="isBusy" /></div>
                    <div></div>

                    <div class="label">{{ $t('redirectUrl') }} ({{ $t('producers') }})</div>
                    <div class="value add-after"><b-form-input v-model="form.producerExtensionName" :disabled="isBusy" /></div>
                    <div></div>

                    <div class="label">{{ $t('parameterName') }}</div>
                    <div class="value add-after"><b-form-input v-model="form.extensionParam" :disabled="isBusy" /></div>
                    <div></div>

                    <div class="label">{{ $t('ruleFeedPrice') }}</div>
                    <div class="value add-after">
                        <b-form-checkbox
                            v-model="form.excludeFeedPriceRule"
                            switch
                            size="lg"
                            :value="false"
                            :unchecked-value="true"
                            :disabled="isBusy"
                        />
                    </div>
                    <div></div>

                    <div class="label">{{ $t('offerLifetime') }}</div>
                    <div class="value add-after"><b-form-input v-model.number="form.offerLifetime" :disabled="isBusy" type="number" /></div>
                    <div>{{ $t('inHours') }}</div>

                </div>

                <div class="d-flex gap-4">

                    <div class="flex-grow-1 flex-shrink-0 w-50">
                        <div class="label">{{ $t('processingOptions') }}</div>
                        <JSONeditor v-model="form.productFeedFile.processorOptions" :plus="false" height="400px" :disabled="isBusy" />
                    </div>

                    <div class="flex-grow-1 flex-shrink-0 w-50">
                        <div class="label">{{ $t('queryOptions') }}</div>
                        <JSONeditor v-model="form.productFeedFile.requestOptions" :plus="false" height="400px" :disabled="isBusy" />
                    </div>

                </div>

                <ButtonIcon
                    class="mr-auto"
                    variant="primary"
                    :disabled="isBusy"
                    :loading="isFeedUpdated"
                    hide-label-while-loading
                    @click="save()"
                >
                    {{ $t('saveChanges') }}
                </ButtonIcon>

            </form>

        </template>

    </div>
</template>



<script>
    import { required } from 'vuelidate/lib/validators';
    import JSONeditor from 'v-jsoneditor';
    import cloneDeep from 'lodash/cloneDeep';
    import pick from 'lodash/pick';
    import { createItemKey } from '@/store/modules/moduleFactory';
    import FormValidator from '@/components/common/FormValidator.vue';

    export default {
        name: 'AdminCampaignsFeedEdit',
        components: {
            JSONeditor,
            FormValidator,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.itemId),
                isFeedUpdated: false,
                isActiveUpdated: false,
                isProcessUpdated: false,
                form: {
                    offerLifetime: null,
                    name: '',
                    categoryItems: 0,
                    parser: '',
                    extensionName: '',
                    producerExtensionName: '',
                    extensionParam: '',
                    excludeFeedPriceRule: true,
                    productFeedFile: {
                        xmlUrl: '',
                        httpAuthUsername: '',
                        httpAuthPassword: '',
                        processorOptions: {},
                        requestOptions: {},
                    },
                },
            };
        },
        validations() {
            return {
                form: {
                    name: { required },
                    categoryItems: { required },
                    parser: { required },
                    productFeedFile: { xmlUrl: { required } },
                },
            };
        },
        computed: {
            parentRoute() {
                return this.$route.matched.slice(-2)[0];
            },
            feed() {
                return this.$store.getters['productFeeds/getFeed'];
            },
            categoryItems() {
                return this.$store.getters['productFeeds/getCategoryItems'];
            },
            feedParsers() {
                return this.$store.getters['productFeeds/getFeedParsers'];
            },
            feedStatuses() {
                return this.$store.getters['offers/getFeedStatuses'].reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {});
            },
            error() {
                return this.$store.getters['productFeeds/getError']('feed');
            },
            isLoading() {
                return this.$store.getters['productFeeds/getLoading']('feed');
            },
            isBusy() {
                return this.$store.getters['productFeeds/getBusy']('feed') || this.$store.getters['productFeeds/getBusy']('feedProcess');
            },
            isItemBusy() {
                return this.$store.getters['productFeeds/getBusyItem']('feed', createItemKey({ id: this.id }));
            },
            isItemUpdated() {
                return this.$store.getters['productFeeds/getUpdatedItem']('feed', createItemKey({ id: this.id }));
            },
        },
        methods: {
            fetchCategoryItems() {
                this.$store.dispatch('productFeeds/fetchCategoryItems').catch(() => {});
            },
            fetchFeedParsers() {
                this.$store.dispatch('productFeeds/fetchFeedParsers').catch(() => {});
            },
            fetchFeedStatuses() {
                this.$store.dispatch('offers/fetchFeedStatuses').catch(() => {});
            },
            fetchFeed() {
                this.$store
                    .dispatch('productFeeds/fetchFeed', { urlParams: { id: this.id } })
                    .then(this.initFormValues)
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            back() {
                this.$router.replace({ name: 'CampaignsDetailsTab', params: { ...this.parentRoute.params } });
            },
            async patchActiveState(state) {
                this.isActiveUpdated = true;
                const feed = await this.$store.dispatch('productFeeds/patchFeed', { urlParams: { id: this.id }, requestParams: { active: state } }).catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
                if (feed) this.$store.commit('productFeeds/setFeed', feed);
                this.isActiveUpdated = false;
            },
            async reprocess() {
                this.isProcessUpdated = true;
                const feed = await this.$store.dispatch('productFeeds/putFeedProcess', { urlParams: { id: this.id } }).catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
                if (feed) this.$store.commit('productFeeds/setFeed', feed);
                this.isProcessUpdated = false;
            },
            async save() {
                this.$v.form.$touch();
                if (this.$v.form.$invalid) return;
                this.isFeedUpdated = true;
                const feed = await this.$store.dispatch('productFeeds/patchFeed', { urlParams: { id: this.id }, requestParams: this.form }).catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
                if (feed) {
                    this.$store.commit('productFeeds/setFeed', feed);
                    this.$toastr.success(this.$t('dataSavedSuccessfully'));
                }
                this.initFormValues();
                this.isFeedUpdated = false;
            },
            initFormValues() {
                const itemClone = cloneDeep(this.feed);
                const itemPick = pick(itemClone, Object.keys(this.form));
                const productFeedFile = { ...this.form.productFeedFile, ...cloneDeep(this.feed.productFeedFile) };
                const categoryItems = this.feed.categoryItem.id;
                this.form = { ...this.form, ...itemPick, categoryItems, productFeedFile };
            },
        },
        created() {
            this.fetchCategoryItems();
            this.fetchFeedParsers();
            this.fetchFeedStatuses();
            this.fetchFeed();
        },
    };
</script>



<style lang="scss" scoped>
    form {
        .label {
            font-weight: bold;
        }
    }

    .grid-template {
        display: grid;
        grid-template-columns: minmax(25%, max-content) auto min-content;
        gap: 1rem;

        > div {
            display: flex;
            gap: 1rem;
            align-items: center;

            fieldset {
                width: 100%;
                margin: 0;
            }
        }

        .border {
            grid-column: 1/-1;
        }
    }
</style>
