<template>
    <b-overlay :show="loading" rounded="sm" class="h-100">
        <div class="card h-100">
            <div class="card-body">
                <ErrorMessage :value="error" />
                <h4 class="header-title mt-0 mb-3">
                    {{ $t('overview') }}
                </h4>
                <Chart
                    height="380"
                    type="bar"
                    class="apex-charts"
                    :series="series"
                    :options="options"
                />
            </div>
        </div>
    </b-overlay>
</template>



<script>
    import clone from 'lodash/clone';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import Chart from '@/components/common/Chart.vue';
    import { generateChartData, chartDataGroupBy } from '@/utils/chart';

    export default {
        name: 'TipsChart',
        inject: {
            parentComponent: {
                default: null,
            },
        },
        components: {
            ErrorMessage,
            Chart,
        },
        data() {
            return {
                options: {
                    colors: ['#5369f8'],
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top',
                            },
                        },
                    },
                    xaxis: {
                        title: {
                            text: '',
                            offsetY: 7,
                            style: {
                                fontSize: 14,
                                fontWeight: 300,
                            },
                        },
                    },
                    tooltip: {
                        x: {
                            show: true,
                            format: 'MMMM y',
                        },
                        y: {
                            title: {
                                formatter: seriesName => '',
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            show: false,
                            formatter: val => this.$format.currency(val),
                        },
                    },
                },
                series: [],
                currentFilters: {},
            };
        },
        computed: {
            filters() {
                return this.parentComponent.filters;
            },
            filtersParams() {
                const filters = {
                    startDate: this.filters.timeRange?.startDate,
                    endDate: this.filters.timeRange?.endDate,
                    groupBy: this.groupBy,
                };
                if (this.filters.statuses?.length) filters.statuses = this.filters.statuses.join(',');
                if (this.filters.space) filters.spaces = this.filters.space.value;
                if (this.filters.users?.length) filters.users = this.filters.users.map(item => item.id).join(',');
                return filters;
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                };
            },
            loading() {
                return this.$store.getters['tipTransactions/getLoading']('chart');
            },
            error() {
                return this.$store.getters['tipTransactions/getError']('chart');
            },
            chartData() {
                return this.$store.getters['tipTransactions/getChart'];
            },
            groupBy() {
                return chartDataGroupBy(this.filters.timeRange);
            },
            isFiltersChanged() {
                return JSON.stringify(this.filtersParams) !== JSON.stringify(this.currentFilters);
            },
        },
        watch: {
            'filters.timeRange': {
                handler(newValue, oldValue) {
                    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                        this.fetchData();
                    }
                },
            },
            chartData: {
                immediate: true,
                handler() {
                    const chartItem = this.chartData.map(item => [item.date, item.amount]);
                    this.series = [{ data: generateChartData(this.filters.timeRange, chartItem, this.groupBy) }];
                },
            },
        },
        methods: {
            clearData() {
                this.$store.commit('tipTransactions/clearChart');
            },
            fetchData() {
                this.currentFilters = clone(this.filtersParams);
                this.$store.dispatch('tipTransactions/fetchChart', { requestParams: this.requestParams }).catch(this.clearData);
            },
        },
        mounted() {
            this.clearData();
        },
    };
</script>
