<template>
    <DataTable
        v-model="selectedRows"
        :fields="fields"
        :items="parentComponent.items"
        :loading="parentComponent.loading"
        :error="parentComponent.error"
        :total-rows="parentComponent.requestData.max"
        :current-page.sync="parentComponent.tableParams.page"
        :per-page.sync="parentComponent.tableParams.perPage"
        :sort-by.sync="parentComponent.tableParams.sort"
        :sort-direction.sync="parentComponent.tableParams.order"
        :no-sort-reset="true"
        :busy-items="busyItems"
        hover
        selectable
        selectable-id-key="id"
    >
        <template #table-busy>
            <div class="d-flex justify-content-center my-2">
                <b-spinner />
            </div>
        </template>
        <template #head(createdAt)>
            {{ $t('date') }} <br> {{ $t('hour') }}
        </template>
        <template #head(id)>
            {{ $t('id') }}
        </template>
        <template #head(transId)>
            {{ $t('identifier') }} <br> {{ $t('status') }}
        </template>
        <template #cell(abpar)="data">
            <Ellipsis v-if="data.item.abpar1" :max-width="200" :max-lines="1">1: {{ data.item.abpar1 }}</Ellipsis>
            <Ellipsis v-if="data.item.abpar2" :max-width="200" :max-lines="1">2: {{ data.item.abpar2 }}</Ellipsis>
            <Ellipsis v-if="data.item.abpar3" :max-width="200" :max-lines="1">3: {{ data.item.abpar3 }}</Ellipsis>
        </template>
        <template #cell(createdAt)="data">
            {{ $format.dateAndHour(data.value) }}
        </template>
        <template #cell(transId)="data">
            <Ellipsis :max-width="200" :max-lines="2">
                {{ data.item.transId }}
            </Ellipsis>
            <TransactionStatusBadge v-if="data.item.status" :status="data.item.status" />
        </template>
        <template #cell(commission)="data">
            <div>{{ $t('transactions.commissions.publisher') }}: {{ $format.currency(data.item.publisherCommission) }}</div>
            <div>{{ $t('transactions.commissions.admin') }}: {{ $format.currency(data.item.adminCommission) }}</div>
            <div>{{ $t('transactions.commissions.advertiser') }}: {{ $format.currency(data.item.advertiserCommission) }}</div>
        </template>
        <template #cell(amount)="data">
            {{ $format.currency(data.value) }}
        </template>
        <template #cell(conversionTime)="data">
            {{ $format.seconds(data.value) }}
        </template>
        <template #cell(action)="data">
            <b-button variant="success" @click="previewItem(data.item)">
                {{ $t('details') }}
            </b-button>
        </template>
        <template #afterTable>
            <div class="d-flex align-items-center mt-3">
                <span>{{ $t('transactions.withSelected') }}</span>
                <span class="mx-2"></span>
                <Multiselect
                    v-model="action"
                    :options="actionOptions"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    :disabled="isItemsBusy"
                    class="width-200 d-inline-block"
                />
                <span class="mx-2"></span>
                <b-button variant="primary" :disabled="!action || !action.value || !parentComponent.items.length || parentComponent.loading || isItemsBusy" @click="changeTransactionsStatus()">
                    {{ $t('save') }}
                </b-button>
            </div>
        </template>
    </DataTable>
</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';
    import TransactionStatusBadge from '@/components/common/TransactionStatusBadge.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import Ellipsis from '@/components/common/Ellipsis.vue';
    import transactionsService, { STATUSES } from '@/services/transactionsService';

    export default {
        name: 'Results',
        components: {
            DataTable,
            TransactionStatusBadge,
            Multiselect,
            Ellipsis,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                STATUSES,
                selectedRows: [],
                action: null,
                busyItems: [],
            };
        },
        computed: {
            fields() {
                return [
                    {
                        key: 'createdAt',
                        label: this.$t('date'),
                        sortable: true,
                    },
                    {
                        key: 'id',
                        label: this.$t('id'),
                        sortable: true,
                    },
                    {
                        key: 'transId',
                        label: this.$t('identifier'),
                        sortable: true,
                    },
                    {
                        key: 'campaign.name',
                        label: this.$t('campaign'),
                    },
                    {
                        key: 'space.name',
                        label: this.$t('space'),
                    },
                    {
                        key: 'abpar',
                        label: 'Abpar',
                    },
                    {
                        key: 'amount',
                        label: this.$t('transactions.transactionAmount'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'commission',
                        label: this.$t('commission'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'conversionTime',
                        label: this.$t('conversionTime'),
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'action',
                        label: '',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ];
            },
            actionOptions() {
                return [
                    { value: null, text: this.$t('doNothing') },
                    { value: 'accept', text: this.$t('acceptTransactions') },
                    { value: 'reject', text: this.$t('rejectTransactions') },
                ];
            },
            isItemBusy() {
                return id => !!this.busyItems.find(itemId => itemId === id);
            },
            isItemsBusy() {
                return !!this.busyItems.length;
            },
        },
        watch: {
            selectedRows() {
                if (this.selectedRows.length) this.$toastr.close('noTransactionsSelected');
            },
        },
        methods: {
            previewItem(item) {
                this.$root.$emit('bv::show::modal', `modal-preview-${this.parentComponent.modalItemName}`);
                this.$root.$emit(`preview-${this.parentComponent.modalItemName}`, item);
            },
            async changeTransactionsStatus() {
                if (!this.selectedRows.length) {
                    this.$toastr.info(this.$t('noTransactionsSelected'), null, { id: 'noTransactionsSelected' });
                } else if (this.action.value) {
                    await this.transactionAction(this.action.value);
                }
            },
            async transactionAction(action) {
                this.busyItems = [...this.selectedRows];
                try {
                    await transactionsService.putTransactionsAction({ urlParams: { action }, requestParams: { transactions: this.selectedRows } });
                    this.selectedRows = [];
                    this.parentComponent.fetchData();
                } catch (error) {
                    this.$toastr.error(error.message);
                }
                this.busyItems = [];
            },
            addBusyItem(id) {
                this.busyItems.push(id);
            },
            removeBusyItem(id) {
                const newSet = new Set(this.busyItems);
                newSet.delete(id);
                this.busyItems = Array.from(newSet);
            },
        },
    };
</script>
