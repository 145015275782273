<template>
    <div>
        <PageHeader>
            {{ $t('statistics') }}
            <template #side>
                <ButtonIcon
                    class="mr-2"
                    icon="refresh-cw"
                    variant="link"
                    :disabled="loading"
                    :icon-animation="loading ? 'spin' : ''"
                    @click="reloadData"
                />
                <TimeRangeDropdown
                    :value="timeRangeValue"
                    :disabled="loading"
                    :options="['last7Days', 'currentWeek', 'last14Days', 'last30days', 'currentMonth', 'lastMonth', 'currentQuarter', 'lastQuarter', 'currentYear', 'lastYear', 'custom']"
                    @startDate="filters.startDate = $event"
                    @endDate="filters.endDate = $event"
                    @timeRange="filterData()"
                />
            </template>
        </PageHeader>
        <Filters ref="Filters" />
        <Chart ref="Chart" />
        <Results ref="Results" />
    </div>
</template>



<script>
    import Filters from './parts/Filters.vue';
    import Chart from './parts/Chart.vue';
    import Results from './parts/Results.vue';
    import TimeRangeDropdown, { getDefaultTimeRange } from '@/components/common/TimeRangeDropdown.vue';
    import viewFiltersMixin from '@/mixins/viewFiltersMixin';

    const DEFAULT_TIME_RANGE = getDefaultTimeRange();
    const DEFAULT_FILTERS_PARAMS = Object.freeze({
        startDate: DEFAULT_TIME_RANGE.startDate,
        endDate: DEFAULT_TIME_RANGE.endDate,
        spaces: [],
        campaigns: null,
        groupBy: 'day',
    });
    const DEFAULT_TABLE_PARAMS = Object.freeze({
        perPage: 20,
        page: 1,
        sort: 'date',
        order: 'DESC',
    });

    export default {
        name: 'ReportsStats',
        mixins: [viewFiltersMixin],
        components: {
            Filters,
            Chart,
            Results,
            TimeRangeDropdown,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                ...this.initParams(DEFAULT_FILTERS_PARAMS, DEFAULT_TABLE_PARAMS),
                request: this.createRequest('userStats/generalStats'),
            };
        },
        computed: {
            requestParams() {
                return {
                    ...this.requestParamsFilters,
                };
            },
            timeRangeValue() {
                const { startDate, endDate } = this.$route.query;
                const timeRangeValue = startDate && endDate ? { startDate, endDate } : undefined;
                return timeRangeValue;
            },
        },
    };
</script>
