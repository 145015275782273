const facebookAuth = {
    init(config) {
        if (config === undefined) {
            console.error('Undefined config for Facebook API! Initialized failed!');
            return;
        }
        const el = document.getElementById('facebook_auth_script');
        const createScript = () => {
            const providerScript = document.createElement('script');
            providerScript.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js');
            providerScript.setAttribute('async', true);
            providerScript.setAttribute('defer', 'defer');
            providerScript.setAttribute('id', 'facebook_auth_script');
            document.head.appendChild(providerScript);
        };
        window.fbAsyncInit = () => {
            window.FB.init(config);
        };
        if (!el) createScript();
    },
    signIn() {
        return new Promise((resolve, reject) => {
            window.FB.login(response => (response.status === 'connected' ? resolve(response) : reject(response)), { scope: 'public_profile,email' });
        });
    },
    signOut() {
        return new Promise((resolve, reject) => {
            try {
                window.FB.logout(resolve);
            } catch (error) {
                reject(error);
            }
        });
    },
    isSignedIn() {
        let response;
        window.FB.getLoginStatus(res => res.status === 'connected');
        return response;
    },
    getCurrentUser() {
        let response;
        window.FB.getLoginStatus(res => (response = res));
        return response;
    },
};

export default facebookAuth;
