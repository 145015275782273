<template>
    <div class="d-flex gap-3 align-items-center">
        <span class="nowrap">{{ $t('forSelected') }}</span>

        <Multiselect
            :disabled="!ids.length || busy"
            :options="tableActionOptions.options"
            select-label=""
            selected-label=""
            deselect-label=""
            class="width-250 flex-shrink-0 d-inline-block"
            :allow-empty="false"
            :value="tableAction"
            @input="tableAction = $event.value"
        />

        <b-form-input v-if="tableAction === 'TRANSFER_TO_ANOTHER_PRODUCT'" v-model.number="tableActionProductId" class="width-100 d-inline-block" :placeholder="$t('productId')" :disabled="!ids.length || busy" />

        <Multiselect
            v-if="tableAction === 'CHANGE_OR_HIDDEN'"
            :disabled="!ids.length || busy"
            :options="tableActionOptions.yesno"
            select-label=""
            selected-label=""
            deselect-label=""
            class="width-150 d-inline-block"
            :value="tableActionHidden"
            @input="tableActionHidden = $event.value"
        />

        <Multiselect
            v-if="tableAction === 'CHANGE_AVAILABILITY'"
            :disabled="!ids.length || busy"
            :options="tableActionOptions.available"
            select-label=""
            selected-label=""
            deselect-label=""
            class="width-150 d-inline-block"
            :value="tableActionChangeAvailability"
            @input="tableActionChangeAvailability = $event.value"
        />

        <Multiselect
            v-if="tableAction === 'CHANGE_PRODUCT_TYPE'"
            :disabled="!ids.length || busy"
            :options="tableActionOptions.type"
            select-label=""
            selected-label=""
            deselect-label=""
            class="width-150 d-inline-block"
            :value="tableActionChangeProductType"
            @input="tableActionChangeProductType = $event.value"
        />

        <Multiselect
            v-if="tableAction === 'CHANGE_PRODUCT_TYPE' && tableActionChangeProductType && tableActionChangeProductType.startsWith('content')"
            :disabled="!ids.length || busy"
            :options="tableActionOptions.format"
            select-label=""
            selected-label=""
            deselect-label=""
            class="width-150 d-inline-block"
            :value="tableActionChangeProductTypeFormat"
            @input="tableActionChangeProductTypeFormat = $event.value"
        />

        <ButtonIcon :disabled="!isTableActionButtonActive" :loading="busy" hide-label-while-loading class="width-min-80" @click="editItems()">
            {{ $t('save') }}
        </ButtonIcon>
    </div>
</template>



<script>
    import offersService from '@/services/offersService';
    import productService from '@/services/productService';
    import Multiselect from '@/components/common/Multiselect.vue';

    export default {
        name: 'TableAction',
        components: {
            Multiselect,
        },
        props: {
            ids: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                busy: false,
                tableAction: null,
                tableActionProductId: null,
                tableActionHidden: null,
                tableActionChangeAvailability: null,
                tableActionChangeProductType: null,
                tableActionChangeProductTypeFormat: null,
            };
        },
        computed: {
            currentAction() {
                if (!this.tableAction) return null;
                if (this.tableAction === 'CREATE_NEW_PRODUCT') return this.createNewProduct;
                if (this.tableAction === 'TRANSFER_TO_ANOTHER_PRODUCT' && this.tableActionProductId) return this.transferToAnotherProduct;
                if (this.tableAction === 'UNLINK_PRODUCT') return this.unlinkProduct;
                if (this.tableAction === 'CHANGE_PRODUCT_TYPE' && this.tableActionChangeProductType && (this.tableActionChangeProductType?.startsWith('content') ? this.tableActionChangeProductTypeFormat : true)) return this.changeProductType; // eslint-disable-line
                if (this.tableAction === 'CHANGE_OR_HIDDEN' && typeof this.tableActionHidden === 'boolean') return this.changeOrHidden;
                if (this.tableAction === 'CHANGE_AVAILABILITY' && typeof this.tableActionChangeAvailability === 'boolean') return this.changeAvailability;
                return null;
            },
            isTableActionButtonActive() {
                const isDisabled = !this.tableAction || !this.ids.length || this.busy;
                if (isDisabled) return false;
                return !!this.currentAction;
            },
            typesAndFormats() {
                return this.$store.getters['offers/getTypesAndFormats'];
            },
            tableActionOptions() {
                return {
                    options: [
                        { value: null, text: this.$t('selectOption') },
                        { value: 'CREATE_NEW_PRODUCT', text: this.$t('createNewProduct') },
                        { value: 'TRANSFER_TO_ANOTHER_PRODUCT', text: this.$t('transferToAnotherProduct') },
                        { value: 'UNLINK_PRODUCT', text: this.$t('unlinkProduct') },
                        { value: 'CHANGE_PRODUCT_TYPE', text: this.$t('changeProductType') },
                        { value: 'CHANGE_OR_HIDDEN', text: this.$t('changeOrHidden') },
                        { value: 'CHANGE_AVAILABILITY', text: this.$t('changeAvailability') },
                    ],
                    type: this.typesAndFormats.map(item => ({ value: item.value, text: item.label })),
                    format: this.typesAndFormats.find(item => item.value === this.tableActionChangeProductType)?.formats.map(item => ({ value: item.id, text: item.categoryName })),
                    yesno: [
                        { value: true, text: this.$t('yes') },
                        { value: false, text: this.$t('no') },
                    ],
                    available: [
                        { value: true, text: this.$t('available2') },
                        { value: false, text: this.$t('unavailable2') },
                    ],
                };
            },
            requestParams() {
                const params = { offers: this.ids };
                if (this.tableActionProductId) params.product = this.tableActionProductId;
                if (typeof this.tableActionHidden === 'boolean') params.hidden = this.tableActionHidden;
                if (typeof this.tableActionChangeAvailability === 'boolean') params.active = this.tableActionChangeAvailability;
                if (this.tableActionChangeProductType) params.type = this.tableActionChangeProductType;
                if (this.tableActionChangeProductTypeFormat) params.format = this.tableActionChangeProductTypeFormat;
                return params;
            },
        },
        watch: {
            tableAction() {
                this.resetTableAction();
            },
            tableActionChangeProductType() {
                this.tableActionChangeProductTypeFormat = null;
            },
        },
        methods: {
            resetTableAction() {
                this.tableActionProductId = null;
                this.tableActionHidden = null;
                this.tableActionChangeAvailability = null;
                this.tableActionChangeProductType = null;
                this.tableActionChangeProductTypeFormat = null;
            },
            editItems() {
                if (!this.ids.length) {
                    this.$toastr.info(this.$t('noItemsSelected'), null, { id: 'noItemsSelected' });
                } else {
                    this.busy = true;
                    this.emitStatus('pending');
                    this.itemsAction()
                        .then(data => {
                            this.emitStatus('success', data);
                            this.$toastr.success(this.$t('requestExecutedSuccessfully'));
                            this.tableAction = null;
                            this.resetTableAction();
                        })
                        .catch(error => {
                            this.emitStatus('error', error);
                            this.$toastr.error(error.message, this.$t('errorOccurred'));
                        })
                        .finally(() => {
                            this.busy = false;
                        });
                }
            },
            itemsAction() {
                if (this.currentAction) return this.currentAction();
                return Promise.reject(new Error(this.$t('noParametersSelected')));
            },
            emitStatus(status, data) {
                this.$emit('status', status, data);
            },
            createNewProduct() {
                return productService.postProducts({ requestParams: this.requestParams });
            },
            transferToAnotherProduct() {
                return this.$store.dispatch('offers/patchOffer', { requestParams: this.requestParams }).catch(error => {
                    if (error?.error?.response?.data?.fields?.product) error.message = this.$t('productWithIdDoesNotExist'); // eslint-disable-line no-param-reassign
                    throw error;
                });
            },
            unlinkProduct() {
                return offersService.deleteProduct({ requestParams: this.requestParams });
            },
            changeProductType() {
                return this.$store.dispatch('offers/patchOffer', { requestParams: this.requestParams });
            },
            changeOrHidden() {
                return this.$store.dispatch('offers/patchOffer', { requestParams: this.requestParams });
            },
            changeAvailability() {
                return this.$store.dispatch('offers/patchOffer', { requestParams: this.requestParams });
            },
        },
    };
</script>
