<template>
    <div class="account-pages my-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-10">
                    <card :header="$t('acceptTerms.title')">
                        <i18n path="acceptTerms.description" tag="p" class="text-muted">
                            <template v-slot:link>
                                <a :href="termsUrl" target="_blank">{{ $t('acceptTerms.descriptionLink') }}</a>
                            </template>
                        </i18n>
                        <error-message v-model="termsError" />
                        <div class="mt-4">
                            <b-button
                                :disabled="submitting"
                                variant="secondary"
                                size="sm"
                                class="width-min-150"
                                @click="cancel"
                            >
                                {{ $t('acceptTerms.cancel') }}
                            </b-button>
                            <div class="mx-2 d-inline-block"></div>
                            <b-button
                                :disabled="submitting"
                                variant="primary"
                                size="sm"
                                class="width-min-150"
                                @click="accept"
                            >
                                <b-spinner v-if="submitting" small />
                                <span v-else>{{ $t('acceptTerms.accept') }}</span>
                            </b-button>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import Card from '@/components/common/Card.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'AcceptTerms',
        components: {
            Card,
            ErrorMessage,
        },
        data() {
            return {
                termsError: null,
            };
        },
        computed: {
            termsUrl() {
                return `${process.env.VUE_APP_MARKETING_PAGE_URL}/${this.$t('acceptTerms.url')}`;
            },
            ...mapState({
                userLanguage: (state) => state.auth.user.language,
                submitting: (state) => state.auth.loading.acceptTerms,
            }),
        },
        methods: {
            async cancel() {
                // this.trackEvent('acceptTerms_cancel');
                this.logout();
            },
            async accept() {
                // this.trackEvent('acceptTerms_accept');
                this.termsError = null;

                try {
                    await this.acceptTerms({
                        redirect: this.$route.query.redirect,
                    });
                } catch (e) {
                    this.termsError = e.message;
                }
            },
            ...mapActions({
                logout: 'auth/logout',
                acceptTerms: 'auth/acceptTerms',
            }),
        },
    };
</script>
