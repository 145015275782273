<script>
    import PageLoading from '@/components/common/PageLoading.vue';
    import TipboxSettings from './Settings.vue';
    import TipboxPolicies from './Policies.vue';

    export default {
        name: 'Tipbox',
        computed: {
            tipboxLoading() {
                return this.$store.getters['tipbox/getLoading']('tipboxSettings');
            },
            tipboxSettings() {
                return this.$store.getters['tipbox/getTipboxSettings'];
            },
            canShowTipbox() {
                return this.tipboxSettings && this.tipboxSettings.policies && this.tipboxSettings.paymentDataCompleted;
            },
        },
        methods: {
            fetchTipboxSettings() {
                return this.$store.dispatch('tipbox/fetchTipboxSettings').catch(() => {});
            },
        },
        render(h) {
            if (this.tipboxLoading) return h(PageLoading, { staticClass: 'my-4 h-100 flex-grow-1' });
            if (this.canShowTipbox) return h(TipboxSettings);
            return h(TipboxPolicies);
        },
        created() {
            this.fetchTipboxSettings();
        },
    };
</script>
