<template>
    <div>
        <PageHeader>
            {{ $t('verifications') }}

            <template #side>
                <ButtonIcon hide-label-while-loading :loading="loading" @click="triggerUpload">
                    {{ $t('selectFile') }}
                </ButtonIcon>
                <input ref="fileUploader" type="file" style="display: none;" @change="uploadFile">
            </template>


        </PageHeader>
        <Results ref="Results" :transactions="transactions" />
    </div>
</template>

<script>
    import Results from './parts/Results.vue';
    import ButtonIcon from '@/components/common/ButtonIcon.vue';

    export default {
        name: 'Verification',
        components: {
            Results,
            ButtonIcon,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                perPage: 50,
                currentPage: 1,
                fileToUpload: null,
                transactions: [],
            };
        },
        computed: {
            loading() {
                return this.$store.getters['userVerifications/getBusy']('verificationsUsers');
            },
        },
        watch: {
            currentPage() {
                this.fetchUsers();
            },
            perPage() {
                this.fetchUsers();
            },
        },
        methods: {
            triggerUpload() {
                this.$refs.fileUploader.click();
            },

            uploadFile(event) {
                this.fileToUpload = event.target.files[0];
                const formData = new FormData();
                formData.append('transferList', this.fileToUpload);
                this.$store
                    .dispatch('userVerifications/postVerificationsUsers', { requestParams: formData, headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(response => {
                        this.transactions = response.items;
                    })
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
        },
    };
</script>
