<template>
    <b-overlay :show="loading" rounded="sm" class="h-100">
        <Card :header="$t('price')" class="m-0 shadow-none">
            <ErrorMessage v-if="error" :value="error" />
            <Chart v-else :height="400" :series="chartSeries" :options="chartOptions" />
        </Card>
    </b-overlay>
</template>



<script>
    import { addDays } from 'date-fns';
    import Chart from '@/components/common/Chart.vue';
    import dateUtil from '@/utils/date';

    export default {
        name: 'PriceChart',
        components: {
            Chart,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.id),
                chartOptions: {
                    colors: ['#2e93fa'],
                    fill: {
                        type: 'image',
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            format: 'yyyy.MM.dd',
                            datetimeUTC: false,
                        },
                    },
                    tooltip: {
                        enabled: true,
                        x: {
                            show: false,
                        },
                        y: {
                            formatter: val => val,
                            title: {
                                formatter: () => '',
                            },
                        },
                    },
                },
            };
        },
        computed: {
            product() {
                return this.parentComponent.product;
            },
            apiParams() {
                return {
                    requestParams: {
                        startDate: dateUtil.formatDateForUrl(addDays(Date.now(), -30)),
                        endDate: dateUtil.formatDateForUrl(Date.now()),
                    },
                    urlParams: { offerId: this.id },
                };
            },
            loading() {
                return this.$store.getters['offers/getLoading']('priceChart');
            },
            error() {
                return this.$store.getters['offers/getError']('priceChart')?.message;
            },
            chartData() {
                return this.$store.getters['offers/getPriceChart'];
            },
            chartSeries() {
                return [
                    {
                        data: this.chartData,
                    },
                ];
            },
        },
        watch: {
            chartData: {
                immediate: true,
                handler() {
                    const values = this.chartData.map(item => Number.parseFloat(item[1]));
                    const min = Number.parseInt(Math.min(...values));
                    const max = Number.parseInt(Math.max(...values));
                    const difference = Number.parseInt(Math.max(max - min, 100) * 0.2);
                    this.chartOptions.yaxis = {
                        min: Math.max(min - difference, 0),
                        max: max + difference,
                    };
                },
            },
        },
        methods: {
            fetchData() {
                this.$store.dispatch('offers/fetchPriceChart', this.apiParams);
            },
            clearStoreData() {
                this.$store.commit('offers/clearPriceChart');
            },
        },
        created() {
            this.clearStoreData();
            this.fetchData();
        },
    };
</script>
