<template>
    <row-labeled :row="row">
        <template v-if="label || $scopedSlots.label" #label>
            <slot name="label">
                <span>{{ label }} <span v-if="isRequired" class="text-danger">*</span></span>
            </slot>
        </template>

        <template #content="{ htmUniqueId }">
            <div v-if="icon" class="input-group-prepend">
                <span class="input-group-text py-0">
                    <feather :type="icon" class="align-middle icon-dual" />
                </span>
            </div>

            <slot name="prepend"></slot>

            <b-input-group :prepend="prepend">
                <b-form-input
                    :id="htmUniqueId"
                    v-model.trim="model"
                    :type="type"
                    :class="{ 'is-invalid': hasValidationErrors }"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :maxlength="maxlength"
                    min="0"
                    :autocomplete="autocomplete"
                    @blur="
                        $emit('blur');
                        validate();
                    "
                />
            </b-input-group>

            <div v-if="hasValidationErrors && onlyFirstError" class="invalid-feedback">
                {{ validationErrors[0] }}
            </div>

            <div v-else-if="hasValidationErrors" class="invalid-feedback">
                {{ validationErrors.join(' ') }}
            </div>
        </template>
    </row-labeled>
</template>

<script>
    import RowLabeled from './RowLabeled.vue';
    import validatableFieldMixin from '../../mixins/validatableFieldMixin';

    export default {
        name: 'TextField',
        mixins: [validatableFieldMixin],
        components: { RowLabeled },
        props: {
            /**
             * @model
             */
            value: {
                type: [String, Number],
            },
            label: {
                type: String,
            },
            placeholder: {
                type: String,
            },
            icon: {
                type: String,
            },
            type: {
                type: String,
                default: 'text',
            },
            row: {
                type: Boolean,
            },
            prepend: {
                type: String,
            },
            disabled: {
                type: Boolean,
            },
            maxlength: {
                type: Number,
            },
            autocomplete: {
                type: String,
            },
            onlyFirstError: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
