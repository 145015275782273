<template>
    <b-overlay :show="loading" rounded="sm" class="h-100">
        <div class="card mb-0 h-100">
            <div class="card-body p-0">
                <ErrorMessage :value="errorMessage" class="m-3" />
                <div class="media p-3 gap-2" :class="{'d-flex flex-column': !data}">
                    <div class="media-body">
                        <span class="text-muted text-uppercase font-size-12 font-weight-bold">{{ title }}</span>
                        <h2 class="mb-0">
                            {{ value }}
                        </h2>
                    </div>
                    <div :class="{'align-self-center': data}">
                        <apexchart
                            v-if="Array.isArray(data)"
                            :options="chartOptions"
                            :series="[{data}]"
                            class="apex-chart-widget"
                            height="45"
                            type="area"
                            width="90"
                        />
                        <span :class="{ 'text-danger': `${parseFloat(difference)}` < 0 }" class="text-success font-weight-bold font-size-13">
                            <feather
                                v-if="parseFloat(difference) !== 0"
                                :type="parseFloat(difference) > 0 ? 'arrow-up' : 'arrow-down'"
                                size="12px"
                                stroke-width="3"
                            />
                            {{ difference }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>



<script>
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'WidgetStatChart',
        components: {
            ErrorMessage,
        },
        props: {
            title: {
                type: String,
                default: '',
            },
            value: {
                type: [Number, String],
                default: 0,
            },
            difference: {
                type: [Number, String],
                default: '',
            },
            chartColor: {
                type: String,
                default: '#5369f8',
            },
            data: {
                type: Array,
                default: null,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            error: {
                type: [Error, Object, String],
                default: null,
            },
            tooltipFormatter: {
                type: [Function, String],
                default: null,
            },
        },
        data() {
            return {
                chartOptions: {
                    chart: {
                        sparkline: {
                            enabled: true,
                        },
                        parentHeightOffset: 0,
                        toolbar: {
                            show: false,
                        },
                        animations: {
                            enabled: true,
                        },
                    },
                    colors: [this.chartColor],
                    height: 45,
                    width: 90,
                    markers: { size: 0 },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            type: 'vertical',
                            shadeIntensity: 1,
                            inverseColors: false,
                            opacityFrom: 0.45,
                            opacityTo: 0.05,
                            stops: [45, 100],
                        },
                    },
                    tooltip: {
                        theme: 'dark',
                        fixed: {
                            enabled: false,
                        },
                        x: {
                            show: false,
                        },
                        y: {
                            title: {
                                formatter: seriesName => '',
                            },
                            formatter: (value, data) => {
                                if (this.tooltipFormatter === 'round') return Math.round(value);
                                if (this.tooltipFormatter === 'currency') return this.$format.currency(value);
                                if (this.tooltipFormatter === 'percent') return this.$format.percent(value);
                                if (typeof this.tooltipFormatter === 'function') return this.tooltipFormatter(value, data);
                                return value;
                            },
                        },
                        marker: {
                            show: false,
                        },
                    },
                    stroke: { width: 2, curve: 'smooth' },
                },
            };
        },
        computed: {
            errorMessage() {
                return this.error?.message || this.error || null;
            },
        },
    };
</script>
