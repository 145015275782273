<template>
    <div class="wrapper-container">
        <div v-show="showProductSelect" class="b-overlay position-absolute w-100 h-100" style="z-index: 10;">
            <div class="position-absolute bg-light w-100 h-100"></div>
            <div class="position-absolute" style="top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%);">
                <b-card class="width-600 shadow m-0">
                    <b-card-title class="d-flex align-center">
                        <div>{{ $t('selectProduct') }}</div>
                        <ButtonIcon v-if="widgetPreviewData['bb-offer-id']" variant="light" size="sm" class="ml-auto" @click="showChangeProduct = false">
                            {{ $t('goBack') }}
                        </ButtonIcon>
                    </b-card-title>
                    <div class="w-100 h-100 my-2"></div>
                    <ProductSearch @input="setProduct" />
                </b-card>
            </div>
        </div>
        <div v-show="!showProductSelect" class="wrapper-container-inner py-5">
            <div class="d-flex gap-3 mb-5">
                <div class="mr-auto">
                    <b-skeleton animation="" width="60px" height="60px" />
                </div>
                <div class="ml-auto d-flex gap-3 flex-grow-1 justify-content-end">
                    <b-skeleton animation="" width="15%" height="30px" />
                    <b-skeleton animation="" width="15%" height="30px" />
                    <b-skeleton animation="" width="15%" height="30px" />
                </div>
            </div>
            <div class="mb-3">
                <b-skeleton animation="" width="100%" height="60px" />
            </div>
            <div class="mb-5">
                <b-skeleton animation="" width="100%" height="400px" />
            </div>
            <div class="mb-5 d-flex flex-column gap-3">
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="100%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="25%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="25%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
                <template v-if="!isPopoverOrOverlay">
                    <div class="d-flex gap-3">
                        <b-skeleton animation="" width="100%" height="20px" />
                    </div>
                    <div class="d-flex gap-3">
                        <b-skeleton animation="" width="40%" height="20px" />
                        <b-skeleton animation="" width="40%" height="20px" />
                    </div>
                    <div class="d-flex gap-3">
                        <b-skeleton animation="" width="25%" height="20px" />
                        <b-skeleton animation="" width="40%" height="20px" />
                        <b-skeleton animation="" width="25%" height="20px" />
                    </div>
                    <div class="d-flex gap-3">
                        <b-skeleton animation="" width="40%" height="20px" />
                        <b-skeleton animation="" width="40%" height="20px" />
                    </div>
                </template>
            </div>
            <div ref="widget-preview-container" class="mb-5 widget-preview">
                <WidgetPreview
                    v-if="widgetPreviewData['bb-id']"
                    ref="widget-preview"
                    :class="(isPopoverOrOverlay && 'btn btn-accent rounded-max' || '') + ` widget-type-${widgetPreviewData['bb-type']} `"
                    v-bind="widgetPreviewData"
                    :reload-delay="0"
                />
                <b-alert v-else show variant="info">
                    {{ $t('noOffersToDisplay') }} – <b-link @click="openWidgetGenerator()">
                        {{ $t('selectPageAndCategory').toLowerCase() }}
                    </b-link>
                </b-alert>
                <WidgetConfigurator ref="widget-configurator" v-model="widgetPreviewData" class="widget-configurator" @changeProduct="changeProduct()" />
            </div>
            <div v-if="!isPopoverOrOverlay" class="d-flex flex-column gap-3">
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="100%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="25%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="25%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
            </div>
            <div v-else class="d-flex flex-column gap-3">
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="35%" height="20px" />
                    <WidgetPreview v-bind="widgetPreviewData" :reload-delay="0" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="25%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="25%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="25%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="25%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="25%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="25%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="25%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="25%" height="20px" />
                </div>
                <div class="d-flex gap-3">
                    <b-skeleton animation="" width="40%" height="20px" />
                    <b-skeleton animation="" width="40%" height="20px" />
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    import debounce from 'lodash/debounce';
    import WidgetPreview from '@/components/common/WidgetPreview.vue';
    import WidgetConfigurator from '@/components/common/WidgetConfigurator.vue';
    import ProductSearch from '@/components/common/ProductSearch.vue';

    export default {
        name: 'WidgetPreviewPage',
        components: {
            WidgetPreview,
            WidgetConfigurator,
            ProductSearch,
        },
        data() {
            return {
                widgetPreviewData: {},
                showChangeProduct: false,
                updateAppearanceDebounce: () => {},
            };
        },
        computed: {
            isPopoverOrOverlay() {
                const type = this.widgetPreviewData['bb-type'];
                return type === 'popover' || type === 'overlay';
            },
            widgetData() {
                const config = this.widgetPreviewData || {};
                return {
                    buyboxId: config['bb-id'],
                    buttonLabel: config['bb-button-label'],
                    leadColor: config['bb-lead-color'],
                    rowCount: config['bb-row-count'],
                    showPrices: config['bb-show-prices'],
                    showProduct: config['bb-show-extra'],
                };
            },
            showProductSelect() {
                return this.showChangeProduct || !this.widgetPreviewData?.['bb-offer-id'];
            },
        },
        watch: {
            showProductSelect: {
                handler(value) {
                    if (!value) {
                        setTimeout(this.expandWidgetConfigurator, 100);
                    }
                },
            },
            widgetPreviewData: {
                handler: 'saveWidgetPreviewData',
            },
            widgetData(newVal, oldVal) {
                if (oldVal.buyboxId && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                    this.updateAppearanceDebounce();
                }
            },
        },
        methods: {
            updateData() {
                const wpData = this.$localStorage.get('widget-preview', {});
                this.widgetPreviewData = { ...this.widgetPreviewData, ...wpData };
            },
            onStorageUpdate(event) {
                if (event.key === 'buybox-widget-preview' && JSON.stringify(this.widgetPreviewData) !== event.newValue) {
                    this.updateData();
                }
            },
            saveWidgetPreviewData() {
                const wpData = this.$localStorage.get('widget-preview', null);
                if (JSON.stringify(this.widgetPreviewData) !== JSON.stringify(wpData)) this.$localStorage.set('widget-preview', this.widgetPreviewData);
            },
            removeWidgetPreviewData() {
                this.$localStorage.remove('widget-preview');
            },
            updateAppearance() {
                if (this.widgetData.buyboxId) this.$store.dispatch('plugin/updateAppearance', this.widgetData);
            },
            scrollToWidget() {
                if (this.showProductSelect || this.isPopoverOrOverlay) return;
                const element = document.getElementById('bb-widget-preview');
                element?.scrollIntoView(true);
            },
            expandWidgetConfigurator() {
                this.$refs['widget-configurator']?.toggleActive(true);
            },
            setProduct(product) {
                this.widgetPreviewData = {
                    ...this.widgetPreviewData,
                    'bb-offer-id': product.offerId,
                    product,
                };
                this.showChangeProduct = false;
            },
            changeProduct() {
                this.showChangeProduct = true;
            },
            widgetReady() {
                setTimeout(this.scrollToWidget, 500);
            },
            openWidgetGenerator() {
                const url = this.$router.resolve({ name: 'WidgetGenerator' }).href;
                window.open(url, 'widget-generator');
            },
        },
        created() {
            this.updateData();
            this.updateAppearanceDebounce = debounce(this.updateAppearance, 500, false);
            document.body.parentNode.classList.add('page-widget-preview');
        },
        mounted() {
            window.addEventListener('storage', this.onStorageUpdate);
            document.addEventListener('bb-loaded', this.widgetReady);
            document.addEventListener('bb-no-data', this.widgetReady);
            setTimeout(this.expandWidgetConfigurator, 1000);
        },
        beforeDestroy() {
            this.removeWidgetPreviewData();
            window.removeEventListener('storage', this.onStorageUpdate);
            document.removeEventListener('bb-loaded', this.widgetReady);
            document.removeEventListener('bb-no-data', this.widgetReady);
            document.body.parentNode.classList.remove('page-widget-preview');
        },
    };
</script>



<style>
    html.page-widget-preview {
        scroll-behavior: smooth;
    }
</style>

<style lang="scss" scoped>
    .wrapper-container {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background: white;
        position: relative;
        display: flex;
        justify-content: center;

        .wrapper-container-inner {
            max-width: map-get($container-max-widths, 'lg');
            flex-grow: 1;
        }
    }

    .b-skeleton {
        cursor: default;
        margin: 0;
        border-radius: 0;
    }

    .widget-preview {
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;

        .widget-type-popover,
        .widget-type-overlay {
            ::v-deep {
                #bb-widget-bb-widget-preview {
                    display: none;
                }
            }
        }

        ::v-deep {
            .bb-widget {
                .preview-link {
                    color: unset;
                    text-decoration: none;
                }
            }
        }
    }

    .widget-configurator {
        position: fixed;
        z-index: 100;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        ::v-deep {
            .panel {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
</style>
