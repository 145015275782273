<script>
    import { BFormFile } from 'bootstrap-vue';
    import { i18n } from '@/i18n/i18n';

    export default {
        name: 'FormFile',
        extends: BFormFile,
        props: {
            browseText: {
                type: String,
                default: i18n.t('selectFile'),
            },
            placeholder: {
                type: String,
                default: i18n.t('noFilesSelected'),
            },
            showRemoveBtn: {
                type: Boolean,
                default: true,
            },
            showThumbnails: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            onRemove() {
                this.$emit('input', null);
                this.$emit('change', null);
                this.$emit('remove', null);
            },
            defaultFileNameFormatter(flattenedFiles, clonedFiles, fileNames) {
                if (this.showThumbnails) {
                    const $thumbnail = file =>
                        this.$createElement(
                            'img',
                            {
                                staticClass: 'form-file-thumbnail mr-2',
                                attrs: {
                                    src: window.URL.createObjectURL(file),
                                },
                                style: {
                                    maxWidth: '20px',
                                    maxHeight: '20px',
                                },
                            },
                            ['']
                        );
                    return this.flattenedFiles.map(file =>
                        this.$createElement(
                            'span',
                            {
                                staticClass: 'form-file-item',
                            },
                            [$thumbnail(file), file.name]
                        )
                    );
                }
                return fileNames.join(', ');
            },
        },
        render(h) {
            const { custom, plain, size, dragging, stateClass, bvAttrs } = this;

            // Form Input
            const $input = h('input', {
                class: [
                    {
                        'form-control-file': plain,
                        'custom-file-input': custom,
                        focus: custom && this.hasFocus,
                    },
                    stateClass,
                ],
                // With IE 11, the input gets in the "way" of the drop events,
                // so we move it out of the way by putting it behind the label
                // Bootstrap v4 has it in front
                style: custom ? { zIndex: -5 } : {},
                attrs: this.computedAttrs,
                on: {
                    change: this.onChange,
                    focusin: this.focusHandler,
                    focusout: this.focusHandler,
                    reset: this.reset,
                },
                ref: 'input',
            });

            if (plain) {
                return $input;
            }

            const $removeBtn = h(
                'button',
                {
                    staticClass: 'form-remove-btn btn btn-light',
                    on: {
                        click: this.onRemove,
                    },
                },
                ['x']
            );

            // Overlay label
            const $label = h(
                'div',
                {
                    staticClass: 'custom-file-label',
                    class: { dragging },
                },
                [
                    h(
                        'span',
                        {
                            staticClass: 'd-flex align-items-center form-file-text',
                            // `pointer-events: none` is used to make sure
                            // the drag events fire only on the label
                            // style: { pointerEvents: 'none' },
                        },
                        [this.labelContent]
                    ),
                    this.showRemoveBtn && this.value && !this.disabled ? $removeBtn : null,
                    h(
                        'label',
                        {
                            staticClass: 'form-browse-text',
                            attrs: {
                                for: this.safeId(),
                                'data-browse': this.browseText || null,
                            },
                        },
                        [this.browseText]
                    ),
                ]
            );

            // Return rendered custom file input
            return h(
                'div',
                {
                    staticClass: 'custom-file b-form-file',
                    class: [{ [`b-custom-control-${size}`]: size }, stateClass, bvAttrs.class],
                    style: bvAttrs.style,
                    attrs: { id: this.safeId('_BV_file_outer_') },
                    on: {
                        dragenter: this.onDragenter,
                        dragover: this.onDragover,
                        dragleave: this.onDragleave,
                        drop: this.onDrop,
                    },
                },
                [$input, $label]
            );
        },
    };
</script>



<style lang="scss" scoped>
    @import 'bootstrap/scss/mixins';

    .custom-file {
        cursor: default;

        .custom-file-input {
            cursor: default;

            &:disabled ~ .custom-file-label {
                .form-file-text {
                    background: $gray-200;
                }
            }
        }

        .custom-file-label {
            cursor: default;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;

            .form-file-text {
                pointer-events: none;
                padding: $custom-file-padding-y $custom-file-padding-x;
                flex-grow: 1;
                overflow: hidden;
            }

            .form-browse-text {
                padding: $custom-file-padding-y $custom-file-padding-x;
                line-height: $custom-file-line-height;
                color: $custom-file-button-color;
                @include gradient-bg($custom-file-button-bg);
                border-left: inherit;
                @include border-radius(0 $custom-file-border-radius $custom-file-border-radius 0);
                margin: 0;
            }

            .form-file-item {
                display: flex;
                align-items: center;
                margin-right: 0.5rem;
            }

            .form-file-thumbnail {
                border: 1px solid #ccc;
            }

            .form-remove-btn {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
                padding: 0;
                margin-left: $custom-file-padding-x;
                margin-right: $custom-file-padding-x;
                cursor: pointer;
            }

            &::after {
                display: none;
            }
        }
    }
</style>
