<template>
    <div>
        <PageHeader>
            {{ $t('settlementsWithPublishers') }}
        </PageHeader>
        <Filters ref="Filters" />
        <Results ref="Results" />
    </div>
</template>



<script>
    import { format as dateFormat, addDays } from 'date-fns';
    import viewFiltersMixin from '@/mixins/viewFiltersMixin';
    import Filters from './parts/Filters.vue';
    import Results from './parts/Results.vue';

    const DATE_FORMAT = 'yyyy-MM-dd';
    const DEFAULT_FILTERS_PARAMS = Object.freeze({
        publishers: [],
        startDate: dateFormat(addDays(Date.now(), -30), DATE_FORMAT),
        endDate: dateFormat(Date.now(), DATE_FORMAT),
        statuses: [0, 1],
        types: ['bill', 'invoice'],
    });

    export default {
        name: 'FinanceSettlementsPublishers',
        mixins: [viewFiltersMixin],
        components: {
            Filters,
            Results,
        },
        data() {
            return {
                ...this.initParams(DEFAULT_FILTERS_PARAMS),
                request: this.createRequest('finance/publisherFinanceDocuments'),
            };
        },
    };
</script>
