<template>
    <DataTable
        :header="$t('campaignsList')"
        :fields="fields"
        :items="parentComponent.items"
        :loading="parentComponent.loading"
        :error="parentComponent.error"
        :total-rows="parentComponent.requestData.max"
        :current-page.sync="parentComponent.tableParams.page"
        :per-page.sync="parentComponent.tableParams.perPage"
        :sort-by.sync="parentComponent.tableParams.sort"
        :sort-direction.sync="parentComponent.tableParams.order"
        no-sort-reset
        no-local-sorting
    >

        <template #cell(name)="data">
            <b-link :to="createItemUrl(data.item)">
                {{ data.item.name }}
            </b-link>
        </template>

        <template #cell(advertiser)="data">
            <template v-if="data.value">
                <b-link :to="{ name: 'UsersDetails', params: { id: data.item.advertiser.id } }">
                    {{ data.item.advertiser.name }}
                </b-link>
            </template>
        </template>

        <template #cell(categories)="data">
            <ul class="m-0 p-0">
                <li v-for="(category, index) in data.value" :key="index">
                    {{ category.name }}
                </li>
            </ul>
        </template>

        <template #cell(commissions)="data">
            <ul class="m-0 p-0">
                <li v-for="(commission, index) in data.value" :key="index">
                    <template v-if="commission.type === 'amount'">{{ `CPS ${$format.currency(commission.commission)} (${$format.currency(commission.adminCommission)}) - ${commission.description || ''}` }}</template>
                    <template v-if="commission.type === 'percent'">{{ `CPS ${$format.percent(commission.commission)} (${$format.percent(commission.adminCommission)}) - ${commission.description || ''}` }}</template>
                </li>
            </ul>
        </template>

        <template #cell(active)="data">
            <BadgeBoolean :value="data.item.isActive" />
        </template>

        <template #cell(hidden)="data">
            <BadgeBoolean :value="data.item.hidden" true-value-badge-variant="danger" false-value-badge-variant="success" />
        </template>

        <template #cell(action)="data">
            <ButtonIcon size="sm" :to="createItemUrl(data.item)">
                {{ $t('show') }}
            </ButtonIcon>
        </template>
    </DataTable>
</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';
    import BadgeBoolean from '@/components/common/BadgeBoolean.vue';

    export default {
        name: 'Results',
        components: {
            DataTable,
            BadgeBoolean,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {};
        },
        computed: {
            fields() {
                return [
                    {
                        key: 'id',
                        label: this.$t('id'),
                        sortable: true,
                    },
                    {
                        key: 'name',
                        label: this.$t('name'),
                        sortable: true,
                    },
                    {
                        key: 'advertiser',
                        label: this.$t('advertiser'),
                    },
                    {
                        key: 'categories',
                        label: this.$t('categories'),
                    },
                    {
                        key: 'commissions',
                        label: this.$t('conditions'),
                    },
                    {
                        key: 'active',
                        label: this.$t('active'),
                    },
                    {
                        key: 'hidden',
                        label: this.$t('hidden'),
                    },
                    {
                        key: 'action',
                        label: '',
                    },
                ];
            },
        },
        methods: {
            createItemUrl(item) {
                const id = item.id;
                return {
                    name: 'CampaignsDetails',
                    params: { id, parentUrl: this.$route.fullPath },
                    query: {},
                };
            },
        },
    };
</script>
