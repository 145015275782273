<template>
    <DataTable
        hover
        :error="error"
        :items="items"
        :fields="fields"
        :total-rows="transactions.max"
        :per-page.sync="parentComponent.perPage"
        :sort-by.sync="parentComponent.sortBy"
        :sort-direction.sync="parentComponent.sortDirection"
        :current-page.sync="parentComponent.currentPage"
        :loading="loading"
        :no-sort-reset="true"
    >
        <template #head(select)>
            <div class="position-relative w-100 h-100 cursor-pointer" @click="selectAll = !selectAll">
                <div class="position-absolute w-100 h-100 z-index-10"></div>
                <b-form-checkbox class="z-index-1" :checked="selectAll" readonly @click.native.prevent />
            </div>
        </template>
        <template #table-busy>
            <div class="d-flex justify-content-center my-2">
                <b-spinner />
            </div>
        </template>

        <template #head(createdAt)>
            {{ $t('date') }} <br> {{ $t('hour') }}
        </template>
        <template #head(transId)>
            {{ $t('identifier') }} <br> {{ $t('status') }}
        </template>
        <template #cell(select)="data">
            <b-spinner v-if="isItemBusy(data.item.id)" small />
            <b-form-checkbox v-else-if="data.item.status === STATUSES.NEW" v-model="selected" :value="data.item.id" />
        </template>
        <template #cell(createdAt)="data">
            {{ $format.dateAndHour(data.value) }}
        </template>
        <template #cell(transId)="data">
            <Ellipsis :max-width="200" :max-lines="2">
                {{ data.item.transId }}
            </Ellipsis>
            <TransactionStatusBadge v-if="data.item.status" :status="data.item.status" />
        </template>
        <template #cell(advertiserCommission)="data">
            {{ $format.currency(data.value) }}
        </template>
        <template #cell(amount)="data">
            {{ $format.currency(data.value) }}
        </template>
        <template #cell(conversionTime)="data">
            {{ $format.seconds(data.value) }}
        </template>
        <template #cell(action)="data">
            <b-button variant="success" @click="previewItem(data.item)">
                {{ $t('details') }}
            </b-button>
        </template>
        <template #afterTable>
            <div class="d-flex align-items-center mt-3">
                <span>{{ $t('transactions.withSelected') }}</span>
                <span class="mx-2"></span>
                <Multiselect
                    v-model="action"
                    :options="actionOptions"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    :disabled="isItemsBusy"
                    class="width-200 d-inline-block"
                />
                <span class="mx-2"></span>
                <b-button variant="primary" :disabled="!action || !action.value || !items.length || loading || isItemsBusy" @click="changeTransactionsStatus()">
                    {{ $t('save') }}
                </b-button>
            </div>
        </template>
    </DataTable>
</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';
    import TransactionStatusBadge from '@/components/common/TransactionStatusBadge.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import Ellipsis from '@/components/common/Ellipsis.vue';
    import transactionsService, { STATUSES } from '@/services/transactionsService';

    export default {
        name: 'Results',
        components: {
            DataTable,
            TransactionStatusBadge,
            Multiselect,
            Ellipsis,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                STATUSES,
                fields: [
                    {
                        key: 'select',
                        label: '',
                    },
                    {
                        key: 'createdAt',
                        label: this.$t('date'),
                        sortable: true,
                    },
                    {
                        key: 'transId',
                        label: this.$t('identifier'),
                        sortable: true,
                    },
                    {
                        key: 'space.name',
                        label: this.$t('space'),
                    },
                    {
                        key: 'amount',
                        label: this.$t('transactions.transactionAmount'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'advertiserCommission',
                        label: this.$t('commission'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'conversionTime',
                        label: this.$t('conversionTime'),
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'action',
                        label: '',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ],
                selectAll: false,
                selected: [],
                action: null,
                actionOptions: [
                    { value: null, text: this.$t('doNothing') },
                    { value: 'accept', text: this.$t('acceptTransactions') },
                    { value: 'reject', text: this.$t('rejectTransactions') },
                ],
                busyItems: [],
            };
        },
        computed: {
            currentCampaign() {
                return this.$store.getters['campaigns/getCurrentCampaign'];
            },
            transactions() {
                return this.$store.getters['transactions/getUserTransactions'];
            },
            items() {
                return this.transactions.items || [];
            },
            error() {
                return this.$store.getters['transactions/getError']('userTransactions');
            },
            loading() {
                return this.$store.getters['transactions/getLoading']('userTransactions');
            },
            isItemBusy() {
                return id => !!this.busyItems.find(itemId => itemId === id);
            },
            isItemsBusy() {
                return !!this.busyItems.length;
            },
        },
        watch: {
            selected() {
                if (this.selected.length) this.$toastr.close('noTransactionsSelected');
            },
            isItemsBusy() {
                this.parentComponent.isItemsBusy = this.isItemsBusy;
            },
            selectAll() {
                if (this.selectAll) {
                    this.selected = this.items.filter(item => item.status === STATUSES.NEW).map(item => item.id);
                } else {
                    this.selected = [];
                }
            },
        },
        methods: {
            previewItem(item) {
                this.$root.$emit('bv::show::modal', `modal-preview-${this.parentComponent.modalItemName}`);
                this.$root.$emit(`preview-${this.parentComponent.modalItemName}`, item);
            },
            async changeTransactionsStatus() {
                if (!this.selected.length) {
                    this.$toastr.info(this.$t('noTransactionsSelected'), null, { id: 'noTransactionsSelected' });
                } else if (this.action.value) {
                    await this.transactionAction(this.action.value);
                }
            },
            async transactionAction(action) {
                this.busyItems = [...this.selected];
                try {
                    await transactionsService.postTransactionsAction({ urlParams: { action, campaignId: this.currentCampaign.id, userRole: 'advertiser' }, requestParams: { transactions: this.selected } });
                    this.selected = [];
                    this.parentComponent.$refs.Filters.fetchTransactions({ cache: false });
                } catch (error) {
                    this.$toastr.error(error.message);
                }
                this.busyItems = [];
            },
            addBusyItem(id) {
                this.busyItems.push(id);
            },
            removeBusyItem(id) {
                const newSet = new Set(this.busyItems);
                newSet.delete(id);
                this.busyItems = Array.from(newSet);
            },
        },
    };
</script>
