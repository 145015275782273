<template>
    <b-overlay :show="loading && product" rounded="sm" class="h-100">
        <Card :header="$t('priceAndAvailability')" class="m-0 shadow-none">
            <div>
                <ErrorMessage :value="error" />
                <FormValidator :validator="$v.form.price" form-group v-slot="slotProps" :label="$t('price')" label-cols-sm="3">
                    <b-form-input v-model.number="form.price" :disabled="!product || isSubmitting" :state="slotProps.state" />
                </FormValidator>
                <FormValidator :validator="$v.form.active" form-group v-slot="slotProps" :label="$t('availability')" label-cols-sm="3">
                    <b-form-radio-group v-model="form.active" :options="availableOptions" :disabled="!product || isSubmitting" :state="slotProps.state" class="h-100 d-flex align-items-center" />
                </FormValidator>
                <b-form-group>
                    <ButtonIcon :loading="isSubmitting" :disabled="isButtonDisabled" @click="submit()">
                        {{ $t('saveChanges') }}
                    </ButtonIcon>
                </b-form-group>
                <b-form-text><i>{{ $t('priceAndAvailabilityWillBeOverwritten') }}</i></b-form-text>
            </div>
        </Card>
    </b-overlay>
</template>



<script>
    import { required, decimal } from 'vuelidate/lib/validators';
    import FormValidator from '@/components/common/FormValidator.vue';
    import toastService from '@/services/toastService';

    export default {
        name: 'Edit',
        components: {
            FormValidator,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        validations: {
            form: {
                price: { required, decimal },
                active: { required },
            },
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.id),
                form: {
                    price: null,
                    active: null,
                },
                isSubmitting: false,
            };
        },
        computed: {
            product() {
                return this.parentComponent.product;
            },
            apiParams() {
                return {
                    requestParams: { price: this.form.price, active: this.form.active },
                    urlParams: { offerId: this.id },
                };
            },
            availableOptions() {
                return [
                    { text: this.$t('available'), value: true },
                    { text: this.$t('unavailable'), value: false },
                ];
            },
            error() {
                return this.$store.getters['offers/getError']('offer')?.message;
            },
            loading() {
                return this.parentComponent.loading;
            },
            isButtonDisabled() {
                return !this.product || this.parentComponent.$refs.PriceChart.loading || this.parentComponent.$refs.AvailabilityChart.loading;
            },
        },
        watch: {
            product: {
                immediate: true,
                deep: true,
                handler() {
                    this.initFormData();
                },
            },
        },
        methods: {
            initFormData() {
                if (this.product) {
                    if (this.form.price === null) this.form.price = this.product.price;
                    if (this.form.active === null) this.form.active = this.product.active;
                }
            },
            resetForm() {
                this.initFormData();
                this.$v.$reset();
            },
            async submit() {
                this.$v.$touch();
                if (this.$v.$invalid) return;
                this.isSubmitting = true;
                const product = await this.$store.dispatch('offers/patchOffer', this.apiParams).finally(() => {
                    this.isSubmitting = false;
                    this.$v.$reset();
                });
                if (!this.error) {
                    this.resetForm();
                    toastService.successToast(this.$t('dataSavedSuccessfully'));
                    if (this.product.price !== this.form.price) {
                        this.parentComponent.$refs.PriceChart.fetchData();
                    }
                    if (this.product.active !== this.form.active) {
                        this.parentComponent.$refs.PriceChart.fetchData();
                        this.parentComponent.$refs.AvailabilityChart.fetchData();
                    }
                    this.$store.commit('offers/setOffer', product);
                }
            },
        },
    };
</script>
