<template>
    <b-overlay :show="loading && product" rounded="sm" class="h-100">
        <Card class="m-0 shadow-none h-100">
            <h4 class="header-title mt-0 mb-1">
                <span v-if="product">{{ product.name }}</span>
                <b-skeleton v-if="!product" animation="wave" width="80%" height="20px" class="mb-0 d-inline-block" />
            </h4>
            <div v-if="product && product.ean" class="mt-2 sub-header">
                <div class="text-secondary">
                    <span class="font-weight-bold">{{ $t('ean') }}:</span> <span>{{ product.ean }}</span>
                </div>
            </div>
            <table class="table b-table mb-0">
                <tbody>
                    <tr>
                        <td class="font-weight-bold">{{ $t('info') }}</td>
                        <td class="w-100">
                            <span v-if="product">{{ product.info }}</span>
                            <b-skeleton v-if="!product" animation="wave" width="40%" class="mb-0 d-inline-block" />
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">{{ $t('offerId') }}</td>
                        <td class="w-100">
                            <span v-if="product">{{ product.id }}</span>
                            <b-skeleton v-if="!product" animation="wave" width="10%" class="mb-0 d-inline-block" />
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">{{ $t('idOfShop') }}</td>
                        <td class="w-100">
                            <span v-if="product">{{ product.offerId }}</span>
                            <b-skeleton v-if="!product" animation="wave" width="70%" class="mb-0 d-inline-block" />
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">{{ $t('feedShop') }}</td>
                        <td class="w-100">
                            <span v-if="product">{{ product.feed }}</span>
                            <b-skeleton v-if="!product" animation="wave" width="20%" class="mb-0 d-inline-block" />
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">{{ $t('code') }}</td>
                        <td class="w-100">
                            <span v-if="product">{{ product.code }}</span>
                            <b-skeleton v-if="!product" animation="wave" width="15%" class="mb-0 d-inline-block" />
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">{{ $t('url') }}</td>
                        <td class="w-100">
                            <span v-if="product"><b-link :href="product.url" target="_blank">{{ product.url }}</b-link></span>
                            <b-skeleton v-if="!product" animation="wave" width="80%" class="mb-0 d-inline-block" />
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">{{ $t('imageUrl') }}</td>
                        <td class="w-100">
                            <span v-if="product"><b-link :href="product.image" target="_blank">{{ product.image }}</b-link></span>
                            <b-skeleton v-if="!product" animation="wave" width="80%" class="mb-0 d-inline-block" />
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">{{ $t('category') }}</td>
                        <td class="w-100">
                            <span v-if="product"><List :items="product.categories" /></span>
                            <b-skeleton v-if="!product" animation="wave" width="60%" class="mb-0 d-inline-block" />
                        </td>
                    </tr>
                    <tr>
                        <td class="font-weight-bold">{{ $t('updated') }}</td>
                        <td class="w-100">
                            <span v-if="product">{{ dateUtil.formatDate(product.updatedAt, 'yyyy-MM-dd HH:mm') }} (feed)</span>
                            <b-skeleton v-if="!product" animation="wave" width="30%" class="mb-0 d-inline-block" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </Card>
    </b-overlay>
</template>



<script>
    import Card from '@/components/common/Card.vue';
    import List from '@/components/common/List.vue';
    import dateUtil from '@/utils/date';

    export default {
        name: 'Details',
        components: {
            Card,
            List,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                dateUtil,
            };
        },
        computed: {
            product() {
                return this.parentComponent.product;
            },
            loading() {
                return this.parentComponent.loading;
            },
        },
    };
</script>
