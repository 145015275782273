const googleAuth = {
    init(config) {
        if (config === undefined) {
            console.error('Undefined config for Google API! Initialized failed!');
            return;
        }
        window.googleInitClient = () => {
            const initClient = () =>
                new Promise((resolve, reject) => {
                    const client = window.google.accounts.oauth2.initCodeClient({
                        client_id: config.client_id,
                        scope: 'https://www.googleapis.com/auth/userinfo.email',
                        ux_mode: 'popup',
                        prompt: 'select_account',
                        redirect_uri: 'postmessage',
                        callback: resolve,
                        error_callback: reject,
                    });
                    window.googleClient = client;
                });
            window.googleClientRequestCallback = initClient();
        };
        const el = document.getElementById('google_auth_script');
        const createScript = () => {
            const providerScript = document.createElement('script');
            providerScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
            providerScript.setAttribute('async', true);
            providerScript.setAttribute('defer', 'defer');
            providerScript.setAttribute('id', 'google_auth_script');
            // providerScript.setAttribute('onload', 'window.googleInitClient()');
            document.head.appendChild(providerScript);
        };
        if (!el) createScript();
    },
    signIn() {
        window.googleInitClient();
        window.googleClient.requestCode();
        return window.googleClientRequestCallback;
    },
    signOut() {
        return window.google.accounts.id.disableAutoSelect();
    },
    isSignedIn() {},
    getCurrentUser() {},
};

export default googleAuth;
