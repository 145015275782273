<template>
    <div class="badge" :class="statusClass">
        {{ label }}
    </div>
</template>



<script>
    export default {
        name: 'TaskStatusBadge',
        props: {
            status: {
                type: [String, Number],
                required: true,
            },
            label: {
                type: String,
                default: null,
            },
        },
        computed: {
            statusClass() {
                const status = parseInt(this.status);
                if (status === 0) return 'badge-soft-info';
                if (status === 1) return 'badge-soft-primary';
                if (status === 2) return 'badge-soft-success';
                if (status === 3) return 'badge-soft-danger';

                return 'badge-soft-dark';
            },
        },
    };
</script>
