<template>
    <div>
        <ButtonIcon class="ml-auto" variant="primary" @click="back()">
            {{ $t('back') }}
        </ButtonIcon>

        <br>

        <h4 class="header-title mt-0 mb-4 d-flex align-items-center">
            {{ $t('feed') }}
        </h4>

        CREATE

        <ButtonIcon class="ml-auto" variant="success" @click="save()">
            {{ $t('save') }}
        </ButtonIcon>

    </div>
</template>



<script>
    export default {
        name: 'AdminCampaignsFeedCreate',
        data() {
            return {};
        },
        computed: {
            parentRoute() {
                return this.$route.matched.slice(-2)[0];
            },
        },
        methods: {
            back() {
                this.$router.replace({ name: 'CampaignsDetailsTab', params: { ...this.parentRoute.params } });
            },
            save() {},
        },
    };
</script>



<style lang="scss" scoped></style>
