<template>
    <Card :header="$t('csvImport')">
        <ErrorMessage :value="error" />
        <FormValidator
            :validator="$v.form.campaign"
            form-group
            v-slot="slotProps"
            :label="$t('campaign')"
            label-cols="12"
            class="form-item"
        >
            <Multiselect
                :value="form.campaign"
                :fetch-options-function="fetchCampaigns"
                :parse-options-function="parseCampaigns"
                :placeholder="$t('selectField.placeholder')"
                searchable
                track-by="id"
                label="name"
                select-label=""
                :disabled="busy"
                :state="slotProps.state"
                @inputTrackBy="form.campaign = $event"
                @loading="value => isCampaignsLoading = value"
            />
        </FormValidator>
        <FormValidator
            form-group
            :validator="$v.form.csv"
            v-slot="slotProps"
            :label="$t('file')"
            label-cols="12"
            class="form-item"
        >
            <FormFile
                v-model="form.csv"
                :disabled="busy"
                :state="slotProps.state"
                accept=".csv"
                :browse-text="$t('add')"
                :placeholder="$t('fileField.placeholder')"
            />
        </FormValidator>
        <ButtonIcon
            class="mr-auto width-min-150"
            variant="primary"
            :disabled="busy"
            :loading="busy"
            hide-label-while-loading
            @click="importCsv()"
        >
            {{ buttonLabel }}
        </ButtonIcon>
    </Card>
</template>



<script>
    import deepFreeze from 'deep-freeze';
    import cloneDeep from 'lodash/cloneDeep';
    import { required } from 'vuelidate/lib/validators';
    import { serialize as objectToFormData } from 'object-to-formdata';
    import spaceService from '@/services/spaceService';
    import FormValidator from '@/components/common/FormValidator.vue';
    import FormFile from '@/components/common/FormFile.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import createRequest from '@/utils/createRequest';
    import { i18n } from '@/i18n/i18n';

    const FORM_DEFAULT_DATA = deepFreeze({
        campaign: null,
        csv: null,
        separator: ';',
    });

    export default {
        name: 'FormCsv',
        components: {
            FormValidator,
            FormFile,
            Multiselect,
        },
        props: {
            requestEndpoint: {
                type: String,
                required: true,
            },
            buttonLabel: {
                type: String,
                default: i18n.t('import'),
            },
        },
        data() {
            return {
                form: cloneDeep(FORM_DEFAULT_DATA),
                request: createRequest(this.requestEndpoint),
            };
        },
        validations() {
            return {
                form: {
                    campaign: { required },
                    csv: { required },
                },
            };
        },
        computed: {
            busy() {
                return this.request.loading;
            },
            error() {
                return this.request.error;
            },
        },
        methods: {
            importCsv() {
                this.$v.form.$touch();
                if (this.$v.form.$invalid) return;
                this.request = createRequest(this.requestEndpoint);
                const params = { requestParams: objectToFormData(this.form), headers: { 'Content-Type': 'multipart/form-data' } };
                this.request
                    .post(params)
                    .then(() => {
                        this.$toastr.success(this.$t('transactionImportSuccessfully'));
                        this.form = cloneDeep(FORM_DEFAULT_DATA);
                        this.$v.form.$reset();
                        this.$emit('imported');
                    })
                    .catch(() => {});
            },
            fetchCampaigns() {
                return spaceService.fetchCampaigns();
            },
            parseCampaigns(data) {
                return data?.items || [];
            },
        },
    };
</script>
