<template>
    <div
        :tabindex="searchable ? -1 : tabindex"
        :class="{ 'multiselect--active': isOpen, 'multiselect--disabled': disabled || loading, 'multiselect--above': isAbove }"
        class="multiselect"
        @focus="activate()"
        @blur="searchable ? false : deactivate()"
        @keydown.self.down.prevent="pointerForward()"
        @keydown.self.up.prevent="pointerBackward()"
        @keypress.enter.tab.stop.self="addPointerElement($event)"
        @keyup.esc="deactivate()"
    >
        <slot name="clear" :search="search"></slot>
        <div ref="tags" class="multiselect__tags form-control h-auto" :class="{ 'is-valid': state === true, 'is-invalid': state === false }">
            <slot name="caret" :toggle="toggle">
                <div class="multiselect__select" @mousedown.prevent.stop="toggle()"></div>
            </slot>
            <slot
                name="selection"
                :search="search"
                :remove="removeElement"
                :values="visibleValues"
                :is-open="isOpen"
            >
                <div v-if="!showCheckboxes" v-show="visibleValues.length > 0" class="multiselect__tags-wrap">
                    <template v-for="(option, index) of visibleValues">
                        <slot
                            name="tag"
                            :option="option"
                            :search="search"
                            :remove="removeElement"
                        >
                            <span :key="index" class="multiselect__tag">
                                <span v-text="getSelectedOptionLabel(option)"></span>
                                <i
                                    aria-hidden="true"
                                    tabindex="1"
                                    class="multiselect__tag-icon"
                                    @keypress.enter.prevent="removeElement(option); onCloseEmitted()"
                                    @mousedown.prevent="removeElement(option); onCloseEmitted()"
                                ></i>
                            </span>
                        </slot>
                    </template>
                </div>
                <template v-if="showCheckboxes && visibleValues.length && !isOpen">
                    {{ $t('selectField.selectedOptions') }} ({{ visibleValues.length }})
                </template>
                <template v-if="internalValue && internalValue.length > limit && !isOpen">
                    <slot name="limit">
                        <span v-text="limitText(internalValue.length - limit)"></span>
                    </slot>
                </template>
            </slot>
            <transition name="multiselect__loading">
                <slot name="loading">
                    <div v-show="loading || optionsLoading" class="multiselect__spinner"></div>
                </slot>
            </transition>
            <input
                v-if="searchable"
                :id="id"
                ref="search"
                :name="name"
                type="text"
                autocomplete="nope"
                :placeholder="placeholder"
                :style="inputStyle"
                :value="search"
                :disabled="disabled || loading || optionsLoading"
                :tabindex="tabindex"
                class="multiselect__input"
                @input="updateSearch($event.target.value)"
                @focus.prevent="onSearchFocus()"
                @blur.prevent="onSearchBlur()"
                @keyup.esc="deactivate()"
                @keydown.down.prevent="pointerForward()"
                @keydown.up.prevent="pointerBackward()"
                @keypress.enter.prevent.stop.self="addPointerElement($event)"
                @keydown.delete.stop="removeLastElement()"
            >
            <span
                v-if="isSingleLabelVisible"
                class="multiselect__single"
                @mousedown.prevent="toggle"
            >
                <slot name="singleLabel" :option="singleValue">
                    {{ currentOptionLabel }}
                </slot>
            </span>
            <span
                v-if="isPlaceholderVisible"
                class="multiselect__placeholder nowrap"
                @mousedown.prevent="toggle"
            >
                <slot name="placeholder">
                    {{ placeholder }}
                </slot>
            </span>
        </div>
        <transition name="multiselect">
            <div v-show="isOpen" class="multiselect__content-container" :class="{'multiselect__content-container--checkboxes': showCheckboxes}">
                <div
                    v-if="showCheckboxes"
                    class="multiselect__select-all multiselect__select-all--main"
                    @focus.stop="activate()"
                    @mousedown.prevent
                    @click="search ? toggleAllFiltered() : toggleAll()"
                >
                    <div>
                        <div class="custom-control custom-checkbox">
                            <input
                                :checked="checkedAllFiltered"
                                :indeterminate.prop="indeterminateAllFiltered"
                                :disabled="!filteredOptions.length"
                                type="checkbox"
                                class="custom-control-input"
                            >
                            <div class="custom-control-label text-white">
                                <slot name="selectAllHeader" :values="visibleValues">
                                    {{ checkedAllFiltered ? $t('deselectAllVisible') : $t('selectAllVisible') }}
                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showCheckboxes" class="multiselect__search-inside">
                    <transition name="multiselect__loading">
                        <slot name="loadingInside">
                            <div v-show="loading || isDebounceOptionsLoading || isTyping" class="multiselect__spinner"></div>
                        </slot>
                    </transition>
                    <feather type="search" class="multiselect__search-inside-icon text-muted flex-shrink-0" />
                    <input
                        v-if="searchable"
                        ref="searchInside"
                        type="text"
                        autocomplete="nope"
                        :placeholder="searchPlaceholder"
                        :style="inputStyle"
                        :value="search"
                        :disabled="disabled"
                        :tabindex="tabindex"
                        class="multiselect__input"
                        @input="updateSearch($event.target.value)"
                        @blur.prevent="deactivate()"
                    >
                    <transition name="fade">
                        <button
                            v-show="search"
                            type="button"
                            class="btn btn-default text-muted multiselect__search-inside-clear-btn"
                            @focus.stop="activate()"
                            @mousedown.prevent
                            @click="clearSearch"
                        >
                            <feather type="x" size="24" />
                        </button>
                    </transition>
                </div>
                <div
                    ref="list"
                    class="multiselect__content-wrapper"
                    tabindex="-1"
                    :style="{ maxHeight: optimizedHeight + 'px' }"
                    @focus="activate"
                    @mousedown.prevent
                >
                    <ul class="multiselect__content" :style="contentStyle">
                        <slot name="beforeList"></slot>
                        <li v-if="multiple && max === internalValue.length">
                            <span class="multiselect__option">
                                <slot name="maxElements">Maximum of {{ max }} options selected. First remove a selected option to select another.</slot>
                            </span>
                        </li>
                        <template v-if="!max || internalValue.length < max">
                            <template v-for="(partOptions, partOptionsIndex) in [filteredOptions, selectedOptionsShown]">
                                <li
                                    v-for="(option, index) of partOptions"
                                    :key="`${partOptionsIndex}-${index}`"
                                    class="multiselect__element"
                                    :class="{'multiselect__element-selected': partOptionsIndex === 1}"
                                >
                                    <span
                                        v-if="!(option && (option.$isLabel || option.$isDisabled))"
                                        :class="{...optionHighlight(index, option), 'multiselect__option__checkbox': showCheckboxes, 'multiselect__option-selected': partOptionsIndex === 1, 'multiselect__option--disabled': !!option.$isDisabled}"
                                        :data-select="showCheckboxes ? '' : (option && option.isTag ? tagPlaceholder : selectLabelText)"
                                        :data-selected="showCheckboxes ? '' : selectedLabelText"
                                        :data-deselect="showCheckboxes ? '' : deselectLabelText"
                                        class="multiselect__option"
                                        @click.stop="select(option)"
                                        @mouseenter.self="pointerSet(index)"
                                    >
                                        <slot name="option" :option="option" :search="search">
                                            <div v-if="showCheckboxes" class="multiselect__checkbox-wrapper">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" :checked="isSelected(option)">
                                                    <div class="custom-control-label">
                                                        <span :title="getSelectedOptionLabel(option)">{{ getSelectedOptionLabel(option) }}</span>
                                                    </div>
                                                </div>
                                                <button type="button" class="btn btn-outline-primary btn-select-only" @click.stop="selectOnly(option)">{{ $t('only') }}</button>
                                            </div>
                                            <span v-else>{{ getSelectedOptionLabel(option) }}</span>
                                        </slot>
                                    </span>
                                    <span
                                        v-if="option && (option.$isLabel || option.$isDisabled)"
                                        :data-select="showCheckboxes ? '' : groupSelect && selectGroupLabelText"
                                        :data-deselect="showCheckboxes ? '' : groupSelect && deselectGroupLabelText"
                                        :class="{...groupHighlight(index, option), 'multiselect__option__checkbox': showCheckboxes, 'multiselect__option--disabled': !!option.$isDisabled}"
                                        class="multiselect__option"
                                        @mouseenter.self="groupSelect && pointerSet(index)"
                                        @mousedown.prevent="selectGroup(option)"
                                    >
                                        <slot name="option" :option="option" :search="search">
                                            <div v-if="showCheckboxes" class="multiselect__checkbox-wrapper">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" :checked="isSelected(option)">
                                                    <div class="custom-control-label">
                                                        <span :title="getSelectedOptionLabel(option)">{{ getSelectedOptionLabel(option) }}</span>
                                                    </div>
                                                </div>
                                                <button type="button" class="btn btn-light btn-select-only" @click.stop="selectOnly(option)">{{ $t('only') }}</button>
                                            </div>
                                            <span v-else>{{ getSelectedOptionLabel(option) }}</span>
                                        </slot>
                                    </span>
                                </li>
                                <template v-if="partOptionsIndex === 0">
                                    <li v-show="showNoOptions && (filteredOptions.length === 0 && !search && !loading)" key="noOptions"> <!-- eslint-disable-line -->
                                        <span class="multiselect__option multiselect__option--no-option">
                                            <slot name="noOptions">{{ noOptionsText }}</slot>
                                        </span>
                                    </li>
                                    <li v-show="showNoResults && (filteredOptions.length === 0 && search.length >= minCharToSearch && !loading && loadingOptionsComplete)" key="noResult"> <!-- eslint-disable-line -->
                                        <span class="multiselect__option multiselect__option--no-result">
                                            <slot name="noResult" :search="search">{{ searchNotFoundText }}</slot>
                                        </span>
                                    </li>
                                    <li v-show="asynchronous && search && search.length < minCharToSearch" key="minChar"> <!-- eslint-disable-line -->
                                        <span class="multiselect__option multiselect__option--min-char">
                                            <slot name="minChar" :search="search"><span v-text="minCharToSearchText(minCharToSearch)"></span></slot>
                                        </span>
                                    </li>
                                    <li v-show="asynchronous && isTyping && !showCheckboxes" key="debounceOptionsLoading"> <!-- eslint-disable-line -->
                                        <span class="multiselect__option multiselect__option--debounce-options-loading">
                                            <slot name="debounceOptionsLoading">
                                                {{ optionsLoadingText }} <div class="multiselect__spinner"></div>
                                            </slot>
                                        </span>
                                    </li>
                                    <li v-if="selectedOptionsShown.length" key="separator" class="multiselect__element multiselect__element--separator"> <!-- eslint-disable-line -->
                                        <div
                                            v-if="showCheckboxes"
                                            class="multiselect__select-all multiselect__select-all-selected rounded-0"
                                            @focus.stop="activate()"
                                            @mousedown.prevent
                                            @click="toggleAllSelected()"
                                        >
                                            <div>
                                                <div class="custom-control custom-checkbox">
                                                    <input
                                                        :checked="checkedAllSelected"
                                                        :indeterminate.prop="indeterminateAllSelected"
                                                        :disabled="!selectedOptionsShown.length"
                                                        type="checkbox"
                                                        class="custom-control-input"
                                                    >
                                                    <div class="custom-control-label">
                                                        <slot name="selectAllSelectedHeader" :values="visibleValues">
                                                            {{ selectedOptionsLabel }} ({{ selectedOptionsCount }})
                                                        </slot>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </template>
                        </template>
                        <slot name="afterList"></slot>
                    </ul>
                </div>
            </div>
        </transition>
    </div>
</template>



<script>
    import Multiselect from 'vue-multiselect';
    import isEqual from 'lodash/isEqual';
    import sortBy from 'lodash/sortBy';
    import uniqBy from 'lodash/uniqBy';
    import debounce from 'lodash/debounce';
    import cloneDeep from 'lodash/cloneDeep';
    import { i18n } from '@/i18n/i18n';

    function includes(string, query) {
        let str = string;
        /* istanbul ignore else */
        if (str === undefined) str = 'undefined';
        if (str === null) str = 'null';
        if (str === false) str = 'false';
        const text = str.toString().toLowerCase();
        return text.indexOf(query.trim()) !== -1;
    }

    function filterOptions(options, search, label, customLabel) {
        return options.filter(option => includes(customLabel(option, label), search));
    }

    function flattenOptions(values, label) {
        return options =>
            options.reduce((prev, curr) => {
                /* istanbul ignore else */
                if (curr[values] && curr[values].length) {
                    prev.push({
                        $groupLabel: curr[label],
                        $isLabel: true,
                    });
                    return prev.concat(curr[values]);
                }
                return prev;
            }, []);
    }

    function not(fun) {
        return (...params) => !fun(...params);
    }

    function isEmpty(opt) {
        if (opt === 0) return false;
        if (Array.isArray(opt) && opt.length === 0) return true;
        return !opt;
    }

    export default {
        name: 'Multiselect',
        extends: Multiselect,
        props: {
            options: {
                type: Array,
                default: () => [],
            },
            /**
             * Determines whether to show checkboxes.
             */
            showCheckboxes: {
                type: Boolean,
                default: false,
            },
            /**
             * Key to compare objects.
             */
            trackBy: {
                type: String,
                default: 'value',
            },
            /**
             * Label to be displayed when given value has no options loaded.
             */
            trackByLabel: {
                type: String,
                default: 'value',
            },
            /**
             * Label to look for in option Object.
             */
            label: {
                type: String,
                default: 'text',
            },
            /**
             * Equivalent to the `placeholder` attribute on a `<select>` input.
             */
            placeholder: {
                type: String,
                default: i18n.t('selectOption'),
            },
            /**
             * `placeholder` for the 'searchable' input.
             */
            searchPlaceholder: {
                type: String,
                default: i18n.t('selectField.searchPlaceholder'),
            },
            /**
             * String to show when pointing to an option
             */
            selectLabel: {
                type: String,
                default: i18n.t('selectField.selectLabel'),
            },
            /**
             * String to show when pointing to an option
             */
            selectGroupLabel: {
                type: String,
                default: i18n.t('selectField.selectGroupLabel'),
            },
            /**
             * Equivalent to the `placeholder` attribute on a `<select>` input.
             */
            selectedLabel: {
                type: String,
                default: i18n.t('selectField.selectedLabel'),
            },
            /**
             * String to show when pointing to an already selected option
             */
            deselectLabel: {
                type: String,
                default: i18n.t('selectField.deselectLabel'),
            },
            /**
             * String to show when pointing to an already selected option
             */
            deselectGroupLabel: {
                type: String,
                default: i18n.t('selectField.deselectGroupLabel'),
            },
            /**
             * Function that process the message shown when selected elements pass the defined limit.
             */
            limitText: {
                type: Function,
                default: count => i18n.t('selectField.limitText', { count }),
            },
            /**
             * String to show when the searched text was not found
             */
            searchNotFoundText: {
                type: String,
                default: i18n.t('selectField.searchNotFoundText'),
            },
            /**
             * String to show when the list of options is empty
             */
            noOptionsText: {
                type: String,
                default: i18n.t('selectField.noOptionsText'),
            },
            /**
             * String to show for the header previously selected options when multiselect is in asynchronous mode
             */
            selectedOptionsLabel: {
                type: String,
                default: i18n.t('selectField.previouslySelectedOptions'),
            },
            /**
             * Determines whether to display previously selected options
             */
            showSelected: {
                type: Boolean,
                default: false,
            },
            /**
             * Sets up asynchronous data retrieval for display in multiselect
             */
            asynchronous: {
                type: Boolean,
                default: false,
            },
            /**
             * Function that sends a query that returns data to be displayed in multiselect
             */
            fetchOptionsFunction: {
                type: Function,
                default: null,
            },
            /**
             * The function that will be used to process the data returned from the server to adjust it to the required format
             */
            parseOptionsFunction: {
                type: Function,
                default: null,
            },
            /**
             * Waiting time to send a query while typing a search term when multiselect is in asynchronous mode
             */
            debounceDelay: {
                type: Number,
                default: 750,
            },
            /**
             * Minimum number of characters to start a search
             */
            minCharToSearch: {
                type: Number,
                default: 3,
                validator: value => value > 0,
            },
            /**
             * String to show when the number of characters to start searching is too small
             */
            minCharToSearchText: {
                type: Function,
                default: min => i18n.t('selectField.minCharToSearchText', { min }),
            },
            /**
             * Selects the all options in initial state
             */
            preselectAll: {
                type: Boolean,
                default: false,
            },
            /**
             * State of field - useful for validation
             * @values null, false, true
             */
            state: {
                type: Boolean,
                default: null,
                validator: value => [null, false, true].includes(value),
            },
            /**
             * The string to be displayed before the data request is completed
             */
            optionsLoadingText: {
                type: String,
                default: i18n.t('selectField.optionsLoadingText'),
            },
            initialValue: undefined,
            customLabel: {
                type: Function,
                default(option, label) {
                    if (isEmpty(option)) return '';
                    if (typeof option === 'number' || typeof option === 'string') return option;
                    return label ? option[label] : option;
                },
            },
        },
        data() {
            return {
                modelValue: [],
                emittedInputData: null,
                debounceFetchOptions: null,
                fetchOptionsPromises: [],
                isDebounceOptionsLoading: false,
                loadedOptions: [],
                loadingOptionsComplete: true,
                internalSelectedOptions: [],
                isTyping: false,
                optionsLoading: false,
                valueOnOpen: null,
            };
        },
        computed: {
            internalValue() {
                return this.modelValue || this.modelValue === 0 ? (Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue]) : []; // eslint-disable-line
            },
            checkedAll() {
                return this.filteredOptions.length === this.internalValue.length;
            },
            indeterminateAll() {
                return !this.checkedAll && this.internalValue.length;
            },
            checkedAllFiltered() {
                return this.filteredOptions.length && this.filteredOptions.every(item => this.valueKeys.includes(this.getItemValue(item)));
            },
            checkedAllSelected() {
                return this.selectedOptionsShown.length && this.selectedOptionsShown.every(item => this.valueKeys.includes(this.getItemValue(item)));
            },
            indeterminateAllFiltered() {
                const anyOfFilteredIsSelected = this.filteredOptions.some(item => this.valueKeys.includes(this.getItemValue(item)));
                return !this.checkedAllFiltered && anyOfFilteredIsSelected;
            },
            indeterminateAllSelected() {
                const anyOfSelectedIsSelected = this.selectedOptionsShown.some(item => this.valueKeys.includes(this.getItemValue(item)));
                return !this.checkedAllSelected && anyOfSelectedIsSelected;
            },
            contentStyle() {
                // return this.filteredOptions.length ? { display: 'inline-block' } : { display: 'block' };
                return { display: 'block' };
            },
            selectedOptions() {
                const optionsValues = this.filteredOptions.map(this.getItemValue);
                const value = typeof this.value !== typeof this.modelValue ? [] : this.value;
                const uniqueValues = uniqBy([...this.internalSelectedOptions, ...[this.modelValue || []].flat(), ...[value || []].flat()], this.trackBy);
                const sortedValues = sortBy(uniqueValues, this.label);
                const filteredValues = sortedValues.filter(item => !optionsValues.includes(this.getItemValue(item)));
                return filteredValues;
            },
            selectedOptionsShown() {
                return this.showSelected ? this.selectedOptions : [];
            },
            selectedOptionsCount() {
                return this.selectedOptionsShown.length;
            },
            optionsFilteredPlusSelected() {
                return [...this.filteredOptions, ...this.selectedOptionsShown];
            },
            filteredOptions() {
                let options = this.options.concat();

                if (this.asynchronous && this.loadedOptions?.length) return this.loadedOptions;
                if (!this.asynchronous && typeof this.fetchOptionsFunction === 'function') options = this.loadedOptions.concat();
                // return Multiselect.mixins[0].computed.filteredOptions.bind(this)();

                const search = this.search || '';
                const normalizedSearch = search.toLowerCase().trim();

                /* istanbul ignore else */
                if (this.internalSearch) {
                    options = this.groupValues ? this.filterAndFlat(options, normalizedSearch, this.label) : filterOptions(options, normalizedSearch, this.label, this.customLabel);
                } else {
                    options = this.groupValues ? flattenOptions(this.groupValues, this.groupLabel)(options) : options;
                }

                options = this.hideSelected ? options.filter(not(this.isSelected)) : options;

                /* istanbul ignore else */
                if (this.taggable && normalizedSearch.length && !this.isExistingOption(normalizedSearch)) {
                    if (this.tagPosition === 'bottom') {
                        options.push({ isTag: true, label: search });
                    } else {
                        options.unshift({ isTag: true, label: search });
                    }
                }

                return options.slice(0, this.optionsLimit);
            },
        },
        watch: {
            isOpen() {
                if (!this.isOpen && this.showCheckboxes && this.showSelected) {
                    this.internalSelectedOptions = [...this.internalValue];
                    this.$refs.list.scrollTop = 0;
                }
            },
            value: {
                immediate: true,
                deep: true,
                handler() {
                    // this.modelValue = this.value;
                    this.initModelValue(this.value);
                    this.internalSelectedOptions = [];
                },
            },
            search() {
                if (this.asynchronous) {
                    this.loadedOptions = [];
                    this.loadingOptionsComplete = false;
                    this.isTyping = false;
                    if (this.search.length >= this.minCharToSearch) {
                        this.isTyping = true;
                        this.debounceFetchOptions?.();
                    } else {
                        this.fetchOptionsPromises = [];
                        this.isDebounceOptionsLoading = false;
                    }
                }
                if (this.showCheckboxes && this.showSelected) {
                    this.internalSelectedOptions = uniqBy([...this.internalSelectedOptions, ...this.internalValue], this.trackBy);
                    this.$refs.list.scrollTop = 0;
                }
            },
            fetchOptionsPromises(promises) {
                const promisesLength = promises.length;
                if (promisesLength) {
                    this.isDebounceOptionsLoading = true;
                    Promise.all(promises)
                        .then(values => {
                            if (promisesLength === this.fetchOptionsPromises.length && this.search) {
                                this.fetchOptionsPromises = [];
                                this.isDebounceOptionsLoading = false;
                                const lastResponse = values[values.length - 1];
                                this.loadedOptions = typeof this.parseOptionsFunction === 'function' ? this.parseOptionsFunction(lastResponse) : lastResponse || [];
                            }
                        })
                        .catch(error => {});
                }
            },
            isDebounceOptionsLoading(loading) {
                this.$emit('loading', loading);
                if (!loading) this.loadingOptionsComplete = true;
            },
            loadingOptionsComplete(loading) {
                if (loading) this.isTyping = false;
            },
            initialValue: {
                immediate: true,
                handler(newVal, oldVal) {
                    // Warunek sprawdzający, czy newVal i oldVal nie są undefined
                    if (newVal === undefined && oldVal === undefined) {
                        return;
                    }

                    // Sprawdzenie, czy wartości się zmieniły oraz czy fetchOptionsFunction jest funkcją
                    const isChanged = JSON.stringify(newVal) !== JSON.stringify(oldVal) && JSON.stringify(newVal) !== JSON.stringify(this.modelValue);
                    const isFetchOptionsFunction = typeof this.fetchOptionsFunction === 'function';
                    if (!isChanged || !isFetchOptionsFunction) {
                        return;
                    }

                    // Inicjalizacja modelValue i modelValueKeys
                    const modelValue = !Array.isArray(this.modelValue) && this.multiple ? [this.modelValue] : this.modelValue;
                    const modelValueKeys = this.trackBy && this.multiple ? modelValue.map(item => item[this.trackBy]) : modelValue;
                    const newInitialValue = Array.isArray(newVal) ? sortBy(newVal) : this.multiple ? [newVal] : newVal; // eslint-disable-line no-nested-ternary

                    // Sprawdzenie, czy modelValueKeys różni się od newInitialValue
                    if (JSON.stringify(modelValueKeys) === JSON.stringify(newInitialValue)) {
                        return;
                    }

                    // Definicja funkcji fnMap do przekształcenia elementów
                    const fnMap = item => {
                        if (item && typeof item === 'object') return item;
                        return { [this.trackBy]: item, [this.label]: this.getSelectedOptionLabel(item), isInitial: true };
                    };

                    // Pomocnicza funkcja do przetwarzania initialValue
                    const processInitialValue = initialValue => {
                        if (Array.isArray(initialValue)) {
                            return initialValue.map(fnMap);
                        }
                        return fnMap(initialValue);
                    };

                    // Przypisanie wartości do modelValue z uwzględnieniem trackBy i multiple
                    if (this.trackBy) {
                        if (this.multiple) {
                            this.modelValue = processInitialValue(Array.isArray(this.initialValue) ? this.initialValue : [this.initialValue]);
                        } else {
                            this.modelValue = this.initialValue === null || this.initialValue === undefined ? null : fnMap(this.initialValue);
                        }
                    } else if (this.multiple) {
                        this.modelValue = Array.isArray(this.initialValue) ? [...this.initialValue] : [this.initialValue];
                    } else {
                        this.modelValue = this.initialValue === null || this.initialValue === undefined ? null : this.initialValue;
                    }
                },
            },
        },
        methods: {
            getOptionLabel(option) {
                if (isEmpty(option)) return '';
                if (option.isTag) return option.label;
                if (option.$isLabel) return option.$groupLabel;
                let label;
                try {
                    label = this.customLabel(option, this.label);
                } catch (error) {
                    //
                }
                if (isEmpty(label) && option.isInitial !== undefined) {
                    return option[this.label];
                }
                if (isEmpty(label)) return '';
                return label;
            },
            updateLabelInInitialValue(option, isSelected, index) {
                const modelValueItem = this.multiple ? this.modelValue?.[index] : this.modelValue;
                if (!!this.initialValue && modelValueItem?.isInitial && isSelected && !!this.trackBy && !option.isInitial && modelValueItem?.[this.trackBy] === option[this.trackBy] && modelValueItem?.[this.label] !== option[this.label]) {
                    this.$set(modelValueItem, [this.label], this.getOptionLabel(option));
                    this.$set(modelValueItem, 'isInitial', false);
                }
            },
            isSelected(option) {
                const opt = this.trackBy ? option[this.trackBy] : option;
                const index = this.valueKeys.indexOf(opt);
                const isSelected = index > -1;
                this.updateLabelInInitialValue(option, isSelected, index);
                return isSelected;
            },
            getSelectedOptionLabel(option) {
                const label = this.getOptionLabel(option);
                if (!label && (typeof option === 'number' || typeof option === 'string')) {
                    if (this.trackByLabel) return `${this.trackByLabel}: ${option}`;
                    return option;
                }
                return label;
            },
            select(option, key) {
                /* istanbul ignore else */
                if (option.$isLabel && this.groupSelect) {
                    this.selectGroup(option);
                    return;
                }
                if (this.blockKeys.indexOf(key) !== -1 || this.disabled || option.$isDisabled || option.$isLabel) return;
                /* istanbul ignore else */
                if (this.max && this.multiple && this.internalValue.length === this.max) return;
                /* istanbul ignore else */
                if (key === 'Tab' && !this.pointerDirty) return;
                if (option.isTag) {
                    this.$emit('tag', option.label, this.id);
                    this.search = '';
                    if (this.closeOnSelect && !this.multiple) this.deactivate();
                } else {
                    const isSelected = this.isSelected(option);

                    if (isSelected) {
                        if (key !== 'Tab') this.removeElement(option);
                        return;
                    }

                    this.$emit('select', option, this.id);

                    const val = this.multiple ? [...this.internalValue, option] : option;
                    if (this.showCheckboxes && this.asynchronous) {
                        this.modelValue = val;
                    } else {
                        this.$emit('input', val, this.id);
                    }

                    /* istanbul ignore else */
                    if (this.clearOnSelect) this.search = '';
                }
                /* istanbul ignore else */
                if (this.closeOnSelect) this.deactivate();
            },
            onSearchFocus() {
                this.activate();
                if (this.showCheckboxes) this.$refs.searchInside.focus();
            },
            onSearchBlur() {
                if (!this.showCheckboxes) this.deactivate();
            },
            toggleAll() {
                const data = this.checkedAll ? [] : this.filteredOptions;
                this.$emit('input', data, this.id);
            },
            toggleAllFiltered() {
                const filteredOptionsIds = this.filteredOptions.map(this.getItemValue);
                const data = this.checkedAllFiltered ? this.internalValue.filter(item => !filteredOptionsIds.includes(this.getItemValue(item))) : [...this.internalValue, ...this.filteredOptions];
                if (this.checkedAllFiltered) this.$emit('remove', this.filteredOptions, this.id);
                this.$emit('input', data, this.id);
            },
            toggleAllSelected() {
                const selectedOptionsIds = this.selectedOptionsShown.map(this.getItemValue);
                const data = this.checkedAllSelected ? this.internalValue.filter(item => !selectedOptionsIds.includes(this.getItemValue(item))) : [...this.internalValue, ...this.selectedOptionsShown];
                if (this.checkedAllSelected) this.$emit('remove', this.selectedOptionsShown, this.id);
                this.$emit('input', data, this.id);
            },
            selectOnly(option) {
                this.$emit('input', [option], this.id);
            },
            clearSearch() {
                this.search = '';
            },
            onInputEmitted(value, id) {
                this.modelValue = value;
                if (this.trackBy) {
                    if (value === null) {
                        this.$emit('inputTrackBy', value);
                    } else if (typeof value === 'object') {
                        if (Array.isArray(value)) {
                            this.$emit(
                                'inputTrackBy',
                                value.map(item => item[this.trackBy])
                            );
                        } else {
                            this.$emit('inputTrackBy', value[this.trackBy]);
                        }
                    }
                }
                // this.initModelValue(value);
            },
            onOpenEmitted() {
                this.valueOnOpen = cloneDeep(this.value);
            },
            onCloseEmitted(value, id) {
                const sortKey = typeof this.value?.[0] === 'object' ? this.label : null;
                const changed1 = !isEqual(sortBy(this.value, sortKey), sortBy(this.modelValue, sortKey));
                const changed2 = !isEqual(sortBy(this.value, sortKey), sortBy(this.valueOnOpen, sortKey));
                if (changed1 || changed2) {
                    this.$emit('input', this.modelValue, id);
                    this.$emit('change', this.modelValue, id);
                }
                this.valueOnOpen = null;
            },
            initAsynchronous() {
                if (this.asynchronous && typeof this.fetchOptionsFunction === 'function') this.debounceFetchOptions = debounce(this.debounceHandler, this.debounceDelay);
                if (!this.asynchronous && typeof this.fetchOptionsFunction === 'function') this.fetchOptions();
            },
            debounceHandler() {
                if (this.search.length >= this.minCharToSearch) {
                    let response = null;
                    try {
                        response = this.fetchOptionsFunction(this.getOptionsFunctionParams());
                    } catch (error) {
                        this.$toastr.error(error.message);
                    }
                    this.fetchOptionsPromises.push(response);
                }
            },
            getItemValue(item) {
                if (this.trackBy && typeof item === 'object') return item[this.trackBy];
                return item;
            },
            getOptionsFunctionParams() {
                return {
                    search: this.search,
                    value: this.value,
                    modelValue: this.modelValue,
                };
            },
            async fetchOptions() {
                let response = null;
                try {
                    this.optionsLoading = true;
                    this.$emit('loading', true);
                    response = await this.fetchOptionsFunction({ ...this.getOptionsFunctionParams(), initialValue: this.initialValue });
                } catch (error) {
                    this.$toastr.error(error.message);
                } finally {
                    this.optionsLoading = false;
                    this.$emit('loading', false);
                    this.loadedOptions = typeof this.parseOptionsFunction === 'function' ? this.parseOptionsFunction(response) : response || [];
                }
                if (this.preselectFirst) {
                    if (this.multiple) this.modelValue = [this.loadedOptions[0]];
                    if (!this.multiple) this.modelValue = this.loadedOptions[0];
                } else if (this.preselectAll) {
                    this.modelValue = this.loadedOptions;
                } else if (this.value !== undefined && (Array.isArray(this.value) ? !!this.value.length : true)) {
                    this.initModelValue(this.value);
                } else if (this.initialValue !== undefined && (Array.isArray(this.initialValue) ? !!this.initialValue.length : true)) {
                    this.initModelValue(this.initialValue);
                } else {
                    this.initModelValue();
                }
                if (this.modelValue) {
                    this.$emit('input', this.modelValue, this.id);
                    this.$emit('change', this.modelValue, this.id);
                }
            },
            initModelValue(value) {
                const options = typeof this.fetchOptionsFunction === 'function' ? (this.asynchronous && this.multiple ? this.selectedOptions : this.loadedOptions) : this.options; // eslint-disable-line no-nested-ternary
                if (!this.multiple) {
                    const baseValue = value && typeof value === 'object' ? value[this.trackBy] : value;
                    if (typeof options[0] === 'object') {
                        this.modelValue = options.find(item => item[this.trackBy] === baseValue) || null;
                    } else {
                        // eslint-disable-next-line no-lonely-if
                        if (this.asynchronous && typeof this.fetchOptionsFunction === 'function') {
                            this.modelValue = value;
                        } else {
                            this.modelValue = options.find(item => item === baseValue) || null;
                        }
                    }
                } else {
                    this.modelValue = options.filter(lo => this.searchOptionsFunction(lo, value));
                }
            },
            searchOptionsFunction(option, value) {
                const searchFn = (i, v) => isEqual(i, v) || isEqual(String(i[this.trackBy]), String(v));
                if (this.multiple || this.showCheckboxes) return Boolean((value || []).find(v => searchFn(option, v)));
                return searchFn(option, value);
            },
        },
        created() {
            if (this.preselectFirst && !this.internalValue.length && this.options.length) {
                this.modelValue = this.options[0];
                this.$emit('input', this.modelValue, this.id);
                this.$emit('change', this.modelValue, this.id);
            }
            if (this.preselectAll && !this.internalValue.length && this.options.length) {
                this.modelValue = this.options;
                this.$emit('input', this.modelValue, this.id);
                this.$emit('change', this.modelValue, this.id);
            }
            this.$on('input', this.onInputEmitted);
            this.$on('open', this.onOpenEmitted);
            this.$on('close', this.onCloseEmitted);
            this.initAsynchronous();
        },
        beforeDestroy() {
            this.$off('input', this.onInputEmitted);
            this.$off('open', this.onOpenEmitted);
            this.$off('close', this.onCloseEmitted);
        },
    };
</script>



<style lang="scss" scoped>
    @import '../../design/index';

    .multiselect,
    .multiselect__input,
    .multiselect__single {
        font-size: $input-font-size;
    }
    .multiselect__single {
        white-space: nowrap;
        overflow: hidden;
        user-select: none;
    }

    .multiselect__placeholder {
        user-select: none;
    }

    .multiselect__option {
        width: 100%;

        &.multiselect__option {
            width: 100%;
            background: $white;
            color: $dropdown-link-color;
            padding: 0.25rem 1.25rem;
            display: flex;
            align-items: center;

            &--highlight,
            &--highlight::after {
                background: $dropdown-link-hover-bg;
                color: $dropdown-link-hover-color;
                font-weight: normal;
            }

            &--selected,
            &--selected::after {
                background: darken($dropdown-link-hover-bg, 2);
                color: darken($dropdown-link-hover-color, 2);
                font-weight: normal;
            }

            &--no-result {
                white-space: normal;
            }

            &--group {
                background: #ededed;
                color: #35495e;
            }

            &--no-option,
            &--no-result,
            &--min-char {
                cursor: default;
            }

            &-selected {
                background: $gray-200 !important;
                border-color: $gray-300 !important;
            }

            &__checkbox {
                min-height: 30px;

                &.multiselect__option--highlight,
                &.multiselect__option--selected,
                &.multiselect__option--highlight::after,
                &.multiselect__option--selected::after {
                    background: $white;
                    color: $dropdown-link-color;
                }
            }
        }
    }

    .multiselect__tags {
        position: relative;
    }

    .multiselect__tag {
        display: inline-flex;
        margin: 2px !important;
    }

    .multiselect__checkbox-wrapper {
        min-width: 100%;
        height: 100%;
        min-height: 26px;
        display: flex;
        align-items: center;
        flex-grow: 1;

        .custom-control.custom-checkbox {
            overflow: hidden;

            .custom-control-label {
                min-height: $font-size-base * $line-height-base;
                display: flex;
                align-items: center;

                span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }

        .btn-select-only {
            padding: $btn-padding-y-sm $btn-padding-x-sm;
            font-size: $btn-font-size-sm;
            text-transform: uppercase;
            display: none;
            margin-left: auto;
            line-height: 1rem;
        }
    }

    .multiselect__content-container {
        min-width: 100%;
        position: relative;
        z-index: 50;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .multiselect__select-all {
            width: 100%;
            background: $primary;
            padding: 5px 10px;
            margin: 0;
            color: #fff;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            cursor: pointer;

            &-selected {
                // background: $gray-400;
                opacity: 0.8;
            }

            .custom-control-input {
                &:checked,
                &:indeterminate {
                    ~ .custom-control-label::before {
                        border-color: $gray-300;
                    }
                }

                &:disabled {
                    cursor: not-allowed;

                    ~ .custom-control-label {
                        cursor: not-allowed;
                    }
                }
            }
        }

        .multiselect__search-inside {
            position: relative;
            display: flex;
            padding: 0.5rem 0.75rem;
            min-height: 40px;
            border-bottom: 1px solid #e8e8e8;
            background: #fff;
            font-size: 14px;

            input {
                flex-grow: 1;
            }

            &-icon {
                width: 26px;
                height: 24px;
                flex-shrink: 0;
                margin-right: 0.75rem;
            }

            &-clear-btn {
                width: 36px;
                height: 24px;
                flex-shrink: 0;
                padding: 0;
            }
        }

        .multiselect__content-wrapper {
            width: unset;
            min-width: 100%;

            .multiselect__content {
                min-width: 100%;
                // overflow: hidden;
                margin: 0;
                display: flex;
                flex-direction: column;
            }
        }

        .multiselect__element {
            position: relative;
            overflow: hidden;

            &:hover {
                .btn-select-only {
                    display: block;
                }
            }
        }

        &--checkboxes {
            width: 100%;
            position: absolute;
            top: 0;
            box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);

            .multiselect__content-wrapper {
                position: relative;
                border: 0;
                box-shadow: unset;
            }
        }
    }

    .multiselect--above .multiselect__content-wrapper {
        bottom: calc(100% + #{$input-height});
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.1s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
