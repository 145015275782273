<template>
    <DataTable
        :fields="fields"
        :items="parentComponent.items"
        :loading="parentComponent.loading"
        :error="parentComponent.error"
        :header="title"
        :per-page-values="[20, 50, 100]"
        :current-page.sync="parentComponent.tableParams.page"
        :per-page.sync="parentComponent.tableParams.perPage"
        :sort-by.sync="parentComponent.tableParams.sort"
        :sort-direction.sync="parentComponent.tableParams.order"
    >
        <template #cell(date)="data">
            <template v-if="groupBy === 'year'">{{ $format.date(data.value, 'yyyy') }}</template>
            <template v-else-if="groupBy === 'quarter'">{{ $format.date(data.value, 'QQQ yyyy') }}</template>
            <template v-else-if="groupBy === 'month'">{{ $format.date(data.value, 'LLLL yyyy') }}</template>
            <template v-else>{{ $format.date(data.value) }}</template>
        </template>
        <template #cell(conversion)="data">
            {{ $format.percent(data.value) }}
        </template>
        <template #cell(epc)="data">
            {{ $format.currency(data.value) }}
        </template>
        <template #cell(publisherNewCommission)="data">
            {{ $format.currency(data.value) }}
        </template>
        <template #cell(publisherAcceptedCommission)="data">
            {{ $format.currency(data.value) }}
        </template>
        <template #cell(newCommission)="data">
            {{ $format.currency(data.value) }}
        </template>
        <template #cell(acceptedCommission)="data">
            {{ $format.currency(data.value) }}
        </template>
    </DataTable>
</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';

    export default {
        name: 'Results',
        components: {
            DataTable,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {};
        },
        computed: {
            isRoleWidgeter() {
                return this.$store.getters['auth/isRoleWidgeter'];
            },
            isRoleAdvertiser() {
                return this.$store.getters['auth/isRoleAdvertiser'];
            },
            isRoleAdmin() {
                return this.$store.getters['auth/isRoleAdmin'];
            },
            fields() {
                return [
                    {
                        key: 'date',
                        label: this.$t('stat.date'),
                        sortable: true,
                    },
                    {
                        key: 'clicks',
                        label: this.$t('stat.clicks'),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'transactions',
                        label: this.$t('stat.transactions'),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'conversion',
                        label: this.$t('stat.conversion'),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'epc',
                        label: this.$t('stat.epc'),
                        sortable: true,
                        tdClass: 'text-right nowrap',
                        thClass: 'text-right',
                    },
                    ...[
                        this.isRoleAdmin
                            ? {
                                  key: 'publisherNewCommission',
                                  label: `${this.$t('stat.publisherCommission')}\n${this.$t('stat.commissionOpened')}`,
                                  sortable: true,
                                  tdClass: 'text-right nowrap',
                                  thClass: 'text-right pre',
                              }
                            : {},
                    ],
                    ...[
                        this.isRoleAdmin
                            ? {
                                  key: 'publisherAcceptedCommission',
                                  label: `${this.$t('stat.publisherCommission')}\n${this.$t('stat.commissionAccepted')}`,
                                  sortable: true,
                                  tdClass: 'text-right nowrap',
                                  thClass: 'text-right pre',
                              }
                            : {},
                    ],
                    {
                        key: 'newCommission',
                        label: this.isRoleAdmin ? `${this.$t('stat.ourCommission')}\n${this.$t('stat.commissionOpened')}` : this.$t('stat.newCommission'),
                        sortable: true,
                        tdClass: 'text-right nowrap',
                        thClass: 'text-right pre',
                    },
                    {
                        key: 'acceptedCommission',
                        label: this.isRoleAdmin ? `${this.$t('stat.ourCommission')}\n${this.$t('stat.commissionAccepted')}` : this.$t('stat.acceptedCommission'),
                        sortable: true,
                        tdClass: 'text-right nowrap',
                        thClass: 'text-right pre',
                    },
                ];
            },
            groupBy() {
                return this.parentComponent.filtersParams.groupBy;
            },
            title() {
                if (this.groupBy === 'day') return this.$t('dailyStatistics');
                if (this.groupBy === 'month') return this.$t('monthlyStatistics');
                if (this.groupBy === 'quarter') return this.$t('quarterlyStatistics');
                if (this.groupBy === 'year') return this.$t('yearlyStatistics');
                return this.$t('statistics');
            },
        },
    };
</script>
