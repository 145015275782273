<template>
    <Card :header="$t('filters')">
        <form class="d-flex flex-column" @keyup.enter="search()">

            <b-form-group :label="$t('space')" label-cols-sm="3">
                <MultiselectSpaces
                    :initial-value="parentComponent.filtersParams.spaces"
                    :disabled="parentComponent.disabled"
                    @inputTrackBy="parentComponent.filters.spaces = $event"
                />
            </b-form-group>

            <b-form-group :label="$t('status')" label-cols-sm="3">
                <b-form-checkbox-group v-model="parentComponent.filters.statuses" :options="optionsStatuses" :disabled="parentComponent.disabled" class="h-100 d-flex align-items-center" />
            </b-form-group>

            <div class="d-flex gap-3">
                <ButtonIcon :loading="parentComponent.loading" hide-label-while-loading @click="parentComponent.filterData()">
                    {{ $t('show') }}
                </ButtonIcon>
                <ButtonIcon variant="light" :disabled="parentComponent.loading" @click="parentComponent.resetData()">
                    {{ $t('reset') }}
                </ButtonIcon>
            </div>

        </form>
    </Card>
</template>



<script>
    import MultiselectSpaces from '@/components/common/MultiselectSpaces.vue';

    export default {
        name: 'Filters',
        components: {
            MultiselectSpaces,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {};
        },
        computed: {
            optionsStatuses() {
                return [
                    { value: 'active', text: this.$t('active') },
                    { value: 'inactive', text: this.$t('inactive') },
                    { value: 'canceled', text: this.$t('canceled') },
                ];
            },
        },
    };
</script>
