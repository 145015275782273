<template>
    <Card :header="$t('filters')">
        <b-form-group :label="$t('space')" label-cols-sm="3">
            <MultiselectSpaces
                v-model="filters.space"
                :multiple="false"
                :show-checkboxes="false"
                :clear-on-select="false"
                :close-on-select="true"
                :preserve-search="true"
                :disabled="loading"
                @loading="value => (isSpacesLoading = value)"
            />
        </b-form-group>

        <b-form-group :label="$t('campaign')" label-cols-sm="3">
            <Multiselect
                :fetch-options-function="fetchCampaigns"
                :parse-options-function="parseCampaigns"
                :placeholder="$t('selectField.placeholder')"
                :searchable="true"
                track-by="id"
                label="name"
                select-label=""
                :disabled="loading"
                @input="value => (filters.campaign = value)"
                @loading="value => (isCampaignsLoading = value)"
            />
        </b-form-group>

        <b-form-group :label="$t('transactions.dateRange')" label-cols-sm="3">
            <FormDatepicker v-model="filters.dateRange" :placeholder="$t('all')" :config="datePickerConfig" :disabled="loading" />
        </b-form-group>

        <b-form-group :label="$t('transactions.financeDocument')" label-cols-sm="3">
            <Multiselect v-model="filters.financeDocument" :options="financeDocumentOptions" :disabled="loading" />
        </b-form-group>

        <b-form-group :label="$t('status')" label-cols-sm="3">
            <b-form-checkbox-group v-model="filters.status" :options="transactionStatusOptions" :disabled="loading" class="h-100 d-flex align-items-center" />
        </b-form-group>

        <b-form-group>
            {{ $t('transactions.count') }}:
            <b-spinner v-if="loading" variant="primary" small class="ml-1" />
            <span v-else class="text-primary">{{ $format.number(transactions.max) }}</span>
        </b-form-group>

        <ButtonIcon :disabled="loading || isSpacesLoading || isCampaignsLoading" icon="filter" @click="fetchTransactions()">
            {{ $t('transactions.showTransactions') }}
        </ButtonIcon>
        <JsonToCsv class="ml-3" :items="exportData" :fields="exportFields" :labels="exportLabels" :name="exportFilename">
            {{ $t('transactions.exportTransactions') }}
        </JsonToCsv>
    </Card>
</template>

<script>
    import sortBy from 'lodash/sortBy';
    import clone from 'lodash/clone';
    import transactionsService from '@/services/transactionsService';
    import spaceService from '@/services/spaceService';
    import Multiselect from '@/components/common/Multiselect.vue';
    import MultiselectSpaces from '@/components/common/MultiselectSpaces.vue';
    import FormDatepicker from '@/components/common/FormDatepicker.vue';
    import JsonToCsv from '@/components/common/JsonToCsv.vue';
    import { translateTransactionStatus } from '@/services/transactionService';

    export default {
        name: 'Filters',
        components: {
            Multiselect,
            MultiselectSpaces,
            FormDatepicker,
            JsonToCsv,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                currentFilters: {},
                filters: {
                    campaign: null,
                    space: null,
                    dateRange: null,
                    status: [],
                    financeDocument: this.$route.query.financeDocument || null,
                },
                isCampaignsLoading: false,
                isSpacesLoading: false,
                fields: [
                    { key: 'id', label: 'ID' },
                    { key: 'createdAt', label: this.$t('date') },
                    { key: 'transId', label: this.$t('identifier') },
                    { key: 'statusText', label: this.$t('status') },
                    { key: 'campaign.name', label: this.$t('campaign') },
                    { key: 'space.name', label: this.$t('space') },
                    { key: 'amount', label: this.$t('transactions.transactionAmount') },
                    { key: 'publisherCommission', label: `${this.$t('commission')} ${this.$t('transactions.commissions.publisher')}` },
                    { key: 'conversionTime', label: this.$t('conversionTime') },
                ],
                fieldsToExportFile: [
                    { key: 'createdAt', label: this.$t('date') },
                    { key: 'transId', label: this.$t('identifier') },
                    { key: 'statusText', label: this.$t('status') },
                    { key: 'campaign.id', label: this.$t('campaignId') },
                    { key: 'campaign.name', label: this.$t('campaign') },
                    { key: 'space.id', label: this.$t('spaceId') },
                    { key: 'space.name', label: this.$t('space') },
                    { key: 'amount', label: this.$t('transactions.transactionAmount') },
                    { key: 'publisherCommission', label: `${this.$t('commission')} ${this.$t('transactions.commissions.publisher')}` },
                    { key: 'conversionTime', label: this.$t('conversionTime') },
                    { key: 'abpar1', label: this.$t('abpar1') },
                    { key: 'abpar2', label: this.$t('abpar2') },
                    { key: 'abpar3', label: this.$t('abpar3') },
                ],
            };
        },
        computed: {
            filtersParams() {
                const filters = {};
                if (this.filters.space?.value) filters.spaces = this.filters.space.value;
                if (this.filters.campaign?.id) filters.campaigns = this.filters.campaign.id;
                if (this.filters.dateRange?.[0]) filters.from = this.filters.dateRange[0];
                if (this.filters.dateRange?.[1]) filters.to = this.filters.dateRange[1];
                if (this.filters.status?.length) filters.statuses = this.filters.status.join(',');
                if (this.filters.financeDocument) filters.financeDocument = this.filters.financeDocument;
                return filters;
            },
            tableParams() {
                return {
                    sort: this.parentComponent.sortBy,
                    order: this.parentComponent.sortDirection.toUpperCase(),
                    page: this.parentComponent.currentPage,
                    perPage: this.parentComponent.perPage,
                };
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                    ...this.tableParams,
                };
            },
            loading() {
                return this.$store.getters['transactions/getLoading']('userTransactions');
            },
            transactionStatusOptions() {
                return this.$store.getters['options/transactionStatusOptions'];
            },
            transactions() {
                return this.$store.getters['transactions/getUserTransactions'];
            },
            financeDocumentOptions() {
                return this.$store.getters['finance/financeDocumentOptions'];
            },
            exportFields() {
                return this.fieldsToExportFile.map(item => item.key);
            },
            exportLabels() {
                return Object.fromEntries(this.fieldsToExportFile.map(item => [item.key, item.label]));
            },
            exportFilename() {
                const from = this.filters.dateRange?.[0] ? `-from-${this.$format.date(this.filters.dateRange[0])}` : '';
                const to = this.filters.dateRange?.[1] ? `-to-${this.$format.date(this.filters.dateRange[1])}` : '';
                return `transactions${from}${to}.csv`;
            },
            datePickerConfig() {
                return { mode: 'range' };
            },
            isFiltersChanged() {
                return JSON.stringify(this.filtersParams) !== JSON.stringify(this.currentFilters);
            },
        },
        watch: {
            tableParams: {
                deep: true,
                handler: 'fetchTransactions',
            },
        },
        methods: {
            resetCurrentPage() {
                this.parentComponent.currentPage = 1;
            },
            fetchCampaigns() {
                return spaceService.fetchCampaigns({
                    userRole: 'admin',
                    page: 1,
                    perPage: Number.MAX_SAFE_INTEGER,
                });
            },
            parseCampaigns(data) {
                return sortBy(data?.items || [], [item => item.name.toLowerCase()], ['asc']);
            },
            fetchTransactions() {
                if (this.isFiltersChanged) this.resetCurrentPage();
                this.currentFilters = clone(this.filtersParams);
                this.$store.dispatch('transactions/fetchUserTransactions', { requestParams: this.requestParams });
            },
            clearTransactions() {
                this.$store.commit('transactions/clearUserTransactions');
            },
            exportData() {
                const params = {
                    requestParams: {
                        ...this.filtersParams,
                        sort: 'createdAt',
                        order: 'DESC',
                        page: 1,
                        perPage: Number.MAX_SAFE_INTEGER,
                        omitItems: 1,
                    },
                };
                return transactionsService
                    .getUserTransactions(params)
                    .then(data => this.formatDataItems(data.items))
                    .catch(error => this.$toastr.error(error.message));
            },
            formatDataItems(items) {
                return (
                    items?.map(item => ({
                        ...item,
                        createdAt: this.$format.dateAndHour(item.createdAt),
                        statusText: translateTransactionStatus(item.status),
                        publisherCommission: this.$format.currency(item.publisherCommission),
                        amount: this.$format.currency(item.amount),
                        conversionTime: this.$format.seconds(item.conversionTime),
                    })) || []
                );
            },
        },
        mounted() {
            this.clearTransactions();
            this.fetchTransactions();
        },
    };
</script>
