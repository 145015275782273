<template>
    <div>
        <PageHeader>{{ $t('textLinks.title2') }}</PageHeader>

        <div class="row">
            <div class="col-12">
                <div class="mb-4">
                    <p class="mb-1">
                        {{ $t('textLinks.subHeader') }}
                    </p>
                    <b-button-group>
                        <b-button
                            href="https://getbuybox.com/linki-partnerskie/"
                            target="_blank"
                            variant="link"
                            class="d-flex align-items-center p-0 mr-4"
                        >
                            <feather type="file-text" class="mr-2" /> {{ $t('seeHowToDoIt') }}
                        </b-button>
                        <b-button
                            href="https://getbuybox.com/jak-utworzyc-link-afiliacyjny/"
                            target="_blank"
                            variant="link"
                            class="d-flex align-items-center p-0 mr-4"
                        >
                            <feather type="youtube" class="mr-2" /> {{ $t('watchVideo') }}
                        </b-button>
                    </b-button-group>
                </div>
            </div>
        </div>

        <card :loading="spacesLoading" :header="$t('textLinks.header')">
            <error-message v-model="error" />

            <select-field
                v-model="model.space"
                class="col-sm-12"
                :options="spaceOptions"
                :label="$t('textLinks.space')"
                row
            />
            <select-field
                v-model="model.campaign"
                class="col-sm-12"
                :options="campaignsOfSpace"
                :loading="campaignsLoading"
                :label="$t('textLinks.campaign')"
                :disabled="!model.space"
                row
            />
            <text-field
                v-model="model.url"
                class="col-sm-12"
                :validation="$v.model.url"
                :custom-validation-errors="urlCustomValidationErrors"
                :label="$t('textLinks.targetUrl')"
                placeholder="https://"
                row
            />

            <text-field
                v-model="model.abpar1"
                class="col-sm-12 mt-5"
                :label="$t('textLinks.abpar', { number: 1 })"
                :placeholder="$t('textLinks.optional')"
                row
            />
            <text-field
                v-model="model.abpar2"
                class="col-sm-12"
                :label="$t('textLinks.abpar', { number: 2 })"
                :placeholder="$t('textLinks.optional')"
                row
            />
            <text-field
                v-model="model.abpar3"
                class="col-sm-12"
                :label="$t('textLinks.abpar', { number: 3 })"
                :placeholder="$t('textLinks.optional')"
                row
            />

            <slide-y-down-transition>
                <div v-if="generatedLink">
                    <div class="mt-4">
                        <h4 class="header-title mb-1">
                            {{ $t('textLinks.generatedLink') }}
                        </h4>
                        <readonly-textarea :value="generatedLink" class="mt-2" />
                    </div>

                    <div class="mt-4">
                        <button-default class="mr-1" icon="copy" @click="copyLink">
                            {{ $t('textLinks.copyLink') }}
                        </button-default>
                        <button-default
                            class="mr-1"
                            variant="outline-primary"
                            icon="external-link"
                            @click="openLink"
                        >
                            {{ $t('textLinks.openLink') }}
                        </button-default>
                        <!--
                        <button-default
                            class="mr-1"
                            variant="outline-primary"
                            icon="share"
                        >
                            {{ $t('textLinks.shareLink') }}
                        </button-default>
                        -->
                    </div>
                </div>
            </slide-y-down-transition>
        </card>
        <LastLinks v-if="getLinksSize" />
    </div>
</template>

<script>
    import { SlideYDownTransition } from 'vue2-transitions';
    import { mapActions, mapGetters, mapState } from 'vuex';
    import { url as validUrl } from 'vuelidate/lib/validators';
    import orderBy from 'lodash/orderBy';
    import ReadonlyTextarea from '@/components/common/ReadonlyTextarea.vue';
    import PageHeader from '@/components/common/PageHeader.vue';
    import Card from '@/components/common/Card.vue';
    import SelectField from '@/components/common/SelectField.vue';
    import TextField from '@/components/common/TextField.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import spaceService from '@/services/spaceService';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import LastLinks from './parts/LastLinks.vue';

    function isSameDomain(value) {
        const campaign = this.campaignsOfSpace?.find(campaignOfSpace => campaignOfSpace.value === this.model.campaign);
        if (!value) {
            return true;
        }
        if (!campaign?.url) {
            return false;
        }
        let isSameHost = true;
        try {
            const campaignUrlHost = new URL(campaign?.url).host;
            isSameHost = value === campaignUrlHost || new URL(value).host === campaignUrlHost;
        } catch (ignore) {
            return false;
        }
        return isSameHost;
    }

    export default {
        name: 'TextLinks',
        components: {
            ReadonlyTextarea,
            ButtonDefault,
            TextField,
            Card,
            PageHeader,
            SelectField,
            SlideYDownTransition,
            ErrorMessage,
            LastLinks,
        },
        validations: {
            model: {
                url: {
                    url: validUrl,
                    isSameDomain,
                },
            },
        },
        data() {
            return {
                error: null,
                model: {
                    space: undefined,
                    campaign: undefined,
                    url: '',
                    abpar1: '',
                    abpar2: '',
                    abpar3: '',
                },
                urlCustomValidationErrors: {
                    isSameDomain: 'textLinks.isSameDomainError',
                },
            };
        },
        computed: {
            generatedLink() {
                const baseUrl = 'https://go.buybox.click';
                const { space, campaign, url, abpar1, abpar2, abpar3 } = this.model;

                this.$v.$touch();

                if (!space || !campaign || !url || this.$v.$invalid) {
                    return '';
                }

                const ps = [];

                if (abpar1) {
                    ps.push(`p1=${encodeURIComponent(abpar1)}`);
                }
                if (abpar2) {
                    ps.push(`p2=${encodeURIComponent(abpar2)}`);
                }
                if (abpar3) {
                    ps.push(`p3=${encodeURIComponent(abpar3)}`);
                }

                const params = ps.join('&');

                return `${baseUrl}/linkclick_${space}_${campaign}?${params}&url=${encodeURIComponent(url)}`;
            },
            campaignsLoading() {
                return this.$asyncComputed.campaignsOfSpace.updating;
            },
            spaceOptions() {
                return orderBy(this.$store.getters['space/spaceOptions'], [item => item.text.toLowerCase()], ['asc']);
            },
            ...mapGetters({
                hasOnlyOneSpace: 'space/hasOnlyOneSpace',
            }),
            ...mapState({
                spacesLoading: state => state.space.loading.fetchSpaces,
                currentSpaceId: state => state.space.currentSpaceId,
            }),
            getLinksSize() {
                return this.$store.getters['linkGenerator/getLinksSize']();
            },
        },
        asyncComputed: {
            campaignsOfSpace: {
                async get() {
                    const spaceId = this.model.space;
                    if (!spaceId) {
                        return [];
                    }
                    const campaigns = await spaceService.fetchTextLinkCampaigns(spaceId);
                    const data = Object.values(campaigns).map(campaign => ({
                        value: campaign.id,
                        text: campaign.name,
                        url: campaign.url,
                    }));
                    return orderBy(data, [item => item.text.toLowerCase()], ['asc']);
                },
                default() {
                    return [];
                },
            },
        },
        watch: {
            // eslint-disable-next-line
            'model.space': function() {
                this.model.campaign = undefined;
            },
        },
        methods: {
            async getData() {
                try {
                    this.error = null;
                    await this.fetchSpaces();
                    if (this.hasOnlyOneSpace) {
                        this.model.space = this.currentSpaceId;
                    }
                } catch (e) {
                    this.error = e.message;
                }
            },
            async copyLink() {
                await this.$copyText(this.generatedLink);
                this.$toastr.success(this.$t('textLinks.copied', { link: this.generatedLink }));
            },
            openLink() {
                window.open(this.generatedLink, '_blank');
            },
            ...mapActions({
                fetchSpaces: 'space/fetchSpaces',
            }),
            createLinkItem() {
                if (!this.generatedLink) return null;
                return {
                    link: this.generatedLink,
                    url: this.model.url,
                    campaign: this.campaignsOfSpace.map(item => ({ id: item.value, name: item.text })).find(item => item.id === this.model.campaign),
                    space: this.spaceOptions.map(item => ({ id: item.value, name: item.text })).find(item => item.id === this.model.space),
                };
            },
        },
        mounted() {
            this.getData();
        },
        beforeRouteLeave(to, from, next) {
            const link = this.createLinkItem();
            if (link) this.$store.commit('linkGenerator/addLink', link);
            return next();
        },
    };
</script>

<style scoped></style>
