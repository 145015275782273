<template>
    <card :header="$t('accountSettingsNotifications.title')">
        <form novalidate @submit.prevent="handleSubmit">
            <div class="row">
                <template v-if="isRoleWidgeter">
                    <checkbox-field
                        v-model="model.news"
                        class="col-sm-12"
                        :label="$t('accountSettingsNotifications.news')"
                    />
                    <checkbox-field
                        v-model="model.programs"
                        class="col-sm-12"
                        :label="$t('accountSettingsNotifications.programs')"
                    />
                    <checkbox-field
                        v-model="model.promotions"
                        class="col-sm-12"
                        :label="$t('accountSettingsNotifications.promotions')"
                    />
                    <checkbox-field
                        v-model="model.display"
                        class="col-sm-12"
                        :label="$t('accountSettingsNotifications.display')"
                    />
                </template>
                <template v-if="isRoleAdvertiser">
                    <checkbox-field
                        v-model="model.isNotification"
                        class="col-sm-12"
                        variant="switch"
                        :label="$t('accountSettingsNotifications.notifications')"
                    />
                </template>
            </div>
            <error-message v-model="saveError" />
            <div class="row">
                <div class="col-sm-6 mt-2 form-group">
                    <button-default :loading="submitting" type="submit" block>
                        {{ $t('accountSettingsNotifications.submit') }}
                    </button-default>
                </div>
            </div>
        </form>
    </card>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import Card from '@/components/common/Card.vue';
    import CheckboxField from '@/components/common/CheckboxField.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'WidgeterAccountSettingsNotifications',
        components: {
            ErrorMessage,
            CheckboxField,
            Card,
            ButtonDefault,
        },
        data() {
            let model = {};
            if (this.$store.getters['auth/isRoleAdvertiser']) {
                model = { isNotification: false };
            } else {
                model = { news: false, programs: false, promotions: false, display: false };
            }
            return {
                model,
                saveError: null,
            };
        },
        computed: {
            isRoleWidgeter() {
                return this.$store.getters['auth/isRoleWidgeter'];
            },
            isRoleAdvertiser() {
                return this.$store.getters['auth/isRoleAdvertiser'];
            },
            ...mapState({
                user: state => state.auth.user,
                submitting: state => state.auth.loading.updateNotificationsSettings,
            }),
        },
        methods: {
            setInitialValues() {
                const {
                    notifications: { news, programs, promotions, display, isNotification },
                } = this.user;

                if (this.isRoleAdvertiser) {
                    this.model = { isNotification };
                } else {
                    this.model = { news, programs, promotions, display };
                }
            },
            async handleSubmit() {
                try {
                    await this.updateNotificationsSettings({ notifications: this.model });
                } catch (e) {
                    this.saveError = e.message;
                }
            },
            ...mapActions({
                updateNotificationsSettings: 'auth/updateNotificationsSettings',
            }),
        },
        created() {
            this.setInitialValues();
        },
    };
</script>
