/* eslint-disable no-param-reassign */
export default {
    install(VueInstance, options) {
        const storage = type => {
            const isObject = dataString => dataString.startsWith('{') && dataString.endsWith('}');
            const isArray = dataString => dataString.startsWith('[') && dataString.endsWith(']');
            const isNumeric = dataString => dataString && !isNaN(dataString); // eslint-disable-line no-restricted-globals
            const JSONParse = (data, defaultData) => {
                try {
                    return JSON.parse(data);
                } catch (error) {
                    return defaultData;
                }
            };
            return {
                get(key, defaultValue) {
                    const data = window[`${type}Storage`].getItem(`${options.prefix}${key}`);
                    if (!data || data === '' || data === 'null' || data === 'undefined') return defaultValue;
                    if (isObject(data)) return JSONParse(data, {});
                    if (isArray(data)) return JSONParse(data, []);
                    if (isNumeric(data)) return parseFloat(data);
                    return data;
                },
                set(key, value) {
                    const data = typeof value === 'object' ? JSON.stringify(value) : value;
                    window[`${type}Storage`].setItem(`${options.prefix}${key}`, data);
                },
                remove(key) {
                    window[`${type}Storage`].removeItem(`${options.prefix}${key}`);
                },
            };
        };

        VueInstance.localStorage = storage('local');
        VueInstance.sessionStorage = storage('session');

        VueInstance.prototype.$localStorage = VueInstance.localStorage;
        VueInstance.prototype.$sessionStorage = VueInstance.sessionStorage;
    },
};
