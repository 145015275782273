<template>
    <Card :header="$t('filters')">
        <form class="d-flex flex-column" @keyup.enter="search()">

            <b-form-group :label="$t('publisher')" label-cols-sm="3">
                <Multiselect
                    :placeholder="$t('selectField.placeholder')"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :show-no-results="true"
                    :show-no-options="true"
                    :preserve-search="false"
                    :show-checkboxes="true"
                    :multiple="true"
                    :show-selected="true"
                    :asynchronous="true"
                    track-by="id"
                    label="username"
                    select-label=""
                    :fetch-options-function="fetchUsers"
                    :parse-options-function="parseUsers"
                    :disabled="parentComponent.disabled"
                    @inputTrackBy="parentComponent.filters.publishers = $event"
                />
            </b-form-group>

            <b-form-group :label="$t('dateRange')" label-cols-sm="3">
                <FormDatepicker
                    :value="[parentComponent.filters.startDate, parentComponent.filters.endDate]"
                    :placeholder="$t('all')"
                    :config="{ mode: 'range' }"
                    :disabled="parentComponent.disabled"
                    @from="parentComponent.filters.startDate = $event"
                    @to="parentComponent.filters.endDate = $event"
                />
            </b-form-group>

            <b-form-group :label="$t('status')" label-cols-sm="3">
                <b-form-checkbox-group v-model="parentComponent.filters.statuses" :options="statusesOption" :disabled="parentComponent.disabled" class="h-100 d-flex align-items-center" />
            </b-form-group>

            <b-form-group :label="$t('accountType')" label-cols-sm="3">
                <b-form-checkbox-group v-model="parentComponent.filters.types" :options="typesOption" :disabled="parentComponent.disabled" class="h-100 d-flex align-items-center" />
            </b-form-group>

            <div class="d-flex gap-3">
                <ButtonIcon :loading="parentComponent.loading" hide-label-while-loading @click="parentComponent.filterData()">
                    {{ $t('show') }}
                </ButtonIcon>
                <ButtonIcon variant="light" :disabled="parentComponent.loading" @click="parentComponent.resetData()">
                    {{ $t('reset') }}
                </ButtonIcon>
            </div>

        </form>
    </Card>
</template>



<script>
    import Role from '@/Role';
    import authService from '@/services/authService';
    import Multiselect from '@/components/common/Multiselect.vue';
    import FormDatepicker from '@/components/common/FormDatepicker.vue';
    import { FinanceDocumentStatus, translateFinanceDocumentStatus } from '@/services/financeService';

    export default {
        name: 'Filters',
        components: {
            Multiselect,
            FormDatepicker,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {};
        },
        computed: {
            statusesOption() {
                const statuses = { ...FinanceDocumentStatus };
                delete statuses.ABANDONED;
                return Object.entries(statuses).map(([key, value]) => ({ text: translateFinanceDocumentStatus(value), value: parseInt(value) }));
            },
            typesOption() {
                return [
                    { text: this.$t('privateType'), value: 'bill' },
                    { text: this.$t('companyType'), value: 'invoice' },
                ];
            },
        },
        methods: {
            fetchUsers({ search }) {
                return authService.fetchUserAccount('', { search, roles: Role.WIDGETER, page: 1, perPage: Number.MAX_SAFE_INTEGER });
            },
            parseUsers(data) {
                return data?.items || [];
            },
        },
    };
</script>
