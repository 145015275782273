<template>
    <div>
        <div v-if="buyboxesRequest.loading" class="d-flex justify-content-center">
            <b-spinner />
        </div>
        <div v-else>
            <select-field
                v-if="buyboxOptions.length"
                v-model="selectedBuyboxId"
                :options="buyboxOptions"
            />
            <error-message v-model="buyboxesRequest.error" />
        </div>
    </div>
</template>

<script>
    import WrapRequest from '@/utils/wrapRequest';
    import pluginService from '@/services/pluginService';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import SelectField from '@/components/common/SelectField.vue';

    export default {
        name: 'BuyboxSearch',
        components: {
            SelectField,
            ErrorMessage,
        },
        props: {
            value: {
                type: Number,
            },
            spaceId: {
                type: Number,
            },
            productId: {
                type: Number,
            },
        },
        data() {
            return {
                buyboxesRequest: new WrapRequest(() => pluginService.matchProductBuyboxes(this.spaceId, this.productId)),
            };
        },
        computed: {
            buyboxOptions() {
                if (!this.buyboxesRequest.result) {
                    return [];
                }
                return this.buyboxesRequest.result.map(item => ({
                    value: item.buybox.id,
                    text: this.formatBuyboxName(item),
                }));
            },
            selectedBuyboxId: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                },
            },
            selectedBuyboxName() {
                if (!this.selectedBuyboxId || !this.buyboxesRequest.result || !this.buyboxesRequest.result.length) {
                    return null;
                }
                const buybox = this.buyboxesRequest.result.find(item => item.buybox.id === this.selectedBuyboxId);
                return this.formatBuyboxName(buybox);
            },
        },
        watch: {
            spaceId: {
                handler() {
                    this.fetchBuyboxes();
                },
                immediate: true,
            },
            productId: {
                handler() {
                    this.fetchBuyboxes();
                },
                immediate: true,
            },
            'buyboxOptions.length': {
                handler(length) {
                    if (!length) this.selectedBuyboxId = null;
                },
            },
            'buyboxesRequest.loading': {
                handler(loading) {
                    this.$emit('loading', loading);
                },
            },
        },
        methods: {
            async fetchBuyboxes() {
                if (this.spaceId && this.productId) {
                    await this.buyboxesRequest.request();
                } else {
                    this.buyboxesRequest.reset();
                }
            },
            formatBuyboxName(item) {
                if (!item) {
                    return null;
                }
                const textPositive = `${this.$t('numberOfOffersToDisplay').toLowerCase()}: ${item.activeNo}`;
                const textNegative = this.$t('noOffersToDisplay').toLowerCase();
                const text = item.activeNo ? textPositive : textNegative;
                return `${item.buybox.name} (${text})`;
            },
        },
    };
</script>
