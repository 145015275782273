import store from '@/store/store';

const hasFeatureGuard = async function hasFeatureGuard(to, from, next) {
    // If the route doesn't have meta.feature, always allow access
    if (!to.meta.feature) {
        return next();
    }

    const featureFlagEnabled = await store.getters['auth/isFeatureFlagEnabled'](to.meta.feature);

    if (featureFlagEnabled) {
        return next();
    }
    return next({ name: 'Forbidden' });
};

export default hasFeatureGuard;
