<template>
    <div>

        <PageHeader>
            {{ $t('feedImportSummary') }}
        </PageHeader>

        <div class="accordion d-flex flex-column gap-3">
            <div v-for="type in TYPES" :key="type">
                <ButtonIcon
                    v-b-toggle="`accordion-${type}`"
                    block
                    variant="primary"
                    icon="chevron-down"
                    flip-icon
                    label-class="flex-grow-1"
                >
                    {{ typeHeaders[type] }}
                </ButtonIcon>
                <b-collapse :id="`accordion-${type}`" visible>
                    <DataTable :key="type" :items="getFeedsProvider(type)" :fields="fields(type)" :pagination="false" no-provider-sorting>
                        <template #cell(id)="{item, value}">
                            <b-link :to="{ name: 'CampaignsDetailsTabEdit', params: { id: item.campaign.id, tab: 'feeds', itemId: item.id } }">{{ value }}</b-link>
                        </template>
                        <template #cell(createdAt)="{value}">
                            <template v-if="value">{{ dateFormat(value, 'yyyy-MM-dd HH:mm:ss') }}</template>
                        </template>
                        <template #cell(updatedAt)="{value}">
                            <template v-if="value">{{ dateFormat(value, 'yyyy-MM-dd HH:mm:ss') }}</template>
                        </template>
                        <template #[`cell(offerFeed.processStartedAt)`]="{value}">
                            <template v-if="value">{{ dateFormat(value, 'yyyy-MM-dd HH:mm:ss') }}</template>
                        </template>
                        <template #[`cell(offerFeed.processEndedAt)`]="{value}">
                            <template v-if="value">{{ dateFormat(value, 'yyyy-MM-dd HH:mm:ss') }}</template>
                        </template>
                    </DataTable>
                </b-collapse>
            </div>
        </div>

    </div>
</template>



<script>
    import { format as dateFormat } from 'date-fns';
    import productFeedsService from '@/services/productFeedsService';
    import DataTable from '@/components/common/DataTable.vue';
    import ButtonIcon from '@/components/common/ButtonIcon.vue';

    const TYPES = Object.freeze({
        WAITING_FOR_DOWNLOAD: 'WAITING_FOR_DOWNLOAD',
        WAITING_FOR_PROCESSING: 'WAITING_FOR_PROCESSING',
        PROCESSING: 'PROCESSING',
        PROCESSED_ACTIVE: 'PROCESSED_ACTIVE',
        PROCESSED_INACTIVE: 'PROCESSED_INACTIVE',
        CANCELED: 'CANCELED',
    });

    export default {
        name: 'FeedImportSummary',
        components: {
            DataTable,
            ButtonIcon,
        },
        data() {
            return {
                TYPES,
                typeParams: {
                    [TYPES.WAITING_FOR_DOWNLOAD]: { statuses: [3] },
                    [TYPES.WAITING_FOR_PROCESSING]: { statuses: [2] },
                    [TYPES.PROCESSING]: { statuses: [1] },
                    [TYPES.PROCESSED_ACTIVE]: { statuses: [0], active: 1 },
                    [TYPES.PROCESSED_INACTIVE]: { statuses: [0], active: 0 },
                    [TYPES.CANCELED]: { statuses: [9] },
                },
            };
        },
        computed: {
            typeHeaders() {
                return {
                    [TYPES.WAITING_FOR_DOWNLOAD]: this.$t('feedsWaitingForFileDownload'),
                    [TYPES.WAITING_FOR_PROCESSING]: this.$t('feedsWaitingForProcessing'),
                    [TYPES.PROCESSING]: this.$t('feedsBeingProcessed'),
                    [TYPES.PROCESSED_ACTIVE]: this.$t('successfullyProcessedFeeds'),
                    [TYPES.PROCESSED_INACTIVE]: this.$t('inactiveFeeds'),
                    [TYPES.CANCELED]: this.$t('canceledFeeds'),
                };
            },
            fields() {
                const id = { key: 'id', label: this.$t('id'), sortable: true };
                const name = { key: 'name', label: this.$t('name'), sortable: true };
                const createdAt = { key: 'createdAt', label: this.$t('createdAt'), sortable: true };
                const updatedAt = { key: 'updatedAt', label: this.$t('updatedAt'), sortable: true };
                const format = { key: 'format', label: this.$t('format'), sortable: true };
                const parser = { key: 'parser', label: this.$t('parser'), sortable: true };
                const processStartedAt = { key: 'offerFeed.processStartedAt', label: this.$t('processStartedAt'), sortable: true };
                const processEndedAt = { key: 'offerFeed.processEndedAt', label: this.$t('processEndedAt'), sortable: true };
                const itemsProcessed = { key: 'offerFeed.report', label: this.$t('itemsProcessed'), sortable: true };
                const baseFields = [id, name, parser, format, createdAt, updatedAt, processStartedAt];
                return type => {
                    const fields = [...baseFields];
                    if (type === TYPES.PROCESSED_ACTIVE || type === TYPES.PROCESSED_INACTIVE || type === TYPES.CANCELED) fields.push(processEndedAt);
                    if (type === TYPES.PROCESSED_ACTIVE) fields.push(itemsProcessed);
                    return fields;
                };
            },
        },
        methods: {
            dateFormat,
            fetchFeeds(params) {
                return productFeedsService.getFeed({ requestParams: params }).then(data => data?.items || []);
            },
            getFeedsProvider(type) {
                return (ctx, callback) => this.fetchFeeds(this.typeParams[type]);
            },
        },
    };
</script>
