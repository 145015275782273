<template>
    <b-dropdown
        ref="timeRangeDropdown"
        variant="primary"
        right
        :disabled="disabled"
    >
        <b-dropdown-text
            v-if="options.indexOf('custom') !== -1"
            ref="datepickerOption"
            class="datepicker-option"
            text-class="p-0 position-relative"
            tag="div"
        >
            <button
                ref="btnOpenDatepicker"
                type="button"
                class="dropdown-item"
                :class="{'active': timeRange.name === 'custom'}"
                @click="openDatepicker()"
            >
                {{ $t(`timeRangeDropdown.custom`) }}
            </button>
            <flat-pickr
                v-if="datepickerShow"
                ref="datepicker"
                class="form-control d-none"
                value=""
                :config="datepickerConfig"
                @on-change="setTimeRangeCustom"
            />
        </b-dropdown-text>
        <template v-for="item in options">
            <b-dropdown-item-button
                v-if="item !== 'custom'"
                :key="item"
                :active="item === timeRange.name"
                @click="setTimeRange(item)"
            >
                {{ $t(`timeRangeDropdown.${item}`) }}
            </b-dropdown-item-button>
        </template>
        <template #button-content>
            {{ timeRange.label }}
            <i class="icon">
                <feather type="chevron-down" class="align-middle" />
            </i>
        </template>
    </b-dropdown>
</template>



<script>
    import { format, addDays, addWeeks, addMonths, addQuarters, addYears, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfQuarter, endOfQuarter, startOfYear, endOfYear, differenceInCalendarDays } from 'date-fns';
    import { i18n, getFlatpickrLocale } from '@/i18n/i18n';

    const DATE_FORMAT = 'yyyy-MM-dd';

    export const getTimeRangeLast7Days = () => {
        const now = new Date();
        return {
            startDate: format(addDays(now, -7), DATE_FORMAT),
            endDate: format(addDays(now, -1), DATE_FORMAT),
            compareStartDate: format(addDays(now, -14), DATE_FORMAT),
            compareEndDate: format(addDays(now, -8), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.last7Days'),
            name: 'last7Days',
        };
    };
    export const getTimeRangeLast14Days = () => {
        const now = new Date();
        return {
            startDate: format(addDays(now, -14), DATE_FORMAT),
            endDate: format(addDays(now, -1), DATE_FORMAT),
            compareStartDate: format(addDays(now, -28), DATE_FORMAT),
            compareEndDate: format(addDays(now, -15), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.last14Days'),
            name: 'last14Days',
        };
    };
    export const getTimeRangeLast30Days = () => {
        const now = new Date();
        return {
            startDate: format(addDays(now, -30), DATE_FORMAT),
            endDate: format(addDays(now, -1), DATE_FORMAT),
            compareStartDate: format(addDays(now, -60), DATE_FORMAT),
            compareEndDate: format(addDays(now, -31), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.last30days'),
            name: 'last30days',
        };
    };
    export const getTimeRangeToday = () => {
        const now = new Date();
        return {
            startDate: format(now, DATE_FORMAT),
            endDate: format(now, DATE_FORMAT),
            compareStartDate: format(addDays(now, -1), DATE_FORMAT),
            compareEndDate: format(addDays(now, -1), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.today'),
            name: 'today',
        };
    };
    export const getTimeRangeYesterday = () => {
        const now = new Date();
        return {
            startDate: format(addDays(now, -1), DATE_FORMAT),
            endDate: format(addDays(now, -1), DATE_FORMAT),
            compareStartDate: format(addDays(now, -2), DATE_FORMAT),
            compareEndDate: format(addDays(now, -2), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.yesterday'),
            name: 'yesterday',
        };
    };
    export const getTimeRangeCurrentWeek = () => {
        const now = new Date();
        const startOfCurrentWeek = startOfWeek(now, { weekStartsOn: 1 });
        const startOfPreviousWeek = startOfWeek(addWeeks(startOfCurrentWeek, -1), { weekStartsOn: 1 });
        return {
            startDate: format(startOfCurrentWeek, DATE_FORMAT),
            endDate: format(now, DATE_FORMAT),
            compareStartDate: format(startOfPreviousWeek, DATE_FORMAT),
            compareEndDate: format(endOfWeek(startOfPreviousWeek, { weekStartsOn: 1 }), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.currentWeek'),
            name: 'currentWeek',
        };
    };
    export const getTimeRangeLastWeek = () => {
        const now = new Date();
        const currentWeek = startOfWeek(now, { weekStartsOn: 1 });
        const startOfOneWeekBefore = startOfWeek(addWeeks(currentWeek, -1), { weekStartsOn: 1 });
        const startOfTwoWeeksBefore = startOfWeek(addWeeks(currentWeek, -2), { weekStartsOn: 1 });
        return {
            startDate: format(startOfOneWeekBefore, DATE_FORMAT),
            endDate: format(endOfWeek(startOfOneWeekBefore, { weekStartsOn: 1 }), DATE_FORMAT),
            compareStartDate: format(startOfTwoWeeksBefore, DATE_FORMAT),
            compareEndDate: format(endOfWeek(startOfTwoWeeksBefore, { weekStartsOn: 1 }), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.lastWeek'),
            name: 'lastWeek',
        };
    };
    export const getTimeRangeCurrentMonth = () => {
        const now = new Date();
        const startOfCurrentMonth = startOfMonth(now);
        const startOfPreviousMonth = startOfMonth(addMonths(startOfCurrentMonth, -1));
        return {
            startDate: format(startOfCurrentMonth, DATE_FORMAT),
            endDate: format(now, DATE_FORMAT),
            compareStartDate: format(startOfPreviousMonth, DATE_FORMAT),
            compareEndDate: format(endOfMonth(startOfPreviousMonth), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.currentMonth'),
            name: 'currentMonth',
        };
    };
    export const getTimeRangeLastMonth = () => {
        const now = new Date();
        const currentMonth = startOfMonth(now);
        const startOfOneMonthBefore = startOfMonth(addMonths(currentMonth, -1));
        const startOfTwoMonthsBefore = startOfMonth(addMonths(currentMonth, -2));
        return {
            startDate: format(startOfOneMonthBefore, DATE_FORMAT),
            endDate: format(endOfMonth(startOfOneMonthBefore), DATE_FORMAT),
            compareStartDate: format(startOfTwoMonthsBefore, DATE_FORMAT),
            compareEndDate: format(endOfMonth(startOfTwoMonthsBefore), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.lastMonth'),
            name: 'lastMonth',
        };
    };
    export const getTimeRangeCurrentQuarter = () => {
        const now = new Date();
        const startOfCurrentQuarter = startOfQuarter(now);
        const startOfPreviousQuarter = startOfQuarter(addQuarters(startOfCurrentQuarter, -1));
        return {
            startDate: format(startOfCurrentQuarter, DATE_FORMAT),
            endDate: format(now, DATE_FORMAT),
            compareStartDate: format(startOfPreviousQuarter, DATE_FORMAT),
            compareEndDate: format(endOfQuarter(startOfPreviousQuarter), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.currentQuarter'),
            name: 'currentQuarter',
        };
    };
    export const getTimeRangeLastQuarter = () => {
        const now = new Date();
        const currentQuarter = startOfQuarter(now);
        const startOfOneQuarterBefore = startOfQuarter(addQuarters(currentQuarter, -1));
        const startOfTwoQuartersBefore = startOfQuarter(addQuarters(currentQuarter, -2));
        return {
            startDate: format(startOfOneQuarterBefore, DATE_FORMAT),
            endDate: format(endOfQuarter(startOfOneQuarterBefore), DATE_FORMAT),
            compareStartDate: format(startOfTwoQuartersBefore, DATE_FORMAT),
            compareEndDate: format(endOfQuarter(startOfTwoQuartersBefore), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.lastQuarter'),
            name: 'lastQuarter',
        };
    };
    export const getTimeRangeCurrentYear = () => {
        const now = new Date();
        const startOfCurrentYear = startOfYear(now);
        const startOfPreviousYear = startOfYear(addYears(startOfCurrentYear, -1));
        return {
            startDate: format(startOfCurrentYear, DATE_FORMAT),
            endDate: format(now, DATE_FORMAT),
            compareStartDate: format(startOfPreviousYear, DATE_FORMAT),
            compareEndDate: format(endOfYear(startOfPreviousYear), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.currentYear'),
            name: 'currentYear',
        };
    };
    export const getTimeRangeLastYear = () => {
        const now = new Date();
        const currentYear = startOfYear(now);
        const startOfOneYearBefore = startOfYear(addYears(currentYear, -1));
        const startOfTwoYearsBefore = startOfYear(addYears(currentYear, -2));
        return {
            startDate: format(startOfOneYearBefore, DATE_FORMAT),
            endDate: format(endOfYear(startOfOneYearBefore), DATE_FORMAT),
            compareStartDate: format(startOfTwoYearsBefore, DATE_FORMAT),
            compareEndDate: format(endOfYear(startOfTwoYearsBefore), DATE_FORMAT),
            label: i18n.t('timeRangeDropdown.lastYear'),
            name: 'lastYear',
        };
    };
    export const getTimeRangeCustom = dates => {
        const dateFrom = format(dates[0], DATE_FORMAT);
        const dateTo = format(dates[1], DATE_FORMAT);
        const differenceInDays = differenceInCalendarDays(dateTo, dateFrom);
        return {
            startDate: dateFrom,
            endDate: dateTo,
            compareStartDate: format(addDays(dateFrom, -(differenceInDays + 1)), DATE_FORMAT),
            compareEndDate: format(addDays(dateTo, -(differenceInDays + 1)), DATE_FORMAT),
            label: `${dateFrom} - ${dateTo}`,
            name: 'custom',
        };
    };

    export const getDefaultTimeRange = getTimeRangeLast30Days;

    const TIME_RANGES = {
        last7Days: getTimeRangeLast7Days,
        last14Days: getTimeRangeLast14Days,
        last30days: getTimeRangeLast30Days,
        today: getTimeRangeToday,
        yesterday: getTimeRangeYesterday,
        currentWeek: getTimeRangeCurrentWeek,
        lastWeek: getTimeRangeLastWeek,
        currentMonth: getTimeRangeCurrentMonth,
        lastMonth: getTimeRangeLastMonth,
        currentQuarter: getTimeRangeCurrentQuarter,
        lastQuarter: getTimeRangeLastQuarter,
        currentYear: getTimeRangeCurrentYear,
        lastYear: getTimeRangeLastYear,
        custom: getTimeRangeCustom,
    };

    const AVAILABLE_OPTIONS = Object.keys(TIME_RANGES);

    const initTimeRangeCache = () =>
        AVAILABLE_OPTIONS.reduce((acc, name) => {
            if (name !== 'custom') {
                acc[name] = TIME_RANGES[name](); // eslint-disable-line
            }
            return acc;
        }, {});

    const DEFAULT_TIME_RANGE = getDefaultTimeRange();

    export default {
        name: 'TimeRangeDropdown',
        props: {
            value: {
                type: Object,
                default: () => {
                    const { startDate, endDate, compareStartDate, compareEndDate } = DEFAULT_TIME_RANGE;
                    return { startDate, endDate, compareStartDate, compareEndDate };
                },
            },
            options: {
                type: Array,
                default: () => [...AVAILABLE_OPTIONS],
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                today: format(new Date(), DATE_FORMAT),
                timeRangeCache: initTimeRangeCache(),
                timeRange: { ...DEFAULT_TIME_RANGE },
                dateFormat: DATE_FORMAT,
                datepickerShow: false,
                datepickerConfig: {
                    mode: 'range',
                    locale: getFlatpickrLocale(this.$i18n.locale),
                    maxDate: new Date(),
                },
            };
        },
        computed: {
            stringifyValue() {
                const { startDate, endDate, compareStartDate, compareEndDate } = this.timeRange;
                const timeRangeValue = startDate && endDate ? { startDate, endDate } : undefined;
                return timeRangeValue ? JSON.stringify({ startDate, endDate, compareStartDate, compareEndDate }) : undefined;
            },
        },
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    if (value) {
                        const { startDate, endDate } = value;
                        if (this.timeRange.startDate !== startDate || this.timeRange.endDate !== endDate) {
                            if (!startDate || !endDate) {
                                this.timeRange = { ...DEFAULT_TIME_RANGE };
                            } else {
                                const timeRange = Object.values(this.timeRangeCache).find(range => range.startDate === startDate && range.endDate === endDate);
                                if (timeRange && this.options.includes(timeRange.name)) {
                                    this.timeRange = { ...timeRange };
                                } else {
                                    this.timeRange = getTimeRangeCustom([startDate, endDate]);
                                }
                            }
                            this.$refs.datepicker?.fp?.clear();
                        }
                    }
                },
            },
            stringifyValue: {
                immediate: true,
                handler() {
                    if (this.stringifyValue) this.$emit('input', JSON.parse(this.stringifyValue));
                },
            },
        },
        methods: {
            emitData() {
                // this.$emit('input', this.timeRange);
                this.$emit('startDate', this.timeRange.startDate);
                this.$emit('endDate', this.timeRange.endDate);
                this.$emit('timeRange', this.timeRange);
            },
            checkCacheIsUpToDate() {
                const currentToday = format(new Date(), DATE_FORMAT);
                if (this.today !== currentToday) {
                    this.today = currentToday;
                    this.timeRangeCache = initTimeRangeCache();
                }
            },
            initDatepicker() {
                this.datepickerConfig.appendTo = this.$refs.datepickerOption;
                this.datepickerConfig.positionElement = this.$refs.btnOpenDatepicker;
                this.datepickerShow = true;
            },
            openDatepicker() {
                this.$refs.datepicker.fp.open();
            },
            setTimeRange(rangeName) {
                this.checkCacheIsUpToDate();
                this.timeRange = { ...this.timeRangeCache[rangeName] };
                this.$refs.datepicker?.fp?.clear();
                this.$nextTick(this.emitData);
            },
            setTimeRangeCustom(dates) {
                if (dates.length === 2) {
                    this.timeRange = getTimeRangeCustom(dates);
                    this.$refs.timeRangeDropdown.hide();
                    this.$nextTick(this.emitData);
                }
            },
            getTimeRangeLast7Days,
            getTimeRangeLast14Days,
            getTimeRangeLast30Days,
            getTimeRangeToday,
            getTimeRangeYesterday,
            getTimeRangeCurrentWeek,
            getTimeRangeLastWeek,
            getTimeRangeCurrentMonth,
            getTimeRangeLastMonth,
            getTimeRangeCurrentQuarter,
            getTimeRangeLastQuarter,
            getTimeRangeCurrentYear,
            getTimeRangeLastYear,
        },
        created() {
            if (!this.value) {
                if (this.stringifyValue) {
                    this.$emit('input', JSON.parse(this.stringifyValue));
                    this.emitData();
                }
            }
        },
        mounted() {
            this.initDatepicker();
        },
    };
</script>



<style lang="scss" scoped>
    .datepicker-option {
        ::v-deep .flatpickr-calendar {
            top: 0 !important;
            right: 100% !important;
        }
    }
</style>
