<template>
    <row-labeled :row="row">
        <template v-if="label || $scopedSlots.label" #label>
            <slot name="label">
                <span>{{ label }} <span v-if="isRequired" class="text-danger">*</span></span>
            </slot>
        </template>

        <template #content="{ htmUniqueId }">
            <div v-if="icon" class="input-group-prepend">
                <span class="input-group-text py-0">
                    <feather :type="icon" class="align-middle icon-dual" />
                </span>
            </div>

            <b-form-file
                :id="htmUniqueId"
                v-model="model"
                :class="{ 'is-invalid': hasValidationErrors }"
                :disabled="disabled"
                :accept="acceptedFormats"
                :browse-text="$t('fileField.browseText')"
                :drop-placeholder="$t('fileField.dropPlaceholder')"
                :no-drop-placeholder="$t('fileField.noDropPlaceholder')"
                :placeholder="placeholder"
            />

            <div v-if="hasValidationErrors" class="invalid-feedback">
                {{ validationErrors.join(' ') }}
            </div>
        </template>
    </row-labeled>
</template>

<script>
    import RowLabeled from './RowLabeled.vue';
    import validatableFieldMixin from '../../mixins/validatableFieldMixin';

    export default {
        name: 'FileField',
        mixins: [validatableFieldMixin],
        props: {
            /**
             * @model
             */
            value: {
                type: [File],
            },
            label: {
                type: String,
            },
            placeholder: {
                type: String,
                default() {
                    return this.$t('fileField.placeholder');
                },
            },
            icon: {
                type: String,
            },
            row: {
                type: Boolean,
            },
            disabled: {
                type: Boolean,
            },
            acceptedFormats: {
                type: String,
            },
        },
        components: { RowLabeled },
    };
</script>
