<template>
    <DataTable
        v-model="selectedRows"
        :header="$t('withdrawalOrders')"
        :error="error || confirmError || transferFileDownloadError"
        :items="items"
        :fields="fields"
        :total-rows="payouts.max"
        :per-page.sync="tableConfig.perPage"
        :sort-by.sync="tableConfig.sortBy"
        :sort-direction.sync="tableConfig.sortDirection"
        :current-page.sync="tableConfig.currentPage"
        :loading="loading"
        :busy-items="busyItems"
        no-sort-reset
        selectable
        selectable-id-key="id"
        :selectable-item-function="selectableItemFunction"
    >

        <template #cell(publisher)="data">
            {{ data.item.publisher.name }} {{ data.item.publisher.email ? `(${data.item.publisher.email})` : '' }}
        </template>

        <template #cell(amount)="data">
            {{ $format.currency(data.value) }}
        </template>

        <template #cell(requestedAt)="data">
            {{ $format.date(data.value, 'dd-MM-yyyy') }}
        </template>

        <template #cell(status)="data">
            <TipboxStatusBadge :status="data.item.status" :label="getStatusLabel(data.item.status)" />
        </template>

        <template #headerSide>
            <TimeRangeDropdown v-model="filters.timeRange" :disabled="loading" :options="['last7Days', 'last30days', 'currentWeek', 'currentMonth', 'today', 'custom']" />
        </template>

        <template #innerPagination>
            <div class="d-flex gap-3 align-items-center">
                <Multiselect
                    v-model="tableAction"
                    :options="tableActionOptions"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    :disabled="isItemsBusy"
                    class="width-250 d-inline-block"
                />
                <b-button variant="primary" :disabled="!tableAction || !tableAction.value || !items.length || loading || confirming || transferFileDownloading || isItemsBusy || !selectedRows.length" @click="initTableAction()">
                    {{ $t('save') }}
                </b-button>
            </div>
        </template>

    </DataTable>
</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';
    import TimeRangeDropdown from '@/components/common/TimeRangeDropdown.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import TipboxStatusBadge from '@/components/common/TipboxStatusBadge.vue';
    import exportService from '@/services/exportService';

    export default {
        name: 'Payouts',
        components: {
            DataTable,
            TimeRangeDropdown,
            Multiselect,
            TipboxStatusBadge,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                filters: {
                    timeRange: null,
                },
                tableConfig: {
                    perPage: 50,
                    currentPage: 1,
                    sortBy: 'createdAt',
                    sortDirection: 'desc',
                },
                tableAction: null,
                tableActionOptions: [
                    { value: null, text: this.$t('select') },
                    { value: 'download', text: this.$t('downloadTransferFile') },
                    { value: 'mark', text: this.$t('markAsSettled') },
                ],
                fields: [
                    {
                        key: 'requestedAt',
                        label: this.$t('date'),
                        sortable: true,
                    },
                    {
                        key: 'publisher',
                        label: this.$t('publisherRecipient'),
                        sortable: true,
                    },
                    {
                        key: 'amount',
                        label: this.$t('paymentAmount'),
                        sortable: true,
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'status',
                        label: this.$t('status'),
                        sortable: true,
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                ],
                busyItems: [],
                selectedRows: [],
                selectableItemFunctionDefault: item => item.status !== 'paid',
                selectableItemFunctionMark: item => item.status === 'requested',
            };
        },
        computed: {
            payouts() {
                return this.$store.getters['tipbox/getPayouts'];
            },
            items() {
                return this.payouts.items || [];
            },
            error() {
                return this.$store.getters['tipbox/getError']('payouts');
            },
            loading() {
                return this.$store.getters['tipbox/getLoading']('payouts');
            },
            confirming() {
                return this.$store.getters['tipbox/getLoading']('payoutsConfirm');
            },
            confirmError() {
                return this.$store.getters['tipbox/getError']('payoutsConfirm');
            },
            transferFileDownloading() {
                return this.$store.getters['tipbox/getLoading']('payoutsStatsTransferFileDownload');
            },
            transferFileDownloadError() {
                return this.$store.getters['tipbox/getError']('payoutsStatsTransferFileDownload');
            },
            isItemBusy() {
                return id => !!this.busyItems.find(itemId => itemId === id);
            },
            isItemsBusy() {
                return !!this.busyItems.length;
            },
            filtersParams() {
                const filters = {};
                if (this.filters.timeRange?.startDate) filters.from = this.filters.timeRange.startDate;
                if (this.filters.timeRange?.endDate) filters.to = this.filters.timeRange.endDate;
                return filters;
            },
            tableParams() {
                return {
                    page: 1,
                    perPage: Number.MAX_SAFE_INTEGER,
                };
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                    ...this.tableParams,
                };
            },
            statuses() {
                return this.$store.getters['tipbox/getPayoutsStatuses'] || {};
            },
            selectableItemFunction() {
                if (this.tableAction?.value === 'mark') return this.selectableItemFunctionMark;
                return this.selectableItemFunctionDefault;
            },
        },
        watch: {
            selectedRows() {
                if (this.selectedRows.length) this.$toastr.close('noPayoutsSelected');
            },
            requestParams: {
                immediate: true,
                handler: 'fetchData',
            },
        },
        methods: {
            async initTableAction() {
                if (!this.selectedRows.length) {
                    this.$toastr.info(this.$t('noPayoutsSelected'), null, { id: 'noPayoutsSelected' });
                } else if (this.tableAction.value === 'download') {
                    await this.downloadTransferFile();
                } else if (this.tableAction.value === 'mark') {
                    await this.markAsSettled();
                }
            },
            async downloadTransferFile() {
                this.busyItems = [...this.selectedRows];
                return this.$store
                    .dispatch('tipbox/fetchPayoutsStatsTransferFileDownload', { requestParams: { payouts: this.selectedRows.join(',') }, responseType: 'blob' })
                    .then(response => {
                        const data = window.URL.createObjectURL(response);
                        const name = `${this.$t('transferFile')}-${this.selectedRows.join('-')}`;
                        exportService.exportData(data, name, 'zip');
                    })
                    .catch(() => {})
                    .finally(() => (this.busyItems = []));
            },
            async markAsSettled() {
                this.busyItems = [...this.selectedRows];
                this.$store
                    .dispatch('tipbox/fetchPayoutsConfirm', { requestParams: { payouts: this.selectedRows.join(',') } })
                    .then(this.fetchData)
                    .catch(() => {})
                    .finally(() => (this.busyItems = []));
            },
            fetchData() {
                this.$store.dispatch('tipbox/fetchPayouts', { requestParams: this.requestParams }).catch(() => {});
            },
            fetchStatuses() {
                this.$store.dispatch('tipbox/fetchPayoutsStatuses').catch(error => this.$toastr.error(this.$t(error.message), this.$t('errorOccurred')));
            },
            getStatusLabel(status) {
                return this.statuses?.find(item => item.value === status)?.label || '';
            },
        },
        created() {
            this.fetchStatuses();
        },
    };
</script>
