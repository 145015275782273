import { i18n } from '@/i18n/i18n';
import apiService from './apiService';
import errorService from './errorService';

export const PAYOUT_THRESHOLD = 50;

export const FinanceDocumentStatus = Object.freeze({
    NEW: '0',
    ACCEPTED: '1',
    REJECTED: '2',
    PAID: '3',
    ABANDONED: '4',
});

export const translateFinanceDocumentStatus = status => {
    switch (status.toString()) {
        case FinanceDocumentStatus.NEW:
            return i18n.t('financeDocumentStatus.new');
        case FinanceDocumentStatus.ACCEPTED:
            return i18n.t('financeDocumentStatus.accepted');
        case FinanceDocumentStatus.REJECTED:
            return i18n.t('financeDocumentStatus.rejected');
        case FinanceDocumentStatus.PAID:
            return i18n.t('financeDocumentStatus.paid');
        case FinanceDocumentStatus.ABANDONED:
            return i18n.t('financeDocumentStatus.abandoned');
        default:
            return '-';
    }
};

export default {
    async fetchCommissions(publisherId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/publisher/${publisherId}/commissions`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchFinanceDocuments(userId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/users/${userId}/finance-documents`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async createBill(publisherId) {
        try {
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/publisher/${publisherId}/bills`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async sendInvoice(publisherId, number, pdf) {
        try {
            const formData = new FormData();
            formData.append('number', number);
            formData.append('pdf', pdf);
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/publisher/${publisherId}/invoices`, formData);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};
