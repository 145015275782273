<template>
    <card :header="$t('accountSettingsPassword.title')">
        <form novalidate @submit.prevent="handleSubmit">
            <div class="row">
                <text-field
                    v-model="model.oldPassword"
                    :validation="$v.model.oldPassword"
                    :label="$t('accountSettingsPassword.oldPassword')"
                    class="col-sm-6"
                    type="password"
                />
                <div class="col-sm-6"></div>
                <text-field
                    v-model="model.newPassword"
                    :validation="$v.model.newPassword"
                    :label="$t('accountSettingsPassword.newPassword')"
                    class="col-sm-6"
                    type="password"
                />
                <text-field
                    v-model="model.confirmPassword"
                    :validation="$v.model.confirmPassword"
                    :label="$t('accountSettingsPassword.confirmPassword')"
                    class="col-sm-6"
                    type="password"
                />
            </div>
            <error-message v-model="saveError" />
            <div class="row">
                <div class="col-sm-6 mt-2 form-group">
                    <button-default :loading="submitting" type="submit" block>
                        {{ $t('accountSettingsPassword.submit') }}
                    </button-default>
                </div>
            </div>
        </form>
    </card>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { required, sameAs } from 'vuelidate/lib/validators';
    import TextField from '@/components/common/TextField.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import Card from '@/components/common/Card.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'WidgeterAccountSettingsPassword',
        components: {
            ErrorMessage,
            Card,
            ButtonDefault,
            TextField,
        },
        validations: {
            model: {
                oldPassword: { required },
                newPassword: { required },
                confirmPassword: {
                    required,
                    sameAs: sameAs('newPassword'),
                },
            },
        },
        data() {
            return {
                model: {
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                },
                saveError: null,
            };
        },
        computed: {
            ...mapState({
                submitting: state => state.auth.loading.changePassword,
            }),
        },
        methods: {
            async handleSubmit() {
                this.saveError = null;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                try {
                    await this.changePassword({ ...this.model });
                    this.$v.$reset();
                    this.model = {
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                    };
                } catch (e) {
                    this.saveError = e.message;
                }
            },
            ...mapActions({
                changePassword: 'auth/changePassword',
            }),
        },
    };
</script>
