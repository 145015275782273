import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    CAMPAIGNS: {
        URI: () => '/api/v1/campaigns/{{id?}}',
        methods: ['get', 'patch'],
        cacheToClear: ['/api/v1/campaigns'],
    },
    SHOW_SPACES: {
        URI: () => '/api/v1/campaigns/{{campaignId}}/show-spaces',
        methods: ['get'],
    },
    SHOW_SPACES_ACTION: {
        URI: () => '/api/v1/campaigns/{{campaignId}}/show-spaces/{{spaceId}}',
        methods: ['put'],
    },
    CAMPAIGNS_COMMISSIONS: {
        URI: () => '/api/v1/campaigns/{{id?}}/commissions',
        methods: ['get', 'post'],
    },
    COMMISSIONS: {
        URI: () => '/api/v1/commissions/{{id?}}',
        methods: ['delete', 'patch'],
    },
    ADVERTISER_CAMPAIGNS: {
        URI: () => '/api/v1/reporters/{{userId}}/campaigns',
        methods: ['get'],
        default: [],
        formatter: data => data?.items,
        defaultParams: {
            get: {
                sort: 'createdAt',
                order: 'DESC',
                page: 1,
                perPage: Number.MAX_SAFE_INTEGER,
                active: true,
            },
        },
    },
    IMAGES: {
        URI: () => '/api/v1/campaigns/{{campaignId}}/images',
        methods: ['get', 'post'],
    },
    OFFERS: {
        URI: () => '/api/v1/campaigns/{{campaignId}}/offers',
        methods: ['get', 'post'],
        default: {},
    },
    SPACES: {
        URI: () => '/api/v1/campaigns/{{campaignId}}/spaces',
        methods: ['get'],
        default: [],
        formatter: data => data?.items,
    },
    SPACES_ACTION: {
        URI: () => '/api/v1/campaigns/{{campaignId}}/spaces/{{spaceId}}/{{action}}',
        methods: ['put'],
        cacheToClear: ['/api/v1/campaigns/{{campaignId}}/spaces'],
    },
    INTEGRATION_REPORTS: {
        URI: () => '/api/v1/campaign-integration-reports',
        methods: ['get'],
    },
    FEEDS: {
        URI: () => '/api/v1/campaigns/{{campaignId}}/product-feeds',
        methods: ['get'],
        default: [],
    },
    CATEGORIES: {
        URI: () => '/api/v1/campaigns/{{campaignId}}/categories/{{id?}}',
        methods: ['get', 'delete', 'put'],
        default: [],
        formatter: data => data?.items,
        cacheToClear: ['api/v1/campaigns/{{campaignId}}/categories', '/api/v1/campaigns'],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
