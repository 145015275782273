import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    VERIFICATIONS_USERS: {
        URI: () => '/api/v1/tipbox-transfer-list',
        methods: ['post'],
        default: [],
    },
    VERIFICATIONS: {
        URI: () => '/api/v1/publishers/{{publisherId}}/verify-tipbox',
        methods: ['put'],
        cache: false,
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
