<template>
    <div>
        <div class="account-pages mt-5 mb-5">
            <div class="container">
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
            </div>
        </div>
    </div>
</template>



<script>
    export default {
        name: 'LayoutBasic',
    };
</script>
