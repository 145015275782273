<template>
    <div>
        <PageHeader>
            {{ $t('offersCatalog') }}
        </PageHeader>

        <Card :header="$t('findProduct')">
            <b-form-group>
                <b-form-input v-model="formSearchText" :placeholder="$t('generator.productSearchPlaceholder')" :disabled="loading" @keyup.enter="search()" />
            </b-form-group>
            <ButtonIcon :loading="!!(loading && items.length)" :disabled="loading || searchText === formSearchText" icon="filter" @click="search()">
                {{ $t('search') }}
            </ButtonIcon>
        </Card>

        <Card :header="$t('transactions.productsList')">
            <ErrorMessage :value="error" />
            <b-overlay :show="!!(loading && items.length)" opacity="0" spinner-variant="secondary">
                <b-table
                    hover
                    :items="items"
                    :fields="fields"
                    show-empty
                    :empty-text="$t('noResult')"
                    :busy="loading"
                >
                    <template v-if="!items.length" #table-busy>
                        <div class="d-flex justify-content-center my-2">
                            <b-spinner />
                        </div>
                    </template>
                    <template #cell(name)="data">
                        <div class="d-flex">
                            <ImageLoader
                                :src="data.item.image"
                                alt=""
                                size="100"
                                image-height="auto"
                                class="mr-2 flex-shrink-0"
                            />
                            <div class="d-flex flex-column">
                                <b-link class="title" :to="createItemUrl(data.item)">
                                    {{ data.item.name }}
                                </b-link>
                                <span class="info text-secondary">{{ data.item.info }}</span>
                            </div>
                        </div>
                    </template>
                    <template #cell(price)="data">
                        {{ $format.currency(data.value) }}
                    </template>
                    <template #cell(active)="data">
                        <BadgeBoolean :value="data.value" />
                    </template>
                    <template #cell(aggregated)="data">
                        <BadgeBoolean :value="data.value" />
                    </template>
                    <template #cell(updatedAt)="data">
                        {{ dateFormat(data.value, 'yyyy-MM-dd HH:mm') }}
                    </template>
                </b-table>
            </b-overlay>
            <Pagination
                :value="currentPage"
                :total-rows="totalPages"
                :per-page="perPage"
                :disabled="loading"
                @change="page => currentPage = Number.parseInt(page)"
            />
        </Card>
    </div>
</template>



<script>
    import slugify from 'slugify';
    import { format as dateFormat } from 'date-fns';
    import ButtonIcon from '@/components/common/ButtonIcon.vue';
    import PageHeader from '@/components/common/PageHeader.vue';
    import storageKeys from '@/Storage';
    import Card from '@/components/common/Card.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import Pagination from '@/components/common/Pagination.vue';
    import ImageLoader from '@/components/common/ImageLoader.vue';
    import BadgeBoolean from '@/components/common/BadgeBoolean.vue';

    const PER_PAGE = 50;

    export default {
        name: 'ProductList',
        components: {
            PageHeader,
            Card,
            ErrorMessage,
            Pagination,
            ImageLoader,
            BadgeBoolean,
            ButtonIcon,
        },
        data() {
            return {
                fields: [
                    {
                        key: 'id',
                        label: 'ID',
                    },
                    {
                        key: 'name',
                        label: this.$t('name'),
                    },
                    {
                        key: 'ean',
                        label: this.$t('eanisbn'),
                    },
                    {
                        key: 'price',
                        label: this.$t('price'),
                        tdClass: 'text-right nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'active',
                        label: this.$t('available'),
                    },
                    {
                        key: 'aggregated',
                        label: this.$t('aggregated'),
                    },
                    {
                        key: 'updatedAt',
                        label: this.$t('lastUpdate'),
                    },
                ],
                perPage: PER_PAGE,
                currentPage: Number.parseInt(this.$route.query.page) || 1,
                formSearchText: this.$route.query.search || '',
                searchText: this.$route.query.search || '',
            };
        },
        computed: {
            campaignId() {
                return this.$store.getters['campaigns/getCurrentCampaign']?.id;
            },
            apiParams() {
                return {
                    requestParams: {
                        page: this.currentPage,
                        perPage: this.perPage,
                        search: this.searchText,
                    },
                    urlParams: {
                        campaignId: this.campaignId,
                    },
                };
            },
            error() {
                return this.$store.getters['campaigns/getError']('offers')?.message;
            },
            loading() {
                return this.$store.getters['campaigns/getBusy']('offers');
            },
            items() {
                return this.$store.getters['campaigns/getOffers']?.items || [];
            },
            totalPages() {
                return this.$store.getters['campaigns/getOffers']?.max;
            },
        },
        watch: {
            apiParams: {
                immediate: true,
                deep: true,
                handler: 'fetchData',
            },
            totalPages() {
                const numberOfPages = Math.ceil(this.totalPages / this.perPage);
                if (this.currentPage > numberOfPages) this.currentPage = numberOfPages || 1;
            },
            '$route.query'() { // eslint-disable-line
                if (this.$route.query.page !== this.currentPage) this.currentPage = Number.parseInt(this.$route.query.page || 1);
                if (this.$route.query.search !== this.searchText) this.searchText = this.$route.query.search || '';
            },
            currentPage: {
                immediate: true,
                handler: 'initQueryParams',
            },
            searchText: {
                immediate: true,
                handler: 'initQueryParams',
            },
            campaignId() {
                this.currentPage = 1;
                this.searchText = '';
                this.clearStoreData();
            },
        },
        methods: {
            dateFormat,
            createItemUrl(item) {
                const locale = window.localStorage.getItem(storageKeys.LOCALE) || 'en';
                const id = item.id;
                const slug = slugify(item.name, { replacement: '-', lower: true, locale });
                return {
                    name: 'OffersCatalogProductDetails',
                    params: { id, slug, sep: '-', parentUrl: this.$route.fullPath },
                    query: {},
                };
            },
            fetchData() {
                this.$store.dispatch('campaigns/fetchOffers', this.apiParams);
            },
            clearStoreData() {
                this.$store.commit('campaigns/clearOffers');
            },
            search() {
                if (this.searchText === this.formSearchText) this.fetchData();
                this.searchText = this.formSearchText;
                this.currentPage = 1;
                this.perPage = PER_PAGE;
            },
            initQueryParams() {
                const query = {};
                if (this.currentPage) query.page = this.currentPage;
                if (this.searchText) query.search = this.searchText;
                this.$router.push({ query }).catch(() => {});
            },
        },
        created() {
            this.clearStoreData();
        },
    };
</script>
