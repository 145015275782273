<template>
    <b-container class="m-auto error-404">
        <b-row>
            <b-col md="6" class="text-center text-md-right d-flex align-items-center">
                <div class="w-100 pr-md-3 mb-4 mb-md-0">
                    <img
                        src="../../../assets/logo-buybox.png"
                        alt="BUY.BOX"
                        class="logo"
                    >
                </div>
            </b-col>
            <b-col md="6" class="text-center text-md-left d-flex align-items-center">
                <div class="w-100 pl-md-3">
                    <div class="display-1">
                        404
                    </div>
                    <div class="h2">
                        {{ $t('pageNotFound.title') }}
                    </div>
                    <p>{{ $t('pageNotFound.text') }}</p>
                    <b-button variant="outline-secondary" :to="{name: 'Dashboard'}">
                        {{ $t('pageNotFound.buttonText') }}
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>



<script>
    export default {
        name: 'PageNotFound',
    };
</script>



<style lang="scss" scoped>
    .logo {
        width: 30vw;
        max-width: 400px;
    }
</style>

