var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',[_vm._v(" "+_vm._s(_vm.$t('feedImportSummary'))+" ")]),_c('div',{staticClass:"accordion d-flex flex-column gap-3"},_vm._l((_vm.TYPES),function(type){return _c('div',{key:type},[_c('ButtonIcon',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("accordion-" + type)),expression:"`accordion-${type}`"}],attrs:{"block":"","variant":"primary","icon":"chevron-down","flip-icon":"","label-class":"flex-grow-1"}},[_vm._v(" "+_vm._s(_vm.typeHeaders[type])+" ")]),_c('b-collapse',{attrs:{"id":("accordion-" + type),"visible":""}},[_c('DataTable',{key:type,attrs:{"items":_vm.getFeedsProvider(type),"fields":_vm.fields(type),"pagination":false,"no-provider-sorting":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('b-link',{attrs:{"to":{ name: 'CampaignsDetailsTabEdit', params: { id: item.campaign.id, tab: 'feeds', itemId: item.id } }}},[_vm._v(_vm._s(value))])]}},{key:"cell(createdAt)",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(_vm._s(_vm.dateFormat(value, 'yyyy-MM-dd HH:mm:ss')))]:_vm._e()]}},{key:"cell(updatedAt)",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(_vm._s(_vm.dateFormat(value, 'yyyy-MM-dd HH:mm:ss')))]:_vm._e()]}},{key:"cell(offerFeed.processStartedAt)",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(_vm._s(_vm.dateFormat(value, 'yyyy-MM-dd HH:mm:ss')))]:_vm._e()]}},{key:"cell(offerFeed.processEndedAt)",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(_vm._s(_vm.dateFormat(value, 'yyyy-MM-dd HH:mm:ss')))]:_vm._e()]}}],null,true)})],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }