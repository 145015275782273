<template>
    <div>
        <PageHeader>
            {{ $t('webhook') }}
        </PageHeader>
        <b-overlay :show="submitting" opacity="0" class="h-100">
            <b-card>
                <div class="d-flex flex-column gap-3">
                    <FormValidator :validator="$v.form.id" v-slot="slotProps">
                        <SpaceSwitch v-model="form.id" :disabled="submitting" :label="$t('selectedSpace')" :state="slotProps.state" class="mb-0" />
                    </FormValidator>
                    <template v-if="form.id">
                        <FormValidator :validator="$v.form.webhook" :label="$t('webhookUrl')" form-group class="mb-0" v-slot="slotProps">
                            <b-form-input v-model="form.webhook" :placeholder="$t('https')" :state="slotProps.state" :disabled="submitting" />
                        </FormValidator>
                        <div class="d-flex">
                            <ButtonIcon class="ml-auto" variant="primary" :disabled="loading || submitting" @click="updateSpaceWebhook()">
                                {{ $t('save') }}
                            </ButtonIcon>
                        </div>
                    </template>
                </div>
            </b-card>
        </b-overlay>
    </div>
</template>



<script>
    import { required, url } from 'vuelidate/lib/validators';
    import SpaceSwitch from '@/components/common/SpaceSwitch.vue';
    import FormValidator from '@/components/common/FormValidator.vue';

    export default {
        name: 'Webhook',
        components: {
            SpaceSwitch,
            FormValidator,
        },
        data() {
            return {
                form: {
                    id: null,
                    webhook: '',
                },
            };
        },
        validations() {
            return {
                form: {
                    id: { required },
                    webhook: { url },
                },
            };
        },
        computed: {
            loading() {
                return this.$store.state.space.loading.fetchSpaces;
            },
            submitting() {
                return this.$store.state.space.loading.updateSpace;
            },
            spaces() {
                return this.$store.state.space.spaces;
            },
            currentSpace() {
                return this.spaces[this.form.id];
            },
        },
        watch: {
            'form.id': {
                handler() {
                    this.$set(this.form, 'webhook', this.currentSpace?.webhook || '');
                },
            },
        },
        methods: {
            updateSpaceWebhook() {
                this.$v.form.$touch();
                if (this.$v.form.$invalid) return;
                this.$store.dispatch('space/updateSpaceWebhook', this.form).then(this.requestSuccess).catch(this.requestError);
            },
            requestSuccess(data) {
                this.$toastr.success(this.$t('pages.updateWebhookSuccess', { name: data.name }));
                this.resetForm();
                this.fetchSpaces();
            },
            requestError(error) {
                this.$toastr.error(error.message, this.$t('errorOccurred'));
            },
            fetchSpaces() {
                return this.$store.dispatch('space/fetchSpaces', { force: true });
            },
            resetForm() {
                this.$v.form.$reset();
                this.form = {
                    id: null,
                    webhook: null,
                };
            },
        },
        created() {
            this.fetchSpaces();
        },
    };
</script>
