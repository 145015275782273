import store from '@/store/store';
import Role from '@/Role';
import { mode } from '@/utils/util';

const hasAccessGuard = async function hasAccessGuard(to, from, next) {
    if (!mode && to.name !== 'Forbidden') return next({ name: 'Forbidden' });

    const router = this;

    if (to.name === 'LoginAs') {
        await store.dispatch('auth/logout', { noRedirect: true });
    }

    const initSession = async () => {
        let state = null;
        if (!store.getters['auth/isSessionInitialized']) {
            store.commit('sessionInitialization', true);
            try {
                await store.dispatch('auth/validateSession');
                state = true;
            } catch (error) {
                state = false;
                store.commit('sessionInitializationError', error);
                router.app.$toastr.error(error.message);
            }
            store.commit('sessionInitialization', false);
        }
        return state;
    };

    const loadSpaces = async () => {
        let state = null;
        if (store.getters['auth/isRoleWidgeter'] && !store.getters['space/getSpacesInitialized']) {
            store.commit('spacesInitialization', true);
            try {
                await store.dispatch('space/fetchSpaces');
                state = true;
            } catch (error) {
                state = false;
                store.commit('spacesInitializationError', error);
                router.app.$toastr.error(error.message);
            }
            store.commit('space/setSpacesInitialized', state);
            store.commit('spacesInitialization', false);
        }
        return state;
    };

    const loadCampaigns = async () => {
        let state = null;
        if (store.getters['auth/isRoleAdvertiser'] && !store.getters['campaigns/getCampaignsInitialized']) {
            store.commit('campaignsInitialization', true);
            try {
                await store.dispatch('campaigns/fetchAdvertiserCampaigns');
                state = true;
            } catch (error) {
                state = false;
                store.commit('campaignsInitializationError', error);
                router.app.$toastr.error(error.message);
            }
            store.commit('campaigns/setCampaignsInitialized', state);
            store.commit('campaignsInitialization', false);
        }
        return state;
    };

    /* eslint-disable */
    const initSessionSuccess   = await initSession();
    const loadSpacesSuccess    = await loadSpaces();
    const loadCampaignsSuccess = await loadCampaigns();
    const spaceCount           = store.getters['space/spaceCount'];
    const campaignCount        = store.getters['campaigns/getAdvertiserCampaigns'].length;
    const userIsAdmin          = store.getters['auth/isAdmin'];
    const userIsAuthenticated  = store.getters['auth/isAuthenticated'];
    const termsAreAccepted     = store.getters['auth/hasTermsAccepted'];
    const userRole             = store.getters['auth/getRoleName'];
    const userRoleShort        = store.getters['auth/getRoleShortName'];
    const isGuestRoute         = to.matched.some(m => m.meta.isGuest);
    const isSecureRoute        = to.matched.some(m => m.meta.isSecure);
    const routeRole            = to.params.role?.toUpperCase();
    const hasAccessToRoute     = !to.meta.roles || to.meta.roles.includes(userRole);
    const routeRoleIsCorrect   = Role[routeRole] === userRole;
    /* eslint-enable */

    const getRouteConfig = () => {
        if (userIsAuthenticated && to.name === 'AcceptTerms') return {};
        if (!userIsAuthenticated && to.path === '/') return { name: 'Login' };
        if (userIsAuthenticated && to.path === '/') return { name: 'Dashboard', params: { role: userRoleShort } };
        if (!isGuestRoute && !isSecureRoute) return {};
        if (!userIsAuthenticated && isSecureRoute) return { name: 'Login' };
        if (userIsAuthenticated && !userIsAdmin && !termsAreAccepted && to.name !== 'AcceptTerms') return { name: 'AcceptTerms', query: { redirect: to.name } };
        if (userIsAuthenticated && isGuestRoute) return { name: 'Dashboard', params: { role: userRoleShort } };
        if (userIsAuthenticated && !routeRoleIsCorrect) return { path: `/${userRoleShort}` };
        if (userIsAuthenticated && hasAccessToRoute && !routeRoleIsCorrect) return { name: to.name, params: { role: userRoleShort } };
        if (userIsAuthenticated && !hasAccessToRoute) to.meta.accessDenied = true; // eslint-disable-line
        return {};
    };

    const nextRouteConfig = getRouteConfig();

    if (to.query.registration) nextRouteConfig.query = { ...(nextRouteConfig.query || {}), registration: to.query.registration };

    return next(nextRouteConfig);
};

export default hasAccessGuard;
