<template>
    <div>
        <PageHeader>
            {{ $t('catalog') }} - {{ $t('categories') }}
            <template #side>
                <ButtonIcon
                    class="ml-2"
                    icon="plus"
                    variant="primary"
                    :disabled="isLoading"
                    @click="editItem()"
                >
                    {{ $t('addNew') }}
                </ButtonIcon>
            </template>
        </PageHeader>

        <Card :header="$t('filters')">
            <b-form-group :label="$t('name')" label-cols-sm="3">
                <b-form-input v-model="filters.name" :disabled="isLoading" />
            </b-form-group>

            <b-form-group :label="$t('parent')" label-cols-sm="3">
                <Multiselect
                    :clear-on-select="false"
                    :close-on-select="true"
                    :show-no-results="true"
                    :show-no-options="true"
                    :preserve-search="true"
                    :asynchronous="true"
                    :fetch-options-function="fetchCategories"
                    :parse-options-function="parseCategories"
                    :placeholder="$t('selectField.placeholder')"
                    track-by="id"
                    label="name"
                    select-label=""
                    :disabled="isLoading"
                    @search-change="value => categoriesSearchText = value"
                    @input="value => filters.parent = value"
                />
            </b-form-group>

            <ButtonIcon :loading="isLoading" icon="filter" @click="fetchData()">
                {{ $t('filter') }}
            </ButtonIcon>
        </Card>

        <DataTable
            :items="items"
            :fields="fields"
            :total-rows="categories.max"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :sort-by.sync="sortBy"
            :sort-direction.sync="sortDirection"
            :loading="isLoading"
            no-local-sorting
        >
            <template #cell(action)="data">
                <div class="d-flex gap-1 justify-content-end">
                    <ButtonIcon
                        size="sm"
                        icon="edit-2"
                        variant="primary"
                        :disabled="isItemBusy(data.item.id)"
                        :loading="isItemUpdated(data.item.id)"
                        @click="editItem(data.item)"
                    />
                    <ButtonIcon
                        size="sm"
                        icon="trash-2"
                        variant="danger"
                        :disabled="isItemBusy(data.item.id)"
                        :loading="isItemDeleted(data.item.id)"
                        @click="deleteItem(data.item.id)"
                    />
                </div>
            </template>
        </DataTable>
        <ModalForm />
    </div>
</template>



<script>
    import catalogService from '@/services/catalogService';
    import DataTable from '@/components/common/DataTable.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import ModalForm from './parts/ModalForm.vue';
    import { createItemKey } from '@/store/modules/moduleFactory';

    export default {
        name: 'CatalogCategories',
        components: {
            DataTable,
            Multiselect,
            ModalForm,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                fields: [
                    {
                        key: 'id',
                        label: this.$t('id'),
                        sortable: true,
                    },
                    {
                        key: 'name',
                        label: this.$t('name'),
                        sortable: true,
                    },
                    {
                        key: 'parent.name',
                        label: this.$t('parent'),
                    },
                    {
                        key: 'action',
                        label: '',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ],
                perPage: 10,
                currentPage: 1,
                sortBy: 'id',
                sortDirection: 'desc',
                categoriesSearchText: '',
                filters: {
                    name: '',
                    parent: null,
                },
            };
        },
        computed: {
            requestParams() {
                return {
                    ...this.tableParams,
                    ...this.filters,
                    parent: this.filters.parent?.id || null,
                };
            },
            tableParams() {
                return {
                    page: this.currentPage,
                    perPage: this.perPage,
                    sort: this.sortBy,
                    order: this.sortDirection.toUpperCase(),
                };
            },
            categories() {
                return this.$store.getters['catalog/getCategories'];
            },
            items() {
                return this.categories.items || [];
            },
            error() {
                return this.$store.getters['catalog/getError']('categories');
            },
            isLoading() {
                return this.$store.getters['catalog/getLoading']('categories');
            },
            isItemBusy() {
                return id => this.$store.getters['catalog/getBusyItem']('categories', createItemKey({ id }));
            },
            isItemUpdated() {
                return id => this.$store.getters['catalog/getUpdatedItem']('categories', createItemKey({ id }));
            },
            isItemDeleted() {
                return id => this.$store.getters['catalog/getDeletedItem']('categories', createItemKey({ id }));
            },
        },
        watch: {
            tableParams: {
                immediate: true,
                handler: 'fetchData',
            },
            error() {
                if (this.error) this.$toastr.error(this.error.message, this.$t('errorOccurred'));
            },
        },
        methods: {
            clearData() {
                this.$store.commit('catalog/clearCategories');
            },
            fetchData() {
                this.$store.dispatch('catalog/fetchCategories', { requestParams: this.requestParams }).catch(() => {});
            },
            deleteItem(id) {
                this.$bvModal
                    .msgBoxConfirm(this.$t('confirmYouWantDelete'), {
                        title: this.$t('confirm'),
                        okTitle: this.$t('yes'),
                        cancelTitle: this.$t('cancel'),
                        okVariant: 'danger',
                    })
                    .then(value => {
                        if (value) {
                            this.$store
                                .dispatch('catalog/deleteCategories', { urlParams: { id } })
                                .then(this.fetchData)
                                .catch(() => {});
                        }
                    });
            },
            editItem(item = null) {
                this.$root.$emit('bv::show::modal', 'modal-form-catalog-categories');
                this.$root.$emit('modal-form-catalog-categories', item);
            },
            fetchCategories() {
                const requestParams = { name: this.categoriesSearchText };
                return catalogService.getCategories({ requestParams });
            },
            parseCategories(data) {
                return data?.items || [];
            },
        },
        created() {
            this.clearData();
        },
    };
</script>
