<template>
    <div>

        <PageHeader>
            {{ $t('listOfPaymentsTips') }}
            <template #side>
                <TimeRangeDropdown v-model="filters.timeRange" :disabled="loading" />
            </template>
        </PageHeader>

        <Filters ref="Filters" />

        <Chart ref="Chart" />

        <Results ref="Results" />

    </div>
</template>



<script>
    import PageHeader from '@/components/common/PageHeader.vue';
    import TimeRangeDropdown from '@/components/common/TimeRangeDropdown.vue';
    import Filters from './parts/Filters.vue';
    import Chart from './parts/Chart.vue';
    import Results from './parts/Results.vue';

    export default {
        name: 'Tips',
        components: {
            PageHeader,
            TimeRangeDropdown,
            Filters,
            Chart,
            Results,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                filters: {
                    timeRange: null,
                    spaces: [],
                    users: [],
                    statuses: [],
                },
                tableParams: {
                    perPage: 50,
                    currentPage: 1,
                    sortBy: 'createdAt',
                    sortDirection: 'desc',
                },
            };
        },
        computed: {
            loading() {
                return this.$store.getters['tipTransactions/getLoading']('transactions');
            },
            statuses() {
                return this.$store.getters['tipTransactions/getStatuses'] || {};
            },
        },
        methods: {
            fetchStatuses() {
                this.$store.dispatch('tipTransactions/fetchStatuses').catch(() => {});
            },
            getStatusLabel(status) {
                return this.statuses?.find(item => item.value === status)?.label || '';
            },
        },
        created() {
            this.fetchStatuses();
        },
    };
</script>
