<template>
    <div class="splash-container">
        <div class="splash-wrapper">
            <img
                src="../../assets/logo-buybox.png"
                alt="BUY.BOX"
                class="logo"
            >
        </div>
    </div>
</template>



<script>
    export default {
        name: 'SplashScreen',
        data() {
            return {};
        },
    };
</script>



<style lang="scss" scoped>
    @keyframes opacity {
        0% {
            opacity: 0.2;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.2;
        }
    }

    .splash-container {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .splash-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        animation: opacity 2s ease-in infinite;
    }

    .logo {
        max-width: 20vw;
        max-height: 10vh;
    }
</style>
