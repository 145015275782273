<template>
    <row-labeled :row="row">
        <template #label> {{ label }}</template>
        <template #content> {{ value }}</template>
    </row-labeled>
</template>

<script>
import RowLabeled from './RowLabeled.vue';

export default {
    name: 'readonly-field',
    components: {
        RowLabeled,
    },
    props: {
        value: {
            type: [String, Number],
            required: true,
        },
        label: {
            type: String,
        },
        row: {
            type: Boolean,
        },
    },
};
</script>
