<template>
    <div>
        <PageHeader>
            {{ $t('sidebar.advertiserSettlements') }}
        </PageHeader>

        <div class="d-flex flex-column gap-4">
            <Filters ref="Filters" />
            <Invoices ref="Invoices" />
        </div>
    </div>
</template>

<script>
    import Invoices from './parts/Invoices.vue';
    import Filters from './parts/Filters.vue';

    export default {
        name: 'AdvertiserSettlementsRaport',
        components: {
            Invoices,
            Filters,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                perPage: 10,
                currentPage: 1,
                sortBy: 'currentSettlement',
                sortDirection: 'desc',
            };
        },
    };
</script>

<style lang="scss" scoped></style>
