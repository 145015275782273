<template>
    <div>

        <PageHeader>
            {{ $t('tipSettlements') }}
        </PageHeader>

        <div class="d-flex flex-column gap-4">

            <Stats ref="Stats" />

            <div class="d-flex gap-4">

                <Invoices ref="Invoices" class="flex-grow-1" />

                <Donations ref="Donations" class="flex-grow-1" />

            </div>

            <Payouts ref="Payouts" class="flex-grow-1" />

        </div>

    </div>
</template>



<script>
    import Stats from './parts/Stats.vue';
    import Invoices from './parts/Invoices.vue';
    import Donations from './parts/Donations.vue';
    import Payouts from './parts/Payouts.vue';

    export default {
        name: 'Settlements',
        components: {
            Stats,
            Invoices,
            Donations,
            Payouts,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {};
        },
    };
</script>
