<template>
    <card :header="$t('accountSettingsPersonal.title')">
        <form novalidate @submit.prevent="handleSubmit">
            <div class="row">
                <text-field
                    v-model="model.firstName"
                    class="col-sm-6"
                    :validation="$v.model.firstName"
                    :label="$t('accountSettingsPersonal.firstName')"
                />
                <text-field
                    v-model="model.lastName"
                    class="col-sm-6"
                    :validation="$v.model.lastName"
                    :label="$t('accountSettingsPersonal.lastName')"
                />
                <text-field
                    v-model="model.email"
                    class="col-sm-6"
                    :validation="$v.model.email"
                    :label="$t('accountSettingsPersonal.email')"
                />
                <text-field
                    v-model="model.phone"
                    class="col-sm-6"
                    :validation="$v.model.phone"
                    :label="$t('accountSettingsPersonal.phone')"
                />
                <select-field
                    v-model="model.language"
                    class="col-sm-12"
                    :options="languageOptions"
                    :validation="$v.model.language"
                    :label="$t('accountSettingsPersonal.sectionLanguage')"
                />
            </div>
            <error-message v-model="saveError" />
            <div class="row">
                <div class="col-sm-6 mt-2 form-group">
                    <button-default :loading="submitting" type="submit" block>
                        {{ $t('accountSettingsPersonal.submit') }}
                    </button-default>
                </div>
            </div>
        </form>
    </card>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { email as emailValidator, required } from 'vuelidate/lib/validators';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import Card from '@/components/common/Card.vue';
    import SelectField from '@/components/common/SelectField.vue';
    import { getLanguageOptions } from '@/i18n/i18n';
    import TextField from '@/components/common/TextField.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'WidgeterAccountSettingsPersonal',
        components: {
            ErrorMessage,
            TextField,
            SelectField,
            Card,
            ButtonDefault,
        },
        validations: {
            model: {
                firstName: { required },
                lastName: { required },
                email: { required, email: emailValidator },
                phone: { required },
                language: { required },
            },
        },
        data() {
            return {
                model: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    language: '',
                },
                languageOptions: getLanguageOptions(),
                saveError: null,
            };
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
                submitting: state => state.auth.loading.updatePersonalSettings,
            }),
        },
        methods: {
            setInitialValues() {
                const { firstName, lastName, email, phone, language } = this.user;
                this.model = {
                    firstName,
                    lastName,
                    email,
                    phone,
                    language,
                };
            },
            async handleSubmit() {
                this.saveError = null;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                try {
                    await this.updatePersonalSettings({ ...this.model });
                } catch (e) {
                    this.saveError = e.message;
                }
            },
            ...mapActions({
                updatePersonalSettings: 'auth/updatePersonalSettings',
            }),
        },
        created() {
            this.setInitialValues();
        },
    };
</script>
