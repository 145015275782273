import { getTime, addHours, startOfHour, addDays, startOfDay, addWeeks, startOfWeek, addMonths, startOfMonth, differenceInCalendarDays, addQuarters, startOfQuarter, addYears, startOfYear } from 'date-fns';

function addSeries(series) {
    if (series === 'hour') return addHours;
    if (series === 'day') return addDays;
    if (series === 'week') return addWeeks;
    if (series === 'month') return addMonths;
    if (series === 'quarter') return addQuarters;
    if (series === 'year') return addYears;
    return addDays;
}

function startSeries(series) {
    if (series === 'hour') return startOfHour;
    if (series === 'day') return startOfDay;
    if (series === 'week') return startOfWeek;
    if (series === 'month') return startOfMonth;
    if (series === 'quarter') return startOfQuarter;
    if (series === 'year') return startOfYear;
    return startOfDay;
}

export const generateChartData = (timeRange, chartData, series = 'day', force = false) => {
    if (!timeRange || !timeRange.startDate || !timeRange.endDate) return [];
    if (!chartData?.length && force === false) return [];
    const isArrayType = Array.isArray(chartData?.[0]);
    let dates = [];
    let currentTimestamp = new Date(startSeries(series)(timeRange.startDate, { weekStartsOn: 1 })).setHours(0, 0, 0, 0);
    const lastTimestamp = new Date(new Date(startSeries(series)(timeRange.endDate, { weekStartsOn: 1 })).setHours(0, 0, 0, 0)).setHours(23, 0, 0, 0);
    while (currentTimestamp <= lastTimestamp) {
        const chartDate = chartData ? chartData.find(item => (isArrayType ? item[0] : item.date) === currentTimestamp) : null;
        const value = chartDate ? (isArrayType ? chartDate[1] : chartDate.value) : 0; // eslint-disable-line no-nested-ternary
        if (isArrayType) {
            dates = [...dates, { x: `${currentTimestamp}`, y: value }]; // apex-charts has a problem displaying dates correctly so we return a string and format the dates manually
        } else {
            dates = [...dates, { date: currentTimestamp, value }];
        }
        currentTimestamp = getTime(addSeries(series)(currentTimestamp, 1));
    }
    return dates;
};

// eslint-disable-next-line prettier-vue/prettier,max-len
export const chartSupportedLanguages = ['ca', 'cs', 'de', 'el', 'en', 'es', 'fi', 'fr', 'he', 'hi', 'hr', 'hy', 'id', 'it', 'ka', 'ko', 'lt', 'nb', 'nl', 'pl', 'pt-br', 'pt', 'rs', 'ru', 'se', 'sk', 'sl', 'sq', 'th', 'tr', 'ua', 'zh-cn'];

export const chartDataGroupBy = timeRange => {
    if (!timeRange || !timeRange.endDate || !timeRange.startDate) return 'day';
    const differenceInDays = differenceInCalendarDays(timeRange.endDate, timeRange.startDate);
    const selectedDays = differenceInDays + 1;
    if (selectedDays <= 3) return 'hour';
    if (selectedDays <= 31) return 'day';
    if (selectedDays <= 12 * 7) return 'week';
    return 'month';
};

export function checkChartSupportsLanguage(lang) {
    return chartSupportedLanguages.includes(lang);
}

export default {
    generateChartData,
    chartDataGroupBy,
    chartSupportedLanguages,
    checkChartSupportsLanguage,
};
