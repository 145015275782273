<template>
    <b-card body-class="d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center">
            <b-spinner />
        </div>
    </b-card>
</template>

<script>
    export default {
        name: 'PageLoading',
    };
</script>
