<template>
    <SelectField
        v-model="spaceId"
        :options="spaceOptions"
        :clearable="false"
        :disabled="loading || $attrs.disabled"
        v-bind="$attrs"
    />
</template>



<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import SelectField from '@/components/common/SelectField.vue';

    export default {
        name: 'SpaceSwitch',
        components: {
            SelectField,
        },
        props: {
            value: {
                type: Number,
                default: undefined,
            },
        },
        data() {
            return {
                spaceId: undefined,
            };
        },
        computed: {
            ...mapGetters({
                spaceOptions: 'space/spaceOptions',
                currentSpace: 'space/currentSpace',
            }),
            ...mapState({
                loading: state => state.space.loading.fetchSpaces,
            }),
        },
        watch: {
            spaceOptions: {
                handler(spaceOptions) {
                    if (spaceOptions && spaceOptions.length === 1 && this.currentSpace && this.currentSpace.id) {
                        this.spaceId = this.currentSpace.id;
                    }
                },
                immediate: true,
            },
            spaceId: {
                handler(spaceId) {
                    this.setCurrentSpace({ spaceId });
                    this.$emit('spaceId', spaceId);
                    this.$emit('input', spaceId);
                    this.$emit('space', this.currentSpace);
                },
                immediate: true,
            },
            loading: {
                handler() {
                    this.$emit('loading', this.loading);
                },
                immediate: true,
            },
            value: {
                handler() {
                    this.spaceId = this.value;
                },
                immediate: true,
            },
        },
        methods: {
            ...mapActions({
                setCurrentSpace: 'space/setCurrentSpace',
            }),
        },
    };
</script>
