var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"items":_vm.breadcrumb}},[_c('div',{staticClass:"d-flex gap-3 align-items-center"},[_c('div',[_vm._v(_vm._s(_vm.$t('productDetails')))]),_c('ButtonIcon',{attrs:{"variant":"warning","to":{ name: 'ProductsItemAudit', params: { id: _vm.id, parentUrl: _vm.$route.params.parentUrl } }}},[_vm._v(" "+_vm._s(_vm.$t('audit'))+" ")])],1)]),_c('ErrorMessage',{attrs:{"value":_vm.error}}),_c('DataTable',{attrs:{"header":((_vm.$t('offers')) + " (" + (_vm.items.length) + ")"),"error":_vm.error,"items":_vm.items,"fields":_vm.fields,"loading":_vm.loading,"pagination":false},on:{"update:error":function($event){_vm.error=$event}},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{staticClass:"title",attrs:{"to":_vm.getUrlOffer(item)}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('ImageLoader',{staticClass:"mr-2 flex-shrink-0",attrs:{"src":item.image,"alt":"","size":"100","image-height":"auto"}}),_c('div',{staticClass:"d-flex flex-column gap-2"},[_c('b-link',{staticClass:"title",attrs:{"href":item.url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}},{key:"cell(price)",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"nowrap"},[_vm._v(_vm._s(_vm.$format.currency(value)))])]}},{key:"cell(active)",fn:function(ref){
var value = ref.value;
return [_c('BadgeBoolean',{attrs:{"value":value,"true-value-text":_vm.$t('active'),"false-value-text":_vm.$t('inactive')}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }