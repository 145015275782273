<template>
    <Card :header="$t('filters')">
        <ErrorMessage :value="error" />

        <form @keyup.enter="fetchReporterInvoices()">
            <b-form-group :label="$t('advertiser')" label-cols-sm="3">
                <Multiselect
                    :clear-on-select="true"
                    :close-on-select="true"
                    :show-no-results="true"
                    :show-no-options="true"
                    :preserve-search="true"
                    :show-checkboxes="false"
                    :multiple="false"
                    :show-selected="true"
                    :asynchronous="true"
                    :fetch-options-function="fetchAdvertisers"
                    :parse-options-function="parseAdvertisers"
                    :placeholder="$t('selectField.placeholder')"
                    track-by="id"
                    label="name"
                    :custom-label="option => `${option.profile.firstName} ${option.profile.lastName} (${option.username})`"
                    select-label=""
                    :disabled="loading"
                    @search-change="value => (advertisersSearchText = value)"
                    @input="value => (filters.advertisers = value)"
                />
            </b-form-group>

            <b-form-group :label="$t('shop')" label-cols-sm="3">
                <Multiselect
                    :clear-on-select="true"
                    :close-on-select="true"
                    :show-no-results="true"
                    :show-no-options="true"
                    :preserve-search="true"
                    :show-checkboxes="false"
                    :multiple="false"
                    :show-selected="true"
                    :asynchronous="true"
                    :fetch-options-function="fetchCampaigns"
                    :parse-options-function="parseCampaigns"
                    :placeholder="$t('selectField.placeholder')"
                    :searchable="true"
                    track-by="id"
                    label="name"
                    select-label=""
                    @search-change="value => (campaignsSearchText = value)"
                    @input="value => (filters.campaigns = value ? value.id : null)"
                />
            </b-form-group>

            <b-form-group :label="$t('transactions.dateRange')" label-cols-sm="3">
                <FormDatepicker v-model="filters.dateRange" :placeholder="$t('all')" :config="datePickerConfig" :disabled="loading" />
            </b-form-group>

            <b-form-group :label="$t('status')" label-cols-sm="3">
                <b-form-checkbox-group v-model="filters.statuses" :options="statusesOption" class="h-100 d-flex align-items-center" :disabled="loading" />
            </b-form-group>

            <div class="d-flex gap-3">
                <ButtonIcon :loading="loading" :disabled="!canFilter" icon="filter" @click="fetchReporterInvoices()">
                    {{ $t('filter') }}
                </ButtonIcon>
            </div>
        </form>
    </Card>
</template>

<script>
    import clone from 'lodash/clone';
    import spaceService from '@/services/spaceService';
    import userService from '@/services/userService';
    import Multiselect from '@/components/common/Multiselect.vue';
    import FormDatepicker from '@/components/common/FormDatepicker.vue';
    import Role from '@/Role';

    export default {
        name: 'Filters',
        components: {
            Multiselect,
            FormDatepicker,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                advertisersSearchText: '',
                campaignsSearchText: '',
                currentFilters: {},
                filters: {
                    advertisers: '',
                    campaigns: '',
                    statuses: [],
                    dateRange: null,
                },
            };
        },
        computed: {
            filtersParams() {
                const filters = {};
                if (this.filters.advertisers) filters.advertisers = this.filters.advertisers.id;
                if (this.filters.campaigns) filters.campaigns = this.filters.campaigns;
                if (this.filters.statuses?.length) filters.statuses = this.filters.statuses || [];
                if (this.filters.dateRange?.[0]) filters.startDate = this.filters.dateRange[0];
                if (this.filters.dateRange?.[1]) filters.endDate = this.filters.dateRange[1];
                return filters;
            },
            statusesOption() {
                return [
                    { text: this.$t('settled'), value: 3 },
                    { text: this.$t('notSettled'), value: 0 },
                ];
            },
            datePickerConfig() {
                return { mode: 'range' };
            },
            tableParams() {
                return {
                    sort: this.parentComponent.sortBy,
                };
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                    ...this.tableParams,
                };
            },
            error() {
                return this.$store.getters['advertiserSettlements/getError']('reporterInvoices');
            },
            loading() {
                return this.$store.getters['advertiserSettlements/getLoading']('reporterInvoices');
            },
            isFiltersChanged() {
                return JSON.stringify(this.filtersParams) !== JSON.stringify(this.currentFilters);
            },
            canFilter() {
                return this.filtersParams.startDate && this.filtersParams.endDate;
            },
        },
        watch: {
            tableParams: {
                deep: true,
                handler: 'fetchReporterInvoices',
            },
        },
        methods: {
            resetCurrentPage() {
                this.parentComponent.currentPage = 1;
            },
            fetchCampaigns() {
                return spaceService.fetchCampaigns({
                    name: this.campaignsSearchText,
                });
            },
            fetchReporterInvoices() {
                if (!this.canFilter) return;
                if (this.isFiltersChanged) this.resetCurrentPage();
                this.currentFilters = clone(this.filtersParams);
                this.$store.dispatch('advertiserSettlements/fetchReporterInvoices', { requestParams: this.requestParams }).catch(() => {});
            },
            fetchAdvertisers() {
                const requestParams = { search: this.advertisersSearchText, roles: [Role.ADVERTISER] };
                return userService.getUsers({ requestParams });
            },
            parseAdvertisers(data) {
                return data?.items || [];
            },
            parseCampaigns(data) {
                const items = data?.items || [];
                if (items.length) return [{ id: null, name: this.$t('all') }, ...items];
                return [...items];
            },
        },
        created() {
            this.fetchReporterInvoices();
        },
    };
</script>
