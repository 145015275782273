import Vue from 'vue';

export default class WrapRequest {
    constructor(request, { defaultData } = {}) {
        this.requestFn = request;

        this.loading = false;
        this.result = defaultData;
        this.error = null;

        this.page = 1;
        this.perPage = 20;

        this.currentCallIndex = 0;
        this.touched = false;
    }

    async request({ page = this.page, perPage = this.perPage } = {}) {
        this.currentCallIndex += 1;
        const callIndex = this.currentCallIndex;

        Vue.set(this, 'touched', true);
        Vue.set(this, 'error', null);
        Vue.set(this, 'loading', true);

        try {
            const result = await this.requestFn({ page, perPage });

            if (callIndex === this.currentCallIndex) {
                Vue.set(this, 'result', result);
                Vue.set(this, 'page', page);
                Vue.set(this, 'perPage', perPage);
                Vue.set(this, 'loading', false);
            }
        } catch (e) {
            if (callIndex === this.currentCallIndex) {
                Vue.set(this, 'error', e);
                Vue.set(this, 'loading', false);
            }
        }
    }

    reset(value, resetTouched) {
        Vue.set(this, 'result', value);
        Vue.set(this, 'page', 1);
        if (resetTouched) {
            Vue.set(this, 'touched', false);
        }
    }
}
