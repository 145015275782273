<template>
    <textarea :value="model" class="readonly-textarea mt-2" @input="$forceUpdate()"></textarea>
</template>

<script>
    export default {
        name: 'ReadonlyTextarea',
        props: {
            value: {
                type: String,
            },
            autoResize: {
                type: Boolean,
            },
        },
        computed: {
            model: {
                get() {
                    return this.value;
                },
                set() {},
            },
        },
        watch: {
            value() {
                if (this.autoResize) {
                    this.resize();
                }
            },
        },
        methods: {
            resize() {
                this.$nextTick(() => {
                    this.$el.style.height = 'auto';
                    this.$el.style.height = `${this.$el.scrollHeight + 2}px`;
                });
            },
        },
        mounted() {
            if (this.autoResize) {
                this.resize();
            }
        },
    };
</script>

<style scoped>
    .readonly-textarea {
        width: 100%;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
    }
</style>
