<template>
    <DataTable
        :header="$t('monthlySummaryOfDonations')"
        :error="error"
        :items="items"
        :fields="fields"
        :loading="loading"
        :busy-items="busyItems"
        :pagination="false"
        no-sort-reset
        no-local-sorting
    >

        <template #cell(createdAt)="data">
            {{ upperFirst($format.date(data.item.createdAt, 'MMMM yyyy')) }}
        </template>

        <template #cell(document)="data">
            <ButtonIcon
                :disabled="!data.item.downloadableSummary"
                variant="link"
                class="p-0 m-0 border-0 line-height-1"
                :loading="isItemBusy(data.item.id)"
                hide-label-while-loading
                @click.prevent="download(data.item.id, data.item.number)"
            >
                {{ $t('download') }}
            </ButtonIcon>
        </template>

    </DataTable>
</template>



<script>
    import upperFirst from 'lodash/upperFirst';
    import slugify from 'slugify';
    import exportService from '@/services/exportService';
    import DataTable from '@/components/common/DataTable.vue';

    export default {
        name: 'Donations',
        components: {
            DataTable,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                tableConfig: {
                    perPage: Number.MAX_SAFE_INTEGER,
                    currentPage: 1,
                },
                fields: [
                    {
                        key: 'createdAt',
                        label: this.$t('month'),
                    },
                    {
                        key: 'document',
                        label: this.$t('document'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                ],
                busyItems: [],
            };
        },
        computed: {
            invoices() {
                return this.$store.getters['tipbox/getInvoices'];
            },
            items() {
                return this.invoices.items || [];
            },
            error() {
                const error = this.$store.getters['tipbox/getError']('invoices');
                return error?.message === 'api_errors.unprocessable_entity.policies_not_accepted' ? null : error;
            },
            loading() {
                return this.$store.getters['tipbox/getLoading']('invoices');
            },
            isItemBusy() {
                return id => !!this.busyItems.find(itemId => itemId === id);
            },
            isItemsBusy() {
                return !!this.busyItems.length;
            },
            tableParams() {
                return {
                    page: this.tableConfig.currentPage,
                    perPage: this.tableConfig.perPage,
                };
            },
            requestParams() {
                return {
                    ...this.tableParams,
                };
            },
        },
        watch: {
            requestParams: {
                immediate: true,
                handler: 'fetchData',
            },
        },
        methods: {
            upperFirst,
            fetchData() {
                this.$store.dispatch('tipbox/fetchInvoices', { requestParams: this.requestParams }).catch(() => {});
            },
            download(id, number) {
                this.busyItems = [...this.busyItems, id];
                this.$store
                    .dispatch('tipbox/fetchInvoicesDownloadSummary', { urlParams: { id }, responseType: 'blob' })
                    .then(response => {
                        const data = window.URL.createObjectURL(response);
                        const name = `${this.$t('donations')}-${slugify(number.replaceAll('/', '-'))}`;
                        exportService.exportData(data, name, 'pdf');
                    })
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')))
                    .finally(() => (this.busyItems = this.busyItems.filter(itemId => itemId !== id)));
            },
        },
    };
</script>
