<template>
    <div class="left-side-menu left-side-menu-dark">
        <template v-if="showShopSwitcher">
            <ShopSwitcher />
            <hr class="mt-0">
        </template>
        <div class="sidebar-content">
            <vue-perfect-scrollbar :settings="settings">
                <div class="sidebar-menu">
                    <ul>
                        <template v-for="(firstLevel, firstLevelKey) in items">
                            <li
                                :key="JSON.stringify(firstLevel)"
                                class="side-nav-title side-nav-item"
                                :class="{'subnav nav-second-level': firstLevel.children, 'expanded': isExpanded(firstLevelKey), 'active': isActive(firstLevelKey)}"
                            >
                                <b-link
                                    v-bind="!firstLevel.children ? { to: firstLevel.to } : { href: '#' }"
                                    @click="firstLevelClick(firstLevel, firstLevelKey)"
                                >
                                    <feather v-if="firstLevel.icon" :type="firstLevel.icon" />
                                    <span class="link-label">{{ firstLevel.label }}</span>
                                    <feather v-if="firstLevel.children" class="menu-arrow" size="12" type="chevron-right" />
                                </b-link>
                                <template v-if="firstLevel.children">
                                    <TransitionExpand>
                                        <ul v-show="isExpanded(firstLevelKey)" class="nav-second-level">
                                            <template v-for="secondLevel in firstLevel.children">
                                                <li :key="JSON.stringify(secondLevel)">
                                                    <b-link :to="secondLevel.to" @click="secondLevelClick(secondLevel)">
                                                        {{ secondLevel.label }}
                                                    </b-link>
                                                </li>
                                            </template>
                                        </ul>
                                    </TransitionExpand>
                                </template>
                            </li>
                        </template>
                    </ul>
                </div>
            </vue-perfect-scrollbar>
        </div>
    </div>
</template>

<script>
    import VuePerfectScrollbar from 'vue-perfect-scrollbar';
    import ShopSwitcher from '@/components/common/ShopSwitcher.vue';
    import usercomService from '@/services/usercomService';

    export default {
        name: 'AppSidebar',
        components: {
            VuePerfectScrollbar,
            ShopSwitcher,
        },
        props: {
            items: {
                type: Array,
            },
        },
        data() {
            return {
                settings: {
                    minScrollbarLength: 60,
                },
                expanded: { key: null, value: null },
                active: { key: null, value: null },
                collapsed: false,
            };
        },
        computed: {
            userRole() {
                return this.$store.getters['auth/getRoleName'];
            },
            showShopSwitcher() {
                return this.$store.getters['auth/isRoleAdvertiser'];
            },
            currentSpace() {
                return this.$store.getters['space/currentSpace'];
            },
            currentSpaceName() {
                return this.currentSpace?.name || '';
            },
            isExpanded() {
                return key => key === this.expanded.key && this.expanded.value;
            },
            isActive() {
                return key => key === this.active.key && this.active.value;
            },
        },
        watch: {
            active() {
                if (Number.isFinite(this.active?.key) && this.items[this.active.key].children) {
                    this.expanded = { ...this.active };
                } else {
                    this.expanded = { key: null, value: null };
                }
            },
            '$route.path': {
                immediate: true,
                handler() {
                    this.initCurrentActive();
                },
            },
        },
        methods: {
            firstLevelClick(item, key) {
                if (item.children) {
                    this.expanded = { key, value: !this.expanded.value || this.expanded.key !== key };
                } else {
                    this.clickOnMenuItem(item);
                }
            },
            secondLevelClick(item, key) {
                this.clickOnMenuItem(item);
            },
            clickOnMenuItem(item) {
                usercomService.userengage(`event.menu-${item.label}`, { 'space-type': this.currentSpaceName });
            },
            initCurrentActive() {
                const currentActiveIndex = this.items.findIndex(item => {
                    if (item.children) return item.children.some(navItem => this.$route.matched.some(({ name }) => name === navItem?.to?.name));
                    return item.to.name === this.$route.name;
                });
                this.active = currentActiveIndex !== -1 ? { key: currentActiveIndex, value: true } : { key: null, value: null };
            },
        },
    };
</script>

<style lang="scss">
    .ps > .ps__scrollbar-y-rail {
        width: 8px !important;
        background-color: transparent !important;
    }
    .ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
    .ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
    .ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
    .ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
        width: 6px !important;
    }
    .abstract-items {
        display: none;
    }
    .btn-quick-start {
        margin-left: 30px;
    }

    .left-side-menu {
        .sidebar-content {
            .sidebar-menu > {
                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        transition: all 0.3s ease;
                        list-style: none;

                        a {
                            transition: all 0.3s ease;

                            &:hover {
                                color: $menu-item-color-dark;
                            }

                            &.router-link-active {
                                color: $menu-item-active-color-dark;

                                &:hover {
                                    color: $menu-item-active-color-dark;
                                }
                            }
                        }

                        &.expanded {
                            background-color: lighten($bg-leftbar-dark, 2%);

                            &:not(.active) {
                                a {
                                    border-left-color: transparent;
                                    color: $menu-item-color-dark;

                                    svg {
                                        color: $menu-item-color-dark;
                                    }
                                }
                            }
                        }
                    }

                    > li {
                        > a {
                            &:hover {
                                color: $menu-item-hover-color-dark;
                                background-color: lighten($bg-leftbar-dark, 4%);
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
