import { helpers } from 'vuelidate/lib/validators';
import { i18n } from '../i18n/i18n';

const hexColorRegex = /^#(?:[0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;

export default {
    getValidationErrors(validation, customValidationErrors = {}) {
        if (!validation.$dirty) {
            return [];
        }

        const errors = [];

        if ('required' in validation.$params && !validation.required) {
            errors.push(i18n.t('validation.required'));
        }

        if ('email' in validation.$params && !validation.email) {
            errors.push(i18n.t('validation.email'));
        }

        if ('sameAs' in validation.$params && !validation.sameAs) {
            errors.push(i18n.t('validation.sameAs'));
        }

        if ('url' in validation.$params && !validation.url) {
            errors.push(i18n.t('validation.url'));
        }

        if ('hexColor' in validation.$params && !validation.hexColor) {
            errors.push(i18n.t('validation.hexColor'));
        }

        if ('maxLength' in validation.$params && !validation.maxLength) {
            errors.push(i18n.t('validation.maxLength', { max: validation.$params.maxLength.max }));
        }

        Object.keys(customValidationErrors).forEach(customValidator => {
            if (customValidator in validation.$params && !validation[customValidator]) {
                errors.push(i18n.t(customValidationErrors[customValidator]));
            }
        });

        return errors;
    },

    hasValidatorRequired(validation) {
        return Boolean(validation.$params.required);
    },

    hexColor: value => !helpers.req(value) || hexColorRegex.test(value),
};
