<template>
    <component :is="tag">
        <component :is="itemTag" v-for="(item, index) in items" :key="index" :class="itemClass">{{ item }}</component>
    </component>
</template>



<script>
    export default {
        name: 'List',
        props: {
            items: {
                type: Array,
                required: true,
            },
            tag: {
                type: String,
                default: 'div',
            },
            itemTag: {
                type: String,
                default: 'div',
            },
            itemClass: {
                type: String,
                default: null,
            },
        },
    };
</script>
