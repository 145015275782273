/* eslint import/no-cycle: off */
import Vue from 'vue';
import Router from 'vue-router';
import qs from 'qs';
import apiService from '@/services/apiService';
import { i18n } from '@/i18n/i18n';
import { mode } from '@/utils/util';
import Role from '@/Role';
import usercomService from '@/services/usercomService';
import globalData from '@/store/globalData';

/* Layout */
import LayoutBlank from '@/layouts/Blank.vue';
import LayoutBasic from '@/layouts/Basic.vue';
import LayoutPanel from '@/layouts/Panel.vue';
/* Layout */

/* Common */
import PageNotFound from '@/views/common/page-not-found/index.vue';
import PageForbidden from '@/views/common/page-forbidden/PageForbidden.vue';
import Login from '@/views/common/auth/login.vue';
import LoginAs from '@/views/common/login-as/index.vue';
import Register from '@/views/common/auth/register.vue';
import ResetPassword from '@/views/common/auth/reset-password.vue';
import ResetPasswordToken from '@/views/common/auth/reset-password-token.vue';
import AcceptTerms from '@/views/common/accept-terms/index.vue';
import ConfirmEmail from '@/views/common/confirm-email/index.vue';
import AccountSettings from '@/views/common/account/settings/index.vue';
import ReportsStats from '@/views/common/reports/stats/index.vue';
/* Common */

/* Admin */
import LoginAdmin from '@/views/admin/auth/LoginAdmin.vue';
import AdminDashboard from '@/views/admin/dashboard/index.vue';
/* Admin */

/* Widgeter */
import WidgeterDashboard from '@/views/widgeter/dashboard/index.vue';
import WidgetGenerator from '@/views/widgeter/widget/generator/index.vue';
import WidgetSettings from '@/views/widgeter/widget/settings/index.vue';
import WidgetSettingsEdit from '@/views/widgeter/widget/settings/_buyboxId/index.vue';
import TextLinks from '@/views/widgeter/text-links/index.vue';
import ProductLandingPageGenerator from '@/views/widgeter/product-landing-page/generator/index.vue';
import ProductLandingPageSettingsView from '@/views/widgeter/product-landing-page/settings/index.vue';
import ProductLandingPageSettingsEdit from '@/views/widgeter/product-landing-page/settings/_id/index.vue';
import ReportsShops from '@/views/widgeter/reports/shops/index.vue';
import WidgeterReportsPages from '@/views/widgeter/reports/pages/index.vue';
import ReportsProducts from '@/views/widgeter/reports/products/index.vue';
import WidgeterReportsTransactions from '@/views/widgeter/reports/transactions/index.vue';
import WidgeterPages from '@/views/widgeter/pages/index.vue';
import Shops from '@/views/widgeter/shops/index.vue';
import SettingsIntegrations from '@/views/widgeter/settings/integrations/index.vue';
import SettingsApi from '@/views/widgeter/settings/api/index.vue';
import SettingsWebhooks from '@/views/widgeter/settings/webhooks/index.vue';
import WidgeterWidgetPreview from '@/views/widgeter/widget-preview/index.vue';
import WidgeterFinance from '@/views/widgeter/finance/index.vue';
import PaymentGeneratorCompany from '@/views/widgeter/finance/parts/PaymentGeneratorCompany.vue';
import BBAffiliateProgram from '@/views/widgeter/bb-affiliate-program/index.vue';
import WidgeterTipboxTips from '@/views/widgeter/tipbox/tips/index.vue';
import WidgeterTipboxSettlements from '@/views/widgeter/tipbox/settlements/index.vue';
import WidgeterTipboxSettings from '@/views/widgeter/tipbox/settings/index.vue';
import WidgeterTipboxLink from '@/views/widgeter/tipbox/link/index.vue';
/* Widgeter */

/* Advertiser */
import AdvertiserWebpagesList from '@/views/advertiser/webpages/index.vue';
import AdvertiserDashboard from '@/views/advertiser/dashboard/index.vue';
import AdvertiserReportsTransactions from '@/views/advertiser/reports/transactions/index.vue';
import AdvertiserReportsPages from '@/views/advertiser/reports/pages/index.vue';
import SettingsShopPresentation from '@/views/advertiser/settings/shop-presentation/index.vue';
import Api from '@/views/advertiser/api/index.vue';
import OffersCatalog from '@/views/advertiser/offers-catalog/index.vue';
import OffersCatalogProductList from '@/views/advertiser/offers-catalog/ProductList.vue';
import OffersCatalogProductDetails from '@/views/advertiser/offers-catalog/_id/ProductDetails.vue';
/* Advertiser */

/* Admin */
import AdminReportsTransactions from '@/views/admin/reports/transactions/index.vue';
import AdminReportsConversion from '@/views/admin/reports/conversion/index.vue';
import AdminReportsIntegrationStatus from '@/views/admin/reports/integration-status/index.vue';
import AdminFeedImportSummary from '@/views/admin/reports/feed-import-summary/index.vue';
import AdminCatalogProducts from '@/views/admin/catalog/products/index.vue';
import AdminCatalogManufacturers from '@/views/admin/catalog/manufacturers/index.vue';
import AdminCatalogBrands from '@/views/admin/catalog/brands/index.vue';
import AdminCatalogCategories from '@/views/admin/catalog/categories/index.vue';
import AdminCatalogModeration from '@/views/admin/catalog/moderation/index.vue';
import AdminUsers from '@/views/admin/users/index.vue';
import AdminUsersDetails from '@/views/admin/users/_id/index.vue';
import AdminWebpagesList from '@/views/admin/webpages/list/index.vue';
import AdminWebpagesDetails from '@/views/admin/webpages/list/_id/index.vue';
import AdminOffers from '@/views/admin/offers/index.vue';
import AdminOffersDetails from '@/views/admin/offers/_id/index.vue';
import AdminTipboxTips from '@/views/admin/tipbox/tips/index.vue';
import AdminTipboxVerification from '@/views/admin/tipbox/verification/index.vue';
import AdminTipboxSettlements from '@/views/admin/tipbox/settlements/index.vue';
import AdminCampaignsList from '@/views/admin/campaigns/list/index.vue';
import AdminCampaignsDetails, { TABS as ADMIN_CAMPAIGNS_DETAILS_TABS } from '@/views/admin/campaigns/list/_id/index.vue';
import AdminCampaignsDetailsTabSettings from '@/views/admin/campaigns/list/_id/_tab/settings/index.vue';
import AdminCampaignsDetailsTabCategories from '@/views/admin/campaigns/list/_id/_tab/categories/index.vue';
import AdminCampaignsDetailsTabCommissions from '@/views/admin/campaigns/list/_id/_tab/commissions/index.vue';
import AdminCampaignsDetailsTabLocations from '@/views/admin/campaigns/list/_id/_tab/locations/index.vue';
import AdminCampaignsDetailsTabFeeds from '@/views/admin/campaigns/list/_id/_tab/feeds/index.vue';
import AdminCampaignsDetailsTabFeedsIndex from '@/views/admin/campaigns/list/_id/_tab/feeds/view.vue';
import AdminCampaignsDetailsTabFeedsCreate from '@/views/admin/campaigns/list/_id/_tab/feeds/create.vue';
import AdminCampaignsDetailsTabFeedsEdit from '@/views/admin/campaigns/list/_id/_tab/feeds/edit.vue';
import AdminProducerProductsImport from '@/views/admin/producer-products/import/index.vue';
import AdminAdvertiserSettlements from '@/views/admin/settlements/advertiser-raport/index.vue';
import AdminAdvertiserSettlementsList from '@/views/admin/settlements/advertiser/index.vue';
import AdminAdvertiserSettlementsDetails from '@/views/admin/settlements/advertiser/details.vue';
import AdminAdvertiserSettlementsCreate from '@/views/admin/settlements/advertiser/create.vue';
import AdminProducts from '@/views/admin/products/index.vue';
import AdminProductsItemShow from '@/views/admin/products/_id/show.vue';
import AdminProductsItemAudit from '@/views/admin/products/_id/audit.vue';
import AdminTransactionsImport from '@/views/admin/transactions/import/index.vue';
import AdminTransactionsValidation from '@/views/admin/transactions/validation/index.vue';
import AdminTransactionsCorrection from '@/views/admin/transactions/correction/index.vue';
import AdminFinanceSubscriptions from '@/views/admin/finance/subscriptions/index.vue';
import AdminFinanceSubscriptionsCreate from '@/views/admin/finance/subscriptions/create.vue';
import AdminFinanceSettlementsPublishers from '@/views/admin/finance/settlements/publishers/index.vue';
/* Admin */

import hasFeatureGuard from './guards/hasFeatureGuard';
import hasAccessGuard from './guards/hasAccessGuard';
import hasDataLoadedGuard from './guards/hasDataLoadedGuard';

Vue.use(Router);

const routes = [
    {
        path: '/login-as/:token?',
        name: 'LoginAs',
        component: LoginAs,
        props: route => ({
            token: route.params.token,
            isAdmin: true,
        }),
        meta: {
            title: 'loginAs.title',
        },
    },
    {
        path: '/redirect-login/:token?',
        name: 'LoginRedirect',
        component: LoginAs,
        props: route => ({
            token: route.params.token,
            isAdmin: false,
        }),
        meta: {
            title: 'loginAs.title',
        },
    },
    ...(mode === 'ADMIN'
        ? [
              {
                  path: '/login',
                  name: 'Login',
                  component: LoginAdmin,
                  meta: {
                      title: 'login.title',
                      isGuest: true,
                  },
              },
          ]
        : []),
    {
        path: '',
        name: 'LayoutAuth',
        component: LayoutBlank,
        redirect: { name: 'Login' },
        meta: {
            isGuest: true,
        },
        children: [
            ...(mode === 'WIDGETER' || mode === 'ADVERTISER'
                ? [
                      {
                          path: '/login',
                          name: 'Login',
                          component: Login,
                          meta: {
                              title: 'login.title',
                          },
                      },
                  ]
                : []),
            {
                path: '/register',
                name: 'Register',
                component: Register,
                meta: {
                    title: 'auth.pageRegister.pageTitle',
                },
            },
            {
                path: '/reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
                meta: {
                    title: 'resetPassword.title',
                },
            },
            {
                path: '/reset-password/:token',
                name: 'ResetPasswordToken',
                component: ResetPasswordToken,
                props: true,
                meta: {
                    title: 'resetPasswordToken.title',
                },
            },
        ],
    },
    {
        path: '',
        name: 'LayoutBasic',
        component: LayoutBasic,
        children: [
            {
                path: '/accept-terms',
                name: 'AcceptTerms',
                component: AcceptTerms,
                meta: {
                    title: 'acceptTerms.title',
                    isSecure: true,
                },
            },
            {
                path: '/confirm-email/:token?',
                name: 'ConfirmEmail',
                component: ConfirmEmail,
                props: true,
                meta: {
                    title: 'confirmEmail.title',
                },
            },
        ],
    },
    {
        path: '/:role/widget-preview',
        name: 'WidgetPreview',
        component: Vue.component('WidgetPreviewPage', {
            render: function render(h) {
                if (globalData.user.isRoleWidgeter) return h(WidgeterWidgetPreview);
                return h(LayoutBlank);
            },
        }),
        meta: {
            isSecure: true,
            title: 'widgetPreview.pageTitle',
            roles: [Role.WIDGETER],
        },
    },
    {
        path: '/:role',
        name: 'LayoutPanel',
        component: LayoutPanel,
        redirect: { name: 'Dashboard' },
        meta: {
            isSecure: true,
        },
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: Vue.component('UserDashboard', {
                    render: function render(h) {
                        if (globalData.user.isRoleAdmin) return h(AdminDashboard);
                        if (globalData.user.isRoleWidgeter) return h(WidgeterDashboard);
                        if (globalData.user.isRoleAdvertiser) return h(AdvertiserDashboard);
                        return h(LayoutBlank);
                    },
                }),
                meta: {
                    title: 'dashboard.title',
                    roles: [Role.ADMIN, Role.SUPERADMIN, Role.WIDGETER, Role.ADVERTISER],
                },
            },
            {
                path: 'widget',
                name: 'Widget',
                component: LayoutBlank,
                redirect: {
                    name: 'WidgetSettings',
                },
                children: [
                    {
                        path: 'settings',
                        name: 'WidgetSettings',
                        component: Vue.component('WidgetSettings', {
                            render: function render(h) {
                                if (Number.parseInt(this.$route.params.buyboxId)) return h(LayoutBlank);
                                return h(WidgetSettings);
                            },
                        }),
                        meta: {
                            title: 'widgetSettings.title',
                            roles: [Role.WIDGETER],
                        },
                        children: [
                            {
                                path: ':buyboxId(\\d+)',
                                name: 'WidgetSettingsEdit',
                                component: WidgetSettingsEdit,
                                props: route => ({ buyboxId: Number(route.params.buyboxId) }),
                                meta: {
                                    title: 'widgetSettings.title',
                                    roles: [Role.WIDGETER],
                                },
                            },
                        ],
                    },
                    {
                        path: 'create',
                        name: 'WidgetSettingsCreate',
                        component: WidgetSettingsEdit,
                        props: route => ({ buyboxId: null }),
                        meta: {
                            title: 'widgetSettings.title',
                            roles: [Role.WIDGETER],
                        },
                    },
                    {
                        path: 'generator',
                        name: 'WidgetGenerator',
                        component: WidgetGenerator,
                        meta: {
                            title: 'generator.title',
                            roles: [Role.WIDGETER],
                        },
                    },
                ],
            },
            {
                path: 'text-links',
                name: 'TextLinks',
                component: TextLinks,
                meta: {
                    title: 'textLinks.title',
                    roles: [Role.WIDGETER],
                },
            },
            {
                path: 'product-landing-page',
                name: 'ProductLandingPage',
                component: LayoutBlank,
                redirect: {
                    name: 'ProductLandingPageGenerator',
                },
                meta: {
                    title: 'productLandingPage.title',
                    roles: [Role.WIDGETER],
                },
                children: [
                    {
                        path: 'generator',
                        name: 'ProductLandingPageGenerator',
                        component: ProductLandingPageGenerator,
                        meta: {
                            title: 'productLandingPageGenerator.title',
                            roles: [Role.WIDGETER],
                        },
                    },
                    {
                        path: 'settings',
                        name: 'ProductLandingPageSettings',
                        component: LayoutBlank,
                        redirect: {
                            name: 'ProductLandingPageSettingsView',
                        },
                        children: [
                            {
                                path: '',
                                name: 'ProductLandingPageSettingsView',
                                component: ProductLandingPageSettingsView,
                                meta: {
                                    title: 'productLandingPageSettings.title',
                                    roles: [Role.WIDGETER],
                                },
                            },
                            {
                                path: ':spaceId(\\d+)/:buyboxId(\\d+)',
                                name: 'ProductLandingPageSettingsEdit',
                                component: ProductLandingPageSettingsEdit,
                                props: route => ({ spaceId: Number.parseInt(route.params.spaceId), buyboxId: Number.parseInt(route.params.buyboxId) }),
                                meta: {
                                    title: 'productLandingPageSettings.title',
                                    roles: [Role.WIDGETER],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'reports',
                name: 'Reports',
                component: LayoutBlank,
                redirect: {
                    name: 'ReportsStats',
                },
                children: [
                    {
                        path: 'stats',
                        name: 'ReportsStats',
                        component: ReportsStats,
                        meta: {
                            title: 'statistics',
                            roles: [Role.WIDGETER, Role.ADVERTISER, Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'transactions',
                        name: 'ReportsTransactions',
                        component: Vue.component('UserReportsTransactions', {
                            render: function render(h) {
                                if (globalData.user.isRoleWidgeter) return h(WidgeterReportsTransactions);
                                if (globalData.user.isRoleAdvertiser) return h(AdvertiserReportsTransactions);
                                if (globalData.user.isRoleAdmin) return h(AdminReportsTransactions);
                                return h(LayoutBlank);
                            },
                        }),
                        props: route => ({ query: route.query }),
                        meta: {
                            title: 'transactions.title',
                            roles: [Role.WIDGETER, Role.ADVERTISER, Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'pages',
                        name: 'ReportsPages',
                        component: Vue.component('UserReportsPages', {
                            render: function render(h) {
                                if (globalData.user.isRoleWidgeter) return h(WidgeterReportsPages);
                                if (globalData.user.isRoleAdvertiser) return h(AdvertiserReportsPages);
                                return h(LayoutBlank);
                            },
                        }),
                        meta: {
                            title: 'reportsPages.title',
                            roles: [Role.WIDGETER, Role.ADVERTISER],
                        },
                    },
                    {
                        path: 'shops',
                        name: 'ReportsShops',
                        component: ReportsShops,
                        meta: {
                            title: 'reportsShops.title',
                            roles: [Role.WIDGETER],
                        },
                    },
                    {
                        path: 'products',
                        name: 'ReportsProducts',
                        component: ReportsProducts,
                        meta: {
                            title: 'reportsProducts.title',
                            roles: [Role.WIDGETER],
                        },
                    },
                    {
                        path: 'conversion',
                        name: 'ReportsConversion',
                        component: AdminReportsConversion,
                        meta: {
                            title: 'conversionReport',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'integration-status',
                        name: 'ReportsIntegrationStatus',
                        component: AdminReportsIntegrationStatus,
                        meta: {
                            title: 'integrationStatus',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'feed-import-summary',
                        name: 'ReportsFeedImportSummary',
                        component: AdminFeedImportSummary,
                        meta: {
                            title: 'feedImportSummary',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                ],
            },
            {
                path: 'pages',
                name: 'Pages',
                component: WidgeterPages,
                meta: {
                    title: 'pages.title',
                    roles: [Role.WIDGETER],
                },
            },
            {
                path: 'shops',
                name: 'Shops',
                component: Shops,
                meta: {
                    title: 'widgeterShops.title',
                    roles: [Role.WIDGETER],
                },
            },
            {
                path: 'finance',
                name: 'Finance',
                component: Vue.component('UserFinance', {
                    render: function render(h) {
                        if (globalData.user.isRoleWidgeter) return h(WidgeterFinance);
                        if (globalData.user.isRoleAdmin && this.$route.name === 'Finance') return this.$router.replace({ name: 'FinanceSubscriptions' }); // redirect to children
                        return h(LayoutBlank);
                    },
                }),
                meta: {
                    title: 'finance.title',
                    roles: [Role.WIDGETER, Role.ADMIN, Role.SUPERADMIN],
                },
                children: [
                    {
                        path: 'subscriptions',
                        name: 'FinanceSubscriptions',
                        component: Vue.component('UserFinanceSubscriptions', {
                            render: function render(h) {
                                if (this.$route.name === 'FinanceSubscriptions') return h(AdminFinanceSubscriptions);
                                return h(LayoutBlank);
                            },
                        }),
                        meta: {
                            title: 'subscriptions',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                        children: [
                            {
                                path: 'create',
                                name: 'FinanceSubscriptionsCreate',
                                component: AdminFinanceSubscriptionsCreate,
                                meta: {
                                    title: 'createSubscription',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'finance/payment',
                name: 'PaymentGenerator',
                component: PaymentGeneratorCompany,
                meta: {
                    title: 'paymentGeneratorCompany.title',
                    roles: [Role.WIDGETER],
                },
            },
            {
                path: 'settings',
                name: 'Settings',
                component: LayoutBlank,
                redirect: {
                    name: 'SettingsApi',
                },
                children: [
                    {
                        path: 'api',
                        name: 'SettingsApi',
                        component: SettingsApi,
                        meta: {
                            title: 'api.title',
                            roles: [Role.WIDGETER],
                        },
                    },
                    {
                        path: 'integrations',
                        name: 'SettingsIntegrations',
                        component: SettingsIntegrations,
                        meta: {
                            title: 'widgeterSettingsIntegrations.title',
                            roles: [Role.WIDGETER],
                        },
                    },
                    {
                        path: 'webhooks',
                        name: 'SettingsWebhooks',
                        component: SettingsWebhooks,
                        meta: {
                            title: 'webhook',
                            roles: [Role.WIDGETER],
                        },
                    },
                    {
                        path: 'shop-presentation',
                        name: 'SettingsShopPresentation',
                        component: SettingsShopPresentation,
                        meta: {
                            title: 'shopPresentation',
                            roles: [Role.ADVERTISER],
                        },
                    },
                ],
            },
            {
                path: 'account',
                name: 'Account',
                component: LayoutBlank,
                redirect: {
                    name: 'AccountSettings',
                },
                children: [
                    {
                        path: 'settings',
                        name: 'AccountSettings',
                        component: AccountSettings,
                        meta: {
                            title: 'accountSettings.title',
                            roles: [Role.WIDGETER, Role.ADVERTISER],
                        },
                    },
                ],
            },
            {
                path: 'webpages',
                name: 'Webpages',
                component: Vue.component('UserWebpages', {
                    render: function render(h) {
                        if (globalData.user.isRoleAdvertiser) return h(AdvertiserWebpagesList);
                        if (this.$route.name === 'Webpages') return this.$router.replace({ name: 'WebpagesList' }); // redirect to children
                        return h(LayoutBlank);
                    },
                }),
                meta: {
                    title: 'webpagesPublishers',
                    roles: [Role.ADVERTISER, Role.ADMIN, Role.SUPERADMIN],
                },
                children: [
                    {
                        path: 'list',
                        name: 'WebpagesList',
                        component: Vue.component('UserWebpagesList', {
                            render: function render(h) {
                                if (Number.parseInt(this.$route.params.id)) return h(LayoutBlank);
                                if (globalData.user.isRoleAdmin) return h(AdminWebpagesList);
                                return h(LayoutBlank);
                            },
                        }),
                        meta: {
                            title: 'pagesList',
                            roles: [Role.ADVERTISER, Role.ADMIN, Role.SUPERADMIN],
                        },
                        children: [
                            {
                                path: ':id(\\d+):sep(-)?:slug(.*)?',
                                name: 'WebpagesDetails',
                                component: Vue.component('UserWebpagesDetails', {
                                    render: function render(h) {
                                        if (globalData.user.isRoleAdmin) return h(AdminWebpagesDetails);
                                        return h(LayoutBlank);
                                    },
                                }),
                                meta: {
                                    title: 'pagesDetails',
                                    roles: [Role.ADVERTISER, Role.ADMIN, Role.SUPERADMIN],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'campaigns',
                name: 'Campaigns',
                component: LayoutBlank,
                redirect: { name: 'CampaignsList' },
                meta: {
                    title: 'campaigns',
                    roles: [Role.ADMIN, Role.SUPERADMIN],
                },
                children: [
                    {
                        path: 'list',
                        name: 'CampaignsList',
                        component: Vue.component('UserCampaignsList', {
                            render: function render(h) {
                                if (Number.parseInt(this.$route.params.id)) return h(LayoutBlank);
                                if (globalData.user.isRoleAdmin) return h(AdminCampaignsList);
                                return h(LayoutBlank);
                            },
                        }),
                        meta: {
                            title: 'campaignsList',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                        children: [
                            {
                                path: ':id(\\d+):sep(-)?:slug?',
                                name: 'CampaignsDetails',
                                beforeEnter: (to, from, next) => {
                                    if (!ADMIN_CAMPAIGNS_DETAILS_TABS.includes(to.params.tab)) {
                                        return next({ name: 'CampaignsDetailsTab', query: to.query, params: { ...to.params, tab: ADMIN_CAMPAIGNS_DETAILS_TABS[0] } });
                                    }
                                    return next();
                                },
                                component: Vue.component('UserCampaignsDetails', {
                                    render: function render(h) {
                                        if (globalData.user.isRoleAdmin) return h(AdminCampaignsDetails);
                                        return h(LayoutBlank);
                                    },
                                }),
                                meta: {
                                    title: 'pagesDetails',
                                    roles: [Role.ADMIN, Role.SUPERADMIN],
                                },
                                children: [
                                    {
                                        path: ':tab',
                                        name: 'CampaignsDetailsTab',
                                        redirect: to => to.path,
                                        components: {
                                            settings: AdminCampaignsDetailsTabSettings,
                                            categories: AdminCampaignsDetailsTabCategories,
                                            commissions: AdminCampaignsDetailsTabCommissions,
                                            feeds: AdminCampaignsDetailsTabFeeds,
                                            locations: AdminCampaignsDetailsTabLocations,
                                        },
                                        children: [
                                            {
                                                path: '',
                                                name: 'CampaignsDetailsTabIndex',
                                                components: {
                                                    feed: AdminCampaignsDetailsTabFeedsIndex,
                                                },
                                            },
                                            {
                                                path: 'create',
                                                name: 'CampaignsDetailsTabCreate',
                                                components: {
                                                    feed: AdminCampaignsDetailsTabFeedsCreate,
                                                },
                                            },
                                            {
                                                path: ':itemId/edit',
                                                name: 'CampaignsDetailsTabEdit',
                                                components: {
                                                    feed: AdminCampaignsDetailsTabFeedsEdit,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'advertiser-settlements-dashboard',
                name: 'AdvertiserSettlements',
                component: Vue.component('UserSettlements', {
                    render: function render(h) {
                        if (globalData.user.isRoleAdmin) return h(AdminAdvertiserSettlements);
                        return h(LayoutBlank);
                    },
                }),
                meta: {
                    title: 'settlements',
                    roles: [Role.ADMIN, Role.SUPERADMIN],
                },
            },
            {
                path: 'advertiser-settlements/create',
                name: 'AdvertiserSettlementsCreate',
                component: Vue.component('UserSettlements', {
                    render: function render(h) {
                        if (globalData.user.isRoleAdmin) return h(AdminAdvertiserSettlementsCreate);
                        return h(LayoutBlank);
                    },
                }),
                meta: {
                    title: 'settlements',
                    roles: [Role.ADMIN, Role.SUPERADMIN],
                },
            },
            {
                path: 'advertiser-settlements/:id',
                name: 'AdvertiserSettlementsDetails',
                component: Vue.component('UserSettlements', {
                    render: function render(h) {
                        if (globalData.user.isRoleAdmin) return h(AdminAdvertiserSettlementsDetails);
                        return h(LayoutBlank);
                    },
                }),
                meta: {
                    title: 'settlements',
                    roles: [Role.ADMIN, Role.SUPERADMIN],
                },
            },
            {
                path: 'advertiser-settlements',
                name: 'AdvertiserSettlementsList',
                component: Vue.component('UserSettlements', {
                    render: function render(h) {
                        if (globalData.user.isRoleAdmin) return h(AdminAdvertiserSettlementsList);
                        return h(LayoutBlank);
                    },
                }),
                meta: {
                    title: 'settlements',
                    roles: [Role.ADMIN, Role.SUPERADMIN],
                },
            },
            {
                path: 'api',
                name: 'Api',
                component: Api,
                meta: {
                    title: 'api.title',
                    roles: [Role.ADVERTISER],
                },
            },
            {
                path: 'offers-catalog',
                name: 'OffersCatalog',
                component: OffersCatalog,
                redirect: { name: 'OffersCatalogProductList' },
                meta: {
                    title: 'offersCatalog',
                    roles: [Role.ADVERTISER],
                },
                children: [
                    {
                        path: '',
                        name: 'OffersCatalogProductList',
                        component: OffersCatalogProductList,
                        meta: {
                            title: 'offersCatalog',
                            roles: [Role.ADVERTISER],
                        },
                    },
                    {
                        path: ':id(\\d+):sep(-)?:slug(.*)?',
                        name: 'OffersCatalogProductDetails',
                        component: OffersCatalogProductDetails,
                        meta: {
                            title: 'offersCatalog',
                            roles: [Role.ADVERTISER],
                        },
                    },
                ],
            },
            {
                path: 'catalog',
                name: 'Catalog',
                component: LayoutBlank,
                redirect: {
                    name: 'CatalogProducts',
                },
                children: [
                    {
                        path: 'products',
                        name: 'CatalogProducts',
                        component: Vue.component('CatalogProducts', {
                            render: function render(h) {
                                if (globalData.user.isRoleAdmin) return h(AdminCatalogProducts);
                                return h(LayoutBlank);
                            },
                        }),
                        meta: {
                            title: 'catalogProductsTitle',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'manufacturers',
                        name: 'CatalogManufacturers',
                        component: Vue.component('CatalogManufacturers', {
                            render: function render(h) {
                                if (globalData.user.isRoleAdmin) return h(AdminCatalogManufacturers);
                                return h(LayoutBlank);
                            },
                        }),
                        meta: {
                            title: 'catalogManufacturersTitle',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'brands',
                        name: 'CatalogBrands',
                        component: Vue.component('CatalogBrands', {
                            render: function render(h) {
                                if (globalData.user.isRoleAdmin) return h(AdminCatalogBrands);
                                return h(LayoutBlank);
                            },
                        }),
                        meta: {
                            title: 'catalogBrandsTitle',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'categories',
                        name: 'CatalogCategories',
                        component: Vue.component('CatalogCategories', {
                            render: function render(h) {
                                if (globalData.user.isRoleAdmin) return h(AdminCatalogCategories);
                                return h(LayoutBlank);
                            },
                        }),
                        meta: {
                            title: 'catalogCategoriesTitle',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'moderation',
                        name: 'CatalogModeration',
                        component: Vue.component('CatalogModeration', {
                            render: function render(h) {
                                if (globalData.user.isRoleAdmin) return h(AdminCatalogModeration);
                                return h(LayoutBlank);
                            },
                        }),
                        meta: {
                            title: 'moderation',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                ],
            },
            {
                path: 'users',
                name: 'Users',
                component: Vue.component('Users', {
                    render: function render(h) {
                        if (Number.parseInt(this.$route.params.id)) return h(LayoutBlank);
                        if (globalData.user.isRoleAdmin) return h(AdminUsers);
                        return h(LayoutBlank);
                    },
                }),
                meta: {
                    title: 'users',
                    roles: [Role.ADMIN, Role.SUPERADMIN],
                },
                children: [
                    {
                        path: ':id(\\d+):sep(-)?:slug(.*)?',
                        name: 'UsersDetails',
                        component: Vue.component('UserUsersDetails', {
                            render: function render(h) {
                                if (globalData.user.isRoleAdmin) return h(AdminUsersDetails);
                                return h(LayoutBlank);
                            },
                        }),
                        meta: {
                            title: 'pagesDetails',
                            roles: [Role.ADVERTISER, Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                ],
            },
            {
                path: 'offers',
                name: 'Offers',
                component: Vue.component('Offers', {
                    render: function render(h) {
                        if (Number.parseInt(this.$route.params.id)) return h(LayoutBlank);
                        if (globalData.user.isRoleAdmin) return h(AdminOffers);
                        return h(LayoutBlank);
                    },
                }),
                meta: {
                    title: 'offerFinder',
                    roles: [Role.ADMIN, Role.SUPERADMIN],
                },
                children: [
                    {
                        path: ':id(\\d+):sep(-)?:slug(.*)?',
                        name: 'OffersDetails',
                        component: Vue.component('UserOffersDetails', {
                            render: function render(h) {
                                if (globalData.user.isRoleAdmin) return h(AdminOffersDetails);
                                return h(LayoutBlank);
                            },
                        }),
                        meta: {
                            title: 'pagesDetails',
                            roles: [Role.ADVERTISER, Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                ],
            },
            {
                path: 'producer-products',
                name: 'ProducerProducts',
                component: LayoutBlank,
                redirect: {
                    name: 'ProducerProductsImport',
                },
                children: [
                    {
                        path: 'import',
                        name: 'ProducerProductsImport',
                        component: Vue.component('ProducerProductsImport', {
                            render: function render(h) {
                                if (globalData.user.isRoleAdmin) return h(AdminProducerProductsImport);
                                return h(LayoutBlank);
                            },
                        }),
                        meta: {
                            title: 'producerProductsImport',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                ],
            },
            {
                path: 'transactions',
                name: 'Transactions',
                component: LayoutBlank,
                redirect: {
                    name: 'TransactionsImport',
                },
                meta: {
                    title: 'transactions2',
                    roles: [Role.ADMIN, Role.SUPERADMIN],
                },
                children: [
                    {
                        path: 'import',
                        name: 'TransactionsImport',
                        component: AdminTransactionsImport,
                        meta: {
                            title: 'transactionsImport',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'validation',
                        name: 'TransactionsValidation',
                        component: AdminTransactionsValidation,
                        meta: {
                            title: 'transactionValidation',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'correction',
                        name: 'TransactionsCorrection',
                        component: AdminTransactionsCorrection,
                        meta: {
                            title: 'transactionCorrection',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                ],
            },
            {
                path: 'finance',
                name: 'AdminFinance',
                component: LayoutBlank,
                redirect: {
                    name: 'AdminFinanceSettlements',
                },
                meta: {
                    roles: [Role.ADMIN, Role.SUPERADMIN],
                },
                children: [
                    {
                        path: 'settlements',
                        name: 'AdminFinanceSettlements',
                        component: LayoutBlank,
                        redirect: {
                            name: 'AdminFinanceSettlementsPublishers',
                        },
                        meta: {
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                        children: [
                            {
                                path: 'publishers',
                                name: 'AdminFinanceSettlementsPublishers',
                                component: AdminFinanceSettlementsPublishers,
                                meta: {
                                    title: 'settlementsWithPublishers',
                                    roles: [Role.ADMIN, Role.SUPERADMIN],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'products',
                name: 'Products',
                component: Vue.component('UserProducts', {
                    render: function render(h) {
                        if (Number.parseInt(this.$route.params.id)) return h(LayoutBlank);
                        if (globalData.user.isRoleAdmin) return h(AdminProducts);
                        return h(LayoutBlank);
                    },
                }),
                meta: {
                    title: 'sidebar.productSearch',
                    roles: [Role.ADMIN, Role.SUPERADMIN],
                },
                children: [
                    {
                        path: ':id/audit',
                        name: 'ProductsItemAudit',
                        component: AdminProductsItemAudit,
                        meta: {
                            title: 'productAudit',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: ':id',
                        name: 'ProductsItemShow',
                        component: AdminProductsItemShow,
                        meta: {
                            title: 'productDetails',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                ],
            },
            {
                path: 'bb-affiliate-program',
                name: 'BBAffiliateProgram',
                component: BBAffiliateProgram,
                meta: {
                    title: 'sidebar.bbAffiliateProgram',
                    roles: [Role.WIDGETER, Role.SUPERADMIN],
                },
            },
            {
                path: 'tipbox',
                name: 'Tipbox',
                component: LayoutBlank,
                meta: {
                    roles: [Role.WIDGETER, Role.ADMIN, Role.SUPERADMIN],
                    feature: 'tipbox',
                },
                redirect: {
                    name: 'TipboxTips',
                },
                children: [
                    {
                        /* path created to redirect to the path for the correct user  */
                        path: 'tips',
                        name: 'TipboxTips',
                        redirect: to => {
                            if (to.path.includes('/admin/tipbox')) return { name: 'AdminTipboxTips' };
                            if (to.path.includes('/widgeter/tipbox')) return { name: 'WidgeterTipboxTips' };
                            return undefined;
                        },
                    },
                    {
                        /* path created to redirect to the path for the correct user  */
                        path: 'settlements',
                        name: 'TipboxSettlements',
                        redirect: to => {
                            if (to.path.includes('/admin/tipbox')) return { name: 'AdminTipboxSettlements' };
                            if (to.path.includes('/widgeter/tipbox')) return { name: 'WidgeterTipboxSettlements' };
                            return undefined;
                        },
                    },
                    {
                        path: 'tips',
                        name: 'AdminTipboxTips',
                        component: AdminTipboxTips,
                        meta: {
                            title: 'sidebar.tips',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'verification',
                        name: 'AdminTipboxVerification',
                        component: AdminTipboxVerification,
                        meta: {
                            title: 'sidebar.verification',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'settlements',
                        name: 'AdminTipboxSettlements',
                        component: AdminTipboxSettlements,
                        meta: {
                            title: 'sidebar.settlements',
                            roles: [Role.ADMIN, Role.SUPERADMIN],
                        },
                    },
                    {
                        path: 'tips',
                        name: 'WidgeterTipboxTips',
                        component: WidgeterTipboxTips,
                        meta: {
                            title: 'sidebar.tipboxTips',
                            roles: [Role.WIDGETER],
                        },
                    },
                    {
                        path: 'settlements',
                        name: 'WidgeterTipboxSettlements',
                        component: WidgeterTipboxSettlements,
                        meta: {
                            title: 'sidebar.tipboxSettlements',
                            roles: [Role.WIDGETER],
                        },
                    },
                    {
                        path: 'settings',
                        name: 'WidgeterTipboxSettings',
                        component: WidgeterTipboxSettings,
                        meta: {
                            title: 'sidebar.tipboxSettings',
                            roles: [Role.WIDGETER],
                        },
                    },
                    {
                        path: 'link',
                        name: 'WidgeterTipboxLink',
                        component: WidgeterTipboxLink,
                        meta: {
                            title: 'sidebar.tipboxLink',
                            roles: [Role.WIDGETER],
                            feature: 'tipbox-landing',
                        },
                    },
                ],
            },

            {
                path: '*',
                component: PageNotFound,
            },
        ],
    },
    {
        path: '',
        component: LayoutBasic,
        children: [
            {
                path: 'forbidden',
                name: 'Forbidden',
                component: PageForbidden,
            },
            {
                path: '*',
                component: PageNotFound,
            },
        ],
    },
];

const router = new Router({
    mode: 'history',
    routes,
    parseQuery(query) {
        return qs.parse(query);
    },
    stringifyQuery(query) {
        const result = qs.stringify(query);
        return result ? `?${result}` : '';
    },
});

router.beforeEach(hasDataLoadedGuard);
router.beforeEach(hasAccessGuard.bind(router));
router.beforeEach(hasFeatureGuard.bind(router));

router.afterEach((to, from) => {
    if (from.path !== to.path) {
        apiService.abortApiRequests();

        const brand = 'BUY.BOX';
        document.title = to.meta.title ? `${i18n.t(to.meta.title)} | ${brand}` : brand;

        if (window.ga) {
            window.ga('set', 'page', to.fullPath);
            window.ga('send', 'pageview');
        }

        if (usercomService.isUserComAvailable()) usercomService.pageHit(usercomService.getUserDataFromUser(globalData.user));

        if (typeof window.fbq === 'function') window.fbq('track', 'PageView');
    }
});

export default router;

export { routes };
