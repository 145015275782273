<template>
    <div>

        <PageHeader :items="breadcrumb">
            <div v-if="user">{{ $t('user') }}: {{ user.username }}</div>
            <template #side>
                <div v-if="!user && error">
                    <strong>{{ $t('goBackTo') }}</strong>: <b-link :to="parentRoute">
                        {{ $t(parentRoute.meta.title) }}
                    </b-link>
                </div>
                <b-skeleton v-if="!user && !error" animation="wave" width="100%" class="mb-0 mt-1 width-300" />
            </template>
        </PageHeader>

        <ErrorMessage v-if="error" :value="error" />

        <Card v-if="!user && loading" class="d-flex justify-content-center align-items-center width-min-100">
            <b-spinner />
        </Card>

        <b-overlay :show="isUserLoading && !!user" rounded="sm" class="h-100">

            <div v-if="user" class="wrapper d-flex flex-column gap-3">

                <div class="wrapper d-flex gap-3">

                    <div class="d-flex flex-column gap-3 flex-grow-2">

                        <Card class="m-0 shadow-none h-100">
                            <div class="grid-template">
                                <div>{{ $t('id') }}:</div>
                                <div>
                                    <div>{{ id }}</div>
                                    <div class="ml-auto">
                                        <div class="h5 m-0">
                                            <BadgeRole :role="user.role" />
                                        </div>
                                    </div>
                                </div>

                                <div>{{ $t('emailAddress') }}:</div>
                                <div>
                                    <FormValidator form-group :validator="$v.form.email" v-slot="slotProps">
                                        <b-form-input v-model="form.email" :disabled="isDisabled" :state="slotProps.state" />
                                    </FormValidator>
                                </div>

                                <div>{{ $t('firstAndLastName') }}:</div>
                                <div>
                                    <b-form-input v-model="form.profile.firstName" :disabled="isDisabled" />
                                    <b-form-input v-model="form.profile.lastName" :disabled="isDisabled" />
                                </div>

                                <div>{{ $t('street') }}:</div>
                                <div>
                                    <b-form-input v-model="form.profile.street" :disabled="isDisabled" class="flex-grow-3 flex-basis-0" />
                                    <b-form-input v-model="form.profile.streetNumber" :disabled="isDisabled" class="flex-grow-1 flex-basis-0" />
                                    <b-form-input v-model="form.profile.apartmentNumber" :disabled="isDisabled" class="flex-grow-1 flex-basis-0" />
                                </div>

                                <div>{{ $t('address') }}:</div>
                                <div>
                                    <b-form-input v-model="form.profile.city" :disabled="isDisabled" class="flex-grow-2 flex-basis-0" />
                                    <b-form-input v-model="form.profile.postcode" :disabled="isDisabled" class="flex-grow-1 flex-basis-0" />
                                </div>

                                <div>{{ $t('phoneNumber') }}:</div>
                                <div>
                                    <b-form-input v-model="form.profile.phone" :disabled="isDisabled" />
                                </div>

                                <div>{{ $t('country') }}:</div>
                                <div>
                                    <Multiselect :value="form.profile.country" :options="countryOptions" :disabled="isDisabled" @input="form.profile.country = $event.value" />
                                </div>

                                <div>{{ $t('bankAccount2') }}:</div>
                                <div>
                                    <b-form-input v-model="form.profile.bankAccount" :disabled="isDisabled" />
                                </div>

                                <div>{{ $t('companyAccount') }}:</div>
                                <div>
                                    <b-checkbox v-model="form.profile.isCompany" :disabled="isDisabled" switch size="lg" />
                                </div>

                                <template v-if="form.profile.isCompany">
                                    <div>{{ $t('company') }}:</div>
                                    <div>
                                        <b-form-input v-model="form.profile.companyName" :disabled="isDisabled" />
                                    </div>

                                    <div>{{ $t('taxId') }}:</div>
                                    <div>
                                        <b-form-input v-model="form.profile.companyNip" :disabled="isDisabled" />
                                    </div>
                                </template>

                                <div>{{ $t('deleted') }}:</div>
                                <div>
                                    <BadgeBoolean :value="user.isDelete" true-value-badge-variant="danger" false-value-badge-variant="success" />
                                </div>

                            </div>

                            <b-form-group class="mb-0 mt-5">
                                <div class="d-flex gap-2">
                                    <ButtonIcon
                                        class="width-min-140"
                                        hide-label-while-loading
                                        :loading="isSubmitting"
                                        :disabled="isDisabled || isTokenLoading"
                                        @click="submit()"
                                    >
                                        {{ $t('saveChanges') }}
                                    </ButtonIcon>
                                    <ButtonIcon
                                        v-if="!user.isDelete"
                                        class="width-min-140"
                                        hide-label-while-loading
                                        :loading="isDeleting"
                                        variant="danger"
                                        :disabled="isDisabled || isTokenLoading"
                                        @click="deleteUser()"
                                    >
                                        {{ $t('deleteUser') }}
                                    </ButtonIcon>
                                    <ButtonIcon
                                        class="flex-shrink-0"
                                        variant="secondary"
                                        hide-label-while-loading
                                        :disabled="isDisabled || isTokenLoading"
                                        :loading="isTokenLoading"
                                        @click="loginAs(user)"
                                    >
                                        {{ $t('go2') }}
                                    </ButtonIcon>
                                </div>
                            </b-form-group>
                        </Card>

                    </div>

                    <div class="d-flex flex-column gap-3 flex-grow-1">

                        <b-overlay v-if="user.role === Role.WIDGETER" :show="userNotificationsLoading" rounded="sm" class="h-100">
                            <Card class="m-0 shadow-none h-100" :header="$t('notifications')">

                                <div class="grid-template">

                                    <div>{{ $t('accountSettingsNotifications.news') }}:</div>
                                    <div class="ml-auto"><b-checkbox v-model="form.news" :disabled="isDisabled" switch size="lg" /></div>

                                    <div>{{ $t('accountSettingsNotifications.programs') }}:</div>
                                    <div class="ml-auto"><b-checkbox v-model="form.programs" :disabled="isDisabled" switch size="lg" /></div>

                                    <div>{{ $t('accountSettingsNotifications.promotions') }}:</div>
                                    <div class="ml-auto"><b-checkbox v-model="form.promotions" :disabled="isDisabled" switch size="lg" /></div>

                                    <div>{{ $t('accountSettingsNotifications.tips') }}:</div>
                                    <div class="ml-auto"><b-checkbox v-model="form.tips" :disabled="isDisabled" switch size="lg" /></div>

                                    <div>{{ $t('accountSettingsNotifications.display') }}:</div>
                                    <div class="ml-auto"><b-checkbox v-model="form.display" :disabled="isDisabled" switch size="lg" /></div>

                                </div>

                            </Card>
                        </b-overlay>

                        <Card class="m-0 shadow-none h-100">

                            <div class="grid-template">

                                <template v-for="item in (USER_OPTIONS[user.role] || [])">
                                    <div :key="`${user.role}-${item}-label`">{{ $t(`accountSettingsNotifications.${item}`) }}:</div>
                                    <div :key="`${user.role}-${item}-input`" class="ml-auto"><b-checkbox v-model="form[item]" :disabled="isDisabled" switch size="lg" /></div>
                                </template>

                            </div>

                        </Card>

                    </div>

                </div>

                <Card class="m-0 shadow-none h-100">

                    <ErrorMessage v-if="errorResetPassword" :value="errorResetPassword" />

                    <div class="grid-template">
                        <div>{{ $t('password') }}:</div>
                        <div>
                            <b-form-input v-model="form.password" :disabled="isDisabled" />
                            <ButtonIcon
                                class="width-min-140"
                                hide-label-while-loading
                                :loading="isPasswordReset"
                                :disabled="isDisabled"
                                variant="danger"
                                @click="resetPassword()"
                            >
                                {{ $t('resetPassword.title') }}
                            </ButtonIcon>
                        </div>
                    </div>

                </Card>

            </div>

        </b-overlay>
    </div>
</template>



<script>
    import { required, email } from 'vuelidate/lib/validators';
    import pick from 'lodash/pick';
    import cloneDeep from 'lodash/cloneDeep';
    import FormValidator from '@/components/common/FormValidator.vue';
    import BadgeRole from '@/components/common/BadgeRole.vue';
    import Role, { roleTranslate } from '@/Role';
    import countryService from '@/services/countryService';
    import Multiselect from '@/components/common/Multiselect.vue';
    import authService from '@/services/authService';
    import BadgeBoolean from '@/components/common/BadgeBoolean.vue';
    import { createItemKey } from '@/store/modules/moduleFactory';

    const FORM_DEFAULT_DATA = {
        email: '',
        password: '',
        profile: {
            firstName: '',
            lastName: '',
            phone: '',
            companyName: '',
            companyNip: '',
            street: '',
            streetNumber: '',
            apartmentNumber: '',
            city: '',
            postcode: '',
            country: '',
            bankAccount: '',
            isCompany: true,
            language: '',
        },
    };

    const USER_OPTIONS = {
        ROLE_WIDGETER: ['isLocked'],
        ROLE_PRODUCER: ['demo', 'news', 'isLocked'],
        ROLE_ADVERTISER: ['isNotification', 'isLocked'],
    };

    export default {
        name: 'UsersDetails',
        components: {
            FormValidator,
            BadgeRole,
            Multiselect,
            BadgeBoolean,
        },
        data() {
            return {
                USER_OPTIONS,
                Role,
                roleTranslate,
                id: Number.parseInt(this.$route.params.id),
                form: {},
                formValidations: {
                    email: { required, email },
                },
                isSubmitting: false,
                isDeleting: false,
                isPasswordReset: false,
                userNotifications: null,
                userNotificationsLoading: false,
                userNotificationsError: null,
            };
        },
        validations() {
            return {
                form: this.formValidations,
            };
        },
        computed: {
            isTokenLoading() {
                return this.$store.getters['user/getBusyItem']('userToken', createItemKey({ id: this.user?.id }));
            },
            user() {
                return this.$store.getters['user/getUsers'];
            },
            error() {
                return this.$store.getters['user/getError']('users');
            },
            errorResetPassword() {
                return this.$store.getters['user/getError']('resetPassword');
            },
            loading() {
                return this.userNotificationsLoading || this.isUserLoading;
            },
            isUserLoading() {
                return this.$store.getters['user/getLoading']('users');
            },
            breadcrumb() {
                if (!this.user) return [];
                return [
                    { text: this.$t(this.parentRoute.meta.title), to: this.$route.params.parentUrl || this.parentRoute },
                    { text: this.user.username || '---', active: true },
                ];
            },
            parentRoute() {
                return this.$route.params.parentRoute || this.$route.matched.slice(-2)[0];
            },
            roleBadgeVariant() {
                return role => {
                    if (role === Role.WIDGETER) return 'primary';
                    if (role === Role.ADVERTISER) return 'danger';
                    if (role === Role.PRODUCER) return 'success';
                    return 'secondary';
                };
            },
            requestParams() {
                const params = cloneDeep(this.form);
                if (!this.form.profile.isCompany) {
                    delete params.profile.companyName;
                    delete params.profile.companyNip;
                }
                delete params.password;
                return params;
            },
            urlParams() {
                return { id: this.id };
            },
            apiParams() {
                return {
                    requestParams: this.requestParams,
                    urlParams: this.urlParams,
                };
            },
            isDisabled() {
                return this.isSubmitting || this.isDeleting || this.isPasswordReset || this.loading;
            },
            countryOptions() {
                return countryService.getCountryOptions();
            },
        },
        watch: {
            loading: {
                handler() {
                    if (!this.loading) this.initFormData();
                },
            },
        },
        methods: {
            async loginAs(user) {
                return this.$store.dispatch('auth/loginAs', user).catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            fetchUserNotificationSettings() {
                if (this.user?.role === Role.WIDGETER) {
                    this.userNotificationsLoading = true;
                    return authService
                        .fetchUserNotificationSettings(this.id)
                        .then(data => {
                            this.userNotifications = data;
                        })
                        .catch(error => {
                            this.userNotificationsError = error;
                        })
                        .finally(() => {
                            this.userNotificationsLoading = false;
                        });
                }
                return Promise.resolve();
            },
            fetchUser() {
                return this.$store.dispatch('user/fetchUsers', { urlParams: this.urlParams });
            },
            async fetchData() {
                await this.fetchUser();
                await this.fetchUserNotificationSettings();
            },
            clearStoreData() {
                this.$store.commit('user/clearUsers');
            },
            initFormData() {
                if (this.user) {
                    const USER_OPTIONS_BY_ROLE = (USER_OPTIONS[this.user.role] || []).reduce((acc, item) => ({ ...acc, [item]: false }), {});
                    const form = { ...cloneDeep(FORM_DEFAULT_DATA), ...USER_OPTIONS_BY_ROLE, ...cloneDeep(this.userNotifications || {}) };
                    this.form = { ...form, ...pick(this.user, Object.keys(form)) };
                    this.form.profile = { ...form.profile, ...pick(this.user.profile, Object.keys(form.profile)) };
                }
            },
            resetForm() {
                this.initFormData();
                this.$v.$reset();
            },
            afterUpdate(user) {
                this.$toastr.success(this.$t('dataSavedSuccessfully'));
                if (user) {
                    this.$store.commit('user/setUsers', user);
                    this.fetchUserNotificationSettings();
                } else {
                    this.fetchData();
                }
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) return;
                this.isSubmitting = true;
                this.$store
                    .dispatch('user/patchUsers', this.apiParams)
                    .then(this.afterUpdate)
                    .catch(error => {})
                    .finally(() => {
                        this.isSubmitting = false;
                    });
            },
            deleteUser() {
                this.$bvModal
                    .msgBoxConfirm(this.$t('confirmYouWantDelete'), {
                        title: this.$t('confirm'),
                        okTitle: this.$t('yes'),
                        cancelTitle: this.$t('cancel'),
                        okVariant: 'danger',
                    })
                    .then(value => {
                        if (value) {
                            this.isDeleting = true;
                            this.$store
                                .dispatch('user/deleteUsers', { urlParams: this.urlParams })
                                .then(this.afterUpdate)
                                .catch(() => {})
                                .finally(() => (this.isDeleting = false));
                        }
                    });
            },
            resetPassword() {
                this.isPasswordReset = true;
                this.$store
                    .dispatch('user/postResetPassword', { urlParams: this.urlParams, requestParams: { password: this.form.password } })
                    .then(() => this.afterUpdate())
                    .catch(() => {})
                    .finally(() => (this.isPasswordReset = false));
            },
        },
        created() {
            this.clearStoreData();
            if (!this.user) this.fetchData();
        },
        destroyed() {
            this.clearStoreData();
        },
        mounted() {
            window.scrollTo(0, 0);
        },
    };
</script>



<style lang="scss" scoped>
    .grid-template {
        display: grid;
        grid-template-columns: minmax(25%, max-content) auto;
        gap: 1rem;

        > div {
            display: flex;
            gap: 1rem;
            align-items: center;

            fieldset {
                width: 100%;
                margin: 0;
            }
        }
    }
</style>
