<template>
    <div class="badge" :class="statusClass">
        {{ statusName }}
    </div>
</template>



<script>
    export default {
        name: 'TipboxStatusBadge',
        props: {
            status: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                default: null,
            },
        },
        computed: {
            statusName() {
                return this.label || this.$tx(`tipTransactionStatuses.${this.status}`, this.status);
            },
            statusClass() {
                if (this.status === 'requested') return 'badge-soft-primary';
                if (this.status === 'error') return 'badge-soft-danger';
                if (this.status === 'paid') return 'badge-soft-info';

                return 'badge-soft-dark';
            },
        },
    };
</script>
