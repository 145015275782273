<template>
    <Card :header="$t('filters')">

        <b-form-group :label="$t('space')" label-cols-sm="3">
            <MultiselectSpaces
                v-model="parentComponent.filters.space"
                :multiple="false"
                :show-checkboxes="false"
                :clear-on-select="false"
                :close-on-select="true"
                :preserve-search="true"
                :disabled="loading"
                @loading="value => isSpacesLoading = value"
            />
        </b-form-group>

        <b-form-group :label="$t('publisher')" label-cols-sm="3">
            <Multiselect
                :placeholder="$t('selectField.placeholder')"
                :show-checkboxes="true"
                :multiple="true"
                :clear-on-select="false"
                :close-on-select="false"
                :show-selected="true"
                :asynchronous="true"
                track-by="id"
                label="username"
                select-label=""
                :fetch-options-function="fetchUsers"
                :parse-options-function="parseUsers"
                :disabled="loading"
                @loading="value => isUsersLoading = value"
                @search-change="value => userSearchText = value"
                @input="value => parentComponent.filters.users = value"
            />
        </b-form-group>

        <b-form-group :label="$t('status')" label-cols-sm="3">
            <b-form-checkbox-group v-model="parentComponent.filters.statuses" :options="statusesOption" class="h-100 d-flex align-items-center" :disabled="loading" />
        </b-form-group>

        <ButtonIcon :disabled="loading || isUsersLoading || isSpacesLoading" icon="filter" @click="fetchData()">
            {{ $t('filter') }}
        </ButtonIcon>

    </Card>
</template>



<script>
    import Role from '@/Role';
    import authService from '@/services/authService';
    import Multiselect from '@/components/common/Multiselect.vue';
    import MultiselectSpaces from '@/components/common/MultiselectSpaces.vue';

    export default {
        name: 'TipsFilters',
        components: {
            Multiselect,
            MultiselectSpaces,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                userSearchText: '',
                isUsersLoading: false,
                isSpacesLoading: false,
            };
        },
        computed: {
            statusesOption() {
                return this.parentComponent.statuses.map(item => ({ text: item.label, value: item.value }));
            },
            loading() {
                return this.$store.getters['tipTransactions/getLoading']('transactions');
            },
        },
        methods: {
            fetchUsers() {
                return authService.fetchUserAccount('', { search: this.userSearchText, roles: Role.WIDGETER, page: 1, perPage: Number.MAX_SAFE_INTEGER });
            },
            parseUsers(data) {
                return data?.items || [];
            },
            fetchData() {
                this.parentComponent.$refs.Chart.fetchData();
                this.parentComponent.$refs.Results.fetchData();
            },
        },
    };
</script>
