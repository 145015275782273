<script>
    import cloneDeep from 'lodash/cloneDeep';
    import { generateChartData, chartDataGroupBy } from '@/utils/chart';
    import { i18n } from '@/i18n/i18n';
    import PageHeader from '@/components/common/PageHeader.vue';
    import TimeRangeDropdown from '@/components/common/TimeRangeDropdown.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import MultiselectSpaces from '@/components/common/MultiselectSpaces.vue';
    import MultiChartCard from '@/components/common/MultiChartCard.vue';
    import PieChart from './parts/PieChart.vue';
    import SiteStatistics from './parts/SiteStatistics.vue';

    export default {
        name: 'ReportsPagesMixin',
        components: {
            Multiselect,
            MultiselectSpaces,
            PageHeader,
            TimeRangeDropdown,
            MultiChartCard,
            PieChart,
            SiteStatistics,
        },
        data() {
            const userRoleShort = this.$store.getters['auth/getRoleShortName'];
            const DEFAULT_LINE_CHART_DATA = Object.freeze({
                ...(userRoleShort === 'widgeter'
                    ? {
                          widget_impressions: {
                              series: [],
                              name: i18n.t('widgeterReportsShops.impressions'),
                          },
                      }
                    : {
                          offers_impressions: {
                              series: [],
                              name: i18n.t('widgeterReportsShops.impressions'),
                          },
                      }),
                clicks: {
                    series: [],
                    name: i18n.t('widgeterReportsShops.clicks'),
                },
                orders: {
                    series: [],
                    name: i18n.t('widgeterReportsShops.transactions'),
                },
                amount: {
                    series: [],
                    name: i18n.t('widgeterReportsShops.transactionAmount'),
                },
                publisher_commission: {
                    series: [],
                    name: i18n.t('widgeterReportsShops.commission'),
                },
            });
            const DEFAULT_PIE_CHART_DATA = Object.freeze(cloneDeep(DEFAULT_LINE_CHART_DATA));

            return {
                currentChart: userRoleShort === 'widgeter' ? 'widget_impressions' : 'offers_impressions',
                DEFAULT_LINE_CHART_DATA,
                DEFAULT_PIE_CHART_DATA,
                lineChartData: cloneDeep(DEFAULT_LINE_CHART_DATA),
                pieChartData: cloneDeep(DEFAULT_PIE_CHART_DATA),
                timeRange: null,
                pages: [],
                searchText: '',
                spaces: [],
                isSpacesLoading: false,
            };
        },
        computed: {
            isSpaceReportLoading() {
                return this.$store.getters['userStats/getLoading']('spaceReport');
            },
            chartDataGroupBy() {
                return chartDataGroupBy(this.timeRange);
            },
            lineChartOptions() {
                let options = {
                    tooltip: {
                        y: {
                            formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                                if (this.currentChart === 'clicks' || this.currentChart === 'orders') return Math.round(value);
                                if (this.currentChart === 'amount' || this.currentChart === 'publisher_commission') return this.$format.currency(value);
                                return value;
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            formatter: (value, index) => {
                                if (this.currentChart === 'clicks' || this.currentChart === 'orders') return value.toFixed(0);
                                if (this.currentChart === 'amount' || this.currentChart === 'publisher_commission') return value.toFixed(2);
                                return value;
                            },
                        },
                    },
                };
                if (this.chartDataGroupBy === 'hour') {
                    options = {
                        ...options,
                        xaxis: {
                            labels: {
                                format: 'HH:mm',
                            },
                        },
                    };
                }
                return options;
            },
            chartDataFeature() {
                return this.currentChart;
            },
            spaceReport() {
                return this.$store.getters['userStats/getSpaceReport'];
            },
            currentChartDataExist() {
                return this.lineChartData[this.currentChart]?.series?.length && this.pieChartData[this.currentChart]?.series?.length;
            },
            pagesMultiselectFormat() {
                return Object.values(this.spaces).map(item => ({ value: item.id, text: item.name }));
            },
            spaceIds() {
                return this.pages.map(item => item.value);
            },
            requestParams() {
                return {
                    startDate: this.timeRange.startDate,
                    endDate: this.timeRange.endDate,
                    feature: this.chartDataFeature,
                    groupBy: this.chartDataGroupBy,
                    spaces: this.spaceIds.join(','),
                    campaigns: this.campaignId || '',
                };
            },
            apiParams() {
                return { requestParams: this.requestParams };
            },
        },
        watch: {
            timeRange(newValue, oldValue) {
                if (oldValue !== null) {
                    this.clearChartData();
                    this.fetchSpaceReport();
                }
            },
            currentChart() {
                if (!this.currentChartDataExist) {
                    this.fetchSpaceReport();
                }
            },
            pages() {
                this.clearChartData();
                this.fetchSpaceReport();
            },
            campaignId: {
                async handler() {
                    this.pages = [];
                },
            },
            spaceReport() {
                this.initChartData();
            },
        },
        methods: {
            async fetchSpaceReport() {
                return this.$store.dispatch('userStats/fetchSpaceReport', this.apiParams);
            },
            clearChartData() {
                this.lineChartData = cloneDeep(this.DEFAULT_LINE_CHART_DATA);
                this.pieChartData = cloneDeep(this.DEFAULT_PIE_CHART_DATA);
            },
            initChartData() {
                this.initPieChartData();
                this.initLineChartData();
            },
            initLineChartData() {
                const chartData = {};
                this.spaceReport?.chart?.forEach(item => {
                    const names = Object.keys(item);
                    const dateIndex = names.findIndex(name => name === 'date');
                    delete names[dateIndex];
                    names.forEach(name => {
                        if (!chartData[name]) chartData[name] = [];
                        chartData[name].push({ date: item.date, value: item[name] });
                    });
                });
                const newChartData = Object.keys(chartData).map(name => ({
                    name,
                    data: generateChartData(this.timeRange, chartData[name], this.chartDataGroupBy).map(item => [item.date, item.value]),
                }));
                this.$set(this.lineChartData[this.currentChart], 'series', newChartData);
            },
            initPieChartData() {
                const areAnyData = !!this.spaceReport?.pie_chart?.reduce((acc, item) => acc + item[1], 0);
                const newChartData = areAnyData ? this.spaceReport.pie_chart.map(item => ({ name: item[0], series: item[1] })) : [];
                this.$set(this.pieChartData[this.currentChart], 'series', newChartData);
            },
            setCurrentChart(currentChart) {
                this.currentChart = currentChart;
            },
            setTimeRange(timeRange) {
                this.timeRange = timeRange;
            },
        },
    };
</script>
