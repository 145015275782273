var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{attrs:{"header":_vm.$t('csvFilesList')}},[_c('DataTable',{attrs:{"items":_vm.tasks.items || [],"fields":_vm.fields,"total-rows":_vm.tasks.max,"current-page":_vm.currentPage,"per-page":_vm.perPage,"loading":_vm.loading,"no-local-sorting":""},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event}},scopedSlots:_vm._u([{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"nowrap"},[_vm._v(_vm._s(_vm.$format.dateAndHour(item.createdAt)))]),(item.processedAt)?_c('div',{staticClass:"nowrap"},[_vm._v(_vm._s(_vm.$format.dateAndHour(item.processedAt)))]):_vm._e()]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [(_vm.loadingTaskStatuses)?_c('b-skeleton',{staticClass:"mb-0",attrs:{"animation":"wave","width":"80px","height":"18px"}}):_c('TaskStatusBadge',{attrs:{"status":value,"label":_vm.taskStatusesObject[value]}})]}},{key:"cell(type)",fn:function(ref){
var value = ref.value;
return [(_vm.loadingTaskTypes)?_c('b-skeleton',{staticClass:"mb-0",attrs:{"animation":"wave","width":"80px","height":"18px"}}):_c('span',[_vm._v(_vm._s(_vm.taskTypesObject[value]))])]}},{key:"cell(comment)",fn:function(ref){
var item = ref.item;
return [(item.options)?_c('pre',{staticClass:"bg-soft-secondary border border-dark rounded p-2"},[_vm._v(_vm._s(item.options))]):_vm._e(),(item.comment)?_c('div',[_vm._v(_vm._s(item.comment))]):_vm._e(),(item.originalFilename)?_c('div',{staticClass:"badge badge-soft-dark"},[_vm._v(_vm._s(item.originalFilename))]):_vm._e()]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap gap-1 justify-content-end"},[_c('ButtonIcon',{staticClass:"width-80",class:{'opacity-30': !item.isReportDownloadable},attrs:{"size":"sm","hide-label-while-loading":"","loading":_vm.isItemDownloadReport(item.id),"disabled":!item.isReportDownloadable || _vm.isItemDeleted(item.id)},on:{"click":function($event){item.isReportDownloadable ? _vm.itemDownload(item.id) : null}}},[_vm._v(" "+_vm._s(_vm.$t('report'))+" ")]),_c('ButtonIcon',{staticClass:"width-80",attrs:{"size":"sm","variant":"danger","hide-label-while-loading":"","loading":_vm.isItemDeleted(item.id),"disabled":_vm.isItemDownloadReport(item.id)},on:{"click":function($event){return _vm.itemDelete(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }