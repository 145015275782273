<template>
    <div class="d-flex flex-column align-items-center">
        <div class="py-5 d-flex flex-column gap-6 width-max-1000 w-100">

            <div class="d-flex flex-column align-items-center">
                <h1 class="mb-1 text-center">{{ $t('buyboxAffiliateProgram') }}</h1>
                <p class="h5 font-weight-normal text-center width-max-700" v-html="$t('buyboxAffiliateProgramRecommendOurServices')"></p>
            </div>

            <div class="d-flex flex-column align-items-center">
                <h4 class="text-center my-3">{{ $t('yourReferralLinks') }}</h4>
                <p class="text-center my-1">{{ $t('theseAreYourUniqueRecommendingLinksToOurPlatform') }}</p>
                <p class="text-center my-1 mb-5">{{ $t('forEachPageYouAddedInThePanelWeHavePreparedASeparateRecommendationLink') }}</p>
                <b-table-simple small borderless>
                    <b-thead>
                        <b-tr>
                            <b-th class="border-bottom border-secondary">{{ $t('website') }}</b-th>
                            <b-th class="border-bottom border-secondary">{{ $t('link') }}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="space in spaceOptions" :key="space.value">
                            <b-td>{{ space.text }}</b-td>
                            <b-td>
                                <ButtonIcon
                                    class="p-0 w-100 "
                                    icon="copy"
                                    variant="link"
                                    flip-icon
                                    icon-class="ml-auto"
                                    :title="$t('clickToCopy')"
                                    @click="copyUrl(space.value)"
                                >
                                    {{ getUrl(space.value) }}
                                </ButtonIcon>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>

            <div class="d-flex flex-column align-items-center">
                <h4 class="mb-1 text-center my-3">{{ $t('advertisingMaterialsToDownload') }}</h4>
                <ButtonIcon size="lg" squared class="px-5" :href="materialsUrl">
                    {{ $t('downloadMaterials') }}
                </ButtonIcon>
            </div>

            <div class="d-flex flex-column align-items-center">
                <h4 class="mb-1 text-center my-3">{{ $t('faq') }}</h4>
                <div class="accordion d-flex flex-column gap-3 w-100">
                    <div v-for="(item, index) in FAQ" :key="`accordion-${index}`">
                        <ButtonIcon
                            v-b-toggle="`accordion-item-${index}`"
                            block
                            variant="link"
                            icon="chevron-down"
                            flip-icon
                            label-class="flex-grow-1"
                            squared
                            size="lg"
                            class="accordion-button"
                        >
                            {{ item.title }}
                        </ButtonIcon>
                        <b-collapse :id="`accordion-item-${index}`">
                            <div class="mt-2" v-html="item.text"></div>
                        </b-collapse>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>



<script>
    import toastService from '@/services/toastService';

    export default {
        name: 'BBAffiliateProgram',
        data() {
            return {
                materialsUrl: `${process.env.VUE_APP_MARKETING_PAGE_URL}/files/pp/BB-materialy.zip`,
                /* eslint-disable max-len */
                FAQ: [
                    {
                        title: 'Komu polecać platformę BUY.BOX?',
                        text: `
                            <p>Nasze rozwiązania są dla każdego, kto tworzy treści w internecie i chce na nich zarabiać. Wokół Ciebie na pewno jest mnóstwo osób, którzy robią podobne rzeczy. Powiedz im o nas.</p>
                            <ul>
                                <li>Właściciele stron www i blogerzy - każdy, kto opisuje i poleca różne produkty, np. książki, gry, elektronikę,</li>
                                <li>YouTuberzy, TikTokerzy, Bookstagramerzy - każdy, kto działa aktywnie w social media i opowiada o produktach.</li>
                                <li>Twórcy edukacyjnych treści - każdy, kto dzieli się wiedzą na dany temat i chce na tym zarabiać.</li>
                            </ul>
                        `,
                    },
                    {
                        title: 'W jaki sposób promować platformę BUY.BOX?',
                        text: `
                            <p>Do promowania naszej platformy możesz wykorzystać powyższe linki polecające oraz materiały graficzne. Możesz też wygenerować link do innej podstrony naszego serwisu https://getbuybox.com używając generator linków tekstowych.</p>
                        `,
                    },
                    {
                        title: 'Gdzie używać linków i materiałów reklamowych?',
                        text: `
                            <p>Skopiuj link polecający przygotowany dla Ciebie, a następnie:</p>
                            <ul>
                                <li>umieść link na swojej stronie www,</li>
                                <li>dodaj link w relacji lub poście,</li>
                                <li>
                                    wykorzystaj inne funkcje social media, np. zadaj pytanie lub zrób ankietę,
                                    <ul>
                                        <li>wyślij link do znajomych lub osób z Twoje bazy mailingowej, których mogą być zainteresowani naszą platformą.</li>
                                    </ul>
                                </li>
                            </ul>
                        `,
                    },
                    {
                        title: 'Jakie są warunki otrzymania wynagrodzenia?',
                        text: `
                            <ul>
                                <li>Każda rejestracja z twojego linku polecającego pojawi się na Twoim koncie BUY.BOX jako transakcja (lead) ze statusem „Nowa” o wartości 50 zł.</li>
                                <li>Aby dana zmieniła status na „Zaakceptowana”, osoba, która się zarejestrowała z Twojego linku musi swoimi działaniami wygenerować pierwsze 50 zł prowizji do wypłaty.</li>
                            </ul>
                        `,
                    },
                    {
                        title: 'Gdzie mogę sprawdzać efekty moich działań?',
                        text: `
                            <p>Liczbę rejestracji (leadów) z twoich działań znajdziesz w zakładce Raporty → Transakcje.</p>
                        `,
                    },
                ],
                /* eslint-enable max-len */
            };
        },
        computed: {
            spaceOptions() {
                return this.$store.getters['space/spaceOptions'];
            },
        },
        methods: {
            getUrl(spaceId) {
                return `https://go.buybox.click/linkclick_${spaceId}_722?&url=https%3A%2F%2Fgetbuybox.com%2F`;
            },
            async copyUrl(spaceId) {
                await this.$copyText(this.getUrl(spaceId));
                toastService.successToast(this.$t('referralLinkWasCopied'));
            },
        },
    };
</script>



<style lang="scss" scoped>
    @import 'bootstrap/scss/functions';
    @import 'bootstrap/scss/variables';
    @import '@/design/_variables.scss';

    .accordion-button {
        background: none;
        outline: none;
        border: none;
        border-bottom: 1px solid $body-color;
        color: $body-color;
        font-weight: bold;
        text-align: left;
        padding-left: 0;
        padding-right: 0;
    }
</style>
