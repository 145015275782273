<template>
    <div class="badge" :class="statusClass">
        {{ statusLabel }}
    </div>
</template>



<script>
    import { TransactionStatus, translateTransactionStatus } from '@/services/transactionService';

    export default {
        name: 'TransactionStatusBadge',
        props: {
            status: {
                type: [String, Number],
                required: true,
            },
        },
        computed: {
            statusLabel() {
                return translateTransactionStatus(parseInt(this.status));
            },
            statusClass() {
                const status = parseInt(this.status);
                if (status === TransactionStatus.NEW) return 'badge-soft-primary';
                if (status === TransactionStatus.ACCEPTED) return 'badge-soft-success';
                if (status === TransactionStatus.REJECTED) return 'badge-soft-danger';
                if (status === TransactionStatus.ABANDONED) return 'badge-soft-secondary';

                return 'badge-soft-dark';
            },
        },
    };
</script>
