<template>
    <div class="card">
        <div class="card-body p-0">
            <h5 class="card-title header-title border-bottom p-3 mb-0">
                {{ title }}
            </h5>
            <div class="px-3">
                <slot></slot>
            </div>
            <div
                v-for="(item, index) in items"
                :key="item.title"
                class="media px-3 py-4"
                :class="{[item.class]: true, 'border-bottom': !isLast(index)}"
            >
                <div class="media-body">
                    <h4 class="mt-0 mb-1 font-size-22 font-weight-normal">
                        {{ item.value }}
                    </h4>
                    <span class="text-muted">{{ item.title }}</span>
                </div>
                <div v-if="item.name" class="action">
                    <slot :name="`action_${item.name}`"></slot>
                </div>
                <feather
                    v-if="item.icon"
                    :type="item.icon"
                    class="icon-dual icon-lg align-self-center"
                />
            </div>
        </div>
    </div>
</template>



<script>
    export default {
        name: 'WidgetOverview',
        props: {
            title: {
                type: String,
                default: 'Overview',
            },
            items: {
                type: Array,
                default: () => [],
            },
        },
        methods: {
            isLast(index) {
                return index + 1 === this.items.length;
            },
        },
    };
</script>
