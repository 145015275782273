<template>

    <b-overlay :show="overlayShow" rounded="sm" class="h-100">

        <Card :no-padding="noPadding" class="m-0 shadow-none" :header="header">

            <template #nav>
                <slot name="headerSide"></slot>
            </template>

            <template #header>
                <slot name="header" :items="internalItems"></slot>
            </template>

            <ErrorMessage :value="error || tableError" class="table-error" @dismissed="$emit('update:error', null)" />

            <div class="overflow-auto">

                <slot name="table-header" :items="internalItems"></slot>

                <b-table
                    ref="bTable"
                    class="m-0"
                    :hover="hover"
                    :items="tableItems"
                    :fields="tableFields"
                    :empty-text="$t('noResult')"
                    show-empty
                    no-sort-reset
                    :busy="tableBusy"
                    :current-page="tableTotalRows === internalItems.length ? tableCurrentPage : 1"
                    :per-page.sync="tablePerPage"
                    :sort-by.sync="tableSortBy"
                    :sort-desc.sync="tableSortDesc"
                    :sort-direction="sortDirection.toLowerCase()"
                    v-bind="tableAttrs"
                    v-on="tableListeners"
                    @input="onInput"
                    @update:busy="onUpdateBusy"
                >
                    <template v-for="slotName in Object.keys($slots)" #[slotName]>
                        <slot :name="slotName"></slot>
                    </template>

                    <template v-for="slotName in Object.keys($scopedSlots)" #[slotName]="slotProps">
                        <slot :name="slotName" v-bind="slotProps"></slot>
                    </template>

                    <template #head($selected)>
                        <div class="position-relative w-100 h-100 cursor-pointer table-selectable-all-item" :class="{'cursor-not-allowed': isAnyItemBusy || !selectableItems.length}" @click="toggleAllRow()">
                            <div class="position-absolute w-100 h-100 z-index-10"></div>
                            <b-form-checkbox
                                class="z-index-1 table-selectable-all-checkbox"
                                :checked="isAllRowsSelected"
                                :indeterminate="!isAllRowsSelected && isAnyRowsSelected"
                                :disabled="isAnyItemBusy || !selectableItems.length"
                                readonly
                                @click.native.prevent
                            />
                        </div>
                    </template>

                    <template #cell($selected)="data">
                        <div v-if="isRowSelectable(data)" class="position-relative w-100 h-100 cursor-pointer table-selectable-item" :class="{'cursor-not-allowed': isRowBusy(data)}" @click="toggleRow(data)">
                            <div class="position-absolute w-100 h-100 z-index-10"></div>
                            <b-form-checkbox class="z-index-1 table-selectable-checkbox" :checked="isRowSelected(data)" readonly :disabled="isRowBusy(data)" @click.native.prevent />
                        </div>
                        <!-- <b-spinner v-if="isItemBusy(data.item.id)" small /> -->
                    </template>

                    <template v-if="!internalBusy" #table-busy>
                        <div class="d-flex justify-content-center my-2">
                            <b-spinner />
                        </div>
                    </template>

                </b-table>

            </div>

            <div v-if="pagination" class="table-footer d-flex gap-3 align-items-center mt-3">

                <b-dropdown variant="primary" class="table-per-page" :disabled="loading || tableBusy">
                    <template #button-content>
                        <span>{{ tablePerPageLabel }}</span>
                        <i class="icon ml-1">
                            <feather type="chevron-down" class="align-middle" />
                        </i>
                    </template>
                    <b-dropdown-item v-for="item in perPageItems" :key="item.value" :class="`item-${item.value}`" :active="item.value === tablePerPage" @click="() => tablePerPage = item.value">
                        {{ item.label }}
                    </b-dropdown-item>
                </b-dropdown>

                <slot name="innerPagination"></slot>

                <Pagination
                    :value="tableCurrentPage"
                    :total-rows="tableTotalRows"
                    :per-page="tablePerPage"
                    :disabled="loading || tableBusy"
                    class="ml-auto"
                    @input="val => (loading ? null : (tableCurrentPage = val))"
                />

            </div>

            <slot name="afterTable"></slot>

        </Card>

    </b-overlay>

</template>



<script>
    import Pagination from '@/components/common/Pagination.vue';

    const availablePerPageValues = [10, 20, 25, 50, 100, 500, 1000];

    export default {
        name: 'DataTable',
        components: {
            Pagination,
        },
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            items: {
                type: [Array, Function],
                required: true,
            },
            fields: {
                type: Array,
                required: true,
            },
            pagination: {
                type: Boolean,
                default: true,
            },
            totalRows: {
                type: Number,
                default: null,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            perPageValues: {
                type: Array,
                default: () => [10, 25, 50, 100],
                validator: value => value.every(item => availablePerPageValues.includes(item)),
            },
            perPage: {
                type: Number,
                default: 10,
                validator: value => availablePerPageValues.includes(value),
            },
            currentPage: {
                type: Number,
                default: 1,
            },
            sortBy: {
                type: String,
                default: 'id',
            },
            sortDirection: {
                type: String,
                default: 'DESC',
            },
            noPadding: {
                type: Boolean,
                default: false,
            },
            error: {
                type: [Error, Object],
                default: null,
            },
            header: {
                type: String,
                default: '',
            },
            selectable: {
                type: Boolean,
                default: false,
            },
            selectableIdKey: {
                type: String,
                default: '',
            },
            selectableItemKey: {
                type: String,
                default: null,
            },
            selectableItemFunction: {
                type: Function,
                default: null,
            },
            busyItems: {
                type: Array,
                default: () => [],
            },
            hover: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            const isProvider = typeof this.items === 'function';
            return {
                tablePerPage: this.pagination ? this.perPage : Number.MAX_SAFE_INTEGER,
                tableCurrentPage: this.currentPage,
                tableSortBy: this.sortBy,
                tableSortDesc: this.sortDirection.toLowerCase() === 'desc',
                selectedRows: [],
                perPageItems: this.perPageValues.map(item => ({ value: item, label: item })),
                tableError: null,
                internalItems: isProvider ? [] : this.items,
                internalBusy: false,
            };
        },
        computed: {
            tableItems() {
                if (this.isProvider) {
                    return (...args) => {
                        this.tableError = null;
                        this.selectedRows = [];
                        return this.items(...args).catch(error => {
                            this.internalBusy = false;
                            this.tableError = error;
                            return [];
                        });
                    };
                }
                return this.items;
            },
            isProvider() {
                return typeof this.items === 'function';
            },
            overlayShow() {
                return !!(this.loading && this.internalItems.length) || this.internalBusy;
            },
            tableBusy() {
                return !!(this.loading && !this.internalItems.length);
            },
            tableAttrs() {
                return this.$attrs;
            },
            tableListeners() {
                delete this.$listeners.input; // eslint-disable-line
                delete this.$listeners.change; // eslint-disable-line
                return this.$listeners;
            },
            tableFields() {
                if (this.isSelectable === true) return [{ key: '$selected', label: '', tdClass: 'width-50' }, ...this.fields];
                return this.fields;
            },
            tableTotalRows() {
                return this.totalRows || this.internalItems.length;
            },
            tablePerPageLabel() {
                if (!this.pagination) return '';
                return this.perPageItems.find(item => item.value === this.tablePerPage).label;
            },
            isSelectable() {
                return !!this.selectable;
            },
            isAllRowsSelected() {
                const allSelectableItemsIds = this.getAllSelectableItemsIds();
                return allSelectableItemsIds.length && allSelectableItemsIds.every(id => this.selectedRows.includes(id));
            },
            isAnyRowsSelected() {
                const allSelectableItemsIds = this.getAllSelectableItemsIds();
                return allSelectableItemsIds.some(id => this.selectedRows.includes(id));
            },
            isAnyItemBusy() {
                return this.busyItems.length > 0;
            },
            selectableItems() {
                return this.internalItems.filter(this.isItemSelectable);
            },
        },
        watch: {
            perPage() {
                this.tablePerPage = this.pagination ? this.perPage : Number.MAX_SAFE_INTEGER;
            },
            currentPage() {
                this.tableCurrentPage = this.currentPage;
            },
            sortDirection() {
                this.tableSortDesc = this.sortDirection.toLowerCase() === 'desc';
            },
            sortBy() {
                this.tableSortBy = this.sortBy;
            },
            isSelectable: {
                handler() {
                    if (this.isSelectable === true && !this.selectableIdKey) {
                        this.tableError = new Error('Prop "selectableIdKey" is required!');
                    } else {
                        this.tableError = null;
                    }
                },
                immediate: true,
            },
            value: {
                handler() {
                    if (!this.isSameArrays(this.value, this.selectedRows)) this.selectedRows = this.value;
                },
                immediate: true,
            },
            selectedRows(newValue, oldValue) {
                if (!this.isSameArrays(newValue, oldValue)) this.$emit('input', newValue);
            },
            internalItems(newValue, oldValue) {
                if (!this.isProvider && !this.isSameArrays(newValue, oldValue)) this.selectedRows = [];
            },
            fields() {
                this.removeNonexistentSelectedRows();
            },
            tablePerPage(val) {
                this.$emit('update:per-page', val);
            },
            tableCurrentPage(val) {
                this.$emit('update:current-page', val);
            },
            tableSortBy(val) {
                this.$emit('update:sort-by', val);
            },
            tableSortDesc(val) {
                this.$emit('update:sort-direction', val ? 'DESC' : 'ASC');
            },
            selectableItems(newValue, oldValue) {
                if (!this.isSameArrays(newValue, oldValue)) {
                    const allSelectableItemsIds = this.getAllSelectableItemsIds();
                    this.selectedRows = this.selectedRows.filter(id => allSelectableItemsIds.includes(id));
                }
            },
        },
        methods: {
            onUpdateBusy(busy) {
                this.internalBusy = this.isProvider ? busy : false;
            },
            onInput(e) {
                this.internalItems = (this.isProvider ? this.$refs.bTable?.computedItems : this.items) || [];
            },
            isSameArrays(arrayOne, arrayTwo) {
                const sortedNew = [...arrayOne].sort((a, b) => a > b);
                const sortedOld = [...arrayTwo].sort((a, b) => a > b);
                try {
                    return JSON.stringify(sortedNew) === JSON.stringify(sortedOld);
                } catch (error) {
                    return null;
                }
            },
            toggleRow(row) {
                if (this.isRowBusy(row)) return;
                if (this.isRowSelected(row)) {
                    this.unselectRow(row);
                } else {
                    this.selectRow(row);
                }
            },
            selectRow(row) {
                if (this.isRowSelectable(row)) {
                    const rowId = row.item[this.selectableIdKey];
                    this.selectedRows = [...this.selectedRows, rowId];
                }
            },
            unselectRow(row) {
                const rowId = row.item[this.selectableIdKey];
                this.selectedRows = this.selectedRows.filter(id => id !== rowId);
            },
            isRowSelectable(row) {
                return this.isItemSelectable(row.item);
            },
            isItemSelectable(item) {
                if (typeof this.selectableItemFunction === 'function') return this.selectableItemFunction(item);
                if (this.selectableItemKey) return !!item[this.selectableItemKey];
                return item.selectable !== false;
            },
            isRowSelected(row) {
                const rowId = row.item[this.selectableIdKey];
                return this.selectedRows.includes(rowId);
            },
            selectAllRows() {
                this.selectedRows = this.getAllSelectableItemsIds();
            },
            unselectAllRows() {
                this.selectedRows = [];
            },
            getAllSelectableItemsIds() {
                return this.selectableItems.map(item => item[this.selectableIdKey]);
            },
            removeNonexistentSelectedRows() {
                const allSelectableItemsIds = this.getAllSelectableItemsIds();
                const someSelectedRowDoesNotExist = this.selectedRows.some(id => !allSelectableItemsIds.includes(id));
                if (someSelectedRowDoesNotExist) this.selectedRows = this.selectedRows.filter(id => allSelectableItemsIds.includes(id));
            },
            toggleAllRow() {
                if (this.isAnyItemBusy) return;
                if (this.isAllRowsSelected) {
                    this.unselectAllRows();
                } else {
                    this.selectAllRows();
                }
            },
            isRowBusy(row) {
                const rowId = row.item[this.selectableIdKey];
                return this.isItemBusy(rowId);
            },
            isItemBusy(id) {
                return this.busyItems.includes(id);
            },
        },
    };
</script>
