<template>
    <b-modal
        :id="`modal-preview-${parentComponent.modalItemName}`"
        ref="modal"
        :title="$t('details')"
        size="xl"
        hide-footer
        scrollable
    >
        <template v-if="item">
            <JsonViewer :value="item" :expand-depth="2" />
        </template>
    </b-modal>
</template>



<script>
    import JsonViewer from 'vue-json-viewer';

    export default {
        name: 'ItemPreview',
        components: {
            JsonViewer,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                item: null,
            };
        },
        methods: {
            onItem(item) {
                this.item = item;
            },
        },
        mounted() {
            this.$root.$on(`preview-${this.parentComponent.modalItemName}`, this.onItem);
        },
        beforeDestroy() {
            this.$root.$off(`preview-${this.parentComponent.modalItemName}`, this.onItem);
        },
    };
</script>
