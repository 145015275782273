<template>
    <div>

        <PageHeader>
            {{ $t('transactionImport') }}
        </PageHeader>

        <FormCsv request-endpoint="transactionFileProcessTask/import" :button-label="$t('import')" @imported="fetchTasks()" />

        <FormTradedoubler @imported="fetchTasks()" />

        <FormAllegro @imported="fetchTasks()" />

        <ListCsv ref="refListCsv" />

    </div>
</template>



<script>
    import FormCsv from '../parts/FormCsv.vue';
    import FormTradedoubler from '../parts/FormTradedoubler.vue';
    import FormAllegro from '../parts/FormAllegro.vue';
    import ListCsv from '../parts/ListCsv.vue';

    export default {
        name: 'TransactionImport',
        components: {
            FormCsv,
            FormTradedoubler,
            FormAllegro,
            ListCsv,
        },
        data() {
            return {};
        },
        methods: {
            fetchTasks() {
                this.$refs.refListCsv?.fetchTasks();
            },
        },
    };
</script>



<style lang="scss" scoped>
    ::v-deep {
        .form-item {
            .form-row {
                display: flex;

                > legend {
                    width: 200px;
                    flex: 0 1 auto;
                }

                > legend ~ div {
                    flex: 10 1 0;
                    min-width: min(100%, 400px);
                    max-width: 600px;
                    margin-right: auto;
                }
            }
        }
    }
</style>
