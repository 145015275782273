<template>
    <div>
        <PageHeader>
            {{ $t('producerProductsImport') }}
        </PageHeader>

        <Card :header="$t('producerProductsImportDescription')" :disabled="busy">

            <div class="d-flex gap-3 flex-column">

                <ErrorMessage :value="error" />

                <b-alert :if="importResponse && Object.keys(importResponse.errors || {}).length" variant="danger" dismissible>
                    <pre>
                        {{ importResponse.errors }}
                    </pre>
                </b-alert>

                <FormValidator form-group :validator="$v.space" v-slot="slotProps" class="mb-0 flex-grow-1 width-min-300">
                    <MultiselectSpaces
                        v-model="space"
                        :multiple="false"
                        :show-checkboxes="false"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :preserve-search="true"
                        :disabled="busy"
                        :state="slotProps.state"
                        @loading="value => isSpacesLoading = value"
                    />
                </FormValidator>

                <FormValidator form-group :validator="$v.form.importFile" v-slot="slotProps" class="mb-0 flex-grow-1 width-min-300">
                    <FormFile v-model="form.importFile" :disabled="busy" :state="slotProps.state" accept="application/JSON" />
                </FormValidator>

                <div class="d-flex justify-content-end">
                    <ButtonIcon :loading="busy" :disabled="busy" class="width-min-150" @click="submit()">
                        {{ $t('importFile') }}
                    </ButtonIcon>
                </div>

            </div>
        </Card>

        <Card v-if="Object.keys(importResponse || {}).length" :header="$t('importResult')">
            <ul>
                <template v-for="key in Object.keys(importResponse)">
                    <li v-if="key !== 'errors'" :key="key">
                        {{ $t(key) }}: {{ importResponse[key] }}
                    </li>
                </template>
            </ul>
        </Card>
    </div>
</template>



<script>
    import { required } from 'vuelidate/lib/validators';
    import { serialize as objectToFormData } from 'object-to-formdata';
    import FormFile from '@/components/common/FormFile.vue';
    import FormValidator from '@/components/common/FormValidator.vue';
    import MultiselectSpaces from '@/components/common/MultiselectSpaces.vue';

    export default {
        name: 'ProducerProductsImport',
        components: {
            FormFile,
            FormValidator,
            MultiselectSpaces,
        },
        data() {
            return {
                isSpacesLoading: false,
                space: null,
                form: {
                    importFile: null,
                },
            };
        },
        validations() {
            return {
                space: { required },
                form: {
                    importFile: { required },
                },
            };
        },
        computed: {
            busy() {
                return this.$store.getters['producerProduct/getBusy']('import');
            },
            error() {
                return this.$store.getters['producerProduct/getError']('import');
            },
            importResponse() {
                return this.$store.getters['producerProduct/getImport'];
            },
            requestParams() {
                return this.form;
            },
            apiParams() {
                return {
                    requestParams: objectToFormData(this.requestParams),
                    urlParams: { spaceId: this.space?.value },
                    headers: { 'Content-Type': 'multipart/form-data' },
                };
            },
        },
        methods: {
            clearStoreData() {
                this.$store.commit('producerProduct/clearImport');
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) return;
                this.clearStoreData();
                this.$store
                    .dispatch('producerProduct/postImport', this.apiParams)
                    .then(data => {
                        this.$toastr.success(this.$t('dataImportedSuccessfully'));
                        this.$store.commit('producerProduct/setImport', data);
                        this.$v.form.importFile.$reset();
                        this.form.importFile = null;
                    })
                    .catch(error => {});
            },
        },
        created() {
            this.clearStoreData();
        },
    };
</script>
