import { addDays, differenceInDays, format, startOfMonth } from 'date-fns';
import { enGB, pl } from 'date-fns/locale';
import { i18n, currentLocale, getLanguageFromLocale } from '../i18n/i18n';

const locales = {
    'en-GB': enGB,
    pl,
};

const lang = getLanguageFromLocale(currentLocale);

const currentLocaleConfig = locales[lang] || locales['en-GB'];

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default {
    createYearOptions({ from, to = new Date().getUTCFullYear() }) {
        const options = [];
        for (let year = from; year <= to; year += 1) {
            options.push({
                text: year,
                value: year,
            });
        }
        return options.reverse();
    },

    createMonthOptions() {
        const options = [];
        for (let month = 0; month <= 11; month += 1) {
            options.push({
                text: this.getMonthName(month),
                value: month + 1,
            });
        }
        return options;
    },

    getMonthName(monthNo) {
        const date = startOfMonth(new Date());
        date.setMonth(monthNo);
        return capitalizeFirstLetter(i18n.d(date, 'monthName'));
    },

    formatDate(value, dateFormat = 'dd.MM.yyyy') {
        const date = value instanceof Date ? value : new Date(value);
        return format(date, dateFormat || 'dd.MM.yyyy', { locale: currentLocaleConfig });
    },

    formatHour(value, timeFormat = 'HH:mm') {
        const date = value instanceof Date ? value : new Date(value);
        return format(date, timeFormat || 'HH:mm', { locale: currentLocaleConfig });
    },

    /**
     * Converts seconds into a formatted string value
     * @param value
     * @return {string}
     */
    secondsToString(value) {
        let seconds = value;
        const days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        const hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        const minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        seconds = Math.round(seconds);

        const parts = [];
        if (days > 0) {
            parts.push(`${days}${i18n.t('timeShort.days')}`);
        }
        if (hours > 0) {
            parts.push(`${hours}${i18n.t('timeShort.hours')}`);
        }
        if (minutes > 0) {
            parts.push(`${minutes}${i18n.t('timeShort.minutes')}`);
        }
        if (seconds > 0) {
            parts.push(`${seconds}${i18n.t('timeShort.seconds')}`);
        }

        return parts.length > 0 ? parts.join(' ') : `0${i18n.t('timeShort.seconds')}`;
    },

    formatMonth(value) {
        const date = value instanceof Date ? value : new Date(value);
        return this.getMonthName(date.getMonth());
    },

    formatDateForUrl(value) {
        const date = value instanceof Date ? value : new Date(value);
        return format(date, 'yyyy-MM-dd');
    },

    addDays(date, days) {
        return addDays(date, days);
    },

    differenceInDays(dateLeft, dateRight) {
        return differenceInDays(dateLeft, dateRight);
    },
};
