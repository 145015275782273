<template>
    <b-modal
        :id="`modal-form-${modalId}`"
        ref="modal"
        :title="modalTitle"
        size="lg"
        cancel-variant="light"
        ok-variant="primary"
        :cancel-title="$t('cancel')"
        :ok-title="$t('save')"
        :busy="submitting"
        static
        @ok.prevent="handleSubmit()"
        @show="resetData()"
        @hidden="resetData()"
    >
        <b-overlay :show="submitting" opacity="0" class="h-100">
            <b-form @submit.prevent>

                <ErrorMessage :value="error" />

                <FormValidator :validator="$v.form.email" form-group v-slot="slotProps" :label="$t('email')">
                    <b-form-input v-model="form.email" :placeholder="$t('email')" :state="slotProps.state" :disabled="submitting" />
                </FormValidator>

                <FormValidator :validator="$v.form.password" form-group v-slot="slotProps" :msg-props="{min: 8, max: 255}" :label="$t('password')">
                    <b-form-input v-model="form.password" :placeholder="$t('password')" :state="slotProps.state" :disabled="submitting" type="password" />
                </FormValidator>

                <b-form-group v-if="role !== Role.ADVERTISER && role !== Role.PRODUCER">
                    <b-form-checkbox v-model="form.isCompany" :disabled="submitting" :value="true" :unchecked-value="false" switch>
                        {{ $t('companyAccount') }}
                    </b-form-checkbox>
                </b-form-group>

                <FormValidator :validator="$v.form.firstName" form-group v-slot="slotProps" :label="$t('firstName')">
                    <b-form-input v-model="form.firstName" :placeholder="$t('firstName')" :state="slotProps.state" :disabled="submitting" />
                </FormValidator>

                <FormValidator :validator="$v.form.lastName" form-group v-slot="slotProps" :label="$t('lastName')">
                    <b-form-input v-model="form.lastName" :placeholder="$t('lastName')" :state="slotProps.state" :disabled="submitting" />
                </FormValidator>

            </b-form>
        </b-overlay>
    </b-modal>
</template>



<script>
    import { required, email, minLength, maxLength } from 'vuelidate/lib/validators';
    import ModalFormMixin from '@/mixins/modalFormMixin';
    import FormValidator from '@/components/common/FormValidator.vue';
    import Role, { roleTranslate } from '@/Role';

    export default {
        name: 'ModalForm',
        mixins: [ModalFormMixin],
        components: {
            FormValidator,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                Role,
                modalId: 'users',
                form: {
                    email: '',
                    password: '',
                    isCompany: false,
                    firstName: '',
                    lastName: '',
                },
                role: null,
            };
        },
        validations() {
            return {
                form: {
                    email: { required, email },
                    password: { minLength: minLength(8), maxLength: maxLength(255) },
                    firstName: { required },
                    lastName: { required },
                },
            };
        },
        computed: {
            modalTitle() {
                return this.itemId ? this.$t('youEditX', { name: this.nameOfEditItem }) : `${this.$t('addUser')}: ${roleTranslate(this.role)}`;
            },
            nameOfEditItem() {
                return this.item?.profile ? `${this.item.profile.firstName} ${this.item.profile.lastName}` : '';
            },
            createAction() {
                if (this.role === Role.WIDGETER) return 'user/postPublishers';
                if (this.role === Role.ADVERTISER) return 'user/postReporters';
                if (this.role === Role.PRODUCER) return 'user/postProducers';
                return '';
            },
            updateAction() {
                return 'user/patchUsers';
            },
            requestParams() {
                const isCompany = this.role === Role.ADVERTISER || this.role === Role.PRODUCER ? true : this.form.isCompany;
                if (this.itemId) {
                    return {
                        email: this.form.email,
                        profile: {
                            isCompany,
                            firstName: this.form.firstName,
                            lastName: this.form.lastName,
                        },
                    };
                }
                return {
                    ...this.form,
                    isCompany,
                };
            },
        },
        watch: {
            item() {
                if (this.item?.role) this.role = this.item.role;
            },
        },
        methods: {
            onInit(item, role) {
                ModalFormMixin.methods.onInit.bind(this)(item);
                this.role = role;
            },
            formatFormData() {
                return { ...this.item.profile };
            },
            refreshData() {
                this.parentComponent.$refs.Filters?.fetchUsers({ cache: false });
            },
        },
    };
</script>
