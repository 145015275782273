import apiService from '@/services/apiService';
import socialAuthService from '@/services/socialAuthService';
import { isAdminRole, mode } from '@/utils/util';
import { getLocaleFromLanguage, i18n, loadLanguageAsync } from '../../i18n/i18n';
import router from '../../router/router';
import authService from '../../services/authService';
import { checkFeatureFlag } from '../../utils/featureFlags';
import {
    ACCEPT_TERMS_FAILURE,
    ACCEPT_TERMS_REQUEST,
    ACCEPT_TERMS_SUCCESS,
    AUTHENTICATE_AS_FAILURE,
    AUTHENTICATE_AS_REQUEST,
    AUTHENTICATE_AS_SUCCESS,
    AUTHENTICATE_FAILURE,
    AUTHENTICATE_REQUEST,
    AUTHENTICATE_SUCCESS,
    REGISTER_FAILURE,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    FETCH_USER_FULL_FAILURE,
    FETCH_USER_FULL_REQUEST,
    FETCH_USER_FULL_SUCCESS,
    FETCH_USER_LIGHT_FAILURE,
    FETCH_USER_LIGHT_REQUEST,
    FETCH_USER_LIGHT_SUCCESS,
    FETCH_USER_PROFILE_FAILURE,
    FETCH_USER_PROFILE_REQUEST,
    FETCH_USER_PROFILE_SUCCESS,
    INITIALIZE_AUTH,
    LOGOUT,
    REFRESH_TOKEN_FAILURE,
    REFRESH_TOKEN_REQUEST,
    REFRESH_TOKEN_SUCCESS,
    RESET_PASSWORD_COMPLETE_FAILURE,
    RESET_PASSWORD_COMPLETE_REQUEST,
    RESET_PASSWORD_COMPLETE_SUCCESS,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    UPDATE_ADDRESS_SETTINGS_FAILURE,
    UPDATE_ADDRESS_SETTINGS_REQUEST,
    UPDATE_ADDRESS_SETTINGS_SUCCESS,
    UPDATE_NOTIFICATIONS_SETTINGS_FAILURE,
    UPDATE_NOTIFICATIONS_SETTINGS_REQUEST,
    UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS,
    UPDATE_PERSONAL_SETTINGS_FAILURE,
    UPDATE_PERSONAL_SETTINGS_REQUEST,
    UPDATE_PERSONAL_SETTINGS_SUCCESS,
    CONFIRM_EMAIL_REQUEST,
    CONFIRM_EMAIL_SUCCESS,
    CONFIRM_EMAIL_FAILURE,
} from '../mutationTypes';
import Storage from '../../Storage';
import Role from '../../Role';
import toastService from '../../services/toastService';

const initialState = {
    initialized: false,
    exp: null,
    user: null,
    isAdmin: window.localStorage.getItem(Storage.IS_ADMIN) === 'true' || false,
    loading: {
        authenticate: false,
        authenticateAs: false,
        refreshToken: false,
        fetchUserLight: false,
        fetchUserFull: false,
        fetchUserProfile: false,
        updatePersonalSettings: false,
        updateNotificationsSettings: false,
        updateAddressSettings: false,
        changePassword: false,
        resetPassword: false,
        resetPasswordComplete: false,
        acceptTerms: false,
    },
};

const getters = {
    getUser: state => state.user,
    isSessionInitialized: state => state.initialized,
    isAuthenticated: state => !!state.user,
    isAdmin: state => state.isAdmin,
    isRoleProducer: state => state.user?.roleName === Role.PRODUCER,
    isRoleWidgeter: state => state.user?.roleName === Role.WIDGETER,
    isRoleAdvertiser: state => state.user?.roleName === Role.ADVERTISER,
    isRoleSuperAdmin: state => state.user?.roleName === Role.SUPERADMIN,
    isRoleAdmin: state => isAdminRole(state.user?.roleName),
    hasTermsAccepted: state => !!(state.user && state.user.policies),
    userId: state => state.user && state.user.id,
    getRoleName: state => state.user?.roleName,
    getRoleShortName: state => state.user?.roleShortName,
    getRoleNameByShortName: state => roleShortName => Role[String(roleShortName).toUpperCase()],
    // eslint-disable-next-line no-shadow
    apiUserType: (state, getters) => {
        if (getters.isRoleWidgeter) return 'publisher';
        if (getters.isRoleAdvertiser) return 'advertiser';
        return 'publisher';
    },
    isFeatureFlagEnabled: state => featureName => checkFeatureFlag(featureName, state.user?.roleName, state.user?.id),
};

const actions = {
    async loginAs({ dispatch }, user) {
        const { token } = await dispatch('user/fetchUserToken', { urlParams: { id: user.id } }, { root: true });

        if (user.role === Role.WIDGETER) window.open(`${process.env.VUE_APP_WIDGETER_URL}/login-as/${token}`, '_blank');
        if (user.role === Role.ADVERTISER) window.open(`${process.env.VUE_APP_ADVERTISER_URL}/login-as/${token}`, '_blank');
        if (user.role === Role.PRODUCER) {
            if (
                process.env.VUE_APP_GLOBAL_PRODUCER_URL &&
                process.env.VUE_APP_GLOBAL_PRODUCERS &&
                process.env.VUE_APP_GLOBAL_PRODUCERS.trim()
                    .split(',')
                    .map(id => Number.parseInt(id.trim(), 10))
                    .includes(user.id)
            ) {
                window.open(`${process.env.VUE_APP_GLOBAL_PRODUCER_URL}/login-as/${token}`, '_blank');
            } else {
                window.open(`${process.env.VUE_APP_PRODUCER_URL}/login-as/${token}`, '_blank');
            }
        }
    },

    async validateSession({ commit }) {
        try {
            const tokenData = await authService.validateToken(window.localStorage.getItem(Storage.ACCESS_TOKEN));

            if (tokenData) {
                const { exp, id, username, roles } = tokenData;

                const userLightResponse = await authService.fetchUserLight();
                const newLocale = getLocaleFromLanguage(userLightResponse.language);
                await loadLanguageAsync(newLocale);

                commit(AUTHENTICATE_SUCCESS, {
                    exp,
                    id,
                    username,
                    roles,
                });
                commit(FETCH_USER_LIGHT_SUCCESS, userLightResponse);
            } else {
                commit(LOGOUT);
            }
        } catch (e) {
            commit(LOGOUT);
        }

        commit(INITIALIZE_AUTH);
    },

    async authenticate({ commit, dispatch }, payload) {
        try {
            commit(AUTHENTICATE_REQUEST);
            const { username, password, socialAuth } = payload;
            let authResponse;
            if (socialAuth) {
                authResponse = await socialAuthService.authenticate(socialAuth);
                if (authResponse === null) return;
            } else {
                authResponse = await authService.authenticate(username, password);
            }
            const tokenData = await authService.parseToken(authResponse.token);

            if (!tokenData) {
                authService.logout();
                throw new Error(i18n.t('error.default'));
            }

            const { exp, id, username: tokenUsername, roles } = tokenData;

            if (mode === 'ADMIN' && !roles.includes(Role.ADMIN) && !roles.includes(Role.SUPERADMIN)) {
                dispatch('logout');
                toastService.infoToast(i18n.t('auth.onlyAdminLoginInfo'));
                return;
            }
            if (mode === 'WIDGETER' && !roles.includes(Role.WIDGETER)) {
                dispatch('logout');
                toastService.infoToast(i18n.t('auth.onlyWidgeterLoginInfo'));
                return;
            }
            if (mode === 'ADVERTISER' && !roles.includes(Role.ADVERTISER)) {
                dispatch('logout');
                toastService.infoToast(i18n.t('auth.onlyAdvertiserLoginInfo'));
                return;
            }

            const userLightResponse = await authService.fetchUserLight();
            const newLocale = getLocaleFromLanguage(userLightResponse.language);
            await loadLanguageAsync(newLocale);

            dispatch('cleanStore');

            commit(AUTHENTICATE_SUCCESS, {
                exp,
                id,
                username: tokenUsername,
                roles,
            });
            commit(FETCH_USER_LIGHT_SUCCESS, userLightResponse);

            authService.scheduleRefreshToken(exp);
        } catch (e) {
            commit(AUTHENTICATE_FAILURE);
            throw e;
        }
    },

    async register({ commit, dispatch }, payload) {
        try {
            commit(REGISTER_REQUEST);
            const { type, firstName, email, password, utmSource, notifications = {}, recommenderId } = payload;
            const responseData = await authService.register(type, firstName, email, password, utmSource, notifications, recommenderId);
            commit(REGISTER_SUCCESS, responseData);
            return responseData;
        } catch (e) {
            commit(REGISTER_FAILURE);
            throw e;
        }
    },

    async authenticateAs({ commit, dispatch }, payload) {
        try {
            commit(AUTHENTICATE_AS_REQUEST);
            const { token, isAdmin } = payload;
            const authResponse = await authService.authenticateAs(token, isAdmin);
            const tokenData = await authService.parseToken(authResponse.token);

            if (!tokenData) {
                authService.logout();
                throw new Error('error.default');
            }

            const { exp, id, username: tokenUsername, roles } = tokenData;

            const userLightResponse = await authService.fetchUserLight();
            const newLocale = getLocaleFromLanguage(userLightResponse.language);
            await loadLanguageAsync(newLocale);

            dispatch('cleanStore');

            commit(AUTHENTICATE_SUCCESS, {
                exp,
                id,
                username: tokenUsername,
                roles,
            });
            commit(AUTHENTICATE_AS_SUCCESS, { isAdmin });
            commit(FETCH_USER_LIGHT_SUCCESS, userLightResponse);

            authService.scheduleRefreshToken(exp);

            router.push({ path: '/' }).catch(() => {});
        } catch (e) {
            commit(AUTHENTICATE_AS_FAILURE);
            throw e;
        }
    },

    async refreshToken({ commit }) {
        try {
            if (window.localStorage.getItem(Storage.REFRESH_TOKEN)) {
                commit(REFRESH_TOKEN_REQUEST);
                const authResponse = await authService.refreshToken();
                const tokenData = await authService.parseToken(authResponse.token);

                if (!tokenData) {
                    authService.logout();
                    throw new Error('error.default');
                }

                const { exp } = tokenData;

                commit(REFRESH_TOKEN_SUCCESS, {
                    exp,
                });

                authService.scheduleRefreshToken(exp);
                return tokenData;
            }
        } catch (e) {
            commit(REFRESH_TOKEN_FAILURE);
            throw e;
        }

        return null;
    },

    async logout({ commit, dispatch }, payload = {}) {
        const { redirectUrl, redirectName, noRedirect } = payload;
        authService.logout();
        commit(LOGOUT);
        dispatch('cleanStore');
        if (redirectUrl) {
            window.location.href = redirectUrl;
        } else if (redirectName) {
            router.push({ name: redirectName }).catch(() => {});
        } else if (noRedirect !== true) {
            router.push({ name: 'Login' }).catch(() => {});
        }
        apiService.abortApiRequests();
        dispatch('clearStoreState');
        await dispatch('validateSession');
    },

    clearStoreState({ commit }) {
        commit('clearStoreState', null, { root: true });
        this.commit('linkGenerator/clearLinks');
    },

    cleanStore() {
        // dispatch('module/clean', null, { root: true });
    },

    // eslint-disable-next-line no-shadow
    async fetchUserFull({ commit, state, getters }) {
        try {
            commit(FETCH_USER_FULL_REQUEST);
            const userId = state.user.id;
            const [userAccountResponse, userNotificationSettingsResponse, userProfileResponse] = await Promise.all([
                authService.fetchUserAccount(userId),
                getters.isRoleAdvertiser ? Promise.resolve({ isNotification: false }) : authService.fetchUserNotificationSettings(userId),
                authService.fetchUserProfile(userId),
            ]);

            if (getters.isRoleAdvertiser) userNotificationSettingsResponse.isNotification = userAccountResponse.isNotification;

            commit(FETCH_USER_FULL_SUCCESS, {
                userAccount: userAccountResponse,
                userNotifications: userNotificationSettingsResponse,
                userProfile: userProfileResponse,
            });
        } catch (e) {
            commit(FETCH_USER_FULL_FAILURE);
            throw e;
        }
    },

    async fetchUserProfile({ commit, state }) {
        try {
            commit(FETCH_USER_PROFILE_REQUEST);
            const userId = state.user.id;
            const userProfileResponse = await authService.fetchUserProfile(userId);
            commit(FETCH_USER_PROFILE_SUCCESS, { userProfile: userProfileResponse });
            return userProfileResponse;
        } catch (e) {
            commit(FETCH_USER_PROFILE_FAILURE);
            throw e;
        }
    },

    async updatePersonalSettings({ commit, state }, payload) {
        try {
            commit(UPDATE_PERSONAL_SETTINGS_REQUEST);
            const { firstName, lastName, email, phone, language } = payload;
            const userId = state.user.id;
            await authService.updatePersonalSettings(userId, {
                email,
                profile: {
                    language,
                    firstName,
                    lastName,
                    phone,
                },
            });

            const newLocale = getLocaleFromLanguage(language);
            await loadLanguageAsync(newLocale);
            const hasLanguageChanged = state.user.language !== language;

            commit(UPDATE_PERSONAL_SETTINGS_SUCCESS, {
                email,
                language,
                firstName,
                lastName,
                phone,
            });
            toastService.successToast(i18n.t('accountSettingsPersonal.saveSuccess'));

            // reload page if language has changed
            // it's required because google charts can be loaded only in a single version/language
            if (hasLanguageChanged) {
                window.setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }
        } catch (e) {
            commit(UPDATE_PERSONAL_SETTINGS_FAILURE);
            throw e;
        }
    },

    async updateAddressSettings({ commit, state }, payload) {
        try {
            commit(UPDATE_ADDRESS_SETTINGS_REQUEST);
            const { isCompany, companyName, companyNip, street, streetNumber, apartmentNumber, city, postcode, country, bankAccount } = payload;
            const userId = state.user.id;
            const updatedAddress = await authService.updateAddressSettings(userId, {
                isCompany,
                companyName,
                companyNip,
                street,
                streetNumber,
                apartmentNumber,
                city,
                postcode,
                country,
                bankAccount,
            });

            commit(UPDATE_ADDRESS_SETTINGS_SUCCESS, {
                isCompany: updatedAddress.isCompany,
                companyName: updatedAddress.companyName,
                companyNip: updatedAddress.companyNip,
                street: updatedAddress.street,
                streetNumber: updatedAddress.streetNumber,
                apartmentNumber: updatedAddress.apartmentNumber,
                city: updatedAddress.city,
                postcode: updatedAddress.postcode,
                country: updatedAddress.country,
                bankAccount: updatedAddress.bankAccount,
            });
            toastService.successToast(i18n.t('accountSettingsAddress.saveSuccess'));
        } catch (e) {
            commit(UPDATE_ADDRESS_SETTINGS_FAILURE);
            throw e;
        }
    },

    async updateNotificationsSettings({ commit, state }, payload) {
        try {
            commit(UPDATE_NOTIFICATIONS_SETTINGS_REQUEST);
            const { notifications } = payload;
            const userId = state.user.id;

            await authService.updatePersonalSettings(userId, {
                ...notifications,
            });
            commit(UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS, {
                notifications,
            });
            toastService.successToast(i18n.t('accountSettingsNotifications.saveSuccess'));
        } catch (e) {
            commit(UPDATE_NOTIFICATIONS_SETTINGS_FAILURE);
            throw e;
        }
    },

    async changePassword({ commit }, payload) {
        try {
            commit(CHANGE_PASSWORD_REQUEST);
            const { oldPassword, newPassword, confirmPassword } = payload;
            await authService.changePassword({
                oldPassword,
                newPassword: {
                    first: newPassword,
                    second: confirmPassword,
                },
            });

            commit(CHANGE_PASSWORD_SUCCESS);
            toastService.successToast(i18n.t('accountSettingsPassword.saveSuccess'));
        } catch (e) {
            commit(CHANGE_PASSWORD_FAILURE);
            throw e;
        }
    },

    async resetPassword({ commit }, payload) {
        try {
            commit(RESET_PASSWORD_REQUEST);
            const { email } = payload;
            await authService.resetPassword({ email });
            commit(RESET_PASSWORD_SUCCESS);
        } catch (e) {
            commit(RESET_PASSWORD_FAILURE);
            throw e;
        }
    },

    async resetPasswordComplete({ commit }, payload) {
        try {
            commit(RESET_PASSWORD_COMPLETE_REQUEST);
            const { token, password } = payload;
            await authService.resetPasswordComplete({ token, password });
            commit(RESET_PASSWORD_COMPLETE_SUCCESS);
            router.push({ name: 'Login' });
        } catch (e) {
            commit(RESET_PASSWORD_COMPLETE_FAILURE);
            throw e;
        }
    },

    async acceptTerms({ commit, state }, payload) {
        try {
            commit(ACCEPT_TERMS_REQUEST);
            const { redirect } = payload;
            await authService.acceptTerms();
            commit(ACCEPT_TERMS_SUCCESS);
            if (redirect) {
                router.push({ name: redirect }).catch(() => {});
            } else {
                router.push({ path: '/' }).catch(() => {});
            }
        } catch (e) {
            commit(ACCEPT_TERMS_FAILURE);
            throw e;
        }
    },

    async confirmEmail({ commit, state }, payload) {
        try {
            commit(CONFIRM_EMAIL_REQUEST);
            const { token } = payload;
            await authService.confirmEmail(token);
            commit(CONFIRM_EMAIL_SUCCESS);
            toastService.infoToast(i18n.t('confirmEmail.success'), { color: 'success', timeout: 5000 });
            if (state.user) {
                const userLightResponse = await authService.fetchUserLight();
                authService.checkIfUserAllowed(userLightResponse);
                commit(FETCH_USER_LIGHT_SUCCESS, userLightResponse);
            }
            router.push({ path: '/' });
        } catch (e) {
            commit(CONFIRM_EMAIL_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // INITIALIZE
    // -----------------------------------------
    [INITIALIZE_AUTH](state) {
        state.initialized = true;
    },

    // -----------------------------------------
    // AUTHENTICATE
    // -----------------------------------------
    [AUTHENTICATE_REQUEST](state) {
        state.loading.authenticate = true;
        state.exp = null;
        state.user = null;
        state.isAdmin = false;
    },
    [AUTHENTICATE_SUCCESS](state, payload) {
        const { exp, id, username, roles } = payload;
        state.loading.authenticate = false;
        state.exp = exp;
        state.user = {
            id,
            username,
            roles,
        };
    },
    [AUTHENTICATE_FAILURE](state) {
        state.loading.authenticate = false;
        state.exp = null;
        state.user = null;
        state.isAdmin = false;
    },

    // -----------------------------------------
    // REGISTER
    // -----------------------------------------
    [REGISTER_REQUEST](state) {
        state.loading.register = true;
        state.exp = null;
        state.user = null;
        state.isAdmin = false;
    },
    [REGISTER_SUCCESS](state, payload) {},
    [REGISTER_FAILURE](state) {
        state.loading.register = false;
        state.exp = null;
        state.user = null;
        state.isAdmin = false;
    },

    // -----------------------------------------
    // AUTHENTICATE_AS
    // -----------------------------------------
    [AUTHENTICATE_AS_REQUEST](state) {
        state.loading.authenticateAs = true;
        state.isAdmin = false;
    },
    [AUTHENTICATE_AS_SUCCESS](state, payload) {
        const { isAdmin } = payload;
        state.loading.authenticateAs = false;
        state.isAdmin = !!isAdmin;
    },
    [AUTHENTICATE_AS_FAILURE](state) {
        state.loading.authenticateAs = false;
        state.isAdmin = false;
    },

    // -----------------------------------------
    // REFRESH_TOKEN
    // -----------------------------------------
    [REFRESH_TOKEN_REQUEST](state) {
        state.loading.refreshToken = true;
    },
    [REFRESH_TOKEN_SUCCESS](state, payload) {
        const { exp } = payload;
        state.loading.refreshToken = false;
        state.exp = exp;
    },
    [REFRESH_TOKEN_FAILURE](state) {
        state.loading.refreshToken = false;
    },

    // -----------------------------------------
    // LOGOUT
    // -----------------------------------------
    [LOGOUT](state) {
        state.loading.authenticate = false;
        state.loading.authenticateAs = false;
        state.loading.refreshToken = false;
        state.loading.fetchUserLight = false;
        state.loading.fetchUserFull = false;
        state.loading.fetchUserProfile = false;
        state.loading.updatePersonalSettings = false;
        state.loading.updateNotificationsSettings = false;
        state.loading.updateAddressSettings = false;
        state.loading.changePassword = false;
        state.loading.resetPassword = false;
        state.loading.resetPasswordComplete = false;
        state.loading.acceptTerms = false;
        state.exp = null;
        state.user = null;
        state.isAdmin = false;
    },

    // -----------------------------------------
    // FETCH_USER_LIGHT
    // -----------------------------------------
    [FETCH_USER_LIGHT_REQUEST](state) {
        state.loading.fetchUserLight = true;
    },
    [FETCH_USER_LIGHT_SUCCESS](state, payload) {
        const { id, username, fullName, email, language, policies, firstName, lastName, role } = payload;
        state.loading.fetchUserLight = false;
        state.user = {
            ...state.user,
            id,
            username,
            fullName,
            email,
            language,
            policies,
            firstName,
            lastName,
            roleName: role,
            roleShortName: role.toLowerCase().replace('role_', ''),
        };
    },
    [FETCH_USER_LIGHT_FAILURE](state) {
        state.loading.fetchUserLight = false;
    },

    // -----------------------------------------
    // FETCH_USER_FULL
    // -----------------------------------------
    [FETCH_USER_FULL_REQUEST](state) {
        state.loading.fetchUserFull = true;
    },
    [FETCH_USER_FULL_SUCCESS](state, payload) {
        const { userAccount, userNotifications, userProfile } = payload;
        state.loading.fetchUserFull = false;
        state.user = {
            ...state.user,
            demo: userAccount.demo,
            email: userAccount.email,
            notifications: userNotifications,
            language: userProfile.language,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            fullName: `${userProfile.firstName} ${userProfile.lastName}`,
            phone: userProfile.phone,
            country: userProfile.country,
            city: userProfile.city,
            postcode: userProfile.postcode,
            street: userProfile.street,
            streetNumber: userProfile.streetNumber,
            apartmentNumber: userProfile.apartmentNumber,
            isCompany: userProfile.isCompany,
            companyName: userProfile.companyName,
            companyNip: userProfile.companyNip,
            bankAccount: userProfile.bankAccount,
        };
    },
    [FETCH_USER_FULL_FAILURE](state) {
        state.loading.fetchUserFull = false;
    },

    // -----------------------------------------
    // FETCH_USER_PROFILE_REQUEST
    // -----------------------------------------
    [FETCH_USER_PROFILE_REQUEST](state) {
        state.loading.fetchUserProfile = true;
    },
    [FETCH_USER_PROFILE_SUCCESS](state, payload) {
        const { userProfile } = payload;
        state.loading.fetchUserProfile = false;
        state.user = {
            ...state.user,
            language: userProfile.language,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            fullName: `${userProfile.firstName} ${userProfile.lastName}`,
            phone: userProfile.phone,
            country: userProfile.country,
            city: userProfile.city,
            postcode: userProfile.postcode,
            street: userProfile.street,
            streetNumber: userProfile.streetNumber,
            apartmentNumber: userProfile.apartmentNumber,
            isCompany: userProfile.isCompany,
            companyName: userProfile.companyName,
            companyNip: userProfile.companyNip,
            bankAccount: userProfile.bankAccount,
        };
    },
    [FETCH_USER_PROFILE_FAILURE](state) {
        state.loading.fetchUserProfile = false;
    },

    // -----------------------------------------
    // UPDATE_PERSONAL_SETTINGS
    // -----------------------------------------
    [UPDATE_PERSONAL_SETTINGS_REQUEST](state) {
        state.loading.updatePersonalSettings = true;
    },
    [UPDATE_PERSONAL_SETTINGS_SUCCESS](state, payload) {
        const { firstName, lastName, email, phone, language, demo } = payload;
        state.loading.updatePersonalSettings = false;
        state.user = {
            ...state.user,
            firstName,
            lastName,
            fullName: `${firstName} ${lastName}`,
            email,
            phone,
            language,
            demo,
        };
    },
    [UPDATE_PERSONAL_SETTINGS_FAILURE](state) {
        state.loading.updatePersonalSettings = false;
    },

    // -----------------------------------------
    // UPDATE_COMPANY_SETTINGS
    // -----------------------------------------
    [UPDATE_ADDRESS_SETTINGS_REQUEST](state) {
        state.loading.updateAddressSettings = true;
    },
    [UPDATE_ADDRESS_SETTINGS_SUCCESS](state, payload) {
        const { isCompany, companyName, companyNip, street, streetNumber, apartmentNumber, city, postcode, country, bankAccount } = payload;
        state.loading.updateAddressSettings = false;
        state.user = {
            ...state.user,
            isCompany,
            companyName,
            companyNip,
            street,
            streetNumber,
            apartmentNumber,
            city,
            postcode,
            country,
            bankAccount,
        };
    },
    [UPDATE_ADDRESS_SETTINGS_FAILURE](state) {
        state.loading.updateAddressSettings = false;
    },

    // -----------------------------------------
    // UPDATE_NOTIFICATIONS_SETTINGS
    // -----------------------------------------
    [UPDATE_NOTIFICATIONS_SETTINGS_REQUEST](state) {
        state.loading.updateNotificationsSettings = true;
    },
    [UPDATE_NOTIFICATIONS_SETTINGS_SUCCESS](state, payload) {
        const { notifications } = payload;
        state.loading.updateNotificationsSettings = false;
        state.user = {
            ...state.user,
            notifications: {
                ...notifications,
            },
        };
    },
    [UPDATE_NOTIFICATIONS_SETTINGS_FAILURE](state) {
        state.loading.updateNotificationsSettings = false;
    },

    // -----------------------------------------
    // CHANGE_PASSWORD
    // -----------------------------------------
    [CHANGE_PASSWORD_REQUEST](state) {
        state.loading.changePassword = true;
    },
    [CHANGE_PASSWORD_SUCCESS](state) {
        state.loading.changePassword = false;
    },
    [CHANGE_PASSWORD_FAILURE](state) {
        state.loading.changePassword = false;
    },

    // -----------------------------------------
    // ACCEPT_TERMS
    // -----------------------------------------
    [ACCEPT_TERMS_REQUEST](state) {
        state.loading.acceptTerms = true;
    },
    [ACCEPT_TERMS_SUCCESS](state) {
        state.loading.acceptTerms = false;
        state.user.policies = true;
    },
    [ACCEPT_TERMS_FAILURE](state) {
        state.loading.acceptTerms = false;
    },

    // -----------------------------------------
    // RESET_PASSWORD
    // -----------------------------------------
    [RESET_PASSWORD_REQUEST](state) {
        state.loading.resetPassword = true;
    },
    [RESET_PASSWORD_SUCCESS](state) {
        state.loading.resetPassword = false;
    },
    [RESET_PASSWORD_FAILURE](state) {
        state.loading.resetPassword = false;
    },

    // -----------------------------------------
    // RESET_PASSWORD_COMPLETE
    // -----------------------------------------
    [RESET_PASSWORD_COMPLETE_REQUEST](state) {
        state.loading.resetPasswordComplete = true;
    },
    [RESET_PASSWORD_COMPLETE_SUCCESS](state) {
        state.loading.resetPasswordComplete = false;
    },
    [RESET_PASSWORD_COMPLETE_FAILURE](state) {
        state.loading.resetPasswordComplete = false;
    },

    // -----------------------------------------
    // CONFIRM_EMAIL
    // -----------------------------------------
    [CONFIRM_EMAIL_REQUEST](state) {
        state.loading.confirmEmail = true;
    },
    [CONFIRM_EMAIL_SUCCESS](state) {
        state.loading.confirmEmail = false;
    },
    [CONFIRM_EMAIL_FAILURE](state) {
        state.loading.confirmEmail = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
