import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    DESCRIBED_PRODUCTS: {
        URI: () => '/api/v2/described-products',
        methods: ['get'],
        default: [],
    },
    PRODUCTS: {
        URI: () => '/api/v1/products/{{id?}}',
        methods: ['post', 'get'],
    },
    PRODUCT_OFFERS: {
        URI: () => '/api/v1/products/{{id}}/offers',
        methods: ['get'],
        default: {},
    },
    PRODUCT_DISTINCT_OFFERS: {
        URI: () => '/api/v1/products/{{id}}/distinct-offers',
        methods: ['get'],
    },
    PRODUCT_RE_AGGREGATE: {
        URI: () => '/api/v1/products/{{id}}/re-aggregate',
        methods: ['put'],
    },
    AGGREGATION_STATUSES: {
        URI: () => '/api/v1/aggregation-statuses',
        methods: ['get'],
        formatter: data => data?.items,
    },
    AGGREGATIONS: {
        URI: () => '/api/v1/aggregations',
        methods: ['get'],
        default: [],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
