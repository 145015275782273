import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    ALLEGRO: {
        URI: () => '/api/v1/transaction-file-process-tasks/allegro',
        methods: ['post'],
        default: {},
        cacheToClear: ['/api/v1/transaction-file-process-tasks'],
    },
    IMPORT: {
        URI: () => '/api/v1/transaction-file-process-tasks/import',
        methods: ['post'],
        default: {},
        cacheToClear: ['/api/v1/transaction-file-process-tasks'],
    },
    TRADEDOUBLER: {
        URI: () => '/api/v1/transaction-file-process-tasks/tradedoubler',
        methods: ['post'],
        default: {},
        cacheToClear: ['/api/v1/transaction-file-process-tasks'],
    },
    VALIDATION: {
        URI: () => '/api/v1/transaction-file-process-tasks/validation',
        methods: ['post'],
        default: {},
        cacheToClear: ['/api/v1/transaction-file-process-tasks'],
    },
    TASKS: {
        URI: () => '/api/v1/transaction-file-process-tasks/{{id?}}',
        methods: ['get', 'delete'],
        default: {},
        cacheToClear: ['/api/v1/transaction-file-process-tasks'],
    },
    DOWNLOAD_REPORT: {
        URI: () => '/api/v1/transaction-file-process-tasks/{{id}}/download-report',
        methods: ['get'],
        default: {},
    },
    TASK_TYPES: {
        URI: () => '/api/v1/transaction-file-process-task-types',
        methods: ['get'],
        default: [],
        formatter: data => data?.items,
    },
    TASK_STATUSES: {
        URI: () => '/api/v1/transaction-file-process-task-statuses',
        methods: ['get'],
        default: [],
        formatter: data => data?.items,
    },
    CORRECTION: {
        URI: () => '/api/v1/transaction-file-process-tasks/correction',
        methods: ['post'],
        default: {},
        cacheToClear: ['/api/v1/transaction-file-process-tasks'],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
