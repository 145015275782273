<template>
    <DataTable
        :header="$t('paymentsDonations')"
        :error="isRedirecting ? null : error"
        :items="items"
        :fields="fields"
        :total-rows="tips.max"
        :per-page.sync="parentComponent.perPage"
        :sort-by.sync="parentComponent.sortBy"
        :sort-direction.sync="parentComponent.sortDirection"
        :current-page.sync="parentComponent.currentPage"
        :loading="loading || isSpacesLoading"
        no-sort-reset
        no-local-sorting
    >

        <template #cell(createdAt)="data">
            {{ $format.dateAndHour(data.item.createdAt, 'dd.MM.yyyy') }}
        </template>

        <template #cell(amount)="data">
            {{ $format.currency(data.item.amount) }}
        </template>

        <template #cell(status)="data">
            <TipsStatusBadge :status="data.item.status" :label="getStatusLabel(data.item.status)" :simple-label="true" />
        </template>

    </DataTable>
</template>



<script>
    import Role from '@/Role';
    import DataTable from '@/components/common/DataTable.vue';
    import TipsStatusBadge from '@/components/common/TipsStatusBadge.vue';

    export default {
        name: 'Results',
        components: {
            DataTable,
            TipsStatusBadge,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                isRedirecting: false,
                fields: [
                    {
                        key: 'createdAt',
                        label: this.$t('date'),
                    },
                    {
                        key: 'space.name',
                        label: this.$t('space'),
                    },
                    {
                        key: 'backerName',
                        label: this.$t('firstNameOfTheDonor'),
                    },
                    {
                        key: 'backerSurname',
                        label: this.$t('lastNameOfTheDonor'),
                    },
                    {
                        key: 'backerEmail',
                        label: this.$t('emailOfTheDonor'),
                    },
                    {
                        key: 'amount',
                        label: this.$t('depositAmount'),
                    },
                    {
                        key: 'status',
                        label: this.$t('paymentStatus'),
                    },
                ],
            };
        },
        computed: {
            isSpacesLoading() {
                return this.parentComponent.isSpacesLoading && this.userRole === Role.WIDGETER;
            },
            userRole() {
                return this.$store.getters['auth/getRoleName'];
            },
            tips() {
                return this.$store.getters['tipTransactions/getTransactions'] || {};
            },
            items() {
                return this.tips.items || [];
            },
            error() {
                return this.$store.getters['tipTransactions/getError']('transactions');
            },
            loading() {
                return this.$store.getters['tipTransactions/getLoading']('transactions');
            },
            statuses() {
                return this.$store.getters['tipTransactions/getStatuses'] || {};
            },
        },
        watch: {
            error() {
                const error = this.$store.getters['tipTransactions/getError']('transactions');
                if (error && error.message === 'api_errors.unprocessable_entity.policies_not_accepted') {
                    this.isRedirecting = true;
                    this.$router.push({ name: 'Tipbox' });
                } else {
                    this.isRedirecting = false;
                }
            },
        },
        methods: {
            fetchStatuses() {
                this.$store.dispatch('tipTransactions/fetchStatuses').catch(() => {});
            },
            getStatusLabel(status) {
                return this.statuses?.find(item => item.value === status)?.label || '';
            },
        },
        created() {
            this.fetchStatuses();
        },
    };
</script>
