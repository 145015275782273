<template>
    <div>
        <PageHeader :items="breadcrumb">
            <div class="d-flex gap-3 align-items-center">
                <div>{{ $t('productDetails') }}</div>
                <ButtonIcon variant="warning" :to="{ name: 'ProductsItemAudit', params: { id: id, parentUrl: $route.params.parentUrl } }">
                    {{ $t('audit') }}
                </ButtonIcon>
            </div>
        </PageHeader>
        <ErrorMessage :value="error" />
        <DataTable
            :header="`${$t('offers')} (${items.length})`"
            :error.sync="error"
            :items="items"
            :fields="fields"
            :loading="loading"
            :pagination="false"
        >
            <template #cell(id)="{item}">
                <b-link class="title" :to="getUrlOffer(item)">
                    {{ item.id }}
                </b-link>
            </template>
            <template #cell(name)="{item}">
                <div class="d-flex">
                    <ImageLoader
                        :src="item.image"
                        alt=""
                        size="100"
                        image-height="auto"
                        class="mr-2 flex-shrink-0"
                    />
                    <div class="d-flex flex-column gap-2">
                        <b-link class="title" :href="item.url" target="_blank">
                            {{ item.name }}
                        </b-link>
                    </div>
                </div>
            </template>
            <template #cell(price)="{value}">
                <span class="nowrap">{{ $format.currency(value) }}</span>
            </template>
            <template #cell(active)="{value}">
                <BadgeBoolean :value="value" :true-value-text="$t('active')" :false-value-text="$t('inactive')" />
            </template>
        </DataTable>
    </div>
</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';
    import ImageLoader from '@/components/common/ImageLoader.vue';
    import BadgeBoolean from '@/components/common/BadgeBoolean.vue';

    export default {
        name: 'ProductsItemShow',
        components: {
            DataTable,
            ImageLoader,
            BadgeBoolean,
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.id),
            };
        },
        computed: {
            fields() {
                return [
                    {
                        key: 'id',
                        label: this.$t('offerId'),
                    },
                    {
                        key: 'name',
                        label: this.$t('name'),
                    },
                    {
                        key: 'ean',
                        label: this.$t('ean'),
                    },
                    {
                        key: 'info',
                        label: this.$t('info'),
                    },
                    {
                        key: 'feed',
                        label: this.$t('feed'),
                    },
                    {
                        key: 'price',
                        label: this.$t('price'),
                    },
                    {
                        key: 'active',
                        label: this.$t('status'),
                    },
                ];
            },
            apiParams() {
                return { urlParams: { id: this.id } };
            },
            offers() {
                return this.$store.getters['product/getProductOffers'];
            },
            items() {
                return this.offers.items || [];
            },
            error() {
                return this.$store.getters['product/getError']('productOffers');
            },
            loading() {
                return this.$store.getters['product/getLoading']('productOffers');
            },
            breadcrumb() {
                return [
                    { text: this.$t(this.parentRoute.meta.title), to: this.$route.params.parentUrl || this.parentRoute },
                    { text: `${this.id}` || '---', active: true },
                ];
            },
            parentRoute() {
                return this.$route.matched.slice(-2)[0];
            },
        },
        watch: {
            apiParams: {
                immediate: true,
                handler() {
                    this.clearStoreData();
                    this.fetchData();
                },
            },
        },
        methods: {
            fetchData() {
                this.$store.dispatch('product/fetchProductOffers', this.apiParams);
            },
            clearStoreData() {
                this.$store.commit('product/clearProductOffers');
            },
            getUrlOffer(item) {
                return {
                    name: 'OffersDetails',
                    params: { id: item.id },
                    query: {},
                };
            },
        },
        destroyed() {
            this.clearStoreData();
        },
        mounted() {
            window.scrollTo(0, 0);
        },
    };
</script>



<style lang="scss" scoped></style>
