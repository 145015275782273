import offersService, { ENDPOINTS } from '@/services/offersService';
import moduleFactory from '@/store/modules/moduleFactory';

const moduleStore = moduleFactory.build(ENDPOINTS, offersService);

export default {
    namespaced: true,
    state: {
        ...moduleStore.state,
    },
    getters: {
        ...moduleStore.getters,
    },
    mutations: {
        ...moduleStore.mutations,
    },
    actions: {
        ...moduleStore.actions,
    },
};
