import { i18n } from '../i18n/i18n';

export default {
    /**
     * Format value by type
     * @param {'decimal'|'percent'|'currency'} type - Value to format
     * @param {number} value - Value to format
     * @param {string} [locale] - Locale. Has fallback to system local and user locale.
     * @return {string}
     */
    format(type, value, locale) {
        if (type === 'percent') {
            return this.formatPercent(value, undefined, locale);
        }
        if (type === 'currency') {
            return this.formatCurrency(value, undefined, locale);
        }
        return this.formatNumber(value, undefined, locale);
    },
    /**
     * Format number
     * @param {number} value - Value to format
     * @param {number} [maximumFractionDigits=2] - Maximum number of fraction digits.
     * @param {string} [locale] - Locale. Has fallback to system local and user locale.
     * @param {boolean} [stripSpaces] - Value will be returned without any spaces
     * @return {string}
     */
    formatNumber(value, maximumFractionDigits = 2, locale, stripSpaces = false) { // eslint-disable-line
        let parsedValue = value;

        if (Number.isNaN(Number.parseInt(value, 10))) {
            parsedValue = 0;
        }

        parsedValue = parsedValue.toLocaleString(locale || process.env.VUE_APP_SYSTEM_LOCALE || i18n.locale, {
            maximumFractionDigits,
        });

        if (stripSpaces) {
            parsedValue = parsedValue.replace(/\s/g, '');
        }

        return parsedValue;
    },

    /**
     * Format percent
     * @param {number} value - Value to format
     * @param {{maximumFractionDigits: number, minimumFractionDigits: number, locale: string}} Config object
     * @return {string}
     */
    formatPercent(value, { maximumFractionDigits = 2, minimumFractionDigits, locale } = {}) {
        let parsedValue = value;

        if (Number.isNaN(Number.parseInt(value, 10))) {
            parsedValue = 0;
        }

        // toLocaleString interprets 1 as 100%, so we need to divide value by 100
        parsedValue /= 100;

        return parsedValue.toLocaleString(locale || process.env.VUE_APP_SYSTEM_LOCALE || i18n.locale, {
            style: 'percent',
            maximumFractionDigits,
            minimumFractionDigits,
        });
    },

    /**
     * Format currency
     * @param {number} value - Value to format
     * @param {string} [currency] - Currency code. Has fallback to system currency.
     * @param {string} [locale] - Locale. Has fallback to system local and user locale.
     * @return {string|null}
     */
    formatCurrency(value, currency, locale) {
        let parsedValue = value;

        if (Number.isNaN(Number.parseInt(value, 10))) {
            parsedValue = 0;
        }

        const localeFormat = locale || process.env.VUE_APP_SYSTEM_LOCALE || i18n.locale;

        let formatted = parsedValue
            .toLocaleString(localeFormat, {
                style: 'currency',
                currency: currency || process.env.VUE_APP_SYSTEM_CURRENCY,
                currencyDisplay: process.env.VUE_APP_CURRENCY_DISPLAY_TYPE,
            })
            .replace(/\xa0/g, ' ');

        // Fix wrong format "####,##" to "# ###,##"
        if (localeFormat.toLowerCase() === 'pl' || localeFormat.toLowerCase() === 'pl-pl') {
            const val = formatted.split(',');
            if (val[0].length === 4) {
                const total = val[0].replace(/^(\d)([\d]{3})$/, '$1 $2');
                formatted = `${total},${val[1]}`;
            }
        }

        return formatted;
    },

    /**
     * Calculate change percentage
     * @param {number} newValue - New value
     * @param {number} oldValue - Old value
     * @return {number|null}
     */
    calculateChangePercentage(newValue, oldValue) {
        if (Number.isNaN(newValue)) {
            return null;
        }
        if (Number.isNaN(oldValue)) {
            return 100;
        }
        if (newValue === 0 && oldValue === 0) {
            return 0;
        }
        if (newValue === 0) {
            return -100;
        }
        if (oldValue === 0) {
            return 100;
        }
        return ((newValue - oldValue) / oldValue) * 100;
    },

    /**
     * Returns passed value as integer or zero if it's not a number.
     * @param {any} value
     * @param {boolean} returnAsString
     * @return {number|string}
     */
    zeroOrInteger(value, returnAsString = false) {
        const valueAsNumber = Number.parseInt(value, 10);
        const parsedValue = Number.isNaN(valueAsNumber) ? 0 : valueAsNumber;

        if (returnAsString) {
            return parsedValue.toString();
        }

        return parsedValue;
    },
};
