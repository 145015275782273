<template>
    <div>

        <PageHeader>
            {{ $t('transactionValidation') }}
        </PageHeader>

        <FormCsv request-endpoint="transactionFileProcessTask/validation" :button-label="$t('validate')" @imported="fetchTasks()" />

        <ListCsv ref="refListCsv" />

    </div>
</template>



<script>
    import FormCsv from '../parts/FormCsv.vue';
    import ListCsv from '../parts/ListCsv.vue';

    export default {
        name: 'TransactionsValidation',
        components: {
            FormCsv,
            ListCsv,
        },
        data() {
            return {};
        },
        methods: {
            fetchTasks() {
                this.$refs.refListCsv?.fetchTasks();
            },
        },
    };
</script>



<style lang="scss" scoped>
    ::v-deep {
        .form-item {
            .form-row {
                display: flex;

                > legend {
                    width: 200px;
                    flex: 0 1 auto;
                }

                > legend ~ div {
                    flex: 10 1 0;
                    min-width: min(100%, 400px);
                    max-width: 600px;
                    margin-right: auto;
                }
            }
        }
    }
</style>
