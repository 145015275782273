<template>
    <Card :header="$t('allegroImport')">
        <ErrorMessage :value="error" />
        <FormValidator
            :validator="$v.form.campaign"
            form-group
            v-slot="slotProps"
            :label="$t('campaign')"
            label-cols="12"
            class="form-item"
        >
            <Multiselect
                :value="form.campaign"
                :fetch-options-function="fetchCampaigns"
                :parse-options-function="parseCampaigns"
                :placeholder="$t('selectField.placeholder')"
                searchable
                track-by="id"
                label="name"
                select-label=""
                :disabled="busy"
                :state="slotProps.state"
                @inputTrackBy="form.campaign = $event"
                @loading="value => isCampaignsLoading = value"
            />
        </FormValidator>
        <FormValidator
            :validator="$v.form.commission"
            form-group
            v-slot="slotProps"
            :label="$t('commission')"
            label-cols="12"
            class="form-item"
        >
            <Multiselect
                :value="form.commission"
                :placeholder="$t('selectOption')"
                :options="form.campaign && commissions.items ? commissions.items : []"
                track-by="id"
                :custom-label="parseCommissionLabel"
                select-label=""
                :loading="isCommissionsLoading"
                :disabled="!form.campaign || isCommissionsLoading || isCampaignsLoading || busy"
                class="w-auto flex-grow-1"
                :state="slotProps.state"
                @input="value => form.commission = value.id"
            />
        </FormValidator>
        <FormValidator
            form-group
            :validator="$v.form.csv"
            v-slot="slotProps"
            :label="$t('file')"
            label-cols="12"
            class="form-item"
        >
            <FormFile
                v-model="form.csv"
                :disabled="busy"
                :state="slotProps.state"
                accept=".csv"
                :browse-text="$t('add')"
                :placeholder="$t('fileField.placeholder')"
            />
        </FormValidator>
        <ButtonIcon
            class="mr-auto width-min-150"
            variant="primary"
            :disabled="busy"
            :loading="busy"
            hide-label-while-loading
            @click="importAllegro()"
        >
            {{ $t('import') }}
        </ButtonIcon>

        <div class="w-100 mb-4"></div>

        <p class="mb-2">{{ $t('transactionImportProcedureLabel') }}</p>
        <code class="text-body font-size-13">
            Data wizyty;Data transakcji;ID transakcji;ID produktu;Liczba produktu;Cena produktu;Prowizja;abpid;abpar1;abpar2;abpar3
        </code>
    </Card>
</template>



<script>
    import deepFreeze from 'deep-freeze';
    import cloneDeep from 'lodash/cloneDeep';
    import { required } from 'vuelidate/lib/validators';
    import { serialize as objectToFormData } from 'object-to-formdata';
    import spaceService from '@/services/spaceService';
    import campaignsService from '@/services/campaignsService';
    import FormValidator from '@/components/common/FormValidator.vue';
    import FormFile from '@/components/common/FormFile.vue';
    import Multiselect from '@/components/common/Multiselect.vue';

    const FORM_DEFAULT_DATA = deepFreeze({
        campaign: null,
        commission: null,
        csv: null,
    });

    export default {
        name: 'FormAllegro',
        components: {
            FormValidator,
            FormFile,
            Multiselect,
        },
        data() {
            return {
                form: cloneDeep(FORM_DEFAULT_DATA),
                isCampaignsLoading: false,
                isCommissionsLoading: false,
                commissions: {},
            };
        },
        validations() {
            return {
                form: {
                    campaign: { required },
                    commission: { required },
                    csv: { required },
                },
            };
        },
        computed: {
            busy() {
                return this.$store.getters['transactionFileProcessTask/getBusy']('allegro');
            },
            error() {
                return this.$store.getters['transactionFileProcessTask/getError']('allegro');
            },
        },
        watch: {
            'form.campaign': {
                handler: 'fetchCommissions',
            },
        },
        methods: {
            importAllegro() {
                this.$v.form.$touch();
                if (this.$v.form.$invalid) return;
                this.$store.commit('transactionFileProcessTask/setError', { key: 'allegro', value: null });
                const params = { requestParams: objectToFormData(this.form), headers: { 'Content-Type': 'multipart/form-data' } };
                this.$store
                    .dispatch('transactionFileProcessTask/postAllegro', params)
                    .then(() => {
                        this.$toastr.success(this.$t('transactionAllegroImportSuccessfully'));
                        this.form = cloneDeep(FORM_DEFAULT_DATA);
                        this.$v.form.$reset();
                        this.$emit('imported');
                    })
                    .catch(() => {});
            },
            fetchCampaigns() {
                return spaceService.fetchCampaigns();
            },
            parseCampaigns(data) {
                return data?.items || [];
            },
            fetchCommissions() {
                this.$set(this.form, 'commission', null);
                this.commissions = {};
                if (this.form.campaign) {
                    this.isCommissionsLoading = true;
                    campaignsService
                        .getCampaignsCommissions({ urlParams: { id: this.form.campaign } })
                        .then(data => (this.commissions = data))
                        .catch(error => {})
                        .finally(() => (this.isCommissionsLoading = false));
                }
            },
            parseCommissionLabel(option) {
                let label = '';
                if (option.type === 'amount') {
                    label = `${this.$t('publisherCommission')}: ${option.publisherAmount}zł | ${this.$t('adminCommission')}: ${option.adminAmount}zł`;
                }
                if (option.type === 'percent') {
                    label = `${this.$t('publisherCommission')}: ${option.publisherPercent}% | ${this.$t('adminCommission')}: ${option.adminPercent}%`;
                }
                if (option.description) {
                    label = `${label} (${option.description})`;
                }
                return label;
            },
        },
    };
</script>
