/* eslint-disable no-await-in-loop, no-restricted-syntax, no-nested-ternary */

import Vue from 'vue';
import camelCase from 'camelcase';

// Jeśli Vite
// const serviceFiles = import.meta.glob('../services/*.js');

// Jeśli Webpack
const serviceFiles = require.context('../services', false, /\.js$/);

/**
 * Ładuje serwis dynamicznie na podstawie nazwy modułu.
 * @param {string} moduleName - Nazwa modułu.
 * @returns {Promise<object>} Serwis modułu.
 * @throws {Error} Jeśli nie znaleziono serwisu dla modułu.
 */
async function loadService(moduleName) {
    const keys = serviceFiles.keys();
    for (let i = 0; i < keys.length; i++) {
        const path = keys[i];
        if (path.includes(camelCase(moduleName))) {
            return (await serviceFiles(path)).default;
        }
    }
    throw new Error(`Service for module ${moduleName} not found.`);
}

const DEFAULT_ID = 'DEFAULT';
let CURRENT_ID = DEFAULT_ID;

/**
 * Klasa RequestBuilder umożliwia tworzenie i zarządzanie zapytaniami do serwisów.
 */
class RequestBuilder {
    /**
     * Tworzy instancję RequestBuilder.
     * @param {string} moduleAndEndpoint - Połączenie nazwy modułu i endpointu.
     */
    constructor(moduleAndEndpoint) {
        this.moduleAndEndpoint = moduleAndEndpoint;
        this.state = Vue.observable({});
    }

    /**
     * Inicjalizuje stan zapytania dla danego identyfikatora.
     * @param {string} id - Identyfikator zapytania.
     */
    initializeState(id) {
        if (!this.state[id]) {
            Vue.set(this.state, id, {
                loading: false,
                error: null,
                data: null,
            });
        }
    }

    /**
     * Ustawia identyfikator zapytania.
     * @param {string} id - Identyfikator zapytania.
     * @returns {RequestBuilder} Instancja RequestBuilder.
     */
    id(id) {
        CURRENT_ID = id || DEFAULT_ID;
        return this;
    }

    /**
     * Wykonuje zapytanie HTTP.
     * @param {string} method - Metoda HTTP.
     * @param {...any} payload - Parametry zapytania.
     * @returns {Promise<any>} Wynik zapytania.
     */
    async execute(method, ...payload) {
        const requestId = CURRENT_ID;
        this.initializeState(requestId);

        this.state[requestId].loading = true;
        this.state[requestId].error = null;

        const [moduleName, endpoint] = this.moduleAndEndpoint.split('/');

        try {
            const service = await loadService(moduleName);
            const methodName = camelCase(endpoint, { pascalCase: true });

            const serviceExecute = (...methods) => {
                for (const methodType of methods) {
                    const fullMethodName = `${methodType}${methodName}`;
                    if (typeof service[fullMethodName] === 'function') {
                        return service[fullMethodName];
                    }
                }
                throw new Error(`No suitable method found for ${methodName} in service.`);
            };

            let response;
            switch (method) {
                case 'get':
                    response = await serviceExecute('get', 'fetch')(...payload);
                    break;
                case 'put':
                    response = await serviceExecute('put')(...payload);
                    break;
                case 'post':
                    response = await serviceExecute('post')(...payload);
                    break;
                case 'patch':
                    response = await serviceExecute('patch', 'update')(...payload);
                    break;
                case 'delete':
                    response = await serviceExecute('delete')(...payload);
                    break;
                default:
                    throw new Error(`Unsupported method: ${method}`);
            }

            this.state[requestId].data = response;
        } catch (error) {
            this.state[requestId].error = error;
        } finally {
            this.state[requestId].loading = false;
        }

        if (this.state[requestId].error) return Promise.reject(this.state[requestId].error);

        return this.state[requestId].data;
    }

    /**
     * Resetuje stan zapytania.
     * @param {string} id - Opcjonalny identyfikator zapytania.
     */
    reset(id) {
        const requestId = id || CURRENT_ID;
        if (this.state[requestId]) {
            this.state[requestId].loading = false;
            this.state[requestId].error = null;
            this.state[requestId].data = null;
        }
        return this;
    }

    /**
     * Pobiera stan zapytania.
     * @param {string} id - Opcjonalny identyfikator zapytania.
     * @returns {object} Stan zapytania.
     */
    getState(id = CURRENT_ID) {
        return (
            this.state[id] || {
                loading: false,
                error: null,
                data: null,
            }
        );
    }

    /**
     * Pobiera stan `loading` zapytania.
     * @param {string} id - Opcjonalny identyfikator zapytania.
     * @returns {boolean} Stan `loading`.
     */
    getLoading(id = CURRENT_ID) {
        return this.state[id] ? this.state[id].loading : false;
    }

    /**
     * Pobiera stan `error` zapytania.
     * @param {string} id - Opcjonalny identyfikator zapytania.
     * @returns {any} Stan `error`.
     */
    getError(id = CURRENT_ID) {
        return this.state[id] ? this.state[id].error : null;
    }

    /**
     * Pobiera stan `data` zapytania.
     * @param {string} id - Opcjonalny identyfikator zapytania.
     * @returns {any} Stan `data`.
     */
    getData(id = CURRENT_ID) {
        return this.state[id] ? this.state[id].data : null;
    }

    /**
     * Getters for loading, error, and data directly
     */
    get loading() {
        return this.getLoading();
    }

    get error() {
        return this.getError();
    }

    get data() {
        return this.getData();
    }
}

// Metody HTTP, które chcemy obsługiwać
const httpMethods = ['get', 'put', 'post', 'patch', 'delete'];

// Dynamicznie dodajemy metody do RequestBuilder
httpMethods.forEach(method => {
    RequestBuilder.prototype[method] = function(...payload) { // eslint-disable-line
        return this.execute(method, ...payload);
    };
});

/**
 * Tworzy instancję RequestBuilder dla zadanego modułu i endpointu.
 * @param {string} moduleAndEndpoint - Połączenie nazwy modułu i endpointu.
 * @returns {RequestBuilder} Instancja RequestBuilder.
 */
export default function createRequest(moduleAndEndpoint) {
    return new RequestBuilder(moduleAndEndpoint);
}

/* Użycie */

// const requestBrands = createRequest('catalog/brands');

// // Inicjalizacja zapytań z unikalnymi identyfikatorami lub domyślnym ID
// requestBrands.id('ID_1').get({ id: 1 });
// requestBrands.id('ID_2').put({ id: 2 });
// requestBrands.post({ id: 3 }); // Używa domyślnego ID

// // Dostęp do poszczególnych wartości stanu zapytań
// const loading1 = requestBrands.getLoading('ID_1');
// const error1 = requestBrands.getError('ID_1');
// const data1 = requestBrands.getData('ID_1');

// // Dostęp do stanu zapytań
// const state1 = requestBrands.id('ID_1').getState();
// const state2 = requestBrands.id('ID_2').getState();
// const state3 = requestBrands.getState(); // Używa domyślnego ID

// // Resetowanie stanu zapytań
// requestBrands.id('ID_1').reset();
// requestBrands.id('ID_2').reset();
// requestBrands.reset(); // Resetuje domyślne ID
