<template>
    <div class="d-flex flex-wrap gap-4">
        <div class="d-flex flex-wrap gap-4 flex-grow-1 flex-basis-0">
            <WidgetStat
                class="flex-grow-1 flex-basis-0 width-min-300 h-auto"
                :title="$t('donationsInThisMonth')"
                :value="$format.currency(donationsThisMonth)"
                :error="donationsThisMonthError"
                :loading="donationsThisMonthLoading"
            />
            <WidgetStat
                class="flex-grow-1 flex-basis-0 width-min-300 h-auto"
                :title="$t('donationsInThisYear')"
                :value="$format.currency(donationsThisYear)"
                :error="donationsThisYearError"
                :loading="donationsThisYearLoading"
            />
            <WidgetStat
                class="flex-grow-1 flex-basis-0 width-min-300 h-auto"
                :title="$t('donationsReadyForWithdrawal')"
                :value="$format.currency(donationsReadyToPayout)"
                :error="donationsReadyToPayoutError"
                :loading="donationsReadyToPayoutLoading"
            >
                <ButtonIcon v-if="donationsReadyToPayout && tipboxSettings.verified" @click="showPayoutModal()">
                    {{ $t('payOut') }}
                </ButtonIcon>
                <ButtonIcon v-else-if="!tipboxSettings.verified" variant="danger" @click="showVerificationModal()">
                    {{ $t('verifiyAccount') }}
                </ButtonIcon>
            </WidgetStat>
            <ModalDonationsToPayout @payout-success="refreshData()" />
            <ModalVerification v-if="tipboxSettings.verificationCode" :verification-code="tipboxSettings.verificationCode" />
        </div>
    </div>
</template>



<script>
    import { format, startOfMonth, startOfYear } from 'date-fns';
    import WidgetStat from '@/components/common/WidgetStat.vue';
    import tipboxService from '@/services/tipboxService';
    import ModalDonationsToPayout from './ModalDonationsToPayout.vue';
    import ModalVerification from './ModalVerification.vue';

    export default {
        name: 'Stats',
        components: {
            WidgetStat,
            ModalDonationsToPayout,
            ModalVerification,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                donationsThisMonth: null,
                donationsThisYear: null,
                donationsReadyToPayout: null,
                donationsThisMonthError: null,
                donationsThisYearError: null,
                donationsReadyToPayoutError: null,
                donationsThisMonthLoading: false,
                donationsThisYearLoading: false,
                donationsReadyToPayoutLoading: false,
            };
        },
        computed: {
            tipboxSettings() {
                return this.$store.getters['tipbox/getTipboxSettings'] || {};
            },
        },
        methods: {
            refreshData() {
                this.fetchData();
                this.parentComponent.$refs.Invoices?.fetchData();
                this.parentComponent.$refs.Donations?.fetchData();
                this.parentComponent.$refs.Payouts?.fetchData();
            },
            fetchData() {
                const dateFormat = 'yyyy-MM-dd';
                const now = new Date();
                const startMonth = format(startOfMonth(now), dateFormat);
                const endMonth = format(now, dateFormat);
                const startYear = format(startOfYear(now), dateFormat);
                const endYear = format(now, dateFormat);

                this.donationsThisMonthLoading = true;
                this.donationsThisYearLoading = true;
                this.donationsReadyToPayoutLoading = true;

                this.fetchAmountStats(startMonth, endMonth)
                    .then(data => (this.donationsThisMonth = data))
                    .catch(error => (this.donationsThisMonthError = error.message === 'api_errors.unprocessable_entity.policies_not_accepted' ? null : error))
                    .finally(() => (this.donationsThisMonthLoading = false));

                this.fetchAmountStats(startYear, endYear)
                    .then(data => (this.donationsThisYear = data))
                    .catch(error => (this.donationsThisYearError = error.message === 'api_errors.unprocessable_entity.policies_not_accepted' ? null : error))
                    .finally(() => (this.donationsThisYearLoading = false));

                this.fetchReadyToPayout()
                    .then(data => (this.donationsReadyToPayout = data))
                    .catch(error => (this.donationsReadyToPayoutError = error.message === 'api_errors.unprocessable_entity.policies_not_accepted' ? null : error))
                    .finally(() => (this.donationsReadyToPayoutLoading = false));
                this.fetchTipboxSettings();
            },
            fetchTipboxSettings() {
                return this.$store.dispatch('tipbox/fetchTipboxSettings').catch(() => {});
            },
            fetchAmountStats(startDate, endDate) {
                return tipboxService.getAmountStats({ requestParams: { startDate, endDate } });
            },
            fetchReadyToPayout() {
                return tipboxService.getReadyToPayout();
            },
            showPayoutModal() {
                this.$root.$emit('bv::show::modal', 'modal-donations-to-payout');
            },
            showVerificationModal() {
                this.$root.$emit('bv::show::modal', 'modal-verification');
            },
        },
        created() {
            this.fetchData();
        },
    };
</script>
