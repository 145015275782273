<template>
    <div>
        <PageHeader>
            {{ $t('conversionReport') }}
            <template #side>
                <div class="d-flex gap-4">
                    <Multiselect
                        v-model="sources"
                        :options="requestSources.data || []"
                        :placeholder="$t('source')"
                        multiple
                        show-checkboxes
                        :close-on-select="false"
                        select-label=""
                        track-by=""
                        class="width-min-250"
                        :loading="requestSources.loading"
                        @change="fetchData()"
                    />
                    <TimeRangeDropdown v-model="timeRange" @timeRange="fetchData()" />
                </div>
            </template>
        </PageHeader>

        <div class="row">
            <div class="col-xl-8 mb-3">
                <MultiChartCard
                    :data="chartOverview"
                    :height="420"
                    :loading="isChartOverviewLoading"
                    :options="chartOverviewOptions"
                    :title="$t('dashboard.overview')"
                    nav-tabs
                    @currentChart="setCurrentChart"
                />
            </div>

            <div class="col-xl-4 mb-3">
                <div class="card mb-0">
                    <div class="card-body">

                        <h4 class="card-title header-title">
                            {{ $t('conversionFunnel') }}
                        </h4>

                        <div v-for="key in Object.keys(chartOverview)" :key="key" class="d-flex gap-3 border-bottom justify-content-between align-items-end pt-2 pb-3">
                            <div>
                                <h6 class="text-muted mt-0 fs-14">
                                    {{ chartOverview[key].name }}
                                </h6>
                                <h3 class="my-2">
                                    <b-skeleton v-if="getLoading(key)" animation="wave" width="100%" class="mb-0 d-inline-block" />
                                    <div v-else>
                                        {{ chartOverview[key].value }}
                                    </div>
                                </h3>
                                <h6 class="mb-0" :class="{'text-success': isPositive(chartOverview[key].difference) === true, 'text-danger': isPositive(chartOverview[key].difference) === false}">
                                    <b-skeleton v-if="getLoading(key)" animation="wave" width="80%" class="mb-0 d-inline-block" />
                                    <div v-else>
                                        {{ getSign(chartOverview[key].difference) }}{{ $format.percent(Math.abs(chartOverview[key].difference)) }}
                                    </div>
                                </h6>
                            </div>
                            <div class="text-center">
                                <b-spinner v-if="getLoading(key)" />
                                <apexchart
                                    v-else
                                    type="area"
                                    :options="{...widgetChartOptions, colors: [chartOverview[key].color]}"
                                    :series="chartOverview[key].series"
                                    class="apex-chart-widget"
                                    :height="45"
                                    :width="90"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <DataTable
            :header="$t('publishersList')"
            :error="getError(currentChart)"
            :items="getPublishers(currentChart)"
            :total-rows="getPublishers(currentChart).length"
            :loading="getLoading(currentChart)"
            :fields="fields"
        >

            <template #cell(createdAt)="{value}">
                {{ dateFormat(value, 'yyyy-MM-dd HH:mm') }}
            </template>

            <template #cell(action)="data">
                <div class="d-flex gap-1 justify-content-end align-items-start">
                    <ButtonIcon
                        class="flex-shrink-0"
                        size="sm"
                        variant="warning"
                        hide-label-while-loading
                        :disabled="isItemBusy(data.item.id)"
                        :loading="isItemUpdated(data.item.id)"
                        :to="createItemUrl(data.item)"
                    >
                        {{ $t('details') }}
                    </ButtonIcon>
                    <ButtonIcon
                        class="flex-shrink-0"
                        size="sm"
                        variant="secondary"
                        hide-label-while-loading
                        :disabled="isItemBusy(data.item.id)"
                        :loading="isTokenLoading(data.item.id)"
                        @click="loginAs(data.item)"
                    >
                        {{ $t('go2') }}
                    </ButtonIcon>
                </div>
            </template>
        </DataTable>

    </div>
</template>



<script>
    import { format as dateFormat } from 'date-fns';
    import { generateChartData, chartDataGroupBy } from '@/utils/chart';
    import PageHeader from '@/components/common/PageHeader.vue';
    import TimeRangeDropdown from '@/components/common/TimeRangeDropdown.vue';
    import MultiChartCard from '@/components/common/MultiChartCard.vue';
    import DataTable from '@/components/common/DataTable.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import numberUtil from '@/utils/number';
    import createRequest from '@/utils/createRequest';
    import { stringToColour } from '@/utils/util';
    import { createItemKey } from '@/store/modules/moduleFactory';

    export default {
        name: 'WidgeterReportsConversion',
        components: {
            PageHeader,
            TimeRangeDropdown,
            MultiChartCard,
            DataTable,
            Multiselect,
        },
        data() {
            return {
                requestSources: createRequest('conversionStats/UniqueSources'),
                requestRegistrationStats: createRequest('conversionStats/RegistrationStats'),
                requestSpaceStats: createRequest('conversionStats/SpaceStats'),
                requestClickStats: createRequest('conversionStats/ClickStats'),
                requestTransactionStats: createRequest('conversionStats/TransactionStats'),
                requestTipTransactionStats: createRequest('conversionStats/TipTransactionStats'),
                timeRange: null,
                sources: [],
                conversionStatsError: null,
                currentChart: 'registration',
                widgetChartOptions: {
                    chart: {
                        sparkline: {
                            enabled: true,
                        },
                        parentHeightOffset: 0,
                        toolbar: {
                            show: false,
                        },
                    },
                    markers: {
                        size: 0,
                    },
                    tooltip: {
                        theme: 'dark',
                        fixed: {
                            enabled: false,
                        },
                        x: {
                            show: false,
                        },
                        y: {
                            title: {
                                formatter: seriesName => '',
                            },
                        },
                        marker: {
                            show: false,
                        },
                    },
                    stroke: { width: 2, curve: 'smooth' },
                },
                dateFormat,
            };
        },
        computed: {
            requests() {
                return {
                    registration: this.requestRegistrationStats,
                    space: this.requestSpaceStats,
                    click: this.requestClickStats,
                    transaction: this.requestTransactionStats,
                    tipTransaction: this.requestTipTransactionStats,
                };
            },
            requestParams() {
                return { ...this.timeRange, sources: this.sources };
            },
            groupBy() {
                return chartDataGroupBy(this.timeRange);
            },
            chartOverview() {
                return this.createChartOverview();
            },
            isChartOverviewLoading() {
                return this.getLoading(this.currentChart);
            },
            chartOverviewCurrentColor() {
                return this.chartOverview[this.currentChart].color;
            },
            chartOverviewOptions() {
                return {
                    markers: { size: 0 },
                    colors: [this.chartOverviewCurrentColor],
                    yaxis: {
                        title: {
                            text: '',
                        },
                        labels: {
                            formatter: (value, index) => value.toFixed(0),
                        },
                    },
                };
            },
            fields() {
                return [
                    { key: 'id', label: this.$t('id'), sortable: true },
                    { key: 'createdAt', label: this.$t('dateAdded'), sortable: true },
                    { key: 'email', label: this.$t('emailAddress'), sortable: true },
                    { key: 'name', label: this.$t('name'), sortable: true },
                    { key: 'source', label: this.$t('source'), sortable: true },
                    { key: 'action', label: '', thClass: 'text-right' },
                ];
            },
            isTokenLoading() {
                return id => this.$store.getters['user/getBusyItem']('userToken', createItemKey({ id }));
            },
            isItemBusy() {
                return id => {
                    const users = this.$store.getters['user/getBusyItem']('users', createItemKey({ id }));
                    const publishers = this.$store.getters['user/getBusyItem']('publishers', createItemKey({ id }));
                    const producers = this.$store.getters['user/getBusyItem']('producers', createItemKey({ id }));
                    const reporters = this.$store.getters['user/getBusyItem']('reporters', createItemKey({ id }));
                    return users || publishers || producers || reporters;
                };
            },
            isItemUpdated() {
                return id => {
                    const users = this.$store.getters['user/getUpdatedItem']('users', createItemKey({ id }));
                    const publishers = this.$store.getters['user/getUpdatedItem']('publishers', createItemKey({ id }));
                    const producers = this.$store.getters['user/getUpdatedItem']('producers', createItemKey({ id }));
                    const reporters = this.$store.getters['user/getUpdatedItem']('reporters', createItemKey({ id }));
                    return users || publishers || producers || reporters;
                };
            },
        },
        watch: {
            timeRange() {
                this.sources = [];
                this.fetchSources();
            },
            currentChart() {
                this.conversionStatsError = null;
            },
        },
        methods: {
            fetchSources() {
                this.requestSources.reset();
                if (!this.timeRange) return Promise.resolve([]);
                const { startDate, endDate } = this.timeRange;
                const params = { requestParams: { startDate, endDate } };
                return this.requestSources.get(params);
            },
            getRequest(type) {
                return this.requests[type];
            },
            getLoading(type) {
                return this.requests[type]?.loading || false;
            },
            getData(type) {
                return this.requests[type]?.data || {};
            },
            getError(type) {
                return this.requests[type]?.error;
            },
            getPublishers(type) {
                return this.getData(type)?.publishers || [];
            },
            fetchData() {
                const params = { requestParams: this.requestParams };
                this.getRequest('registration').reset().get(params);
                this.getRequest('space').reset().get(params);
                this.getRequest('click').reset().get(params);
                this.getRequest('transaction').reset().get(params);
                this.getRequest('tipTransaction').reset().get(params);
            },
            setTimeRange(timeRange) {
                this.timeRange = timeRange;
            },
            setCurrentChart(currentChart) {
                this.currentChart = currentChart;
            },
            getSign(val) {
                if (Math.sign(val) === 1) return '+';
                if (Math.sign(val) === -1) return '-';
                return '';
            },
            isPositive(val) {
                if (Math.sign(val) === 1) return true;
                if (Math.sign(val) === -1) return false;
                return null;
            },
            createChartData(data, key) {
                return generateChartData(
                    this.timeRange,
                    data.map(item => ({ ...item, value: item[key] }))
                ).map(item => [item.date, item.value]);
            },
            createChartObject(type, data, nameKey) {
                const seriesData = this.createChartData(data.chart || [], 'users');
                return {
                    series: [{ data: seriesData, name: this.$t(`conversionReportChartName.${nameKey}`) }],
                    name: this.$t(`conversionReportChartName.${nameKey}`),
                    color: stringToColour(`color-${type}`),
                    value: data.value,
                    valueCompare: data.value_compare,
                    difference: numberUtil.calculateChangePercentage(data.value, data.value_compare),
                };
            },
            createChartOverview() {
                const dataTypes = ['registration', 'space', 'click', 'transaction', 'tipTransaction'];
                const overview = {};
                dataTypes.forEach(type => {
                    overview[type] = this.createChartObject(type, this.getData(type), type);
                });
                return overview;
            },
            async loginAs(user) {
                return this.$store.dispatch('auth/loginAs', { ...user, role: 'ROLE_WIDGETER' }).catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            createItemUrl({ id }) {
                return {
                    name: 'UsersDetails',
                    params: { id, parentRoute: this.$route },
                    query: {},
                };
            },
        },
    };
</script>

