<template>
    <div>

        <PageHeader>
            {{ $t('listOfPaymentsTips') }}
            <template #side>
                <div class="d-flex">
                    <MultiselectSpaces
                        v-model="filters.spaces"
                        class="width-250"
                        :disabled="loading"
                        :preselect-all="true"
                        @loading="value => isSpacesLoading = value"
                    />
                    <div class="mx-2"></div>
                    <TimeRangeDropdown v-model="filters.timeRange" :disabled="loading || isSpacesLoading" />
                </div>
            </template>
        </PageHeader>

        <Chart ref="Chart" />

        <Results ref="Results" />

    </div>
</template>



<script>
    import PageHeader from '@/components/common/PageHeader.vue';
    import MultiselectSpaces from '@/components/common/MultiselectSpaces.vue';
    import TimeRangeDropdown from '@/components/common/TimeRangeDropdown.vue';
    import Chart from './parts/Chart.vue';
    import Results from './parts/Results.vue';

    export default {
        name: 'Tips',
        components: {
            PageHeader,
            MultiselectSpaces,
            TimeRangeDropdown,
            Chart,
            Results,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                filters: {
                    spaces: [],
                    timeRange: null,
                },
                perPage: 50,
                currentPage: 1,
                sortBy: 'createdAt',
                sortDirection: 'desc',
                isSpacesLoading: false,
            };
        },
        computed: {
            filtersParams() {
                const filters = {};
                if (this.filters.spaces?.length) filters.spaces = this.filters.spaces.map(item => item.value).join(',');
                if (this.filters.timeRange?.startDate) filters.startDate = this.filters.timeRange.startDate;
                if (this.filters.timeRange?.endDate) filters.endDate = this.filters.timeRange.endDate;
                return filters;
            },
            tableParams() {
                return {
                    sort: this.sortBy,
                    order: this.sortDirection.toUpperCase(),
                    page: this.currentPage,
                    perPage: this.perPage,
                };
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                    ...this.tableParams,
                };
            },
            loading() {
                return this.$store.getters['tipTransactions/getLoading']('transactions');
            },
        },
        watch: {
            requestParams: {
                deep: true,
                handler: 'fetchData',
            },
        },
        methods: {
            clearData() {
                this.$store.commit('tipTransactions/clearTransactions');
            },
            fetchData() {
                if (this.isSpacesLoading) return;
                this.$store.dispatch('tipTransactions/fetchTransactions', { requestParams: this.requestParams });
            },
        },
        mounted() {
            this.clearData();
        },
    };
</script>
