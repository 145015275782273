import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueClipboard from 'vue-clipboard2';
import BootstrapVue from 'bootstrap-vue';
import VueFeather from 'vue-feather';
import AsyncComputed from 'vue-async-computed';
import VueApexCharts from 'vue-apexcharts';
import VueCookies from 'vue-cookies';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import cloneDeep from 'lodash/cloneDeep';
import formatUtil from '@/utils/format';
import flatPickr from '@/components/common/FlatPickr.vue';
import ButtonIcon from '@/components/common/ButtonIcon.vue';
import PageHeader from '@/components/common/PageHeader.vue';
import Card from '@/components/common/Card.vue';
import ErrorMessage from '@/components/common/ErrorMessage.vue';
import TransitionExpand from '@/components/common/TransitionExpand.vue';
import toastr from '@/plugins/toastr';
import socialAuth from '@/plugins/socialauth/index';
import WebStorage from '@/plugins/webStorage';
import App from './App.vue';
import router from './router/router';
import store from './store/store';
import { i18n } from './i18n/i18n';

if (store) store.initialState = cloneDeep(store.state || {});

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    release: process.env.GIT_COMMIT_HASH_SHORT_7,
});

Vue.use(VueClipboard);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);

/* Extend BFormSelect */
Vue.component('BFormSelect', {
    extends: Vue.options.components.BFormSelect,
    props: {
        addNullOption: {
            type: [Boolean, Object],
            default: false,
        },
    },
    computed: {
        formOptions() {
            const options = this.normalizeOptions(this.options);
            if (this.addNullOption) {
                const nullOption = {
                    value: this.addNullOption?.null || null,
                    text: this.addNullOption?.text || i18n.tx('selectOption', '-- select option --'),
                    disabled: false,
                };
                return [nullOption, ...options];
            }
            return options;
        },
    },
});

const socialAuthConfig = {};
if (process.env.VUE_APP_SOCIAL_AUTH_GOOGLE_ID) {
    socialAuthConfig.google = {
        client_id: process.env.VUE_APP_SOCIAL_AUTH_GOOGLE_ID,
    };
}
if (process.env.VUE_APP_SOCIAL_AUTH_FACEBOOK_ID) {
    socialAuthConfig.facebook = {
        appId: process.env.VUE_APP_SOCIAL_AUTH_FACEBOOK_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v12.0',
    };
}
if (process.env.VUE_APP_SOCIAL_AUTH_APPLE_ID) {
    socialAuthConfig.apple = {
        clientId: process.env.VUE_APP_SOCIAL_AUTH_APPLE_ID,
        scope: 'name email',
        redirectURI: window.location.origin,
        state: 'connected',
        nonce: 'nonce',
        usePopup: true,
    };
}
Vue.use(socialAuth, socialAuthConfig);

Vue.use(VueFeather);
Vue.use(AsyncComputed);
Vue.use(toastr);
Vue.use(VueCookies);
Vue.use(WebStorage, {
    prefix: 'buybox-',
});
Vue.component('Apexchart', VueApexCharts);
Vue.component('ButtonIcon', ButtonIcon);
Vue.component('PageHeader', PageHeader);
Vue.component('Card', Card);
Vue.component('ErrorMessage', ErrorMessage);
Vue.component('TransitionExpand', TransitionExpand);
Vue.component('FlatPickr', flatPickr);

Vue.config.productionTip = process.env.NODE_ENV === 'development';
Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.config.performance = process.env.NODE_ENV === 'development';
Vue.config.debug = process.env.NODE_ENV === 'development';
Vue.config.silent = process.env.NODE_ENV === 'production';

if (process.env.NODE_ENV === 'development') {
    Vue.config.errorHandler = function errorHandler(err, vm, info) {
        console.error(info, vm._name, err); // eslint-disable-line
    };
}

Vue.prototype.$format = formatUtil;

// While registering new component/plugin, don't forget to do it also in main.styleguide.js if applies

const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');

if (window.Cypress) {
    window.app = app;
}

export default app;
