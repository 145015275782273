<template>
    <b-overlay :show="loading" rounded="sm" class="h-100">
        <Card :header="$t('dashboard.yourBestProducts')" class="mb-0 h-100">
            <ErrorMessage :value="error" />
            <b-table
                hover
                :items="items"
                :fields="fields"
                show-empty
                :empty-text="$t('noResult')"
                :busy="loading"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            >
                <template #table-busy>
                    <div class="d-flex justify-content-center my-2">
                        <b-spinner />
                    </div>
                </template>
            </b-table>
        </Card>
    </b-overlay>
</template>



<script>
    import Card from '@/components/common/Card.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'WidgetBestProducts',
        components: {
            Card,
            ErrorMessage,
        },
        props: {
            timeRange: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                fields: [
                    {
                        key: 'name',
                        label: this.$t('dashboard.product'),
                    },
                    {
                        key: 'quantity',
                        label: this.$t('dashboard.unitsSold'),
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ],
                itemsToShow: 5,
                sortBy: 'quantity',
                sortDesc: true,
            };
        },
        computed: {
            isRoleWidgeter() {
                return this.$store.getters['auth/isRoleWidgeter'];
            },
            spaceCount() {
                return this.$store.getters['space/spaceCount'];
            },
            isRoleAdvertiser() {
                return this.$store.getters['auth/isRoleAdvertiser'];
            },
            campaignId() {
                return this.$store.getters['campaigns/getCurrentCampaign']?.id;
            },
            requestParams() {
                const params = {
                    startDate: this.timeRange.startDate,
                    endDate: this.timeRange.endDate,
                    limit: this.itemsToShow,
                };
                if (this.isRoleAdvertiser) params.campaigns = this.campaignId;
                return params;
            },
            apiParams() {
                return { requestParams: this.requestParams };
            },
            loading() {
                return this.$store.getters['userStats/getLoading']('topProductsQuantity');
            },
            products() {
                return this.$store.getters['userStats/getTopProductsQuantity'] || [];
            },
            items() {
                return this.products.items || [];
            },
            error() {
                return this.$store.getters['userStats/getError']('topProductsQuantity')?.message;
            },
        },
        watch: {
            apiParams: {
                deep: true,
                handler: 'fetchData',
            },
        },
        methods: {
            fetchData(params = {}) {
                if (this.isRoleWidgeter && !this.spaceCount) return;
                this.$store.dispatch('userStats/fetchTopProductsQuantity', { ...this.apiParams, ...params });
            },
        },
    };
</script>
