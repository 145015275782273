<template>
    <div>
        <PageHeader>
            {{ $t('users') }}
            <template #side>
                <b-dropdown :text="$t('addUser')" variant="primary" class="ml-2" :disabled="loading">
                    <b-dropdown-item href="#" @click="createUser(Role.WIDGETER)">
                        {{ roleTranslate(Role.WIDGETER) }}
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="createUser(Role.ADVERTISER)">
                        {{ roleTranslate(Role.ADVERTISER) }}
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="createUser(Role.PRODUCER)">
                        {{ roleTranslate(Role.PRODUCER) }}
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </PageHeader>
        <Filters ref="Filters" />
        <Results ref="Results" />
        <ModalForm />
        <!-- <ModalShow /> -->
    </div>
</template>



<script>
    import Filters from './parts/Filters.vue';
    import Results from './parts/Results.vue';
    import ModalForm from './parts/ModalForm.vue';
    // import ModalShow from './parts/ModalShow.vue';
    import Role, { roleTranslate } from '@/Role';

    export default {
        name: 'Users',
        components: {
            Filters,
            Results,
            ModalForm,
            // ModalShow,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                Role,
                perPage: 50,
                currentPage: 1,
                sortBy: 'id',
                sortDirection: 'desc',
            };
        },
        computed: {
            loading() {
                return this.$store.getters['user/getLoading']('users');
            },
        },
        methods: {
            roleTranslate,
            createUser(role) {
                this.$root.$emit('bv::show::modal', 'modal-form-users');
                this.$root.$emit('modal-form-users', null, role);
            },
        },
    };
</script>
