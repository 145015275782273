<template>
    <b-modal
        id="payment-generator-private"
        :visible="value"
        :title="$t('paymentGeneratorPrivate.title')"
        @ok="handlePayOut"
        @change="$emit('input', $event)"
    >
        <i18n v-if="hasMissingData" path="paymentGeneratorPrivate.missingData" tag="p">
            <template #link>
                <router-link :to="{ name: 'AccountSettings' }">
                    {{ $t('paymentGeneratorPrivate.missingDataLink') }}
                </router-link>
            </template>
        </i18n>
        <div v-else>
            <p>{{ $t('paymentGeneratorPrivate.description') }}</p>
            <p>
                {{ user.fullName }}<br>
                {{ user.street }} {{ user.streetNumber }}{{ user.apartmentNumber && ` / ${user.apartmentNumber}` }}<br>
                {{ user.postcode }} {{ user.city }}<br>
                {{ countryName }}<br>
                {{ user.bankAccount }}
            </p>
        </div>
        <error-message v-model="error" :action="showAccountSettingsLink" @action="$router.push({ name:'AccountSettings'})" />
        <template #modal-footer="{ ok, cancel }">
            <button-default variant="outline-primary" @click="cancel">
                {{ $t('paymentGeneratorPrivate.cancel') }}
            </button-default>
            <button-default :loading="createBillLoading" variant="primary" @click="ok">
                {{ $t('paymentGeneratorPrivate.confirm') }}
            </button-default>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import countryService from '@/services/countryService';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';

    export default {
        name: 'PaymentGeneratorPrivate',
        components: {
            ButtonDefault,
            ErrorMessage,
        },
        props: {
            value: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                error: null,
            };
        },
        computed: {
            hasMissingData() {
                const { firstName, lastName, street, streetNumber, postcode, city, country, bankAccount } = this.user;
                return !firstName || !lastName || !street || !streetNumber || !postcode || !city || !country || !bankAccount;
            },
            countryName() {
                return countryService.getCountryName(this.user.country);
            },
            showAccountSettingsLink() {
                if (this.error && this.error === 'unprocessable_entity.missing_personal_data') {
                    return this.$t('accountSettings.title');
                }
                return false;
            },
            ...mapState({
                user: state => state.auth.user,
                createBillLoading: state => state.finance.loading.createBill,
            }),
        },
        methods: {
            async handlePayOut(event) {
                event.preventDefault();
                try {
                    this.error = null;
                    await this.createBill();
                    this.$emit('input', false);
                    this.$emit('done');
                } catch (e) {
                    this.error = e.message;
                }
            },
            ...mapActions({
                createBill: 'finance/createBill',
            }),
        },
    };
</script>

<style lang="scss" scoped></style>
