<template>
    <router-view />
</template>



<script>
    export default {
        name: 'OffersCatalog',
    };
</script>
