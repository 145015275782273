<template>
    <div>
        <page-loading v-if="loading" />
        <error-message v-else-if="error" v-model="error" />
        <slot v-else></slot>
    </div>
</template>

<script>
    /* eslint-disable no-underscore-dangle */
    import { mapActions, mapState } from 'vuex';
    import PageLoading from '@/components/common/PageLoading.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'SpaceProvider',
        components: { ErrorMessage, PageLoading },
        props: {
            fetchAllowedCampaigns: {
                type: Boolean,
                default: false,
            },
            fetchSpaceTypes: {
                type: Boolean,
                default: false,
            },
            fetchSpaceStatuses: {
                type: Boolean,
                default: false,
            },
            fetchCategories: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                error: null,
            };
        },
        computed: {
            loading() {
                if (this.spacesLoading) {
                    return true;
                }

                if ((this.fetchAllowedCampaigns && this.allowedCampaignsLoading) || (this.fetchSpaceTypes && this.spaceTypesLoading) || (this.fetchSpaceStatuses && this.spaceStatusesLoading) || (this.fetchCategories && this.categoriesLoading)) {
                    return true;
                }

                return false;
            },
            ...mapState({
                allowedCampaignsLoading: state => state.space.loading.fetchAllowedCampaigns,
                spacesLoading: state => state.space.loading.fetchSpaces,
                spaceTypesLoading: state => state.space.loading.fetchSpaceTypes,
                spaceStatusesLoading: state => state.space.loading.fetchSpaceStatuses,
                categoriesLoading: state => state.space.loading.fetchCategories,
            }),
        },
        methods: {
            async getData() {
                try {
                    this.error = null;
                    const promises = [this._fetchSpaces()];
                    if (this.fetchAllowedCampaigns) {
                        promises.push(this._fetchAllowedCampaigns());
                    }
                    if (this.fetchSpaceTypes) {
                        promises.push(this._fetchSpaceTypes());
                    }
                    if (this.fetchSpaceStatuses) {
                        promises.push(this._fetchSpaceStatuses());
                    }
                    if (this.fetchCategories) {
                        promises.push(this._fetchCategories());
                    }
                    await Promise.all(promises);
                } catch (e) {
                    this.error = e.message;
                }
            },
            ...mapActions({
                _fetchSpaces: 'space/fetchSpaces',
                _fetchAllowedCampaigns: 'space/fetchAllowedCampaigns',
                _fetchSpaceTypes: 'space/fetchSpaceTypes',
                _fetchSpaceStatuses: 'space/fetchSpaceStatuses',
                _fetchCategories: 'space/fetchCategories',
            }),
        },
        mounted() {
            this.getData();
        },
    };
</script>
