import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    FAVICON: {
        URI: () => '/api/v1/buyboxes/{{id}}/landing-page-favicon',
        methods: ['delete'],
        default: {},
    },
    LOGO: {
        URI: () => '/api/v1/buyboxes/{{id}}/landing-page-logo',
        methods: ['delete'],
        default: {},
    },
    LANDING_PAGE: {
        URI: () => '/api/v1/buyboxes/{{id}}/landing-page',
        methods: ['get', 'patch'],
        default: {},
    },
    IMAGES: {
        URI: () => '/api/v1/buyboxes/{{id}}/landing-page-images',
        methods: ['post'],
        default: {},
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
