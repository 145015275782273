import validationService from '../services/validationService';

export default {
    props: {
        /**
         * `Vuelidate` validation object
         */
        validation: {
            type: Object,
            required: false,
        },
        customValidationErrors: {
            type: Object,
            required: false,
        },
    },
    methods: {
        validate() {
            if (this.validation) {
                this.validation.$touch();
            }
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        validationErrors() {
            return this.validation ? validationService.getValidationErrors(this.validation, this.customValidationErrors) : [];
        },
        hasValidationErrors() {
            return this.validationErrors.length > 0;
        },
        isRequired() {
            return this.validation ? validationService.hasValidatorRequired(this.validation) : false;
        },
    },
};
