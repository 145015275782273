<template>
    <b-overlay :show="transactionsLoading" rounded="sm" class="h-100">
        <card :header="$t('dashboard.recentTransactions')" class="mb-0 h-100">
            <template #nav>
                <b-button variant="primary" :to="{ name: 'ReportsTransactions' }">
                    {{ $t('all') }}
                </b-button>
            </template>
            <div class="overflow-auto">
                <ErrorMessage :value="transactionsError" />
                <b-table
                    hover
                    :items="items"
                    :fields="fields"
                    show-empty
                    :empty-text="$t('noResult')"
                    :busy="transactionsLoading"
                >
                    <template #table-busy>
                        <div class="d-flex justify-content-center my-2">
                            <b-spinner />
                        </div>
                    </template>
                </b-table>
            </div>
        </card>
    </b-overlay>
</template>



<script>
    import Card from '@/components/common/Card.vue';
    import { STATUSES } from '@/services/transactionsService';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'WidgetRecentTransactions',
        components: {
            Card,
            ErrorMessage,
        },
        computed: {
            isRoleWidgeter() {
                return this.$store.getters['auth/isRoleWidgeter'];
            },
            spaceCount() {
                return this.$store.getters['space/spaceCount'];
            },
            campaignId() {
                return this.$store.getters['campaigns/getCurrentCampaign']?.id;
            },
            apiUserType() {
                return this.$store.getters['auth/apiUserType'];
            },
            requestParams() {
                return {
                    sort: 'createdAt',
                    order: 'DESC',
                    page: 1,
                    perPage: 5,
                    omitItems: true,
                    statuses: STATUSES.NEW,
                    campaigns: this.campaignId || '',
                };
            },
            apiParams() {
                return { requestParams: this.requestParams };
            },
            fields() {
                return [
                    {
                        key: 'date',
                        label: this.$t('transactions.date'),
                    },
                    !this.$store.getters['auth/isRoleAdvertiser'] && {
                        key: 'campaign',
                        label: this.$t('transactions.campaign'),
                    },
                    {
                        key: 'space',
                        label: this.$t('transactions.space'),
                    },
                    {
                        key: 'transactionAmount',
                        label: this.$t('transactions.transactionAmount'),
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'commissionAmount',
                        label: this.$t('transactions.commissionAmount'),
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ];
            },
            transactions() {
                return this.$store.getters['transactions/getUserTransactions'];
            },
            transactionsError() {
                return this.$store.getters['transactions/getError']('userTransactions')?.message;
            },
            transactionsLoading() {
                return this.$store.getters['transactions/getLoading']('userTransactions');
            },
            items() {
                return (
                    this.transactions.items?.map(item => ({
                        date: this.$format.dateAndHour(item.createdAt),
                        campaign: item.campaign.name,
                        space: item.space.name,
                        transactionAmount: this.$format.currency(item.amount),
                        commissionAmount: this.$format.currency(item[`${this.apiUserType}Commission`]),
                    })) || []
                );
            },
        },
        watch: {
            apiParams: {
                immediate: true,
                deep: true,
                handler: 'fetchData',
            },
        },
        methods: {
            fetchData(params = {}) {
                if (this.isRoleWidgeter && !this.spaceCount) return;
                this.$store.dispatch('transactions/fetchUserTransactions', { ...this.apiParams, ...params });
            },
        },
    };
</script>
