<template>
    <div>
        <PageHeader>
            {{ $t('reportSubscriptionList') }}
            <template #side>
                <div class="d-flex gap-3">
                    <ButtonIcon class="width-min-100" variant="primary" :to="{ name: 'FinanceSubscriptionsCreate', params: { parentUrl: $route.fullPath } }">
                        {{ $t('createSubscription') }}
                    </ButtonIcon>
                </div>
            </template>
        </PageHeader>
        <Filters ref="Filters" />
        <Results ref="Results" />
    </div>
</template>



<script>
    import viewFiltersMixin from '@/mixins/viewFiltersMixin';
    import Filters from './parts/Filters.vue';
    import Results from './parts/Results.vue';

    const DEFAULT_FILTERS_PARAMS = Object.freeze({
        statuses: [],
        spaces: [],
    });

    export default {
        name: 'Subscriptions',
        mixins: [viewFiltersMixin],
        components: {
            Filters,
            Results,
        },
        data() {
            return {
                ...this.initParams(DEFAULT_FILTERS_PARAMS),
                request: this.createRequest('reportSubscriptions/subscriptions'),
            };
        },
    };
</script>
