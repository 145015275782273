<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">{{ year }} &copy; BUY.BOX</div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'app-footer',
    data() {
        return {
            year: new Date().getUTCFullYear(),
        };
    },
};
</script>
