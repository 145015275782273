// eslint-disable-next-line max-classes-per-file
import keyfinder from 'keyfinder';
import { i18n } from '../i18n/i18n';

const parseParameterRegexp = /{{ | }}/g;

export class ApiError extends Error {
    constructor(name, statusCode, message, errorCode, error) {
        super(message);
        this.name = name;
        this.statusCode = statusCode;
        this.errorCode = errorCode;
        this.error = error;
    }
}

export class EndpointError extends Error {
    constructor(message) {
        super(message);
        this.message = message || i18n.t('error.endpoint');
        this.name = 'EndpointError';
    }
}

export class EndpointParamsError extends Error {
    constructor(params, message) {
        super(message);
        this.message = message || i18n.t('error.endpointParams');
        this.name = 'EndpointParamsError';
        this.params = params;
    }
}

export default {
    getFormattedError(error, defaultMessage = 'error.default') {
        const { message } = error;
        let errorMessage = message || i18n.t(defaultMessage);
        let statusCode = 500;
        let errorCode;

        if (error instanceof ApiError) {
            statusCode = error.statusCode;
            errorCode = error.errorCode;
        } else if (error.response) {
            const { status, data } = error.response;
            errorCode = data.errorCode;
            // axios error
            statusCode = status;

            const fieldsErrors = [];
            keyfinder(data?.fields, (key, value, parent) => {
                if (typeof value?.message === 'string') fieldsErrors.push(value);
            });

            if (fieldsErrors.length) {
                if (data.code) {
                    statusCode = data.code;
                }
                const key = fieldsErrors[0].message;
                const parameters = {};
                const rawParameters = fieldsErrors[0].parameters;
                if (rawParameters && Object.keys(rawParameters).length > 0) {
                    Object.keys(rawParameters).forEach(rawParam => {
                        const paramId = rawParam.replaceAll(parseParameterRegexp, '');
                        parameters[paramId] = rawParameters[rawParam];
                    });
                }
                errorMessage = i18n.t(key, parameters);
            } else if (data.message) {
                errorMessage = i18n.t(data.message);
            } else if (status === 400 && data?.errorCode === 'INVALID_REQUEST_DATA') {
                errorMessage = i18n.t('invalidRequestData');
            } else if (status === 404) {
                errorMessage = i18n.t('error.notFound');
            } else if (data?.errorCode) {
                if (data.code) {
                    statusCode = data.code;
                }
                errorMessage = i18n.t(`error.${data.errorCode}`);
            } else if (data?.error?.errorCode) {
                if (data.error.code) {
                    statusCode = data.error.code;
                }
                errorMessage = i18n.t(`error.${data.error.errorCode}`);
            } else if (data?.error?.message) {
                if (data.error.code) {
                    statusCode = data.error.code;
                }
                errorMessage = i18n.t(`error.${data.error.message}`);
            }
        } else if (error.request) {
            // axios network error
            statusCode = 503;
            errorMessage = i18n.t('error.network');
        }

        return { message: errorMessage, statusCode, errorCode, error };
    },
};
