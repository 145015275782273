<template>
    <div>
        <PageHeader>
            {{ $t('searchForOffers') }}
        </PageHeader>
        <Filters ref="Filters" />
        <Results ref="Results" />
    </div>
</template>



<script>
    import pickBy from 'lodash/pickBy';
    import Filters from './parts/Filters.vue';
    import Results from './parts/Results.vue';

    export default {
        name: 'Offers',
        components: {
            Filters,
            Results,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                filters: {
                    name: null,
                    ean: null,
                    info: null,
                    product: null,
                    code: null,
                    image: null,
                    campaigns: null,
                    feed: null,
                    categoryRegex: null,
                    type: null,
                    format: null,
                    hidden: null,
                    active: null,
                },
                tableParams: {
                    perPage: 50,
                    page: 1,
                },
                loading: false,
            };
        },
        computed: {
            currentFilters() {
                return pickBy(this.filters, value => value !== null && value !== undefined && value !== '');
            },
        },
        methods: {
            search() {
                return this.$refs.Results.fetchData();
            },
            fetchTypesAndFormats() {
                this.$store.dispatch('offers/fetchTypesAndFormats').catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            resetFilters() {
                Object.keys(this.filters).forEach(key => {
                    this.filters[key] = null;
                });
            },
        },
        created() {
            this.fetchTypesAndFormats();
        },
    };
</script>
