<template>
    <div :class="{'touched':productsRequest.touched}">
        <div v-if="selectedProduct" class="mb-2 font-weight-bold product-box" :class="{'selected-product':selectedProduct}">
            <ImageLoader
                v-if="selectedProduct.image"
                class="d-block border product-image"
                :src="selectedProduct.image"
                alt=""
            />
            {{ selectedProduct.name }}
        </div>
        <div>
            <text-field
                v-model="searchText"
                :placeholder="$t('generator.productSearchPlaceholder')"
                autocomplete="off"
                @input="onChangeText"
            />
            <error-message v-model="productsRequest.error" />
            <table-request
                v-if="productsRequest.touched"
                :request="productsRequest"
                :headers="[
                    {
                        slot: 'product',
                    },
                ]"
                max-height="300px"
                @rowClick="onSelectItem"
            >
                <template #product="{ item }">
                    <div class="product-box">
                        <ImageLoader
                            v-if="item.image"
                            class="d-block border product-image"
                            :src="item.image"
                            alt=""
                        />
                        {{ item.name }}
                    </div>
                </template>
            </table-request>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { debounce } from 'lodash';
    import WrapRequest from '@/utils/wrapRequest';
    import pluginService from '@/services/pluginService';
    import TableRequest from '@/components/common/TableRequest.vue';
    import TextField from '@/components/common/TextField.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import ImageLoader from '@/components/common/ImageLoader.vue';

    export default {
        name: 'ProductSearch',
        components: {
            ErrorMessage,
            TextField,
            TableRequest,
            ImageLoader,
        },
        props: {
            value: {
                type: Object,
            },
        },
        data() {
            return {
                searchText: '',
                productsRequest: new WrapRequest(() => pluginService.searchProducts(this.userId, this.searchText)),
            };
        },
        computed: {
            selectedProduct: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                },
            },
            ...mapState({
                userId: state => state.auth.user.id,
                currentSpaceId: state => state.space.currentSpaceId,
            }),
        },
        methods: {
            onChangeText: debounce(function debouncedOnChangeText(text) {
                if (text) {
                    this.productsRequest.request();
                } else {
                    this.productsRequest.reset(undefined, true);
                }
            }, 750),
            onSelectItem(product) {
                this.selectedProduct = product;
                this.searchText = '';
                this.productsRequest.reset(undefined, true);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .product-box {
        display: flex;
        align-items: center;
    }

    .product-image {
        margin-right: 15px;
        max-width: 100px;
        max-height: 60px;

        &.image-error,
        &.image-loader {
            width: 40px;
            height: 40px;
            background-size: 90%;
        }
    }
</style>
