<template>
    <row-labeled :row="row">
        <template v-if="label || $scopedSlots.label" #label>
            <slot name="label">
                <span>{{ label }} <span v-if="isRequired" class="text-danger">*</span></span>
            </slot>
        </template>

        <template #content="{ htmUniqueId }">
            <div class="input-group-prepend">
                <span class="input-group-text">
                    <b-dropdown variant="black" class="align-self-center" toggle-class="p-0" :disabled="disabled">
                        <template #button-content>
                            <div class="color-picker-preview" :style="{ background: model || '#FFF' }"></div>
                        </template>
                        <color-picker
                            v-model="model"
                            disable-alpha
                            @mousedown.native="tempValue = value"
                            @mouseup.native="tempValue = null"
                            @keydown.native="tempValue = value"
                            @keyup.native="tempValue = null"
                        />
                    </b-dropdown>
                </span>
            </div>

            <b-form-input
                :id="htmUniqueId"
                v-model.trim="model"
                type="text"
                :class="{ 'is-invalid': hasValidationErrors }"
                :placeholder="placeholder || '#FFF'"
                min="0"
                :state="state"
                :disabled="disabled"
                @blur="validate"
                @change="() => $emit('change')"
            />

            <div v-if="hasValidationErrors" class="invalid-feedback">
                {{ validationErrors.join(' ') }}
            </div>
        </template>
    </row-labeled>
</template>

<script>
    import { Chrome } from 'vue-color';

    import RowLabeled from './RowLabeled.vue';
    import validatableFieldMixin from '../../mixins/validatableFieldMixin';

    export default {
        name: 'ColorField',
        mixins: [validatableFieldMixin],
        components: { RowLabeled, ColorPicker: Chrome },
        props: {
            /**
             * @model
             */
            value: {
                type: String,
                required: false,
            },
            label: {
                type: String,
            },
            placeholder: {
                type: String,
            },
            row: {
                type: Boolean,
            },
            state: {
                type: Boolean,
                default: null,
                validator: value => [null, false, true].includes(value),
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                tempValue: null,
            };
        },
        computed: {
            model: {
                get() {
                    return this.value || '';
                },
                set(value) {
                    this.$emit('input', value.hex || value);
                },
            },
        },
        watch: {
            tempValue(newValue, oldValue) {
                // wait to this.value (model) change
                setTimeout(() => {
                    if (newValue === null && this.value !== oldValue) this.$emit('change', this.value);
                }, 100);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .color-picker-preview {
        $size: 18px;
        width: $size;
        height: $size;
        border-radius: 5px;
        transition: 50ms filter;
        &:hover {
            filter: brightness(110%);
        }
    }
</style>
