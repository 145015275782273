<template>
    <div v-if="loading" class="d-flex justify-content-center">
        <b-spinner />
    </div>

    <div v-else>
        <ul class="list-container list-group flex-grow-1">
            <li
                v-for="campaign in activeCampaigns"
                :key="campaign.id"
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <checkbox-field
                    class="campaign-switch"
                    variant="switch"
                    :value="true"
                    :label="campaign.name"
                    :disabled="isSubmitting(campaign.id)"
                    @click="handleChangeAvailable({ added: { element: campaign } }, false)"
                />
                <div class="d-flex align-items-center justify-content-end">
                    <b-spinner v-if="isSubmitting(campaign.id)" small />
                    <TextField
                        v-model="campaign.priority"
                        class="priority-input"
                        type="number"
                        :disabled="isSubmitting(campaign.id)"
                        @blur="handlePriorityChange(campaign)"
                        @keyup.enter="handlePriorityChange(campaign)"
                    />
                </div>
            </li>

            <li
                v-for="campaign in availableCampaigns"
                :key="campaign.id"
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                <checkbox-field
                    class="campaign-switch"
                    variant="switch"
                    :value="false"
                    :label="campaign.name"
                    :disabled="isSubmitting(campaign.id)"
                    @click="handleChangeActive({ added: { element: campaign } }, false)"
                />
                <b-spinner v-if="isSubmitting(campaign.id)" small />
            </li>
        </ul>

        <!-- Desktop -->
        <div class="desktop-container">


            <div class="available-container">
                <h4>{{ $t('widgetSettings.availableShops') }}</h4>

                <text-field v-model="filters.name" :label="$t('name')" />

                <row-labeled :row="false">
                    <template #label>
                        {{ $t('category') }}
                    </template>
                    <template #content>
                        <Multiselect
                            v-model="filters.categories"
                            :placeholder="$t('selectField.placeholder')"
                            :fetch-options-function="fetchCategories"
                            :clear-on-select="false"
                            :close-on-select="false"
                            :show-selected="true"
                            :asynchronous="false"
                            :searchable="false"
                            :multiple="true"
                            track-by="id"
                            label="name"
                            select-label=""
                            class="mb-4"
                        />
                    </template>
                </row-labeled>

                <button-default class="mb-4" @click="getAvailableCampaigns">
                    {{ $t('filter') }}
                </button-default>

                <table-default
                    :items="availableCampaigns"
                    :headers="[
                        {
                            label: $t('name'),
                            slot: 'name',
                        },
                        // {
                        //     align: 'left',
                        //     label: $t('category'),
                        //     value: (item) => item.category || 'N/A',
                        // },
                        // {
                        //     align: 'left',
                        //     label: $t('widgetSettings.commisions'),
                        //     value: (item) => item.category || 'N/A',
                        // },
                        {
                            label: '',
                            slot: 'actions',
                        },
                    ]"
                >

                    <template #name="{ item }">
                        <img v-if="item.icon" :src="item.icon" alt="icon" width="24" height="24">
                        <img
                            v-if="item.campaign && item.campaign.icon"
                            :src="item.campaign.icon"
                            alt="icon"
                            width="24"
                            height="24"
                        >
                        <div v-else class="icon-placeholder"> </div>

                        <span class="ml-2">{{ item.name }}</span>
                    </template>

                    <template #actions="{ item }">
                        <button-default :disabled="isSubmitting(item.id)" @click="handleAvailableClick(item, 0)">
                            {{ $t('widgetSettings.addToWidget') }}
                        </button-default>
                    </template>
                </table-default>
            </div>

            <div class="active-container">
                <h4>{{ $t('widgetSettings.activeShops') }}</h4>

                <table-default
                    :items="activeCampaigns"
                    :headers="[
                        {
                            label: '',
                            slot: 'icon',
                        },
                        {
                            align: 'left',
                            label: $t('name'),
                            value: (item) => item.name,
                        },
                        {
                            align: 'left',
                            label: $t('widgetSettings.priority'),
                            slot: 'priority',
                        },
                        {
                            label: '',
                            slot: 'actions',
                        },
                    ]"
                >
                    <template #priority="{ item }">
                        <text-field
                            v-model="item.priority"
                            class="mb-0 width-min-70"
                            type="number"
                            :disabled="isSubmitting(item.id)"
                            @blur="handlePriorityChange(item)"
                            @keyup.enter="handlePriorityChange(item)"
                        />

                    </template>

                    <template #icon="{ item }">
                        <img
                            v-if="item.campaign && item.campaign.icon"
                            :src="item.campaign.icon"
                            alt="icon"
                            width="24"
                            height="24"
                        >
                        <img
                            v-else-if="item.icon"
                            :src="item.icon"
                            alt="icon"
                            width="24"
                            height="24"
                        >
                        <div v-else class="icon-placeholder"></div>
                    </template>

                    <template #actions="{ item }">
                        <button-default :disabled="isSubmitting(item.id)" variant="danger" @click="handleActiveClick(item)">
                            {{ $t('remove') }}
                        </button-default>
                    </template>
                </table-default>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { cloneDeep, deburr, orderBy } from 'lodash';
    import numberUtil from '@/utils/number';
    import TextField from '@/components/common/TextField.vue';
    import CheckboxField from '@/components/common/CheckboxField.vue';
    import TableDefault from '@/components/common/TableDefault.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import RowLabeled from '@/components/common/RowLabeled.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import categoryService from '@/services/categoryService';

    export default {
        name: 'PluginCampaignsList',
        components: {
            CheckboxField,
            TextField,
            TableDefault,
            ButtonDefault,
            Multiselect,
            RowLabeled,
        },
        props: {
            pluginId: {
                type: Number,
                required: false,
            },
        },
        data() {
            return {
                activeCampaigns: [],
                availableCampaigns: [],
                error: null,

                filters: {
                    name: '',
                    categories: '',
                },
            };
        },
        computed: {
            filtersParams() {
                const filters = {};
                if (this.filters.name) filters.name = this.filters.name;
                if (this.filters.categories?.length) filters.categories = (this.filters.categories || []).map(item => item.id).join(',');
                return filters;
            },
            loading() {
                return this.fetchActiveCampaignsLoading || this.fetchAvailableCampaignsLoading;
            },
            ...mapGetters({
                getterPlugin: 'plugin/plugin',
                getterActiveCampaigns: 'plugin/activeCampaigns',
                getterAvailableCampaigns: 'plugin/availableCampaigns',
            }),
            ...mapState({
                currentSpaceId: state => state.space.currentSpaceId,
                fetchActiveCampaignsLoading: state => state.plugin.loading.fetchActiveCampaigns,
                fetchAvailableCampaignsLoading: state => state.plugin.loading.fetchAvailableCampaigns,
                createSubmitting: state => state.plugin.loading.createCampaign,
                deleteSubmitting: state => state.plugin.loading.deleteCampaign,
                updateSubmitting: state => state.plugin.loading.updateCampaign,
            }),
        },
        watch: {
            pluginId: {
                handler(pluginId) {
                    if (pluginId) {
                        this.getData();
                    }
                },
                immediate: true,
            },
        },
        methods: {
            async getData() {
                await this.getActiveCampaigns();
                await this.getAvailableCampaigns();
            },
            fetchCategories() {
                return categoryService.getCategories() || [];
            },
            isSubmitting(campaignId) {
                return this.createSubmitting.includes(campaignId) || this.deleteSubmitting.includes(campaignId) || this.updateSubmitting.includes(campaignId);
            },
            handleChangeActive(e, draggable = true) {
                if (e.added) {
                    if (!draggable) {
                        this.activeCampaigns.push({ ...e.added.element, priority: 0 });
                        this.availableCampaigns = this.availableCampaigns.filter(item => item.id !== e.added.element.id);
                    }
                    const campaign = this.activeCampaigns.find(item => item.id === e.added.element.id);
                    if (campaign) {
                        campaign.priority = 0;
                    }
                    this.sortActiveCampaigns();
                    this.createCampaign(e.added.element);
                }
            },
            handleChangeAvailable(e, draggable = true) {
                if (e.added) {
                    if (!draggable) {
                        this.availableCampaigns.push(e.added.element);
                        this.activeCampaigns = this.activeCampaigns.filter(item => item.id !== e.added.element.id);
                    }
                    this.sortAvailableCampaigns();
                    this.deleteCampaign(e.added.element);
                }
            },
            handleActiveClick(campaign, save = true) {
                this.availableCampaigns.push(campaign);
                this.sortAvailableCampaigns();
                this.activeCampaigns = this.activeCampaigns.filter(item => item.id !== campaign.id);
                if (save) {
                    this.deleteCampaign(campaign);
                }
            },
            handleAvailableClick(campaign, priority = 0, save = true) {
                this.activeCampaigns.push({ ...campaign, priority });
                this.sortActiveCampaigns();
                this.availableCampaigns = this.availableCampaigns.filter(item => item.id !== campaign.id);
                if (save) {
                    this.createCampaign(campaign);
                }
            },
            handlePriorityChange(campaign) {
                this.sortActiveCampaigns();
                this.updateCampaign(campaign);
            },
            sortActiveCampaigns() {
                this.activeCampaigns = orderBy(this.activeCampaigns, ['priority', item => deburr(item.name.toLowerCase())], ['desc', 'asc']);
            },
            sortAvailableCampaigns() {
                this.availableCampaigns = orderBy(this.availableCampaigns, item => deburr(item.name.toLowerCase()), 'asc');
            },
            filterAvailableCampaigns(item) {
                // remove active campaigns from list of available
                return !this.activeCampaigns.some(activeItem => activeItem.id === item.id);
            },
            async getActiveCampaigns() {
                try {
                    this.error = null;
                    await this.fetchActiveCampaigns({ pluginId: this.pluginId });
                    if (this.getterActiveCampaigns) {
                        this.activeCampaigns = cloneDeep(this.getterActiveCampaigns);
                        this.sortActiveCampaigns();
                    }
                } catch (e) {
                    this.error = e.message;
                }
            },
            async getAvailableCampaigns() {
                try {
                    this.error = null;
                    await this.fetchAvailableCampaigns({ pluginId: this.pluginId, filters: this.filtersParams, v2: true });
                    if (this.getterAvailableCampaigns) {
                        this.availableCampaigns = cloneDeep(this.getterAvailableCampaigns).filter(this.filterAvailableCampaigns);
                        // this.sortAvailableCampaigns();
                    }
                } catch (e) {
                    this.error = e.message;
                }
            },
            async createCampaign(campaign) {
                try {
                    this.error = null;
                    await this.createCampaignAction({
                        pluginId: this.pluginId,
                        campaign: {
                            campaign: {
                                id: campaign.id,
                                name: campaign.name,
                            },
                            priority: numberUtil.zeroOrInteger(campaign),
                        },
                    });
                } catch (e) {
                    this.error = e.message;
                    this.handleActiveClick(campaign, false);
                }
            },
            async updateCampaign(campaign) {
                const originalCampaign = this.getterActiveCampaigns.find(item => item.id === campaign.id);
                try {
                    if (!originalCampaign || originalCampaign.priority === parseInt(campaign.priority, 10)) {
                        return;
                    }
                    this.error = null;
                    await this.updateCampaignAction({
                        pluginId: this.pluginId,
                        campaign: {
                            campaign: {
                                id: campaign.id,
                                name: campaign.name,
                            },
                            priority: numberUtil.zeroOrInteger(campaign.priority),
                        },
                    });
                } catch (e) {
                    this.error = e.message;
                    const index = this.activeCampaigns.findIndex(item => item.id === campaign.id);
                    if (originalCampaign && index !== -1) {
                        this.activeCampaigns[index].priority = originalCampaign.priority;
                        this.sortActiveCampaigns();
                    }
                }
            },
            async deleteCampaign(campaign) {
                try {
                    this.error = null;
                    await this.deleteCampaignAction({
                        pluginId: this.pluginId,
                        campaign: {
                            campaign: {
                                id: campaign.id,
                                name: campaign.name,
                            },
                            priority: numberUtil.zeroOrInteger(campaign),
                        },
                    });
                } catch (e) {
                    this.error = e.message;
                    this.handleAvailableClick(campaign, campaign.priority, false);
                }
            },
            ...mapActions({
                fetchActiveCampaigns: 'plugin/fetchActiveCampaigns',
                fetchAvailableCampaigns: 'plugin/fetchAvailableCampaigns',
                createCampaignAction: 'plugin/createCampaign',
                updateCampaignAction: 'plugin/updateCampaign',
                deleteCampaignAction: 'plugin/deleteCampaign',
            }),
        },
    };
</script>

<style lang="scss" scoped>
    .icon-placeholder {
        width: 24px;
        height: 24px;
        display: inline-block;
    }

    .list-container {
        display: flex;

        @media (min-width: 600px) {
            display: none;
        }

        .priority-input {
            margin: 0 0 0 10px;
            width: 75px;
        }
    }

    .campaign-switch {
        margin-bottom: 0;
        word-break: break-word;
    }

    .container {
        padding: 10px;
        display: flex;
        flex-direction: row;
    }

    .desktop-container {
        display: none;
        flex-wrap: nowrap;
        flex-direction: row;
        width: 100%;
        gap: 30px;

        @media (min-width: 600px) {
            display: flex;
        }
    }

    .available-container {
        width: 60%;
        margin-right: 10px;
    }

    .max-w-prose {
        max-width: 400px;
    }

    .active-container {
        width: 40%;
        margin-left: 10px;
    }

    .drag-box {
        border-radius: 5px;
        min-height: 100px;
        overflow-y: auto;
        flex: 1;
    }

    .drag-item {
        cursor: move;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        background-color: #f6f6f7;
        border: 1px solid #e2e7f1;
        margin-bottom: 5px;
        border-radius: 3px;

        &:hover {
            background: $bg-light-color;
        }
    }

    .drag-item--disabled {
        pointer-events: none;
        opacity: 0.75;
    }

    .campaign-name {
        max-width: 70%;
    }

    .priority-input {
        margin: 0 0 0 10px;
        width: 75px;
    }
</style>
