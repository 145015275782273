<template>
    <row-labeled :row="row">
        <template v-if="label" #label>
            <span>{{ label }} <span v-if="isRequired" class="text-danger">*</span></span>
        </template>
        <template #content="{ htmUniqueId }">
            <multiselect
                :id="htmUniqueId"
                ref="multiselect"
                v-model="model"
                :options="_options"
                :loading="loading"
                :disabled="disabled || loading"
                :placeholder="placeholder || $t('selectField.placeholder')"
                :select-label="$t('selectField.selectLabel')"
                :selected-label="$t('selectField.selectedLabel')"
                :deselect-label="allowEmpty ? $t('selectField.deselectLabel') : $t('selectField.selectedLabel')"
                :deselect-group-label="$t('selectField.deselectGroupLabel')"
                :limit-text="limitText"
                :allow-empty="allowEmpty"
                track-by="value"
                label="text"
                :class="{ 'is-invalid': hasValidationErrors }"
                @close="validate"
                @open="repositionDropdown"
            >
                <template slot="noResult">
                    {{ $t('selectField.noResult') }}
                </template>
                <template slot="noOptions">
                    {{ $t('selectField.noOptions') }}
                </template>
            </multiselect>

            <div v-if="hasValidationErrors" class="invalid-feedback">
                {{ validationErrors.join(' ') }}
            </div>
        </template>
    </row-labeled>
</template>

<script>
    /* eslint-disable no-underscore-dangle */
    import Multiselect from 'vue-multiselect';
    import RowLabeled from './RowLabeled.vue';
    import validatableFieldMixin from '../../mixins/validatableFieldMixin';

    function validateOption(option) {
        if (option.value === undefined || option.text === undefined) {
            const optionAsString = JSON.stringify(option, null, 4);
            console.error(`${optionAsString}\n option is invalid: value and text must be defined`);
            return false;
        }

        return true;
    }

    export default {
        name: 'SelectField',
        mixins: [validatableFieldMixin],
        components: {
            RowLabeled,
            Multiselect,
        },
        props: {
            /**
             * @model
             */
            value: {
                type: [String, Number, Array, Boolean],
            },
            options: {
                type: Array,
                required: true,
                validator(options) {
                    return options.every(validateOption);
                },
            },
            label: {
                type: String,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            /**
             * Can value be deselected
             */
            clearable: {
                type: Boolean,
                default: true,
            },
            /**
             * If specified, option with value `undefined` and given text
             * is prepended to the options list
             */
            emptyOption: {
                type: [Boolean, String],
            },
            placeholder: {
                type: String,
            },
            row: {
                type: Boolean,
            },
            appendToBody: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            model: {
                get() {
                    return this._options.find(option => option.value === this.value);
                },
                set(selection) {
                    return this.$emit('input', selection && selection.value);
                },
            },
            _options() {
                if (this.emptyOption) {
                    return [
                        {
                            value: undefined,
                            text: this.emptyOption === true ? this.$t('all') : this.emptyOption,
                        },
                        ...this.options,
                    ];
                }
                return this.options;
            },
            allowEmpty() {
                return this.clearable && !this.emptyOption;
            },
        },
        methods: {
            limitText(count) {
                return this.$t('selectField.limitText', { count });
            },
            repositionDropdown() {
                if (this.appendToBody) {
                    const ref = this.$refs.multiselect;
                    const { top, height, left } = ref.$el.getBoundingClientRect();
                    if (ref) {
                        ref.$refs.list.style.position = 'fixed';
                        ref.$refs.list.style.bottom = 'auto';
                        ref.$refs.list.style.top = `${top + height}px`;
                        ref.$refs.list.style.left = `${left}px`;
                        ref.$refs.list.style.width = 'auto';
                    }
                }
            },
        },
        mounted() {
            window.addEventListener('scroll', this.repositionDropdown);
        },
        destroyed() {
            window.removeEventListener('scroll', this.repositionDropdown);
        },
    };
</script>
