<template>
    <div class="badge" :class="statusClass">
        {{ label }}
    </div>
</template>



<script>
    export default {
        name: 'ReportSubscriptionsStatusBadge',
        props: {
            status: {
                type: [String, Number],
                required: true,
            },
            label: {
                type: String,
                default: null,
            },
        },
        computed: {
            statusClass() {
                if (this.status === 'active') return 'badge-soft-success';
                if (this.status === 'inactive') return 'badge-soft-danger';
                if (this.status === 'canceled') return 'badge-soft-warning';

                return 'badge-soft-dark';
            },
        },
    };
</script>
