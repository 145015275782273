import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';
import { i18n } from '@/i18n/i18n';

const chartFormatter = data => {
    if (!data?.chart) return data;
    return data.chart
        .map(item => {
            const keys = Object.keys(item);
            const dateKeyIndex = keys.findIndex(key => key === 'date');
            if (dateKeyIndex !== -1) {
                keys.splice(dateKeyIndex, 1);
                const valueKey = keys[0];
                return [new Date(item.date).getTime(), item[valueKey]];
            }
            return item;
        })
        .filter(item => item[1] !== undefined);
};

const availabilityChartFormatter = data => {
    if (!data?.chart) return data;
    return data.chart.map(item => ({
        name: i18n.t('available'),
        data: item.segments.map(segment => ({
            x: i18n.t('availability'),
            y: [segment.start, segment.end],
            fillColor: '#43d39e',
        })),
        color: '#43d39e',
    }));
};

export const translateOfferType = type => {
    switch (type) {
        case 'content_book':
            return i18n.t('typesOfOffers.content_book');
        case 'content_movie':
            return i18n.t('typesOfOffers.content_movie');
        case 'content_game':
            return i18n.t('typesOfOffers.content_game');
        case 'content_music':
            return i18n.t('typesOfOffers.content_music');
        case 'product':
            return i18n.t('typesOfOffers.product');
        default:
            return '-';
    }
};

export const ENDPOINTS = deepFreeze({
    OFFER: {
        URI: () => '/api/v1/offers/{{offerId?}}',
        methods: ['get', 'patch'],
        default: null,
        formatter: data => ({ ...data, typeTranslated: translateOfferType(data.type) }),
        cacheToClear: ['/price-chart', '/availability-chart', '/api/v1/offers/{{offerId}}$', '/api/v1/offers$', '/api/v1/campaigns/\\d+/offers\\?'],
        cache: false,
    },
    AVAILABILITY_CHART: {
        URI: () => '/api/v1/offers/{{offerId}}/availability-chart',
        methods: ['get'],
        formatter: availabilityChartFormatter,
        default: [],
    },
    PRICE_CHART: {
        URI: () => '/api/v1/offers/{{offerId}}/price-chart',
        methods: ['get'],
        formatter: chartFormatter,
        default: [],
    },
    QUANTITY_CHART: {
        URI: () => '/api/v1/offers/{{offerId}}/quantity-chart',
        methods: ['get'],
        formatter: chartFormatter,
        default: [],
    },
    TYPES_AND_FORMATS: {
        URI: () => '/api/v1/offer-types-and-formats',
        methods: ['get'],
        formatter: data => data.items,
        default: [],
    },
    PRODUCT: {
        URI: () => '/api/v1/offers/product',
        methods: ['delete'],
        default: [],
        cacheToClear: ['/api/v1/offers/{{offerId}}$', '/api/v1/offers$'],
    },
    FEED_STATUSES: {
        URI: () => '/api/v1/offer-feed-statuses',
        methods: ['get'],
        default: [],
        formatter: data => data?.items,
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
