/* eslint-disable prettier-vue/prettier */
/* eslint-disable max-len */
export default {
    validator: {
        accepted: 'The "{attribute}" must be accepted.',
        alpha_dash: 'The specified value may only contain letters, numbers, dashes and underscores.',
        alpha_num: 'The specified value may only contain letters and numbers.',
        alpha: 'The specified value may only contain letters.',
        between: '@:validator.type_between.numeric',
        boolean: 'The specified value field must be true or false.',
        confirmed: 'The "{attribute}" confirmation does not match.',
        date_after_or_equal: 'The date must be a date after or equal to {date}.',
        date_after: 'The date must be a date after {date}.',
        date_before_or_equal: 'The date must be a date before or equal to {date}.',
        date_before: 'The date must be a date before {date}.',
        date_equals: 'The date must be a date equal to {date}.',
        date_format: 'The date does not match the format {format}.',
        date: 'The specified value is not a valid date.',
        decimal: 'The specified value must be a number.',
        different: 'The "{attribute}" and "{other}" must be different.',
        digits_between: 'The specified value must be between {min} and {max} digits.',
        digits: 'The specified value must be {digits} digits.',
        dimensions: 'The "{attribute}" has invalid image dimensions.',
        email: 'The e-mail format is incorrect.',
        ends_with: 'The specified value must end with one of the following: {values}.',
        file: 'The "{attribute}" must be a file.',
        hexColor: 'The specified value must be in hexadecimal format (e.g. #ffffff)',
        image: 'The "{attribute}" must be an image.',
        integer: 'The specified value must be an integer.',
        ip: 'The specified value must be a valid IP address.',
        ipAddress: '@:validator.ip',
        ipv4: 'The specified value must be a valid IPv4 address.',
        ipv6: 'The specified value must be a valid IPv6 address.',
        json: 'The specified value must be a valid JSON string.',
        maxLength: '@:validator.max.string',
        mimes: 'The specified value must be a file of type: {values}.',
        mimetypes: 'The specified value must be a file of type: {values}.',
        minLength: '@:validator.min.string',
        minValue: '@:validator.min.numeric',
        numeric: 'The specified value must be a number.',
        password: 'The password is incorrect.',
        required_if: 'The field is required when the value of the "{other}" field is provided.',
        required: 'The field is required.',
        same: 'The "{attribute}" and "{other}" must match.',
        sameAs: '@:validator.same',
        starts_with: 'The specified value must start with one of the following: {values}.',
        string: 'The specified value must be a string.',
        timezone: 'The specified value must be a valid zone.',
        tooGeneralUrl: 'The address provided is too general. Enter the full url of your website or social media profile.',
        unique: 'The specified value has already been taken.',
        url: 'The specified value is not a valid url.',
        valid: 'The field has a valid value.',
        min: {
            numeric: 'The specified value must be at least {min}.',
            file: 'The file size must be at least {min} kilobytes.',
            string: 'The length of the text must be at least {min} characters.',
        },
        max: {
            numeric: 'The specified value may not be greater than {max}.',
            file: 'The file size may not be greater than {max} kilobytes.',
            string: 'The length of the text may not be greater than {max} characters.',
        },
        lt: {
            numeric: 'The specified value must be less than {value}.',
            file: 'The file size must be less than {value} kilobytes.',
            string: 'The length of the text must be less than {value} characters.',
        },
        lte: {
            numeric: 'The specified value must be less than or equal {value}.',
            file: 'The file size must be less than or equal {value} kilobytes.',
            string: 'The length of the text must be less than or equal {value} characters.',
        },
        gt: {
            numeric: 'The specified value must be greater than {value}.',
            file: 'The file size must be greater than {value} kilobytes.',
            string: 'The length of the text must be greater than {value} characters.',
        },
        gte: {
            numeric: 'The specified value must be greater than or equal {value}.',
            file: 'The file size must be greater than or equal {value} kilobytes.',
            string: 'The length of the text must be greater than or equal {value} characters.',
        },
        type_between: {
            numeric: 'The specified value must be between {min} and {max}.',
            file: 'The file size must be between {min} and {max} kilobytes.',
            string: 'The length of the text must be between {min} and {max} characters.',
        },
        size: {
            numeric: 'The specified value must be {size}.',
            file: 'The file size must be {size} kilobytes.',
            string: 'The length of the text must be {size} characters.',
        },
    },
    validation: {
        required: 'This field is required.',
        email: 'Please enter a valid email address.',
        url: 'Please enter a valid URL.',
        sameAs: 'Please enter the same value again.',
        regex: 'Invalid format.',
        numeric: 'Please enter a valid number.',
        maxLength: 'Please enter up to {max} characters',
        hexColor: 'Please provide the correct HEX color (e.g. #000000).',
        bankAccount: 'Please enter a valid bank account number.',
    },
    error: {
        default: 'Something went wrong. Please try again or contact support.',
        network: 'Unable to connect to the server.',
        notFound: 'Unable to connect to the server.',
        unauthorized: 'No required permissions.',
        ERROR: '@:error.default',
        BAD_CREDENTIALS: 'Incorrect credentials.',
        BAD_REQUEST: 'Bad Request.',
        INVALID_REQUEST_DATA: 'Invalid data.',
        AMOUNT_BELOW_MINIMUM: 'Amount below minimum.',
        endpoint: 'Endpoint does not exist.',
        endpointParams: 'The required parameters were not provided.',
    },
    api_errors: {
        bad_credentials: {
            base: 'Incorrect credentials.',
        },
        conflict: {
            transaction: 'Transaction already verified.',
        },
        not_found: {
            base: 'Not found',
            brand: "Brand doesn't exist",
            buybox: "Widget doesn't exist",
            campaign: "Campaign doesn't exist",
            campaign_api_token: "Campaign doesn't have API Token. Generate one first.",
            campaign_not_related_buybox: "Campaign isn't related to Buybox!",
            campaign_space: "Relation doesn't exist!",
            category: "Category doesn't exist",
            commission: "Commission doesn't exist",
            cookie: "Cookie doesn't exist",
            document: "Document doesn't exist",
            manufacturer: "Manufacturer doesn't exist",
            offer: "Offer doesn't exist",
            offer_campaign: "Offer campaign doesn't exist",
            offer_feed: "Offer feed doesn't exist",
            product: "Product doesn't exist",
            report: "Report doesn't exist",
            space: "Space doesn't exist",
            space_no_buybox: 'Space has no Buybox!',
            space_no_category: 'Space has no category!',
            space_no_token: "Space doesn't have API Token",
            space_token: 'Unable to find space',
            subscription: "Subscription doesn't exist",
            token: 'Token is expired!',
            transaction: "Transaction doesn't exist",
            transaction_invalid_amount: 'Invalid transaction amount!',
            user: "User doesn't exist",
        },
        unprocessable_entity: {
            amount_below_minimum: 'Amount below minimum',
            campaign_assign_buybox: "Campaign can't be assign to Buybox",
            campaign_assign_commission: "Commission isn't assigned to Campaign!",
            email_not_confirmed: 'Email is not confirmed!',
            locked_user: 'User Account is locked!',
            missing_personal_data: 'Missing personal data',
            not_first_request_of_month: 'You can only request a payout once a month!',
            space_accept: "You can't accept that Space!",
            space_reject: "You can't reject that Space!",
            space_subscription: 'Space has active subscription',
            report_not_generated: "Report isn't generated!",
            user: {
                email: {
                    exist: 'User with username/email already exists!',
                },
                not: {
                    advertiser: 'User is not an advertiser!',
                    publisher: 'User is not an publisher!',
                },
            },
            wrong_document_type: 'Wrong document type',
        },
        access_denied: {
            base: 'Access Denied.',
            feed: "You can't create feed for that Buybox!",
            feature_disabled: 'Feature disabled',
            functionality_inactive: "Functionality isn't active!",
            token: 'Token is expired!',
        },
        http: {
            bi_api: 'BI API Problem',
            database: 'Database error',
            not_implemented: 'Not Implemented',
        },
        bad_request: {
            wrong_xml_format: 'Wrong XML format!',
            not_xml: 'Not XML!',
            amount_gross: "'gross' can't be negative!",
            amount_net: "'net' can't be negative!",
            amount_price_limit: 'Price bottom limit should not exceed the top limit',
            campaign_id: 'Campaign ID not set!',
            cookie: 'Cookie ID not set!',
            email: "Email can't be empty!",
            image_url: 'Malformed image URL',
            order: 'Order ID not set!',
            product: {
                commission: {
                    id: 'Product commission ID not set!',
                },
                gross: 'Product gross value not set!',
                id: 'Product ID not set!',
                net: 'Product net value not set!',
                quantity: 'Product quantity not set!',
                specification: 'Incorrect products specification!',
            },
            quantity: {
                value: 'Quantity has negative value!',
            },
            query: 'Empty query',
            refresh_token: 'Missing search parameter',
            required_parameters: 'Required parameters are missing!',
            response_format: 'Unsupported response format!',
            search: 'Missing search parameter',
            space_id: 'Space ID not set!',
            url_missing: 'Missing url parameter',
        },
        form: {
            should_be_false: 'This value should be false.',
            should_be_true: 'This value should be true.',
            should_be_type: 'This value should be of type {type}.',
            should_be_blank: 'This value should be blank.',
            select_wrong_choice: 'The value you selected is not a valid choice.',
            select_wrong_min_limit: 'You must select at least {limit}.',
            select_wrong_max_limit: 'You must select at most {limit}.',
            wrong_values: 'One or more of the given values is invalid.',
            field_not_expected: 'This field was not expected.',
            field_missing: 'This field is missing.',
            not_valid_date: 'This value is not a valid date.',
            not_valid_datetime: 'This value is not the correct date and time.',
            not_valid_email: 'This value is not a valid email address.',
            file_not_found: 'The file could not be found.',
            file_not_readable: 'The file is not readable.',
            file_not_readable_too_large: 'The file is too large ({size} {suffix}). Allowed maximum size is {limit} {suffix}.',
            file_invalid_mimetype: 'The mime type of the file is invalid ({type}). Allowed mime types are {types}.',
            should_be_limit_max: 'This value should be {limit} or less.',
            should_be_long_max: 'This value is too long. It should have {limit} characters or less.',
            should_be_limit_min: 'This value should be {limit} or more.',
            should_be_long_min: 'This value is too short. It should have {limit} characters or more.',
            not_blank: 'This value should not be blank.',
            not_null: 'This value should not be null.',
            null: 'This value should be null.',
            not_valid: 'This value is not valid.',
            not_valid_time: 'This value is not a valid time.',
            not_valid_url: 'This value is not a valid URL.',
            two_values_equal: 'The two values should be equal.',
            file_large_limit: 'The file is too large. Allowed maximum size is {limit} {suffix}.',
            file_large: 'The file is too large.',
            file_not_uploaded: 'The file could not be uploaded.',
            not_valid_number: 'This value should be a valid number.',
            not_valid_image: 'This file is not a valid image.',
            not_valid_ip: 'This is not a valid IP address.',
            not_valid_language: 'This value is not a valid language.',
            not_valid_locale: 'This value is not a valid locale.',
            not_valid_country: 'This value is not a valid country.',
            already_used: 'This value is already used.',
            image_size_not_detected: 'The size of the image could not be detected.',
            image_width_too_big: 'The image width is too big ({width}px). Allowed maximum width is {max_width}px.',
            image_width_too_small: 'The image width is too small ({width}px). Minimum width expected is {min_width}px.',
            image_height_too_big: 'The image height is too big ({height}px). Allowed maximum height is {max_height}px.',
            image_height_too_small: 'The image height is too small ({height}px). Minimum height expected is {min_height}px.',
            should_be_user_current_password: "This value should be the user's current password.",
            should_have_exactly_limit: 'This value should have exactly {limit} characters.',
            file_only_partially_uploaded: 'The file was only partially uploaded.',
            file_no_uploaded: 'No file was uploaded.',
            no_temporary_folder: 'No temporary folder was configured in php.ini.',
            temporary_cant_write: 'Cannot write temporary file to disk.',
            PHP_extension_caused_upload_fail: 'A PHP extension caused the upload to fail.',
            should_contain_max: 'This collection should contain {limit} elements or more.',
            should_contain_min: 'This collection should contain {limit} elements or less.',
            should_contain_exactly: 'This collection should contain exactly {limit} elements.',
            not_valid_card_number: 'Invalid card number.',
            not_valid_card_number_or_type: 'Unsupported card type or invalid card number.',
            not_valid_IBAN: 'This is not a valid International Bank Account Number (IBAN).',
            'not_valid_ISBN-10': 'This value is not a valid ISBN-10.',
            'not_valid_ISBN-13': 'This value is not a valid ISBN-13.',
            'not_valid_ISBN-10_SBN-13': 'This value is neither a valid ISBN-10 nor a valid ISBN-13.',
            not_valid_ISSN: 'This value is not a valid ISSN.',
            not_valid_currency: 'This value is not a valid currency.',
            should_be_equal: 'This value should be equal to {compared_value}.',
            should_be_greater: 'This value should be greater than {compared_value}.',
            should_be_greater_or_equal: 'This value should be greater than or equal to {compared_value}.',
            should_be_identical: 'This value should be identical to {compared_value_type} {compared_value}.',
            should_be_less: 'This value should be less than {compared_value}.',
            should_be_less_or_equal: 'This value should be less than or equal to {compared_value}.',
            should_not_be_qual: 'This value should not be equal to {compared_value}.',
            should_not_be_identical: 'This value should not be identical to {compared_value_type} {compared_value}.',
            image_ratio_too_big: 'The image ratio is too big ({ratio}). Allowed maximum ratio is {max_ratio}.',
            image_ratio_too_small: 'The image ratio is too small ({ratio}). Minimum ratio expected is {min_ratio}.',
            image_square: 'The image is square ({width}x{height}px). Square images are not allowed.',
            image_landscape_oriented: 'The image is landscape oriented ({width}x{height}px). Landscape oriented images are not allowed.',
            image_portrait_oriented: 'The image is portrait oriented ({width}x{height}px). Portrait oriented images are not allowed.',
            not_empty: 'An empty file is not allowed.',
            wrong_host: 'The host could not be resolved.',
            wrong_charset: 'This value does not match the expected {charset} charset.',
            not_valid_BIC: 'This is not a valid Business Identifier Code (BIC).',
            error: 'Error',
            not_valid_UUID: 'This is not a valid UUID.',
            should_be_multiple: 'This value should be a multiple of {compared_value}.',
            BIC_not_associated_IBAN: 'This Business Identifier Code (BIC) is not associated with IBAN {iban}.',
            should_be_JSON: 'This value should be valid JSON.',
            collection_should_contain_unique_elements: 'This collection should contain only unique elements.',
            should_be_positive: 'This value should be positive.',
            should_be_positive_or_zero: 'This value should be either positive or zero.',
            should_be_negative: 'This value should be negative.',
            should_be_negative_or_zero: 'This value should be either negative or zero.',
            not_valid_timezone: 'This value is not a valid timezone.',
            password_leaked_use_another: 'This password has been leaked in a data breach, it must not be used. Please use another password.',
            should_be_between: 'This value should be between {min} and {max}.',
            not_valid_hostname: 'This value is not a valid hostname.',
            should_be_multiply: 'The number of elements in this collection should be a multiple of {compared_value}.',
            should_satisfy_following_constraints: 'This value should satisfy at least one of the following constraints',
            elements_should_have_own_constraints: 'Each element of this collection should satisfy its own set of constraints.',
            not_valid_ISIN: 'This value is not a valid International Securities Identification Number (ISIN).',
            should_not_contain_extra_fields: 'This form should not contain extra fields.',
            not_valid_EAN: 'This value is not a valid European Article Number (EAN).',
            not_valid_regexp: "The value isn't a valid regexp.",
            value_already_used: 'This value is already used.',
        },
        form_type: {
            birthdate: 'Please provide the correct date of birth.',
            checkbox: 'The checkbox has an invalid value.',
            choice: 'The selected choice is invalid.',
            collection: 'The collection is invalid.',
            color: 'Please select a valid color.',
            country: 'Please select a valid country.',
            currency: 'Please select a valid currency.',
            date_interval: 'Please choose a valid date interval.',
            date_time: 'Please enter a valid date and time.',
            date: 'Please enter a valid date.',
            email: 'Please enter a valid email address.',
            file: 'Please select a valid file.',
            form: 'This value is not valid.',
            hidden: 'The hidden field is invalid.',
            integer: 'Please enter an integer.',
            language: 'Please select a valid language.',
            locale: 'Please select a valid locale.',
            money: 'Please enter a valid money amount.',
            password: 'The password is invalid.',
            percentage: 'Please enter a percentage value.',
            radio: 'Please select a valid option.',
            range: 'Please choose a valid range.',
            repeated: 'The values do not match.',
            search: 'Please enter a valid search term.',
            time: 'Please enter a valid time.',
            timezone: 'Please select a valid timezone.',
            ulid: 'Please enter a valid ULID.',
            url: 'Please enter a valid URL.',
            week: 'Please enter a valid week.',
        },
        producer_product: {
            ean_already_exists: 'EAN number already exists in product catalog.',
            sku_already_exists: 'SKU number already exists in product catalog.',
        },
        products_used_brand: 'There are products assigned to this brand',
        category_parent: "You can't remove parent category",
        products_used_category: 'There are products assigned to this category',
        brands_used_manufacturer: 'There are brand assigned to this manufacturer',
        variants_used_manufacturer: 'There are variant assigned to this manufacturer',
        variants_used_product: 'There are variant assigned to this product',
    },
    language: {
        pl: 'Polish',
        en: 'English',
        lt: 'Lithuanian',
        lv: 'Latvian',
        et: 'Estonian',
        de: 'German',
        dk: 'Danish',
        fi: 'Finnish',
        fr: 'French',
        nl: 'Dutch',
    },
    timeShort: {
        days: 'd',
        hours: 'h',
        minutes: 'm',
        seconds: 's',
        quarter: 'Q',
    },
    role: {
        producer: 'Producer',
        widgeter: 'Publisher',
        advertiser: 'Advertiser',
        admin: 'Admin',
    },
    selectField: {
        placeholder: 'Type a name to search for',
        selectedLabel: '✔',
        selectGroupLabel: '✚',
        selectLabel: '✚',
        deselectLabel: '✖',
        deselectGroupLabel: '✖',
        limitText: 'and {count} more',
        noResult: 'No results',
        noOptions: 'List is empty',
        searchNotFoundText: 'No elements found. Consider changing the search query.',
        noOptionsText: 'List is empty.',
        searchPlaceholder: 'Enter to search',
        selectedOptions: 'Selected options',
        previouslySelectedOptions: 'Previously selected',
        minCharToSearchText: 'Type min. {min} characters',
        optionsLoadingText: 'Searching...',
    },
    fileField: {
        browseText: 'Add',
        dropPlaceholder: 'Drop file here',
        noDropPlaceholder: 'Not allowed',
        placeholder: 'Add file',
    },
    sidebar: {
        dashboard: '@:dashboard.title',
        widget: 'Widget',
        widgetSettings: '@:widgetSettings.title',
        generator: '@:generator.title',
        textLinks: '@:textLinks.title',
        productLandingPage: 'Product LP',
        productLandingPageGenerator: 'Generator',
        productLandingPageSettings: 'Settings',
        reports: 'Reports',
        statByDay: '@:statByDay.title',
        statByMonth: '@:statByMonth.title',
        transactions: '@:transactions.title',
        reportsPages: '@:reportsPages.title',
        reportsShops: '@:reportsShops.title',
        reportsProducts: '@:reportsProducts.title',
        settings: 'Settings',
        pages: '@:pages.title',
        shops: 'Shops',
        finance: '@:finance.title',
        api: '@:api.title',
        integrations: '@:widgeterSettingsIntegrations.title',
        webhook: 'Webhook',
        webpagesPublishers: '@:webpagesPublishers',
        productImport: '@:productImport',
        shopPresentation: '@:shopPresentation',
        shopLocations: '@:shopLocations',
        offersCatalog: '@:offersCatalog',
        catalog: 'Catalog',
        catalogProducts: 'Products',
        catalogManufacturers: 'Manufacturers',
        catalogBrands: 'Brands',
        catalogCategories: 'Categories',
        catalogModeration: '@:moderation',
        reportsConversion: '@:conversionReport',
        reportsIntegrationStatus: '@:integrationStatus',
        reportPages: 'Pages report',
        reportShops: 'Shops report',
        reportProducts: 'Products report',
        gdziekupicTo: 'Gdziekupic.to',
        users: 'Users',
        verifications: 'Verifications',
        webpagesAdmin: '@:webpages',
        tipbox: 'tip.BOX',
        offers: '@:offerFinder',
        settlements: 'Settlements',
        tipSettlements: 'Tip settlements',
        campaigns: '@:campaigns',
        producerProducts: '@:producerProducts',
        producerProductsImport: 'Import',
        advertiserSettlements: 'Store billing report',
        advertiserSettlementsList: 'List of invoices',
        productSearch: 'Product search',
        transactionsImport: 'Transaction Import',
        transactions2: 'Transactions',
        transactionValidation: 'Transaction validation',
        settlementsWithPublishers: 'Settlements with publishers',
        feedImportSummary: 'Summary of feed import',
        tipboxLanding: 'Your tipbox.link',
        subscriptions: 'subscriptions',
        bbAffiliateProgram: 'BB Affiliate Program',
        stats: 'Stats',
        tips: 'Tips',
        verification: 'Verification',
        widgetTipbox: 'Widget tip.BOX',
        tipboxTips: 'Payments',
        tipboxSettlements: 'Settlements',
        tipboxSettings: 'Widget and settings',
        tipboxLink: 'Your tipbox.link profile',
        transactionCorrection: 'Transaction correction',
    },
    login: {
        title: 'Log in',
        welcome: 'Welcome back!',
        hint: 'Enter your email address and password to access BUY.BOX panel.',
        rememberMe: 'Remember me',
        resetPassword: 'Forgot your password?',
        enterLogin: 'Enter login',
        enterPassword: 'Enter your password',
        password: 'Password',
        submit: 'Log in',
        registerBefore: "You don't have an account yet?",
        registerLink: 'Register',
        registerUrl: 'https://getbuybox.com/zarejestruj-sie/',
    },
    loginAs: {
        title: 'Logging to BUY.BOX account',
        missingToken: 'No authorization token.',
    },
    adminPanelButton: {
        label: 'Back to admin panel',
    },
    acceptTerms: {
        title: 'Amendment to the regulations',
        description: 'We would like to inform you that some of the rules of our platform have changed. To proceed further you must accept the {link}.',
        descriptionLink: 'new rules',
        accept: 'Accept',
        cancel: 'Cancel',
        url: 'terms',
    },
    dashboard: {
        title: 'Dashboard',
        impressions: 'Impressions',
        clicks: 'Clicks',
        transactions: 'Transactions',
        commission: 'Commission',
        yourCommission: 'Your commission',
        newCommission: 'New',
        confirmedCommission: 'Confirmed',
        toWithdraw: 'To withdraw',
        withdraw: 'Withdraw',
        overview: 'Overview',
        newCampaigns: 'New campaigns',
        recentTransactions: 'Recent Transactions',
        campaign: 'Shop',
        space: 'Website',
        category: 'Category',
        amount: 'Amount',
        rangeLast7Days: 'Last 7 days',
        rangeCurrentWeek: 'Current week',
        rangeLast30days: 'Last 30 days',
        rangeCurrentMonth: 'Current month',
        yourBestPages: 'Your best websites',
        yourBestShops: 'Your best shops',
        yourBestProducts: 'Your best products',
        product: 'Product',
        unitsSold: 'Units sold',
        to: 'to',
        salesValue: 'The value of sales',
        ourCommission: 'Our commission',
        advertiserCommission: 'Advertiser commission',
        newPublishers: 'New publishers',
        newSpaces: 'New spaces',
        lastAddedSpaces: 'Recently added spaces',
    },
    widgeterReportsShops: {
        title: 'Shops report',
        campaigns: 'Shops statistics',
        campaign: 'Shop',
        impressions: 'Impressions',
        clicks: 'Clicks',
        transactions: 'Transactions',
        commission: 'Commission',
        transactionAmount: 'Transaction Amount',
    },
    widgeterReportsPages: {
        title: 'Pages report',
        pageStats: 'Site statistics',
        space: 'Page',
        impressions: 'Widget views',
        clicks: 'Clicks',
        ctr: 'CTR',
        transactions: 'Transactions',
        conversion: 'Conversion',
        transactionAmount: 'Transaction amount',
        commission: 'Commission',
    },
    widgeterDropdown: {
        accountSettings: 'Account settings',
        logout: 'Logout',
    },
    resetPassword: {
        title: 'Reset password',
        description: 'Enter the e-mail address to start the password reset process.',
        email: 'E-mail',
        submit: 'Next',
        back: 'Back to',
        backName: 'login page',
        success: 'An email has been sent to your email address, {email}. Follow the directions in the email to reset your password.',
    },
    resetPasswordComplete: {
        title: 'New password',
        description: 'Enter new password to complete the password reset process.',
        password: 'Password',
        confirmPassword: 'Confirm password',
        submit: 'Save',
        back: 'Back to',
        backName: 'login page',
        success: 'New password has been set. You can now login to your account using it.',
    },
    authSidebar: {
        title: 'Earn with BUY.BOX',
        description: 'Try a free widget for your blog or website',
    },
    accountSettings: {
        title: 'Account settings',
    },
    accountSettingsPersonal: {
        title: 'Personal settings',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'E-mail',
        phone: 'Phone',
        sectionNotifications: 'Notifications',
        notifications: 'Notifications package',
        sectionLanguage: 'Language',
        language: 'Language',
        submit: '@:save',
        saveSuccess: 'Updated personal settings.',
    },
    accountSettingsAddress: {
        title: 'Address settings',
        companyAccount: 'Company account',
        companyName: 'Company name',
        companyNip: 'VAT number',
        street: 'Street',
        streetNumber: 'Street number',
        apartmentNumber: 'Apartment number',
        city: 'City',
        postcode: 'Post code',
        country: 'Country',
        bankAccount: 'Bank account',
        submit: '@:save',
        saveSuccess: 'Updated address settings.',
    },
    accountSettingsPassword: {
        title: 'Password settings',
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmPassword: 'Confirm new password',
        submit: '@:save',
        saveSuccess: 'Changed password.',
    },
    accountSettingsNotifications: {
        title: 'Notifications settings',
        news: 'News and important information',
        isNotification: 'News and important information',
        programs: 'New shops',
        promotions: 'Promo codes and deals',
        display: 'Banner ads',
        tips: 'Tips',
        submit: '@:save',
        saveSuccess: 'Updated notification settings.',
        notifications: 'I want to receive e-mail notifications',
        isLocked: 'Lock',
        demo: 'Demo',
    },
    tipboxLandingSettings: {
        title: 'Your tipbox.link',
        help: 'See how to use tipbox.link',
        seeProfile: 'See your profile',
        profileName: 'Profile name',
        description: 'Profile description',
        thanks: 'Thank you message',
        amounts: 'Amounts',
        yourLinks: 'Your links',
        social: 'Type',
        addSocial: 'Add another link',
        profileSettings: 'Your profile settings',
        slug: 'Profile link',
        color: 'Color',
        url: 'URL address',
        icon: 'Icon',
        gaId: 'Google Analytics (optional)',
        profileSettingsDescription: 'If you want to better adjust the appearance and operation of the widget to your site and requirements, add a new configuration. For each page, you can add multiple configurations and use them in different places.',
    },
    widgetSettings: {
        title: 'Configurations',
        availableShops: 'Available shops',
        edit: 'Edit Name',
        save: 'Save',
        activeShops: 'Active shops',
        commisions: 'Commissions',
        priority: 'Priority',
        title2: 'Widget configurations',
        description: 'Select the widget you want to configure.',
        tabs: {
            appearance: 'Appearance',
            campaigns: 'Shops',
        },
        defaultFormat: 'Default format',
        avaliableShops: 'Available shops',
        addToWidget: 'Add to widget',
        colors: 'Colors',
        configuration: 'Configuration',
        goBack: 'Back to widget list',
        configurations: 'Configurations',
        generateConfiguration: 'Generate configuration',
        details: 'Details',
        default: 'Default',
        setAsDefault: 'Set as default',
        remove: 'Remove',
        addConfiguration: 'Add configuration',
        noConfigurations: 'No configurations',
        shopName: 'Shop name',
        sortType: 'Sort order',
        productDetails: 'Product details',
        hideProduct: 'Hide product details',
        all: 'All',
        appearanceSubheader: 'Match widget design to your website',
        tabsTitle: 'Tabs',
        showTabs: 'Show tabs',
        offersList: 'Offers list',
        hideTabs: 'Hide tabs',
        tabDefault: 'Default tab',
        rowCount: 'Max default displayed offers count',
        additionalSettings: 'Additional settings',
        prices: 'Prices',
        hidePrices: 'Hide prices',
        buttonLabel: 'Button label',
        id: 'ID',
        configurationDescription:
            'If you want to better match the appearance and operation of the widget to your site and requirements, add a new configuration. You can add multiple configurations for each site and use them in different places.',
        name: 'Name',
        addSettings: 'Add settings',
        editSettings: 'Edit settings',
        buyboxesFor: 'List of widgets',
        categoryName: 'Category',
        buyboxId: 'ID',
        actions: 'Actions',
        appearance: 'Appearance',
        category: 'Category',
        space: '@:space',
        customStyles: 'Custom styles',
        leadColor: 'Lead color',
        showProduct: 'Show product data (photo, name)',
        showPrices: 'Show prices',
        isTab: 'Divide formats into tabs',
        shopStyle: 'Show shop as',
        formats: 'Formats',
        campaigns: 'Shops',
        campaignsSubheader:
            'Select and drag shops you want to display in your widget. If you want some shops to be displayed at the top of the list without sorting by price, set the appropriate weights for them.',
        campaignsSubheaderMobile:
            'Select shops you want to display in your widget. If you want some shops to be displayed at the top of the list without sorting by price, set the appropriate weights for them.',
        campaignsShown: 'Displayed',
        campaignsNotShown: 'Not displayed',
        updateAppearanceSuccess: 'Updated widget settings',
        campaignsListCreateSuccess: 'Added shop "{name}".',
        campaignsListUpdateSuccess: 'Updated shop weight "{name}".',
        campaignsListDeleteSuccess: 'Removed shop "{name}".',
        showAdditionalColumn: 'Show additional column',
        additionalColumn: 'Additional column content (HTML)',
        additionalColumnOnlyForSorted: 'Show only for sorted',
        version: 'Widget version',
        latestVersion: 'Latest',
        language: 'Widget language',
        showCardAppearanceBtn: 'Customize the appearance of the widget for this product',
    },
    pluginCampaignsList: {
        title: 'Store list settings',
        noCampaigns: 'No stores available.',
        noActiveCampaigns: 'All stores selected',
        noAvailableCampaigns: 'All stores selected',
        activeCampaigns: 'Stores displayed on your website',
        activeCampaignsDescription: 'Change the order of importance next to the store',
        availableCampaigns: 'Shops available',
        availableCampaignsDescription: 'Select the stores you want to view and drag them to the left',
        createSuccess: 'Added store "{name}".',
        updateSuccess: 'The validity of the "{name}" store has been updated.',
        deleteSuccess: 'Store "{name}" removed.',
    },
    generator: {
        title: 'Generator',
        title2: 'Generate widget',
        title3: 'Generate a product landing page',
        cardProduct: '1. Select product',
        cardProductDescription: 'Find a product, configure the widget\'s appearance, generate the code, and then insert it on your website.',
        cardSpace: '2. Select page and configuration',
        cardSpaceDescription: 'Select page and configuration',
        cardAppearance: '3. Adjust appearance',
        cardAppearanceDescription: 'Find a product, configure the widget\'s appearance, generate the code, and then insert it on your website.',
        cardCode: '4. Insert on page',
        cardCodeDescription: 'Copy the generated code and insert it into your website',
        previewHeader: 'Your widget preview',
        previewSubheader: 'Select product and page to preview widget',
        previewSubheaderNoOffers: 'We did not find any offer for the selected product and website.',
        productSearchPlaceholder: 'Enter the name or EAN of the product',
        buyboxSearchCategory: 'Configuration',
        buyboxSearchId: 'ID',
        widgetType: 'Widget type',
        widgetTypeTooltip:
            'You have four types of widget to choose from. It can be visible immediately on the page (standard) or appear after clicking on the linked text fragment or button (popover, overlay). You can check each of them in Preview.',
        language: 'Widget language',
        productName: 'Product name',
        productInfo: 'Product description',
        leadColor: 'Lead color',
        leadColorTooltip: 'You can change the color of the "Go to Store" button. It\'s best to use a color that dominates your site or leave the default settings.',
        buttonLabel: 'Custom button label',
        buttonLabelTooltip: 'You can change the default "Go to Store" to any text you like, such as "Buy Now", "Buy Online", "Buy Product", etc.',
        shopStyle: 'Shop style',
        sortType: 'Sort order',
        rowCount: 'Max default displayed offers count',
        rowCountTooltip: 'Here you can enter the number of stores to be displayed in the widget. Additional offers of shops will be visible after clicking on "Show more".',
        showProduct: 'Show product data (photo, name)',
        showProductTooltip: 'This function enables or disables the product presentation (photo, name and description) in the widget.',
        showPrices: 'Show prices',
        showPricesTooltip: 'This feature enables or disables the price column in the widget.',
        next: 'Next',
        copyCode: 'Copy code',
        copied: 'Code copied',
        helperDescription: 'Find the product, configure the appearance of the widget, generate the code, and then insert it into your site.',
        helperDescription2: 'Find the product, copy the generated URL, and then insert it into your site.',
        seeHowToDoIt: 'See how to use the generator',
        runTheTutorial: 'Run the tutorial',
        learnAboutWidgetTypes: 'Learn about widget types',
        codeWarning: '{warn} If you put several widgets on a single page, then insert {code} only once.',
        codeWarningWarn: 'Warning!',
        liveDemo: 'Live demo',
        liveDemoUrl: 'https://getbuybox.com/jak-widzet-buybox-moze-wygladac-na-twojej-stronie/',
        htmlTag: 'HTML tag that triggers the widget',
        buyboxSearchCategoryNoResults:
            'No listings to display - product is available in stores you do not display in the widget. <a href="https://docs.getbuybox.com/ustawienia/" target="_blank">See how to change settings</a>',
        correct_form_data_to_proceed: 'Please correct the form details to proceed.',
    },
    widgetType: {
        widget: 'Standard',
        popover: 'Popover',
        overlay: 'Overlay',
        logosOnly: 'Logo',
    },
    campaignsSettings: {
        nameOnBuybox: 'Shop name',
        visibleFor: 'Visible for',
        shopOnlyForCampaigns: 'Shop only for campaigns',
        addSpace: 'Space ID',
        textLinkTargetUrlPattern: 'Target URL pattern',
        textLinkRedirectUrlPattern: 'Redirect URL pattern',
        isActive: 'Active',
        isHidden: 'Hidden',
        textLinks: 'Text links',
        filteredStatistics: 'Filtered statistics',
        logo: 'Logo',
        icon: 'Icon',
        utmy: 'UTM-y',
        cookie: 'Cookie',
        textFields: 'Text fields',
        description: 'Description',
        conditionDescription: 'Condition description',
    },
    widgetPreview: {
        show: 'Check where to buy',
        noOffers: 'No offers',
        pageTitle: 'Widget preview',
    },
    textLinks: {
        title: 'Text links',
        title2: 'Link generator',
        header: 'Create a text link',
        subHeader: 'Generate a link to any subpage in your online store (homepage, promotion, listing).',
        subHeaderLink: 'https://docs.getbuybox.com/linki-tekstowe/',
        subHeaderLinkText: 'See how to do it',
        abpar: '@:abpar',
        space: 'Your website',
        campaign: 'Shop',
        targetUrl: 'Target URL',
        optional: 'Optional',
        generatedLink: 'Your finished link',
        openLink: 'Open link',
        copyLink: 'Copy link',
        shareLink: 'Share',
        copied: 'Link copied {link}',
        isSameDomainError: "Target URL's domain must match shop domain.",
    },
    stat: {
        header: 'Filters',
        space: '@:space',
        campaign: '@:campaign',
        year: 'Year',
        month: 'Month',
        date: 'Date',
        clicks: 'Clicks',
        transactions: 'Transactions',
        conversion: 'Conversion',
        epc: 'EPC',
        newCommission: 'Open commissions',
        acceptedCommission: 'Accepted commissions',
        newPublisherCommission: 'Open publisher commissions',
        acceptedPublisherCommission: 'Publisher Commissions Accepted',
        newOurCommission: 'Our commission is open',
        acceptedOurCommission: 'Our commission accepted',
        filter: '@:filter',
        noResult: '@:noResult',
        publisherCommission: 'Publisher commissions',
        ourCommission: 'Our commissions',
        commissionOpened: 'Opened',
        commissionAccepted: 'Accepted',
        commission: 'commission',
        commissions: 'commissions',
    },
    statByDay: {
        title: 'Daily statistics',
    },
    statByMonth: {
        title: 'Monthly statistics',
    },
    transactions: {
        title: 'Transactions',
        list: 'Transactions list',
        filters: '@:filters',
        space: '@:space',
        campaign: 'Shop',
        dateRange: 'Date range',
        financeDocument: 'Payment',
        status: 'Status',
        hour: 'Hour',
        showTransactions: 'Show transactions',
        exportTransactions: 'Export to Excel',
        count: 'The number of transactions for the applied filters',
        counting: 'Calculating the number of available transactions ...',
        exportRejectedNoItems: 'There are no transactions to export. Please change your filtering settings and try again.',
        exportRejectedTooMuchItems:
            'You have exceeded the maximum {limit} of transactions to be downloaded in a single export. Please change your filtering settings and try again.',
        id: 'ID',
        date: 'Date',
        transactionAmount: 'Transaction amount',
        commissionAmount: 'Commission',
        conversionTime: 'Conversion time',
        productsList: 'Products list',
        productsListTransaction: 'Transaction ID: {id} ({campaign})',
        productName: 'Product name',
        quantity: 'Quantity',
        price: 'Amount',
        publisherCommission: 'Commission',
        total: 'Total',
        productNoInfo: 'No data about the product',
        abpar: '@:abpar',
        commissions: {
            publisher: 'Publishers',
            admin: "Our's",
            advertiser: "Shop's",
        },
        details: 'Details',
        withSelected: 'With selected',
    },
    transactionStatus: {
        new: 'New',
        accepted: 'Accepted',
        rejected: 'Rejected',
        abandoned: 'Abandoned',
    },
    shopStyle: {
        icon_name: 'Icon plus shop name',
        logo: 'Logo shop',
    },
    reportsPages: {
        title: "Pages' reports",
        pages: 'Pages',
    },
    reportsShops: {
        title: "Shops' reports",
    },
    pages: {
        title: 'Your pages',
        name: 'Name',
        namePlaceholder: 'Page name',
        url: 'Url',
        urlPlaceholder: 'e.g. https://my-page.com',
        description: 'Description',
        descriptionPlaceholder: 'Two or three sentences describing your page',
        categories: 'Categories',
        type: 'Type',
        status: 'Status',
        actions: 'Actions',
        confirm: 'Confirm',
        cancel: 'Cancel',
        create: 'Add new website',
        edit: 'Edit website „{name}”',
        createSuccess: 'Website "{name}" has been created.',
        updateSuccess: 'Website "{name}" has been updated.',
        updateWebhookSuccess: 'Webhook for website "{name}" has been updated.',
    },
    widgeterShops: {
        title: 'Shops',
        subtitle: 'Choose a page for which you want to check available stores and conditions, e.g. commission height.You can also download product feed here.',
        filters: 'Filters',
        campaignName: '@:campaign',
        campaignNamePlaceholder: 'Enter a name to search',
        category: 'Category',
        status: 'Status',
        filter: 'Filter',
        shopsList: 'Shops list',
        id: 'ID',
        shop: 'Shop',
        commission: 'Commission',
        textLinks: 'Text links',
        transactionDetectTime: 'Transaction detection time',
        transactionDetectTimeInDays: '{count} day | {count} days',
        productFeed: 'Product feed',
        feedCopied: 'URL copied',
        copyFeedUrl: 'Copy the url',
        showFeeds: 'Show',
        cps: 'CPS',
        selectWebsite: 'Select a website',
    },
    campaignStatus: {
        waiting: 'Waiting',
        accepted: 'Accepted',
        rejected: 'Rejected',
        disconnected: 'Disconnected',
    },
    availableStatus: {
        yes: 'Yes',
        no: 'No',
    },
    finance: {
        title: 'Finance',
        accountSummary: 'Account Summary',
        paymentHistory: 'Payment history',
        newCommission: 'New commission',
        acceptedCommission: 'Commission confirmed by advertisers',
        toPayCommission: 'Commission ready to be paid',
        payOut: 'Withdraw',
        date: 'Date',
        documentNumber: 'Document number',
        paymentDate: 'Payment term',
        amount: 'Amount',
        status: 'Status',
        transactions: 'Transactions',
        showTransactions: 'show',
        createBillSuccess: 'A commission was ordered.',
        sendInvoiceSuccess: 'Invoice saved.',
        yourPayouts: 'Your payouts',
        totalMonthlyPayouts: 'Total monthly payouts',
    },
    financeDocumentStatus: {
        new: 'New',
        accepted: 'Accepted',
        rejected: 'Rejected',
        paid: 'Paid',
        abandoned: 'Abandoned',
    },
    paymentGeneratorCompany: {
        title: 'New payment',
        companyOnly: 'Page available for company accounts only',
        unavailable: 'We are sorry, but payment is not possible at this time. Please try again in a moment.',
        payoutThresholdNotReached: 'Sorry, but payout is not possible at this time. There are not yet sufficient funds in your account for withdrawal.',
        prepareTitle: '1. Prepare document',
        prepareDescription: 'Issue an invoice according to the following details and generate a PDF file.',
        recipient: 'Recipient:',
        issueDate: 'Issue date:',
        dueDate: 'Due date:',
        dueInDays: '21 days',
        netPrice: 'Net amount:',
        comments: 'Service:',
        commentContent: 'Advertising space rental',
        sendTitle: '2. Send us the finished document',
        invoiceNumber: 'Your document number',
        invoicePdf: 'PDF',
        confirm: 'Save',
    },
    paymentGeneratorPrivate: {
        title: 'New payment',
        description: "Please confirm that your personal data is up-to-date and that you want to bill this data with today's date.",
        missingData: 'Before generating the payment, please complete the data in {link}.',
        missingDataLink: 'account settings',
        cancel: 'Cancel',
        confirm: 'Confirm',
    },
    api: {
        title: 'API',
        apiKeyFor: 'API key for {space}',
        apiKey: 'API key',
        apiSecret: 'API secret',
        generate: 'Generate',
        regenerate: 'Regenerate',
        revoke: 'Revoke',
        noData: 'No generated API key',
        generateSuccess: 'The API key has been generated.',
        revokeSuccess: 'API key has been revoked.',
    },
    save: 'Save',
    noResult: 'No results',
    filters: 'Filters',
    filter: 'Filter',
    all: 'All',
    space: 'Space',
    advertisingSpace: 'Website (space)',
    campaign: 'Shop',
    campaigns: 'Shops',
    abpar: 'Param {number}',
    widget: 'Widget',
    dated: 'dated',
    yes: 'Yes',
    no: 'No',
    shops: 'Shops',
    yourPages: 'Your pages',
    timeRangeDropdown: {
        custom: 'Custom',
        last7Days: 'Last 7 days',
        last14Days: 'Last 14 days',
        last30days: 'Last 30 days',
        today: 'Today',
        yesterday: 'Yesterday',
        currentWeek: 'Current week',
        lastWeek: 'Last week',
        currentMonth: 'Current month',
        lastMonth: 'Last month',
        currentQuarter: 'Current quarter',
        lastQuarter: 'Last quarter',
        currentYear: 'Current year',
        lastYear: 'Last year',
    },
    widgeterSettingsIntegrations: {
        title: 'Integrations',
        wordpress: {
            title: 'Wordpress plugin',
            description:
                'This WordPress plugin allows you to integrate your website with BUY.BOX in a few simple steps. Among other things, it allows you to add BUY.BOX widgets to your blog page quickly and easily, without having to log into our panel.',
            url: 'https://docs.getbuybox.com/wtyczka-do-wordpressa/',
            buttonLabel: 'Find out more',
        },
        webhooks: {
            title: 'Webhook',
            description:
                'Webhooks are a mechanism for communication between our service and external systems. It allows you to send data (e.g. about new transactions) on the so-called callback URL, without having to query our API every time. You can use this for integration, for example through the <a href="https://zapier.com/">Zapier</a> service.',
            url: { name: 'SettingsWebhooks' },
            buttonLabel: 'Configure',
        },
    },
    modalAddPage: {
        header: 'Add your website',
        content:
            'To start your adventure with our platform, add your website (blog, portal, social media profile, etc.), where you will use our tools. It will take you less than a minute.',
        button: 'Add website',
    },
    pageNotFound: {
        title: "You're lost",
        text: 'The page you are looking for does not exist. But you can click the button below to go back to the homepage.',
        buttonText: 'Back to Home',
    },
    pageForbidden: {
        title: "You're lost",
        text: 'The page you are looking for does not exist under this address.',
        widgeterLink: 'Go to widgeter page',
        advertiserLink: 'Go to advertiser page',
    },
    accessDenied: {
        title: 'Access Denied',
        text: 'You dont have permission to view this site.',
        buttonText: 'Back to Home',
    },
    reportsProducts: {
        title: 'Product report',
        products: 'Products',
        salesValue: 'Sales value',
    },
    productLandingPage: {
        title: 'Product LP',
    },
    productLandingPageGenerator: {
        title: 'Generate Gdziekupic.to',
        helperDescription: 'With the generator here, create a link to the product subpage.The user who clicks on the link will be transferred to a subpage with a list of online stores where you can buy a given product.',
        helperDescription2: 'Find the product, copy the generated URL, and then insert it to your website, e.g. in reports on Instagram, Facebook, YouTube or in the content of the article.',
        seeHowToDoIt: 'See how to do it',
        takeALookAtHelp: 'Take a look at help',
        watchVideo: 'Watch video',
        cardStep3Title: 'Copy the URL',
        copyUrl: 'Copy URL',
        copied: 'URL copied',
        previewHeader: 'Preview your LP',
    },
    productLandingPageSettings: {
        title: 'Settings',
    },
    productLandingPageSettingsEdit: {
        title: 'Settings',
    },
    confirmEmail: {
        title: 'Confirm email',
        missingToken: 'No confirmation token.',
        invalidToken: 'The account has already been activated or the activation link was incorrectly copied into the browser.',
        success: 'Email has been confirmed.',
    },
    SpaceTypes: {
        webpage: 'Portal',
        socialMedia: 'Social media',
        blog: 'Blog',
        mobileApp: 'Mobile app, widgets, extensions',
        mailingDatabase: 'Mailing database',
        couponsCodesPromotions: 'Discount coupons, promotions',
    },
    dataNotLoaded: {
        error: 'Error',
        title: 'No data loaded',
        text: 'Failed to load necessary data for the application to function properly. Please refresh the page or try logging in again to fix this problem.',
        buttonText: 'Refresh page',
        loginAgain: 'Login again',
    },
    auth: {
        widgeter: {
            login: 'Log in',
            descriptionPoints: [
                'Easy and effective monetization of website content',
                'High commissions on sales',
                'Over 500 stores and several million product listings',
                'A form of advertising that is friendly to Internet users',
                'High click-through and conversion rates',
                'Convenient user interface',
            ],
        },
        advertiser: {
            login: 'Log in to shop account',
            descriptionPoints: [
                'We connect websites and blogs with your store.',
                'We shorten the path of users to your store.',
                'We display your current offers right next to product recommendations on websites and in social media.',
                "Extensive data on transactions and users' behavior.",
                'The wide range of BUY.BOX widget operation.',
                'High CTR and CR ratios.',
            ],
            bottomText: 'If you <strong>run a store</strong> and want to add it to our platform, <a href="{urlForShops}" target="_blank"><strong>go here</strong></a>.',
        },
        helloHeader: 'Welcome back',
        registerWithGoogle: 'Register with Google',
        loginWithGoogle: 'Log in with Google',
        loginWithFacebook: 'Log in with Facebook',
        loginWithApple: 'Log in with Apple',
        forgotYourPassword: 'Forgot your password?',
        logIn: 'Log in',
        youDontHaveAnAccountYet: "Don't have an account yet?",
        registerForFree: 'Register for free',
        agreements: {
            rules: 'I know and accept <a href="{urlRules}" target="_blank" class="text-brand">the terms of service</a> and <a href="{urlPrivacyPolicy}" target="_blank" class="text-brand">privacy policy</a>.',
            newsletter: 'I agree to receive at the e-mail address provided a newsletter containing news and important information about the operation of the service.',
        },
        register: 'Register',
        resetPassword: 'Reset password',
        backToTheLoginPage: 'Back to the login page',
        repeatPassword: 'repeat your password',
        pageRegister: {
            pageTitle: 'Create account',
            helloHeader: 'Create an account',
            helloSubheader: 'and use it <strong>for free!</strong>',
            ctaText: 'Already have an account?',
            successMessage: 'Registration was successful. You have been logged in to the panel.',
        },
        pageResetPassword: {
            successMessage: 'A link to set a new password was sent to the e-mail address you provided.',
        },
        pageResetPasswordToken: {
            successMessage: 'New password has been set. You can now login to your account using it.',
        },
        onlyAdminLoginInfo: 'Please be advised that logging into this site currently works only for admin account.',
        onlyWidgeterLoginInfo: 'Please be advised that logging into this site currently works only for widgeter account.',
        onlyAdvertiserLoginInfo: 'Please be advised that logging into this site currently works only for advertiser account.',
    },
    toolTypes: {
        widget: 'Widget',
        lp: 'product landing page',
        linki: 'links',
    },
    noData: 'No data',
    noDataToExport: 'No data to export',
    selectAll: 'Select all',
    deselectAll: 'Deselect all',
    selectAllVisible: 'Select all visible',
    deselectAllVisible: 'Deselect all visible',
    enterToSearch: 'Enter to search',
    errorOccurred: 'Error',
    selectedSpace: 'Selected page',
    productImport: 'Product import',
    shopPresentation: 'Shop presentation',
    shopLocations: 'Shop locations',
    download: 'Download',
    close: 'Close',
    cancel: 'Cancel',
    active: 'Active',
    inactive: 'Inactive',
    loading: 'Loading',
    verifications: 'User verifications',
    selectPageToViewStores: 'Select a page to view stores',
    shopLogo: 'Shop logo',
    shopIcon: 'Shop icon',
    shopLogoDescription:
        'Upload a 250x50px PNG file with a transparent background. The logo will be used in your store description in the publisher panel and in the widget version of the logos.',
    shopIconDescription: 'Upload a 32x32px PNG file with a transparent background. The icon will be used in the widget.',
    noFilesSelected: 'No files selected',
    selectFile: 'Select file',
    saveChanges: 'Save changes',
    dataSavedSuccessfully: 'Data saved successfully',
    offersCatalog: 'Offers catalog',
    findProduct: 'Find a product',
    search: 'Search',
    name: 'Name',
    ean: 'EAN',
    isbn: 'ISBN',
    eanisbn: 'EAN/ISBN',
    price: 'Price',
    available: 'Available',
    unavailable: 'Unavailable',
    aggregated: 'Aggregated',
    lastUpdate: 'Last update',
    offerDetails: 'Details of the offer',
    offerId: 'Offer ID',
    storeOfferId: 'Store Offer ID',
    offerType: 'Type of offer',
    aggregation: 'Aggregation',
    priceAndAvailability: 'Price and availability',
    availability: 'Availability',
    priceAndAvailabilityWillBeOverwritten: 'Note that price and availability will be overwritten on the next automatic update',
    code: 'Code',
    day: 'Day',
    days: 'Days',
    sales: 'Sales',
    webpagesPublishers: 'Pages (publishers)',
    status: 'Status',
    category: 'Category',
    searchUrlPlaceholder: 'Enter a URL to search',
    searchNamePlaceholder: 'Enter a name to search',
    lastDays: 'last {days} days',
    clicks: 'Clicks',
    type: 'Typ',
    pagesList: 'List of webpages',
    localeNotFound: 'Locale file for "{lang}" not found',
    selectOption: '-- Select option --',
    year: 'Year',
    month: 'Month',
    date: 'Date',
    identifier: 'Identifier',
    amount: 'Amount',
    commission: 'Commission',
    conversionTime: 'Conversion time',
    abpar1: 'Parameter 1',
    abpar2: 'Parameter 2',
    abpar3: 'Parameter 3',
    shop: 'Shop',
    campaignShort: 'Shop',
    enterUrlToSearch: 'Enter url to search',
    clicksLastXDays: 'Clicks (last %days days)',
    salesLastXDays: 'Sales (last %days days)',
    reject: 'Reject',
    accept: 'Accept',
    lastXDays: 'last %days days',
    url: 'URL',
    actions: 'Actions',
    enterYourWebsiteDetails: 'Enter your website details',
    eGMyBlog: 'e.g. My Blog',
    https: 'https://',
    or: 'or',
    emailAddress: 'Email address',
    password: 'Password',
    firstName: 'First name',
    fullName: 'Full name',
    next: 'Next',
    termsAndConditions: 'Terms and Condition',
    goBackTo: 'Go back to',
    targetUrl: 'Target URL',
    preparedTextLink: 'Prepared text link',
    yourRecentlyGeneratedLinks: 'Your recently generated links',
    loginSuccess: 'You have successfully logged in.',
    websiteName: 'Website name',
    verificationCode: 'Verification code',
    verifiyAccount: 'Verify account',
    websiteUrl: 'Website URL',
    select: 'Select',
    websiteType: 'Website type',
    invalidRequestData: 'The submitted data is incorrect',
    product: 'Product',
    addWebsite: 'Add website',
    selectProduct: 'Select the product',
    customAppearance: 'Customize the appearance',
    optional: 'Optional',
    selectTool: 'select tool',
    toolTypeWidget: 'Widget BUY.BOX',
    toolTypeLinks: 'Text link',
    toolTypeLP: 'Product landing page',
    skipConfiguratorAndGoToPanel: 'Skip the configurator and go to the panel',
    insertWidget: 'Insert a widget on the page',
    insertTextLink: 'Insert link to page',
    done: 'Done',
    selectAnotherTool: 'Select another tool',
    goToPanel: 'Go to the panel',
    quickStart: 'Quick start',
    id: 'ID',
    hour: 'Hour',
    details: 'Details',
    value: 'Value',
    doNothing: "Don't do nothing",
    acceptTransactions: 'Accept the transactions',
    rejectTransactions: 'Reject transactions',
    noTransactionsSelected: 'No transactions selected',
    typesOfOffers: {
        content_book: 'Book',
        content_movie: 'Movie',
        content_game: 'Game',
        content_music: 'Music',
        product: 'Product',
    },
    showPreview: 'Show preview',
    catalogProductsTitle: 'Catalog - Products',
    author: 'Author',
    brand: 'Brand',
    description: 'Description',
    source: 'Source',
    categories: 'Categories',
    edit: 'Edit',
    catalog: 'Catalog',
    products: 'Products',
    createSuccess: 'The data has been saved',
    updateSuccess: 'The data has been updated',
    createError: 'An error occurred while saving the data',
    updateError: 'An error occurred while updating the data',
    youEditX: 'You edit "{name}"',
    youAdd: 'Add',
    confirm: 'Confirm',
    confirmYouWantDelete: 'Are you sure you want to delete?',
    brands: 'Brands',
    manufacturer: 'Manufacturer',
    manufacturers: 'Manufacturers',
    parent: 'Parent',
    addNew: 'Add new',
    favicon: 'Favicon',
    logo: 'Logo',
    urlAddress: 'URL address',
    links: 'Links',
    title: 'Title',
    additionalText: 'Additional text',
    googleAnalyticsId: 'Google Analytics ID',
    gaPlaceholder: 'UA-XXXXXX-X',
    titleAndUrl: 'Title and URL',
    appearance: 'Appearance',
    personalizeYourLandingPageText: 'Personalize your landing page by adding your logo, custom links and additional text.',
    lpFaviconTooltip: 'Icon displayed in the browser bar. Recommended aspect ratio 1:1. Maximum file size is 150KB.',
    lpLogoTooltip: 'Your logo to be displayed at the top of the page on the widget. Recommended dimensions are maximum 200px wide and 100px high. Maximum file size is 1MB.',
    lpUrlTooltip: 'The URL that web surfers will be directed to when they click on your logo.',
    lpLinksTooltip:
        'Links placed under the widget that lead to your privacy policy, terms of service or social media profiles, for example. The title is the content of the link displayed on the page (e.g. Privacy Policy).',
    lpTextTooltip:
        'Your own text that we insert at the bottom of the page. You can add here e.g. marketing text or information about using cookies. The maximum number of characters is 300.',
    lpGaIdTooltip: 'ID of your Google Analytics tracking code. Add if you want to track your Product LP statistics.',
    whatsNew: "What's New?",
    goNext: 'Go next',
    conversionReport: 'Conversion report',
    registrations: 'Registrations',
    conversionFunnel: 'Conversion funnel',
    newPublishers: 'New publishers',
    publishersWithThePageAdded: 'Publishers with the page added',
    publishersWithClicks: 'Publishers with clicks',
    publishersWithTransactions: 'Publishers with transactions',
    publishersWithTipboxPayments: 'Publishers with Tipbox payments',
    changeProduct: 'Change the Product',
    goBack: 'Bo back',
    webhook: 'Webhook',
    webhooks: 'Webhooks',
    webhookUrl: 'Webhook URL',
    lastName: 'Last name',
    companyName: 'Company name',
    userRole: 'User role',
    userList: 'User list',
    dateAdded: 'Date added',
    login2: 'Login',
    email: 'Email',
    firstNameAndLastName: 'First name and last name',
    company: 'Company',
    role2: 'Role',
    addUser: 'Add User',
    show: 'Show',
    go: 'Go',
    go2: 'Go',
    userDetails: 'User details',
    userName: 'User Name',
    blockade: 'Blockade',
    acceptanceOfRegulations: 'Akceptacja regulaminu',
    notifications: 'Notifications',
    deleted: 'Deleted',
    demo: 'Demo',
    created: 'Created',
    phoneNumber: 'Phone number',
    address: 'Address',
    country: 'Country',
    bankAccount: 'Bank account',
    companyAccount: 'Company account',
    taxId: 'Tax ID',
    language2: 'Language',
    users: 'Users',
    selectWebsite: 'Select a website',
    only: 'Only',
    listOfPaymentsTips: 'List of payments (tips)',
    overview: 'Overview',
    paymentsDonations: 'Payments (donations)',
    webpages: 'Webpages',
    user: 'User',
    publisher: 'Publisher',
    producer: 'Producer',
    pageStatus: 'Page status',
    userType: 'User type',
    amazonTrackingId: 'Amazon Tracking ID',
    pageType: 'Page type',
    productDetails: 'Product details',
    tracking: 'Tracking',
    permissions: 'Permissions',
    enabled: 'Enabled',
    catalogManufacturersTitle: '@:catalog - @:manufacturers',
    exportToExcel: 'Export to Excel',
    noOffersToDisplay: 'There are no offers to display',
    selectPageAndCategory: 'Select a page and a category',
    tipbox: 'tip.BOX',
    tipboxWidgetSettings: 'tip.BOX widget settings',
    tipboxWidget: 'tip.BOX widget',
    insertOnPage: 'Insert on page',
    ifYouLikedThisPost: 'If you liked this post, you can appreciate me by offering me a small tip. Thank you in advance.',
    copyTheCodeAndInsertItIntoYourPage: 'Copy the code and insert it into your page where you want the widget to display.',
    tipboxForm: {
        label: {
            amounts: 'Tip amounts',
            header: 'Header content',
            description: 'Content of the prompt',
            thanks: 'Content of thanks',
            headerColor: 'Header color',
            textColor: 'Text color',
            buttonColor: 'Button color',
            buttonTextColor: 'Button text color',
            frameColor: 'Frame color',
            backgroundColor: 'Background color',
            icon: 'Icon',
        },
        placeholder: {
            amounts: ['5', '10', '15'],
            header: 'Appreciate my work',
            description: 'If you liked this post, you can appreciate me by offering me a small tip. Thank you in advance.',
            thanks: 'Thank you for your tip',
            headerColor: '#000000',
            textColor: '#000000',
            buttonColor: '#e32e32',
            buttonTextColor: '#ffffff',
            frameColor: '#000000',
            backgroundColor: '#ffffff',
        },
    },
    paymentStatus: 'Payment status',
    nameOfTheDonor: "Donor's name and surname",
    emailOfTheDonor: "Donor's email address",
    depositAmount: 'Deposit amount',
    moderation: 'Moderation',
    statusCreated: 'Created',
    statusClosed: 'Closed',
    statusReopen: 'Reopen',
    statusWontDo: 'Wont Do',
    comment: 'Comment',
    youAssignVariantsToTheProduct: 'You assign variants to the product: "{name}"',
    variantsHaveBeenAssigned: 'Variants have been assigned',
    assignVariants: 'Assign variants',
    remove: 'Remove',
    paymentId: 'Payment ID',
    ourCommission: 'Our commission',
    operatorsCommission: 'Operator commission',
    tipTransactionStatuses: {
        created: 'Created',
        pending: 'Pending',
        correct: 'Correct',
        payout_requested: 'Payout Requested',
        paid: 'Paid',
        error: 'Error',
        returned: 'Returned',
    },
    offerFinder: 'Offer Finder',
    searchForOffers: 'Search for offers',
    eanNumber: 'EAN number',
    search2: 'Search',
    searchResults: 'Search Results',
    searchResultsCount: 'Search Results ({count})',
    authorDirectorProducer: 'Author / Director / Producer',
    productId: 'Product ID',
    producerCode: 'Producent code',
    imageUrl: 'Image URL',
    feedId: 'Feed ID',
    storeCategoryTemplate: 'Store category template',
    format2: 'Format',
    hidden: 'Hidden',
    productOfferId: 'Product ID / offer ID',
    info: 'Info',
    feedShop: 'Feed / Shop',
    forSelected: 'For selected',
    createNewProduct: 'Create a new product',
    transferToAnotherProduct: 'Transfer to another product',
    unlinkProduct: 'Unlink the product',
    changeProductType: 'Change product type',
    changeOrHidden: 'Change or Hidden',
    changeAvailability: 'Change Availability',
    edit2: 'Edit',
    idOfShop: 'ID of Shop',
    updated: 'Updated',
    available2: 'Available',
    unavailable2: 'Unavailable',
    hidden2: 'Hidden',
    settlements: 'Settlements',
    tipSettlements: 'Tip settlements',
    donationsInThePreviousMonth: 'Donations in the previous month',
    publishersInThePreviousMonth: 'Publishers in the previous month',
    revenueInThePreviousMonth: 'Revenue in the previous month',
    revenueInPreviousMonth: 'Revenue in previous month',
    withdrawalOrders: 'Withdrawal orders',
    invoices: 'Invoices',
    noPayoutsSelected: 'No withdrawal orders selected',
    downloadTransferFile: 'Download the transfer file',
    transferFile: 'Transfer file',
    markAsSettled: 'Mark as settled',
    downloadSelected: 'Download selected',
    noInvoicesSelected: 'No invoices selected',
    publisherRecipient: 'Publisher (recipient)',
    filesUpdatedAt: 'Feed updated at',
    documentNumber: 'Document number',
    netAmount: 'Net amount',
    issueDate: 'Date of issue',
    paymentAmount: 'Payment amount',
    donationsInThisMonth: 'Donations this month',
    donationsInThisYear: 'Donations this year',
    donationsReadyForWithdrawal: 'Donations ready for withdrawal',
    monthlySummaryOfDonations: 'Monthly summary of donations',
    document: 'Document',
    invoice: 'Invoice',
    donations: 'Donations',
    paymentOfDonations: 'Payment of donations',
    change: 'Change',
    payOut: 'Pay out',
    pleaseConfirmDataCorrect: 'Please confirm that the following data is correct.',
    verificationModal: {
        header: 'To verify your bank account to which we will transfer the accumulated donations, make a transfer according to the following data.',
        footer: "Remember that the sender's transfer data must be the same as those given in the settings of your account. Account verification may take up to 3 business days.",
    },
    paymentData: 'Payment data',
    paymentWasAccepted: 'The payment was accepted.',
    payouts: 'Payouts',
    noItemsSelected: 'No items selected!',
    noParametersSelected: 'No parameters selected!',
    clear: 'Clear',
    requestExecutedSuccessfully: 'Request executed successfully',
    productWithIdDoesNotExist: 'Product with the specified ID does not exist',
    integrationStatus: 'Integration status',
    shopId: 'Shop ID',
    shopName: 'Shop name',
    testedUrl: 'Tested URL',
    lastCookie: 'Last cookie',
    ratioClicksToCookies: 'Ratio of clicks to cookies',
    lastTransaction: 'Last transaction',
    campaignId: 'Campaign ID',
    spaceId: 'Space ID',
    companyNip: 'VAT number',
    street: 'Street',
    streetNumber: 'Street number',
    apartmentNumber: 'Apartment number',
    city: 'City',
    postcode: 'Post code',
    policies: 'Policies',
    acceptTermsAndConditions: 'I accept the terms and conditions',
    tipboxPolicies: 'I have read and accept the <a href="{url}" target="_blank" class="text-body text-decoration-underline">terms and conditions of the tip.BOX service</a>',
    tipboxPoliciesRequired: 'Acceptance of the rules of the tip.BOX service is required',
    theNew: 'New',
    createValuableContentAndCollectTipsFromYourUsers: 'Create valuable content and collect tips from your users',
    hereWeGo: 'Here we go',
    tipboxPoliciesAccepted: 'Regulations of the tip.BOX service have been accepted',
    completeYourDetails: 'Complete your details',
    companyBeforeActivatingTheServiceCheckAndUpdateYourData:
        'Before activating the service, check and update your data (company name, address, tax ID number, bank account number).',
    privateBeforeActivatingTheServiceCheckAndUpdateYourData: 'Before activating the service, check and update your information (name, home address and bank account number).',
    goToAccountSettings: 'Go to account settings',
    list: 'List',
    campaignsList: 'Campaigns list',
    advertiser: 'Advertiser',
    conditions: 'Conditions',
    campaignDetails: 'Campaign details',
    producerProducts: 'Producer products',
    producerProductsImport: 'Producer products - import',
    producerProductsImportDescription: 'Upload a file in JSON format to import products',
    importFile: 'Import file',
    dataImportedSuccessfully: 'Data imported successfully',
    importResult: 'Import result',
    importedProducts: 'Imported products',
    matchedProducts: 'Matched products',
    newProducts: 'New products',
    newCategories: 'New categories',
    removedCategories: 'Removed categories',
    feed: 'Feed',
    parser: 'Parser',
    offers: 'Offers',
    update: 'Update',
    assignCategory: 'Assign a category',
    add: 'Add',
    commissionId: 'Commission ID',
    addPercentCommission: 'Add new percentage commission',
    addAmountCommission: 'Add new amount commission',
    publisherCommission: 'Publisher commission',
    adminCommission: 'Admin commission',
    deleteUser: 'Delete user',
    firstAndLastName: 'First name and last name',
    bankAccount2: 'Bank account',
    requestLimitedForPerformanceReasons: "Search terms are too general. The presented results aren't complete due performance reasons. Please try more specific queries.",
    searchForOffersRecommendation: "It's recommended to avoid using “Store category pattern“, “Product ID“ and “Format” with “Title or name“ and “Author / Director / Producer“",
    settled: 'Settled',
    notSettled: 'Not settled',
    grossAmount: 'Gross amount',
    paidAmount: 'Paid amount',
    dueDate: 'Due date',
    toBePaid: 'To be paid',
    settleInvoice: 'Settle invoice',
    abandonInvoice: 'Abandon invoice',
    invoiceCreatedAt: 'Invoice created at',
    invoiceDueDate: 'Invoice due date',
    invoiceNumber: 'Invoice number',
    newInvoice: 'New invoice',
    invoicePositions: 'Invoice positions',
    invoiceNetAmount: 'Invoice net amount',
    invoiceCurrentNetAmount: 'Current net amount',
    shopsList: 'Shops list',
    currentSettlement: 'Current settlement',
    pagesDetails: 'Page details',
    gaApiSecret: 'Google Analytics API secret',
    gaSpace: 'Google Analytics space',
    settings: 'Settings',
    widgetSettingsTitle: 'Widget settings',
    weight: 'Weight',
    productFeeds: 'Product Feeds',
    socialAuthLoginError: 'An error occurred while logging in with your {provider} account',
    locations: 'Locations',
    adminCampaignsDetailsTabs: {
        settings: 'Settings',
        categories: 'Categories',
        commissions: 'Conditions',
        feeds: 'Feeds',
        locations: 'Locations',
    },
    activate: 'Activate',
    deactivate: 'Deactivate',
    processAgain: 'Process again',
    returnToListView: 'Return to list view',
    created2: 'Created',
    updated2: 'Updated',
    report: 'Report',
    processingStatus: 'Processing status',
    feedStatus: 'Feed status',
    xmlFile: 'XML File',
    open: 'open',
    authorization: 'Authorization',
    format: 'Format',
    redirectUrl: 'Redirect URL',
    publishers: 'Publishers',
    producers: 'Producers',
    parameterName: 'Parameter name',
    ruleFeedPrice: 'Feed/price rule',
    processingOptions: 'Processing options',
    queryOptions: 'Query options',
    transactions2: 'Transactions',
    transactionImport: 'Transaction Import',
    csvImport: 'Import from a CSV file',
    tradeDoublerImport: 'TradeDoubler Import',
    allegroImport: 'Allegro Import',
    csvFilesList: 'List of CSV files',
    file: 'File',
    import: 'Import',
    delete: 'Delete',
    processed: 'Processed',
    unprocessed: 'Unprocessed',
    createdAt: 'Creation date',
    canceledAt: 'Canceled date',
    deactivatedAt: 'Deactivated date',
    processedAt: 'Processing date',
    sentAt: 'Date sent',
    transactionImportProcedureLabel: 'The procedure expects a CSV file (character ";" as separator) with a header line and fields given below:',
    transactionImportSuccessfully: 'The file has been successfully uploaded.',
    transactionAllegroImportSuccessfully: 'The file has been successfully uploaded.',
    transactionTradedoublerImportSuccessfully: 'The file has been successfully uploaded.',
    transId: 'Transaction ID',
    showAll: 'Show all',
    productSearch: 'Product search',
    audit: 'Audit',
    transactionValidation: 'Transaction validation',
    validate: 'Validate',
    options: 'Options',
    settlementsWithPublishers: 'Settlements with publishers',
    accountType: 'Account type',
    privateType: 'Private',
    companyType: 'Company',
    dateRange: 'Date range',
    showTransaction: 'Show transaction',
    withSelected: 'With selected',
    productAudit: 'Product audit',
    outsourceAggregation: 'Outsource aggregation',
    error2: 'Error',
    feedImportSummary: 'Summary of feed import',
    feedsWaitingForFileDownload: 'Feeds waiting for file download',
    feedsWaitingForProcessing: 'Feeds waiting for processing',
    feedsBeingProcessed: 'Feeds being processed',
    successfullyProcessedFeeds: 'Successfully processed feeds',
    canceledFeeds: 'Canceled feeds',
    inactiveFeeds: 'Inactive feeds',
    updatedAt: 'Update date',
    processStartedAt: 'Start of processing',
    processEndedAt: 'End of processing',
    itemsProcessed: 'Items processed',
    reAggregateSuccessfully: 'The product has been queued for aggregation.',
    productAwaitingAggregation: 'The product is waiting for aggregation. You have to wait from a few to even several dozen minutes to see the effects.',
    finance2: 'Finance',
    subscriptions: 'Subscriptions',
    agreement: 'Agreement',
    canceled: 'Canceled',
    cancelSubscription: 'Cancel subscription',
    changeType: 'Change type',
    reportSubscriptionList: 'List of report subscriptions',
    bbAffiliateProgram: 'Program Partnerski BB',
    buyboxAffiliateProgram: 'BUY.BOX affiliate program',
    buyboxAffiliateProgramRecommendOurServices: 'Recommend our services to others and earn <strong>50 PLN of additional commission</strong> for each referred user who opens an account and collects the first 50 PLN of commission to withdraw.',
    yourReferralLinks: 'Your referral links',
    theseAreYourUniqueRecommendingLinksToOurPlatform: 'These are your unique recommending links to our platform.',
    forEachPageYouAddedInThePanelWeHavePreparedASeparateRecommendationLink: 'For each page you added in the panel, e.g. websites, Instagram, Facebook, we have prepared a separate recommendation link.Thanks to this, you can follow which page brings the best results.Copy the link for the selected page and act.',
    advertisingMaterialsToDownload: 'Advertising materials to download',
    downloadMaterials: 'Download materials',
    toWhomToRecommendTheBuyBox: 'To whom to recommend the BUY.BOX platform?',
    whatAreTheConditionsForReceivingRemuneration: 'What are the conditions for receiving remuneration?',
    whereCanICheckTheEffectsOfMyActions: 'Where can I check the effects of my actions?',
    faq: 'FAQ',
    referralLinkWasCopied: 'The referral link has been copied',
    website: 'Website',
    link: 'Link',
    clickToCopy: 'Click to copy',
    interval: 'Interval',
    quarter: 'Quarter',
    statistics: 'Statistics',
    dailyStatistics: 'Daily statistics',
    monthlyStatistics: 'Monthly statistics',
    quarterlyStatistics: 'Quarterly statistics',
    yearlyStatistics: 'Yearly statistics',
    week: 'Week',
    settle: 'Settle',
    firstNameOfTheDonor: 'First name',
    lastNameOfTheDonor: 'Last name',
    shopPanel: 'Shop panel',
    createSubscription: 'Create subscriptions',
    newSubscription: 'New subscription',
    addNewSubscription: 'Add new subscription',
    reportSubscriptionsCreateSuccessfully: 'The subscription was successfully created',
    entitlement: 'Entitlement',
    subscriptionsPermissionOptions: {
        campaign_analysis: 'campaign analysis',
        stats_availability: 'availability of products',
        stats_categories: 'category statistics',
        stats_competition_report: 'competition report',
        stats_price_distribution: 'price schedule',
        stats_products: 'product statistics',
        stats_sales: 'sales statistics',
        stats_shops: 'store statistics',
    },
    numberOfOffersToDisplay: 'Number of offers to display',
    offerLifetime: 'Lifetime of offers',
    inHours: 'In hours',
    youCannotAddSuchAnAddressBecauseSomeoneElseHasAlreadyReportedSuchASite: 'You cannot add such an address because someone else has already reported such a site. If you think this is a mistake and you own the rights to this site, <a href="javascript:void(0)" onclick="window.userengage(\'widget.open\')" style="color:white;text-decoration:underline">send us a message</a>',
    permission: 'Permission',
    transactionCorrection: 'Transaction correction',
    widgeterPagesSubheaderText: 'Add at least one page (blog, website, profile in social media, etc.) on which you want to use our tools.',
    takeALookAtHelp: 'Take a look at help',
    watchVideo: 'Watch video',
    seeHowToUseTheGenerator: 'See how to use the generator',
    seeHowToDoIt: 'See how to do it',
    conversionReportChartName: {
        registration: 'Registration',
        space: 'Publishers with added page',
        click: 'Publishers with clicks',
        transaction: 'Publishers with transactions',
        tipTransaction: 'Publishers with Tipbox payments',
    },
    publishersList: 'List of publishers',
    widgeterGeneratorSubheaderText: 'Generate a widget for a single product and put on your website, e.g. under a product review.The widget will display to users in which stores they can buy a given product.',
};

// @todo unused!
