<template>
    <component :is="to ? 'router-link' : 'div'" :to="to" :class="{ 'inline-block': !to && !block }">
        <b-button
            :class="{
                'button-default': true,
                'btn-block': block,
                'button-default-short': short,
            }"
            :type="type"
            :variant="variant"
            :size="size"
            :disabled="loading || disabled"
            @click="$emit('click')"
        >
            <b-spinner v-if="loading" small />
            <div v-else class="v-center">
                <feather
                    v-if="icon"
                    :type="icon"
                    :size="iconSize"
                    class="mr-2"
                />
                <slot></slot>
            </div>
        </b-button>
    </component>
</template>

<script>
    export default {
        name: 'ButtonDefault',
        props: {
            /**
             * Loading state.
             */
            loading: {
                type: Boolean,
                default: false,
            },
            /**
             * Disabled state.
             */
            disabled: {
                type: Boolean,
                default: false,
            },
            /**
             * Bootstrap button variant: https://bootstrap-vue.org/docs/components/button
             * @values primary, outline-primary
             */
            variant: {
                type: String,
                default: 'primary',
            },
            /**
             * Feather icon type: https://feathericons.com/
             */
            icon: {
                type: String,
                default: undefined,
            },
            iconSize: {
                type: Number,
                default: 18,
            },
            block: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: undefined,
            },
            /**
             * Bootstrap button size: https://bootstrap-vue.org/docs/components/button
             * @values sm, lg
             */
            size: {
                type: String,
                default: undefined,
            },
            short: {
                type: Boolean,
                default: false,
            },
            to: {
                type: Object,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .inline-block {
        display: inline-block;
    }

    .button-default {
        $padding: 30px;
        padding-left: $padding;
        padding-right: $padding;
    }

    .button-default-short {
        $padding: 12px;
        padding-left: $padding;
        padding-right: $padding;
    }

    .v-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
