import { url as isValidUrl } from 'vuelidate/lib/validators';

export const image = file => Boolean(file?.type?.startsWith('image'));

export const unique = (value, list) => !list.includes(value);

export const uniqueUrl = (value, list) => {
    const createClearUrl = urlString => {
        const validUrlString = /(http(s?)):\/\//i.test(urlString) ? urlString : `http://${urlString}`;
        if (!isValidUrl(validUrlString)) return urlString;
        const urlData = new URL(validUrlString);
        const hostWithoutWWW = urlData.host.startsWith('www.') ? urlData.host.replace('www.', '') : urlData.host;
        const url = hostWithoutWWW + urlData.pathname + urlData.search;
        return url;
    };
    const url = createClearUrl(value);
    const urlList = list.map(createClearUrl);
    return !urlList.includes(url);
};

export const url = value => {
    try {
        new URL(value); // eslint-disable-line no-new
        return value.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm).length === 1; // eslint-disable-line no-useless-escape
    } catch {
        return false;
    }
};
