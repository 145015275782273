import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    USERS: {
        URI: () => '/api/v1/users/{{id?}}',
        methods: ['get', 'patch', 'delete'],
        default: null,
        cacheToClear: ['/api/v1/users'],
    },
    USER_ROLES: {
        URI: () => '/api/v1/user-roles',
        methods: ['get'],
        default: [],
    },
    PUBLISHERS: {
        URI: () => '/api/v1/publishers',
        methods: ['post', 'patch'],
    },
    PRODUCERS: {
        URI: () => '/api/v1/producers',
        methods: ['post', 'patch'],
    },
    REPORTERS: {
        URI: () => '/api/v1/reporters',
        methods: ['post', 'patch'],
    },
    USER_TOKEN: {
        URI: () => '/api/v1/users/{{id}}/token',
        methods: ['get'],
        cache: false,
    },
    RESET_PASSWORD: {
        URI: () => '/api/v1/users/{{id}}/reset-password',
        methods: ['post'],
        cache: false,
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
