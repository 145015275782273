<template>
    <div v-if="bbId">
        <component
            :is="htmlTag"
            id="bb-widget-preview"
            class="bb-widget border-0 bg-none"
            data-bb-stats="0"
            :data-bb-type="bbType === 'widget' ? undefined : bbType"
            :data-bb-popover-event="bbPopoverEvent"
            :data-bb-language="bbLanguage"
            :data-bb-id="bbId"
            :data-bb-oid="bbOfferId"
            :data-bb-number="bbNumber"
            :data-bb-name="bbName"
            :data-bb-info="bbInfo"
            :data-bb-shop-style="bbShopStyle"
            :data-bb-sort="bbSort"
            :data-bb-lead-color="bbLeadColor"
            :data-bb-button-label="bbButtonLabel"
            :data-bb-row-count="bbRowCount"
            :data-bb-show-extra="bbShowExtraValue"
            :data-bb-show-prices="bbShowPricesValue"
            :data-bb-p1="bbAbpar1"
            :data-bb-p2="bbAbpar2"
            :data-bb-p3="bbAbpar3"
            :data-bb-alt="hasTrigger ? undefined : 'bb-alt'"
        >
            <span v-if="hasTrigger" class="preview-link">
                {{ $t('widgetPreview.show') }}
            </span>
        </component>
        <div v-if="!hasTrigger" id="bb-alt" class="title text-xs-center bb-alt" style="display: none;">
            {{ $t('widgetPreview.noOffers') }}
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import { isEmpty } from '@/utils/util';

    export default {
        name: 'WidgetPreview',
        props: {
            bbType: {
                validator: value => ['widget', 'popover', 'overlay', 'logos-only'].includes(value),
            },
            bbPopoverEvent: {
                type: String,
                validator: value => ['hover', 'click'].includes(value),
            },
            bbLanguage: {
                type: String,
            },
            bbId: {
                type: Number,
                required: true,
            },
            bbOfferId: {
                type: Number,
            },
            bbNumber: {
                type: Number,
            },
            bbName: {
                type: String,
            },
            bbInfo: {
                type: String,
            },
            bbShopStyle: {
                type: String,
            },
            bbSort: {
                type: String,
            },
            bbLeadColor: {
                type: String,
            },
            bbButtonLabel: {
                type: String,
            },
            bbRowCount: {
                type: Number,
            },
            bbShowExtra: {
                type: Boolean,
            },
            bbShowPrices: {
                type: Boolean,
            },
            bbAbpar1: {
                type: String,
            },
            bbAbpar2: {
                type: String,
            },
            bbAbpar3: {
                type: String,
            },
            htmlTag: {
                type: String,
                default: 'span',
            },
            reloadDelay: {
                type: Number,
                default: 1000,
            },
        },
        data() {
            return {
                reloadBBDebounced: () => {},
            };
        },
        computed: {
            bbShowExtraValue() {
                if (isEmpty(this.bbShowExtra)) {
                    return undefined;
                }
                return this.bbShowExtra ? 'product_name-info-img' : 'false';
            },
            bbShowPricesValue() {
                if (isEmpty(this.bbShowPrices)) {
                    return undefined;
                }
                return this.bbShowPrices ? 'true' : 'false';
            },
            hasTrigger() {
                return ['popover', 'overlay'].includes(this.bbType);
            },
        },
        watch: {
            hasTrigger() {
                document.getElementById(`bb-alt${this.bbId}`)?.remove();
            },
        },
        methods: {
            loadWidgetScript() {
                if (window.bb) {
                    return;
                }
                const script = document.createElement('script');
                script.src = process.env.VUE_APP_WIDGET_SCRIPT_URL;
                script.async = true;
                document.body.appendChild(script);
            },
            reloadBB() {
                document.getElementById(`bb-alt${this.bbId}`)?.remove();
                if (window.bb) {
                    window.bb.forceReload();
                    this.$emit('reloadBB');
                }
            },
        },
        created() {
            this.reloadBBDebounced = debounce(this.reloadBB, this.reloadDelay);
        },
        mounted() {
            this.reloadBBDebounced();
            this.loadWidgetScript();
        },
        updated() {
            this.reloadBBDebounced();
        },
    };
</script>

<style lang="scss" scoped>
    .preview-link {
        text-decoration: underline;
        color: blue;
        cursor: pointer;
    }
</style>
