<template>
    <div>
        <PageHeader>
            {{ $t('catalog') }} - {{ $t('brands') }}
            <template #side>
                <ButtonIcon
                    class="ml-2"
                    icon="plus"
                    variant="primary"
                    :disabled="isLoading"
                    @click="editItem()"
                >
                    {{ $t('addNew') }}
                </ButtonIcon>
            </template>
        </PageHeader>

        <Card :header="$t('filters')">
            <b-form-group :label="$t('name')" label-cols-sm="3">
                <b-form-input v-model="filters.name" :disabled="isLoading" />
            </b-form-group>

            <b-form-group :label="$t('manufacturer')" label-cols-sm="3">
                <Multiselect
                    :clear-on-select="false"
                    :close-on-select="true"
                    :show-no-results="true"
                    :show-no-options="true"
                    :preserve-search="true"
                    :asynchronous="true"
                    :fetch-options-function="fetchManufacturers"
                    :parse-options-function="parseManufacturers"
                    :placeholder="$t('selectField.placeholder')"
                    track-by="id"
                    label="name"
                    select-label=""
                    :disabled="isLoading"
                    @search-change="value => manufacturersSearchText = value"
                    @input="value => filters.manufacturer = value"
                />
            </b-form-group>

            <ButtonIcon :loading="isLoading" icon="filter" @click="fetchData()">
                {{ $t('filter') }}
            </ButtonIcon>
        </Card>

        <DataTable
            :items="items"
            :fields="fields"
            :total-rows="brands.max"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :sort-by.sync="sortBy"
            :sort-direction.sync="sortDirection"
            :loading="isLoading"
            no-local-sorting
        >
            <template #cell(action)="data">
                <div class="d-flex gap-1 justify-content-end">
                    <ButtonIcon
                        size="sm"
                        icon="edit-2"
                        variant="primary"
                        :disabled="isItemBusy(data.item.id)"
                        :loading="isItemUpdated(data.item.id)"
                        @click="editItem(data.item)"
                    />
                    <ButtonIcon
                        size="sm"
                        icon="trash-2"
                        variant="danger"
                        :disabled="isItemBusy(data.item.id)"
                        :loading="isItemDeleted(data.item.id)"
                        @click="deleteItem(data.item.id)"
                    />
                </div>
            </template>
        </DataTable>
        <ModalForm />
    </div>
</template>



<script>
    import catalogService from '@/services/catalogService';
    import DataTable from '@/components/common/DataTable.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import ModalForm from './parts/ModalForm.vue';
    import { createItemKey } from '@/store/modules/moduleFactory';
    import createRequest from '@/utils/createRequest';

    export default {
        name: 'CatalogBrands',
        components: {
            DataTable,
            Multiselect,
            ModalForm,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                fields: [
                    {
                        key: 'id',
                        label: this.$t('id'),
                        sortable: true,
                    },
                    {
                        key: 'name',
                        label: this.$t('name'),
                        sortable: true,
                    },
                    {
                        key: 'manufacturer.name',
                        label: this.$t('manufacturer'),
                    },
                    {
                        key: 'action',
                        label: '',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ],
                perPage: 10,
                currentPage: 1,
                sortBy: 'id',
                sortDirection: 'desc',
                manufacturersSearchText: '',
                filters: {
                    name: '',
                    manufacturer: null,
                },
                requestSpace: createRequest('space/space'),
                requestSpaces: createRequest('space/spaces'),
                requestBrands: createRequest('catalog/brands'),
            };
        },
        computed: {
            requestParams() {
                return {
                    ...this.tableParams,
                    ...this.filters,
                    manufacturer: this.filters.manufacturer?.id || null,
                };
            },
            tableParams() {
                return {
                    page: this.currentPage,
                    perPage: this.perPage,
                    sort: this.sortBy,
                    order: this.sortDirection.toUpperCase(),
                };
            },
            brands() {
                return this.$store.getters['catalog/getBrands'];
            },
            items() {
                return this.brands.items || [];
            },
            error() {
                return this.$store.getters['catalog/getError']('brands');
            },
            isLoading() {
                return this.$store.getters['catalog/getLoading']('brands');
            },
            isItemBusy() {
                return id => this.$store.getters['catalog/getBusyItem']('brands', createItemKey({ id }));
            },
            isItemUpdated() {
                return id => this.$store.getters['catalog/getUpdatedItem']('brands', createItemKey({ id }));
            },
            isItemDeleted() {
                return id => this.$store.getters['catalog/getDeletedItem']('brands', createItemKey({ id }));
            },
        },
        watch: {
            tableParams: {
                immediate: true,
                handler: 'fetchData',
            },
            error() {
                if (this.error) this.$toastr.error(this.error.message, this.$t('errorOccurred'));
            },
        },
        methods: {
            clearData() {
                this.$store.commit('catalog/clearBrands');
            },
            fetchData() {
                this.$store.dispatch('catalog/fetchBrands', { requestParams: this.requestParams }).catch(() => {});
            },
            deleteItem(id) {
                this.$bvModal
                    .msgBoxConfirm(this.$t('confirmYouWantDelete'), {
                        title: this.$t('confirm'),
                        okTitle: this.$t('yes'),
                        cancelTitle: this.$t('cancel'),
                        okVariant: 'danger',
                    })
                    .then(value => {
                        if (value) {
                            this.deleteBrandAndUpdateSpaces(id);
                        }
                    });
            },
            editItem(item = null) {
                this.$root.$emit('bv::show::modal', 'modal-form-catalog-brands');
                this.$root.$emit('modal-form-catalog-brands', item);
            },
            fetchManufacturers() {
                const requestParams = { name: this.manufacturersSearchText };
                return catalogService.getManufacturers({ requestParams });
            },
            parseManufacturers(data) {
                return data?.items || [];
            },
            fetchSpaces(brandId) {
                return this.requestSpaces.get(null, { brandIds: brandId });
            },
            updateSpaces() {
                const ids = this.requestSpaces.data?.items?.map(item => item.id) || [];
                return Promise.all(ids.map(this.updateSpace));
            },
            updateSpace(id) {
                return this.requestSpace.patch(id, { brandId: null });
            },
            deleteBrand(id) {
                return this.$store.dispatch('catalog/deleteBrands', { urlParams: { id } }).then(this.fetchData);
            },
            async deleteBrandAndUpdateSpaces(brandId) {
                const setDeletedItem = value => {
                    this.$store.commit('catalog/setBusy', { key: 'brands', value });
                    this.$store.commit('catalog/setBusyItem', { key: 'brands', itemKey: createItemKey({ id: brandId }), value });
                    this.$store.commit('catalog/setDeletedItem', { key: 'brands', itemKey: createItemKey({ id: brandId }), value });
                };
                const setError = error => this.$store.commit('catalog/setError', { key: 'brands', value: error });
                try {
                    setError(null);
                    setDeletedItem(true);
                    await this.fetchSpaces(brandId);
                    await this.updateSpaces();
                    await this.deleteBrand(brandId);
                } catch (error) {
                    if (!this.error) setError(error);
                } finally {
                    setDeletedItem(false);
                    this.requestSpace.reset();
                    this.requestSpaces.reset();
                    this.requestBrands.reset();
                }
            },
        },
        created() {
            this.clearData();
        },
    };
</script>
