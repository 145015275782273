<template>
    <b-modal
        id="modal-chart"
        ref="modal"
        :title="modalTitle"
        :ok-only="true"
        :ok-title="$t('close')"
        size="lg"
        centered
        @show="clearChartData()"
        @hidden="clearChartData()"
    >
        <MultiChartCard
            :data="chartData"
            :height="400"
            :loading="loading"
            :options="chartOptions"
        />
    </b-modal>
</template>



<script>
    import { generateChartData } from '@/utils/chart';
    import MultiChartCard from '@/components/common/MultiChartCard.vue';

    export default {
        name: 'ModalChart',
        components: { MultiChartCard },
        data() {
            return {
                item: null,
                spaceIds: null,
                campaignIds: null,
                timeRange: null,
                chartOptions: {
                    chart: {
                        type: 'bar',
                    },
                    fill: {
                        opacity: 1,
                        type: 'solid',
                    },
                },
            };
        },
        computed: {
            modalTitle() {
                return this.item?.name;
            },
            requestParams() {
                return {
                    spaces: (this.spaceIds || []).join(','),
                    campaigns: (this.campaignIds || []).join(','),
                    startDate: this.timeRange?.startDate,
                    endDate: this.timeRange?.endDate,
                    aggregations: this.item?.id,
                };
            },
            loading() {
                return this.$store.getters['userStats/getLoading']('aggregationSalesChart');
            },
            error() {
                return this.$store.getters['userStats/getError']('aggregationSalesChart')?.message;
            },
            data() {
                return this.$store.getters['userStats/getAggregationSalesChart']?.map(item => ({ date: item.date, value: item.quantity })) || [];
            },
            chartData() {
                return {
                    orders: {
                        name: this.$t('dashboard.unitsSold'),
                        series: [
                            {
                                data: generateChartData(this.timeRange, this.data).map(item => [item.date, item.value]),
                                name: '',
                            },
                        ],
                    },
                };
            },
        },
        watch: {
            item() {
                if (this.item?.id) this.getChartData();
            },
        },
        methods: {
            clearChartData() {
                this.item = null;
                this.spaceIds = null;
                this.campaignIds = null;
                this.timeRange = null;
                this.$store.commit('userStats/setAggregationSalesChart', null);
            },
            getChartData() {
                this.$store.dispatch('userStats/fetchAggregationSalesChart', { requestParams: this.requestParams });
            },
        },
        mounted() {
            this.$root.$on('modal-chart-item', data => {
                this.item = data.item;
                this.spaceIds = data.spaceIds;
                this.campaignIds = data.campaignIds;
                this.timeRange = data.timeRange;
            });
        },
    };
</script>
