<template>
    <b-overlay :show="loading || isSpacesLoading" rounded="sm" class="h-100">
        <div class="card h-100">
            <div class="card-body">
                <ErrorMessage :value="error" />
                <h4 class="header-title mt-0 mb-3">
                    {{ $t('overview') }}
                </h4>
                <Chart
                    height="380"
                    type="bar"
                    class="apex-charts"
                    :series="series"
                    :options="options"
                />
            </div>
        </div>
    </b-overlay>
</template>



<script>
    import Role from '@/Role';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import Chart from '@/components/common/Chart.vue';
    import { generateChartData, chartDataGroupBy } from '@/utils/chart';

    export default {
        name: 'TipsChart',
        inject: {
            parentComponent: {
                default: null,
            },
        },
        components: {
            ErrorMessage,
            Chart,
        },
        data() {
            return {
                options: {
                    colors: ['#5369f8'],
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top',
                            },
                        },
                    },
                    xaxis: {
                        title: {
                            text: '',
                            offsetY: 7,
                            style: {
                                fontSize: 14,
                                fontWeight: 300,
                            },
                        },
                    },
                    tooltip: {
                        x: {
                            show: true,
                            format: 'MMMM y',
                        },
                        y: {
                            title: {
                                formatter: seriesName => '',
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            show: false,
                            formatter: val => this.$format.currency(val),
                        },
                    },
                },
            };
        },
        computed: {
            isSpacesLoading() {
                return this.parentComponent.isSpacesLoading && this.userRole === Role.WIDGETER;
            },
            userRole() {
                return this.$store.getters['auth/getRoleName'];
            },
            apiParams() {
                const requestParams = {
                    startDate: this.parentComponent.filters.timeRange?.startDate,
                    endDate: this.parentComponent.filters.timeRange?.endDate,
                    groupBy: this.groupBy,
                    statuses: 'correct,payout_requested,paid_out',
                };
                if (this.parentComponent.filters.spaces?.length) requestParams.spaces = this.parentComponent.filters.spaces.map(item => item.value).join(',');
                return { requestParams };
            },
            loading() {
                return this.$store.getters['tipTransactions/getLoading']('chart');
            },
            error() {
                return this.$store.getters['tipTransactions/getError']('chart');
            },
            chartData() {
                return this.$store.getters['tipTransactions/getChart'];
            },
            groupBy() {
                return chartDataGroupBy(this.parentComponent.filters.timeRange);
            },
            series() {
                const chartItem = this.chartData.map(item => [item.date, item.amount]);
                return [{ data: generateChartData(this.parentComponent.filters.timeRange, chartItem, this.groupBy) }];
            },
        },
        watch: {
            apiParams() {
                if (!this.parentComponent.isSpacesLoading) this.fetchData();
            },
        },
        methods: {
            fetchData() {
                this.$store.dispatch('tipTransactions/fetchChart', this.apiParams);
            },
        },
    };
</script>
