<template>
    <Card :header="$t('filters')">
        <ErrorMessage :value="error" />

        <form @keyup.enter="fetchSettlements()">
            <b-form-group :label="$t('advertiser')" label-cols-sm="3">
                <Multiselect
                    :clear-on-select="true"
                    :close-on-select="true"
                    :show-no-results="true"
                    :show-no-options="true"
                    :preserve-search="true"
                    :show-checkboxes="false"
                    :multiple="false"
                    :show-selected="true"
                    :asynchronous="true"
                    :fetch-options-function="fetchAdvertisers"
                    :parse-options-function="parseAdvertisers"
                    :placeholder="$t('selectField.placeholder')"
                    track-by="id"
                    label="name"
                    :custom-label="option => `${option.profile.firstName} ${option.profile.lastName} (${option.username})`"
                    select-label=""
                    :disabled="loading"
                    @search-change="value => (advertiserSearchText = value)"
                    @input="value => (filters.advertiser = value)"
                />
            </b-form-group>

            <b-form-group :label="$t('shop')" label-cols-sm="3">
                <Multiselect
                    :disabled="!filters.advertiser"
                    :options="campaigns"
                    :parse-options-function="parseCampaigns"
                    :placeholder="$t('selectField.placeholder')"
                    track-by="id"
                    label="name"
                    select-label=""
                    :value="filters.campaigns"
                    @input="value => (filters.campaigns = value ? value.id : null)"
                />
            </b-form-group>

            <div class="d-flex gap-3">
                <ButtonIcon :loading="loading" icon="filter" @click="fetchSettlements()">
                    {{ $t('filter') }}
                </ButtonIcon>
            </div>
        </form>
    </Card>
</template>

<script>
    import clone from 'lodash/clone';
    import userService from '@/services/userService';
    import Multiselect from '@/components/common/Multiselect.vue';
    import Role from '@/Role';

    export default {
        name: 'Filters',
        components: {
            Multiselect,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                advertiserSearchText: null,
                currentFilters: {},
                filters: {
                    advertiser: '',
                    campaigns: '',
                },
            };
        },
        computed: {
            filtersParams() {
                const filters = {};
                if (this.filters.advertiser) filters.advertisers = this.filters.advertiser.id;
                if (this.filters.campaigns) filters.campaigns = this.filters.campaigns;
                return filters;
            },
            tableParams() {
                return {
                    sort: this.parentComponent.sortBy,
                    order: this.parentComponent.sortDirection?.toUpperCase(),
                    page: this.parentComponent.currentPage,
                    perPage: this.parentComponent.perPage,
                };
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                    ...this.tableParams,
                };
            },
            error() {
                return this.$store.getters['advertiserSettlements/getError']('settlements');
            },

            loading() {
                return this.$store.getters['advertiserSettlements/getLoading']('settlements');
            },

            isFiltersChanged() {
                return JSON.stringify(this.filtersParams) !== JSON.stringify(this.currentFilters);
            },

            campaigns() {
                const items = this.$store.getters['advertiserSettlements/getReporterCampaigns'];
                if (Array.isArray(items.items)) {
                    return items.items;
                }
                return [];
            },
        },
        watch: {
            tableParams: {
                deep: true,
                handler: 'fetchSettlements',
            },
            'filtersParams.advertisers': {
                handler(value) {
                    if (value) this.fetchCampaigns();
                },
            },
        },
        methods: {
            resetCurrentPage() {
                this.parentComponent.currentPage = 1;
            },
            fetchSettlements() {
                if (this.isFiltersChanged) this.resetCurrentPage();
                this.currentFilters = clone(this.filtersParams);

                this.$store.dispatch('advertiserSettlements/fetchSettlements', { requestParams: this.requestParams }).catch(() => {});
            },
            fetchAdvertisers() {
                const requestParams = { search: this.advertiserSearchText, roles: [Role.ADVERTISER] };
                return userService.getUsers({ requestParams });
            },
            parseAdvertisers(data) {
                return data?.items || [];
            },
            parseCampaigns(data) {
                const items = data?.items || [];
                if (items.length) return [{ id: null, name: this.$t('all') }, ...items];
                return [...items];
            },
            fetchCampaigns() {
                const advertiserId = this.filtersParams.advertisers;
                if (!advertiserId) return [];

                return this.$store.dispatch('advertiserSettlements/fetchReporterCampaigns', { urlParams: { advertiserId } });
            },
            clearStoreCampaigns() {
                this.$store.commit('advertiserSettlements/clearReporterCampaigns');
            },
        },
        created() {
            this.clearStoreCampaigns();
            this.fetchSettlements();
        },
    };
</script>
