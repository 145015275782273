import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    REGISTRATION_STATS: {
        URI: () => '/api/v1/publisher/conversion-publishers-stats',
        methods: ['get'],
        default: {},
    },
    TRANSACTION_STATS: {
        URI: () => '/api/v1/publisher/conversion-publishers-by-transaction-stats',
        methods: ['get'],
        default: {},
    },
    SPACE_STATS: {
        URI: () => '/api/v1/publisher/conversion-publishers-by-space-stats',
        methods: ['get'],
        default: {},
    },
    CLICK_STATS: {
        URI: () => '/api/v1/publisher/conversion-publishers-by-click-stats',
        methods: ['get'],
        default: {},
    },
    TIP_TRANSACTION_STATS: {
        URI: () => '/api/v1/publisher/conversion-publishers-by-tip-transaction-stats',
        methods: ['get'],
        default: {},
    },
    UNIQUE_SOURCES: {
        URI: () => '/api/v1/publishers/unique-sources',
        methods: ['get'],
        default: [],
        formatter: data => data?.items,
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
