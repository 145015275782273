<template>
    <div>
        <PageHeader>
            {{ $t('integrationStatus') }}
        </PageHeader>

        <Card>
            <form class="d-flex flex-column gap-3" @keyup.enter="fetchData()">
                <div class="d-flex flex-wrap gap-3">
                    <b-form-group :label="$t('lastTransaction')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                        <b-input-group>
                            <b-form-input v-model="filters.lastTransactionDaysAgo" type="number" min="0" />
                            <b-input-group-append>
                                <b-input-group-text class="bg-transparent font-weight-bold">
                                    {{ $t('days') }}
                                </b-input-group-text>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <b-form-group :label="$t('integrationStatus')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                        <b-form-select v-model="filters.isInstalled" :options="filtersOptions.hidden" add-null-option />
                    </b-form-group>
                </div>

                <div class="d-flex flex-wrap gap-3">
                    <b-form-group :label="$t('hidden')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                        <b-form-select v-model="filters.hidden" :options="filtersOptions.hidden" add-null-option />
                    </b-form-group>
                    <b-form-group :label="$t('active')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                        <b-form-select v-model="filters.active" :options="filtersOptions.hidden" add-null-option />
                    </b-form-group>
                </div>

                <div class="d-flex gap-3">
                    <ButtonIcon :disabled="loading" @click="fetchData()">
                        {{ $t('search2') }}
                    </ButtonIcon>
                    <ButtonIcon variant="light" :disabled="loading" @click="reset()">
                        {{ $t('clear') }}
                    </ButtonIcon>
                </div>

            </form>
        </Card>

        <DataTable
            :error="error"
            :items="items"
            :fields="fields"
            :total-rows="responseData.max"
            :current-page.sync="tableConfig.currentPage"
            :per-page.sync="tableConfig.perPage"
            :sort-by.sync="tableConfig.sortBy"
            :sort-direction.sync="tableConfig.sortDirection"
            :loading="loading"
            no-local-sorting
        >
            <template #cell(campaign)="data">
                {{ data.item.campaign.id }}
            </template>
            <template #cell(testUrl)="data">
                <b-link :href="data.value" target="_blank">{{ data.value }}</b-link>
            </template>
            <template #cell(lastCheckedAt)="data">
                <template v-if="data.value">{{ $format.dateAndHour(data.value) }}</template>
            </template>
            <template #cell(lastCookieAt)="data">
                <template v-if="data.value">{{ $format.dateAndHour(data.value) }}</template>
            </template>
            <template #cell(lastTransactionAt)="data">
                <template v-if="data.value">{{ $format.dateAndHour(data.value) }}</template>
            </template>
            <template #cell(filesUpdatedAt)="data">  
                <BadgeFeedUpdate v-for="(value, key) in data.value" :key="key" :value="value" :label="key" />
            </template>
            <template #cell(ratio)="data">
                <template v-if="data.value && !isNaN(data.value)">{{ parseFloat(data.value).toFixed(2) }}</template>
                <template v-else>--</template>
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #cell(campaign.hidden)="data">
                <BadgeBoolean v-if="typeof data.value === 'boolean'" :value="data.value" true-value-badge-variant="danger" false-value-badge-variant="success" />
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #cell(campaign.isActive)="data">
                <BadgeBoolean v-if="typeof data.value === 'boolean'" :value="data.value" />
            </template>
            <template #cell(isInstalled)="data">
                <BadgeBoolean v-if="typeof data.value === 'boolean'" :value="data.value" />
            </template>
        </DataTable>
    </div>
</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';
    import BadgeBoolean from '@/components/common/BadgeBoolean.vue';
    import BadgeFeedUpdate from '@/components/common/BadgeFeedUpdate.vue';

    export default {
        name: 'IntegrationStatus',
        components: {
            DataTable,
            BadgeBoolean,
            BadgeFeedUpdate,
        },
        data() {
            return {
                filters: {
                    hidden: null,
                    active: null,
                    isInstalled: null,
                    lastTransactionUntil: null,
                },

                tableConfig: {
                    perPage: 50,
                    currentPage: 1,
                    sortBy: 'isInstalled',
                    sortDirection: 'asc',
                },
            };
        },
        computed: {
            filtersOptions() {
                return {
                    hidden: [
                        { value: true, text: this.$t('yes') },
                        { value: false, text: this.$t('no') },
                    ],
                };
            },
            lastTransactionUntil() {
                if (this.filters.lastTransactionDaysAgo) {
                    const date = new Date();
                    date.setDate(date.getDate() - this.filters.lastTransactionDaysAgo);
                    return date.toISOString().split('T')[0];
                }
                return null;
            },
            fields() {
                return [
                    {
                        key: 'campaign',
                        label: this.$t('shopId'),
                        sortable: true,
                    },
                    {
                        key: 'campaign.name',
                        label: this.$t('shopName'),
                        sortable: false,
                    },
                    {
                        key: 'testUrl',
                        label: this.$t('testedUrl'),
                        sortable: false,
                    },
                    {
                        key: 'lastCheckedAt',
                        label: this.$t('date'),
                        sortable: true,
                    },
                    {
                        key: 'isInstalled',
                        label: this.$t('integrationStatus'),
                        sortable: true,
                    },
                    {
                        key: 'campaign.isActive',
                        label: this.$t('active'),
                        sortable: false,
                    },
                    {
                        key: 'campaign.hidden',
                        label: this.$t('hidden'),
                        sortable: false,
                    },
                    {
                        key: 'lastCookieAt',
                        label: this.$t('lastCookie'),
                        sortable: true,
                    },
                    {
                        key: 'filesUpdatedAt',
                        label: this.$t('filesUpdatedAt'),
                        sortable: false,
                    },
                    {
                        key: 'ratio',
                        label: this.$t('ratioClicksToCookies'),
                        sortable: true,
                    },
                    {
                        key: 'lastTransactionAt',
                        label: this.$t('lastTransaction'),
                        sortable: true,
                    },
                ];
            },
            responseData() {
                return this.$store.getters['campaigns/getIntegrationReports'];
            },
            items() {
                return this.responseData.items || [];
            },
            error() {
                return this.$store.getters['campaigns/getError']('integrationReports');
            },
            loading() {
                return this.$store.getters['campaigns/getLoading']('integrationReports');
            },
            filtersParams() {
                return {
                    hidden: this.filters.hidden,
                    active: this.filters.active,
                    isInstalled: this.filters.isInstalled,
                    lastTransactionUntil: this.lastTransactionUntil,
                };
            },
            tableParams() {
                return {
                    page: this.tableConfig.currentPage,
                    perPage: this.tableConfig.perPage,
                    sort: this.tableConfig.sortBy,
                    order: this.tableConfig.sortDirection.toUpperCase(),
                };
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                    ...this.tableParams,
                };
            },
        },
        watch: {
            tableParams: {
                immediate: true,
                handler: 'fetchData',
            },
        },
        methods: {
            fetchData() {
                this.$store.dispatch('campaigns/fetchIntegrationReports', { requestParams: this.requestParams }).catch(() => {});
            },
            reset() {
                this.filters = {
                    hidden: null,
                    active: null,
                    isInstalled: null,
                    lastTransactionUntil: null,
                };
                this.tableConfig = {
                    perPage: 50,
                    currentPage: 1,
                    sortBy: 'isInstalled',
                    sortDirection: 'asc',
                };

                this.fetchData();
            },
        },
    };
</script>
