<template>
    <div class="account-pages my-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-10">
                    <card :header="$t('confirmEmail.title')">
                        <div v-if="loading" class="d-flex align-items-center">
                            <b-spinner />
                            <span class="ml-2">{{ $t('loading') }}...</span>
                        </div>
                        <b-alert v-else-if="message" variant="success" show>
                            {{ message }}
                        </b-alert>
                        <b-alert v-else-if="error" variant="danger" show>
                            {{ error }}
                        </b-alert>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
    import Card from '@/components/common/Card.vue';

    export default {
        name: 'ConfirmEmail',
        props: {
            token: String,
        },
        components: {
            Card,
        },
        data() {
            return {
                error: null,
                message: null,
                loading: null,
            };
        },
        methods: {
            confirmEmail(token) {
                return this.$store.dispatch('auth/confirmEmail', { token });
            },
            submit() {
                this.error = null;
                this.message = null;
                this.loading = true;
                this.confirmEmail(this.token)
                    .then(() => (this.message = this.$t('confirmEmail.success')))
                    .catch(error => (this.error = error.message))
                    .finally(() => (this.loading = false));
            },
        },
        created() {
            if (this.token) {
                this.submit();
            } else {
                this.error = this.$t('confirmEmail.missingToken');
            }
        },
    };
</script>
