import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    BRANDS: {
        URI: () => '/api/v1/catalog/brands/{{id?}}',
        methods: ['get', 'post', 'patch', 'delete'],
        default: {},
        cacheToClear: ['/api/v1/catalog/brands'],
    },
    CATEGORIES: {
        URI: () => '/api/v1/catalog/categories/{{id?}}',
        methods: ['get', 'post', 'patch', 'delete'],
        default: {},
        cacheToClear: ['/api/v1/catalog/categories'],
    },
    MANUFACTURERS: {
        URI: () => '/api/v1/catalog/manufacturers/{{id?}}',
        methods: ['get', 'post', 'patch', 'delete'],
        default: {},
        cacheToClear: ['/api/v1/catalog/manufacturers'],
    },
    PRODUCTS: {
        URI: () => '/api/v1/catalog/products/{{id?}}',
        methods: ['get', 'post', 'patch', 'delete'],
        default: {},
        cacheToClear: ['/api/v1/catalog/products'],
    },
    IMPORT_ISSUES: {
        URI: () => '/api/v1/catalog/import-issues',
        methods: ['get'],
        default: {},
    },
    DUPLICATES: {
        URI: () => '/api/v1/catalog/products/{{id}}/duplicates',
        methods: ['post'],
        cacheToClear: ['/api/v1/catalog/products'],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
