<template>
    <table-default
        :ref="TABLE_REF"
        :items="requestResult.items"
        :total-rows="requestResult.max"
        :page="request.page"
        :per-page="request.perPage"
        :loading="request.loading"
        :show-pagination="shouldShowPagination"
        :max-height="maxHeight"
        v-bind="{...passedDownProps, ...$attrs}"
        @pageChange="handlePageChange"
        v-on="$listeners"
    >
        <!-- passing down slots -->
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope"></slot>
        </template>
    </table-default>
</template>
<script>
    import TableDefault from '@/components/common/TableDefault.vue';
    import WrapRequest from '@/utils/wrapRequest';
    import { omit } from 'lodash';
    import tableExtensionMixin from '@/mixins/tableExtensionMixin';

    export default {
        name: 'TableRequest',
        mixins: [tableExtensionMixin],
        props: {
            request: {
                type: WrapRequest,
                required: true,
            },
            parse: {
                type: Function,
            },
            maxHeight: {
                type: String,
            },
        },
        components: { TableDefault },
        computed: {
            passedDownProps() {
                return omit(this.$props, ['request', 'items', 'totalRows', 'page', 'loading', 'showPagination', 'perPage']);
            },
            requestResult() {
                const { result } = this.request;

                if (!result) {
                    return {};
                }
                const finalResult = result.items || result;

                return {
                    items: this.parse ? this.parse(finalResult) : finalResult,
                    max: result.max,
                };
            },
            shouldShowPagination() {
                return Boolean(this.requestResult.max && !this.request.loading);
            },
        },
        methods: {
            handlePageChange(page) {
                this.request.request({ page });
            },
        },
        extends: TableDefault,
    };
</script>
