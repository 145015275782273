<template>
    <Card :header="$t('filters')">
        <ErrorMessage :value="error" />

        <form class="d-flex flex-column gap-3" @keyup.enter="search()">

            <div class="d-flex flex-wrap gap-3">
                <b-form-group :label="$t('name')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.name" />
                </b-form-group>
                <b-form-group :label="$t('eanNumber')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.ean" />
                </b-form-group>
            </div>

            <div class="d-flex flex-wrap gap-3">
                <b-form-group :label="$t('info')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.info" />
                </b-form-group>
                <b-form-group :label="$t('code')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.code" />
                </b-form-group>
            </div>

            <div class="d-flex flex-wrap gap-3">
                <b-form-group :label="$t('publisher')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <Multiselect
                        :placeholder="$t('selectField.placeholder')"
                        :asynchronous="true"
                        track-by="id"
                        label="username"
                        select-label=""
                        :fetch-options-function="fetchUsers"
                        :parse-options-function="parseUsers"
                        :disabled="loading"
                        @loading="value => isUsersLoading = value"
                        @search-change="value => userSearchText = value"
                        @input="value => filters.publisher = value.id"
                    />
                </b-form-group>
                <b-form-group :label="$t('search')" class="flex-grow-1 flex-basis-0 width-min-250 mb-0">
                    <b-form-input v-model="filters.search" />
                </b-form-group>
            </div>

            <div class="d-flex gap-3">
                <ButtonIcon :disabled="loading" @click="search()">
                    {{ $t('search2') }}
                </ButtonIcon>
                <ButtonIcon variant="light" :disabled="loading" @click="reset()">
                    {{ $t('clear') }}
                </ButtonIcon>
            </div>

        </form>

    </Card>
</template>



<script>
    import mapValues from 'lodash/mapValues';
    import Role from '@/Role';
    import authService from '@/services/authService';
    import Multiselect from '@/components/common/Multiselect.vue';

    export default {
        name: 'Filters',
        components: {
            Multiselect,
        },
        data() {
            return {
                userSearchText: '',
                isUsersLoading: false,
            };
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        computed: {
            error() {
                return null;
            },
            loading() {
                return this.$store.getters['product/getLoading']('describedProducts');
            },
            filters() {
                return this.parentComponent.filters;
            },
        },
        methods: {
            search() {
                const filters = this.parentComponent.currentFilters;
                if (Object.keys(filters).length) {
                    if (JSON.stringify(filters) !== JSON.stringify(mapValues(this.$route.query, this.parentComponent.$refs.Results.parseValue))) {
                        this.$router.push({ query: filters }).catch(() => {});
                    } else {
                        this.parentComponent.$refs.Results.fetchData();
                    }
                } else {
                    this.reset();
                }
            },
            reset() {
                this.parentComponent.resetFilters();
                this.$router.push({ query: {} }).catch(() => {});
                this.parentComponent.$refs.Results.clearStoreData();
            },
            fetchUsers() {
                return authService.fetchUserAccount('', { search: this.userSearchText, roles: Role.WIDGETER, page: 1, perPage: Number.MAX_SAFE_INTEGER });
            },
            parseUsers(data) {
                return data?.items || [];
            },
        },
    };
</script>



<style lang="scss" scoped></style>
