<template>
    <b-overlay :show="campaignsLoading" rounded="sm" class="h-100">
        <card :header="$t('dashboard.newCampaigns')" class="mb-0 h-100">
            <div class="overflow-auto">
                <ErrorMessage :value="campaignsError" />
                <b-table
                    hover
                    :items="items"
                    :fields="fields"
                    show-empty
                    :empty-text="$t('noResult')"
                    :busy="campaignsLoading"
                >
                    <template #table-busy>
                        <div class="d-flex justify-content-center my-2">
                            <b-spinner />
                        </div>
                    </template>
                    <template #cell(icon)="data">
                        <b-img :src="data.value" alt="" width="16" />
                    </template>
                    <template #cell(categories)="data">
                        {{ data.value.map(item => item.name).join(', ') }}
                    </template>
                    <template #cell(commissions)="data">
                        {{ formatCommission(data.value) }}
                    </template>
                </b-table>
            </div>
        </card>
    </b-overlay>
</template>



<script>
    import Card from '@/components/common/Card.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';

    export default {
        name: 'WidgetNewCampaigns',
        components: {
            Card,
            ErrorMessage,
        },
        data() {
            return {
                fields: [
                    {
                        key: 'icon',
                        label: '',
                    },
                    {
                        key: 'name',
                        label: this.$t('dashboard.campaign'),
                    },
                    {
                        key: 'categories',
                        label: this.$t('dashboard.category'),
                    },
                    {
                        key: 'commissions',
                        label: this.$t('dashboard.commission'),
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ],
            };
        },
        computed: {
            isRoleWidgeter() {
                return this.$store.getters['auth/isRoleWidgeter'];
            },
            spaceCount() {
                return this.$store.getters['space/spaceCount'];
            },
            campaigns() {
                return this.$store.getters['campaigns/getCampaigns'];
            },
            campaignsError() {
                return this.$store.getters['campaigns/getError']('campaigns')?.message;
            },
            campaignsLoading() {
                return this.$store.getters['campaigns/getLoading']('campaigns');
            },
            items() {
                return this.campaigns.items || [];
            },
        },
        methods: {
            formatCommission(commissions) {
                const prefix = commissions.length > 1 ? `${this.$t('dashboard.to')} ` : '';
                const formatterFn = commissions[0]?.type === 'percent' ? this.$format.percent : this.$format.currency;
                const value = formatterFn(Math.max(...commissions.map(item => item.commission)));
                return prefix + value;
            },
            fetchData(params = {}) {
                if (this.isRoleWidgeter && !this.spaceCount) return;
                const requestParams = {
                    sort: 'createdAt',
                    order: 'DESC',
                    page: 1,
                    perPage: 5,
                    active: true,
                };
                this.$store.dispatch('campaigns/fetchCampaigns', { requestParams, ...params });
            },
        },
        created() {
            this.fetchData();
        },
    };
</script>
