<template>
    <Card :header="$t('filters')">

        <form @keyup.enter="parentComponent.filterData()">

            <b-form-group :label="$t('name')" label-cols-sm="3">
                <b-form-input v-model="parentComponent.filters.name" :disabled="loading" />
            </b-form-group>

            <b-form-group :label="$t('id')" label-cols-sm="3">
                <b-form-input v-model="parentComponent.filters.id" :disabled="loading" />
            </b-form-group>

            <b-form-group :label="$t('advertiser')" label-cols-sm="3">
                <Multiselect
                    :clear-on-select="true"
                    :close-on-select="true"
                    :show-no-results="true"
                    :show-no-options="true"
                    :preserve-search="true"
                    :show-checkboxes="false"
                    :multiple="false"
                    :show-selected="true"
                    :asynchronous="true"
                    :fetch-options-function="fetchAdvertisers"
                    :parse-options-function="parseAdvertisers"
                    :placeholder="$t('selectField.placeholder')"
                    track-by="id"
                    label="name"
                    select-label=""
                    :disabled="loading"
                    :track-by-label="$t('id')"
                    :custom-label="customLabelAdvertiser"
                    :initial-value="parentComponent.filtersParams.advertiser"
                    @inputTrackBy="parentComponent.filters.advertiser = $event"
                />
            </b-form-group>

            <b-form-group :label="$t('categories')" label-cols-sm="3">
                <Multiselect
                    :placeholder="$t('selectField.placeholder')"
                    :fetch-options-function="fetchCategories"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :show-selected="true"
                    :asynchronous="false"
                    :searchable="false"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    select-label=""
                    :disabled="loading"
                    :value="parentComponent.filters.categories"
                    @inputTrackBy="parentComponent.filters.categories = $event"
                />
            </b-form-group>

            <b-form-group :label="$t('active')" label-cols-sm="3">
                <div class="d-flex align-items-center h-100">
                    <b-form-checkbox v-model="parentComponent.filters.active" switch :disabled="loading" />
                </div>
            </b-form-group>

            <b-form-group :label="$t('hidden')" label-cols-sm="3">
                <div class="d-flex align-items-center h-100">
                    <b-form-checkbox v-model="parentComponent.filters.hidden" switch :disabled="loading" />
                </div>
            </b-form-group>

            <div class="d-flex gap-3">
                <ButtonIcon :loading="parentComponent.loading" hide-label-while-loading @click="parentComponent.filterData()">
                    {{ $t('filter') }}
                </ButtonIcon>
                <ButtonIcon variant="light" :disabled="parentComponent.loading" @click="parentComponent.resetData()">
                    {{ $t('reset') }}
                </ButtonIcon>
            </div>

        </form>
    </Card>
</template>



<script>
    // import clone from 'lodash/clone';
    import categoryService from '@/services/categoryService';
    import userService from '@/services/userService';
    import Role from '@/Role';
    import Multiselect from '@/components/common/Multiselect.vue';

    export default {
        name: 'Filters',
        components: {
            Multiselect,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        computed: {
            loading() {
                return this.parentComponent.loading;
            },
        },
        methods: {
            fetchCategories() {
                return categoryService.getCategories();
            },
            fetchAdvertisers({ search }) {
                const requestParams = { search, roles: [Role.ADVERTISER] };
                return userService.getUsers({ requestParams });
            },
            parseAdvertisers(data) {
                return data?.items || [];
            },
            customLabelAdvertiser(option, label) {
                return `${option.profile.firstName} ${option.profile.lastName} (${option.username})`;
            },
        },
    };
</script>
