import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    REPORTER_INVOICES: {
        URI: () => '/api/v1/reporter-invoices',
        methods: ['get'],
        default: null,
    },
    REPORTER_INVOICE: {
        URI: params => `/api/v1/reporter-invoices/${params.id}`,
        methods: ['get'],
        default: null,
    },
    SETTLE_REPORTER_INVOICE: {
        URI: params => `/api/v1/reporter-invoices/${params.id}/settle`,
        methods: ['put'],
        cacheToClear: ['/api/v1/reporter-invoices'],
        default: {},
    },
    ABANDON_REPORTER_INVOICE: {
        URI: params => `/api/v1/reporter-invoices/${params.id}/abandon`,
        methods: ['put'],
        cacheToClear: ['/api/v1/reporter-invoices'],
        default: {},
    },
    NEW_REPORTER_INVOICE: {
        URI: params => `/api/v1/reporters/${params.advertiserId}/invoices`,
        cacheToClear: ['/api/v1/reporter-invoices'],
        methods: ['post'],
    },
    REPORTER_CAMPAIGNS: {
        URI: params => `/api/v1/reporters/${params.advertiserId}/campaigns`,
        methods: ['get'],
    },
    SETTLEMENTS: {
        URI: params => '/api/v1/campaign-settlements',
        methods: ['get'],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
