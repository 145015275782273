<template>
    <div>
        <PageHeader>
            {{ $t('shopPresentation') }}
        </PageHeader>
        <b-overlay :show="loading" rounded="sm" class="h-100">
            <Card>
                <ErrorMessage :value="error" />

                <div class="d-flex flex-column">
                    <b-form-group>
                        <h4 class="header-title mt-0">
                            {{ $t('shopLogo') }}
                        </h4>
                        <p>{{ $t('shopLogoDescription') }}</p>
                        <div class="d-flex">
                            <FormValidator :validator="$v.logoFile" v-slot="slotProps" :msg-props="{attribute: $t('shopLogo')}" class="d-flex flex-column flex-grow-1 width-max-600 mr-3">
                                <b-form-file
                                    v-model="logoFile"
                                    accept="image/*"
                                    :placeholder="$t('noFilesSelected')"
                                    :browse-text="$t('selectFile')"
                                    :state="slotProps.state"
                                />
                            </FormValidator>
                            <ImageLoader
                                v-if="currentCampaign.logo"
                                :src="currentCampaign.logo"
                                class="my-auto border shadow-sm"
                                height="50px"
                                error-width="50px"
                                error-height="50px"
                                loader-width="50px"
                                loader-height="50px"
                            />
                        </div>
                    </b-form-group>

                    <div class="my-3"></div>

                    <b-form-group>
                        <h4 class="header-title mt-0">
                            {{ $t('shopIcon') }}
                        </h4>
                        <p>{{ $t('shopIconDescription') }}</p>
                        <div class="d-flex">
                            <FormValidator :validator="$v.iconFile" v-slot="slotProps" :msg-props="{attribute: $t('shopIcon')}" class="d-flex flex-column flex-grow-1 width-max-600 mr-3">
                                <b-form-file
                                    v-model="iconFile"
                                    accept="image/*"
                                    :placeholder="$t('noFilesSelected')"
                                    :browse-text="$t('selectFile')"
                                    :state="slotProps.state"
                                />
                            </FormValidator>
                            <ImageLoader
                                v-if="currentCampaign.icon"
                                :src="currentCampaign.icon"
                                class="my-auto border shadow-sm"
                                width="32px"
                                height="32px"
                                error-width="32px"
                                error-height="32px"
                                loader-width="32px"
                                loader-height="32px"
                            />
                        </div>
                    </b-form-group>

                    <div class="my-3"></div>
                </div>

                <b-button variant="primary" :disabled="!logoFile && !iconFile" @click="submit()">
                    <b-spinner v-if="loading" small class="mr-2" /> {{ $t('saveChanges') }}
                </b-button>
            </Card>
        </b-overlay>
    </div>
</template>



<script>
    import { validationMixin } from 'vuelidate';
    import { required } from 'vuelidate/lib/validators';
    import cloneDeep from 'lodash/cloneDeep';
    import FormValidator from '@/components/common/FormValidator.vue';
    import PageHeader from '@/components/common/PageHeader.vue';
    import Card from '@/components/common/Card.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import ImageLoader from '@/components/common/ImageLoader.vue';
    import { image } from '@/utils/validators';
    import toastService from '@/services/toastService';
    import usercomService from '@/services/usercomService';

    export default {
        name: 'SettingsShopPresentation',
        mixins: [validationMixin],
        components: {
            FormValidator,
            PageHeader,
            Card,
            ErrorMessage,
            ImageLoader,
        },
        data() {
            return {
                logoFile: null,
                iconFile: null,
            };
        },
        validations() {
            const validator = {};
            if (!this.currentCampaign.logo) validator.logoFile = { ...(validator.logoFile || {}), required };
            if (!this.currentCampaign.icon) validator.iconFile = { ...(validator.iconFile || {}), required };
            if (this.logoFile) validator.logoFile = { ...(validator.logoFile || {}), image };
            if (this.iconFile) validator.iconFile = { ...(validator.iconFile || {}), image };
            return validator;
        },
        computed: {
            user() {
                return this.$store.getters['auth/getUser'];
            },
            campaigns() {
                return this.$store.getters['campaigns/getAdvertiserCampaigns'];
            },
            currentCampaign() {
                return this.$store.getters['campaigns/getCurrentCampaign'];
            },
            campaignId() {
                return this.currentCampaign.id;
            },
            requestParams() {
                const formData = new FormData();
                if (!this.currentCampaign.logo || this.logoFile) formData.append('logo', this.logoFile);
                if (!this.currentCampaign.icon || this.iconFile) formData.append('icon', this.iconFile);
                return formData;
            },
            apiParams() {
                return { requestParams: this.requestParams, headers: { 'Content-Type': 'multipart/form-data' } };
            },
            images() {
                return this.$store.getters['campaigns/getImages'];
            },
            error() {
                return this.$store.getters['campaigns/getError']('images')?.message;
            },
            loading() {
                return this.$store.getters['campaigns/getLoading']('images');
            },
        },
        watch: {
            campaignId: {
                handler: 'resetForm',
            },
        },
        methods: {
            resetForm() {
                this.logoFile = null;
                this.iconFile = null;
                this.$v.$reset();
            },
            updateCampaign(campaign) {
                const campaigns = cloneDeep(this.campaigns);
                const campaignIndex = this.campaigns.findIndex(item => item.id === this.campaignId);
                this.$set(campaigns, campaignIndex, campaign);
                this.$store.commit('campaigns/setAdvertiserCampaigns', campaigns);
            },
            updateUsercom() {
                const userData = usercomService.getUserDataFromUser(this.user);
                userData['logo uploaded'] = Boolean(!this.error);
                usercomService.clientUpdate(userData);
            },
            async submit() {
                this.$v.$touch();
                if (this.$v.$invalid) return;
                const campaign = await this.$store.dispatch('campaigns/postImages', this.apiParams);
                if (!this.error) {
                    this.resetForm();
                    this.updateCampaign(campaign);
                    toastService.successToast(this.$t('dataSavedSuccessfully'));
                }
                this.updateUsercom();
            },
        },
    };
</script>
