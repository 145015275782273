<template>
    <b-button
        v-if="isVisible"
        class="admin-btn"
        variant="link"
        @click="goToAdminPanel"
    >
        <feather type="chevron-left" class="mr-2" />
        <span>{{ $t('adminPanelButton.label') }}</span>
    </b-button>
</template>



<script>
    import { mapActions, mapState, mapGetters } from 'vuex';

    export default {
        name: 'AdminPanelButton',
        computed: {
            isVisible() {
                return (this.isAuthenticated && this.isAdmin) || this.$route.name === 'loginAs';
            },
            ...mapState({
                user: state => state.user,
            }),
            ...mapGetters({
                isAuthenticated: 'auth/isAuthenticated',
                isAdmin: 'auth/isAdmin',
            }),
        },
        methods: {
            goToAdminPanel() {
                this.logout().then(() => window.close('', '_parent', ''));
            },
            ...mapActions({
                logout: 'auth/logout',
            }),
        },
    };
</script>



<style lang="scss" scoped>
    @import 'bootstrap/scss/functions';
    @import 'bootstrap/scss/variables';
    @import '@/design/_variables.scss';

    .admin-btn {
        line-height: 1.2;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: $dark;

        &:hover {
            border: 1px solid $dark;
            color: $dark;
            text-decoration: none;
        }
    }
</style>
