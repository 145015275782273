<template>
    <Card :header="$t('filters')">
        <form @keyup.enter="parentComponent.filterData()">

            <b-form-group :label="$t('name')" label-cols-sm="3">
                <b-form-input v-model="parentComponent.filters.name" :disabled="loading" />
            </b-form-group>

            <b-form-group :label="$t('url')" label-cols-sm="3">
                <b-form-input v-model="parentComponent.filters.url" :disabled="loading" />
            </b-form-group>

            <b-form-group :label="$t('user')" label-cols-sm="3">
                <Multiselect
                    :placeholder="$t('selectField.placeholder')"
                    :show-checkboxes="true"
                    :multiple="true"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :show-selected="true"
                    :asynchronous="true"
                    track-by="id"
                    label="username"
                    select-label=""
                    :fetch-options-function="fetchUsers"
                    :parse-options-function="parseUsers"
                    :disabled="loading"
                    :initial-value="parentComponent.filtersParams.users"
                    @inputTrackBy="parentComponent.filters.users = $event"
                />
            </b-form-group>

            <b-form-group :label="$t('category')" label-cols-sm="3">
                <Multiselect
                    :placeholder="$t('selectField.placeholder')"
                    :fetch-options-function="fetchCategories"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :show-selected="true"
                    :asynchronous="false"
                    :searchable="true"
                    :multiple="true"
                    track-by="id"
                    label="name"
                    select-label=""
                    :disabled="loading"
                    :initial-value="parentComponent.filtersParams.categories"
                    @inputTrackBy="parentComponent.filters.categories = $event"
                />
            </b-form-group>

            <b-form-group :label="$t('pageType')" label-cols-sm="3">
                <Multiselect
                    :fetch-options-function="fetchSpaceTypes"
                    :parse-options-function="data => data.items || []"
                    :asynchronous="false"
                    track-by="value"
                    label="label"
                    :disabled="loading"
                    :initial-value="parentComponent.filtersParams.type"
                    @inputTrackBy="parentComponent.filters.type = $event"
                />
            </b-form-group>

            <b-form-group :label="$t('userType')" label-cols-sm="3">
                <b-form-checkbox-group v-model="parentComponent.filters.userTypes" :options="userTypesOptionsFormat" :disabled="loading" class="h-100 d-flex align-items-center" />
            </b-form-group>

            <b-form-group :label="$t('pageStatus')" label-cols-sm="3">
                <b-form-checkbox-group v-model="parentComponent.filters.statuses" :options="statusesOptionsFormat" :disabled="loading" class="h-100 d-flex align-items-center" />
            </b-form-group>

            <ButtonIcon :loading="loading" icon="filter" @click="parentComponent.filterData()">
                {{ $t('filter') }}
            </ButtonIcon>
            <JsonToCsv
                class="ml-3"
                :items="exportData"
                :fields="exportFields"
                :labels="exportLabels"
                :name="exportFilename"
            >
                {{ $t('exportToExcel') }}
            </JsonToCsv>
        </form>
    </Card>
</template>



<script>
    import Card from '@/components/common/Card.vue';
    import ButtonIcon from '@/components/common/ButtonIcon.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import JsonToCsv from '@/components/common/JsonToCsv.vue';
    import categoryService from '@/services/categoryService';
    import spaceService, { translateCampaignStatus } from '@/services/spaceService';
    import authService from '@/services/authService';
    import Role, { roleTranslate } from '@/Role';
    import { TransactionStatus, translateTransactionStatus } from '@/services/transactionService';

    export default {
        name: 'Filters',
        components: {
            Card,
            ButtonIcon,
            Multiselect,
            JsonToCsv,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {};
        },
        computed: {
            loading() {
                return this.parentComponent.loading;
            },
            userTypesOptionsFormat() {
                return [
                    { value: Role.WIDGETER, text: this.$t('publisher') },
                    { value: Role.PRODUCER, text: this.$t('producer') },
                ];
            },
            statusesOptionsFormat() {
                const statuses = { ...TransactionStatus };
                delete statuses.ABANDONED;
                return Object.values(statuses).map(status => ({ value: status, text: translateTransactionStatus(status) }));
            },
            fields() {
                return this.parentComponent.fields || [];
            },
            exportFields() {
                return this.fields.map(item => item.key);
            },
            exportLabels() {
                return Object.fromEntries(this.fields.map(item => [item.key, item.label]));
            },
            exportFilename() {
                return 'webpages.csv';
            },
            spaceTypes() {
                return this.$store.state.space.spaceTypes;
            },
        },
        methods: {
            fetchCategories() {
                return categoryService.getCategories();
            },
            fetchUsers({ search }) {
                return authService.fetchUserAccount('', { search, page: 1, perPage: Number.MAX_SAFE_INTEGER });
            },
            parseUsers(data) {
                return data?.items || [];
            },
            fetchSpaceTypes() {
                return spaceService.fetchSpaceTypes();
            },
            exportData() {
                const params = {
                    ...this.parentComponent.filtersParams,
                    sort: this.parentComponent.tableParams.sort,
                    order: this.parentComponent.tableParams.order,
                    page: 1,
                    perPage: Number.MAX_SAFE_INTEGER,
                };
                return spaceService
                    .fetchSpaces(null, params)
                    .then(data => this.formatDataItems(data.items))
                    .catch(error => this.$toastr.error(error.message));
            },
            formatDataItems(items) {
                return (
                    items?.map(item => ({
                        ...item,
                        type: this.translateSpaceTypes(item.type),
                        status: translateCampaignStatus(item.status),
                        user: `[${item.publisher.id}] ${item.publisher.name} (${roleTranslate(item.publisher.role)})`,
                        categories: item.categories.map(cat => cat.name).join(', '),
                    })) || []
                );
            },
            translateSpaceTypes(spaceId) {
                return this.spaceTypes?.[spaceId]?.label;
            },
        },
    };
</script>
