<template>
    <DataTable
        v-model="selectedRows"
        :header="$t('invoices')"
        :error="error"
        :items="items"
        :fields="fields"
        :loading="loading"
        :busy-items="busyItems"
        no-sort-reset
        no-local-sorting
        :pagination="false"
        selectable
        selectable-id-key="id"
    >
        <template #cell(items)="data">
            {{ formatShops(data.item.items) }}
        </template>
        <template #cell(number)="data">
            <router-link
                :to="{
                    name: 'AdvertiserSettlementsDetails',
                    params: {
                        id: data.item.id,
                    },
                }"
            >
                {{ data.item.number }}
            </router-link>
        </template>

        <template #cell(createdAt)="data">
            {{ $format.date(data.item.createdAt, 'dd-MM-yyyy') }}
        </template>

        <template #cell(dueDate)="data">
            {{ $format.date(data.item.dueDate, 'dd-MM-yyyy') }}
        </template>
        <template #cell(paidAmount)="data">
            {{ $format.currency(data.value) }}
        </template>

        <template #cell(netAmount)="data">
            {{ $format.currency(data.value) }}
        </template>

        <template #cell(grossAmount)="data">
            {{ $format.currency(data.value) }}
        </template>

        <template #cell(status)="data">
            <FinanceDocumentStatusBadge :status="new String(data.value)" />
        </template>

        <template #afterTable>
            <div class="d-flex gap-3 align-items-center">
                <Multiselect
                    v-model="tableAction"
                    :options="tableActionOptions"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    class="width-250 d-inline-block"
                />
                <b-button variant="primary" :disabled="!tableAction || !tableAction.value || !items.length || loading || !selectedRows.length" @click="initTableAction()">
                    {{ $t('save') }}
                </b-button>
            </div>
        </template>
    </DataTable>
</template>

<script>
    import DataTable from '@/components/common/DataTable.vue';
    import FinanceDocumentStatusBadge from '@/components/common/FinanceDocumentStatusBadge.vue';
    import Multiselect from '@/components/common/Multiselect.vue';

    export default {
        name: 'Invoices',
        components: {
            DataTable,
            FinanceDocumentStatusBadge,
            Multiselect,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                tableConfig: {},
                fields: [
                    {
                        key: 'number',
                        label: this.$t('document'),
                    },
                    {
                        key: 'advertiser.company',
                        label: this.$t('advertiser'),
                    },
                    {
                        key: 'items',
                        label: this.$t('campaign'),
                    },
                    {
                        key: 'createdAt',
                        label: this.$t('issueDate'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'dueDate',
                        label: this.$t('dueDate'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'paidAmount',
                        label: this.$t('paidAmount'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'netAmount',
                        label: this.$t('netAmount'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'grossAmount',
                        label: this.$t('grossAmount'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'status',
                        label: this.$t('status'),
                    },
                ],
                busyItems: [],
                selectedRows: [],

                tableAction: null,
                tableActionOptions: [
                    { value: null, text: this.$t('select') },
                    { value: 'settle', text: this.$t('settleInvoice') },
                    { value: 'abandon', text: this.$t('abandonInvoice') },
                ],
            };
        },
        computed: {
            invoices() {
                return this.$store.getters['advertiserSettlements/getReporterInvoices'];
            },
            items() {
                if (!this.invoices) return [];
                return this.invoices.items || [];
            },
            error() {
                return this.$store.getters['advertiserSettlements/getError']('reporterInvoices');
            },
            loading() {
                return this.$store.getters['advertiserSettlements/getLoading']('reporterInvoices');
            },
        },
        methods: {
            formatShops(shops) {
                if (!shops) return '';

                return shops.map(item => item.campaign?.name).join(', ');
            },

            async initTableAction() {
                if (!this.selectedRows.length) return;

                if (this.tableAction.value === 'settle') {
                    this.selectedRows.forEach(item => {
                        this.$store.dispatch('advertiserSettlements/putSettleReporterInvoice', { urlParams: { id: item } }).catch(() => {});
                    });
                } else if (this.tableAction.value === 'abondon') {
                    this.selectedRows.forEach(item => {
                        this.$store.dispatch('advertiserSettlements/putAbandonReporterInvoice', { urlParams: { id: item } }).catch(() => {});
                    });
                }
            },
        },
    };
</script>
