<template>
    <card :header="$t('accountSettingsAddress.title')">
        <form novalidate @submit.prevent="handleSubmit">
            <div class="row">
                <checkbox-field
                    v-if="!$store.getters['auth/isRoleAdvertiser']"
                    v-model="model.isCompany"
                    class="col-sm-12"
                    variant="switch"
                    :label="$t('accountSettingsAddress.companyAccount')"
                />
                <text-field
                    v-if="model.isCompany"
                    v-model="model.companyName"
                    class="col-sm-6"
                    :validation="$v.model.companyName"
                    :label="$t('accountSettingsAddress.companyName')"
                    :maxlength="255"
                />
                <text-field
                    v-if="model.isCompany"
                    v-model="model.companyNip"
                    class="col-sm-6"
                    :validation="$v.model.companyNip"
                    :label="$t('accountSettingsAddress.companyNip')"
                    :maxlength="20"
                />
                <text-field v-model="model.street" class="col-sm-6" :validation="$v.model.street" :label="$t('accountSettingsAddress.street')" :maxlength="200" />
                <text-field v-model="model.streetNumber" class="col-sm-6" :validation="$v.model.streetNumber" :label="$t('accountSettingsAddress.streetNumber')" :maxlength="255" />
                <text-field
                    v-model="model.apartmentNumber"
                    class="col-sm-6"
                    :validation="$v.model.apartmentNumber"
                    :label="$t('accountSettingsAddress.apartmentNumber')"
                    :maxlength="255"
                />
                <text-field v-model="model.city" class="col-sm-6" :validation="$v.model.city" :label="$t('accountSettingsAddress.city')" :maxlength="255" />
                <text-field v-model="model.postcode" class="col-sm-6" :validation="$v.model.postcode" :label="$t('accountSettingsAddress.postcode')" :maxlength="10" />
                <select-field v-model="model.country" class="col-sm-6" :options="countryOptions" :validation="$v.model.country" :label="$t('accountSettingsAddress.country')" />

                <text-field
                    v-if="isRoleWidgeter"
                    v-model="model.bankAccountProccesed"
                    class="col-sm-12"
                    :validation="$v.model.bankAccountProccesed"
                    :label="$t('accountSettingsAddress.bankAccount')"
                    only-first-error
                    :custom-validation-errors="bankAccountProccesedCustomValidationErrors"
                >
                    <template #prepend>
                        <select-field
                            v-if="isRoleWidgeter"
                            v-model="model.bankAccountPrefix"
                            class="mr-2 bank-account-prefix"
                            :options="bankAccountPrefixOptions"
                            :clearable="false"
                        />
                    </template>
                </text-field>
            </div>
            <error-message v-model="saveError" />
            <div class="row">
                <div class="col-sm-6 mt-2 form-group">
                    <button-default :loading="submitting" type="submit" block>
                        {{ $t('accountSettingsAddress.submit') }}
                    </button-default>
                </div>
            </div>
        </form>
    </card>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { required, requiredIf } from 'vuelidate/lib/validators';
    import IBAN from 'iban';
    import countryService from '@/services/countryService';
    import Card from '@/components/common/Card.vue';
    import TextField from '@/components/common/TextField.vue';
    import SelectField from '@/components/common/SelectField.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import CheckboxField from '@/components/common/CheckboxField.vue';

    export default {
        name: 'AccountSettingsAddress',
        components: {
            CheckboxField,
            SelectField,
            ButtonDefault,
            ErrorMessage,
            TextField,
            Card,
        },
        validations: {
            model: {
                companyName: {
                    required: requiredIf(model => model.isCompany),
                },
                companyNip: {
                    required: requiredIf(model => model.isCompany),
                },
                street: { required },
                streetNumber: { required },
                city: { required },
                postcode: { required },
                country: { required },
                bankAccountProccesed: {
                    required,
                    bankAccount(value) {
                        return this.validateIban(value);
                    },
                },
            },
        },
        data() {
            return {
                model: {
                    isCompany: false,
                    companyName: '',
                    companyNip: '',
                    street: '',
                    streetNumber: '',
                    apartmentNumber: '',
                    city: '',
                    postcode: '',
                    country: '',
                    bankAccountProccesed: '',
                    bankAccountPrefix: '',
                },
                saveError: null,
                bankAccountProccesedCustomValidationErrors: {
                    bankAccount: 'validation.bankAccount',
                },
            };
        },
        computed: {
            isRoleWidgeter() {
                return this.$store.getters['auth/isRoleWidgeter'];
            },
            countryOptions() {
                return countryService.getCountryOptions();
            },
            bankAccountPrefixOptions() {
                return countryService.getBankAccountPrefixes();
            },
            ...mapState({
                user: state => state.auth.user,
                submitting: state => state.auth.loading.updateAddressSettings,
            }),
        },

        methods: {
            validateIban(value) {
                return IBAN.isValid(this.model.bankAccountPrefix + value);
            },

            setInitialValues() {
                const { isCompany, companyName, companyNip, street, streetNumber, apartmentNumber, city, postcode, country, bankAccount } = this.user;
                const countryOption = this.countryOptions.find(option => option.value === country || option.text === country);
                const { bankAccountPrefix, bankAccountProccesed } = this.parseBankAccount(bankAccount, countryOption);
                this.model = {
                    isCompany,
                    companyName,
                    companyNip,
                    street,
                    city,
                    streetNumber,
                    apartmentNumber,
                    postcode,
                    country: countryOption ? countryOption.value : '',
                    bankAccountProccesed,
                    bankAccountPrefix,
                };
            },
            async handleSubmit() {
                try {
                    this.saveError = null;
                    this.$v.$touch();
                    if (this.$v.$invalid) {
                        return;
                    }
                    if (this.isRoleWidgeter) {
                        const combinedBankAccount = `${this.model.bankAccountPrefix}${this.model.bankAccountProccesed}`.replace(/\s/g, '');
                        const updatedModel = { ...this.model, bankAccount: combinedBankAccount };
                        await this.updateAddressSettings(updatedModel);
                    } else {
                        await this.updateAddressSettings(this.model);
                    }
                    this.setInitialValues();
                    this.$v.$reset();
                } catch (e) {
                    this.saveError = e.message;
                }
            },
            parseBankAccount(bankAccount, countryOption) {
                if (!this.isRoleWidgeter) return { bankAccountPrefix: '00000000', bankAccountProccesed: '00000000000000000000000000' };
                let bankAccountPrefix;
                let bankAccountProccesed;

                try {
                    const match = bankAccount?.match(/^([A-Za-z]{2})(.*)$/);
                    if (match) {
                        bankAccountPrefix = match[1];
                        bankAccountProccesed = match[2].replace(/\s/g, '');
                    } else {
                        bankAccountPrefix = (countryOption ? countryOption.value : process.env.VUE_APP_REGION).toUpperCase();
                        bankAccountProccesed = bankAccount.replace(/\s/g, '');
                    }
                } catch (e) {
                    console.error(e);
                    bankAccountPrefix = process.env.VUE_APP_REGION.toUpperCase();
                    bankAccountProccesed = bankAccount.replace(/\s/g, '');
                }

                return { bankAccountPrefix, bankAccountProccesed };
            },
            ...mapActions({
                updateAddressSettings: 'auth/updateAddressSettings',
            }),
        },
        created() {
            this.setInitialValues();
        },
    };
</script>

<style scoped>
    .bank-account-prefix {
        width: 100px;
        margin-bottom: 0px;
    }
</style>
