import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    FEED: {
        URI: () => '/api/v1/product-feeds/{{id?}}',
        methods: ['get', 'patch'],
        default: {},
        cacheToClear: ['/api/v1/product-feeds', '/api/v1/campaigns/\\d+/product-feeds'],
    },
    FEED_PROCESS: {
        URI: () => '/api/v1/product-feeds/{{id}}/process',
        methods: ['put'],
        cacheToClear: ['/api/v1/product-feeds', '/api/v1/campaigns/\\d+/product-feeds'],
    },
    CATEGORY_ITEMS: {
        URI: () => '/api/v1/category-items',
        methods: ['get'],
        formatter: data => data.items,
        default: [],
    },
    FEED_PARSERS: {
        URI: () => '/api/v1/feed-parsers',
        methods: ['get'],
        formatter: data => data.items,
        default: [],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
