<template>
    <div>
        <page-header>
            {{ $t('widgetSettings.title2') }}

            <template #side>
                <div class="d-flex">
                    <ButtonIcon :disabled="pluginsLoading || !spaceId" icon="plus" @click="addConfiguration()">
                        {{ $t('widgetSettings.addSettings') }}
                    </ButtonIcon>
                </div>
            </template>
        </page-header>
        <space-provider>
            <SpaceSwitchCard @spaceId="setSpaceId" />
            <card v-if="spaceId" :loading="pluginsLoading">
                <table-default
                    :items="pluginsList"
                    :headers="[
                        {
                            label: $t('widgetSettings.name'),
                            value: item => item.name,
                        },
                        {
                            label: $t('widgetSettings.buyboxId'),
                            value: item => item.id,
                        },
                        {
                            label: $t('widgetSettings.actions'),
                            slot: 'actions',
                        },
                    ]"
                >
                    <template #actions="{ item }">
                        <router-link :to="{ name: 'WidgetSettingsEdit', params: { buyboxId: item.id } }">
                            <button-default>
                                {{ $t('widgetSettings.editSettings') }}
                            </button-default>
                        </router-link>

                        <ButtonIcon class="margin-left" variant="danger" icon="trash-2" @click="onDeletePlugin(item.id)" />
                    </template>
                </table-default>
            </card>
        </space-provider>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import SpaceSwitchCard from '@/components/common/SpaceSwitchCard.vue';
    import SpaceProvider from '@/components/common/SpaceProvider.vue';
    import Card from '@/components/common/Card.vue';
    import TableDefault from '@/components/common/TableDefault.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import PageHeader from '@/components/common/PageHeader.vue';

    export default {
        name: 'WidgetSettings',
        components: {
            ButtonDefault,
            TableDefault,
            Card,
            SpaceProvider,
            SpaceSwitchCard,
            PageHeader,
        },
        data() {
            return {
                spaceId: null,
            };
        },
        computed: {
            currentSpaceName() {
                return this.currentSpace ? this.currentSpace.name : '';
            },
            pluginsList() {
                return Object.values(this.plugins);
            },
            ...mapGetters({
                currentSpace: 'space/currentSpace',
            }),
            ...mapState({
                plugins: state => state.plugin.plugins,
                pluginsLoading: state => state.plugin.loading.fetchPlugins,
            }),
        },
        watch: {
            spaceId() {
                if (this.spaceId) {
                    this.fetchPlugins({ spaceId: this.spaceId });
                }
            },
        },
        methods: {
            ...mapActions({
                fetchPlugins: 'plugin/fetchPlugins',
                deletePlugin: 'plugin/deletePlugin',
            }),
            setSpaceId(spaceId) {
                this.spaceId = spaceId;
            },

            async onDeletePlugin(pluginId) {
                await this.deletePlugin({ pluginId });
                this.fetchPlugins({ spaceId: this.spaceId });
            },

            async addConfiguration() {
                this.$router.push({ name: 'WidgetSettingsCreate' });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .float-right {
        float: right;
        margin-right: 11px;
    }

    .margin-left {
        margin-left: 10px;
    }
</style>
