/* eslint-disable no-param-reassign */
import md5 from 'md5';

export default {
    install(VueInstance) {
        VueInstance.mixin({
            created() {
                if (!VueInstance.prototype.$toastr) {
                    const app = this;

                    const baseConfig = {
                        id: 'custom-toastr',
                        toastClass: 'custom-toastr',
                        toaster: 'b-toaster-top-full',
                        solid: true,
                        appendToast: false,
                        autoHideDelay: 5000,
                    };

                    const getConfig = (variant, args) => {
                        const id = args.options?.id || args.id;
                        const title = args.title && typeof args.title === 'string' ? args.title : '';
                        const options = args.options && typeof args.options === 'object' ? args.options : {};
                        const config = {
                            ...baseConfig,
                            ...options,
                            title,
                            variant,
                            id: `${baseConfig.id}-${id}`,
                        };
                        return config;
                    };

                    const close = id => {
                        app.$bvToast.hide(`${baseConfig.id}-${id}`);
                    };
                    const remove = id => {
                        close(id);
                        window.document.getElementById(`${baseConfig.id}-${id}`)?.remove();
                    };
                    const success = (message, title, options) => {
                        const id = options?.id || md5(message);
                        remove(id);
                        app.$bvToast.toast(message, getConfig('success', { title, options, id }));
                    };
                    const info = (message, title, options) => {
                        const id = options?.id || md5(message);
                        remove(id);
                        app.$bvToast.toast(message, getConfig('info', { title, options, id }));
                    };
                    const warning = (message, title, options) => {
                        const id = options?.id || md5(message);
                        remove(id);
                        app.$bvToast.toast(message, getConfig('warning', { title, options, id }));
                    };
                    const error = (message, title, options) => {
                        const id = options?.id || md5(message);
                        remove(id);
                        app.$bvToast.toast(message, getConfig('danger', { title, options, id }));
                    };

                    VueInstance.prototype.$toastr = {
                        success,
                        info,
                        warning,
                        error,
                        close,
                        remove,
                    };
                }
            },
        });
    },
};
