<script>
    import { generateChartData } from '@/utils/chart';
    import numberUtil from '@/utils/number';
    import PageHeader from '@/components/common/PageHeader.vue';
    import TimeRangeDropdown from '@/components/common/TimeRangeDropdown.vue';
    import MultiChartCard from '@/components/common/MultiChartCard.vue';
    import WidgetStatChart from './parts/WidgetStatChart.vue';
    import WidgetCommission from './parts/WidgetCommission.vue';
    import WidgetNewCampaigns from './parts/WidgetNewCampaigns.vue';
    import WidgetRecentTransactions from './parts/WidgetRecentTransactions.vue';
    import WidgetBestSpaces from './parts/WidgetBestSpaces.vue';
    import WidgetBestCampaigns from './parts/WidgetBestCampaigns.vue';
    import WidgetBestProducts from './parts/WidgetBestProducts.vue';

    export default {
        name: 'DashboardMixin',
        components: {
            PageHeader,
            WidgetStatChart,
            TimeRangeDropdown,
            MultiChartCard,
            WidgetCommission,
            WidgetNewCampaigns,
            WidgetRecentTransactions,
            WidgetBestSpaces,
            WidgetBestCampaigns,
            WidgetBestProducts,
        },
        data() {
            return {
                timeRange: null,
                currentChart: 'impressions',
            };
        },
        computed: {
            isRoleAdmin() {
                return this.$store.getters['auth/isRoleAdmin'];
            },
            requestParams() {
                return { ...this.timeRange };
            },
            apiParams() {
                return { requestParams: this.requestParams };
            },
            apiUserType() {
                return this.$store.getters['auth/apiUserType'];
            },
            impressions() {
                return this.$store.getters['userStats/getImpressionsStats'];
            },
            clicks() {
                return this.$store.getters['userStats/getClicksStats'];
            },
            orders() {
                return this.$store.getters['userStats/getOrdersStats'];
            },
            commission() {
                return this.$store.getters['userStats/getCommissionStats'];
            },
            amount() {
                return this.$store.getters['userStats/getAmountStats'];
            },
            publisherQuantity() {
                return this.$store.getters['userStats/getPublisherQuantityStats'];
            },
            spacesQuantity() {
                return this.$store.getters['userStats/getSpacesQuantityStats'];
            },
            ordersChart() {
                return this.$store.getters['userStats/getOrdersChart'];
            },
            adminCommissionChart() {
                return this.$store.getters['userStats/getAdminCommissionChart'];
            },
            advertiserCommissionChart() {
                return this.$store.getters['userStats/getAdvertiserCommissionChart'];
            },
            isChartOverviewLoading() {
                return this.$store.getters['userStats/getLoading'](`${this.currentChart}Stats`);
            },
            chartOverviewCurrentColor() {
                return this.chartOverview[this.currentChart].color;
            },
            chartOverviewOptions() {
                return {
                    markers: { size: 0 },
                    colors: [this.chartOverviewCurrentColor],
                    tooltip: {
                        y: {
                            formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                                if (['clicks', 'orders'].includes(this.currentChart)) return Math.round(value);
                                if (['amount', 'commission', 'advertiserCommission', 'ourCommission'].includes(this.currentChart)) return this.$format.currency(value);
                                return value;
                            },
                        },
                    },
                    yaxis: {
                        title: {
                            text: '',
                        },
                        labels: {
                            formatter: (value, index) => {
                                if (['clicks', 'orders'].includes(this.currentChart)) return value.toFixed(0);
                                if (['amount', 'commission', 'advertiserCommission', 'ourCommission'].includes(this.currentChart)) return value.toFixed(2);
                                return value;
                            },
                        },
                    },
                };
            },
        },
        watch: {
            apiParams: {
                deep: true,
                handler: 'fetchData',
            },
        },
        methods: {
            getChartItem(data, name = '', color = '#5369f8') {
                return {
                    series: [{ data, name }],
                    name,
                    color,
                };
            },
            getChartData(chart, key) {
                const chartItem = chart?.map(item => [item.date, item[key]]);
                return generateChartData(this.timeRange, chartItem);
            },
            getWidgetChartData(key) {
                return this.chartOverview[key].series?.[0]?.data?.map(item => item.y) || [];
            },
            percentDiff(newValue, oldValue) {
                return numberUtil.calculateChangePercentage(newValue, oldValue);
            },
            setTimeRange(timeRange) {
                this.timeRange = timeRange;
            },
            setCurrentChart(currentChart) {
                this.currentChart = currentChart;
            },
            fetchImpressionsStats(params = {}) {
                this.$store.dispatch('userStats/fetchImpressionsStats', { ...this.apiParams, ...params });
            },
            fetchClicksStats(params = {}) {
                this.$store.dispatch('userStats/fetchClicksStats', { ...this.apiParams, ...params });
            },
            fetchOrdersStats(params = {}) {
                this.$store.dispatch('userStats/fetchOrdersStats', { ...this.apiParams, ...params });
            },
            fetchCommissionStats(params = {}) {
                this.$store.dispatch('userStats/fetchCommissionStats', { ...this.apiParams, ...params });
            },
            fetchAmountStats(params = {}) {
                this.$store.dispatch('userStats/fetchAmountStats', { ...this.apiParams, ...params });
            },
            fetchPublisherQuantityStats(params = {}) {
                this.$store.dispatch('userStats/fetchPublisherQuantityStats', { ...this.apiParams, ...params });
            },
            fetchSpacesQuantityStats(params = {}) {
                this.$store.dispatch('userStats/fetchSpacesQuantityStats', { ...this.apiParams, ...params });
            },
            fetchOrdersChart(params = {}) {
                this.$store.dispatch('userStats/fetchOrdersChart', { ...this.apiParams, ...params });
            },
            fetchAdminCommissionChart(params = {}) {
                this.$store.dispatch('userStats/fetchAdminCommissionChart', { ...this.apiParams, ...params });
            },
            fetchAdvertiserCommissionChart(params = {}) {
                this.$store.dispatch('userStats/fetchAdvertiserCommissionChart', { ...this.apiParams, ...params });
            },
            fetchData() {},
        },
    };
</script>
