<template>
    <Card :header="$t('filters')">
        <b-form-group v-if="!isRoleAdvertiser" :label="$t('campaign')" label-cols-sm="3">
            <Multiselect
                :value="parentComponent.filters.campaigns"
                :fetch-options-function="fetchCampaigns"
                :parse-options-function="parseCampaigns"
                :placeholder="$t('selectField.placeholder')"
                :searchable="true"
                :preselect-first="true"
                track-by="id"
                label="name"
                select-label=""
                :disabled="loading"
                @loading="value => isCampaignsLoading = value"
                @inputTrackBy="parentComponent.filters.campaigns = $event"
            />
        </b-form-group>

        <b-form-group :label="$t('space')" label-cols-sm="3">
            <MultiselectSpaces
                :value="parentComponent.filters.spaces"
                :multiple="false"
                :show-checkboxes="false"
                :clear-on-select="false"
                :close-on-select="true"
                :preserve-search="true"
                :disabled="loading"
                @loading="value => isSpacesLoading = value"
                @inputTrackBy="parentComponent.filters.spaces = $event"
            />
        </b-form-group>

        <b-form-group :label="$t('interval')" label-cols-sm="3">
            <Multiselect
                :value="parentComponent.filters.groupBy"
                :options="intervalOptions"
                :placeholder="$t('selectField.placeholder')"
                preselect-first
                :disabled="loading"
                @inputTrackBy="parentComponent.filters.groupBy = $event"
            />
        </b-form-group>

        <div class="d-flex gap-3">
            <ButtonIcon :loading="parentComponent.loading" :disabled="isCampaignsLoading || isSpacesLoading" hide-label-while-loading @click="parentComponent.filterData()">
                {{ $t('show') }}
            </ButtonIcon>
            <ButtonIcon variant="light" :disabled="parentComponent.loading || isCampaignsLoading || isSpacesLoading" @click="parentComponent.resetData()">
                {{ $t('reset') }}
            </ButtonIcon>
        </div>
    </Card>
</template>



<script>
    import spaceService from '@/services/spaceService';
    import campaignsService from '@/services/campaignsService';
    import Multiselect from '@/components/common/Multiselect.vue';
    import MultiselectSpaces from '@/components/common/MultiselectSpaces.vue';

    export default {
        name: 'Filters',
        components: {
            Multiselect,
            MultiselectSpaces,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                isCampaignsLoading: false,
                isSpacesLoading: false,
            };
        },
        computed: {
            intervalOptions() {
                return [
                    { text: this.$t('day'), value: 'day' },
                    { text: this.$t('month'), value: 'month' },
                    { text: this.$t('quarter'), value: 'quarter' },
                    { text: this.$t('year'), value: 'year' },
                ];
            },
            loading() {
                return this.parentComponent.loading;
            },
            isRoleWidgeter() {
                return this.$store.getters['auth/isRoleWidgeter'];
            },
            isRoleAdvertiser() {
                return this.$store.getters['auth/isRoleAdvertiser'];
            },
            isRoleAdmin() {
                return this.$store.getters['auth/isRoleAdmin'];
            },
        },
        methods: {
            fetchCampaigns() {
                if (this.isRoleAdvertiser) return campaignsService.getAdvertiserCampaigns({ userId: this.$store.getters['auth/userId'] });
                return spaceService.fetchCampaigns({ userRole: 'admin' });
            },
            parseCampaigns(data) {
                const items = data?.items || [];
                if (items.length) return [{ id: null, name: this.$t('all') }, ...items];
                return [...items];
            },
        },
    };
</script>
