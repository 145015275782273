<template>
    <div
        class="form-group"
        :class="{
            row: row,
            'v-center': row,
        }"
    >
        <label
            v-if="$scopedSlots.label"
            :for="htmUniqueId"
            class="form-control-label"
            :class="{
                'col-sm-3': row,
                'stretch': !row,
            }"
        >
            <slot name="label" />
        </label>
        <div
            class="input-group input-group-merge"
            :class="{
                'col-sm-9': row,
            }"
        >
            <slot name="content" :htmUniqueId="htmUniqueId" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'row-labeled',
    props: {
        row: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            htmUniqueId: `id-${Math.random()}`,
        };
    },
};
</script>

<style scoped lang="scss">
    .v-center {
        display: flex;
        align-items: center;
    }

    .stretch {
        width: 100%;
    }
</style>
