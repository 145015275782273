<template>
    <b-overlay :show="loading" rounded="sm" class="h-100">
        <div class="card mb-0 h-100">
            <div class="card-body d-flex justify-content-center align-items-center">
                <apexchart
                    ref="chart"
                    height="400"
                    type="pie"
                    :series="chartSeries"
                    :options="chartOptions"
                />
            </div>
        </div>
    </b-overlay>
</template>



<script>
    import { stringToColour } from '@/utils/util';

    export default {
        name: 'PieChart',
        props: {
            chartData: {
                type: Array,
                required: true,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            chartSeries() {
                return this.chartData.map(({ series }) => series) || [];
            },
            chartOptions() {
                return {
                    colors: this.chartData.length ? this.chartData.map((item) => stringToColour(item.name)) : undefined,
                    noData: {
                        text: this.$t('noData'),
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: '#ccc',
                            fontSize: '30px',
                            fontFamily: undefined,
                        },
                    },
                    chart: {
                        width: '100%',
                    },
                    labels: this.chartData.map(({ name }) => name),
                    legend: {
                        show: true,
                        showForSingleSeries: false,
                        showForNullSeries: true,
                        showForZeroSeries: true,
                        position: 'bottom',
                        height: 60,
                    },
                    dataLabels: {
                        enabled: true,
                    },
                    tooltip: {
                        y: {
                            formatter(val, chartData) {
                                const sum = chartData.config.series.reduce((a, b) => a + b, 0);
                                const percent = (val / sum) * 100 || 0;
                                return `${parseFloat(val.toFixed(2))} (${parseFloat(percent.toFixed(2))}%)`;
                            },
                        },
                    },
                };
            },
        },
    };
</script>
