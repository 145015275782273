<template>
    <div>
        <PageHeader>{{ $t('transactions.list') }}</PageHeader>
        <Filters ref="Filters" />
        <Results ref="Results" />
        <ItemPreview ref="ItemPreview" />
    </div>
</template>



<script>
    import Filters from './parts/Filters.vue';
    import Results from './parts/Results.vue';
    import ItemPreview from './parts/ItemPreview.vue';

    export default {
        name: 'ReportsTransactions',
        components: {
            Filters,
            Results,
            ItemPreview,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                modalItemName: 'advertiser-reports-transactions-item',
                perPage: 25,
                currentPage: 1,
                sortBy: 'createdAt',
                sortDirection: 'desc',
                isItemsBusy: false,
            };
        },
    };
</script>
