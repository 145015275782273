<template>
    <div>
        <PageHeader>
            {{ $t('invoices') }}

            <template #side>
                <ButtonIcon hide-label-while-loading @click="$router.push({ name: 'AdvertiserSettlementsCreate' })">
                    {{ $t('addNew') }}
                </ButtonIcon>
            </template>
        </PageHeader>

        <div class="d-flex flex-column gap-4">
            <Filters ref="Filters" />
            <Invoices ref="Invoices" />
        </div>
    </div>
</template>

<script>
    import Invoices from './parts/Invoices.vue';
    import Filters from './parts/Filters.vue';

    export default {
        name: 'AdvertiserSettlementsList',
        components: {
            Invoices,
            Filters,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {};
        },
    };
</script>

<style lang="scss" scoped></style>
