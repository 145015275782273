<template>
    <row-labeled :row="row">
        <template v-if="row" #label>
            <span>{{ label }} <span v-if="isRequired" class="text-danger">*</span></span>
        </template>

        <template #content="{ htmUniqueId }">
            <div
                :class="[
                    'custom-control',
                    `custom-${variant}`
                ]"
            >
                <input
                    :id="htmUniqueId"
                    v-model="model"
                    type="checkbox"
                    class="custom-control-input"
                    :value="valueForIteration"
                    :disabled="disabled"
                    @change="$emit('change');"
                    @click="$emit('click');"
                >
                <label class="custom-control-label" :for="htmUniqueId">
                    <span v-if="!row">{{ label }}</span>
                </label>
            </div>
            <div v-if="hasValidationErrors" class="invalid-feedback">
                {{ validationErrors.join(' ') }}
            </div>
        </template>
    </row-labeled>
</template>

<script>
    import RowLabeled from './RowLabeled.vue';
    import validatableFieldMixin from '../../mixins/validatableFieldMixin';

    export default {
        name: 'CheckboxField',
        mixins: [validatableFieldMixin],
        components: {
            RowLabeled,
        },
        props: {
            /**
             * @model
             */
            value: {
                type: [Boolean, Array],
                required: false,
            },
            /**
             * Sets explicit value; use with array type v-model and mapping
             * e.g.
             * <checkbox-field
             *   v-for="number in numbers"
             *   v-model="selectedNumbers"
             *   :value-for-iteration="number"
             * />
             *
             * numbers: [1, 2, 3]
             * selectedNumbers: []
             */
            valueForIteration: {
                required: false,
            },
            label: {
                type: String,
                required: false,
            },
            row: {
                type: Boolean,
            },
            disabled: {
                type: Boolean,
            },
            variant: {
                type: String,
                validator(val) {
                    return ['checkbox', 'switch'].includes(val);
                },
                default: 'checkbox',
            },
        },
    };
</script>
