<template>
    <b-form-valid-feedback v-if="validator.$dirty && !validator.$error && showValidState" :force-show="true">
        <slot name="default">
            <div class="feedback">
                {{ $t('validator.valid', msgProps) }}
            </div>
        </slot>
    </b-form-valid-feedback>
    <b-form-invalid-feedback v-else-if="validator.$dirty && validator.$error" :force-show="true">
        <slot name="default">
            <template v-for="name in Object.keys(validator.$params)">
                <div v-if="validator[name] === false" :key="name" :class="`feedback feedback-validator-${name}`">
                    <template v-if="messages && messages[name]">
                        {{ messages[name] }}
                    </template>
                    <template v-else>
                        {{ $te(`validator.${name}`) ? $t(`validator.${name}`, msgProps): name }}
                    </template>
                </div>
            </template>
        </slot>
    </b-form-invalid-feedback>
</template>



<script>
    export default {
        name: 'FormFeedback',
        inheritAttrs: false,
        props: {
            validator: {
                type: Object,
                required: true,
            },
            showValidState: {
                type: Boolean,
                default: false,
            },
            messages: {
                type: Object,
                default: null,
            },
            msgProps: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {};
        },
    };
</script>
