<template>
    <div>
        <PageHeader>
            {{ $t('catalogManufacturersTitle') }}
            <template #side>
                <ButtonIcon
                    class="ml-2"
                    icon="plus"
                    variant="primary"
                    :disabled="isLoading"
                    @click="editItem()"
                >
                    {{ $t('addNew') }}
                </ButtonIcon>
            </template>
        </PageHeader>

        <Card :header="$t('filters')">
            <b-form-group :label="$t('name')" label-cols-sm="3">
                <b-form-input v-model="filters.name" :disabled="isLoading" @keyup.enter="fetchData()" />
            </b-form-group>

            <ButtonIcon :loading="isLoading" icon="filter" @click="fetchData()">
                {{ $t('filter') }}
            </ButtonIcon>
        </Card>

        <DataTable
            :items="items"
            :fields="fields"
            :total-rows="manufacturers.max"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :sort-by.sync="sortBy"
            :sort-direction.sync="sortDirection"
            :loading="isLoading"
            no-local-sorting
        >
            <template #cell(action)="data">
                <div class="d-flex gap-1 justify-content-end">
                    <ButtonIcon
                        size="sm"
                        icon="edit-2"
                        variant="primary"
                        :disabled="isItemBusy(data.item.id)"
                        :loading="isItemUpdated(data.item.id)"
                        @click="editItem(data.item)"
                    />
                    <ButtonIcon
                        size="sm"
                        icon="trash-2"
                        variant="danger"
                        :disabled="isItemBusy(data.item.id)"
                        :loading="isItemDeleted(data.item.id)"
                        @click="deleteItem(data.item.id)"
                    />
                </div>
            </template>
        </DataTable>
        <ModalForm />
    </div>
</template>



<script>
    import categoryService from '@/services/categoryService';
    import DataTable from '@/components/common/DataTable.vue';
    import ModalForm from './parts/ModalForm.vue';
    import { createItemKey } from '@/store/modules/moduleFactory';

    export default {
        name: 'CatalogManufacturers',
        components: {
            DataTable,
            ModalForm,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                fields: [
                    {
                        key: 'id',
                        label: this.$t('id'),
                        sortable: true,
                    },
                    {
                        key: 'name',
                        label: this.$t('name'),
                        sortable: true,
                    },
                    {
                        key: 'action',
                        label: '',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ],
                perPage: 10,
                currentPage: 1,
                sortBy: 'id',
                sortDirection: 'desc',
                categories: [],
                isCategoriesLoading: false,
                filters: {
                    name: '',
                },
            };
        },
        computed: {
            requestParams() {
                return {
                    ...this.tableParams,
                    ...this.filters,
                };
            },
            tableParams() {
                return {
                    page: this.currentPage,
                    perPage: this.perPage,
                    sort: this.sortBy,
                    order: this.sortDirection.toUpperCase(),
                };
            },
            manufacturers() {
                return this.$store.getters['catalog/getManufacturers'];
            },
            items() {
                return this.manufacturers.items || [];
            },
            error() {
                return this.$store.getters['catalog/getError']('manufacturers');
            },
            isLoading() {
                return this.$store.getters['catalog/getLoading']('manufacturers');
            },
            isItemBusy() {
                return id => this.$store.getters['catalog/getBusyItem']('manufacturers', createItemKey({ id }));
            },
            isItemUpdated() {
                return id => this.$store.getters['catalog/getUpdatedItem']('manufacturers', createItemKey({ id }));
            },
            isItemDeleted() {
                return id => this.$store.getters['catalog/getDeletedItem']('manufacturers', createItemKey({ id }));
            },
            categoriesOptionsFormat() {
                return this.categories.map(({ id, name }) => ({ value: id, text: name }));
            },
        },
        watch: {
            tableParams: {
                immediate: true,
                handler: 'fetchData',
            },
            error() {
                if (this.error) this.$toastr.error(this.error.message, this.$t('errorOccurred'));
            },
        },
        methods: {
            clearData() {
                this.$store.commit('catalog/clearManufacturers');
            },
            fetchData() {
                this.$store.dispatch('catalog/fetchManufacturers', { requestParams: this.requestParams }).catch(() => {});
            },
            deleteItem(id) {
                this.$bvModal
                    .msgBoxConfirm(this.$t('confirmYouWantDelete'), {
                        title: this.$t('confirm'),
                        okTitle: this.$t('yes'),
                        cancelTitle: this.$t('cancel'),
                        okVariant: 'danger',
                    })
                    .then(value => {
                        if (value) {
                            this.$store
                                .dispatch('catalog/deleteManufacturers', { urlParams: { id } })
                                .then(this.fetchData)
                                .catch(() => {});
                        }
                    });
            },
            editItem(item = null) {
                this.$root.$emit('bv::show::modal', 'modal-form-catalog-manufacturers');
                this.$root.$emit('modal-form-catalog-manufacturers', item);
            },
            async fetchFilterCategories() {
                this.isCategoriesLoading = true;
                this.categories = await categoryService.getCategories();
                this.isCategoriesLoading = false;
            },
            fetchBrands() {
                const requestParams = {
                    sort: 'name',
                    order: 'ASC',
                    page: 1,
                    perPage: Number.MAX_SAFE_INTEGER,
                };
                return this.$store.dispatch('catalog/fetchBrands', { requestParams }).catch(() => {});
            },
        },
        created() {
            this.clearData();
            this.fetchFilterCategories();
        },
    };
</script>
