<template>
    <div>
        <PageHeader>{{ $t('productLandingPageGenerator.title') }}</PageHeader>

        <div class="row">
            <div class="col-12">
                <div class="mb-4">
                    <p class="mb-1">
                        <span class="d-block">{{ $t('productLandingPageGenerator.helperDescription') }}</span>
                        <span class="d-block">{{ $t('productLandingPageGenerator.helperDescription2') }}</span>
                    </p>
                    <b-button-group>
                        <b-button
                            href="https://getbuybox.com/gdziekupic-to/"
                            target="_blank"
                            variant="link"
                            class="d-flex align-items-center p-0 mr-4"
                        >
                            <feather type="file-text" class="mr-2" /> {{ $t('takeALookAtHelp') }}
                        </b-button>
                        <b-button
                            href="https://getbuybox.com/jak-utworzyc-gdziekupic-to-widzet-w-formie-linku-na-social-media/"
                            target="_blank"
                            variant="link"
                            class="d-flex align-items-center p-0 mr-4"
                        >
                            <feather type="youtube" class="mr-2" /> {{ $t('watchVideo') }}
                        </b-button>
                    </b-button-group>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-6">
                <Card :header="$t('generator.cardProduct')" :state="getCardState(1)">
                    <ProductSearch v-model="product" />
                </Card>

                <Card :header="$t('generator.cardSpace')" :state="getCardState(2)">
                    <SpaceProvider class="shadow-none">
                        <SelectField
                            v-model="spaceId"
                            :options="spaceOptions"
                            :clearable="false"
                        />
                    </SpaceProvider>
                    <BuyboxSearch
                        v-show="spaceId && product"
                        ref="buyboxSearch"
                        v-model="buyboxId"
                        :space-id="spaceId || 0"
                        :product-id="product && product.id || 0"
                        @loading="loading => buyboxLoading = loading"
                    />
                </Card>

                <Card :header="`3. ${$t('productLandingPageGenerator.cardStep3Title')}`" :state="getCardState(3)">
                    <div>
                        <b-link :href="widgetUrl" target="_blank">
                            {{ widgetUrl }}
                        </b-link>
                    </div>

                    <text-field
                        v-model="abpar1"
                        class="mt-4"
                        :label="$t('textLinks.abpar', { number: 1 })"
                        :placeholder="$t('textLinks.optional')"
                        row
                    />
                    <text-field
                        v-model="abpar2"
                        :label="$t('textLinks.abpar', { number: 2 })"
                        :placeholder="$t('textLinks.optional')"
                        row
                    />
                    <text-field
                        v-model="abpar3"
                        :label="$t('textLinks.abpar', { number: 3 })"
                        :placeholder="$t('textLinks.optional')"
                        row
                    />

                    <ButtonDefault
                        class="mt-4"
                        variant="success"
                        icon="copy"
                        @click="copyWidgetUrl()"
                    >
                        {{ $t('productLandingPageGenerator.copyUrl') }}
                    </ButtonDefault>
                </Card>
            </div>

            <div class="col-xl-6">
                <Card :header="$t('productLandingPageGenerator.previewHeader')" :sub-header="previewSubheader" class="widget-preview">
                    <WidgetPreview
                        v-if="product && buyboxId"
                        :bb-id="buyboxId"
                        :bb-offer-id="offerId"
                        :bb-abpar1="bbAbpar1"
                        :bb-abpar2="bbAbpar2"
                        :bb-abpar3="bbAbpar3"
                    />
                </Card>
            </div>
        </div>
    </div>
</template>



<script>
    import { mapState } from 'vuex';
    import Card from '@/components/common/Card.vue';
    import PageHeader from '@/components/common/PageHeader.vue';
    import ProductSearch from '@/components/common/ProductSearch.vue';
    import BuyboxSearch from '@/components/common/BuyboxSearch.vue';
    import SpaceProvider from '@/components/common/SpaceProvider.vue';
    import SelectField from '@/components/common/SelectField.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import toastService from '@/services/toastService';
    import WidgetPreview from '@/components/common/WidgetPreview.vue';
    import TextField from '@/components/common/TextField.vue';
    import { generateId, isEmpty } from '@/utils/util';

    export default {
        name: 'ProductLandingPage',
        components: {
            Card,
            PageHeader,
            ProductSearch,
            BuyboxSearch,
            SpaceProvider,
            SelectField,
            ButtonDefault,
            WidgetPreview,
            TextField,
        },
        data() {
            return {
                widgetLandingPageUrl: process.env.VUE_APP_WIDGET_PREVIEW_URL,
                product: null,
                spaceId: null,
                buyboxId: null,
                abpar1: '',
                abpar2: '',
                abpar3: '',
                buyboxLoading: false,
            };
        },
        computed: {
            space() {
                return this.spaces[this.spaceId] || null;
            },
            spaces() {
                return this.$store.state.space.spaces;
            },
            spaceOptions() {
                return this.$store.getters['space/spaceOptions'];
            },
            offerId() {
                return this.product?.offerId || '';
            },
            bbAbpar1() {
                return !isEmpty(this.abpar1) ? this.abpar1 : undefined;
            },
            bbAbpar2() {
                return !isEmpty(this.abpar2) ? this.abpar2 : undefined;
            },
            bbAbpar3() {
                return !isEmpty(this.abpar3) ? this.abpar3 : undefined;
            },
            widgetUrl() {
                const attrs = {};
                if (this.buyboxId) {
                    attrs.id = this.buyboxId;
                } else {
                    attrs.sid = this.spaceId;
                }
                attrs.oid = this.offerId;
                if (!isEmpty(this.bbAbpar1)) {
                    attrs.p1 = this.bbAbpar1;
                }
                if (!isEmpty(this.bbAbpar2)) {
                    attrs.p2 = this.bbAbpar2;
                }
                if (!isEmpty(this.bbAbpar3)) {
                    attrs.p3 = this.bbAbpar3;
                }
                const attrsStr = Object.keys(attrs)
                    .map(name => `${name}=${attrs[name]}`)
                    .join('&');
                return `${this.widgetLandingPageUrl}?${attrsStr}`;
            },
            previewSubheader() {
                const noOffers = this.space && this.product && !this.buyboxId && !this.$refs.buyboxSearch?.buyboxesRequest.loading;
                const subheader = noOffers ? this.$t('generator.previewSubheaderNoOffers') : this.$t('generator.previewSubheader');
                return subheader;
            },
            currentStep() {
                if (this.product && this.space && !this.pluginsLoading && !this.buyboxLoading) return 4;
                if (this.product && this.space && (this.pluginsLoading || this.buyboxLoading)) return 2;
                if (this.product) return 2;
                return 1;
            },
            ...mapState({
                pluginsLoading: state => state.plugin.loading.fetchPlugins,
            }),
        },
        watch: {
            product(product, prevProduct) {
                if (product !== prevProduct) {
                    this.spaceId = null;
                    this.buyboxId = null;
                }
                if (product) {
                    this.onProductSet();
                }
            },
            spaceId(spaceId, prevSpaceId) {
                if (spaceId !== prevSpaceId) {
                    this.buyboxId = null;
                }
            },
            buyboxId(id) {
                if (id) {
                    this.onBuyboxIdSet();
                } else {
                    this.buybox = null;
                }
            },
        },
        methods: {
            getCardState(cardNo) {
                if (cardNo > this.currentStep) return 'disabled';
                if (cardNo < this.currentStep) return 'completed';
                return 'default';
            },
            onProductSet() {
                this.widgetId = generateId();
            },
            onBuyboxIdSet() {
                this.setBuyboxFields();
            },
            setBuyboxFields() {
                this.abpar1 = '';
                this.abpar2 = '';
                this.abpar3 = '';
            },
            async copyWidgetUrl() {
                if (this.widgetUrl) {
                    await this.$copyText(this.widgetUrl);
                    toastService.successToast(this.$t('productLandingPageGenerator.copied'));
                }
            },
        },
    };
</script>



<style lang="scss" scoped>
    @import 'bootstrap/scss/functions';
    @import 'bootstrap/scss/variables';
    @import '@/design/_variables.scss';
    .widget-preview {
        position: sticky;
        top: $topbar-height + $grid-gutter-width;
        z-index: 2;
    }
</style>
