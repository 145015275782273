<template>
    <div>
        <PageHeader> {{ $t('invoice') }} {{ invoice.number }} </PageHeader>

        <div class="d-flex flex-column mt-4">
            <Card>
                <template #header>
                    {{ invoice.number }}

                    <FinanceDocumentStatusBadge class="ml-10" :status="invoice.status" />
                </template>

                <SkeletonList :loading="!invoice">
                    <SkeletonItem :label="$t('advertiser')" width="80%" class="mt-4">
                        <span v-if="invoice">{{ invoice.advertiser.company }}</span>
                    </SkeletonItem>
                    <SkeletonItem :label="$t('issueDate')" width="100%" class="mt-4">
                        <span v-if="invoice">{{ $format.dateAndHour(invoice.createdAt, 'dd-MM-yyyy') }}</span>
                    </SkeletonItem>
                    <SkeletonItem :label="$t('dueDate')" width="100%">
                        <span v-if="invoice">{{ $format.dateAndHour(invoice.dueDate, 'dd-MM-yyyy') }}</span>
                    </SkeletonItem>
                </SkeletonList>

                <div class="row mt-10">
                    <!-- header row -->
                    <div class="col-4 font-weight-bold border-bottom">
                        <p>{{ $t('shops') }}</p>
                    </div>
                    <div class="col-4 font-weight-bold border-bottom">
                        <p>{{ $t('netAmount') }}</p>
                    </div>
                    <div class="col-4 font-weight-bold border-bottom">
                        <p>{{ $t('grossAmount') }}</p>
                    </div>

                    <template v-for="(item, index) in invoice.items">
                        <div :key="item.id" class="col-4 border-bottom mt-3">
                            <p>
                                <span class="font-weight-bold">{{ index + 1 }}.</span> {{ item.campaign.name }}
                            </p>
                        </div>
                        <!-- eslint-disable-next-line vue/require-v-for-key -->
                        <div class="col-4 border-bottom mt-3">
                            <p>{{ $format.currency(item.netAmount) }}</p>
                        </div>
                        <!-- eslint-disable-next-line vue/require-v-for-key -->
                        <div class="col-4 border-bottom mt-3">
                            <p>{{ $format.currency(item.grossAmount) }}</p>
                        </div>
                    </template>

                    <!-- Footer Row -->
                    <div class="col-4 font-weight-bold mt-4">
                        <p>{{ $t('transactions.total') }}</p>
                    </div>
                    <div class="col-4 font-weight-bold mt-4">
                        <p>{{ $format.currency(invoice.netAmount) }}</p>
                    </div>
                    <div class="col-4 font-weight-bold mt-4">
                        <p>{{ $format.currency(invoice.grossAmount) }}</p>
                    </div>
                </div>

                <h5 class="font-weight-bold mt-10">{{ $t('toBePaid') }}: {{ $format.currency(invoice.grossAmount) }}</h5>

                <div class="mt-4">
                    <ButtonIcon :loading="loading" :disabled="!canBeSettled" class="mr-2" @click="settleInvoice()">
                        {{ $t('settleInvoice') }}
                    </ButtonIcon>

                    <ButtonIcon :loading="loading" variant="danger" :disabled="!isSuperAdmin" @click="abandonInvoice()">
                        {{ $t('abandonInvoice') }}
                    </ButtonIcon>
                </div>
            </Card>
        </div>
    </div>
</template>

<script>
    import SkeletonList from '@/components/common/SkeletonList.vue';
    import SkeletonItem from '@/components/common/SkeletonItem.vue';
    import FinanceDocumentStatusBadge from '@/components/common/FinanceDocumentStatusBadge.vue';

    export default {
        name: 'AdvertiserSettlementsDetails',
        components: {
            SkeletonList,
            SkeletonItem,
            FinanceDocumentStatusBadge,
        },
        computed: {
            error() {
                return this.$store.getters['advertiserSettlements/getError']('reporterInvoice');
            },
            loading() {
                return this.$store.getters['advertiserSettlements/getLoading']('reporterInvoice');
            },
            invoice() {
                return this.$store.getters['advertiserSettlements/getReporterInvoice'];
            },
            isSuperAdmin() {
                return this.$store.getters['auth/isRoleSuperAdmin'];
            },

            canBeSettled() {
                return this.invoice?.status === 0;
            },
        },

        methods: {
            fetchReporterInvoice() {
                this.$store.dispatch('advertiserSettlements/fetchReporterInvoice', { urlParams: { id: this.$route.params.id } }).catch(() => {});
            },

            settleInvoice() {
                this.$store
                    .dispatch('advertiserSettlements/putSettleReporterInvoice', { urlParams: { id: this.$route.params.id } })
                    .catch(() => {})
                    .then(() => {
                        this.fetchReporterInvoice();
                    });
            },

            abandonInvoice() {
                this.$store
                    .dispatch('advertiserSettlements/putAbandonReporterInvoice', { urlParams: { id: this.$route.params.id } })
                    .catch(() => {})
                    .then(() => {
                        this.fetchReporterInvoice();
                    });
            },
        },
        created() {
            this.fetchReporterInvoice();
        },
    };
</script>

<style scoped>
    .mt-10 {
        margin-top: 50px;
    }

    .ml-10 {
        margin-left: 50px;
    }
</style>
