<template>
    <b-overlay :show="loading" rounded="sm" class="h-100">
        <Card :header="$t('dashboard.lastAddedSpaces')" class="mb-0 h-100">
            <div class="overflow-auto">
                <ErrorMessage :value="error" />
                <b-table-lite
                    :items="items"
                    :fields="fields"
                    show-empty
                    :empty-text="$t('noResult')"
                    :busy="loading"
                    thead-class="d-none"
                    class="mb-0"
                    borderless
                    small
                >
                    <template #table-busy>
                        <div class="d-flex justify-content-center my-2">
                            <b-spinner />
                        </div>
                    </template>
                    <template #cell(url)="data">
                        <b-link :href="data.item.url" target="_blank">
                            {{ data.item.url }}
                        </b-link>
                    </template>
                    <template #cell(action)="data">
                        <ButtonIcon size="sm" :to="createItemUrl(data.item)">
                            {{ $t('show') }}
                        </ButtonIcon>
                    </template>
                    <template v-if="items.length" #custom-foot>
                        <b-tr>
                            <b-td colspan="10" class="text-right">
                                <div class="pt-3">
                                    <b-button :to="{ name: 'WebpagesList' }" variant="link" class="p-0 ml-auto">
                                        {{ $t('showAll') }}
                                    </b-button>
                                </div>
                            </b-td>
                        </b-tr>
                    </template>
                </b-table-lite>
            </div>
        </Card>
    </b-overlay>
</template>



<script>
    import slugify from 'slugify';
    import Card from '@/components/common/Card.vue';
    import storageKeys from '@/Storage';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import spaceService from '@/services/spaceService';

    export default {
        name: 'WidgetNewSpaces',
        components: {
            Card,
            ErrorMessage,
        },
        data() {
            return {
                spaces: {},
                error: null,
                loading: false,
            };
        },
        computed: {
            requestParams() {
                return {
                    perPage: 5,
                    page: 1,
                    sort: 'createdAt',
                    order: 'DESC',
                    statuses: '1,2',
                };
            },
            fields() {
                return [
                    {
                        key: 'id',
                        label: 'ID',
                    },
                    {
                        key: 'url',
                        label: this.$t('url'),
                        tdClass: 'w-100',
                        thClass: 'w-100',
                    },
                    {
                        key: 'action',
                        label: '',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ];
            },
            items() {
                return this.spaces?.items || [];
            },
        },
        methods: {
            async fetchSpaces(params) {
                this.loading = true;
                this.error = null;
                this.spaces = await spaceService.fetchSpaces(null, this.requestParams, params.cache).catch(error => (this.error = error));
                this.loading = false;
            },
            async fetchData(params = {}) {
                this.fetchSpaces(params);
            },
            createItemUrl(item) {
                const locale = window.localStorage.getItem(storageKeys.LOCALE) || 'en';
                const id = item.id;
                const slug = slugify(item.name, { replacement: '-', lower: true, locale });
                return {
                    name: 'WebpagesDetails',
                    params: { id, slug, sep: '-', parentUrl: this.$router.resolve({ name: 'WebpagesList' }).resolved.fullPath },
                    query: {},
                };
            },
        },
        created() {
            this.fetchData();
        },
    };
</script>
