<template>
    <div>

        <page-header>
            {{ $t('dashboard.title') }}
            <template #side>
                <ButtonIcon
                    class="mr-2"
                    icon="refresh-cw"
                    variant="link"
                    :disabled="loading"
                    :icon-animation="iconAnimation"
                    @click="reload"
                />
                <TimeRangeDropdown v-model="timeRange" :disabled="loading" :options="['today', 'yesterday', 'last7Days', 'currentWeek', 'last14Days', 'last30days', 'currentMonth', 'currentQuarter', 'currentYear']" />
            </template>
        </page-header>

        <div class="row">
            <div class="col-xl-3 col-md-6 mb-4">
                <WidgetStatChart
                    :title="$t('dashboard.transactions')"
                    :value="$format.number(orders.orders)"
                    :difference="$format.percent(percentDiff(orders.orders, orders.orders_compare))"
                    :loading="$store.getters['userStats/getLoading']('ordersStats')"
                    :error="$store.getters['userStats/getError']('ordersStats')"
                    tooltip-formatter="round"
                />
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
                <WidgetStatChart
                    :title="$t('dashboard.ourCommission')"
                    :value="$format.currency(commission.admin_commission)"
                    :difference="$format.percent(percentDiff(commission.admin_commission, commission.admin_commission_compare))"
                    :loading="$store.getters['userStats/getLoading']('commissionStats')"
                    :error="$store.getters['userStats/getError']('commissionStats')"
                    tooltip-formatter="currency"
                />
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
                <WidgetStatChart
                    :title="$t('dashboard.newPublishers')"
                    :value="$format.number(publisherQuantity.quantity)"
                    :difference="$format.percent(percentDiff(publisherQuantity.quantity, publisherQuantity.quantity_compare))"
                    :loading="$store.getters['userStats/getLoading']('publisherQuantityStats')"
                    :error="$store.getters['userStats/getError']('publisherQuantityStats')"
                    tooltip-formatter="round"
                />
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
                <WidgetStatChart
                    :title="$t('dashboard.newSpaces')"
                    :value="$format.number(spacesQuantity.quantity)"
                    :difference="$format.percent(percentDiff(spacesQuantity.quantity, spacesQuantity.quantity_compare))"
                    :loading="$store.getters['userStats/getLoading']('spacesQuantityStats')"
                    :error="$store.getters['userStats/getError']('spacesQuantityStats')"
                    tooltip-formatter="round"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12 mb-4">
                <MultiChartCard
                    :data="chartOverview"
                    :height="276"
                    :loading="isChartOverviewLoading"
                    :options="chartOverviewOptions"
                    :title="$t('dashboard.overview')"
                    @currentChart="setCurrentChart"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-xl-6 mb-4">
                <WidgetNewSpaces ref="widgetNewSpaces" />
            </div>
        </div>

    </div>
</template>

<script>
    import DashboardMixin from '@/views/common/dashboard/index.vue';
    import WidgetNewSpaces from '@/views/common/dashboard/parts/WidgetNewSpaces.vue';

    export default {
        name: 'AdminDashboard',
        mixins: [DashboardMixin],
        components: {
            WidgetNewSpaces,
        },
        data() {
            return {
                currentChart: 'orders',
            };
        },
        computed: {
            loading() {
                return (
                    this.$store.getters['userStats/getLoading']('ordersStats') ||
                    this.$store.getters['userStats/getLoading']('commissionStats') ||
                    this.$store.getters['userStats/getLoading']('publisherQuantityStats') ||
                    this.$store.getters['userStats/getLoading']('spacesQuantityStats') ||
                    this.$store.getters['userStats/getLoading']('ordersChart') ||
                    this.$store.getters['userStats/getLoading']('adminCommissionChart') ||
                    this.$store.getters['userStats/getLoading']('advertiserCommissionChart') ||
                    this.isChartOverviewLoading
                );
            },
            iconAnimation() {
                return this.loading ? 'spin' : undefined;
            },
            chartOverview() {
                return {
                    orders: this.getChartItem(this.getChartData(this.ordersChart.chart, 'orders'), this.$t('dashboard.transactions'), '#5369f8'),
                    advertiserCommission: this.getChartItem(this.getChartData(this.adminCommissionChart.chart, 'admin_commission'), this.$t('dashboard.ourCommission'), '#f77e53'),
                    ourCommission: this.getChartItem(this.getChartData(this.advertiserCommissionChart.chart, 'advertiser_commission'), this.$t('dashboard.advertiserCommission'), '#43d39e'),
                };
            },
        },
        methods: {
            fetchData(params = {}) {
                this.fetchOrdersStats(params);
                this.fetchCommissionStats(params);
                this.fetchPublisherQuantityStats(params);
                this.fetchSpacesQuantityStats(params);
                this.fetchOrdersChart(params);
                this.fetchAdminCommissionChart(params);
                this.fetchAdvertiserCommissionChart(params);
            },
            reload() {
                const params = { cache: false };
                this.fetchData(params);
                this.$refs.widgetNewSpaces.fetchData(params);
            },
        },
    };
</script>
