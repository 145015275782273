<template>
    <div>
        <PageHeader>
            {{ $t('widgeterShops.title') }}
            <template #subtitle>
                {{ $t('widgeterShops.subtitle') }}
            </template>
        </PageHeader>
        <space-provider fetch-categories>
            <card :header="$t('widgeterShops.filters')">
                <SpaceSwitch class="col-sm-12" :label="$t('textLinks.space')" row @spaceId="onSpaceId" />
                <text-field
                    v-model="filterModel.name"
                    class="col-sm-12"
                    :label="$t('widgeterShops.shop')"
                    :placeholder="$t('widgeterShops.campaignNamePlaceholder')"
                    type="text"
                    row
                    :disabled="!currentSpaceId"
                />
                <multi-select-field
                    v-model="filterModel.categories"
                    class="col-sm-12"
                    :options="categoryOptions"
                    :label="$t('widgeterShops.category')"
                    row
                />
                <multi-select-field
                    v-model="filterModel.statuses"
                    variant="checkbox"
                    class="col-sm-12"
                    :options="campaignStatusOptions"
                    :label="$t('widgeterShops.status')"
                    row
                />

                <button-default
                    icon="filter"
                    class="mr-1"
                    :loading="campaignsRequest.loading"
                    @click="campaignsFilter"
                >
                    {{ $t('widgeterShops.filter') }}
                </button-default>
            </card>
            <card :header="$t('widgeterShops.shopsList')">
                <error-message v-model="campaignsFilterError" @dismissed="campaignsFilterError = null" />
                <error-message v-model="campaignsRequest.error" />

                <table-request
                    v-if="currentSpaceId"
                    :request="campaignsRequest"
                    :parse="parseResults"
                    :headers="[
                        {
                            label: $t('widgeterShops.id'),
                            value: (item) => item.id,
                        },
                        {
                            label: $t('widgeterShops.shop'),
                            slot: 'shop',
                            align: 'left',
                        },
                        {
                            label: $t('widgeterShops.category'),
                            slot: 'category',
                            align: 'left',
                        },
                        {
                            label: $t('widgeterShops.commission'),
                            slot: 'commissions',
                            align: 'left',
                        },
                        {
                            label: $t('widgeterShops.textLinks'),
                            slot: 'textLinks',
                            align: 'left',
                        },
                        {
                            label: $t('widgeterShops.transactionDetectTime'),
                            value: (item) => item.cookieExpireDays ? $tc('widgeterShops.transactionDetectTimeInDays', item.cookieExpireDays) : '-',
                            align: 'left',
                        },
                        {
                            label: $t('widgeterShops.productFeed'),
                            slot: 'productFeed',
                            align: 'left',
                        },
                        {
                            label: $t('widgeterShops.status'),
                            slot: 'status',
                            align: 'left',
                        },
                    ]"
                >
                    <template #shop="{ item }">
                        <a :href="item.url">
                            <img class="logo" :src="item.logo" :alt="item.name">
                        </a>
                    </template>
                    <template #category="{ item }">
                        <ul class="categories">
                            <li v-for="category in item.categories" :key="category.id">
                                {{ category.name }}
                            </li>
                        </ul>
                    </template>
                    <template #commissions="{ item }">
                        <ul class="categories">
                            <li v-for="(commission, index) in item.formattedCommissions" :key="index">
                                {{ commission }}
                            </li>
                        </ul>
                    </template>
                    <template #textLinks="{ item }">
                        <BadgeBoolean :value="item.allowTextLinks" />
                    </template>
                    <template #productFeed="{ item }">
                        <button-default
                            v-if="item.feeds && item.feeds.length > 0"
                            class="feed-btn"
                            variant="primary"
                            icon="list"
                            short
                            size="sm"
                            @click="onShowFeeds(item.feeds)"
                        >
                            {{ $t('widgeterShops.showFeeds') }}
                        </button-default>
                    </template>
                    <template #status="{ item }">
                        <CampaignSpaceStatusBadge :status="item.status" />
                    </template>
                </table-request>

                <span v-else>{{ $t('selectPageToViewStores') }}</span>
            </card>
        </space-provider>
        <b-modal
            v-model="feedsModalShown"
            :title="$t('widgeterShops.productFeed')"
            title-class="font-18"
            size="lg"
            hide-footer
            @close="handleFeedsClose"
        >
            <b-list-group ref="feedsModalRef">
                <b-list-group-item v-for="(feed, index) in feeds" :key="index" class="feeds">
                    <span class="feedUrl">{{ feed }}</span>
                    <button-default
                        class="feed-btn"
                        variant="primary"
                        icon="copy"
                        size="sm"
                        short
                        @click="onCopyFeedUrl(feed)"
                    >
                        {{ $t('widgeterShops.copyFeedUrl') }}
                    </button-default>
                </b-list-group-item>
            </b-list-group>
        </b-modal>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import numberUtil from '@/utils/number';
    import PageHeader from '@/components/common/PageHeader.vue';
    import Card from '@/components/common/Card.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import SpaceProvider from '@/components/common/SpaceProvider.vue';
    import TextField from '@/components/common/TextField.vue';
    import MultiSelectField from '@/components/common/MultiSelectField.vue';
    import SpaceSwitch from '@/components/common/SpaceSwitch.vue';
    import BadgeBoolean from '@/components/common/BadgeBoolean.vue';
    import TableRequest from '@/components/common/TableRequest.vue';
    import WrapRequest from '@/utils/wrapRequest';
    import spaceService from '@/services/spaceService';
    import toastService from '@/services/toastService';
    import CampaignSpaceStatusBadge from '@/components/common/CampaignSpaceStatusBadge.vue';

    export default {
        name: 'Shops',
        components: {
            TableRequest,
            BadgeBoolean,
            CampaignSpaceStatusBadge,
            SpaceSwitch,
            MultiSelectField,
            TextField,
            SpaceProvider,
            ButtonDefault,
            ErrorMessage,
            PageHeader,
            Card,
        },
        data() {
            return {
                filterModel: {
                    name: '',
                    categories: [],
                    statuses: [],
                },
                campaignsRequest: new WrapRequest(({ page, perPage }) =>
                    spaceService.fetchCampaigns({
                        spaceId: this.currentSpaceId,
                        page,
                        perPage,
                        ...this.filterModel,
                    })
                ),
                feedsModalShown: false,
                feeds: null,
                campaignsFilterError: null,
                currentSpaceId: null,
            };
        },
        computed: {
            ...mapState({
                campaignsIds: state => state.space.campaignsIds,
                campaigns: state => state.space.campaigns,
            }),
            ...mapGetters({
                categoryOptions: 'space/categoryOptions',
                campaignStatusOptions: 'space/campaignStatusOptions',
            }),
        },
        watch: {
            currentSpaceId: {
                handler(spaceId) {
                    if (spaceId) {
                        this.campaignsFilterError = null;
                        this.campaignsRequest.request();
                    }
                },
                immediate: true,
            },
        },
        methods: {
            onSpaceId(spaceId) {
                this.currentSpaceId = spaceId;
            },
            campaignsFilter() {
                if (!this.currentSpaceId) {
                    this.campaignsFilterError = this.$t('selectWebsite');
                } else {
                    this.campaignsRequest.request();
                }
            },
            async onCopyFeedUrl(feed) {
                await this.$copyText(feed, this.$refs.feedsModalRef);
                toastService.successToast(this.$t('widgeterShops.feedCopied'));
            },
            onShowFeeds(feeds) {
                this.feeds = feeds;
                this.feedsModalShown = true;
            },
            handleFeedsClose() {
                this.feeds = null;
            },
            formatCommissions(commissions) {
                if (!commissions) {
                    return [];
                }
                return commissions
                    .filter(commission => commission.commission)
                    .map(commission => {
                        let format;
                        switch (commission.type) {
                            case 'amount':
                                format = 'currency';
                                break;
                            case 'percent':
                                format = 'percent';
                                break;
                            default:
                                format = 'decimal';
                        }
                        return `${this.$t('widgeterShops.cps')} ${numberUtil.format(format, commission.commission)}${commission.description ? ` ${commission.description}` : ''}`;
                    });
            },
            parseResults(items) {
                return items.map(item => ({
                    ...item,
                    formattedCommissions: this.formatCommissions(item.commissions),
                }));
            },
        },
    };
</script>

<style lang="scss" scoped>
    .logo {
        max-width: 75px;
        max-height: 39px;
    }

    .categories {
        margin: 0;
        padding-left: 17px;
    }

    .feeds {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    }

    .feedUrl {
        flex-grow: 1;
        margin-right: 10px;
        word-break: break-all;
    }

    .feed-btn {
        white-space: nowrap;
    }
</style>
