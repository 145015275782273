<template>
    <b-overlay :show="loading" rounded="sm" class="h-100">
        <Card :header="$t('availability')" :loading="!product && loading" class="m-0 shadow-none">
            <ErrorMessage v-if="error" :value="error" />
            <Chart v-else :height="200" :series="chartSeries" :options="chartOptions" />
        </Card>
    </b-overlay>
</template>



<script>
    import { differenceInDays, addDays } from 'date-fns';
    import Chart from '@/components/common/Chart.vue';
    import dateUtil from '@/utils/date';

    export default {
        name: 'AvailabilityChart',
        components: {
            Chart,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.id),
                chartOptions: {
                    chart: {
                        type: 'rangeBar',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            rangeBarGroupRows: true,
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: (val, opts) => {
                            const diff = differenceInDays(val[1], val[0]);
                            return `${diff}${diff > 1 ? ` ${this.$t('days').toLowerCase()}` : ` ${this.$t('day').toLowerCase()}`}`;
                        },
                    },
                    xaxis: {
                        type: 'datetime',
                    },
                    yaxis: {
                        show: false,
                    },
                    grid: {
                        row: {
                            colors: ['#f3f4f5', '#fff'],
                            opacity: 1,
                        },
                    },
                    stroke: {
                        show: false,
                    },
                    fill: {
                        type: 'solid',
                        opacity: 1,
                    },
                    legend: {
                        show: false,
                    },
                    tooltip: {
                        y: {
                            formatter() {
                                return '';
                            },
                        },
                    },
                },
            };
        },
        computed: {
            product() {
                return this.parentComponent.product;
            },
            apiParams() {
                return {
                    requestParams: {
                        startDate: dateUtil.formatDateForUrl(addDays(Date.now(), -30)),
                        endDate: dateUtil.formatDateForUrl(Date.now()),
                    },
                    urlParams: { offerId: this.id },
                };
            },
            loading() {
                return this.$store.getters['offers/getLoading']('availabilityChart');
            },
            error() {
                return this.$store.getters['offers/getError']('availabilityChart')?.message;
            },
            chartData() {
                return this.$store.getters['offers/getAvailabilityChart'];
            },
            chartSeries() {
                return this.chartData;
            },
        },
        methods: {
            fetchData() {
                this.$store.dispatch('offers/fetchAvailabilityChart', this.apiParams);
            },
            clearStoreData() {
                this.$store.commit('offers/clearAvailabilityChart');
            },
        },
        created() {
            this.clearStoreData();
            this.fetchData();
        },
    };
</script>
