import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';
import financeServiceOLD, { PAYOUT_THRESHOLD, FinanceDocumentStatus, translateFinanceDocumentStatus } from './financeServiceOLD';

export { PAYOUT_THRESHOLD, FinanceDocumentStatus, translateFinanceDocumentStatus };

export const ENDPOINTS = deepFreeze({
    PUBLISHER_FINANCE_DOCUMENTS: {
        URI: () => '/api/v1/publisher-finance-documents',
        methods: ['get'],
        default: {},
    },
    PUBLISHER_FINANCE_DOCUMENTS_STATUSES: {
        URI: () => '/api/v1/publisher-finance-documents/statuses',
        methods: ['put'],
        default: {},
        cacheToClear: ['/api/v1/publisher-finance-documents'],
    },
    FINANCE_DOCUMENTS_DOWNLOAD: {
        URI: () => '/api/v1/finance-documents/{{id}}/download',
        methods: ['get'],
    },
});

export default {
    ...financeServiceOLD,
    ...serviceFactory.build(ENDPOINTS),
};
