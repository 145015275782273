import campaignsService, { ENDPOINTS } from '@/services/campaignsService';
import moduleFactory from '@/store/modules/moduleFactory';
import storageKeys from '@/Storage';

const moduleStore = moduleFactory.build(ENDPOINTS, campaignsService);

export default {
    namespaced: true,
    state: {
        ...moduleStore.state,
        currentCampaign: null,
        campaignsInitialized: false,
    },
    getters: {
        ...moduleStore.getters,
        getCurrentCampaign: state => state.currentCampaign,
        getCampaignsInitialized: state => state.campaignsInitialized,
    },
    mutations: {
        ...moduleStore.mutations,
        setCurrentCampaign: (state, payload) => {
            state.currentCampaign = payload;
            window.localStorage.setItem(storageKeys.CAMPAIGN_ID, payload.id || '');
        },
        setCampaignsInitialized: (state, payload) => (state.campaignsInitialized = payload),
    },
    actions: {
        ...moduleStore.actions,
    },
};
