var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-responsive",style:({ maxHeight: _vm.maxHeight })},[_c('table',{staticClass:"table table-hover table-default mb-0"},[(_vm.showHeaders)?_c('thead',[_c('tr',[(_vm.collapsible)?_c('th'):_vm._e(),(_vm.selectable)?_c('th'):_vm._e(),_vm._l((_vm.headers),function(header,columnIndex){return _c('th',{key:columnIndex,staticClass:"header-label",style:({
                            textAlign: _vm.getColumnAlign(header, columnIndex)
                        }),attrs:{"scope":"col"},domProps:{"innerHTML":_vm._s(header.label)}})})],2)]):_vm._e(),_c('tbody',[(_vm.loading)?_c('tr',[_c('td',{attrs:{"colspan":_vm.headersCount}},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-spinner')],1)])]):(!_vm.hasItems)?_c('tr',[_c('td',{attrs:{"colspan":_vm.headersCount}},[_c('div',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.$t('noResult'))+" ")])])]):_vm._l((_vm.items),function(row,rowIndex){return [_c('tr',{key:row.id,class:{
                            'row-clickable': _vm.hasClickableRows,
                        },on:{"click":function($event){return _vm.onRowClick(row)}}},[(_vm.collapsible)?_c('td',{staticClass:"clickable-cell",class:{
                                'clickable': _vm.hasRowClickListener
                            },on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.onCollapseCellClick(rowIndex)}}}):_vm._e(),(_vm.collapsible)?_c('td',{staticClass:"clickable",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.onCollapseCellClick(rowIndex)}}},[_c('feather',{class:{
                                    'collapse-arrow': true,
                                    'collapse-arrow--rotated': _vm.isRowCollapsed(rowIndex),
                                },attrs:{"size":"14","type":"chevron-right"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collapsedRows),expression:"collapsedRows"}],ref:"collapseCheckbox",refInFor:true,staticClass:"hidden-checkbox",attrs:{"type":"checkbox"},domProps:{"value":rowIndex,"checked":Array.isArray(_vm.collapsedRows)?_vm._i(_vm.collapsedRows,rowIndex)>-1:(_vm.collapsedRows)},on:{"click":function($event){$event.stopPropagation();},"change":function($event){var $$a=_vm.collapsedRows,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=rowIndex,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.collapsedRows=$$a.concat([$$v]))}else{$$i>-1&&(_vm.collapsedRows=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.collapsedRows=$$c}}}})],1):_vm._e(),(_vm.selectable)?_c('td',{staticClass:"clickable",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.onSelectCellClick(rowIndex)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"selectCheckbox",refInFor:true,attrs:{"type":"checkbox"},domProps:{"value":row,"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,row)>-1:(_vm.model)},on:{"click":function($event){$event.stopPropagation();},"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=row,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}})]):_vm._e(),_vm._l((_vm.headers),function(header,columnIndex){return _c('td',{key:columnIndex,class:{
                                'clickable': _vm.hasRowClickListener
                            },style:(Object.assign({}, {textAlign: _vm.getColumnAlign(header, columnIndex)},
                                header.style))},[_vm._t(header.slot,function(){return [_c(header.ellipsis ? 'ellipsis' : 'span',_vm._b({tag:"component"},'component',header.ellipsis,false),[_vm._v(" "+_vm._s(header.value && header.value(row, rowIndex))+" ")])]},{"item":row})],2)})],2),(_vm.isRowCollapsed(rowIndex))?_c('tr',{key:((row.id) + "-content"),staticClass:"row--collapsed"},[_c('slide-y-down-transition',[_c('td',{attrs:{"colspan":_vm.headersCount}},[_vm._t("collapsed",null,{"item":row})],2)])],1):_vm._e()]}),(_vm.hasItems && _vm.hasAppendedRow)?_c('tr',_vm._l((_vm.headers),function(header,columnIndex){return _c('td',{key:columnIndex,style:(Object.assign({}, {textAlign: _vm.getColumnAlign(header, columnIndex)},
                            header.appendedRow ? header.appendedRow.style : {}))},[(header.appendedRow)?_vm._t(header.appendedRow.slot,function(){return [_vm._v(" "+_vm._s(header.appendedRow.value && header.appendedRow.value())+" ")]}):_vm._e()],2)}),0):_vm._e()],2)])]),(_vm.showPagination)?_c('pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),(_vm.hasDetailsModal)?_c('b-modal',{attrs:{"size":"xl","title":_vm.detailsModalTitle,"title-class":"font-18","hide-footer":""},model:{value:(_vm.modalShown),callback:function ($$v) {_vm.modalShown=$$v},expression:"modalShown"}},[(_vm.activeRow)?_vm._t("detailsModal",null,{"item":_vm.activeRow}):_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }