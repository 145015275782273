<template>
    <div class="d-flex flex-wrap gap-4">
        <div class="d-flex flex-wrap gap-4 flex-grow-1 flex-basis-0">
            <WidgetStat
                class="flex-grow-1 flex-basis-0 width-min-300 h-auto"
                :title="$t('donationsInThePreviousMonth')"
                :value="$format.currency(donationsPreviousMonth)"
                :loading="donationsPreviousMonthLoading"
                :error="donationsPreviousMonthError"
            />
            <WidgetStat
                class="flex-grow-1 flex-basis-0 width-min-300 h-auto"
                :title="$t('publishersInThePreviousMonth')"
                :value="publishersPreviousMonth"
                :loading="publishersPreviousMonthLoading"
                :error="publishersPreviousMonthError"
            />
        </div>
        <div class="d-flex flex-wrap gap-4 flex-grow-1 flex-basis-0">
            <WidgetStat
                class="flex-grow-1 flex-basis-0 width-min-300 h-auto"
                :title="$t('revenueInThePreviousMonth')"
                :value="$format.currency(commissionPreviousMonth)"
                :loading="commissionPreviousMonthLoading"
                :error="commissionPreviousMonthError"
            />
            <WidgetStat
                class="flex-grow-1 flex-basis-0 width-min-300 h-auto"
                :title="$t('withdrawalOrders')"
                :value="$format.currency(payouts)"
                :loading="payoutsLoading"
                :error="payoutsError"
            />
        </div>
    </div>
</template>



<script>
    import { format, startOfMonth, endOfMonth, addMonths } from 'date-fns';
    import tipboxService from '@/services/tipboxService';
    import WidgetStat from '@/components/common/WidgetStat.vue';

    export default {
        name: 'Stats',
        components: {
            WidgetStat,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                donationsPreviousMonth: null,
                donationsPreviousMonthError: null,
                donationsPreviousMonthLoading: false,
                commissionPreviousMonth: null,
                commissionPreviousMonthError: null,
                commissionPreviousMonthLoading: false,
                publishersPreviousMonth: null,
                publishersPreviousMonthError: null,
                publishersPreviousMonthLoading: false,
                payouts: null,
                payoutsError: null,
                payoutsLoading: false,
            };
        },
        methods: {
            getData() {
                const dateFormat = 'yyyy-MM-dd';
                const now = new Date();
                const currentMonth = startOfMonth(now);
                const startDate = format(startOfMonth(addMonths(currentMonth, -1)), dateFormat);
                const endDate = format(endOfMonth(startDate), dateFormat);

                this.donationsPreviousMonthLoading = true;
                this.commissionPreviousMonthLoading = true;
                this.publishersPreviousMonthLoading = true;
                this.payoutsLoading = true;

                tipboxService
                    .getAmountStats({ requestParams: { startDate, endDate } })
                    .then(data => (this.donationsPreviousMonth = data))
                    .catch(error => (this.donationsPreviousMonthError = error))
                    .finally(() => (this.donationsPreviousMonthLoading = false));

                tipboxService
                    .getCommissionStats({ requestParams: { startDate, endDate } })
                    .then(data => (this.commissionPreviousMonth = data))
                    .catch(error => (this.commissionPreviousMonthError = error))
                    .finally(() => (this.commissionPreviousMonthLoading = false));

                tipboxService
                    .getPublishersStats({ requestParams: { startDate, endDate } })
                    .then(data => (this.publishersPreviousMonth = data))
                    .catch(error => (this.publishersPreviousMonthError = error))
                    .finally(() => (this.publishersPreviousMonthLoading = false));

                tipboxService
                    .getPayoutsStats()
                    .then(data => (this.payouts = data))
                    .catch(error => (this.payoutsError = error))
                    .finally(() => (this.payoutsLoading = false));
            },
        },
        created() {
            this.getData();
        },
    };
</script>
