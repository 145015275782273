<template>
    <div>

        <PageHeader :items="breadcrumb">
            <span v-if="item">{{ item.name }}</span>
            <b-skeleton v-if="!item && !error" animation="wave" width="45%" height="25px" class="mb-0" />
            <template #side>
                <div v-if="!item && error">
                    <strong>{{ $t('goBackTo') }}</strong>: <b-link :to="parentRoute">
                        {{ $t(parentRoute.meta.title) }}
                    </b-link>
                </div>
                <b-skeleton v-if="!item && !error" animation="wave" width="100%" class="mb-0 mt-1 width-300" />
            </template>
        </PageHeader>

        <Card :loading="(!item || loading) && !error" class="flex-basis-0 flex-grow-1 width-min-400 mb-auto">

            <ErrorMessage v-if="error" :value="error" :dismissible="false" />

            <b-tabs v-if="item && !error" v-model="currentTab" content-class="">
                <b-tab v-for="tabName in tabs" :key="tabName" @click="showTab(tabName)">
                    <template #title>
                        <div class="d-flex justify-content-center align-items-center gap-1">
                            {{ $t(`adminCampaignsDetailsTabs.${tabName}`) }}
                            <div v-if="tabsLoading[tabName]" class="tab-spinner"><b-spinner type="border" small /></div>
                            <span v-else-if="Array.isArray(item[tabName]) && item[tabName].length">({{ item[tabName].length }})</span>
                        </div>
                    </template>
                    <router-view :name="tabName" />
                </b-tab>
            </b-tabs>

        </Card>

    </div>
</template>



<script>
    import PageHeader from '@/components/common/PageHeader.vue';
    import campaignsService from '@/services/campaignsService';

    export const TABS = ['settings', 'categories', 'commissions', 'feeds', 'locations'];

    export default {
        name: 'WebpagesDetails',
        components: {
            PageHeader,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            const tabIndex = TABS.findIndex(tab => this.$route.params.tab === tab);
            const currentTab = tabIndex !== -1 ? tabIndex : 0;
            return {
                tabs: TABS,
                currentTab,
                tab: TABS.includes(this.$route.params.tab) ? this.$route.params.tab : TABS[0],
                id: Number.parseInt(this.$route.params.id),
                loading: false,
                item: null,
                error: null,
            };
        },
        computed: {
            breadcrumb() {
                if (!this.item) return [];
                return [
                    { text: this.$t(this.parentRoute.meta.title), to: this.$route.params.parentUrl || this.parentRoute },
                    { text: this.item?.name || '---', active: true },
                ];
            },
            parentRoute() {
                return this.$route.matched.slice(-4)[0];
            },
            tabsLoading() {
                return {
                    settings: this.$store.getters['campaigns/getLoading']('campaigns'),
                    categories: this.$store.getters['campaigns/getLoading']('categories'),
                    commissions: this.$store.getters['campaigns/getLoading']('campaignsCommissions'),
                    feeds: this.$store.getters['campaigns/getLoading']('feeds'),
                    locations: false,
                };
            },
        },
        methods: {
            showTab(tab) {
                if (tab !== this.$route.params.tab) {
                    const { id, sep, slug } = this.$route.params;
                    this.$router.push({ name: 'CampaignsDetailsTab', params: { id, sep, slug, tab } });
                }
            },
            loadItem() {
                this.loading = true;
                this.error = null;
                campaignsService
                    .getCampaigns({ urlParams: { id: this.id } })
                    .then(data => (this.item = data))
                    .catch(error => (this.error = error))
                    .then(() => (this.loading = false));
            },
        },
        created() {
            this.loadItem();
        },
    };
</script>



<style lang="scss" scoped>
    .tab-spinner {
        min-width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
