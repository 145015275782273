<script>
    import FlatPickr from 'vue-flatpickr-component';

    export default {
        name: 'FlatPickr',
        extends: FlatPickr,
        methods: {
            onCloseDatePicker(value, dateString, fp) {
                if (fp?.config?.mode === 'range' && value.length && value.length !== 2) {
                    const nowDate = new Date();
                    const selectedDate = value[0];
                    const dateRange = [selectedDate];
                    nowDate >= selectedDate ? dateRange.push(nowDate) : dateRange.unshift(nowDate); //eslint-disable-line
                    const dateRangeFormatted = dateRange.map(date => fp.formatDate(date, fp.config.dateFormat));
                    fp.setDate(dateRangeFormatted, true);
                }
            },
            onClose(selectedDates, dateStr) {
                this.onCloseDatePicker(selectedDates, dateStr, this.fp);
                FlatPickr.methods.onClose.bind(this)(selectedDates, dateStr);
            },
        },
    };
</script>
