<template>
    <b-overlay :show="loading" rounded="sm" class="h-100">
        <Card :header="$t('sales')" class="m-0 shadow-none">
            <ErrorMessage v-if="error" :value="error" />
            <Chart v-else :height="400" :series="chartSeries" :options="chartOptions" />
        </Card>
    </b-overlay>
</template>



<script>
    import { addDays } from 'date-fns';
    import Chart from '@/components/common/Chart.vue';
    import dateUtil from '@/utils/date';

    export default {
        name: 'SalesChart',
        components: {
            Chart,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.id),
                chartOptions: {
                    chart: {
                        type: 'bar',
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            format: 'MMM',
                            datetimeUTC: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            endingShape: 'flat',
                            horizontal: false,
                            borderRadius: 0,
                            dataLabels: {
                                position: 'top',
                            },
                        },
                    },
                    fill: {
                        type: 'solid',
                        opacity: 1,
                    },
                    dataLabels: {
                        enabled: true,
                    },
                    stroke: {
                        show: false,
                        width: 0,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
            };
        },
        computed: {
            product() {
                return this.parentComponent.product;
            },
            apiParams() {
                return {
                    requestParams: {
                        startDate: dateUtil.formatDateForUrl(addDays(Date.now(), -30)),
                        endDate: dateUtil.formatDateForUrl(Date.now()),
                    },
                    urlParams: { offerId: this.id },
                };
            },
            loading() {
                return this.$store.getters['offers/getLoading']('quantityChart');
            },
            error() {
                return this.$store.getters['offers/getError']('quantityChart')?.message;
            },
            chartData() {
                return this.$store.getters['offers/getQuantityChart'];
            },
            chartSeries() {
                return [
                    {
                        data: this.chartData,
                    },
                ];
            },
        },
        methods: {
            fetchData() {
                this.$store.dispatch('offers/fetchQuantityChart', this.apiParams);
            },
            clearStoreData() {
                this.$store.commit('offers/clearQuantityChart');
            },
        },
        created() {
            this.clearStoreData();
            this.fetchData();
        },
    };
</script>
