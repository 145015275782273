<template>
    <div class="d-flex flex-column align-items-center py-5">

        <b-badge variant="success" class="h6 mb-4 px-4 py-2 text-white text-uppercase" pill>{{ $t('theNew') }}</b-badge>

        <h1 class="mb-1 text-center">{{ $t('tipboxWidget') }}</h1>

        <p class="h5 font-weight-normal mb-5 text-center">{{ $t('createValuableContentAndCollectTipsFromYourUsers') }}</p>

        <video
            autoplay
            loop
            src="@/assets/tipbox/video_anim_1280.mp4"
            type="video/mp4"
            width="320"
            class="mb-5 mw-100"
        ></video>

        <FormValidator :validator="$v.policies" :attribute="$t('termsAndConditions')" :messages="{ required: $t('tipboxPoliciesRequired') }" v-slot="slotProps" class="mb-4">
            <b-form-checkbox v-model="policies" :value="true" :unchecked-value="false" :disabled="isSubmitting" :state="slotProps.state">
                <span class="text-body" v-html="$t('tipboxPolicies', { url: policiesUrl })"></span>
            </b-form-checkbox>
        </FormValidator>

        <ButtonIcon
            :loading="isSubmitting"
            :disabled="$v.policies.$dirty && $v.policies.$invalid"
            hide-label-while-loading
            variant="primary"
            class="width-120"
            @click="goToTipbox()"
        >
            {{ $t('hereWeGo') }}
        </ButtonIcon>

        <b-modal v-model="modalVisible" hide-footer hide-header centered>
            <div class="text-center p-2">
                <h4 class="mb-4">{{ $t('completeYourDetails') }}</h4>
                <div class="mb-4 font-size-15">
                    <p v-if="user.isCompany">{{ $t('companyBeforeActivatingTheServiceCheckAndUpdateYourData') }}</p>
                    <p v-else>{{ $t('privateBeforeActivatingTheServiceCheckAndUpdateYourData') }}</p>
                </div>
                <b-button variant="primary" class="px-5" :to="{ name: 'AccountSettings' }">{{ $t('goToAccountSettings') }}</b-button>
            </div>
        </b-modal>

    </div>
</template>



<script>
    import FormValidator from '@/components/common/FormValidator.vue';
    import usercomService from '@/services/usercomService';

    export default {
        name: 'TipboxPolicies',
        components: {
            FormValidator,
        },
        data() {
            return {
                policies: this.$store.getters['tipbox/getTipboxSettings']?.policies || false,
                policiesUrl: 'https://getbuybox.com/regulamin-tip-box/',
                isSubmitting: false,
                modalVisible: false,
            };
        },
        validations: {
            policies: { required: val => val === true },
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
        },
        methods: {
            goToTipbox() {
                this.$v.$touch();
                if (this.$v.$invalid) return null;
                if (!this.$store.getters['tipbox/getTipboxSettings']?.paymentDataCompleted) return this.showModal();
                return this.acceptTipboxPolicies();
            },
            acceptTipboxPolicies() {
                this.isSubmitting = true;
                return this.$store
                    .dispatch('tipbox/patchTipboxSettings', { requestParams: { policies: this.policies } })
                    .then(data => {
                        this.$toastr.success(this.$t('tipboxPoliciesAccepted'));
                        this.$store.commit('tipbox/setTipboxSettings', data);
                        usercomService.userengage('tipbox_activated');
                    })
                    .catch(error => this.$toastr.error(this.$t(error.message), this.$t('errorOccurred')))
                    .finally(() => (this.isSubmitting = false));
            },
            showModal() {
                this.modalVisible = true;
            },
        },
    };
</script>
