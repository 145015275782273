<template>
    <LayoutAuth>
        <template #default>
            <b-form @submit.prevent>
                <FormValidator :validator="$v.form.email" v-slot="slotProps" class="mb-3">
                    <b-form-input
                        v-model="form.email"
                        :placeholder="$t('emailAddress').toLowerCase()"
                        :state="slotProps.state"
                        :disabled="submitting"
                        @keyup.enter="handleSubmit()"
                    />
                </FormValidator>
                <FormValidator :validator="$v.form.name" v-slot="slotProps" class="mb-3">
                    <b-form-input
                        v-model="form.name"
                        :placeholder="$t('firstName').toLowerCase()"
                        :state="slotProps.state"
                        :disabled="submitting"
                        @keyup.enter="handleSubmit()"
                    />
                </FormValidator>
                <FormValidator :validator="$v.form.password" v-slot="slotProps" class="mb-3">
                    <b-form-input
                        v-model="form.password"
                        :placeholder="$t('password').toLowerCase()"
                        type="password"
                        :state="slotProps.state"
                        :disabled="submitting"
                        @keyup.enter="handleSubmit()"
                    />
                </FormValidator>
                <FormValidator :validator="$v.form.rules" class="agreements mb-3">
                    <template #default="slotProps">
                        <div class="mt-2"></div>
                        <b-form-checkbox v-model="form.rules" :value="true" :unchecked-value="null" :state="slotProps.state">
                            <span v-html="$t('auth.agreements.rules', { urlRules: urlRules, urlPrivacyPolicy: urlPrivacyPolicy })"></span>
                        </b-form-checkbox>
                    </template>
                    <template #bottom>
                        <div class="mb-3"></div>
                        <b-form-checkbox v-model="form.newsletter" :value="true" :unchecked-value="false">
                            <span>{{ $t('auth.agreements.newsletter') }}</span>
                        </b-form-checkbox>
                    </template>
                </FormValidator>
                <b-form-group>
                    <ButtonIcon
                        :loading="submitting || logging"
                        :flip-icon="true"
                        variant="brand"
                        size="lg"
                        class="btn-login"
                        block
                        @click="handleSubmit()"
                    >
                        <template #default>
                            {{ $t('auth.register') }}
                        </template>
                        <template #icon>
                            <img src="@/assets/arrow-button.svg" alt="" width="45px" class="ml-2">
                        </template>
                    </ButtonIcon>
                </b-form-group>
            </b-form>
        </template>
        <template #page-title>
            <div class="d-none d-md-flex mx-auto text-center">
                <span class="mx-auto">{{ $t('auth.pageRegister.pageTitle') }}</span>
            </div>
            <div class="d-flex d-md-none mx-auto text-center flex-column">
                <div class="hello-header mx-auto">
                    {{ $t('auth.pageRegister.helloHeader') }}
                </div>
                <div class="hello-subheader">
                    <span v-html="$t('auth.pageRegister.helloSubheader')"></span>
                </div>
            </div>
        </template>
        <template #hello-header>
            {{ $t('auth.pageRegister.helloHeader') }}
        </template>
        <template #hello-subheader>
            <span v-html="$t('auth.pageRegister.helloSubheader')"></span>
        </template>
        <template #divider>
            <div class="divider">
                {{ $t('or') }}
            </div>
        </template>
        <template #cta-text>
            {{ $t('auth.pageRegister.ctaText') }}
        </template>
        <template #cta-link>
            <router-link :to="{name: 'Login'}" class="text-brand">
                {{ $t('auth.logIn') }}
            </router-link>
        </template>
    </LayoutAuth>
</template>



<script>
    import { required, email, minLength, maxLength } from 'vuelidate/lib/validators';
    import usercomService from '@/services/usercomService';
    import AuthMixin from './auth-mixin.vue';
    import { mode } from '@/utils/util';

    export default {
        name: 'Register',
        mixins: [AuthMixin],
        data() {
            return {
                form: {
                    name: '',
                    email: this.$route.query?.email || '',
                    password: '',
                    rules: null,
                    newsletter: false,
                    utmSource: this.$route.query?.utm_source || '',
                    recommenderId: null,
                },
                logging: false,
                urlRules: `${process.env.VUE_APP_MARKETING_PAGE_URL}/regulamin`,
                urlPrivacyPolicy: `${process.env.VUE_APP_MARKETING_PAGE_URL}/polityka-prywatnosci`,
                isWidgeterMode: mode === 'WIDGETER',
            };
        },
        validations: {
            form: {
                name: { required },
                email: { required, email },
                password: { required, minLength: minLength(8), maxLength: maxLength(255) },
                rules: { required },
            },
        },
        methods: {
            formRequest() {
                const data = {
                    type: 'publisher',
                    firstName: this.form.name,
                    email: this.form.email,
                    password: this.form.password,
                    utmSource: this.form.utmSource,
                    notifications: { news: this.form.newsletter },
                    recommenderId: this.form.recommenderId,
                };
                if (this.isWidgeterMode && this.form.newsletter === true) data.notifications = { news: true, programs: true, promotions: true, display: true };
                return this.$store.dispatch('auth/register', data);
            },
            login() {
                return this.$store.dispatch('auth/authenticate', { username: this.form.email, password: this.form.password });
            },
            requestSuccess(data) {
                this.updateUsercom(data);
                this.logging = true;
                this.login()
                    .then(() => {
                        this.$router.replace({ name: 'Dashboard', query: { registration: 'success' } }).catch(() => {});
                        this.$toastr.success(this.$t('auth.pageRegister.successMessage'));
                    })
                    .catch(this.setError)
                    .finally(() => (this.logging = false));
            },
            updateUsercom(data) {
                if (!usercomService.isUserComAvailable()) return;
                const userData = usercomService.getUserDataFromUser(data);
                userData.bb_newsletter = Boolean(this.form.newsletter);
                usercomService.clientUpdate(userData);
            },
        },
        mounted() {
            // Check for query parameter and set recommenderId if present
            if (this.$route.query?.bb_pp) {
                this.form.recommenderId = this.$route.query.bb_pp;
            }

            // Get the 'bb_recommender_id' cookie value
            const recommenderId = this.$cookies.get('bb_recommender_id');

            // If the cookie exists, set the recommenderId
            if (recommenderId) {
                this.form.recommenderId = recommenderId;
            }
        },
    };
</script>
