import apiService from '@/services/apiService';
import errorService from '@/services/errorService';

export default {
    async fetchCategoryFormats(categoryId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/categories/${categoryId}/items`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};
