<template>
    <Card :header="$t('filters')">
        <b-form-group :label="$t('id')" label-cols-sm="3">
            <b-form-input v-model="filters.id" :disabled="loading" @keyup.enter="fetchUsers()" />
        </b-form-group>

        <b-form-group :label="$t('emailAddress')" label-cols-sm="3">
            <b-form-input v-model="filters.email" :disabled="loading" @keyup.enter="fetchUsers()" />
        </b-form-group>

        <b-form-group :label="$t('lastName')" label-cols-sm="3">
            <b-form-input v-model="filters.lastName" :disabled="loading" @keyup.enter="fetchUsers()" />
        </b-form-group>

        <b-form-group :label="$t('companyName')" label-cols-sm="3">
            <b-form-input v-model="filters.companyName" :disabled="loading" @keyup.enter="fetchUsers()" />
        </b-form-group>

        <b-form-group :label="$t('userRole')" label-cols-sm="3">
            <b-form-checkbox-group v-model="filters.roles" :options="roleOption" class="h-100 d-flex align-items-center" :disabled="loading" @keyup.enter="fetchUsers()" />
        </b-form-group>

        <ButtonIcon :disabled="loading" icon="filter" @click="fetchUsers()">
            {{ $t('filter') }}
        </ButtonIcon>
    </Card>
</template>



<script>
    import clone from 'lodash/clone';
    import Role from '@/Role';

    export default {
        name: 'Filters',
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                currentFilters: {},
                filters: {
                    id: null,
                    email: null,
                    lastName: null,
                    companyName: null,
                    roles: [],
                },
            };
        },
        computed: {
            filtersParams() {
                const filters = {};
                if (this.filters.id) filters.id = this.filters.id;
                if (this.filters.email) filters.email = this.filters.email;
                if (this.filters.lastName) filters.lastName = this.filters.lastName;
                if (this.filters.companyName) filters.companyName = this.filters.companyName;
                if (this.filters.roles?.length) filters.roles = this.filters.roles.join(',');
                return filters;
            },
            tableParams() {
                return {
                    sort: this.parentComponent.sortBy,
                    order: this.parentComponent.sortDirection.toUpperCase(),
                    page: this.parentComponent.currentPage,
                    perPage: this.parentComponent.perPage,
                };
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                    ...this.tableParams,
                };
            },
            loading() {
                return this.$store.getters['user/getLoading']('users');
            },
            roleOption() {
                return [
                    { text: this.$t('role.widgeter'), value: Role.WIDGETER },
                    { text: this.$t('role.advertiser'), value: Role.ADVERTISER },
                    { text: this.$t('role.producer'), value: Role.PRODUCER },
                ];
            },
            isFiltersChanged() {
                return JSON.stringify(this.filtersParams) !== JSON.stringify(this.currentFilters);
            },
        },
        watch: {
            tableParams: {
                deep: true,
                handler: 'fetchUsers',
            },
        },
        methods: {
            resetCurrentPage() {
                this.parentComponent.currentPage = 1;
            },
            clearUsers() {
                this.$store.commit('user/clearUsers');
            },
            fetchUsers(params = {}) {
                if (this.isFiltersChanged) this.resetCurrentPage();
                this.currentFilters = clone(this.filtersParams);
                this.$store.dispatch('user/fetchUsers', { requestParams: this.requestParams, ...params });
            },
        },
        mounted() {
            this.clearUsers();
            this.fetchUsers();
        },
    };
</script>
