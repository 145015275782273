<template>
    <div class="card">
        <div
            :class="{
                'card-body': true,
                'p-0': noPadding,
                'state-completed': state === 'completed',
                'state-disabled': state === 'disabled',
            }"
        >
            <h4 v-if="!(blankLoading && loading) && (header || $slots.header)" class="header-title mt-0 mb-1" :class="{'border-bottom p-3': headerBorder}">
                <div class="card-nav float-right">
                    <slot name="nav"></slot>
                </div>
                <!--
                @slot Alternative to `header` prop
                -->
                <slot name="header">
                    {{ header }}
                </slot>
            </h4>

            <p v-if="!(blankLoading && loading) && (subHeader || $slots.subHeader)" class="mt-2 sub-header">
                <!--
                @slot Alternative to `subHeader` prop
                -->
                <slot name="subHeader">
                    {{ subHeader }}
                </slot>
            </p>

            <div v-if="state !== 'disabled'" :class="{ 'mt-4': separateContent, 'content-loading': loading }" class="content">
                <div v-if="loading" class="d-flex justify-content-center my-4 loading">
                    <b-spinner />
                </div>
                <slot v-else class="p-0"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Card',
        props: {
            header: {
                type: String,
                required: false,
            },
            subHeader: {
                type: String,
                required: false,
            },
            noPadding: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            noSeparateContent: {
                type: Boolean,
                default: false,
            },
            headerBorder: {
                type: Boolean,
                default: false,
            },
            state: {
                type: String,
                default: 'default',
                validator(val) {
                    return ['default', 'disabled', 'completed'].includes(val);
                },
            },
            blankLoading: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            separateContent() {
                return !this.noSeparateContent && (this.header || this.subHeader);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .state-completed {
        border: 2px solid $special-color;
        border-radius: 5px;
    }

    .state-completed-header {
        color: $special-color;
    }

    .state-disabled {
        opacity: 0.7;
    }
</style>
