<template>
    <div class="badge" :class="statusClass">
        {{ statusName }}
    </div>
</template>



<script>
    export default {
        name: 'TipsStatusBadge',
        props: {
            status: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                default: null,
            },
            simpleLabel: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            statusName() {
                if (this.simpleLabel) {
                    if (['created', 'pending', 'waiting_for_confirmation', 'error', 'returned'].includes(this.status)) {
                        return this.$t('tipTransactionStatuses.pending');
                    }

                    if (['correct', 'payout_requested'].includes(this.status)) {
                        return this.$t('tipTransactionStatuses.correct');
                    }

                    if (this.status === 'paid_out') {
                        return this.$t('tipTransactionStatuses.paid');
                    }
                }

                return this.label || this.$tx(`tipTransactionStatuses.${this.status}`, this.status);
            },
            statusClass() {
                // Nieopłacone (Unpaid) - Red
                if (['created', 'pending', 'waiting_for_confirmation', 'error', 'returned'].includes(this.status)) {
                    return 'badge-soft-danger';
                }
                // Opłacona (Paid) - Blue
                if (['correct', 'payout_requested'].includes(this.status)) {
                    return 'badge-soft-info';
                }
                // Wypłacona (Paid out) - Green
                if (this.status === 'paid_out') {
                    return 'badge-soft-success';
                }
                // Default case if none of the above statuses match
                return 'badge-soft-dark';
            },
        },
    };
</script>
