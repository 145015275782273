<template>
    <div class="panel-container">
        <b-button variant="brand" class="close-btn" :class="{'active': active}" @click="toggleActive()">
            <feather type="arrow-left" size="20" />
        </b-button>
        <div class="panel shadow" :class="{'active': active}">
            <div class="panel-row">
                <div class="panel-icon">
                    <img src="@/assets/images/widget-configurator/bb-type-icon.png">
                </div>
                <div class="panel-form">
                    <FormValidator :validator="$v.value['bb-type']" form-group v-slot="slotProps" :label="$t('generator.widgetType')">
                        <b-form-select
                            :value="value['bb-type'] || 'widget'"
                            :options="formOptions['bb-type']"
                            :state="slotProps.state"
                            @change="value => emitValue('bb-type', value)"
                        />
                    </FormValidator>
                </div>
            </div>
            <div class="panel-row">
                <div class="panel-icon">
                    <img src="@/assets/images/widget-configurator/bb-lead-color-icon.png">
                </div>
                <div class="panel-form">
                    <FormValidator :validator="$v.value['bb-lead-color']" form-group v-slot="slotProps" :label="$t('generator.leadColor')">
                        <Saturation :width="250" :height="150" :color="hsv" @change="emitValue('bb-lead-color', hsv2hex(hsv))" @color="color => hsv = color" />
                        <Hue :width="250" :height="30" :color="hsv" @change="emitValue('bb-lead-color', hsv2hex(hsv))" @color="color => hsv = color" />
                        <div class="d-flex align-items-center mt-2">
                            <span class="mr-1">#</span>
                            <b-form-input class="width-100 px-2 py-1 h-auto" :value="hsv2hex(hsv).replace('#', '')" :state="slotProps.state" @change="color => { hsv = hex2hsv(`#${color}`); emitValue('bb-lead-color', hsv2hex(hsv)) }" />
                        </div>
                    </FormValidator>
                </div>
            </div>
            <div class="panel-row">
                <div class="panel-icon">
                    <img src="@/assets/images/widget-configurator/bb-button-label-icon.png">
                </div>
                <div class="panel-form">
                    <b-form-group :label="$t('generator.buttonLabel')">
                        <b-form-input :value="value['bb-button-label']" :placeholder="$t('optional')" @change="value => emitValue('bb-button-label', value.trim())" />
                    </b-form-group>
                </div>
            </div>
            <div class="panel-row">
                <div class="panel-icon">
                    <img src="@/assets/images/widget-configurator/bb-row-count-icon.png">
                </div>
                <div class="panel-form">
                    <FormValidator :validator="$v.value['bb-row-count']" form-group v-slot="slotProps" :label="$t('generator.rowCount')">
                        <b-form-input
                            :value="value['bb-row-count']"
                            :placeholder="$t('optional')"
                            :state="slotProps.state"
                            @change="value => emitValue('bb-row-count', !isNaN(value) ? (Number.parseInt(value) || 10) : 10)"
                        />
                    </FormValidator>
                </div>
            </div>
            <div class="panel-row">
                <div class="panel-icon">
                    <img src="@/assets/images/widget-configurator/bb-show-extra-icon.png">
                </div>
                <div class="panel-form">
                    <b-form-group>
                        <b-form-checkbox :checked="value['bb-show-extra']" :value="true" :unchecked-value="false" switch @change="value => emitValue('bb-show-extra', value)">
                            {{ $t('generator.showProduct') }}
                        </b-form-checkbox>
                    </b-form-group>
                </div>
            </div>
            <div class="panel-row">
                <div class="panel-icon">
                    <img src="@/assets/images/widget-configurator/bb-show-prices-icon.png">
                </div>
                <div class="panel-form">
                    <b-form-group>
                        <b-form-checkbox :checked="value['bb-show-prices']" :value="true" :unchecked-value="false" switch @change="value => emitValue('bb-show-prices', value)">
                            {{ $t('generator.showPrices') }}
                        </b-form-checkbox>
                    </b-form-group>
                </div>
            </div>
            <!-- <div class="panel-row">
                <div class="panel-icon"></div>
                <div class="panel-form">
                    <b-form-group>
                        <ButtonIcon variant="primary" @click="$emit('changeProduct')">
                            {{ $t('changeProduct') }}
                        </ButtonIcon>
                    </b-form-group>
                </div>
            </div> -->
        </div>
    </div>
</template>



<script>
    import { required, integer } from 'vuelidate/lib/validators';
    import { hsv2hex, hex2hsv } from '@/utils/color';
    import validationService from '@/services/validationService';
    import FormValidator from '@/components/common/FormValidator.vue';
    import Hue from '@/components/common/Hue.vue';
    import Saturation from '@/components/common/Saturation.vue';

    export default {
        name: 'WidgetConfigurator',
        components: {
            FormValidator,
            Hue,
            Saturation,
        },
        props: {
            value: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                formOptions: {
                    'bb-type': [
                        { value: 'widget', text: this.$t('widgetType.widget') },
                        { value: 'popover', text: this.$t('widgetType.popover') },
                        { value: 'overlay', text: this.$t('widgetType.overlay') },
                        { value: 'logos-only', text: this.$t('widgetType.logosOnly') },
                    ],
                },
                hsv: hex2hsv(this.value['bb-lead-color']),
                active: false,
            };
        },
        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    if (newValue['bb-lead-color'] !== hsv2hex(this.hsv)) {
                        this.hsv = hex2hsv(newValue['bb-lead-color']);
                    }
                },
            },
        },
        validations() {
            return {
                value: {
                    'bb-type': { required: value => this.formOptions['bb-type'].map(item => item.value).includes(value) },
                    'bb-lead-color': { hexColor: validationService.hexColor },
                    'bb-row-count': { required, integer },
                },
            };
        },
        methods: {
            hsv2hex,
            hex2hsv,
            emitValue(name, value) {
                this.$emit('input', { ...this.value, [name]: value });
                this.$emit('change');
            },
            toggleActive(isActive) {
                this.active = isActive ?? !this.active;
            },
        },
    };
</script>


<style lang="scss" scoped>
    .panel-container {
        position: relative;
        display: flex;

        .close-btn {
            width: 38px;
            height: 38px;
            flex-grow: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            line-height: 1;
            padding: 0.5rem;
            position: absolute;
            z-index: 10;
            left: 0;
            top: 10px;
            transform: translateX(-50%);
            transition: all 0.15s ease;

            &.active {
                transform: translateX(-50%) rotateZ(180deg);
            }
        }
    }

    .panel {
        width: 70px;
        overflow: hidden;
        transition: all 0.15s ease;
        background: rgba(#f3f1f1, 80);
        padding: 20px 20px;
        border-radius: 10px;
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .panel-row {
            display: flex;

            .panel-icon {
                width: 70px;
                flex-grow: 0;
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                align-items: flex-start;
            }

            .panel-form {
                width: 250px;
                height: 0;
                overflow: hidden;
                opacity: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-shrink: 0;
                align-items: flex-start;
                padding-left: 20px;
                transition: all 0.15s ease;

                .form-group {
                    margin-bottom: 0;
                    width: 100%;
                }
            }

            .panel-form {
                flex-grow: 1;
            }
        }

        &.active {
            width: 340px;

            .panel-row {
                .panel-form {
                    opacity: 1;
                    height: auto;
                }
            }
        }
    }
</style>
