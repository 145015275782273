import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    CAMPAIGNS_API_TOKEN: {
        URI: () => '/api/v1/campaigns/{{campaignId}}/api-token',
        methods: ['get', 'put', 'delete'],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
