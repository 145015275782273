<template>
    <div id="wrapper">
        <AppTopbar />
        <AppSidebar :items="sidebarItems" />

        <div class="content-page">
            <div class="content">
                <div class="container-fluid d-flex flex-column flex-grow-1">
                    <AccessDenied v-if="accessDenied" />
                    <transition v-else name="fade" mode="out-in">
                        <router-view />
                    </transition>
                </div>
            </div>
            <AppFooter class="footer" />
        </div>
    </div>
</template>



<script>
    import AppTopbar from '@/components/AppTopbar.vue';
    import AppSidebar from '@/components/AppSidebar.vue';
    import AppFooter from '@/components/AppFooter.vue';
    import AccessDenied from '@/views/common/access-denied/index.vue';
    import sidebar from '@/navs/sidebar';

    export default {
        name: 'LayoutPanel',
        components: {
            AppTopbar,
            AppSidebar,
            AppFooter,
            AccessDenied,
        },
        computed: {
            sidebarItems() {
                return sidebar();
            },
            accessDenied() {
                return this.$route.meta.accessDenied;
            },
        },
    };
</script>



<style lang="scss" scoped>
    @import 'bootstrap/scss/functions';
    @import 'bootstrap/scss/variables';
    @import '@/design/_variables.scss';

    .content-page {
        min-height: calc(100vh - #{$topbar-height});
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .content {
            flex-grow: 100;
            display: flex;
            flex-direction: column;
        }

        .footer {
            flex-grow: 0;
            position: relative;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
        }
    }
</style>
