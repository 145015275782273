/* eslint-disable no-param-reassign */

import ColorConverter from 'color-convert';

export const rgb2hex = ({ r, g, b }, toUpper) => {
    const color = ColorConverter.rgb.hex(r, g, b);
    return `#${toUpper ? color.toUpperCase() : color.toLowerCase()}`;
};

export const hsv2hex = ({ h, s, v }, toUpper) => {
    const color = ColorConverter.hsv.hex(h, s, v);
    return `#${toUpper ? color.toUpperCase() : color.toLowerCase()}`;
};

export const hex2rgb = hex => {
    const color = ColorConverter.hex.rgb(hex || '#000000');
    return { r: color[0], g: color[1], b: color[2] };
};

export const hsv2rgb = ({ h, s, v }) => {
    const color = ColorConverter.hsv.rgb(h, s, v);
    return { r: color[0], g: color[1], b: color[2] };
};

export const hex2hsv = hex => {
    const color = ColorConverter.hex.hsv(hex || '#000000');
    return { h: color[0], s: color[1], v: color[2] };
};

export const rgb2hsv = ({ r, g, b }) => {
    const color = ColorConverter.rgb.hsv(r, g, b);
    return { h: color[0], s: color[1], v: color[2] };
};

export const hsl2hsv = ({ h, s, l }) => {
    const color = ColorConverter.hsl.hsv(h, s, l);
    return { h: color[0], s: color[1], v: color[2] };
};

export const getColorAsHSV = color => {
    let hsv = { h: 0, s: 0, v: 0 };
    if (typeof color === 'string' && color.startsWith('#')) {
        hsv = hex2hsv(color);
    } else if (typeof color === 'object' && color.r !== undefined && color.g !== undefined && color.b !== undefined) {
        hsv = rgb2hsv(color);
    } else if (typeof color === 'object' && color.h !== undefined && color.s !== undefined && color.l !== undefined) {
        hsv = hsl2hsv(color);
    } else if (typeof color === 'object' && color.h !== undefined && color.s !== undefined && color.v !== undefined) {
        hsv = color;
    }
    return hsv;
};
