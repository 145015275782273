<template>
    <div>
        <PageHeader>
            {{ $t('widgeterReportsShops.title') }}
            <template #side>
                <div class="d-flex">
                    <MultiSelectField
                        v-model="pages"
                        :lazy="true"
                        variant="select"
                        class="width-250 mb-0"
                        :options="pagesMultiselectFormat"
                        :placeholder="$t('yourPages')"
                        :loading="isSpacesLoading"
                        :disabled="isCampaignsLoading"
                        :show-checkboxes="true"
                    >
                        <template slot="selection" slot-scope="{ values, isOpen }">
                            <span v-if="values.length && !isOpen" class="multiselect__single">{{ $t('yourPages') }} ({{ values.length }})</span>
                        </template>
                    </MultiSelectField>

                    <div class="mx-2"></div>

                    <MultiSelectField
                        v-model="shops"
                        :lazy="true"
                        variant="select"
                        class="width-250 mb-0"
                        :options="shopsMultiselectFormat"
                        :placeholder="$t('shops')"
                        :loading="isCampaignsLoading"
                        :show-checkboxes="true"
                    >
                        <template slot="selection" slot-scope="{ values, isOpen }">
                            <span v-if="values.length && !isOpen" class="multiselect__single">{{ $t('shops') }} ({{ values.length }})</span>
                        </template>
                    </MultiSelectField>

                    <div class="mx-2"></div>

                    <TimeRangeDropdown v-model="timeRange" :disabled="isCampaignsLoading" />
                </div>
            </template>
        </PageHeader>

        <div class="row">
            <div class="col mb-4 overflow-hidden width-min-700 flex-grow-10">
                <MultiChartCard
                    :data="lineChartData"
                    :height="400"
                    :loading="isMultiChartCardLoading"
                    :options="lineChartOptions"
                    @currentChart="setCurrentChart"
                />
            </div>
            <div class="col mb-4 overflow-hidden width-400 width-min-400 flex-grow-1">
                <PieChart :chart-data="pieChartData[currentChart].series" :loading="isPieChartLoading" />
            </div>
        </div>

        <div class="row">
            <div class="col-xl-12 mb-4">
                <CampaignList :space-ids="pages || []" :campaign-ids="shops || []" :time-range="timeRange || {}" />
            </div>
        </div>
    </div>
</template>



<script>
    import { cloneDeep } from 'lodash';
    import { generateChartData, chartDataGroupBy } from '@/utils/chart';
    import { i18n } from '@/i18n/i18n';
    import PageHeader from '@/components/common/PageHeader.vue';
    import TimeRangeDropdown from '@/components/common/TimeRangeDropdown.vue';
    import MultiSelectField from '@/components/common/MultiSelectField.vue';
    import MultiChartCard from '@/components/common/MultiChartCard.vue';
    import PieChart from './parts/PieChart.vue';
    import CampaignList from './parts/CampaignList.vue';

    const DEFAULT_LINE_CHART_DATA = {
        offers_impressions: {
            series: [],
            name: i18n.t('widgeterReportsShops.impressions'),
        },
        clicks: {
            series: [],
            name: i18n.t('widgeterReportsShops.clicks'),
        },
        orders: {
            series: [],
            name: i18n.t('widgeterReportsShops.transactions'),
        },
        amount: {
            series: [],
            name: i18n.t('widgeterReportsShops.transactionAmount'),
        },
        publisher_commission: {
            series: [],
            name: i18n.t('widgeterReportsShops.commission'),
        },
    };

    const DEFAULT_PIE_CHART_DATA = cloneDeep(DEFAULT_LINE_CHART_DATA);

    export default {
        name: 'WidgeterReportsShops',
        components: {
            MultiSelectField,
            PageHeader,
            TimeRangeDropdown,
            MultiChartCard,
            PieChart,
            CampaignList,
        },
        data() {
            return {
                currentChart: 'offers_impressions',
                lineChartData: cloneDeep(DEFAULT_LINE_CHART_DATA),
                pieChartData: cloneDeep(DEFAULT_PIE_CHART_DATA),
                timeRange: null,
                pages: [],
                shops: [],
                isInit: false,
            };
        },
        computed: {
            isSpacesLoading() {
                return this.$store.state.space.loading.fetchSpaces;
            },
            isCampaignsLoading() {
                return this.isSpacesLoading || this.$store.getters['campaigns/getLoading']('campaigns');
            },
            isMultiChartCardLoading() {
                return this.isCampaignsLoading || this.$store.getters['userStats/getLoading']('campaignReport');
            },
            isPieChartLoading() {
                return this.isCampaignsLoading || this.$store.getters['userStats/getLoading']('campaignReport');
            },
            chartDataGroupBy() {
                return chartDataGroupBy(!this.timeRange);
            },
            lineChartOptions() {
                if (this.chartDataGroupBy === 'hour') {
                    return {
                        xaxis: {
                            labels: {
                                format: 'HH:mm',
                            },
                        },
                    };
                }
                return {};
            },
            chartDataFeature() {
                return this.currentChart;
            },
            campaignReport() {
                return this.$store.getters['userStats/getCampaignReport'];
            },
            spaces() {
                return this.$store.state.space.spaces;
            },
            campaigns() {
                return this.$store.getters['campaigns/getCampaigns']?.items || [];
            },
            currentChartDataExist() {
                return this.lineChartData[this.currentChart]?.series?.length && this.pieChartData[this.currentChart]?.series?.length;
            },
            categories() {
                return Array.from(
                    new Set(
                        Object.values(this.spaces)
                            .filter(item => this.pages.includes(item.id))
                            .map(item => item.categories.map(({ id }) => id))
                            .flat()
                    )
                );
            },
            pagesMultiselectFormat() {
                return Object.values(this.spaces).map(({ id, name }) => ({ value: id, text: name }));
            },
            shopsMultiselectFormat() {
                return this.campaigns.map(({ id, name }) => ({ value: id, text: name }));
            },
        },
        watch: {
            timeRange(newValue, oldValue) {
                if (oldValue !== null) {
                    this.clearChartData();
                    this.fetchCampaignReport();
                }
            },
            currentChart() {
                if (!this.currentChartDataExist) {
                    this.fetchCampaignReport();
                }
            },
            campaigns() {
                this.updateSelectedShopsIfPagesWasChanged();
            },
            pages() {
                if (this.isInit) this.fetchCampaigns();
            },
            shops() {
                this.clearChartData();
                this.fetchCampaignReport();
            },
            campaignReport() {
                this.initChartData();
            },
        },
        methods: {
            async init() {
                await this.fetchSpaces();
                this.selectAllPages();
                await this.fetchCampaigns();
                this.selectAllShops();
                this.isInit = true;
            },
            async fetchCampaignReport() {
                const requestParams = {
                    startDate: this.timeRange.startDate,
                    endDate: this.timeRange.endDate,
                    feature: this.chartDataFeature,
                    groupBy: this.chartDataGroupBy,
                    spaces: this.pages.join(','),
                    campaigns: this.shops.join(','),
                    showFirst: 9,
                };
                return this.$store.dispatch('userStats/fetchCampaignReport', { requestParams });
            },
            async fetchSpaces() {
                const requestParams = {
                    sort: 'name',
                    order: 'ASC',
                };
                return this.$store.dispatch('space/fetchSpaces', { requestParams });
            },
            async fetchCampaigns() {
                const requestParams = {
                    sort: 'name',
                    order: 'ASC',
                    active: true,
                    allowedFor: this.pages.join(','),
                };
                return this.$store.dispatch('campaigns/fetchCampaigns', { requestParams });
            },
            selectAllPages() {
                this.pages = Object.values(this.spaces).map(({ id }) => parseInt(id));
            },
            selectAllShops() {
                this.shops = this.campaigns.map(({ id }) => parseInt(id));
            },
            clearChartData() {
                this.lineChartData = cloneDeep(DEFAULT_LINE_CHART_DATA);
                this.pieChartData = cloneDeep(DEFAULT_PIE_CHART_DATA);
            },
            initChartData() {
                this.initPieChartData();
                this.initLineChartData();
            },
            initLineChartData() {
                const chartData = {};
                this.campaignReport?.chart?.forEach(item => {
                    const names = Object.keys(item);
                    const dateIndex = names.findIndex(name => name === 'date');
                    delete names[dateIndex];
                    names.forEach(name => {
                        if (!chartData[name]) chartData[name] = [];
                        chartData[name].push({ date: item.date, value: item[name] });
                    });
                });
                const newChartData = Object.keys(chartData).map(name => ({
                    name,
                    data: generateChartData(this.timeRange, chartData[name], this.chartDataGroupBy).map(item => [item.date, item.value]),
                }));
                this.$set(this.lineChartData[this.currentChart], 'series', newChartData);
            },
            initPieChartData() {
                const areAnyData = !!this.campaignReport?.pie_chart?.some(item => item[1]);
                const newChartData = areAnyData ? this.campaignReport.pie_chart.map(item => ({ name: item[0], series: item[1] })) : [];
                this.$set(this.pieChartData[this.currentChart], 'series', newChartData);
            },
            setCurrentChart(currentChart) {
                this.currentChart = currentChart;
            },
            setTimeRange(timeRange) {
                this.timeRange = timeRange;
            },
            updateSelectedShopsIfPagesWasChanged() {
                if (this.shops.length) {
                    const campaignIds = this.campaigns.map(({ id }) => id);
                    const isOldId = this.shops.some(id => !campaignIds.includes(id));
                    if (isOldId) this.shops = this.shops.filter(id => campaignIds.includes(id));
                }
            },
        },
        created() {
            this.init();
        },
    };
</script>
