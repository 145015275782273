<template>
    <div class="saturation" @mousedown.prevent.stop="selectSaturation">
        <canvas ref="canvasSaturation"></canvas>
        <div :style="slideSaturationStyle" class="slide"></div>
    </div>
</template>

<script>
    import { rgb2hsv, hsv2hex, getColorAsHSV } from '@/utils/color';

    export default {
        name: 'Saturation',
        props: {
            color: {
                type: [String, Object],
                default: null,
            },
            width: {
                type: Number,
                default: 150,
            },
            height: {
                type: Number,
                default: 100,
            },
        },
        data() {
            return {
                slideSaturationStyle: {},
                hsv: {},
            };
        },
        watch: {
            color: {
                immediate: true,
                handler(newValue, oldValue) {
                    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                        this.hsv = getColorAsHSV(this.color);
                        this.renderColor();
                        this.setSlideStyle();
                        this.$emit('color', this.hsv);
                    }
                },
            },
        },
        methods: {
            createLinearGradient(direction, ctx, width, height, color1, color2) {
                // l landscape | p portrait
                const isL = direction === 'l';
                const gradient = ctx.createLinearGradient(0, 0, isL ? width : 0, isL ? 0 : height);
                gradient.addColorStop(0.01, color1);
                gradient.addColorStop(0.99, color2);
                ctx.fillStyle = gradient; // eslint-disable-line no-param-reassign
                ctx.fillRect(0, 0, width, height);
            },
            renderColor() {
                if (!this.$refs.canvasSaturation) return;
                const canvas = this.$refs.canvasSaturation;
                const ctx = canvas.getContext('2d');
                canvas.width = this.width;
                canvas.height = this.height;

                ctx.fillStyle = hsv2hex({ h: this.hsv.h, s: 100, v: 100 });
                ctx.fillRect(0, 0, this.width, this.height);

                this.createLinearGradient('l', ctx, this.width, this.height, '#FFFFFF', 'rgba(255,255,255,0)');
                this.createLinearGradient('p', ctx, this.width, this.height, 'rgba(0,0,0,0)', '#000000');
            },
            setSlideStyle() {
                this.slideSaturationStyle = {
                    left: `${(this.hsv.s / 100) * this.width - 5}px`,
                    top: `${(1 - this.hsv.v / 100) * this.height - 5}px`,
                };
            },
            selectSaturation(event) {
                const { top: saturationTop, left: saturationLeft } = this.$el.getBoundingClientRect();
                const ctx = event.target.getContext('2d');
                let selectedColor = {};

                const mousemove = e => {
                    let x = e.clientX - saturationLeft;
                    let y = e.clientY - saturationTop;

                    if (x < 0) {
                        x = 0;
                    }
                    if (y < 0) {
                        y = 0;
                    }
                    if (x > this.width) {
                        x = this.width;
                    }
                    if (y > this.height) {
                        y = this.height;
                    }

                    // Do not modify the dom by monitoring data changes, otherwise when the color is #ffffff, the slide will go to the lower left corner
                    this.slideSaturationStyle = {
                        left: `${x - 5}px`,
                        top: `${y - 5}px`,
                    };
                    // If the maximum value is used, the selected pixel will be empty, and the empty default is black
                    const imgData = ctx.getImageData(Math.min(x, this.width - 1), Math.min(y, this.height - 1), 1, 1);
                    const [r, g, b] = imgData.data;
                    const { s, v } = rgb2hsv({ r, g, b });
                    selectedColor = { h: this.hsv.h, s, v };
                    this.$emit('color', selectedColor);
                };

                mousemove(event);

                const mouseup = () => {
                    this.$emit('change', selectedColor);
                    document.removeEventListener('mousemove', mousemove);
                    document.removeEventListener('mouseup', mouseup);
                };

                document.addEventListener('mousemove', mousemove);
                document.addEventListener('mouseup', mouseup);
            },
        },
        mounted() {
            this.renderColor();
            this.setSlideStyle();
        },
    };
</script>

<style lang="scss" scoped>
    .saturation {
        position: relative;
        cursor: pointer;

        .slide {
            position: absolute;
            left: 100px;
            top: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 1px solid #fff;
            box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
            pointer-events: none;
        }
    }
</style>
