<template>
    <b-button
        ref="button"
        v-bind="$attrs"
        :style="buttonStyle"
        :variant="variant"
        :disabled="$attrs.disabled || loading"
        :class="{'button-with-icon': icon}"
        v-on="$listeners"
    >
        <span style="width:1px;">&nbsp;</span> <!-- is needed to preserve line height -->
        <slot name="spinner">
            <b-spinner
                v-if="loading"
                small
                class="button-spinner"
                :class="spinnerClass"
                :style="spinnerStyle"
            />
        </slot>
        <slot v-if="!loading" name="icon">
            <feather
                v-if="icon"
                :type="icon"
                :size="iconSize"
                class="button-icon"
                :class="{[iconClass]: true}"
                :style="$slots.default ? (flipIcon ? 'margin-left: 0.5rem;' : 'margin-right: 0.5rem;') : ''"
                :animation="iconAnimation"
            />
        </slot>
        <slot name="label">
            <span class="button-label" :class="labelClass" :style="labelStyle"><slot></slot></span>
        </slot>
        <span style="width:1px;">&nbsp;</span> <!-- is needed to preserve line height -->
    </b-button>
</template>



<script>
    export default {
        name: 'ButtonIcon',
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            variant: {
                type: String,
                default: 'primary',
            },
            icon: {
                type: String,
                default: null,
            },
            iconSize: {
                type: String,
                default: '18',
            },
            iconClass: {
                type: String,
                default: '',
            },
            spinnerClass: {
                type: String,
                default: '',
            },
            labelClass: {
                type: String,
                default: '',
            },
            hideLabelWhileLoading: {
                type: Boolean,
                default: false,
            },
            flipIcon: {
                type: Boolean,
                default: false,
            },
            iconAnimation: {
                type: String,
                default: undefined,
            },
        },
        computed: {
            hideLabel() {
                return this.hideLabelWhileLoading && this.loading;
            },
            spinnerStyle() {
                return {
                    width: `${this.iconSize}px`,
                    height: `${this.iconSize}px`,
                    marginRight: !this.flipIcon && this.$slots.default && !this.hideLabel ? '0.5rem' : '',
                    marginLeft: this.flipIcon && this.$slots.default && !this.hideLabel ? '0.5rem' : '',
                };
            },
            labelStyle() {
                return {
                    display: this.hideLabel ? 'none' : '',
                    order: this.flipIcon ? '-1' : '',
                };
            },
            buttonStyle() {
                if (this.hideLabel && this.$refs.button) {
                    return { minWidth: `${this.$refs.button.offsetWidth}px` };
                }
                return {};
            },
        },
    };
</script>



<style lang="scss" scoped>
    .btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        flex-wrap: nowrap;

        .button-icon {
            flex-shrink: 0;
        }

        // .button-spinner {
        //     color: $white;
        // }

        // &.disabled {
        //     color: $white;
        // }

        &.button-with-icon {
            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }
        }
    }
</style>
