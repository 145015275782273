<template>
    <div>

        <b-overlay :show="isLoading && !!items.length" rounded="sm" class="h-100">

            <router-view name="feed" />

        </b-overlay>

    </div>
</template>



<script>
    export default {
        name: 'Feeds',
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {};
        },
        computed: {
            campaignId() {
                return this.parentComponent?.item?.id;
            },
            items() {
                return this.$store.getters['campaigns/getFeeds'];
            },
            error() {
                return this.$store.getters['campaigns/getError']('feeds');
            },
            isLoading() {
                return this.$store.getters['campaigns/getLoading']('feeds');
            },
        },
        watch: {
            items() {
                this.$set(this.parentComponent.item, 'feeds', this.items);
            },
            $route: {
                immediate: true,
                handler(to, from) {
                    if (to.name === 'CampaignsDetailsTabIndex' || !this.parentComponent.item.feeds) {
                        this.fetchData();
                    }
                },
            },
        },
        methods: {
            clearData() {
                this.$store.commit('campaigns/clearFeeds');
            },
            fetchData() {
                this.$store.dispatch('campaigns/fetchFeeds', { urlParams: { campaignId: this.campaignId } }).catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
        },
    };
</script>
