<template>
    <div>
        <div class="table-responsive" :style="{ maxHeight }">
            <table class="table table-hover table-default mb-0">
                <thead v-if="showHeaders">
                    <tr>
                        <th v-if="collapsible"></th>
                        <th v-if="selectable"></th>
                        <th
                            v-for="(header, columnIndex) in headers"
                            :key="columnIndex"
                            scope="col"
                            class="header-label"
                            :style="{
                                textAlign: getColumnAlign(header, columnIndex)
                            }"
                            v-html="header.label"
                        ></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="loading">
                        <td :colspan="headersCount">
                            <div class="d-flex justify-content-center">
                                <b-spinner />
                            </div>
                        </td>
                    </tr>
                    <tr v-else-if="!hasItems">
                        <td :colspan="headersCount">
                            <div class="d-flex justify-content-center">
                                {{ $t('noResult') }}
                            </div>
                        </td>
                    </tr>
                    <template v-for="(row, rowIndex) in items" v-else>
                        <tr
                            :key="row.id"
                            :class="{
                                'row-clickable': hasClickableRows,
                            }"
                            @click="onRowClick(row)"
                        >
                            <td
                                v-if="collapsible"
                                class="clickable-cell"
                                :class="{
                                    'clickable': hasRowClickListener
                                }"
                                @click.self="onCollapseCellClick(rowIndex)"
                            >
                            </td><td v-if="collapsible" class="clickable" @click.self="onCollapseCellClick(rowIndex)">
                                <feather
                                    :class="{
                                        'collapse-arrow': true,
                                        'collapse-arrow--rotated': isRowCollapsed(rowIndex),
                                    }"
                                    size="14"
                                    type="chevron-right"
                                />
                                <input
                                    ref="collapseCheckbox"
                                    v-model="collapsedRows"
                                    type="checkbox"
                                    :value="rowIndex"
                                    class="hidden-checkbox"
                                    @click.stop
                                >
                            </td>
                            <td v-if="selectable" class="clickable" @click.self="onSelectCellClick(rowIndex)">
                                <input
                                    ref="selectCheckbox"
                                    v-model="model"
                                    type="checkbox"
                                    :value="row"
                                    @click.stop
                                >
                            </td>
                            <td
                                v-for="(header, columnIndex) in headers"
                                :key="columnIndex"
                                :class="{
                                    'clickable': hasRowClickListener
                                }"
                                :style="{
                                    textAlign: getColumnAlign(header, columnIndex),
                                    ...header.style
                                }"
                            >
                                <!--
                            @slot Dynamic value slot.
                            -->

                                <slot :name="header.slot" :item="row">
                                    <component
                                        :is="header.ellipsis ? 'ellipsis' : 'span'"
                                        v-bind="header.ellipsis"
                                    >
                                        {{ header.value && header.value(row, rowIndex) }}
                                    </component>
                                </slot>
                            </td>
                        </tr>
                        <tr v-if="isRowCollapsed(rowIndex)" :key="`${row.id}-content`" class="row--collapsed">
                            <slide-y-down-transition>
                                <td :colspan="headersCount">
                                    <slot name="collapsed" :item="row"></slot>
                                </td>
                            </slide-y-down-transition>
                        </tr>
                    </template>
                    <tr v-if="hasItems && hasAppendedRow">
                        <td
                            v-for="(header, columnIndex) in headers"
                            :key="columnIndex"
                            :style="{
                                textAlign: getColumnAlign(header, columnIndex),
                                ...header.appendedRow ? header.appendedRow.style : {}
                            }"
                        >
                            <slot v-if="header.appendedRow" :name="header.appendedRow.slot">
                                {{ header.appendedRow.value && header.appendedRow.value() }}
                            </slot>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination
            v-if="showPagination"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
        />
        <b-modal
            v-if="hasDetailsModal"
            v-model="modalShown"
            size="xl"
            :title="detailsModalTitle"
            title-class="font-18"
            hide-footer
        >
            <slot v-if="activeRow" name="detailsModal" :item="activeRow"></slot>
        </b-modal>
    </div>
</template>

<script>
    import { SlideYDownTransition } from 'vue2-transitions';
    import exportService from '@/services/exportService';
    import Pagination from './Pagination.vue';
    import Ellipsis from './Ellipsis.vue';

    export default {
        name: 'TableDefault',
        components: {
            Pagination,
            SlideYDownTransition,
            Ellipsis,
        },
        props: {
            /**
             * @model
             */
            value: {
                type: Array,
            },
            items: {
                type: Array,
            },
            /**
             * Array of headers:
             * {
             *     label: String,
             *     value: Function,
             *     slot: String,
             *     appendedRow: {}
             * }
             */
            headers: {
                type: Array,
            },
            exportHeaders: {
                type: Array,
            },
            hideHeaders: {
                type: Boolean,
                default: false,
            },
            selectable: {
                type: Boolean,
                default: false,
            },
            showPagination: {
                type: Boolean,
                default: false,
            },
            page: {
                type: Number,
            },
            perPage: {
                type: [Number, String],
            },
            totalRows: {
                type: [Number, String],
            },
            detailsModalTitle: {
                type: String,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            maxHeight: {
                type: String,
            },
        },
        data() {
            return {
                collapsedRows: [],
                activeRow: undefined,
                modalShown: false,
            };
        },
        computed: {
            model: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                },
            },
            currentPage: {
                get() {
                    return this.page;
                },
                set(value) {
                    this.$emit('pageChange', value);
                },
            },
            hasAppendedRow() {
                return this.headers.some(header => header.appendedRow);
            },
            hasItems() {
                return Boolean(this.items && this.items.length);
            },
            hasRowClickListener() {
                return this.$listeners && this.$listeners.rowClick;
            },
            showHeaders() {
                return !this.hideHeaders && this.headers.some(header => header.label);
            },
            collapsible() {
                return Boolean(this.$scopedSlots.collapsed);
            },
            hasDetailsModal() {
                return Boolean(this.$scopedSlots.detailsModal);
            },
            headersCount() {
                return this.headers.length + (this.selectable ? 1 : 0) + (this.collapsible ? 1 : 0);
            },
            hasClickableRows() {
                return this.hasDetailsModal;
            },
        },
        methods: {
            onRowClick(row) {
                if (this.hasDetailsModal) {
                    this.modalShown = !this.modalShown;
                    if (this.modalShown) {
                        this.activeRow = row;
                    } else {
                        this.activeRow = undefined;
                    }
                }
                if (this.hasRowClickListener) {
                    this.$listeners.rowClick(row);
                }
            },
            onCollapseCellClick(rowIndex) {
                this.$refs.collapseCheckbox[rowIndex].click();
            },
            onSelectCellClick(rowIndex) {
                this.$refs.selectCheckbox[rowIndex].click();
            },
            isRowCollapsed(rowIndex) {
                return this.collapsedRows.includes(rowIndex);
            },
            getColumnAlign(header, columnIndex) {
                if (header.align) {
                    return header.align;
                }

                return columnIndex > 0 ? 'right' : 'left';
            },
            exportData(data = this.items) {
                const parse = value => `"${exportService.parseText(value)}"`;

                const headerRow = this.exportHeaders.reduce((headerCells, currentHeader) => {
                    const headerCell = currentHeader.label;
                    return [...headerCells, parse(headerCell)];
                }, []);

                const dataRows = data.reduce((rows, currentRow) => {
                    const row = this.exportHeaders.reduce((rowsCells, currentHeader) => {
                        const cell = currentHeader.value(currentRow);
                        return [...rowsCells, parse(cell)];
                    }, []);

                    return [...rows, row];
                }, []);

                exportService.exportDataCsv([headerRow, ...dataRows]);
            },
        },
    };
</script>

<style scoped lang="scss">
    .table-default {
        background-color: white;
    }

    .header-label {
        white-space: nowrap;
    }

    .column-right {
        text-align: right;
    }

    .clickable,
    .clickable * {
        cursor: pointer;
    }

    .hidden-checkbox {
        display: none;
    }

    .collapse-arrow {
        pointer-events: none;
        transition: transform 0.15s;
    }

    .collapse-arrow--rotated {
        transform: rotate(90deg);
    }

    .row-clickable {
        cursor: pointer;
    }

    .row--collapsed {
        &,
        &:hover {
            background-color: white !important;
        }
    }

    .table-hover tr:hover ::v-deep .progress {
        background: #ffffff;
    }

    table td {
        vertical-align: middle;
    }
</style>
