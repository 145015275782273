<template>
    <div>
        <PageHeader>
            {{ $t('yourPages') }} ({{ spaceCount }})
            <template #side>
                <div class="d-flex">
                    <ButtonIcon :disabled="loading" icon="plus" @click="addItem()">
                        {{ $t('pages.create') }}
                    </ButtonIcon>
                </div>
            </template>
        </PageHeader>

        <div class="row">
            <div class="col-12">
                <div class="mb-4">
                    <p class="mb-1">
                        {{ $t('widgeterPagesSubheaderText') }}
                    </p>
                    <b-button-group>
                        <b-button
                            href="https://getbuybox.com/dodawanie-stron-i-profili-social-media/"
                            target="_blank"
                            variant="link"
                            class="d-flex align-items-center p-0 mr-4"
                        >
                            <feather type="file-text" class="mr-2" /> {{ $t('takeALookAtHelp') }}
                        </b-button>
                        <b-button
                            href="https://getbuybox.com/dodanie-pierwszej-strony/"
                            target="_blank"
                            variant="link"
                            class="d-flex align-items-center p-0 mr-4"
                        >
                            <feather type="youtube" class="mr-2" /> {{ $t('watchVideo') }}
                        </b-button>
                    </b-button-group>
                </div>
            </div>
        </div>

        <Card>
            <ErrorMessage :value="error" />
            <b-table
                hover
                :items="items"
                :fields="fields"
                show-empty
                :empty-text="$t('noResult')"
                :busy="loading"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            >
                <template #table-busy>
                    <div class="d-flex justify-content-center my-2">
                        <b-spinner />
                    </div>
                </template>
                <template #cell(url)="data">
                    <b-link :href="data.value" target="_blank">
                        {{ data.value }}
                    </b-link>
                </template>
                <template #cell(categories)="data">
                    <ul class="m-0 p-0 d-inline-block">
                        <li v-for="category in data.value" :key="category.id" class="ml-4 nowrap" style="display: inline flow-root list-item;">
                            {{ category.name }}
                        </li>
                    </ul>
                </template>
                <template #cell(type)="data">
                    {{ translateSpaceTypes(data.value) }}
                </template>
                <template #cell(status)="data">
                    <CampaignSpaceStatusBadge :status="data.value" />
                </template>
                <template #cell(action)="data">
                    <ButtonIcon class="width-50" :disabled="loading" icon="edit-2" @click="editItem(data.item)" />
                </template>
            </b-table>
            <Pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" />
        </Card>
        <ModalEditItem />
    </div>
</template>



<script>
    import Pagination from '@/components/common/Pagination.vue';
    import CampaignSpaceStatusBadge from '@/components/common/CampaignSpaceStatusBadge.vue';
    import { CampaignStatus } from '@/services/spaceService';
    import ModalEditItem from './parts/ModalEditItem.vue';

    export default {
        name: 'Pages',
        components: {
            Pagination,
            CampaignSpaceStatusBadge,
            ModalEditItem,
        },
        data() {
            return {
                fields: [
                    {
                        key: 'id',
                        label: this.$t('id'),
                        sortable: true,
                    },
                    {
                        key: 'name',
                        label: this.$t('name'),
                        sortable: true,
                    },
                    {
                        key: 'url',
                        label: this.$t('url'),
                    },
                    {
                        key: 'categories',
                        label: this.$t('category'),
                    },
                    {
                        key: 'type',
                        label: this.$t('type'),
                        sortable: true,
                    },
                    {
                        key: 'status',
                        label: this.$t('status'),
                        sortable: true,
                    },
                    {
                        key: 'action',
                        label: this.$t('actions'),
                    },
                ],
                perPage: 30,
                currentPage: 1,
                sortBy: 'name',
                sortDesc: true,
                CampaignStatus,
                busyItems: [],
                error: null,
                modalItemName: 'widgeter-settings-pages-item',
            };
        },
        computed: {
            loading() {
                return this.$store.state.space.loading.fetchSpaces;
            },
            spaces() {
                return this.$store.state.space.spaces;
            },
            spaceTypes() {
                return this.$store.state.space.spaceTypes;
            },
            items() {
                return Object.values(this.spaces);
            },
            spaceCount() {
                return this.$store.getters['space/spaceCount'];
            },
        },
        methods: {
            translateSpaceTypes(spaceId) {
                return this.spaceTypes?.[spaceId]?.label;
            },
            fetchSpaces() {
                return this.$store.dispatch('space/fetchSpaces');
            },
            fetchSpaceTypes() {
                return this.$store.dispatch('space/fetchSpaceTypes');
            },
            addItem() {
                this.editItem();
            },
            editItem(item = null) {
                this.$root.$emit('bv::show::modal', `modal-edit-${this.modalItemName}`);
                this.$root.$emit(`edit-${this.modalItemName}`, item);
            },
        },
        created() {
            this.fetchSpaces();
            this.fetchSpaceTypes();
        },
    };
</script>
