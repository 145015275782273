<template>
    <div class="d-flex justify-content-end align-items-center">
        <ul class="pagination pagination-rounded mb-0">
            <b-pagination ref="pagination" v-bind="$attrs" v-on="$listeners" />
        </ul>
    </div>
</template>



<script>
    export default {
        name: 'Pagination',
        inheritAttrs: false,
        watch: {
            $attrs: {
                handler(newValue, oldValue) {
                    if (newValue['total-rows'] !== oldValue['total-rows']) {
                        this.setCurrentPage();
                        this.$nextTick(this.setCurrentPage);
                    }
                },
            },
        },
        methods: {
            setCurrentPage() {
                this.$refs.pagination.currentPage = this.$attrs.value;
            },
        },
    };
</script>
