<template>
    <b-overlay :show="loading && product" rounded="sm" class="h-100">
        <Card class="m-0 shadow-none">
            <div class="d-flex">
                <ImageLoader
                    v-if="product"
                    :src="product.image"
                    alt=""
                    size="130"
                    image-height="auto"
                    class="mr-2 mb-auto flex-shrink-0"
                />
                <b-skeleton v-if="!product" size="130px" class="mr-2 mb-auto flex-shrink-0" />

                <div class="d-flex flex-column w-100">
                    <h3 class="mt-0">
                        <span v-if="product">{{ product.name }}</span>
                        <b-skeleton v-if="!product" animation="wave" width="100%" height="30px" class="mb-0" />
                    </h3>

                    <span v-if="product">{{ product.info }}</span>
                    <b-skeleton v-if="!product" animation="wave" width="85%" class="mb-2" />

                    <b-breadcrumb v-if="product" class="text-black-50 p-0 m-0 bg-none" :items="product.categories" />
                    <b-skeleton v-if="!product" animation="wave" width="85%" class="mb-0" />
                    <span class="my-2"></span>

                    <span class="d-flex align-items-center">
                        {{ $t('ean') }}:&nbsp;
                        <strong v-if="product">{{ product.ean }}</strong>
                        <b-skeleton v-if="!product" animation="wave" width="50%" class="mb-0 ml-2 d-inline-block" />
                    </span>

                    <span class="d-flex align-items-center">
                        {{ $t('code') }}:&nbsp;
                        <strong v-if="product">{{ product.code }}</strong>
                        <b-skeleton v-if="!product" animation="wave" width="50%" class="mb-0 ml-2 d-inline-block" />
                    </span>
                </div>
            </div>
        </Card>
    </b-overlay>
</template>



<script>
    import Card from '@/components/common/Card.vue';
    import ImageLoader from '@/components/common/ImageLoader.vue';

    export default {
        name: 'Preview',
        components: {
            Card,
            ImageLoader,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        computed: {
            product() {
                return this.parentComponent.product;
            },
            loading() {
                return this.parentComponent.loading;
            },
        },
    };
</script>
