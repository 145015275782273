<template>
    <transition name="fade" mode="out-in">
        <SplashScreen v-if="dataLoading" />
        <DataNotLoaded v-else-if="dataError" />
        <div v-else class="h-100 w-100 flex-grow-1">
            <router-view />
        </div>
    </transition>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import { debounce } from 'lodash';
    import SplashScreen from '@/components/common/SplashScreen.vue';
    import DataNotLoaded from '@/views/common/data-not-loaded/index.vue';
    import Role from '@/Role';

    export default {
        name: 'App',
        components: {
            SplashScreen,
            DataNotLoaded,
        },
        computed: {
            dataLoading() {
                return this.$store.getters.sessionInitialization || this.$store.getters.spacesInitialization || this.$store.getters.campaignsInitialization;
            },
            dataError() {
                return this.$store.getters.sessionInitializationError || this.$store.getters.spacesInitializationError || this.$store.getters.campaignsInitializationError;
            },
            ...mapGetters({
                bodyClasses: 'layout/bodyClasses',
            }),
            isAuthenticated() {
                return this.$store.getters['auth/isAuthenticated'];
            },
            isAdmin() {
                return this.$store.getters['auth/isAdmin'];
            },
            user() {
                return this.$store.getters['auth/getUser'];
            },
        },
        watch: {
            bodyClasses: {
                handler(classes = []) {
                    document.body.classList = classes;
                },
                immediate: true,
            },
            user: {
                immediate: true,
                handler() {
                    if (this.user && this.user?.id && this.user?.roleName === Role.WIDGETER && !this.isAdmin) {
                        this.InitGtag();
                        this.InitGleap();
                    }
                },
            },
        },
        methods: {
            InitGtag() {
                try {
                    window.dataLayer.push({
                        user_id: this.user.id,
                        user_first_name: this.user.firstName,
                        user_email: this.user.email,
                    });
                } catch (error) {
                    //
                }
            },
            InitGleap() {
                if (!process.env.VUE_APP_GLEAP_KEY) return;

                try {
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.async = true;
                    /* eslint-disable max-len */
                    script.innerHTML = `
                        !function(Gleap,t,i){if(!(Gleap=window.Gleap=window.Gleap||[]).invoked){for(window.GleapActions=[],Gleap.invoked=!0,Gleap.methods=["identify","setEnvironment","setTags","attachCustomData","setCustomData","removeCustomData","clearCustomData","registerCustomAction","trackEvent","setUseCookies","log","preFillForm","showSurvey","sendSilentCrashReport","startFeedbackFlow","startBot","setAppBuildNumber","setAppVersionCode","setApiUrl","setFrameUrl","isOpened","open","close","on","setLanguage","setOfflineMode","startClassicForm","initialize","disableConsoleLogOverwrite","logEvent","hide","enableShortcuts","showFeedbackButton","destroy","getIdentity","isUserIdentified","clearIdentity","openConversations","openConversation","openHelpCenterCollection","openHelpCenterArticle","openHelpCenter","searchHelpCenter","openNewsArticle","openChecklists","startChecklist","openNews","openFeatureRequests","isLiveMode"],Gleap.f=function(e){return function(){var t=Array.prototype.slice.call(arguments);window.GleapActions.push({e:e,a:t})}},t=0;t<Gleap.methods.length;t++)Gleap[i=Gleap.methods[t]]=Gleap.f(i);Gleap.load=function(){var t=document.getElementsByTagName("head")[0],i=document.createElement("script");i.type="text/javascript",i.async=!0,i.src="https://sdk.gleap.io/latest/index.js",t.appendChild(i)},Gleap.load(),
                            Gleap.identify("${this.user.id}", {
                            name: "${this.user.firstName}",
                            email: "${this.user.email}"
                            }),
                            Gleap.initialize("${process.env.VUE_APP_GLEAP_KEY}")
                        }}();
                    `;
                    /* eslint-enable max-len */
                    document.head.appendChild(script);
                } catch (error) {
                    //
                }
            },
            handleSize() {
                if (document.documentElement.clientWidth <= 992) {
                    this.enableMenuMobileMode();
                } else {
                    this.disableMenuMobileMode();
                }
            },
            ...mapMutations({
                enableMenuMobileMode: 'layout/enableMenuMobileMode',
                disableMenuMobileMode: 'layout/disableMenuMobileMode',
            }),
        },
        mounted() {
            this.handleSize();
            window.addEventListener('resize', debounce(this.handleSize, 100));

            window.getVersion = function getVersion() {
                return { hash: process.env.GIT_HASH, commit: process.env.GIT_COMMIT_MESSAGE, date: process.env.GIT_COMMIT_DATE };
            };
        },
    };
</script>

<style lang="scss">
    body {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
    @import './design/index';
</style>
