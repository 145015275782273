<template>
    <div class="hue" :style="{width: `${width}px`, height: `${height}px`}" @mousedown.prevent.stop="selectHue">
        <canvas ref="canvasHue"></canvas>
        <div :style="slideHueStyle" :class="`slide ${direction}`"></div>
    </div>
</template>

<script>
    import { getColorAsHSV, rgb2hsv } from '@/utils/color';

    export default {
        name: 'Hue',
        props: {
            color: {
                type: [String, Object],
                default: null,
            },
            width: {
                type: Number,
                default: 150,
            },
            height: {
                type: Number,
                default: 15,
            },
            direction: {
                type: String,
                default: 'horizontal',
                validator: value => ['vertical', 'horizontal'].includes(value),
            },
        },
        data() {
            return {
                slideHueStyle: {},
                hsv: {},
                vertical: this.direction === 'vertical',
                horizontal: this.direction === 'horizontal',
            };
        },
        watch: {
            color: {
                immediate: true,
                handler(newValue, oldValue) {
                    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                        this.hsv = getColorAsHSV(this.color);
                        this.setSlideStyle();
                        this.$emit('color', this.hsv);
                    }
                },
            },
        },
        methods: {
            renderColor() {
                if (!this.$refs.canvasHue) return;
                const canvas = this.$refs.canvasHue;
                const width = this.width;
                const height = this.height;
                const ctx = canvas.getContext('2d');
                const size = this.vertical ? this.height : this.width;
                canvas.width = width;
                canvas.height = height;

                const gradient = this.vertical ? ctx.createLinearGradient(0, 0, 0, height) : ctx.createLinearGradient(0, 0, width, 0);
                gradient.addColorStop(0, '#FF0000'); // red
                gradient.addColorStop(1 / size, '#FF0000'); // fix first pixel to red
                gradient.addColorStop(0.17 * 1, '#FF00FF'); // purple
                gradient.addColorStop(0.17 * 2, '#0000FF'); // blue
                gradient.addColorStop(0.17 * 3, '#00FFFF'); // blue-green
                gradient.addColorStop(0.17 * 4, '#00FF00'); // green
                gradient.addColorStop(0.17 * 5, '#FFFF00'); // yellow
                gradient.addColorStop(1 - 1 / size, '#FF0000'); // fix last pixel to red
                gradient.addColorStop(1, '#FF0000'); // red
                ctx.fillStyle = gradient;
                ctx.fillRect(0, 0, width, height);
            },
            setSlideStyle() {
                if (this.vertical) {
                    this.slideHueStyle = { top: `${(1 - this.hsv.h / 360) * this.height - 2}px` };
                } else {
                    this.slideHueStyle = { left: `${(1 - this.hsv.h / 360) * this.width - 2}px` };
                }
            },
            selectHue(event) {
                const bcr = this.$el.getBoundingClientRect();
                const hue = this.vertical ? bcr.top : bcr.left;
                const ctx = event.target.getContext('2d');
                let imgData = [];
                let selectedColor = {};

                const mousemove = e => {
                    if (this.vertical) {
                        let y = e.clientY - hue;

                        if (y < 0) y = 0;
                        if (y > this.height) y = this.height;

                        this.slideHueStyle = { top: `${y - 2}px` };

                        // If you use the maximum value, the selected pixel will be empty, and empty is black by default.
                        imgData = ctx.getImageData(0, Math.min(y, this.height - 1), 1, 1);
                    } else {
                        let x = e.clientX - hue;

                        if (x < 0) x = 0;
                        if (x > this.width) x = this.width;

                        this.slideHueStyle = { left: `${x - 2}px` };

                        // If you use the maximum value, the selected pixel will be empty, and empty is black by default.
                        imgData = ctx.getImageData(Math.min(x, this.width - 1), 0, 1, 1);
                    }

                    const [r, g, b] = imgData.data;
                    const { h } = rgb2hsv({ r, g, b });
                    selectedColor = { h, s: this.hsv.s, v: this.hsv.v };
                    this.$emit('color', selectedColor);
                };

                mousemove(event);

                const mouseup = () => {
                    this.$emit('change', selectedColor);
                    document.removeEventListener('mousemove', mousemove);
                    document.removeEventListener('mouseup', mouseup);
                };

                document.addEventListener('mousemove', mousemove);
                document.addEventListener('mouseup', mouseup);
            },
        },
        mounted() {
            this.renderColor();
            this.setSlideStyle();
        },
    };
</script>

<style lang="scss">
    .hue {
        position: relative;
        cursor: pointer;

        .slide {
            position: absolute;
            left: 0;
            top: 100px;
            width: 100%;
            height: 4px;
            background: #fff;
            box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
            pointer-events: none;

            &.horizontal {
                top: 0;
                width: 4px;
                height: 100%;
            }
        }
    }
</style>
