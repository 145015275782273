<template>
    <DataTable
        :header="$t('widgeterReportsShops.campaigns')"
        :error="error"
        :items="itemsFormatted"
        :fields="fields"
        :loading="loading"
        :total-rows="items.length"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
        :sort-by.sync="sortBy"
        :sort-direction.sync="sortDirection"
    >
        <template #headerSide>
            <JsonToCsv
                class="ml-3"
                :disabled="loading || !items.length"
                :items="itemsFormatted"
                :fields="exportFields"
                :labels="exportLabels"
                :name="exportFilename"
            >
                {{ $t('exportToExcel') }}
            </JsonToCsv>
        </template>
    </DataTable>
</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';
    import JsonToCsv from '@/components/common/JsonToCsv.vue';

    export default {
        name: 'CampaignList',
        components: {
            DataTable,
            JsonToCsv,
        },
        props: {
            spaceIds: {
                type: Array,
                required: true,
            },
            campaignIds: {
                type: Array,
                required: true,
            },
            timeRange: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                sortBy: 'campaign.name',
                perPage: 25,
                currentPage: 1,
                sortDirection: 'ASC',
            };
        },
        computed: {
            fields() {
                return [
                    {
                        key: 'campaign.name',
                        label: this.$t('widgeterReportsShops.campaign'),
                        sortable: true,
                    },
                    {
                        key: 'impressions',
                        label: this.$t('widgeterReportsShops.impressions'),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'clicks',
                        label: this.$t('widgeterReportsShops.clicks'),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'orders',
                        label: this.$t('widgeterReportsShops.transactions'),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'commission',
                        label: this.$t('widgeterReportsShops.commission'),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'amount',
                        label: this.$t('widgeterReportsShops.transactionAmount'),
                        sortable: true,
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ];
            },
            error() {
                return this.$store.getters['userStats/getError']('campaignStats');
            },
            loading() {
                return this.$store.getters['userStats/getLoading']('campaignStats') || this.$store.state.space.loading.fetchSpaces || this.$store.getters['campaigns/getLoading']('campaigns');
            },
            campaigns() {
                return this.$store.getters['userStats/getCampaignStats'];
            },
            items() {
                return this.campaigns.items || [];
            },
            requestParams() {
                return {
                    spaces: this.spaceIds.join(','),
                    startDate: this.timeRange.startDate,
                    endDate: this.timeRange.endDate,
                    campaigns: this.campaignIds.join(','),
                };
            },
            apiParams() {
                return { requestParams: this.requestParams };
            },
            exportFields() {
                return this.fields.map(item => item.key);
            },
            exportLabels() {
                return Object.fromEntries(this.fields.map(item => [item.key, item.label]));
            },
            exportFilename() {
                const from = this.timeRange?.startDate ? `-from-${this.$format.date(this.timeRange.startDate)}` : '';
                const to = this.timeRange?.endDate ? `-to-${this.$format.date(this.timeRange.endDate)}` : '';
                return `reports-shops${from}${to}.csv`;
            },
            itemsFormatted() {
                return this.items.map(item => ({
                    ...item,
                    commission: this.$format.currency(item.commission),
                    amount: this.$format.currency(item.amount),
                }));
            },
        },
        watch: {
            apiParams: {
                handler: 'fetchData',
            },
        },
        methods: {
            fetchData() {
                if (!this.requestParams.startDate || !this.requestParams.endDate || !this.requestParams.spaces || !this.requestParams.campaigns) return;
                this.$store.dispatch('userStats/fetchCampaignStats', this.apiParams);
            },
        },
    };
</script>
