<template>
    <b-badge :variant="badgeColor" class="mx-1 my-1"> {{ label }} {{ value ? '(' + formattedDate + ')' : '' }} </b-badge>
</template>

<script>
    export default {
        name: 'BadgeFeedUpdate',
        props: {
            value: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
        },
        computed: {
            formattedDate() {
                return this.$format.dateAndHour(this.value);
            },
            badgeColor() {
                if (!this.value) {
                    return 'danger';
                }

                const now = Date.now();
                const lastUpdate = new Date(this.value).getTime();

                // Calculate difference in hours
                const diffInHours = (now - lastUpdate) / (1000 * 60 * 60);

                if (diffInHours > 48) {
                    return 'danger';
                }
                return 'success';
            },
        },
    };
</script>
