import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    CAMPAIGN_SPACE_STATUSES: {
        URI: () => '/api/v1/campaign-space-statuses',
        methods: ['get'],
        default: [],
        formatter: data => data?.items,
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
