import errorService from './errorService';
import apiService from './apiService';
import dateUtil from '@/utils/date';
import { i18n } from '../i18n/i18n';

export const TransactionStatus = Object.freeze({
    NEW: 1,
    ACCEPTED: 2,
    REJECTED: 3,
    ABANDONED: 4,
});

export const translateTransactionStatus = status => {
    switch (status) {
        case TransactionStatus.NEW:
            return i18n.t('transactionStatus.new');
        case TransactionStatus.ACCEPTED:
            return i18n.t('transactionStatus.accepted');
        case TransactionStatus.REJECTED:
            return i18n.t('transactionStatus.rejected');
        case TransactionStatus.ABANDONED:
            return i18n.t('transactionStatus.abandoned');
        default:
            return '';
    }
};

export default {
    async fetchTransactions({ page, perPage, userId, space, campaign, financeDocument, date = [], status = [], omitItems, abandonedAsRejected }) {
        try {
            const statuses = [...status];
            const [dateFrom, dateTo] = date;

            if (abandonedAsRejected && status.includes(TransactionStatus.REJECTED)) {
                statuses.push(TransactionStatus.ABANDONED);
            }

            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/publishers/${userId}/transactions`, {
                spaces: space,
                campaigns: campaign,
                from: dateFrom ? dateUtil.formatDateForUrl(dateFrom) : undefined,
                to: dateTo ? dateUtil.formatDateForUrl(dateTo) : undefined,
                statuses: statuses.join(','),
                financeDocument,
                omitItems,
                order: 'DESC',
                sort: 'createdAt',
                page,
                perPage,
            });

            if (abandonedAsRejected) {
                response.data.items = response.data.items.map(item => ({
                    ...item,
                    status: item.status === TransactionStatus.ABANDONED ? TransactionStatus.REJECTED : item.status,
                }));
            }
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};
