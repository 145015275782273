<template>
    <component :is="componentType" class="form-validator" v-bind="$attrs">
        <template #label><slot name="label"></slot></template>
        <slot name="default" :state="state"></slot>
        <FormFeedback v-if="validator" :validator="validator" :msg-props="{attribute: $attrs.label, ...msgProps }" :messages="messages" />
        <slot name="bottom"></slot>
    </component>
</template>



<script>
    import FormFeedback from '@/components/common/FormFeedback.vue';

    export default {
        name: 'FormValidator',
        inheritAttrs: false,
        components: {
            FormFeedback,
        },
        props: {
            validator: {
                type: Object,
                default: null,
            },
            attribute: {
                type: String,
                default: '',
            },
            formGroup: {
                type: Boolean,
                default: false,
            },
            messages: {
                type: Object,
                default: null,
            },
            msgProps: {
                type: Object,
                default: () => ({}),
            },
        },
        computed: {
            componentType() {
                if (this.formGroup) return 'b-form-group';
                return 'div';
            },
            state() {
                return this.validator?.$dirty && this.validator.$error ? false : null;
            },
        },
    };
</script>
