<template>
    <DataTable
        v-model="selectedRows"
        :fields="fields"
        :items="parentComponent.items"
        :loading="parentComponent.loading"
        :error="parentComponent.error"
        :total-rows="parentComponent.requestData.max"
        :current-page.sync="parentComponent.tableParams.page"
        :per-page.sync="parentComponent.tableParams.perPage"
        :sort-by.sync="parentComponent.tableParams.sort"
        :sort-direction.sync="parentComponent.tableParams.order"
        no-sort-reset
        selectable
        selectable-id-key="id"
        :busy-items="busyItems"
        foot-clone
        no-local-sorting
        no-footer-sorting
    >

        <template #cell(createdAt)="{value}">
            {{ dateUtil.formatDate(value) }}
        </template>

        <template #cell(user)="{value, item}">
            <b-link :to="{ name: 'UsersDetails', params: { id: item.userId }, query: {} }">{{ value }}</b-link>
        </template>

        <template #cell(downloadDocument)="{item}">
            <div class="d-flex justify-content-start">
                <ButtonIcon
                    variant="link"
                    size="sm"
                    hide-label-while-loading
                    :loading="isInvoiceDownloading(item.id)"
                    :disabled="!item.downloadable"
                    @click="downloadInvoice(item)"
                >
                    {{ $t('download').toLowerCase() }}
                </ButtonIcon>
            </div>
        </template>

        <template #cell(net)="{value}">
            {{ $format.currency(value) }}
        </template>

        <template #cell(status)="{value}">
            <FinanceDocumentStatusBadge :status="value" />
        </template>

        <template #cell(showTransaction)="{item}">
            <div class="d-flex justify-content-start">
                <ButtonIcon variant="link" size="sm" :disabled="isItemBusy(item.id)" :to="{ name: 'ReportsTransactions', query: { financeDocument: item.id, from: '1970-01-01' } }">
                    {{ $t('showTransaction').toLowerCase() }}
                </ButtonIcon>
            </div>
        </template>

        <template #foot(net) v-if="selectedRows.length">
            <strong>{{ selectedNetSum }}</strong>
        </template>

        <template #foot()>
            <div></div>
        </template>

        <template #afterTable>
            <div class="d-flex align-items-center mt-3">
                <span>{{ $t('withSelected') }}</span>
                <span class="mx-2"></span>
                <Multiselect
                    :value="action"
                    :options="actionOptions"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    :disabled="isItemsBusy"
                    class="width-200 d-inline-block"
                    track-by="value"
                    @inputTrackBy="action = $event"
                />
                <span class="mx-2"></span>
                <ButtonIcon variant="primary" :disabled="!action || !parentComponent.items.length || parentComponent.loading" :loading="isItemsBusy" hide-label-while-loading @click="tableItemsAction()">
                    {{ $t('save') }}
                </ButtonIcon>
            </div>
        </template>

    </DataTable>
</template>



<script>
    import dateUtil from '@/utils/date';
    import DataTable from '@/components/common/DataTable.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import FinanceDocumentStatusBadge from '@/components/common/FinanceDocumentStatusBadge.vue';
    import financeService, { FinanceDocumentStatus } from '@/services/financeService';
    import { createItemKey } from '@/store/modules/moduleFactory';
    import { translateTransactionStatus } from '@/services/transactionService';

    export default {
        name: 'Results',
        components: {
            DataTable,
            Multiselect,
            FinanceDocumentStatusBadge,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                dateUtil,
                selectedRows: [],
                action: null,
                busyItems: [],
            };
        },
        computed: {
            actionOptions() {
                return [
                    { value: null, text: this.$t('doNothing') },
                    { value: FinanceDocumentStatus.ACCEPTED, text: this.$t('accept') },
                    { value: FinanceDocumentStatus.REJECTED, text: this.$t('reject') },
                    { value: FinanceDocumentStatus.PAID, text: this.$t('settle') },
                ];
            },
            fields() {
                return [
                    {
                        key: 'createdAt',
                        label: this.$t('date'),
                        sortable: true,
                    },
                    {
                        key: 'user',
                        label: this.$t('publisher'),
                    },
                    {
                        key: 'number',
                        label: this.$t('document'),
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                    {
                        key: 'downloadDocument',
                        label: '',
                    },
                    {
                        key: 'net',
                        label: this.$t('amount'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'showTransaction',
                        label: '',
                    },
                    {
                        key: 'status',
                        label: this.$t('status'),
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ];
            },
            isItemBusy() {
                return id => this.busyItems.includes(id);
            },
            isItemsBusy() {
                return !!this.busyItems.length;
            },
            selectedNetSum() {
                const selectedItems = this.parentComponent.items.filter(item => this.selectedRows.includes(item.id));
                const sum = selectedItems.reduce((acc, item) => acc + item.net, 0);
                return this.$format.currency(sum);
            },
            isInvoiceDownloading() {
                return id => this.$store.getters['finance/getBusyItem']('financeDocumentsDownload', createItemKey({ id }));
            },
            exportFields() {
                return Object.keys(this.exportLabels);
            },
            exportLabels() {
                return {
                    id: 'ID',
                    createdAt: this.$t('date'),
                    transId: this.$t('identifier'),
                    statusText: this.$t('status'),
                    'campaign.name': this.$t('campaign'),
                    'space.name': this.$t('space'),
                    amount: this.$t('transactions.transactionAmount'),
                    publisherCommission: `${this.$t('commission')} ${this.$t('transactions.commissions.publisher')}`,
                    adminCommission: `${this.$t('commission')} ${this.$t('transactions.commissions.admin')}`,
                    advertiserCommission: `${this.$t('commission')} ${this.$t('transactions.commissions.advertiser')}`,
                    conversionTime: this.$t('conversionTime'),
                };
            },
        },
        watch: {
            selectedRows() {
                if (this.selectedRows.length) this.$toastr.close('noDocumentsSelected');
            },
            tableParams: {
                deep: true,
                handler: 'fetchData',
            },
        },
        methods: {
            formatDataItems(items) {
                return (
                    items?.map(item => ({
                        ...item,
                        createdAt: this.$format.dateAndHour(item.createdAt),
                        statusText: translateTransactionStatus(item.status),
                        publisherCommission: this.$format.currency(item.publisherCommission),
                        adminCommission: this.$format.currency(item.adminCommission),
                        advertiserCommission: this.$format.currency(item.advertiserCommission),
                        amount: this.$format.currency(item.amount),
                        conversionTime: this.$format.seconds(item.conversionTime),
                    })) || []
                );
            },
            fetchData() {
                this.parentComponent.fetchData();
            },
            downloadInvoice(item) {
                this.$store.dispatch('finance/fetchFinanceDocumentsDownload', { urlParams: { id: item.id } }).catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            async tableItemsAction() {
                if (!this.selectedRows.length) {
                    this.$toastr.info(this.$t('noDocumentsSelected'), null, { id: 'noDocumentsSelected' });
                } else if (this.action) {
                    await this.documentsAction(this.action);
                }
            },
            async documentsAction(status) {
                this.busyItems = [...this.selectedRows];
                try {
                    await financeService.putPublisherFinanceDocumentsStatuses({ requestParams: { financeDocuments: this.selectedRows, status } });
                    this.selectedRows = [];
                    this.action = null;
                    this.fetchData();
                } catch (error) {
                    this.$toastr.error(error.message);
                }
                this.busyItems = [];
            },
            addBusyItem(id) {
                this.busyItems.push(id);
            },
            removeBusyItem(id) {
                const newSet = new Set(this.busyItems);
                newSet.delete(id);
                this.busyItems = Array.from(newSet);
            },
        },
    };
</script>
