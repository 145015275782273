<template>
    <b-modal
        :id="`modal-form-${modalId}`"
        ref="modal"
        :title="modalTitle"
        size="lg"
        cancel-variant="light"
        ok-variant="primary"
        :cancel-title="$t('cancel')"
        :ok-title="$t('save')"
        :busy="submitting"
        static
        @ok.prevent="handleSubmit()"
        @show="resetData()"
        @hidden="resetData()"
    >
        <b-overlay :show="submitting" opacity="0" class="h-100">
            <b-form @submit.prevent>
                <FormValidator :validator="$v.form.id" form-group v-slot="slotProps" :label="$t('id')">
                    <b-form-input v-model.number="form.id" :placeholder="$t('id')" :state="slotProps.state" :disabled="submitting" />
                </FormValidator>
                <FormValidator :validator="$v.form.name" form-group v-slot="slotProps" :label="$t('name')">
                    <b-form-input v-model="form.name" :placeholder="$t('name')" :state="slotProps.state" :disabled="submitting" />
                </FormValidator>
                <b-form-group :label="$t('parent')">
                    <Multiselect
                        ref="formCategories"
                        v-model="form.parent"
                        :fetch-options-function="fetchCategories"
                        :parse-options-function="parseCategories"
                        :placeholder="$t('selectField.placeholder')"
                        asynchronous
                        searchable
                        show-selected
                        track-by="id"
                        label="name"
                        select-label=""
                        :disabled="submitting"
                        @loading="value => isCategoriesLoading = value"
                        @search-change="value => categoriesSearchText = value"
                    />
                </b-form-group>
            </b-form>
        </b-overlay>
    </b-modal>
</template>



<script>
    import { required, integer } from 'vuelidate/lib/validators';
    import ModalFormMixin from '@/mixins/modalFormMixin';
    import FormValidator from '@/components/common/FormValidator.vue';
    import catalogService from '@/services/catalogService';
    import Multiselect from '@/components/common/Multiselect.vue';

    export default {
        name: 'ModalForm',
        mixins: [ModalFormMixin],
        components: {
            FormValidator,
            Multiselect,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                modalId: 'catalog-categories',
                createAction: 'catalog/postCategories',
                updateAction: 'catalog/patchCategories',
                form: {
                    id: '',
                    name: '',
                    parent: null,
                },
                isCategoriesLoading: false,
                categoriesSearchText: '',
            };
        },
        validations() {
            return {
                form: {
                    id: { required, integer },
                    name: { required },
                },
            };
        },
        watch: {
            item() {
                if (this.item?.parent) this.form.parent = this.item.parent;
            },
        },
        methods: {
            getDataStructure(data) {
                return {
                    ...data,
                    parent: data.parent?.id,
                };
            },
            fetchCategories() {
                const requestParams = { name: this.categoriesSearchText };
                return catalogService.getCategories({ requestParams });
            },
            parseCategories(data) {
                return data?.items || [];
            },
            refreshData() {
                this.parentComponent.fetchData();
            },
        },
    };
</script>
