<template>
    <div>
        <PageHeader>
            {{ $t('catalog') }} - {{ $t('moderation') }}
        </PageHeader>

        <Card :header="$t('filters')">
            <b-form-group :label="$t('status')" label-cols-sm="3">
                <b-form-checkbox-group v-model="filters.statuses" :options="statusesOption" class="h-100 d-flex align-items-center" :disabled="loading" />
            </b-form-group>

            <ButtonIcon :loading="loading" icon="filter" @click="fetchData()">
                {{ $t('filter') }}
            </ButtonIcon>
        </Card>

        <DataTable
            :items="items"
            :fields="fields"
            :total-rows="responseData.max"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :sort-by.sync="sortBy"
            :sort-direction.sync="sortDirection"
            :loading="loading"
            no-local-sorting
        >
            <template #cell(createdAt)="data">
                {{ $format.dateAndHour(data.value) }}
            </template>
            <template #cell(status)="data">
                {{ $t(`status${camelCase(data.value, { pascalCase: true })}`) }}
            </template>
            <template #cell(action)="data">
                <b-button variant="success" @click="previewItem(data.item)">
                    {{ $t('details') }}
                </b-button>
            </template>
        </DataTable>
        <ItemPreview ref="ItemPreview" />
    </div>
</template>



<script>
    import camelCase from 'camelcase';
    import DataTable from '@/components/common/DataTable.vue';
    import ItemPreview from './parts/ItemPreview.vue';

    export default {
        name: 'CatalogModeration',
        components: {
            DataTable,
            ItemPreview,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                modalItemName: 'admin-catalog-moderation-item',
                fields: [
                    {
                        key: 'id',
                        label: this.$t('id'),
                        sortable: true,
                    },
                    {
                        key: 'createdAt',
                        label: this.$t('date'),
                    },
                    {
                        key: 'status',
                        label: this.$t('status'),
                    },
                    {
                        key: 'comment',
                        label: this.$t('comment'),
                    },
                    {
                        key: 'action',
                        label: '',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ],
                perPage: 25,
                currentPage: 1,
                sortBy: 'id',
                sortDirection: 'desc',
                filters: {
                    statuses: [],
                },
            };
        },
        computed: {
            filtersParams() {
                const filters = {};
                if (this.filters.statuses?.length) filters.statuses = this.filters.statuses.join(',');
                return filters;
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                    ...this.tableParams,
                };
            },
            tableParams() {
                return {
                    page: this.currentPage,
                    perPage: this.perPage,
                    sort: this.sortBy,
                    order: this.sortDirection.toUpperCase(),
                };
            },
            responseData() {
                return this.$store.getters['catalog/getImportIssues'];
            },
            items() {
                return this.responseData.items || [];
            },
            error() {
                return this.$store.getters['catalog/getError']('importIssues');
            },
            loading() {
                return this.$store.getters['catalog/getLoading']('importIssues');
            },
            statusesOption() {
                return [
                    { text: this.$t('statusCreated'), value: 'created' },
                    { text: this.$t('statusClosed'), value: 'closed' },
                    { text: this.$t('statusReopen'), value: 'reopen' },
                    { text: this.$t('statusWontDo'), value: 'wont_do' },
                ];
            },
        },
        watch: {
            tableParams: {
                immediate: true,
                handler: 'fetchData',
            },
            error() {
                if (this.error) this.$toastr.error(this.error.message, this.$t('errorOccurred'));
            },
        },
        methods: {
            camelCase,
            clearData() {
                this.$store.commit('catalog/clearImportIssues');
            },
            fetchData() {
                this.$store.dispatch('catalog/fetchImportIssues', { requestParams: this.requestParams }).catch(() => {});
            },
            previewItem(item) {
                this.$root.$emit('bv::show::modal', `modal-preview-${this.modalItemName}`);
                this.$root.$emit(`preview-${this.modalItemName}`, item);
            },
        },
        created() {
            this.clearData();
        },
    };
</script>
