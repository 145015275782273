<template>
    <div>

        <PageHeader>
            {{ $t('tipboxWidgetSettings') }}
        </PageHeader>

        <SpaceSwitchCard :disabled="busy" @space="space = $event" />

        <template v-if="space">

            <b-overlay :show="loading" rounded="sm" class="card flex-grow-1 flex-basis-0 width-min-300">
                <Card :header="$t('tipboxWidget')" class="mb-0 shadow-none">
                    <ul class="mb-0">
                        <li>
                            <strong>{{ $t('identifier') }}</strong>:
                            <span v-if="tipbox">{{ tipbox.id || '' }}</span>
                        </li>
                        <li>
                            <strong>{{ $t('space') }}</strong>:
                            <span v-if="space">{{ space.url || '' }}</span>
                        </li>
                    </ul>
                </Card>
            </b-overlay>

            <div class="d-flex gap-4 flex-wrap mb-4">

                <b-overlay :show="loading" rounded="sm" class="card flex-grow-1 flex-basis-0 mb-0 width-min-300">
                    <Card :header="`1. ${$t('customAppearance')}`" class="mb-0 shadow-none">
                        <div class="d-flex flex-column gap-2">
                            <div class="d-flex flex-wrap gap-2">
                                <FormValidator :validator="$v.form.headerColor" v-slot="slotProps" form-group :label="$t('tipboxForm.label.headerColor')" class="flex-grow-1 flex-basis-0 width-min-200">
                                    <ColorField v-model="form.headerColor" :placeholder="tipboxDefaults.headerColor" :state="slotProps.state" class="mb-0" :disabled="busy" />
                                </FormValidator>
                                <FormValidator :validator="$v.form.textColor" v-slot="slotProps" form-group :label="$t('tipboxForm.label.textColor')" class="flex-grow-1 flex-basis-0 width-min-200">
                                    <ColorField v-model="form.textColor" :placeholder="tipboxDefaults.textColor" :state="slotProps.state" class="mb-0" :disabled="busy" />
                                </FormValidator>
                            </div>
                            <div class="d-flex flex-wrap gap-2">
                                <FormValidator :validator="$v.form.buttonColor" v-slot="slotProps" form-group :label="$t('tipboxForm.label.buttonColor')" class="flex-grow-1 flex-basis-0 width-min-200">
                                    <ColorField v-model="form.buttonColor" :placeholder="tipboxDefaults.buttonColor" :state="slotProps.state" class="mb-0" :disabled="busy" />
                                </FormValidator>
                                <FormValidator :validator="$v.form.buttonTextColor" v-slot="slotProps" form-group :label="$t('tipboxForm.label.buttonTextColor')" class="flex-grow-1 flex-basis-0 width-min-200">
                                    <ColorField v-model="form.buttonTextColor" :placeholder="tipboxDefaults.buttonTextColor" :state="slotProps.state" class="mb-0" :disabled="busy" />
                                </FormValidator>
                            </div>
                            <div class="d-flex flex-wrap gap-2">
                                <FormValidator :validator="$v.form.frameColor" v-slot="slotProps" form-group :label="$t('tipboxForm.label.frameColor')" class="flex-grow-1 flex-basis-0 width-min-200">
                                    <ColorField v-model="form.frameColor" :placeholder="tipboxDefaults.frameColor" :state="slotProps.state" class="mb-0" :disabled="busy" />
                                </FormValidator>
                                <FormValidator :validator="$v.form.backgroundColor" v-slot="slotProps" form-group :label="$t('tipboxForm.label.backgroundColor')" class="flex-grow-1 flex-basis-0 width-min-200">
                                    <ColorField v-model="form.backgroundColor" :placeholder="tipboxDefaults.backgroundColor" :state="slotProps.state" class="mb-0" :disabled="busy" />
                                </FormValidator>
                            </div>
                        </div>
                        <b-form-group :label="$t('tipboxForm.label.header')">
                            <b-form-input v-model="form.header" :placeholder="tipboxDefaults.header" class="" :disabled="busy" />
                        </b-form-group>
                        <b-form-group :label="$t('tipboxForm.label.description')">
                            <b-form-textarea v-model="form.description" :placeholder="tipboxDefaults.description" class="" :disabled="busy" />
                        </b-form-group>
                        <b-form-group :label="$t('tipboxForm.label.thanks')">
                            <b-form-input v-model="form.thanks" :placeholder="tipboxDefaults.thanks" class="" :disabled="busy" />
                        </b-form-group>
                        <b-form-group :label="$t('tipboxForm.label.amounts')">
                            <div class="d-flex flex-wrap gap-2">
                                <FormValidator :validator="$v.form.amounts[0]" :msg-props="{ min: minAmount[0] }" v-slot="slotProps">
                                    <b-form-input v-model="form.amounts[0]" :placeholder="tipboxDefaults.amounts[0].toString()" :state="slotProps.state" class="flex-grow-1 flex-basis-0 width-min-200" :disabled="busy" />
                                </FormValidator>
                                <FormValidator :validator="$v.form.amounts[1]" :msg-props="{ min: minAmount[1] }" v-slot="slotProps">
                                    <b-form-input v-model="form.amounts[1]" :placeholder="tipboxDefaults.amounts[1].toString()" :state="slotProps.state" class="flex-grow-1 flex-basis-0 width-min-200" :disabled="busy" />
                                </FormValidator>
                                <FormValidator :validator="$v.form.amounts[2]" :msg-props="{ min: minAmount[2] }" v-slot="slotProps">
                                    <b-form-input v-model="form.amounts[2]" :placeholder="tipboxDefaults.amounts[2].toString()" :state="slotProps.state" class="flex-grow-1 flex-basis-0 width-min-200" :disabled="busy" />
                                </FormValidator>
                            </div>
                        </b-form-group>
                        <b-form-group class="max-w-sm" :label="$t('tipboxForm.label.icon')">
                            <SelectField
                                v-model="form.icon"
                                :options="iconsOptions"
                                :clearable="true"
                                :disabled="busy "
                                placeholder="..."
                            />
                        </b-form-group>
                        <b-form-group class="mb-0">
                            <ButtonIcon :disabled="busy" :loading="busy" @click="formSubmit()">
                                {{ $t('save') }}
                            </ButtonIcon>
                        </b-form-group>
                    </Card>
                </b-overlay>

                <b-overlay :show="loading" rounded="sm" class="card flex-grow-1 flex-basis-0 mb-0 width-min-300">
                    <Card no-padding class="mb-0 overflow-hidden shadow-none">
                        <img src="@/assets/images/tipbox-1.png" class="w-100 mb-4">
                        <img src="@/assets/images/tipbox-2.png" class="w-100 mb-2">
                    </Card>
                    <Card class="mb-4 shadow-none w-100 h-50">
                        <TipboxPreview v-if="tipbox && tipbox.id" :config="tipboxConfig" />
                    </Card>
                </b-overlay>

                <b-overlay :show="loading" rounded="sm" class="w-100">
                    <Card :header="`2. ${$t('insertOnPage')}`" class="w-100 width-min-300">
                        <b-card-text>
                            {{ $t('copyTheCodeAndInsertItIntoYourPage') }}
                        </b-card-text>
                        <output class="form-control h-auto my-3">
                            <pre class="mb-0"><code id="generated-code">{{ tipboxCode }}</code></pre>
                        </output>
                        <ButtonIcon icon="copy" @click="copyTipboxCodeAuto()">
                            {{ $t('generator.copyCode') }}
                        </ButtonIcon>
                    </Card>
                </b-overlay>

            </div>

        </template>

    </div>
</template>



<script>
    import merge from 'lodash/merge';
    import pick from 'lodash/pick';
    import { required, decimal, minValue } from 'vuelidate/lib/validators';
    import { generateId } from '@/utils/util';
    import validationService from '@/services/validationService';
    import ColorField from '@/components/common/ColorField.vue';
    import FormValidator from '@/components/common/FormValidator.vue';
    import SpaceSwitchCard from '@/components/common/SpaceSwitchCard.vue';
    import TipboxPreview from '@/components/common/TipboxPreview.vue';
    import SelectField from '@/components/common/SelectField.vue';

    export default {
        name: 'TipboxSettings',
        components: {
            ColorField,
            FormValidator,
            SpaceSwitchCard,
            TipboxPreview,
            SelectField,
        },
        data() {
            const formDefaults = {
                amounts: [null, null, null],
                header: null,
                description: null,
                thanks: null,
                headerColor: null,
                textColor: null,
                buttonColor: null,
                buttonTextColor: null,
                frameColor: null,
                backgroundColor: null,
                icon: null,
            };
            return {
                space: null,
                tipboxDefaults: this.$t('tipboxForm.placeholder'),
                formDefaults,
                form: merge({}, formDefaults),
                tipboxScriptUrl: process.env.VUE_APP_WIDGET_SCRIPT_URL,
                tipboxId: generateId(),
                emojiCodes: [
                    '👋',
                    '👍',
                    '👏',
                    '💪',
                    '🦴',
                    '🌹',
                    '🌷',
                    '🌻',
                    '🪴',
                    '🌲',
                    '🌳',
                    '🍀',
                    '🍇',
                    '🍎',
                    '🍓',
                    '🫐',
                    '🍅',
                    '🥑',
                    '🥕',
                    '🌶️',
                    '🥦',
                    '🥜',
                    '🥐',
                    '🥨',
                    '🥯',
                    '🧇',
                    '🍔',
                    '🍟',
                    '🍕',
                    '🌭',
                    '🥗',
                    '🍿',
                    '🍣',
                    '🥟',
                    '🥠',
                    '🍦',
                    '🍨',
                    '🍩',
                    '🍪',
                    '🍰',
                    '🧁',
                    '🍬',
                    '🍭',
                    '🫖',
                    '🍷',
                    '🍺',
                    '☕',
                    '🧉',
                    '🚲',
                    '⛵',
                    '🚀',
                    '⌚',
                    '⚡',
                    '💧',
                    '🎃',
                    '🎄',
                    '🎈',
                    '✨',
                    '🎉',
                    '🎁',
                    '🏆',
                    '⚽',
                    '🎲',
                    '🛍️',
                ],
            };
        },
        validations() {
            return {
                form: {
                    headerColor: { hexColor: validationService.hexColor },
                    textColor: { hexColor: validationService.hexColor },
                    buttonColor: { hexColor: validationService.hexColor },
                    buttonTextColor: { hexColor: validationService.hexColor },
                    frameColor: { hexColor: validationService.hexColor },
                    backgroundColor: { hexColor: validationService.hexColor },
                    amounts: {
                        0: { required, decimal, minValue: minValue(this.minAmount[0]) },
                        1: { required, decimal, minValue: minValue(this.minAmount[1]) },
                        2: { required, decimal, minValue: minValue(this.minAmount[2]) },
                    },
                },
            };
        },
        computed: {
            minAmount() {
                const min = 5;
                return {
                    0: min,
                    1: Math.max(min + 0.5, (Number.parseInt(this.form.amounts[0]) || 0) + 0.5),
                    2: Math.max(min + 1, (Number.parseInt(this.form.amounts[1]) || 0) + 0.5),
                };
            },
            apiParams() {
                return {
                    requestParams: this.form,
                    urlParams: { spaceId: this.space?.id },
                };
            },
            loading() {
                return this.$store.getters['tipbox/getLoading']('tipbox') || this.$store.getters['tipbox/getLoading']('defaults');
            },
            busy() {
                return this.$store.getters['tipbox/getBusy']('tipbox');
            },
            error() {
                return this.$store.getters['tipbox/getError']('tipbox');
            },
            tipbox() {
                return this.$store.getters['tipbox/getTipbox'];
            },
            tipboxConfig() {
                if (!this.tipbox) return {};
                const attrs = {
                    'data-bb-space-id': this.tipbox.id,
                    'bb-preview': 'true',
                };
                /* eslint-disable prettier-vue/prettier */
                if (this.form.headerColor)      attrs['data-bb-header-color'] = this.form.headerColor;
                if (this.form.textColor)        attrs['data-bb-text-color'] = this.form.textColor;
                if (this.form.buttonColor)      attrs['data-bb-button-background-color'] = this.form.buttonColor;
                if (this.form.buttonTextColor)  attrs['data-bb-button-text-color'] = this.form.buttonTextColor;
                if (this.form.frameColor)       attrs['data-bb-frame-color'] = this.form.frameColor;
                if (this.form.backgroundColor)  attrs['data-bb-background-color'] = this.form.backgroundColor;
                if (this.form.header)           attrs['data-bb-header-label'] = this.form.header;
                if (this.form.description)      attrs['data-bb-description-label'] = this.form.description;
                if (this.form.thanks)           attrs['data-bb-thanks-label'] = this.form.thanks;
                if (this.form.amounts[0])       attrs['data-bb-amounts-1'] = this.form.amounts[0];
                if (this.form.amounts[1])       attrs['data-bb-amounts-2'] = this.form.amounts[1];
                if (this.form.amounts[2])       attrs['data-bb-amounts-3'] = this.form.amounts[2];
                if (this.form.icon)             attrs['data-bb-icon'] = this.form.icon;
                /* eslint-enable prettier-vue/prettier */
                return attrs;
            },
            tipboxCode() {
                // eslint-disable-next-line prettier-vue/prettier
                const attrs = [
                    `id="tipbox-${this.tipboxId}"`,
                    'class="bb-widget"',
                    'data-bb-type="tipbox"',
                    `data-bb-space-id="${this.tipbox.id}"`,
                ];
                const attrsStr = attrs.join(' ');
                const scriptTag = `<script src="${this.tipboxScriptUrl}"><\/script>`; // eslint-disable-line no-useless-escape
                const tipboxTag = `<div ${attrsStr}></div>`;
                return `${scriptTag}\n${tipboxTag}`;
            },
            iconsOptions() {
                return this.emojiCodes.map(emoji => ({ value: emoji, text: emoji }));
            },
        },
        watch: {
            space() {
                this.clearStoreData();
                this.$v.form.$reset();
                this.initFormData();
                this.fetchTipbox();
            },
            loading() {
                if (!this.loading) this.initFormData();
            },
        },
        methods: {
            initFormData() {
                const pickData = data => pick(data, Object.keys(this.formDefaults));
                this.form = merge({}, this.formDefaults, pickData(this.tipboxDefaults), pickData(this.tipbox));

                if (this.form.icon) {
                    this.form.icon = this.convertUnicodeToEmoji(this.form.icon);
                }
            },
            convertUnicodeToEmoji(str) {
                return str.replace(/\\u[\dA-F]{4}/gi, match => String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16)));
            },
            fetchTipboxDefaults() {
                return this.$store
                    .dispatch('tipbox/fetchDefaults')
                    .then(data => {
                        this.tipboxDefaults = merge({}, this.tipboxDefaults, data);
                        this.initFormData();
                    })
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            fetchTipbox() {
                return this.$store
                    .dispatch('tipbox/fetchTipbox', { urlParams: this.apiParams.urlParams })
                    .then(this.initFormData)
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            formSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$invalid) return;
                this.$store
                    .dispatch('tipbox/patchTipbox', this.apiParams)
                    .then(() => this.$toastr.success(this.$t('dataSavedSuccessfully')))
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            copyingConfirmation() {
                this.$toastr.success(this.$t('generator.copied'));
            },
            copyTipboxCodeAuto() {
                if (!this.tipboxCode) return;
                this.$copyText(this.tipboxCode)
                    .then(this.copyingConfirmation)
                    .catch(() => {});
            },
            copyTipboxCodeAutoManual(e) {
                if (!this.tipboxCode) return;
                if (e.target.id !== 'generated-code') return;
                const removeSpaces = string => string?.trim().replace(/(?:\r\n|\r|\n)/g, '');
                const selection = document.getSelection();
                if (removeSpaces(selection.toString()) === removeSpaces(this.tipboxCode)) this.copyingConfirmation();
            },
            clearStoreData() {
                this.$store.commit('tipbox/clearTipbox');
            },
        },
        created() {
            this.clearStoreData();
            this.fetchTipboxDefaults();
        },
        mounted() {
            document.addEventListener('copy', this.copyTipboxCodeAutoManual);
        },
        beforeDestroy() {
            document.removeEventListener('copy', this.copyTipboxCodeAutoManual);
            this.clearStoreData();
        },
    };
</script>

<style scoped>
    .max-w-sm {
        width: 124px;
    }
</style>