<template>
    <Card :header="$t('yourRecentlyGeneratedLinks')">
        <b-table
            hover
            :items="items"
            :fields="fields"
            show-empty
            :empty-text="$t('noResult')"
        >
            <template #cell(link)="data">
                <b-link :href="data.value" target="_blank">
                    {{ data.value }}
                </b-link>
            </template>
            <template #cell(action)="data">
                <ButtonIcon icon="copy" label-class="nowrap" @click="copyLink(data.item.link)">
                    {{ $t('textLinks.copyLink') }}
                </ButtonIcon>
            </template>
        </b-table>
    </Card>
</template>



<script>
    import ButtonIcon from '@/components/common/ButtonIcon.vue';

    export default {
        name: 'LastLinks',
        components: {
            ButtonIcon,
        },
        data() {
            return {
                fields: [
                    {
                        key: 'space.name',
                        label: this.$t('space'),
                    },
                    {
                        key: 'campaign.name',
                        label: this.$t('campaign'),
                    },
                    {
                        key: 'url',
                        label: this.$t('targetUrl'),
                    },
                    {
                        key: 'link',
                        label: this.$t('preparedTextLink'),
                    },
                    {
                        key: 'action',
                        label: '',
                    },
                ],
            };
        },
        computed: {
            items() {
                return this.$store.getters['linkGenerator/getLinksAsArray']();
            },
        },
        methods: {
            async copyLink(link) {
                await this.$copyText(link);
                this.$toastr.success(this.$t('textLinks.copied', { link }));
            },
        },
    };
</script>
