import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';

export const ENDPOINTS = deepFreeze({
    CATEGORIES: {
        URI: () => '/api/v1/categories',
        methods: ['get'],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
