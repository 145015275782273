<template>
    <div class="navbar navbar-expand flex-column flex-md-row navbar-custom">
        <div class="container-fluid flex-row d-flex justify-content-between">
            <h4 v-if="isRoleAdvertiser" :style="{ width: `${widthOfTopbar}px` }">
                {{ $t('shopPanel') }}
            </h4>

            <div>
                <app-logo />
                <app-100-logo v-if="isRoleAdvertiser" class="ml-4" />
            </div>

            <ul class="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0">
                <li class>
                    <button class="button-menu-mobile open-left disable-btn" :class="{ open: menuMobileOpened }" @click="toggleMenuMobileOpened">
                        <feather type="menu" class="menu-icon align-middle" />
                        <feather type="x" class="close-icon" />
                    </button>
                </li>
            </ul>

            <div ref="topbar" class="d-flex align-items-center">
                <div class="mx-2"></div>
                <AdminPanelButton />
                <div class="mx-2"></div>
                <ul class="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0">
                    <b-nav-item-dropdown right class="notification-list align-self-center" toggle-class="nav-user mr-0">
                        <template #button-content>
                            <div class="topbar__dropdown-button">
                                <div class="avatar">
                                    <span>{{ avatar }}</span>
                                </div>
                                <div v-if="isRoleAdmin" class="media-body text-left">
                                    <h6 class="topbar__name ml-2 my-0">
                                        <span>{{ email }}</span>
                                        <span class="text-muted d-block mt-1 topbar__role">{{ role }}</span>
                                    </h6>
                                </div>
                                <feather type="chevron-down" class="ml-2 align-self-center" />
                            </div>
                        </template>
                        <template v-for="drop in dropdownItems">
                            <b-dropdown-divider v-if="drop.divider" :key="`${drop.label}-divider`" />
                            <b-dropdown-item :key="drop.label" :to="drop.route" @click="drop.onClick && drop.onClick()">
                                <feather :type="drop.icon" class="icon-dual icon-xs mr-2 align-middle" />
                                <span>{{ $t(drop.label) }}</span>
                            </b-dropdown-item>
                        </template>
                    </b-nav-item-dropdown>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import AdminPanelButton from '@/components/common/AdminPanelButton.vue';
    import AppLogo from './AppLogo.vue';
    import App100Logo from './App100Logo.vue';
    import { isAdminRole } from '@/utils/util';

    export default {
        name: 'Topbar',
        components: {
            AppLogo,
            App100Logo,
            AdminPanelButton,
        },
        data() {
            return {
                widthOfTopbar: 0,
            };
        },

        computed: {
            isRoleWidgeter() {
                return this.$store.getters['auth/isRoleWidgeter'];
            },
            isRoleAdmin() {
                return this.$store.getters['auth/isRoleAdmin'];
            },
            isRoleAdvertiser() {
                return this.$store.getters['auth/isRoleAdvertiser'];
            },

            dropdownItems() {
                const items = [];
                if (!isAdminRole(this.getRoleName)) {
                    items.push({
                        label: 'widgeterDropdown.accountSettings',
                        icon: 'user',
                        route: { name: 'AccountSettings' },
                    });
                }
                items.push({
                    label: 'widgeterDropdown.logout',
                    icon: 'log-out',
                    divider: !isAdminRole(this.getRoleName),
                    onClick: this.logout,
                });
                return items;
            },
            avatar() {
                if (!this.user?.firstName && !this.user?.lastName) return 'W';
                return (this.user.firstName || '').slice(0, 1) + (this.user.lastName || '').slice(0, 1);
            },
            email() {
                return this.user ? this.user.email : '';
            },
            role() {
                return this.$te(`role.${this.getRoleShortName}`) ? this.$t(`role.${this.getRoleShortName}`) : '-';
            },
            ...mapGetters({
                userId: 'auth/userId',
                getRoleName: 'auth/getRoleName',
                getRoleShortName: 'auth/getRoleShortName',
            }),
            ...mapState({
                menuMobileOpened: state => state.layout.menuMobileOpened,
                user: state => state.auth.user,
            }),
        },
        methods: {
            ...mapMutations({
                toggleMenuMobileOpened: 'layout/toggleMenuMobileOpened',
            }),
            ...mapActions({
                logout: 'auth/logout',
            }),
        },

        mounted() {
            this.widthOfTopbar = this.$refs.topbar.offsetWidth + 280;
        },
    };
</script>
<style lang="scss">
    .topbar__dropdown-button {
        display: flex;
        align-items: center;
    }

    .topbar__name {
        font-weight: 700;
        color: #4b4b5a;
    }

    .topbar__role {
        font-size: 0.6rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .noti-scroll {
        height: 220px;
    }
    .ps > .ps__scrollbar-y-rail {
        width: 8px !important;
        background-color: transparent !important;
    }
    .ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
    .ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
    .ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
    .ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
        width: 6px !important;
    }
    .button-menu-mobile {
        outline: none !important;
    }
    .avatar {
        background: #00aeef;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        color: #fff;
        font-weight: bold;
        line-height: 1;

        span {
            display: block;
            height: 12px;
        }
    }
    .ak-trigger,
    .ak-trigger:hover {
        color: $gray-700;
        font-weight: 600;
    }
</style>
