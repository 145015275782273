import store from '../store/store';
import errorService from './errorService';
import apiService from './apiService';

export default {
    async fetchStat({ userId, space, campaign, startDate, endDate, groupBy }) {
        try {
            const userRoleShort = store.getters['auth/getRoleShortName'];
            const urlByRole = {
                widgeter: `${process.env.VUE_APP_API_URL}/api/v1/publisher/${userId}/general-stats`,
                advertiser: `${process.env.VUE_APP_API_URL}/api/v1/reporters/${userId}/general-stats`,
            };
            const url = urlByRole[userRoleShort];
            const response = await apiService.get(url, {
                spaces: space,
                campaigns: campaign,
                startDate,
                endDate,
                groupBy,
            });
            return response.data
                .sort((a, b) => a.date < b.date)
                .map(statItem => ({
                    ...statItem,
                    space,
                    campaign,
                }));
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },
};
