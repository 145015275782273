<template>
    <div>
        <page-header>
            {{ $t('finance.title') }}
        </page-header>

        <error-message :value="commissionsError" />
        <error-message :value="userProfileError" />

        <div class="row">
            <div class="col-xl-4 col-md-6 mb-4">
                <WidgetStat :title="$t('finance.newCommission')" :value="commissionsFormatted.openFormatted" :loading="fetchCommissionsLoading" />
            </div>
            <div class="col-xl-4 col-md-6 mb-4">
                <WidgetStat :title="$t('finance.acceptedCommission')" :value="commissionsFormatted.acceptedFormatted" :loading="fetchCommissionsLoading" />
            </div>
            <div class="col-xl-4 col-md-6 mb-4">
                <WidgetStat :title="$t('finance.toPayCommission')" :value="commissionsFormatted.toPaidFormatted" :loading="fetchCommissionsLoading">
                    <router-link
                        v-if="isPaymentAvailable && user.isCompany === true"
                        :to="{ name: 'PaymentGenerator' }"
                    >
                        <button-default
                            class="ml-2"
                            variant="success"
                            short
                        >
                            {{ $t('finance.payOut') }}
                        </button-default>
                    </router-link>
                    <button-default
                        v-if="isPaymentAvailable && user.isCompany === false"
                        class="ml-2"
                        variant="success"
                        short
                        @click="handlePayOutPrivate"
                    >
                        {{ $t('finance.payOut') }}
                    </button-default>
                    <payment-generator-private v-if="isPaymentAvailable && user.isCompany === false" v-model="privateGeneratorModalVisible" @done="handlePayOutComplete" />
                </WidgetStat>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mb-4">
                <FinanceChart :loading="fetchFinanceDocumentsLoading" :error="financeDocumentsError" :series="chartSeries" />
            </div>
        </div>

        <space-provider>
            <card :header="$t('finance.paymentHistory')" :loading="fetchFinanceDocumentsLoading">
                <error-message v-model="financeDocumentsError" />
                <table-default
                    :items="financeDocumentsItems"
                    :headers="[
                        {
                            label: $t('finance.date'),
                            value: (item) => $format.date(item.createdAt),
                        },
                        {
                            label: $t('finance.documentNumber'),
                            slot: 'documentNumber',
                            align: 'left',
                        },
                        {
                            label: $t('finance.paymentDate'),
                            slot: 'paymentDate',
                            align: 'left',
                        },
                        {
                            label: $t('finance.amount'),
                            value: (item) => $format.currency(item.net),
                            align: 'left',
                        },
                        {
                            label: $t('finance.status'),
                            slot: 'status',
                            align: 'left',
                        },
                        {
                            label: $t('finance.transactions'),
                            slot: 'transactions',
                            align: 'left',
                        },
                    ]"
                >
                    <template #documentNumber="{ item }">
                        {{ item.number }}
                        <button-icon
                            v-if="canDownload(item)"
                            :disabled="!!isDownloadingDocument"
                            :loading="isDownloadingDocument === item.id"
                            class="float-right"
                            size="sm"
                            variant="link"
                            icon="file-text"
                            @click="downloadDocument(item)"
                        />
                    </template>
                    <template #paymentDate="{ item }">
                        {{ $format.date(item.paymentDate) }}
                        <b-progress
                            v-if="item.paymentDateProgressType"
                            :value="item.paymentDateProgress"
                            :max="paymentTimeDays"
                            :variant="item.paymentDateProgressType"
                        />
                    </template>
                    <template #status="{ item }">
                        <finance-document-status-badge :status="item.status" />
                    </template>
                    <template #transactions="{ item }">
                        <router-link
                            v-if="hasTransactions(item)"
                            :to="{
                                name: 'ReportsTransactions',
                                query: { financeDocument: item.id },
                            }"
                        >
                            {{ $t('finance.showTransactions') }}
                        </router-link>
                    </template>
                </table-default>
            </card>
        </space-provider>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { startOfMonth, getTime } from 'date-fns';
    import { FinanceDocumentStatus, PAYOUT_THRESHOLD, translateFinanceDocumentStatus } from '@/services/financeService';
    import { generateChartData } from '@/utils/chart';
    import dateUtil from '@/utils/date';
    import PageHeader from '@/components/common/PageHeader.vue';
    import Card from '@/components/common/Card.vue';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import TableDefault from '@/components/common/TableDefault.vue';
    import SpaceProvider from '@/components/common/SpaceProvider.vue';
    import WidgetStat from '@/components/common/WidgetStat.vue';
    import ButtonIcon from '@/components/common/ButtonIcon.vue';
    import toastService from '@/services/toastService';
    import exportService from '@/services/exportService';
    import FinanceChart from './parts/FinanceChart.vue';
    import FinanceDocumentStatusBadge from '@/components/common/FinanceDocumentStatusBadge.vue';
    import PaymentGeneratorPrivate from './parts/PaymentGeneratorPrivate.vue';

    export default {
        name: 'Finance',
        components: {
            SpaceProvider,
            PaymentGeneratorPrivate,
            FinanceDocumentStatusBadge,
            TableDefault,
            ButtonDefault,
            ErrorMessage,
            PageHeader,
            Card,
            WidgetStat,
            FinanceChart,
            ButtonIcon,
        },
        data() {
            return {
                paymentTimeDays: 21,
                commissionsError: null,
                financeDocumentsError: null,
                userProfileError: null,
                privateGeneratorModalVisible: false,
                isCompany: null,
                isDownloadingDocument: false,
            };
        },
        computed: {
            commissionsFormatted() {
                if (!this.commissions) {
                    return {};
                }
                return {
                    ...this.commissions,
                    openFormatted: this.$format.currency(this.commissions.open),
                    acceptedFormatted: this.$format.currency(this.commissions.accepted),
                    toPaidFormatted: this.$format.currency(this.commissions.toPaid),
                };
            },
            financeDocumentsItems() {
                const now = new Date();
                return this.financeDocuments
                    .map(item => {
                        const paymentDate = dateUtil.addDays(item.createdAt, this.paymentTimeDays);
                        const daysLeft = dateUtil.differenceInDays(paymentDate, now);
                        let paymentDateProgress;
                        let paymentDateProgressType;
                        if (![FinanceDocumentStatus.REJECTED, FinanceDocumentStatus.PAID].includes(item.status.toString())) {
                            if (daysLeft < 0) {
                                paymentDateProgress = 100;
                                paymentDateProgressType = 'danger';
                            } else {
                                paymentDateProgress = dateUtil.differenceInDays(now, item.createdAt);
                                paymentDateProgressType = 'success';
                            }
                        }

                        return {
                            ...item,
                            statusFormatted: translateFinanceDocumentStatus(item.status.toString()),
                            paymentDate,
                            paymentDateProgress,
                            paymentDateProgressType,
                        };
                    })
                    .reverse();
            },
            isPaymentAvailable() {
                return this.commissions && this.commissions.toPaid >= PAYOUT_THRESHOLD && !this.fetchUserProfileLoading && !this.userProfileError && this.user;
            },
            chartSeries() {
                if (!this.financeDocuments?.length) return [];
                const sortData = [...this.financeDocuments].sort((a, b) => new Date(a.createdAt) > new Date(b.createdAt));
                const chartData = sortData.reduce((acc, currentVal) => {
                    if (!this.hasTransactions(currentVal)) return acc;
                    const timestamp = getTime(startOfMonth(new Date(currentVal.createdAt)));
                    const value = currentVal.net;
                    const found = acc.find(item => item.date === timestamp);
                    if (found) {
                        found.value += value;
                    } else {
                        acc.push({ date: timestamp, value });
                    }
                    return acc;
                }, []);
                const timeRange = { startDate: sortData[0].createdAt, endDate: new Date() };
                const data = generateChartData(timeRange, chartData, 'month').map(item => [item.date, item.value]);
                return [{ data }];
            },
            ...mapState({
                fetchCommissionsLoading: state => state.finance.loading.fetchCommissions,
                fetchFinanceDocumentsLoading: state => state.finance.loading.fetchFinanceDocuments,
                commissions: state => state.finance.commissions,
                financeDocuments: state => state.finance.financeDocuments,
                user: state => state.auth.user,
                fetchUserProfileLoading: state => state.auth.loading.fetchUserProfile,
            }),
        },
        methods: {
            async getCommissions() {
                try {
                    this.commissionsError = null;
                    await this.fetchCommissions();
                } catch (e) {
                    this.commissionsError = e.message;
                }
            },
            async getFinanceDocuments() {
                try {
                    this.financeDocumentsError = null;
                    await this.fetchFinanceDocuments();
                } catch (e) {
                    this.financeDocumentsError = e.message;
                }
            },
            async getAccountSettings() {
                try {
                    this.userProfileError = null;
                    await this.fetchUserProfile();
                } catch (e) {
                    this.userProfileError = e.message;
                }
            },
            hasTransactions(item) {
                return [FinanceDocumentStatus.NEW, FinanceDocumentStatus.ACCEPTED, FinanceDocumentStatus.PAID].includes(item.status.toString());
            },
            handlePayOutPrivate() {
                this.privateGeneratorModalVisible = true;
            },
            handlePayOutComplete() {
                this.getCommissions();
                this.getFinanceDocuments();
            },
            canDownload(item) {
                return item.downloadable && this.user.isCompany === false;
            },
            async downloadDocument(item) {
                try {
                    this.isDownloadingDocument = item.id;
                    await exportService.exportDataAuthorized(`${process.env.VUE_APP_API_URL}/api/v1/finance-documents/${item.id}/download`, item.number, 'pdf');
                } catch (e) {
                    toastService.errorToast(e.message);
                } finally {
                    this.isDownloadingDocument = null;
                }
            },
            ...mapActions({
                fetchCommissions: 'finance/fetchCommissions',
                fetchFinanceDocuments: 'finance/fetchFinanceDocuments',
                fetchUserProfile: 'auth/fetchUserProfile',
            }),
        },
        created() {
            this.getCommissions();
            this.getFinanceDocuments();
            this.getAccountSettings();
        },
    };
</script>

<style lang="scss" scoped>
    .commission-value {
        margin-bottom: 0.25rem;
        font-size: 28px;
    }

    .commission-label {
        font-size: 18px;
        color: $text-muted;
    }
</style>
