<!-- eslint-disable max-len -->
<template>
    <div>
        <page-header>
            <template #side>
                <ButtonIcon class="mr-2" @click="$router.go(-1)"> {{ $t('widgetSettings.goBack') }}</ButtonIcon>
            </template>

            {{ $t('widgetSettings.configuration') }} <template v-if="!isEditingName">"{{ model.name }}"</template>
            <template v-else><input v-model="model.name" type="text" class="form-control name-input"></template> ({{ currentBuybox.space.url }})
            <button class="btn btn-link" @click="toggleNameEditing()">{{ isEditingName ? $t('widgetSettings.save') : $t('widgetSettings.edit') }}</button>
        </page-header>

        <a href="" target="_blank">
            <p class="widget-description">
                <svg
                    version="1.2"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    overflow="visible"
                    preserveAspectRatio="none"
                    viewBox="0 0 24 24"
                    width="29"
                    height="29"
                >
                    <g>
                        <path
                            id="file-text-o"
                            xmlns:default="http://www.w3.org/2000/svg"
                            d="M16,3.38c-0.23-0.22-0.5-0.39-0.79-0.5c-0.29-0.13-0.6-0.2-0.92-0.21H5c-0.55,0-1,0.45-1,1v16.66  c0,0.55,0.45,1,1,1h14c0.55,0,1-0.45,1-1v-12c-0.01-0.32-0.08-0.63-0.21-0.92c-0.11-0.29-0.28-0.56-0.5-0.79L16,3.38z M15.05,4.32  l3.26,3.26c0.11,0.13,0.19,0.27,0.23,0.43h-3.87V4.09c0.15,0.05,0.3,0.12,0.42,0.23H15.05z M18.66,20H5.34V4h8v4.34  c0,0.55,0.45,1,1,1h4.33V20l0,0H18.66z M8,11v0.67C7.99,11.85,8.13,11.99,8.31,12c0.01,0,0.01,0,0.02,0h7.33  c0.18,0.01,0.33-0.12,0.34-0.3c0-0.01,0-0.02,0-0.03V11c0.01-0.18-0.13-0.32-0.31-0.33c-0.01,0-0.01,0-0.02,0H8.34  c-0.18-0.01-0.33,0.12-0.34,0.3C8,10.98,8,10.99,8,11L8,11z M8.33,13.33C8.15,13.32,8.01,13.46,8,13.64c0,0.01,0,0.01,0,0.02v0.67  c-0.01,0.18,0.13,0.32,0.31,0.33c0.01,0,0.01,0,0.02,0h7.33c0.18,0.01,0.33-0.12,0.34-0.3c0-0.01,0-0.02,0-0.03v-0.66  c0.01-0.18-0.13-0.32-0.31-0.33c-0.01,0-0.01,0-0.02,0H8.34L8.33,13.33z M8.33,16C8.15,15.99,8.01,16.13,8,16.31  c0,0.01,0,0.01,0,0.02V17c-0.01,0.18,0.13,0.32,0.31,0.33c0.01,0,0.01,0,0.02,0h7.33c0.18,0.01,0.33-0.12,0.34-0.3  c0-0.01,0-0.02,0-0.03v-0.67c0.01-0.18-0.13-0.32-0.31-0.33c-0.01,0-0.02,0-0.03,0H8.34H8.33z"
                            style="fill: rgb(83, 105, 248)"
                            vector-effect="non-scaling-stroke"
                        />
                    </g>
                </svg>
                {{ $t('widgetSettings.description') }}
            </p>
        </a>

        <space-provider>
            <card :loading="pluginsLoading">
                <error-message v-model="error" />

                <!-- Selector -->
                <section class="tab-selector">
                    <button v-if="buyboxId" class="tab-selector-button" :class="{ active: currentTab == 'campaigns' }" @click="currentTab = 'campaigns'">
                        {{ $t('widgetSettings.campaigns') }}
                    </button>
                    <button class="tab-selector-button" :class="{ active: currentTab == 'appearance' }" @click="currentTab = 'appearance'">
                        {{ $t('widgetSettings.appearance') }}
                    </button>
                </section>

                <!-- Campaigns -->
                <section v-if="currentTab == 'campaigns'" class="row">
                    <div class="d-flex">
                        <card class="flex-grow-1 campaigns-list" :header="$t('widgetSettings.campaigns')" :sub-header="campaignsListSubHeader">
                            <campaigns-list :plugin-id="buyboxId" />
                        </card>
                    </div>
                </section>

                <!-- Appearance -->
                <section v-if="currentTab == 'appearance'" class="row">
                    <div class="col-xl-12 max-w- d-flex flex-column">
                        <card
                            class="flex-grow-1 border-b"
                            :header="$t('widgetSettings.colors')"
                            :sub-header="$t('widgetSettings.appearanceSubheader')"
                            :loading="pluginsLoading || sortTypesLoading"
                        >
                            <color-field
                                v-model="model.leadColor"
                                :label="$t('widgetSettings.leadColor')"
                                :sublabel="$t('widgetSettings.appearanceSubheader')"
                                :validation="$v.model.leadColor"
                            />
                        </card>

                        <card
                            class="flex-grow-1 border-b"
                            :header="$t('widgetSettings.offersList')"
                            :sub-header="$t('widgetSettings.appearanceSubheader')"
                            :loading="pluginsLoading || sortTypesLoading"
                        >
                            <div class="row">
                                <div class="col-xl-6 d-flex flex-column">
                                    <select-field
                                        v-model="model.shopStyle"
                                        :sublabel="$t('widgetSettings.appearanceSubheader')"
                                        :label="$t('widgetSettings.shopName')"
                                        :options="shopStyleOptions"
                                        :clearable="false"
                                    />

                                    <select-field
                                        v-if="sortTypeOptions.length > 0"
                                        v-model="model.sortType"
                                        :sublabel="$t('widgetSettings.appearanceSubheader')"
                                        :label="$t('widgetSettings.sortType')"
                                        :options="sortTypeOptions"
                                    />

                                    <select-field
                                        v-model="model.showPrices"
                                        :sublabel="$t('widgetSettings.appearanceSubheader')"
                                        :label="$t('widgetSettings.prices')"
                                        :options="[
                                            { text: $t('widgetSettings.showPrices'), value: true },
                                            { text: $t('widgetSettings.hidePrices'), value: false },
                                        ]"
                                    />
                                </div>

                                <div class="col-xl-6 d-flex flex-column">
                                    <text-field
                                        v-model="model.buttonLabel"
                                        :sublabel="$t('widgetSettings.appearanceSubheader')"
                                        :label="$t('widgetSettings.buttonLabel', { maxLength: buttonLabelMaxLength })"
                                        :validation="$v.model.buttonLabel"
                                        :maxlength="buttonLabelMaxLength"
                                    />

                                    <text-field
                                        v-model="model.rowCount"
                                        :sublabel="$t('widgetSettings.appearanceSubheader')"
                                        :label="$t('widgetSettings.rowCount')"
                                        type="number"
                                        :validation="$v.model.rowCount"
                                    />
                                </div>
                            </div>
                        </card>

                        <card
                            class="flex-grow-1 border-b"
                            :header="$t('widgetSettings.additionalSettings')"
                            :sub-header="$t('widgetSettings.appearanceSubheader')"
                            :loading="pluginsLoading || sortTypesLoading"
                        >
                            <div class="row">
                                <div class="col-xl-6 d-flex flex-column">
                                    <select-field
                                        v-model="model.showProduct"
                                        :sublabel="$t('widgetSettings.appearanceSubheader')"
                                        :label="$t('widgetSettings.productDetails')"
                                        :options="[
                                            { text: $t('widgetSettings.showProduct'), value: true },
                                            { text: $t('widgetSettings.hideProduct'), value: false },
                                        ]"
                                    />

                                    <select-field
                                        v-model="model.version"
                                        :sublabel="$t('widgetSettings.appearanceSubheader')"
                                        :label="$t('widgetSettings.version')"
                                        :options="versionOptions"
                                        :clearable="false"
                                    />
                                </div>

                                <div class="col-xl-6 d-flex flex-column">
                                    <select-field
                                        v-model="model.language"
                                        :sublabel="$t('widgetSettings.appearanceSubheader')"
                                        :label="$t('widgetSettings.language')"
                                        :options="languageOptions"
                                        :clearable="false"
                                        class="invisible"
                                    />

                                    <select-field
                                        v-model="model.language"
                                        :sublabel="$t('widgetSettings.appearanceSubheader')"
                                        :label="$t('widgetSettings.language')"
                                        :options="languageOptions"
                                        :clearable="false"
                                    />
                                </div>
                            </div>
                        </card>

                        <card
                            v-if="multipleFormatsAvailable"
                            class="flex-grow-1"
                            :header="$t('widgetSettings.formats')"
                            :sub-header="$t('widgetSettings.appearanceSubheader')"
                            :loading="pluginsLoading || sortTypesLoading"
                        >
                            <div class="row">
                                <div class="col-xl-6">
                                    <select-field
                                        v-model="model.isTab"
                                        :sublabel="$t('widgetSettings.appearanceSubheader')"
                                        :label="$t('widgetSettings.tabsTitle')"
                                        :options="[
                                            { text: $t('widgetSettings.showTabs'), value: true },
                                            { text: $t('widgetSettings.hideTabs'), value: false },
                                        ]"
                                    />
                                </div>
                            </div>

                            <!-- Formats -->
                            <div v-for="formatCategory in formats" :key="formatCategory.label" class="row mt-4">
                                <div class="col-xl-6 d-flex flex-column">
                                    <multi-select-field
                                        v-model="formatCategory.selected"
                                        :sublabel="$t('widgetSettings.appearanceSubheader')"
                                        :label="$t('widgetSettings.formats') + ' - ' + formatCategory.label"
                                        :options="formatCategory.formats"
                                    />
                                </div>

                                <div class="col-xl-6 d-flex flex-column">
                                    <select-field
                                        v-model="formatCategory.default"
                                        variant="checkbox"
                                        :sublabel="$t('widgetSettings.appearanceSubheader')"
                                        :label="$t('widgetSettings.defaultFormat') + ' - ' + formatCategory.label"
                                        :options="[...formatCategory.formats, { value: null, text: $t('widgetSettings.all') }]"
                                    />
                                </div>
                            </div>
                        </card>
                    </div>
                </section>

                <section class="submit-buttons">
                    <button-default :loading="submitting" @click="handleSaveBuybox">
                        {{ $t('save') }}
                    </button-default>
                </section>
            </card>
        </space-provider>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
    import { debounce } from 'lodash';
    import ErrorMessage from '@/components/common/ErrorMessage.vue';
    import validationService from '@/services/validationService';
    import Card from '@/components/common/Card.vue';
    import TextField from '@/components/common/TextField.vue';
    import ColorField from '@/components/common/ColorField.vue';
    import SelectField from '@/components/common/SelectField.vue';
    import MultiSelectField from '@/components/common/MultiSelectField.vue';
    import SpaceProvider from '@/components/common/SpaceProvider.vue';
    import PageHeader from '@/components/common/PageHeader.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    // import TextareaField from '@/components/common/TextareaField';
    import { translateWidgetVersion, widgetVersions } from '@/services/pluginService';
    import { getLanguageFromLocale, getLanguageOptions, i18n } from '@/i18n/i18n';
    import CampaignsList from './parts/CampaignsList.vue';

    const BUTTON_LABEL_MAX_LENGTH = 15;
    const NAME_MAX_LENGTH = 255;
    const ADDITIONAL_COLUMN_MAX_LENGTH = 65535;

    export default {
        name: 'WidgeterBuyboxSettings',
        components: {
            // TextareaField,
            ErrorMessage,
            ButtonDefault,
            PageHeader,
            SpaceProvider,
            CampaignsList,
            TextField,
            ColorField,
            MultiSelectField,
            SelectField,
            Card,
        },
        props: {
            buyboxId: {
                type: Number,
                required: false,
            },
        },
        data() {
            return {
                error: null,
                saved: false,
                currentTab: this.buyboxId ? 'campaigns' : 'appearance',
                isEditingName: false,

                model: {
                    showProduct: true,
                    shopStyle: undefined,
                    showPrices: false,
                    leadColor: '',
                    buttonLabel: '',
                    format: [],
                    isTab: false,
                    tabDefault: null,
                    rowCount: 0,
                    showAdditionalColumn: false,
                    additionalColumn: '',
                    additionalColumnOnlyForSorted: false,
                    version: 'latest',
                    language: null,
                    sortType: undefined,
                },
                languageOptions: getLanguageOptions(true),
                buttonLabelMaxLength: BUTTON_LABEL_MAX_LENGTH,
                nameMaxLength: NAME_MAX_LENGTH,
                additionalColumnMaxLength: ADDITIONAL_COLUMN_MAX_LENGTH,
                campaignsListSubHeader: '',
                campaignListHeight: undefined,
                widgetPreviewData: {},
                isInitialised: false,

                formats: [],
            };
        },
        validations: {
            model: {
                buttonLabel: {
                    maxLength: maxLength(BUTTON_LABEL_MAX_LENGTH),
                },
                name: { required, maxLength: maxLength(NAME_MAX_LENGTH) },
                leadColor: { required, hexColor: validationService.hexColor },
                rowCount: { required },
                additionalColumn: {
                    maxLength: maxLength(ADDITIONAL_COLUMN_MAX_LENGTH),
                    required: requiredIf(model => model.showAdditionalColumn),
                },
                version: { required },
                language: { required },
            },
        },
        computed: {
            ...mapGetters({
                currentSpace: 'space/currentSpace',
            }),
            currentBuybox() {
                if (!this.plugins || !this.buyboxId) {
                    return {
                        name: 'Buybox',
                        sortType: 1,
                        format: [],
                        formats: [],
                        isTab: true,
                        tabDefault: null,
                        category: this.currentSpace?.categories?.[0],
                        leadColor: '#000000',
                        showProduct: true,
                        default: false,
                        shopStyle: 'icon_name',
                        version: 'latest',
                        showPrices: true,
                        rowCount: 10,
                        additionalColumnOnlyForSorted: false,
                        space: this.currentSpace,
                    };
                }

                return this.plugins[this.buyboxId];
            },
            currentBuyboxCategory() {
                return this.currentBuybox ? this.currentBuybox.category.name : '-';
            },
            currentBuyboxSpace() {
                return this.currentBuybox ? this.currentBuybox.space.name : '-';
            },
            currentBuyboxFormats() {
                return (this.currentBuybox && this.currentBuybox.category && this.formatOptions(this.currentBuybox.category.id)) || [];
            },
            multipleFormatsAvailable() {
                return this.currentBuyboxFormats.length > 1;
            },
            versionOptions() {
                return widgetVersions.map(version => ({
                    value: version,
                    text: translateWidgetVersion(version),
                }));
            },
            sortTypeOptions() {
                if (this.sortTypesIds) {
                    return this.sortTypesIds.map(sortTypeId => ({
                        value: this.sortTypes[sortTypeId].value,
                        text: this.sortTypes[sortTypeId].label,
                    }));
                }
                return [];
            },
            ...mapState({
                currentSpaceId: state => state.space.currentSpaceId,
                plugins: state => state.plugin.plugins,
                pluginsLoading: state => state.plugin.loading.fetchPlugins,
                sortTypes: state => state.plugin.sortTypes,
                sortTypesIds: state => state.plugin.sortTypesIds,
                sortTypesLoading: state => state.plugin.loading.fetchSortTypes,
                submitting: state => state.plugin.loading.updateAppearance,
            }),
            showWidgetPreview() {
                return !!this.product;
            },
            typesAndFormats() {
                return this.$store.getters['offers/getTypesAndFormats'];
            },
            typesAndFormatsAndFormats() {
                return this.currentBuybox + this.typesAndFormats;
            },
            ...mapGetters({
                shopStyleOptions: 'options/shopStyleOptions',
                formatOptions: 'options/formatOptions',
            }),
        },
        watch: {
            currentSpaceId: {
                handler(spaceId) {
                    if (spaceId) {
                        this.fetchPlugins({ spaceId });
                    }
                    this.saveWidgetPreviewData();
                },
                immediate: true,
            },
            currentBuybox: {
                handler(currentBuybox) {
                    if (currentBuybox) {
                        this.setBuyboxFields(currentBuybox);
                        if (currentBuybox.category) {
                            this.fetchFormatsOfCategory({ categoryId: currentBuybox?.category?.id });
                        }
                    }
                },
                immediate: true,
            },
            'model.showAdditionalColumn': function watchShowAdditionalColumn(showAdditionalColumn) {
                if (!showAdditionalColumn) {
                    this.$set(this.model, 'additionalColumn', '');
                    this.$set(this.model, 'additionalColumnOnlyForSorted', false);
                }
            },
            pluginsLoading: {
                handler(loading, prevLoading) {
                    if (prevLoading === true && loading === false) {
                        this.$nextTick(this.handleResize);
                    }
                },
                immediate: true,
            },
            widgetPreviewData: {
                handler: 'saveWidgetPreviewData',
            },
            model: {
                // immediate: true,
                deep: true,
                handler: 'initWidgetPreviewData',
            },
            typesAndFormatsAndFormats: {
                handler() {
                    try {
                        this.formats = this.typesAndFormats.map(typeAndFormat => ({
                            ...typeAndFormat,
                            formats: typeAndFormat.formats.map(format => ({
                                value: format.id,
                                text: format.categoryName,
                            })),
                            selected: this.currentBuybox && this.currentBuybox.format ? typeAndFormat.formats.filter(format => this.currentBuybox.format.includes(format.id)).map(format => format.id) : [],
                            default: this.currentBuybox ? typeAndFormat.formats.filter(format => this.currentBuybox.format.includes(format.id)).map(format => format.id)[0] : null,
                        }));
                    } catch (e) {
                        console.error(e);
                    }
                },
                immediate: true,
            },
        },
        methods: {
            initWidgetPreviewData() {
                const config = this.model;
                this.widgetPreviewData = {
                    ...this.widgetPreviewData,
                    'bb-id': this.buyboxId || 0,
                    'bb-offer-id': this.widgetPreviewData.product?.offerId || 0,
                    'bb-type': 'widget',
                    'bb-lead-color': config.leadColor,
                    'bb-button-label': config.buttonLabel,
                    'bb-show-extra': config.showProduct ?? true,
                    'bb-show-prices': config.showPrices ?? true,
                    'bb-row-count': config.rowCount ?? 10,
                    'bb-info': '',
                };
            },
            toggleNameEditing() {
                if (this.isEditingName) {
                    this.handleSaveBuybox();
                }
                this.isEditingName = !this.isEditingName;
            },

            fetchTypesAndFormats() {
                this.$store.dispatch('offers/fetchTypesAndFormats').catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            setBuyboxFields(buybox) {
                if (this.saved) {
                    return;
                }

                this.model = {
                    name: buybox.name,
                    showProduct: buybox.showProduct || false,
                    shopStyle: buybox.shopStyle,
                    showPrices: buybox.showPrices || false,
                    leadColor: buybox.leadColor,
                    buttonLabel: buybox.buttonLabel || '',
                    format: Object.values(buybox.format),
                    isTab: buybox.isTab || false,
                    tabDefault: buybox.tabDefault || null,
                    rowCount: buybox.rowCount,
                    showAdditionalColumn: Boolean(buybox.additionalColumn),
                    additionalColumn: buybox.additionalColumn,
                    additionalColumnOnlyForSorted: buybox.additionalColumnOnlyForSorted,
                    version: buybox.version || 'latest',
                    language: buybox.language || getLanguageFromLocale(i18n.locale),
                    sortType: buybox.sortType ?? this.sortTypesIds?.[0],
                };
            },
            async handleSaveBuybox() {
                try {
                    this.saved = true;
                    this.error = null;
                    this.$v.$touch();
                    if (this.$v.$invalid) {
                        return;
                    }

                    let data;

                    if (this.multipleFormatsAvailable) {
                        data = this.model;

                        // add up every selected format to the formsts array from selected, also add every default to defaultFormats array
                        const formats = [];
                        const defaultFormats = [];

                        this.formats.forEach(formatCategory => {
                            (formatCategory.selected || []).forEach(format => {
                                formats.push(format);
                            });
                            defaultFormats.push(formatCategory.default ? formatCategory.default : null);
                        });

                        data.formats = formats;
                        data.defaultFormats = defaultFormats.filter(format => format !== null);

                        delete data.format;
                        delete data.tabDefault;
                    } else {
                        const { format, isTab, tabDefault, ...modelWithoutFormatSettings } = this.model;
                        data = modelWithoutFormatSettings;
                    }
                    const { showAdditionalColumn, ...filteredData } = data;
                    delete filteredData.tabDefault;

                    if (this.buyboxId) {
                        await this.updateAppearance({
                            buyboxId: this.buyboxId,
                            ...filteredData,
                        });
                    } else {
                        await this.createAppearance({
                            spaceId: this.currentSpaceId,
                            ...filteredData,
                        });

                        this.$router.push({ name: 'WidgetSettings' });
                    }
                } catch (e) {
                    this.saved = false;
                    this.error = e.message;
                }
            },
            handleResize() {
                this.campaignsListSubHeader = this.$t(window.innerWidth < 600 ? 'widgetSettings.campaignsSubheaderMobile' : 'widgetSettings.campaignsSubheader');
            },
            saveWidgetPreviewData() {
                const wpData = this.$localStorage.get('widget-preview', null);
                if (JSON.stringify(this.widgetPreviewData) !== JSON.stringify(wpData)) this.$localStorage.set('widget-preview', this.widgetPreviewData);
            },
            removeWidgetPreviewData() {
                this.$localStorage.remove('widget-preview');
            },
            onStorageUpdate(event) {
                if (event.key === 'buybox-widget-preview' && JSON.stringify(this.widgetPreviewData) !== event.newValue) this.updateData();
            },
            updateData() {
                const wpData = this.$localStorage.get('widget-preview', null);
                if (wpData) {
                    this.widgetPreviewData = { ...this.widgetPreviewData, ...wpData };
                    this.$set(this.model, 'showProduct', wpData['bb-show-extra']);
                    this.$set(this.model, 'showPrices', wpData['bb-show-prices']);
                    this.$set(this.model, 'leadColor', wpData['bb-lead-color']);
                    this.$set(this.model, 'buttonLabel', wpData['bb-button-label']);
                    this.$set(this.model, 'rowCount', wpData['bb-row-count']);
                }
            },
            openWidgetPreview() {
                const url = this.$router.resolve({ name: 'WidgetPreview' }).href;
                window.open(url, 'widget-preview');
            },
            ...mapActions({
                fetchPlugins: 'plugin/fetchPlugins',
                fetchSortTypes: 'plugin/fetchSortTypes',
                fetchFormatsOfCategory: 'options/fetchFormatsOfCategory',
                updateAppearance: 'plugin/updateAppearance',
                createAppearance: 'plugin/createAppearance',
            }),
        },
        created() {
            this.updateData();
        },
        mounted() {
            this.fetchSortTypes();
            window.addEventListener('resize', debounce(this.handleResize, 100));
            this.handleResize();
            window.addEventListener('storage', this.onStorageUpdate);
            this.fetchTypesAndFormats();
        },
        beforeDestroy() {
            this.removeWidgetPreviewData();
            window.removeEventListener('storage', this.onStorageUpdate);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
    };
</script>

<style lang="scss" scoped>
    .tab-selector {
        display: flex;
        margin-bottom: 12px;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        height: 61px;
        padding: 0 28px;
        border-radius: 5px;
        background-color: #f4f4f4;
    }

    .border-b {
        border-bottom: 1px solid #f6f6ff;
    }

    .max-w-900 {
        max-width: 900px;
    }

    .tab-selector-button {
        border: none;
        width: 171px;
        height: 42px;
        font-size: 16px;
        color: #000000;
        text-align: center;
        background-color: #ffff;
        border-radius: 5px;

        &:not(.active) {
            background-color: #f4f4f4;
        }

        &:hover {
            background-color: #ffff;
        }
    }

    .submit-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        gap: 10px;
    }

    .widget-description {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: -12px;
        color: #5369f8;
    }

    .name-input {
        max-width: 300px;
        display: inline-block;
    }

    @media (min-width: 600px) {
        .card.campaigns-list {
            ::v-deep {
                .card-body {
                    flex-direction: column;
                    display: flex;

                    .mt-4 {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;

                        .container {
                            flex-grow: 1;

                            .drag-container {
                                .drag-box {
                                    flex-grow: 1;
                                    flex-basis: 500px;
                                    max-height: unset;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
