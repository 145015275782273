<template>
    <div>
        <PageHeader :items="breadcrumb">
            <span v-if="product">{{ product.name }}</span>
            <b-skeleton v-if="!product && !error" animation="wave" width="45%" height="25px" class="mb-0" />
            <template #side>
                <div v-if="!product && error">
                    <strong>{{ $t('goBackTo') }}</strong>: <b-link :to="parentRoute">
                        {{ $t(parentRoute.meta.title) }}
                    </b-link>
                </div>
                <b-skeleton v-if="!product && !error" animation="wave" width="100%" class="mb-0 mt-1 width-300" />
            </template>
        </PageHeader>
        <ErrorMessage v-if="!product && error" :value="error" />
        <b-row v-else>
            <b-col xs="12" sm="12" lg="6">
                <b-row>
                    <b-col class="mb-4">
                        <Preview ref="Preview" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <Details ref="Details" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <Edit ref="Edit" />
                    </b-col>
                </b-row>
            </b-col>
            <b-col xs="12" sm="12" lg="6">
                <b-row>
                    <b-col class="mb-4">
                        <PriceChart ref="PriceChart" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <AvailabilityChart ref="AvailabilityChart" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mb-4">
                        <SalesChart ref="SalesChart" />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>



<script>
    import PageHeader from '@/components/common/PageHeader.vue';
    import Preview from './parts/Preview.vue';
    import Details from './parts/Details.vue';
    import Edit from './parts/Edit.vue';
    import PriceChart from './parts/PriceChart.vue';
    import AvailabilityChart from './parts/AvailabilityChart.vue';
    import SalesChart from './parts/SalesChart.vue';

    export default {
        name: 'ProductDetails',
        components: {
            PageHeader,
            Preview,
            Details,
            Edit,
            PriceChart,
            AvailabilityChart,
            SalesChart,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.id),
            };
        },
        computed: {
            campaignId() {
                return this.$store.getters['campaigns/getCurrentCampaign']?.id;
            },
            apiParams() {
                return { urlParams: { offerId: this.id } };
            },
            product() {
                return this.$store.getters['offers/getOffer'];
            },
            error() {
                return this.$store.getters['offers/getError']('offer')?.message;
            },
            loading() {
                return this.$store.getters['offers/getLoading']('offer');
            },
            breadcrumb() {
                if (!this.product) return [];
                return [
                    { text: this.$t(this.parentRoute.meta.title), to: this.$route.params.parentUrl || this.parentRoute },
                    { text: this.product?.name || '---', active: true },
                ];
            },
            parentRoute() {
                return this.$route.matched.slice(-2)[0];
            },
        },
        methods: {
            fetchData() {
                this.$store.dispatch('offers/fetchOffer', this.apiParams);
            },
            clearStoreData() {
                this.$store.commit('offers/clearOffer');
            },
        },
        created() {
            this.clearStoreData();
            if (!this.product) this.fetchData();
        },
        destroyed() {
            this.clearStoreData();
            this.$store.commit('campaigns/clearOffers');
        },
    };
</script>
