<template>
    <div>

        <PageHeader>
            <div>{{ $t('newSubscription') }}</div>
            <template #side>
                <strong>{{ $t('goBackTo') }}</strong>: <b-link :to="$route.params.parentUrl || parentRoute">
                    {{ $t(parentRoute.meta.title) }}
                </b-link>
            </template>
        </PageHeader>

        <Card :header="$t('addNewSubscription')">

            <ErrorMessage :value="error" />

            <FormValidator :validator="$v.form.space" form-group label-cols-sm="3" v-slot="slotProps" :label="$t('space')">
                <MultiselectSpaces
                    :initial-value="form.space"
                    :state="slotProps.state"
                    :disabled="busy"
                    :show-checkboxes="false"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    @inputTrackBy="form.space = $event"
                />
            </FormValidator>
            <FormValidator :validator="$v.form.permission" form-group label-cols-sm="3" v-slot="slotProps" :label="$t('entitlement')">
                <b-form-select v-model="form.permission" :options="optionsPermission" add-null-option :disabled="busy" :state="slotProps.state" />
            </FormValidator>
            <FormValidator :validator="$v.form.agreement" form-group label-cols-sm="3" v-slot="slotProps" :label="$t('agreement')">
                <div class="d-flex align-items-center h-100">
                    <b-form-checkbox v-model="form.agreement" :disabled="busy" :state="slotProps.state" />
                </div>
            </FormValidator>

            <ButtonIcon :loading="busy" hide-label-while-loading class="width-min-80" @click="save()">
                {{ $t('save') }}
            </ButtonIcon>

        </Card>

    </div>
</template>



<script>
    import cloneDeep from 'lodash/cloneDeep';
    import { required } from 'vuelidate/lib/validators';
    import MultiselectSpaces from '@/components/common/MultiselectSpaces.vue';
    import FormValidator from '@/components/common/FormValidator.vue';

    const FORM_DEFAULT_DATA = Object.freeze({
        space: null,
        permission: null,
        agreement: null,
    });

    export default {
        name: 'Create',
        components: {
            MultiselectSpaces,
            FormValidator,
        },
        data() {
            return {
                form: cloneDeep(FORM_DEFAULT_DATA),
            };
        },
        validations() {
            return {
                form: {
                    space: { required },
                    permission: { required },
                },
            };
        },
        computed: {
            parentRoute() {
                return this.$route.matched.slice(-2)[0];
            },
            error() {
                return this.$store.getters['reportSubscriptions/getError']('subscriptions');
            },
            busy() {
                return this.$store.getters['reportSubscriptions/getBusy']('subscriptions');
            },
            optionsPermission() {
                const options = ['campaign_analysis', 'stats_availability', 'stats_categories', 'stats_competition_report', 'stats_price_distribution', 'stats_products', 'stats_sales', 'stats_shops'];
                return options.map(option => ({ value: option, text: this.$t(`subscriptionsPermissionOptions.${option}`) }));
            },
        },
        methods: {
            save() {
                this.$v.form.$touch();
                if (this.$v.form.$invalid) return;
                this.$store.commit('reportSubscriptions/setError', { key: 'subscriptions', value: null });
                const params = { requestParams: this.form };
                this.$store
                    .dispatch('reportSubscriptions/postSubscriptions', params)
                    .then(() => {
                        this.$toastr.success(this.$t('reportSubscriptionsCreateSuccessfully'));
                        this.form = cloneDeep(FORM_DEFAULT_DATA);
                        this.$v.form.$reset();
                    })
                    .catch(() => {});
            },
        },
    };
</script>



<style lang="scss" scoped></style>
