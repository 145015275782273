<template>
    <router-link v-if="redirectHome" to="/">
        <img
            src="../assets/logo.png"
            alt="100 Shoppers"
            class="logo"
            :style="`height: ${height}`"
        >
    </router-link>
    <img
        v-else
        src="../assets/logo.png"
        alt="100 Shoppers"
        class="logo"
        :style="`height: ${height}`"
    >
</template>

<script>
    export default {
        name: 'App100Logo',
        props: {
            height: {
                type: String,
                default: '12px',
            },
            redirectHome: {
                type: Boolean,
                default: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .logo {
        width: auto;
    }
</style>
