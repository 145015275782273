import financeService, { FinanceDocumentStatus } from '@/services/financeService';
import {
    CREATE_BILL_FAILURE,
    CREATE_BILL_REQUEST,
    CREATE_BILL_SUCCESS,
    FETCH_COMMISSIONS_FAILURE,
    FETCH_COMMISSIONS_REQUEST,
    FETCH_COMMISSIONS_SUCCESS,
    FETCH_FINANCE_DOCUMENTS_FAILURE,
    FETCH_FINANCE_DOCUMENTS_REQUEST,
    FETCH_FINANCE_DOCUMENTS_SUCCESS,
    SEND_INVOICE_FAILURE,
    SEND_INVOICE_REQUEST,
    SEND_INVOICE_SUCCESS,
} from '@/store/mutationTypes';
import { i18n } from '@/i18n/i18n';
import dateUtil from '@/utils/date';
import toastService from '@/services/toastService';

const initialState = {
    commissions: {},
    financeDocuments: [],
    loading: {
        fetchCommissions: false,
        fetchFinanceDocuments: false,
        createBill: false,
        sendInvoice: false,
    },
};

const getters = {
    /*
        See:
        bb-panel/src/Softwarefocus/TransactionBundle/Form/TransactionFilter4AdvertiserType.php
        bb-panel/src/Softwarefocus/TransactionBundle/Form/TransactionFilter4PublisherType.php
    */
    financeDocumentOptions: state =>
        (state.financeDocuments || [])
            .filter(document => [FinanceDocumentStatus.NEW, FinanceDocumentStatus.ACCEPTED, FinanceDocumentStatus.PAID].includes(document.status))
            .map(document => ({
                value: document.id,
                text: `${document.number} ${i18n.t('dated')} ${dateUtil.formatDateForUrl(document.createdAt)}`,
            }))
            .reverse(),
    getCommissions: state => state.commissions,
    getLoading: state => type => state.loading[type],
};

const actions = {
    async fetchCommissions({ commit, rootState }) {
        try {
            commit(FETCH_COMMISSIONS_REQUEST);
            const commissions = await financeService.fetchCommissions(rootState.auth.user.id);
            commit(FETCH_COMMISSIONS_SUCCESS, { commissions });
        } catch (e) {
            commit(FETCH_COMMISSIONS_FAILURE);
            throw e;
        }
    },

    async fetchFinanceDocuments({ commit, rootState }) {
        try {
            commit(FETCH_FINANCE_DOCUMENTS_REQUEST);
            const financeDocuments = await financeService.fetchFinanceDocuments(rootState.auth.user.id);
            commit(FETCH_FINANCE_DOCUMENTS_SUCCESS, {
                items: financeDocuments.items,
            });
        } catch (e) {
            commit(FETCH_FINANCE_DOCUMENTS_FAILURE);
            throw e;
        }
    },

    async createBill({ commit, rootState }) {
        try {
            commit(CREATE_BILL_REQUEST);
            await financeService.createBill(rootState.auth.user.id);
            commit(CREATE_BILL_SUCCESS);
            toastService.successToast(i18n.t('finance.createBillSuccess'));
        } catch (e) {
            commit(CREATE_BILL_FAILURE);
            throw e;
        }
    },

    async sendInvoice({ commit, rootState }, payload) {
        try {
            const { invoiceNumber, invoicePdf } = payload;
            commit(SEND_INVOICE_REQUEST);
            await financeService.sendInvoice(rootState.auth.user.id, invoiceNumber, invoicePdf);
            commit(SEND_INVOICE_SUCCESS);
            toastService.successToast(i18n.t('finance.sendInvoiceSuccess'));
        } catch (e) {
            commit(SEND_INVOICE_FAILURE);
            throw e;
        }
    },
};

const mutations = {
    // -----------------------------------------
    // FETCH_COMMISSIONS
    // -----------------------------------------
    [FETCH_COMMISSIONS_REQUEST](state) {
        state.loading.fetchCommissions = true;
    },
    [FETCH_COMMISSIONS_SUCCESS](state, payload) {
        const { commissions } = payload;
        state.loading.fetchCommissions = false;
        state.commissions = commissions;
    },
    [FETCH_COMMISSIONS_FAILURE](state) {
        state.loading.fetchCommissions = false;
        state.commissions = {};
    },

    // -----------------------------------------
    // FETCH_FINANCE_DOCUMENTS
    // -----------------------------------------
    [FETCH_FINANCE_DOCUMENTS_REQUEST](state) {
        state.loading.fetchFinanceDocuments = true;
    },
    [FETCH_FINANCE_DOCUMENTS_SUCCESS](state, payload) {
        const { items } = payload;
        state.loading.fetchFinanceDocuments = false;
        state.financeDocuments = items || [];
    },
    [FETCH_FINANCE_DOCUMENTS_FAILURE](state) {
        state.loading.fetchFinanceDocuments = false;
        state.financeDocuments = [];
    },

    // -----------------------------------------
    // CREATE_BILL
    // -----------------------------------------
    [CREATE_BILL_REQUEST](state) {
        state.loading.createBill = true;
    },
    [CREATE_BILL_SUCCESS](state) {
        state.loading.createBill = false;
    },
    [CREATE_BILL_FAILURE](state) {
        state.loading.createBill = false;
    },

    // -----------------------------------------
    // SEND_INVOICE
    // -----------------------------------------
    [SEND_INVOICE_REQUEST](state) {
        state.loading.sendInvoice = true;
    },
    [SEND_INVOICE_SUCCESS](state) {
        state.loading.sendInvoice = false;
    },
    [SEND_INVOICE_FAILURE](state) {
        state.loading.sendInvoice = false;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
