import deepFreeze from 'deep-freeze';
import serviceFactory from '@/services/serviceFactory';
import { EndpointError } from '@/services/errorService';
import globalData from '@/store/globalData';

export const ENDPOINTS = deepFreeze({
    TIPBOX: {
        URI: () => '/api/v1/spaces/{{spaceId}}/tipbox',
        methods: ['get', 'patch'],
        default: {},
    },
    DEFAULTS: {
        URI: () => '/api/v1/tipbox/defaults',
        methods: ['get'],
        default: {},
    },
    INVOICES: {
        URI: params => {
            if (globalData.user.isRoleAdmin) return '/api/v1/tipbox-invoices/{{id?}}';
            if (globalData.user.isRoleWidgeter) return '/api/v1/publishers/{{userId}}/tipbox-invoices';
            throw new EndpointError();
        },
        methods: ['get'],
        default: [],
    },
    INVOICES_DOWNLOAD: {
        URI: params => {
            if (params.id) return '/api/v1/tipbox-invoices/{{id}}/download';
            if (globalData.user.isRoleAdmin) return '/api/v1/tipbox-invoices/download';
            throw new EndpointError();
        },
        methods: ['get'],
        default: null,
    },
    INVOICES_DOWNLOAD_SUMMARY: {
        URI: params => {
            if (params.id) return '/api/v1/tipbox-invoices/{{id}}/download-summary';
            throw new EndpointError();
        },
        methods: ['get'],
        default: null,
    },
    PAYOUTS: {
        URI: params => {
            if (globalData.user.isRoleAdmin) return '/api/v1/tipbox-payouts';
            if (globalData.user.isRoleWidgeter) return '/api/v1/publishers/{{userId}}/tipbox-payouts';
            throw new EndpointError();
        },
        methods: ['get', 'post'],
        default: [],
    },
    PAYOUTS_STATS: {
        URI: params => '/api/v1/tipbox-payouts-stats',
        methods: ['get'],
        default: null,
        formatter: data => data?.amount,
    },
    PAYOUTS_STATUSES: {
        URI: params => '/api/v1/tipbox-payout-statuses',
        methods: ['get'],
        default: [],
        formatter: data => data?.items,
    },
    PAYOUTS_CONFIRM: {
        URI: params => {
            if (globalData.user.isRoleAdmin) return '/api/v1/tipbox-payouts/confirm';
            throw new EndpointError();
        },
        methods: ['get'],
        default: [],
        cacheToClear: ['/tipbox-payouts$', '/api/v1/tipbox-invoices/', '/api/v1/tipbox-invoices/download', '/api/v1/tipbox-invoices/{{id}}/download'],
    },
    PAYOUTS_STATS_TRANSFER_FILE_DOWNLOAD: {
        URI: params => '/api/v1/tipbox-payouts/transfer-file-download',
        methods: ['get'],
        default: null,
    },
    AMOUNT_STATS: {
        URI: params => {
            if (globalData.user.isRoleWidgeter) return '/api/v1/publishers/{{userId}}/tipbox-amount-stats';
            if (globalData.user.isRoleAdmin) return '/api/v1/tipbox-amount-stats';
            throw new EndpointError();
        },
        methods: ['get'],
        default: null,
        formatter: data => data?.amount,
    },
    COMMISSION_STATS: {
        URI: params => {
            if (globalData.user.isRoleAdmin) return '/api/v1/tipbox-commission-stats';
            throw new EndpointError();
        },
        methods: ['get'],
        default: null,
        formatter: data => data?.commission,
    },
    PUBLISHERS_STATS: {
        URI: params => {
            if (globalData.user.isRoleAdmin) return '/api/v1/tipbox-publishers-stats';
            throw new EndpointError();
        },
        methods: ['get'],
        default: null,
        formatter: data => data?.publishers,
    },
    READY_TO_PAYOUT: {
        URI: params => {
            if (globalData.user.isRoleWidgeter) return '/api/v1/publishers/{{userId}}/tipbox-ready-to-payout';
            throw new EndpointError();
        },
        methods: ['get'],
        default: null,
        formatter: data => data?.to_payout,
    },
    TIPBOX_SETTINGS: {
        URI: params => {
            if (globalData.user.isRoleWidgeter) return '/api/v1/publishers/{{userId}}/tipbox-settings';
            throw new EndpointError();
        },
        methods: ['get', 'patch'],
        default: null,
        cache: false,
    },
    TIPBOX_LANDING_SETTING: {
        URI: params => '/api/v1/spaces/{{spaceId}}/tipbox-landing-page',
        methods: ['get', 'patch', 'post'],
        default: null,
        cacheToClear: ['/api/v1/spaces/{{spaceId}}/tipbox', '/api/v1/spaces/{{spaceId}}/tipbox-landing-page'],
    },
    SOCIALS: {
        URI: params => '/api/v1/available-socials',
        methods: ['get'],
        default: [],
    },
});

export default {
    ...serviceFactory.build(ENDPOINTS),
};
