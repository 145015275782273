const initialState = {
    menuMobileMode: false,
    menuMobileOpened: false,
};

const getters = {
    menuOpened(state) {
        if (!state.menuMobileMode) {
            return true;
        }
        return state.menuMobileOpened;
    },
    bodyClasses(state, gtrs) {
        const classes = [];

        if (gtrs.menuOpened) {
            classes.push('sidebar-enable');
        }

        return classes;
    },
};

const mutations = {
    enableMenuMobileMode(state) {
        state.menuMobileMode = true;
    },
    disableMenuMobileMode(state) {
        state.menuMobileMode = false;
    },
    closeMobileMenu(state) {
        state.menuMobileOpened = false;
    },
    toggleMenuMobileOpened(state) {
        state.menuMobileOpened = !state.menuMobileOpened;
    },
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    getters,
};
