var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"items":_vm.breadcrumb}},[_c('div',{staticClass:"d-flex gap-3 align-items-center"},[_c('div',[_vm._v(_vm._s(_vm.$t('productAudit')))]),_c('div',{staticClass:"d-flex gap-2 align-items-center"},[_c('ButtonIcon',{attrs:{"variant":"success","to":{ name: 'ProductsItemShow', params: { id: _vm.id, parentUrl: _vm.$route.params.parentUrl } }}},[_vm._v(" "+_vm._s(_vm.$t('show'))+" ")]),_c('ButtonIcon',{attrs:{"variant":"warning","loading":_vm.busyReAggregate,"hide-label-while-loading":""},on:{"click":function($event){return _vm.reAggregate()}}},[_vm._v(" "+_vm._s(_vm.$t('outsourceAggregation'))+" ")])],1)])]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('DataTable',{attrs:{"header":_vm.$t('details'),"items":_vm.fetchProduct,"fields":_vm.fieldsProduct,"pagination":false,"no-provider-sorting":"","stacked":"","hover":false},scopedSlots:_vm._u([{key:"table-header",fn:function(ref){
var items = ref.items;
return [(items[0] && items[0].status === 1)?_c('b-alert',{attrs:{"show":"","variant":"light"}},[_vm._v(_vm._s(_vm.$t('productAwaitingAggregation')))]):_vm._e()]}},{key:"cell(status)",fn:function(ref){
var value = ref.value;
return [(!_vm.aggregationStatuses && !_vm.aggregationStatusesError)?_c('b-spinner',{attrs:{"small":""}}):(!_vm.aggregationStatusesError)?[_vm._v(_vm._s(_vm.aggregationStatuses[value]))]:[_vm._v(_vm._s(value))]]}},{key:"cell(updatedAt)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateAndHour(item.updatedAt))+" ")]}}])}),_c('DataTable',{attrs:{"items":_vm.fetchOffers,"fields":_vm.fieldsOffers,"pagination":false,"no-provider-sorting":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var items = ref.items;
return [_vm._v(" "+_vm._s(_vm.$t('offers'))+" ("+_vm._s(items.length)+") ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }