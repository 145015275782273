var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:{
        row: _vm.row,
        'v-center': _vm.row,
    }},[(_vm.$scopedSlots.label)?_c('label',{staticClass:"form-control-label",class:{
            'col-sm-3': _vm.row,
            'stretch': !_vm.row,
        },attrs:{"for":_vm.htmUniqueId}},[_vm._t("label")],2):_vm._e(),_c('div',{staticClass:"input-group input-group-merge",class:{
            'col-sm-9': _vm.row,
        }},[_vm._t("content",null,{"htmUniqueId":_vm.htmUniqueId})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }