import { prefixed } from '@/Storage';

const storageKey = 'link-generator';

export default {
    namespaced: true,
    state: {
        links: new Map(JSON.parse(window.sessionStorage.getItem(prefixed(storageKey)) || '[]').map(item => [item.link, item])),
    },
    getters: {
        getLinks: (state, getters) => () => state.links,
        getLinksSize: (state, getters) => () => getters.getLinks().size,
        getLinksAsArray: (state, getters) => (size = 10) => Array.from(getters.getLinks().values()).reverse().slice(0, size), // eslint-disable-line
    },
    mutations: {
        addLink(state, data) {
            state.links.set(data.link, data);
            this.commit('linkGenerator/saveLinksToSessionStorage');
        },
        removeLink(state, data) {
            const key = typeof data === 'object' ? data.link : data;
            const removed = state.links.delete(key);
            this.commit('linkGenerator/saveLinksToSessionStorage');
            return removed;
        },
        clearLinks(state) {
            state.links.clear();
            state.links = new Map();
            this.commit('linkGenerator/saveLinksToSessionStorage');
        },
        saveLinksToSessionStorage() {
            window.sessionStorage.setItem(prefixed(storageKey), JSON.stringify(this.getters['linkGenerator/getLinksAsArray']()));
        },
    },
};
