<script>
    import cloneDeep from 'lodash/cloneDeep';
    import LayoutAuth from '@/layouts/Auth.vue';
    import FormValidator from '@/components/common/FormValidator.vue';
    import ButtonIcon from '@/components/common/ButtonIcon.vue';

    export default {
        name: 'AuthMixin',
        components: {
            LayoutAuth,
            FormValidator,
            ButtonIcon,
        },
        data() {
            return {
                submitting: false,
                error: null,
                form: {},
                formInitData: {},
            };
        },
        watch: {
            error() {
                if (this.error) this.$toastr.error(this.error.message);
            },
        },
        methods: {
            resetForm() {
                Object.keys(this.form).forEach(key => {
                    if (key in this.formInitData) this.form[key] = this.formInitData[key];
                });
                this.$v.$reset();
            },
            resetError() {
                this.error = null;
            },
            setError(error) {
                this.error = error;
            },
            handleSubmit() {
                this.$v.$touch();
                if (this.$v.$invalid) return;
                this.submitting = true;
                this.error = null;
                this.formRequest()
                    .then(this.requestSuccess)
                    .catch(this.setError)
                    .finally(() => (this.submitting = false));
            },
            requestSuccess(data) {},
        },
        mounted() {
            this.$root.$on('bv::toast::hidden', this.resetError);
        },
        beforeDestroy() {
            this.$root.$off('bv::toast::hidden', this.resetError);
        },
        created() {
            this.formInitData = cloneDeep(this.form);
        },
    };
</script>
