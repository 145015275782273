<template>
    <DataTable
        :fields="fields"
        :items="parentComponent.items"
        :loading="parentComponent.loading"
        :error="parentComponent.error"
        :total-rows="parentComponent.requestData.max"
        :current-page.sync="parentComponent.tableParams.page"
        :per-page.sync="parentComponent.tableParams.perPage"
        :sort-by.sync="parentComponent.tableParams.sort"
        :sort-direction.sync="parentComponent.tableParams.order"
        no-local-sorting
    >

        <template #cell(permission)="{value}">
            {{ $t(`subscriptionsPermissionOptions.${value}`) }}
        </template>

        <template #cell(createdAt)="{value}">
            <template v-if="value">{{ $format.dateAndHour(value) }}</template>
        </template>

        <template #cell(canceledAt)="{value}">
            <template v-if="value">{{ $format.dateAndHour(value) }}</template>
        </template>

        <template #cell(deactivatedAt)="{value}">
            <template v-if="value">{{ $format.dateAndHour(value) }}</template>
        </template>

        <template #cell(status)="{value}">
            <ReportSubscriptionsStatusBadge :status="value" :label="$t(value)" />
        </template>

        <template #cell(agreement)="{value}">
            <BadgeBoolean :value="value" />
        </template>

        <template #cell(space)="{item}">
            <b-link :href="item.space.url" target="_blank">{{ item.space.name }}</b-link>
        </template>

        <template #cell(action)="{item}">
            <div class="d-flex gap-1">
                <ButtonIcon
                    size="sm"
                    variant="warning"
                    hide-label-while-loading
                    :disabled="item.status === 'inactive' || item.status === 'canceled' || isItemBusy(item.id)"
                    :loading="isItemCanceled(item.id)"
                    @click="cancelItem(item)"
                >
                    {{ $t('cancelSubscription') }}
                </ButtonIcon>
                <ButtonIcon
                    size="sm"
                    variant="danger"
                    hide-label-while-loading
                    :disabled="item.status === 'inactive' || isItemBusy(item.id)"
                    :loading="isItemDeactivated(item.id)"
                    @click="deactivateItem(item)"
                >
                    {{ $t('deactivate') }}
                </ButtonIcon>
                <ButtonIcon
                    size="sm"
                    variant="success"
                    hide-label-while-loading
                    :disabled="isItemBusy(item.id)"
                    :loading="isItemChanged(item.id)"
                    @click="changeItem(item)"
                >
                    {{ $t('changeType') }}
                </ButtonIcon>
            </div>
        </template>

    </DataTable>
</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';
    import BadgeBoolean from '@/components/common/BadgeBoolean.vue';
    import ReportSubscriptionsStatusBadge from '@/components/common/ReportSubscriptionsStatusBadge.vue';
    import { createItemKey } from '@/store/modules/moduleFactory';

    export default {
        name: 'Results',
        components: {
            DataTable,
            BadgeBoolean,
            ReportSubscriptionsStatusBadge,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {};
        },
        computed: {
            fields() {
                return [
                    { key: 'id', label: this.$t('id'), sortable: true },
                    { key: 'status', label: this.$t('status') },
                    { key: 'permission', label: this.$t('permission') },
                    { key: 'agreement', label: this.$t('agreement') },
                    { key: 'createdAt', label: this.$t('createdAt'), sortable: true },
                    { key: 'canceledAt', label: this.$t('canceledAt'), sortable: true },
                    { key: 'deactivatedAt', label: this.$t('deactivatedAt'), sortable: true },
                    { key: 'space', label: this.$t('advertisingSpace') },
                    { key: 'action', label: this.$t('actions') },
                ];
            },
            isItemBusy() {
                return id => this.isItemCanceled(id) || this.isItemDeactivated(id) || this.isItemChanged(id);
            },
            isItemCanceled() {
                return id => this.$store.getters['reportSubscriptions/getUpdatedItem']('cancel', createItemKey({ id }));
            },
            isItemDeactivated() {
                return id => this.$store.getters['reportSubscriptions/getUpdatedItem']('deactivate', createItemKey({ id }));
            },
            isItemChanged() {
                return id => this.$store.getters['reportSubscriptions/getUpdatedItem']('subscriptions', createItemKey({ id }));
            },
        },
        watch: {
            tableParams: {
                deep: true,
                handler: 'fetchData',
            },
        },
        methods: {
            fetchData() {
                this.parentComponent.fetchData();
            },
            cancelItem({ id }) {
                this.$store
                    .dispatch('reportSubscriptions/putCancel', { urlParams: { id } })
                    .then(this.fetchData)
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            deactivateItem({ id }) {
                this.$store
                    .dispatch('reportSubscriptions/putDeactivate', { urlParams: { id } })
                    .then(this.fetchData)
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
            changeItem({ id, agreement }) {
                this.$store
                    .dispatch('reportSubscriptions/patchSubscriptions', { urlParams: { id }, requestParams: { agreement: !agreement } })
                    .then(this.fetchData)
                    .catch(error => this.$toastr.error(error.message, this.$t('errorOccurred')));
            },
        },
    };
</script>
