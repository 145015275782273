<template>
    <b-card
        :title="item.title"
        :img-src="item.image"
        :img-alt="item.title"
        img-top
        img-height="60px"
        tag="div"
        class="item mb-0 w-100 h-100"
        body-class="d-flex flex-column"
    >
        <b-card-text>
            <div v-html="item.description"></div>
        </b-card-text>

        <hr class="w-100 mt-auto">

        <div class="d-flex">
            <b-button
                :target="item.target || ''"
                variant="warning"
                class="ml-auto"
                v-bind="{ ...(typeof item.url === 'string' ? { href: item.url } : { to: item.url}) }"
            >
                {{ item.buttonLabel }}
            </b-button>
        </div>
    </b-card>
</template>



<script>
    export default {
        name: 'IntegrationsItem',
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
    };
</script>



<style lang="scss" scoped>
    @import 'bootstrap/scss/functions';
    @import 'bootstrap/scss/variables';
    @import '@/design/_variables.scss';

    .item {
        ::v-deep {
            .card-img-top {
                max-width: 250px;
                padding: $card-spacer-y $card-spacer-x;
                padding-bottom: 0;
                object-fit: contain;
            }
        }
    }
</style>
