<template>
    <Card :header="$t('filters')">
        <b-form-group :label="$t('space')" label-cols-sm="3">
            <MultiselectSpaces
                v-model="filters.spaces"
                :placeholder="$t('enterUrlToSearch')"
                @loading="value => isSpacesLoading = value"
            />
        </b-form-group>

        <b-form-group :label="$t('category')" label-cols-sm="3">
            <Multiselect
                v-model="filters.categories"
                :options="categoriesOptionsFormat"
                :placeholder="$t('selectField.placeholder')"
                :show-checkboxes="true"
                :multiple="true"
                :clear-on-select="false"
                :close-on-select="false"
                :show-selected="true"
                :loading="isCategoriesLoading"
            />
        </b-form-group>

        <b-form-group :label="$t('status')" label-cols-sm="3">
            <b-form-checkbox-group v-model="filters.statuses" :options="statusesOptionsFormat" class="h-100 d-flex align-items-center" />
        </b-form-group>

        <ButtonIcon :loading="loading" :disabled="isSpacesLoading || isCategoriesLoading" icon="filter" @click="fetchSpaces()">
            {{ $t('filter') }}
        </ButtonIcon>
        <ErrorMessage :value="error" />
    </Card>
</template>



<script>
    import { addDays } from 'date-fns';
    import Card from '@/components/common/Card.vue';
    import ButtonIcon from '@/components/common/ButtonIcon.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import MultiselectSpaces from '@/components/common/MultiselectSpaces.vue';
    import { CampaignStatus, translateCampaignStatus } from '@/services/spaceService';
    import categoryService from '@/services/categoryService';
    import dateUtil from '@/utils/date';

    export default {
        name: 'Filters',
        components: {
            Card,
            ButtonIcon,
            Multiselect,
            MultiselectSpaces,
        },
        data() {
            return {
                searchTextCategory: '',
                categories: [],
                isSpacesLoading: false,
                isCategoriesLoading: false,
                filters: {
                    spaces: [],
                    categories: [],
                    statuses: [],
                },
                results: [],
            };
        },
        computed: {
            campaignId() {
                return this.$store.getters['campaigns/getCurrentCampaign']?.id;
            },
            requestParams() {
                return {
                    spaces: this.filterSpacesIds.join(','),
                    categories: this.filterCategoriesIds.join(','),
                    statuses: this.filters.statuses.join(','),
                    page: 1,
                    perPage: Number.MAX_SAFE_INTEGER,
                    sort: 'name',
                    order: 'ASC',
                    startDate: dateUtil.formatDateForUrl(addDays(Date.now(), -30)),
                    endDate: dateUtil.formatDateForUrl(Date.now()),
                };
            },
            apiParams() {
                return { requestParams: this.requestParams };
            },
            filterSpacesIds() {
                return this.filters.spaces.map(item => item.value);
            },
            filterCategoriesIds() {
                return this.filters.categories.map(item => item.value);
            },
            loading() {
                return this.$store.getters['campaigns/getLoading']('spaces');
            },
            error() {
                return this.$store.getters['campaigns/getError']('spaces');
            },
            categoriesOptionsFormat() {
                return this.categories.map(({ id, name }) => ({ value: id, text: name }));
            },
            statusesOptionsFormat() {
                return Object.values(CampaignStatus).map(status => ({ value: status, text: translateCampaignStatus(status) }));
            },
        },
        watch: {
            campaignId() {
                this.filters.spaces = [];
                this.results = [];
                this.clearStoreSpaces();
                this.fetchSpaces();
            },
        },
        methods: {
            async fetchFilterCategories() {
                this.isCategoriesLoading = true;
                this.categories = await categoryService.getCategories();
                this.isCategoriesLoading = false;
            },
            clearStoreSpaces() {
                this.$store.commit('campaigns/clearSpaces');
            },
            fetchSpaces() {
                this.$store.dispatch('campaigns/fetchSpaces', this.apiParams);
            },
        },
        created() {
            this.fetchFilterCategories();
            this.fetchSpaces();
        },
    };
</script>
