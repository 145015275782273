<template>
    <div class="row page-title">
        <div class="col-md-12">
            <b-breadcrumb :items="items" class="float-right mt-1" />
            <div class="float-right">
                <slot name="side"></slot>
            </div>
            <h4 class="mb-1 mt-0">
                <slot></slot>
            </h4>
        </div>
        <div class="page-subtitle col-md-12">
            <slot name="subtitle"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageHeader',
        props: {
            items: {
                type: Array,
                default: () => [],
            },
        },
    };
</script>
