<template>
    <DataTable
        :header="$t('searchResultsCount', { count: items.length })"
        :error.sync="error"
        :items="items"
        :fields="fields"
        :loading="loading"
        :pagination="false"
    >
        <template #cell(offerId)="{item}">
            <b-link class="title" :to="getUrlOffer(item)">
                {{ item.offerId }}
            </b-link>
        </template>
        <template #cell(name)="{item}">
            <div class="d-flex">
                <ImageLoader
                    :src="item.image"
                    alt=""
                    size="100"
                    image-height="auto"
                    class="mr-2 flex-shrink-0"
                />
                <div class="d-flex flex-column gap-2">
                    <b-link class="title" :to="getUrlItemShow(item)">
                        {{ item.name }}
                    </b-link>
                </div>
            </div>
        </template>
        <template #cell(action)="{item}">
            <div class="d-flex flex-wrap gap-1 justify-content-end">
                <ButtonIcon size="sm" :to="getUrlItemShow(item)">
                    {{ $t('show') }}
                </ButtonIcon>
                <ButtonIcon size="sm" variant="success" :to="getUrlItemAudit(item)">
                    {{ $t('audit') }}
                </ButtonIcon>
            </div>
        </template>
    </DataTable>
</template>



<script>
    import DataTable from '@/components/common/DataTable.vue';
    import ImageLoader from '@/components/common/ImageLoader.vue';

    export default {
        name: 'Results',
        components: {
            DataTable,
            ImageLoader,
        },
        data() {
            return {
                error: null,
            };
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        computed: {
            filters() {
                return this.parentComponent.filters;
            },
            filtersParams() {
                return this.parentComponent.currentFilters;
            },
            tableParams() {
                return this.parentComponent.tableParams;
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                };
            },
            fields() {
                return [
                    {
                        key: 'id',
                        label: this.$t('id'),
                    },
                    {
                        key: 'offerId',
                        label: this.$t('offerId'),
                    },
                    {
                        key: 'name',
                        label: this.$t('name'),
                    },
                    {
                        key: 'info',
                        label: this.$t('info'),
                    },
                    {
                        key: 'action',
                        label: '',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                    },
                ];
            },
            items() {
                return this.$store.getters['product/getAggregations'];
            },
            loading() {
                return this.$store.getters['product/getLoading']('aggregations');
            },
        },
        watch: {
            '$route.query': {
                immediate: true,
                handler: 'initFiltersAndFetchData',
            },
        },
        methods: {
            parseValue(val) {
                if (/^\d+$/.test(val)) return Number.parseInt(val);
                if (val === 'false') return false;
                if (val === 'true') return true;
                return val;
            },
            initFiltersAndFetchData() {
                this.parentComponent.resetFilters();
                Object.keys(this.$route.query).forEach(key => {
                    this.$set(this.parentComponent.filters, key, this.parseValue(this.$route.query[key]));
                });
                if (Object.keys(this.filtersParams).length) {
                    this.fetchData();
                } else {
                    this.clearStoreData();
                }
            },
            fetchData() {
                this.error = null;
                this.$store.dispatch('product/fetchAggregations', { requestParams: this.requestParams }).catch(error => (this.error = error));
            },
            clearStoreData() {
                this.$store.commit('product/clearAggregations');
            },
            getUrlItemShow(item) {
                return {
                    name: 'ProductsItemShow',
                    params: { id: item.id, parentUrl: this.$route.params.parentUrl || this.$route.fullPath },
                    query: {},
                };
            },
            getUrlItemAudit(item) {
                return {
                    name: 'ProductsItemAudit',
                    params: { id: item.id, parentUrl: this.$route.params.parentUrl || this.$route.fullPath },
                    query: {},
                };
            },
            getUrlOffer(item) {
                return {
                    name: 'OffersDetails',
                    params: { id: item.offerId },
                    query: {},
                };
            },
        },
        created() {
            this.clearStoreData();
        },
        destroyed() {
            this.clearStoreData();
        },
    };
</script>



<style lang="scss" scoped></style>
