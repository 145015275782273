<template>
    <b-modal id="modal-verification" ref="modal" :title="$t('verifiyAccount')">
        <div>
            <p>{{ $t('verificationModal.header') }}</p>

            <div class="payment-information">
                <div class="row">
                    <div class="col-5">
                        <p>{{ $t('paymentGeneratorCompany.recipient') }} </p>
                    </div>
                    <div class="col-7">
                        <b>{{ companyName }}</b>
                    </div>
                </div>

                <div class="row">
                    <div class="col-5">
                        <p>{{ $t('bankAccount') }}</p>
                    </div>
                    <div class="col-7">
                        <b>{{ companyBankAccount }}</b>
                    </div>
                </div>

                <div class="row">
                    <div class="col-5">
                        <p>{{ $t('title') }}</p>
                    </div>
                    <div class="col-7">
                        <b>{{ verificationCode }}</b>
                    </div>
                </div>

                <div class="row">
                    <div class="col-5">
                        <p>{{ $t('amount') }}</p>
                    </div>
                    <div class="col-7">
                        <b>1 zł</b>
                    </div>
                </div>
            </div>

            <p class="mt-4">{{ $t('verificationModal.footer') }}</p>
        </div>

        <template #modal-footer>

            <ButtonIcon variant="primary" class="width-100" hide-label-while-loading @click="close()">
                {{ $t('confirm') }}
            </ButtonIcon>
        </template>

    </b-modal>
</template>



<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'ModalVerification',
        props: {
            verificationCode: {
                type: String,
                required: true,
            },
        },
        computed: {
            companyData() {
                return this.$store.getters['options/companyData'];
            },

            companyName() {
                return this.companyData?.companyName || '';
            },

            companyBankAccount() {
                return this.companyData?.tipboxBankAccount || '';
            },
        },
        methods: {
            ...mapActions({
                fetchCompanyData: 'options/fetchCompanyData',
            }),

            close() {
                this.$root.$emit('bv::hide::modal', 'modal-verification');
            },
        },
        mounted() {
            this.fetchCompanyData();
        },
    };
</script>

<style scoped>
    .payment-information p {
        margin-bottom: 1px;
    }
</style>