<template>
    <div>
        <PageHeader>{{ $t('campaignsList') }}</PageHeader>
        <Filters ref="Filters" />
        <Results ref="Results" />
    </div>
</template>



<script>
    import viewFiltersMixin from '@/mixins/viewFiltersMixin';
    import Filters from './parts/Filters.vue';
    import Results from './parts/Results.vue';

    const DEFAULT_FILTERS_PARAMS = Object.freeze({
        name: null,
        id: null,
        advertiser: null,
        categories: [],
        active: true,
        hidden: false,
    });
    const DEFAULT_TABLE_PARAMS = Object.freeze({
        perPage: 25,
        page: 1,
        sort: 'id',
        order: 'DESC',
    });

    export default {
        name: 'AdminCampaignsList',
        mixins: [viewFiltersMixin],
        components: {
            Filters,
            Results,
        },
        provide() {
            return {
                parentComponent: this,
            };
        },
        data() {
            return {
                ...this.initParams(DEFAULT_FILTERS_PARAMS, DEFAULT_TABLE_PARAMS),
                request: this.createRequest('campaigns/campaigns'),
            };
        },
    };
</script>
