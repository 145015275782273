import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Polish } from 'flatpickr/dist/l10n/pl';
import English from 'flatpickr/dist/l10n/default';
import countryService from '../services/countryService';
import storageKeys from '../Storage';
import defaultLang from './en-GB';

export const defaultLocale = 'en-GB';
export const currentLocale = window.localStorage.getItem(storageKeys.LOCALE) || process.env.VUE_APP_DEFAULT_LOCALE || 'en-GB';

let currentLang = defaultLang;

if (currentLocale !== defaultLocale) {
    currentLang = require(`./${currentLocale}.js`).default; // eslint-disable-line
}

const messages = {
    [defaultLocale]: defaultLang,
};

export const loadedLanguages = [defaultLocale];

if (!loadedLanguages.includes(currentLocale)) {
    loadedLanguages.push(currentLocale);
}

if (!messages[currentLocale]) {
    messages[currentLocale] = currentLang;
}

const dateTimeFormats = {
    'en-GB': {
        basic: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        },
        long: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        },
        longNumeric: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        },
        month: {
            year: 'numeric',
            month: 'long',
        },
        monthName: {
            month: 'long',
        },
        year: {
            year: 'numeric',
        },
    },
    pl: {
        basic: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        short: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        },
        long: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        },
        longNumeric: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        },
        month: {
            year: 'numeric',
            month: 'long',
        },
        monthName: {
            month: 'long',
        },
        year: {
            year: 'numeric',
        },
    },
};

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: currentLocale,
    fallbackLocale: defaultLocale,
    messages,
    dateTimeFormats,
});

export function setI18nLanguage(locale) {
    i18n.locale = locale;
    document.querySelector('html').setAttribute('lang', locale);
    window.localStorage.setItem(storageKeys.LOCALE, locale);
    return locale;
}

export const supportedLanguages = [
    { language: 'en', locale: 'en-GB', name: 'English' },
    { language: 'pl', locale: 'pl', name: 'Polski' },
    { language: 'lt', locale: 'lt', name: 'Lietuvių', widgetOnly: true },
    { language: 'lv', locale: 'lv', name: 'Latviešu', widgetOnly: true },
    { language: 'et', locale: 'et', name: 'Eesti', widgetOnly: true },
    { language: 'de', locale: 'de', name: 'Deutsch', widgetOnly: true },
    { language: 'dk', locale: 'dk', name: 'Dansk', widgetOnly: true },
    { language: 'fi', locale: 'fi', name: 'Suomalainen', widgetOnly: true },
    { language: 'fr', locale: 'fr', name: 'Français', widgetOnly: true },
    { language: 'nl', locale: 'nl', name: 'Nederlands', widgetOnly: true },
];

export const languageOptions = supportedLanguages.map(option => ({
    value: option.language,
    text: option.name,
}));

export const getLocaleFromLanguage = language => {
    const selectedLanguage = supportedLanguages.find(lang => lang.language === language);
    return selectedLanguage ? selectedLanguage.locale : currentLocale;
};
export const getLanguageFromLocale = locale => {
    const selectedLocale = supportedLanguages.find(lang => lang.locale === locale);
    return selectedLocale ? selectedLocale.language : currentLocale;
};

export const getFlatpickrLocale = locale => {
    switch (locale) {
        case 'pl':
            return Polish;
        default:
            return English;
    }
};

export const getFlapickrFormat = locale => {
    switch (locale) {
        default:
            return 'd M Y';
    }
};

document.querySelector('html').setAttribute('lang', defaultLocale);

export async function loadLanguageAsync(locale) {
    await countryService.loadCountriesLanguage(locale);

    if (i18n.locale !== locale) {
        if (!loadedLanguages.includes(locale)) {
            return import(/* @vite-ignore */ /* webpackChunkName: "lang-[request]" */ `./${locale}`)
                .then(async msgs => {
                    i18n.setLocaleMessage(locale, msgs.default);
                    loadedLanguages.push(locale);
                    return setI18nLanguage(locale);
                })
                .catch(() => {
                    console.error(`Locale file for "${locale}" not found.`);
                    window.localStorage.setItem(storageKeys.LOCALE, i18n.locale);
                });
        }
        return Promise.resolve(setI18nLanguage(locale));
    }
    return Promise.resolve(locale);
}

countryService.loadCountriesLanguage(currentLocale);

export function getSupportedLanguages(includeWidgetLanguages = false) {
    return supportedLanguages.filter(option => includeWidgetLanguages || !option.widgetOnly);
}

export function getLanguageOptions(includeWidgetLanguages = false) {
    return supportedLanguages
        .filter(option => includeWidgetLanguages || !option.widgetOnly)
        .map(option => ({
            value: option.language,
            text: i18n.t(`language.${option.language}`),
        }));
}

export const tx = (key, defaultText, localeName) => (i18n.te(key, localeName) ? i18n.t(key) : defaultText || '');
i18n.tx = tx;
Vue.prototype.$tx = tx;
