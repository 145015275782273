export const prefixed = val => `buybox-panel-${val}`;

export default Object.freeze({
    ACCESS_TOKEN: prefixed('accessToken'),
    REFRESH_TOKEN: prefixed('refreshToken'),
    IS_ADMIN: prefixed('isAdmin'),
    SPACE_ID: prefixed('spaceId'),
    LOCALE: prefixed('locale'),
    CAMPAIGN_ID: prefixed('campaignId'),
});
