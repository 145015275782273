<template>
    <div>
        <PageHeader :items="breadcrumb">
            <span v-if="item">{{ item.name }}</span>
            <b-skeleton v-if="!item && !error" animation="wave" width="45%" height="25px" class="mb-0" />
            <template #side>
                <div v-if="!item && error">
                    <strong>{{ $t('goBackTo') }}</strong>:
                    <b-link :to="parentRoute">
                        {{ $t(parentRoute.meta.title) }}
                    </b-link>
                </div>
                <b-skeleton v-if="!item && !error" animation="wave" width="100%" class="mb-0 mt-1 width-300" />
            </template>
        </PageHeader>

        <ErrorMessage :value="error" />

        <div class="d-flex flex-wrap gap-4">
            <Card :header="$t('productDetails')" :loading="!item || loading" class="flex-basis-0 flex-grow-1 width-min-400 mb-auto">
                <FormValidator :validator="$v.form.name" form-group v-slot="slotProps" :label="$t('name')">
                    <b-form-input v-model="form.name" :disabled="busy" :state="slotProps.state" />
                </FormValidator>
                <FormValidator :validator="$v.form.url" form-group v-slot="slotProps" :label="$t('url')">
                    <b-form-input v-model="form.url" :disabled="busy" :state="slotProps.state" />
                </FormValidator>
                <FormValidator :validator="$v.form.description" form-group v-slot="slotProps" :label="$t('description')">
                    <b-form-textarea v-model="form.description" :disabled="busy" :state="slotProps.state" />
                </FormValidator>
                <FormValidator :validator="$v.form.type" form-group v-slot="slotProps" :label="$t('websiteType')">
                    <Multiselect
                        :value="form.type"
                        :fetch-options-function="fetchSpaceTypes"
                        :parse-options-function="parseSpaceTypes"
                        :state="slotProps.state"
                        :disabled="busy"
                        @change="item => (form.type = item.value)"
                    />
                </FormValidator>
                <b-form-group :label="$t('category')">
                    <Multiselect
                        v-model="form.categories"
                        :fetch-options-function="fetchCategories"
                        :parse-options-function="parseCategories"
                        :placeholder="$t('selectField.placeholder')"
                        :show-checkboxes="false"
                        :multiple="true"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :show-selected="true"
                        :disabled="busy"
                    />
                </b-form-group>
                <b-form-group :label="$t('permissions')">
                    <Multiselect
                        v-model="form.permissions"
                        :fetch-options-function="fetchSpacePermissions"
                        :parse-options-function="parseSpacePermissions"
                        :placeholder="$t('selectField.placeholder')"
                        :disabled="busy"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :multiple="true"
                    />
                </b-form-group>
                <FormValidator :validator="$v.form.amazonTrackingId" form-group v-slot="slotProps" :label="$t('amazonTrackingId')">
                    <b-form-input v-model="form.amazonTrackingId" :disabled="busy" :state="slotProps.state" />
                </FormValidator>
                <FormValidator :validator="$v.form.gaApiSecret" form-group v-slot="slotProps" :label="$t('gaApiSecret')">
                    <b-form-input v-model="form.gaApiSecret" :disabled="busy" :state="slotProps.state" />
                </FormValidator>
                <FormValidator :validator="$v.form.gaSpace" form-group v-slot="slotProps" :label="$t('gaSpace')">
                    <b-form-input v-model="form.gaSpace" :disabled="busy" :state="slotProps.state" />
                </FormValidator>
                <div class="my-4"></div>
                <b-form-group class="mb-0">
                    <ButtonIcon :loading="updateBusy" :disabled="!item || busy" block @click="submit()">
                        {{ $t('saveChanges') }}
                    </ButtonIcon>
                </b-form-group>
            </Card>

            <Card class="flex-basis-0 flex-grow-1 width-min-400 mb-auto">
                <SkeletonList :loading="!item">
                    <SkeletonItem :label="$t('ID')" width="80%">
                        <span v-if="item">{{ item.id }}</span>
                    </SkeletonItem>
                    <SkeletonItem :label="$t('dateAdded')" width="100%">
                        <span v-if="item">{{ $format.dateAndHour(item.createdAt, 'dd-MM-yyyy') }}</span>
                    </SkeletonItem>
                    <SkeletonItem :label="$t('user')" width="70%">
                        <span v-if="item">
                            <router-link :to="{ name: 'UsersDetails', params: { id: item.publisher.id } }">{{ item.publisher.name }}</router-link>
                            <span v-if="item.publisher.id"> ({{ item.publisher.id }}) </span> <span v-if="item.publisher.company">({{ item.publisher.company }})</span></span>
                    </SkeletonItem>
                    <SkeletonItem :label="$t('userType')" width="40%">
                        <span v-if="item">{{ roleTranslate(item.publisher.role) }}</span>
                    </SkeletonItem>
                    <SkeletonItem :label="$t('status')" width="30%">
                        <TransactionStatusBadge v-if="item && item.status" :status="item.status" />
                    </SkeletonItem>
                </SkeletonList>

                <div v-if="item" class="d-flex gap-1">
                    <ButtonIcon
                        v-if="item.status === TransactionStatus.ACCEPTED || item.status === TransactionStatus.NEW"
                        variant="danger"
                        :disabled="busy"
                        :loading="rejectBusy"
                        @click="rejectSpace()"
                    >
                        {{ $t('reject') }}
                    </ButtonIcon>
                    <ButtonIcon
                        v-if="item.status === TransactionStatus.REJECTED || item.status === TransactionStatus.NEW"
                        variant="success"
                        :disabled="busy"
                        :loading="acceptBusy"
                        @click="acceptSpace()"
                    >
                        {{ $t('accept') }}
                    </ButtonIcon>
                </div>
            </Card>
        </div>
    </div>
</template>

<script>
    import pick from 'lodash/pick';
    import { required } from 'vuelidate/lib/validators';
    import { url } from '@/utils/validators';
    import { roleTranslate } from '@/Role';
    import { TransactionStatus } from '@/services/transactionService';
    import PageHeader from '@/components/common/PageHeader.vue';
    import spaceService from '@/services/spaceService';
    import categoryService from '@/services/categoryService';
    import FormValidator from '@/components/common/FormValidator.vue';
    import Multiselect from '@/components/common/Multiselect.vue';
    import TransactionStatusBadge from '@/components/common/TransactionStatusBadge.vue';
    import SkeletonList from '@/components/common/SkeletonList.vue';
    import SkeletonItem from '@/components/common/SkeletonItem.vue';

    export default {
        name: 'WebpagesDetails',
        components: {
            PageHeader,
            FormValidator,
            Multiselect,
            TransactionStatusBadge,
            SkeletonList,
            SkeletonItem,
        },
        data() {
            return {
                id: Number.parseInt(this.$route.params.id),
                item: null,
                loading: null,
                updateBusy: false,
                form: {
                    name: '',
                    url: '',
                    description: '',
                    type: '',
                    categories: '',
                    permissions: '',
                    amazonTrackingId: '',
                    gaApiSecret: '',
                    gaSpace: '',
                },
                error: null,
                TransactionStatus,
                acceptBusy: false,
                rejectBusy: false,
            };
        },
        validations() {
            return {
                form: {
                    name: { required },
                    url: { required, url },
                    description: { required },
                    type: { required },
                    amazonTrackingId: {},
                    gaApiSecret: {},
                    gaSpace: {},
                },
            };
        },
        computed: {
            items() {
                return Object.values(this.$store.state.space.spaces || {});
            },
            breadcrumb() {
                if (!this.item) return [];
                return [
                    { text: this.$t(this.parentRoute.meta.title), to: this.$route.params.parentUrl || this.parentRoute },
                    { text: this.item?.name || '---', active: true },
                ];
            },
            parentRoute() {
                return this.$route.matched.slice(-2)[0];
            },
            busy() {
                return this.loading || this.updateBusy || this.acceptBusy || this.rejectBusy;
            },
        },
        watch: {
            item: {
                immediate: true,
                handler: 'initFormData',
            },
        },
        methods: {
            roleTranslate,
            async loadItem() {
                this.loading = true;
                const item = await spaceService.fetchSpace(this.id).catch(error => {
                    this.$toastr.error(error.message);
                    this.$router.push(this.parentRoute);
                });
                if (item) {
                    item.status = parseInt(item.status);
                }
                this.item = item;
                this.loading = false;
            },
            initFormData() {
                if (this.item) {
                    const itemPick = pick(this.item, Object.keys(this.form));
                    this.form = { ...this.form, ...itemPick, categories: this.item.categories?.map(item => item.id) };
                }
            },
            fetchSpaceTypes() {
                return spaceService.fetchSpaceTypes();
            },
            parseSpaceTypes(data) {
                return data?.items?.map(({ value, label }) => ({ value: `${value}`, text: label }));
            },
            fetchCategories() {
                return categoryService.getCategories();
            },
            parseCategories(data) {
                return Object.values(data || {}).map(({ id, name }) => ({ value: id, text: name }));
            },
            fetchSpacePermissions() {
                return spaceService.fetchSpacePermissions();
            },
            parseSpacePermissions(data) {
                return Object.values(data || {}).map(({ value, label }) => ({ value, text: label }));
            },
            async submit() {
                this.$v.$touch();
                if (this.$v.$invalid) return;
                this.updateBusy = true;
                const data = {
                    ...this.form,
                    categories: this.form.categories.map(item => item.value),
                    permissions: this.form.permissions.map(item => item.value),
                };
                try {
                    await spaceService.updateSpace(this.id, data);
                } catch (error) {
                    this.error = error;
                }
                this.updateBusy = false;
            },
            async acceptSpace() {
                this.acceptBusy = true;
                try {
                    const item = await spaceService.acceptSpace(this.id);
                    this.item = Object.assign(this.item, item);
                } catch (error) {
                    this.error = error;
                }
                this.acceptBusy = false;
            },
            async rejectSpace() {
                this.rejectBusy = true;
                try {
                    const item = await spaceService.rejectSpace(this.id);
                    this.item = Object.assign(this.item, item);
                } catch (error) {
                    this.error = error;
                }
                this.rejectBusy = false;
            },
        },
        created() {
            this.loadItem();
        },
    };
</script>
