<template>
    <b-modal
        :id="`modal-form-${modalId}`"
        ref="modal"
        :title="modalTitle"
        size="lg"
        cancel-variant="light"
        ok-variant="primary"
        :cancel-title="$t('cancel')"
        :ok-title="$t('save')"
        :busy="submitting"
        static
        @ok.prevent="handleSubmit()"
        @show="resetData()"
        @hidden="resetData()"
    >
        <b-overlay v-if="item" :show="submitting" opacity="0" class="h-100">
            <b-form @submit.prevent>
                <FormValidator :validator="$v.form.name" form-group v-slot="slotProps" :label="$t('name')">
                    <b-form-input v-model="form.name" :placeholder="$t('name')" :state="slotProps.state" :disabled="submitting" />
                </FormValidator>
                <FormValidator :validator="$v.form.manufacturer" form-group v-slot="slotProps" :label="$t('manufacturer')">
                    <Multiselect
                        ref="formManufacturers"
                        :initial-value="item ? item.manufacturer : undefined"
                        :fetch-options-function="fetchManufacturers"
                        :parse-options-function="parseManufacturers"
                        :placeholder="$t('selectField.placeholder')"
                        :asynchronous="true"
                        :searchable="true"
                        :show-selected="true"
                        track-by="id"
                        label="name"
                        select-label=""
                        :disabled="submitting"
                        :state="slotProps.state"
                        @inputTrackBy="form.manufacturer = $event"
                    />
                </FormValidator>
                <b-form-group :label="$t('space')">
                    <MultiselectSpaces
                        :initial-value="initialValueSpaces"
                        :disabled="submitting"
                        :loading="brandSpacesLoading"
                        @inputTrackBy="form.spaces = $event"
                    />
                </b-form-group>
            </b-form>
        </b-overlay>
    </b-modal>
</template>



<script>
    import { required } from 'vuelidate/lib/validators';
    import ModalFormMixin from '@/mixins/modalFormMixin';
    import FormValidator from '@/components/common/FormValidator.vue';
    import catalogService from '@/services/catalogService';
    import Multiselect from '@/components/common/Multiselect.vue';
    import MultiselectSpaces from '@/components/common/MultiselectSpaces.vue';
    import spaceService from '@/services/spaceService';

    export default {
        name: 'ModalForm',
        mixins: [ModalFormMixin],
        components: {
            FormValidator,
            Multiselect,
            MultiselectSpaces,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                modalId: 'catalog-brands',
                createAction: 'catalog/postBrands',
                updateAction: 'catalog/patchBrands',
                form: {
                    name: '',
                    manufacturer: null,
                    spaces: null,
                },
                brandSpaces: null,
                brandSpacesLoading: false,
            };
        },
        validations() {
            return {
                form: {
                    name: { required },
                    manufacturer: { required },
                },
            };
        },
        computed: {
            initialValueSpaces() {
                return this.brandSpaces?.map(item => ({ text: item.name, value: item.id })) || undefined;
            },
        },
        watch: {
            async itemId(id) {
                if (id) {
                    this.brandSpacesLoading = true;
                    const response = await this.fetchSpaces(id);
                    this.brandSpacesLoading = false;
                    this.brandSpaces = response?.items || null;
                    this.form.spaces = this.brandSpaces?.map(item => item.id) || null;
                }
            },
        },
        methods: {
            fetchSpaces(brandId) {
                return spaceService.fetchSpaces(null, { brandIds: brandId });
            },
            formatFormData() {
                return { manufacturer: this.item?.manufacturer?.id };
            },
            async beforeSuccess(data) {
                await Promise.all((this.form.spaces || []).map(id => spaceService.updateSpace(id, { brandId: data.id }))).catch(error => this.$toastr.error(error.message));
            },
            getDataStructure({ name, manufacturer }) {
                return {
                    name,
                    manufacturer,
                };
            },
            fetchManufacturers({ search }) {
                const requestParams = { name: search };
                return catalogService.getManufacturers({ requestParams });
            },
            parseManufacturers(data) {
                return data?.items || [];
            },
            refreshData() {
                this.parentComponent.fetchData();
            },
        },
    };
</script>
