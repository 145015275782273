/* eslint-disable no-param-reassign */
import googleAuth from './googleAuth';
import facebookAuth from './facebookAuth';
import appleAuth from './appleAuth';

const vueSocialAuth = {
    install(Vue, options) {
        if (!Vue.prototype.$socialAuth) {
            if (options.google) googleAuth.init(options.google);
            if (options.facebook) facebookAuth.init(options.facebook);
            if (options.apple) appleAuth.init(options.apple);
            Vue.prototype.$socialAuth = {
                google: googleAuth,
                facebook: facebookAuth,
                apple: appleAuth,
            };
        }
    },
};

export default vueSocialAuth;
