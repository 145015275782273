<template>
    <DataTable
        :header="$t('paymentsDonations')"
        :error="error"
        :items="items"
        :fields="fields"
        :total-rows="tips.max"
        :per-page.sync="parentComponent.tableParams.perPage"
        :sort-by.sync="parentComponent.tableParams.sortBy"
        :sort-direction.sync="parentComponent.tableParams.sortDirection"
        :current-page.sync="parentComponent.tableParams.currentPage"
        :loading="loading"
        no-sort-reset
        no-local-sorting
    >

        <template #cell(createdAt)="data">
            {{ $format.dateAndHour(data.item.createdAt, 'dd.MM.yyyy') }}
        </template>

        <template #cell(amount)="data">
            {{ $format.currency(data.item.amount) }}
        </template>

        <template #cell(commission)="data">
            {{ $format.currency(data.item.commission) }}
        </template>

        <template #cell(paymentCommission)="data">
            {{ $format.currency(data.item.paymentCommission) }}
        </template>

        <template #cell(backer)="data">
            {{ data.item.backerName }} {{ data.item.backerSurname }}
        </template>

        <template #cell(status)="data">
            <TipsStatusBadge :status="data.item.status" :label="parentComponent.getStatusLabel(data.item.status)" />
        </template>

    </DataTable>
</template>



<script>
    import clone from 'lodash/clone';
    import DataTable from '@/components/common/DataTable.vue';
    import TipsStatusBadge from '@/components/common/TipsStatusBadge.vue';

    export default {
        name: 'TipsResults',
        components: {
            DataTable,
            TipsStatusBadge,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                fields: [
                    {
                        key: 'id',
                        label: this.$t('id'),
                    },
                    {
                        key: 'tpayId',
                        label: this.$t('paymentId'),
                    },
                    {
                        key: 'createdAt',
                        label: this.$t('date'),
                    },
                    {
                        key: 'space.name',
                        label: this.$t('space'),
                    },
                    {
                        key: 'publisher.name',
                        label: this.$t('publisher'),
                    },
                    {
                        key: 'backer',
                        label: this.$t('nameOfTheDonor'),
                    },
                    {
                        key: 'backerEmail',
                        label: this.$t('emailOfTheDonor'),
                    },
                    {
                        key: 'amount',
                        label: this.$t('paymentAmount'),
                    },
                    {
                        key: 'commission',
                        label: this.$t('ourCommission'),
                    },
                    {
                        key: 'paymentCommission',
                        label: this.$t('operatorsCommission'),
                    },
                    {
                        key: 'status',
                        label: this.$t('paymentStatus'),
                    },
                ],
                currentFilters: {},
            };
        },
        computed: {
            tips() {
                return this.$store.getters['tipTransactions/getTransactions'] || {};
            },
            items() {
                return this.tips.items || [];
            },
            error() {
                return this.$store.getters['tipTransactions/getError']('transactions');
            },
            loading() {
                return this.$store.getters['tipTransactions/getLoading']('transactions');
            },
            filters() {
                return this.parentComponent.filters;
            },
            filtersParams() {
                const filters = {};
                if (this.filters.space) filters.spaces = this.filters.space.value;
                if (this.filters.users?.length) filters.users = this.filters.users.map(item => item.id).join(',');
                if (this.filters.statuses?.length) filters.statuses = this.filters.statuses.join(',');
                if (this.filters.timeRange?.startDate) filters.startDate = this.filters.timeRange.startDate;
                if (this.filters.timeRange?.endDate) filters.endDate = this.filters.timeRange.endDate;
                return filters;
            },
            tableParams() {
                return {
                    sort: this.parentComponent.tableParams.sortBy,
                    order: this.parentComponent.tableParams.sortDirection.toUpperCase(),
                    page: this.parentComponent.tableParams.currentPage,
                    perPage: this.parentComponent.tableParams.perPage,
                };
            },
            requestParams() {
                return {
                    ...this.filtersParams,
                    ...this.tableParams,
                };
            },
            isFiltersChanged() {
                return JSON.stringify(this.filtersParams) !== JSON.stringify(this.currentFilters);
            },
        },
        watch: {
            tableParams: {
                handler: 'fetchData',
            },
            'filters.timeRange': {
                handler(newValue, oldValue) {
                    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                        this.fetchData();
                    }
                },
            },
        },
        methods: {
            resetCurrentPage() {
                this.parentComponent.tableParams.currentPage = 1;
            },
            clearData() {
                this.$store.commit('tipTransactions/clearTransactions');
            },
            fetchData() {
                if (this.isFiltersChanged) this.resetCurrentPage();
                this.currentFilters = clone(this.filtersParams);
                this.$store.dispatch('tipTransactions/fetchTransactions', { requestParams: this.requestParams }).catch(this.clearData);
            },
        },
        mounted() {
            this.clearData();
        },
    };
</script>
