<template>
    <section id="tipbox-object" type="text/html">
        <div id="tipbox-preview" class="bb-widget" data-bb-type="tipbox" data-bb-preview="true" v-bind="config"></div>
    </section>
</template>



<script>
    export default {
        name: 'TipboxPreview',
        props: {
            config: {
                required: true,
                type: Object,
                validator(value) {
                    return value['data-bb-space-id'];
                },
            },
        },
        data() {
            return {
                tipboxScriptUrl: process.env.VUE_APP_WIDGET_SCRIPT_URL || '',
            };
        },
        watch: {
            config() {
                this.$nextTick(this.reloadTipbox);
            },
        },
        methods: {
            destroyTipbox() {
                window.bb?.destroyWidgets();
                document.getElementById('buybox-tipbox-tipbox-preview')?.__vue__?.$destroy(); // eslint-disable-line no-underscore-dangle
                document.getElementById('tipbox-object').querySelectorAll('script')?.forEach(scriptTag => scriptTag.remove()); // eslint-disable-line prettier-vue/prettier
                document.getElementById('tipbox-preview').innerHTML = '';
            },
            createTipboxScript() {
                const tipboxScript = document.createElement('script');
                tipboxScript.setAttribute('src', this.tipboxScriptUrl);
                document.getElementById('tipbox-object').appendChild(tipboxScript);
            },
            initTipbox() {
                this.destroyTipbox();
                this.createTipboxScript();
            },
            reloadTipbox() {
                window.bb?.forceReload();
            },
        },
        beforeDestroy() {
            this.destroyTipbox();
        },
        mounted() {
            this.initTipbox();
        },
    };
</script>
