import Vue from 'vue';
import jwtDecode from 'jwt-decode';
import { i18n, getLanguageFromLocale } from '@/i18n/i18n';
import store from '../store/store';
import apiService from './apiService';
import errorService from './errorService';
import Storage from '../Storage';

export default {
    refreshTokenTimer: -1,

    async authenticate(username, password) {
        try {
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/login_check`, { username, password }, { withAuth: false });
            const { token, refresh_token } = response.data; // eslint-disable-line camelcase
            window.localStorage.setItem(Storage.ACCESS_TOKEN, token);
            window.localStorage.setItem(Storage.REFRESH_TOKEN, refresh_token);
            window.localStorage.removeItem(Storage.IS_ADMIN);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async register(type, firstName, email, password, utmSource, notifications = {}, recommenderId) {
        try {
            const data = {
                profile: {
                    firstName,
                    language: getLanguageFromLocale(i18n.locale),
                },
                user: {
                    email,
                    password,
                    utmSource,
                },
                userComKey: Vue.$cookies.get('__ca__chat'),
                recommenderId,
                ...notifications,
            };
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/register/${type}`, data, { withAuth: false });
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async authenticateAs(loginToken, isAdmin) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/users/login-as/${loginToken}`, null, { withAuth: false }, null, false);
            const { token, refresh_token } = response.data; // eslint-disable-line camelcase
            window.localStorage.setItem(Storage.ACCESS_TOKEN, token);
            window.localStorage.setItem(Storage.REFRESH_TOKEN, refresh_token);
            if (isAdmin) {
                window.localStorage.setItem(Storage.IS_ADMIN, 'true');
            } else {
                window.localStorage.removeItem(Storage.IS_ADMIN);
            }
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async refreshToken() {
        try {
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/token/refresh`, { refresh_token: window.localStorage.getItem(Storage.REFRESH_TOKEN) }, { withAuth: false });
            const { token, refresh_token } = response.data; // eslint-disable-line camelcase
            window.localStorage.setItem(Storage.ACCESS_TOKEN, token);
            window.localStorage.setItem(Storage.REFRESH_TOKEN, refresh_token);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            if (formattedError.statusCode === 401) {
                window.localStorage.removeItem(Storage.REFRESH_TOKEN);
            }
            throw new Error(formattedError.message);
        }
    },

    logout() {
        this.cleanStorage();
        this.cancelScheduledRefreshToken();
    },

    cleanStorage() {
        window.localStorage.removeItem(Storage.ACCESS_TOKEN);
        window.localStorage.removeItem(Storage.REFRESH_TOKEN);
        window.localStorage.removeItem(Storage.IS_ADMIN);
        window.localStorage.removeItem(Storage.SPACE_ID);
    },

    parseToken(token) {
        try {
            const decoded = jwtDecode(token);
            const { exp, id, username, roles, language } = decoded;
            const expTime = exp * 1000; // convert to milliseconds

            return {
                exp: expTime,
                id,
                username,
                roles,
                language,
            };
        } catch (e) {
            return null;
        }
    },

    async validateToken(token) {
        try {
            let tokenData = this.parseToken(token);

            if (tokenData) {
                const { exp } = tokenData;
                const currentTime = new Date().getTime();

                if (exp > currentTime) {
                    this.scheduleRefreshToken(exp);
                } else {
                    tokenData = await store.dispatch('auth/refreshToken');
                }

                return tokenData;
            }

            return null;
        } catch (e) {
            return null;
        }
    },

    cancelScheduledRefreshToken() {
        if (this.refreshTokenTimer) {
            window.clearTimeout(this.refreshTokenTimer);
        }
    },

    scheduleRefreshToken(exp) {
        const currentTime = new Date().getTime();

        // cancel existing refresh timer
        this.cancelScheduledRefreshToken();

        if (!exp || exp <= currentTime) {
            return;
        }

        // refresh access token 3min before it expires
        const refreshTime = exp - currentTime - 180000;

        this.refreshTokenTimer = window.setTimeout(() => {
            store.dispatch('auth/refreshToken');
        }, refreshTime);
    },

    async fetchUserLight() {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/users/myself`, null, null, null, false);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchUserAccount(userId, data) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/users/${userId}`.replace(/\/$/, ''), data, null, null, false);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchUserNotificationSettings(userId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/users/${userId}/notification-settings`, null, null, null, false);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async fetchUserProfile(userId) {
        try {
            const response = await apiService.get(`${process.env.VUE_APP_API_URL}/api/v1/users/${userId}/profile`, null, null, null, false);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async updatePersonalSettings(userId, data) {
        try {
            const response = await apiService.patch(`${process.env.VUE_APP_API_URL}/api/v1/users/${userId}`, data);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async updateAddressSettings(userId, data) {
        try {
            const response = await apiService.patch(`${process.env.VUE_APP_API_URL}/api/v1/users/${userId}/profile`, data);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async changePassword(data) {
        try {
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/users/password`, data);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async resetPassword(data) {
        try {
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/users/send-reset-password-email`, data, { withAuth: false });
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async resetPasswordComplete(data) {
        try {
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/users/reset-password`, data);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async acceptTerms() {
        try {
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/users/policies`);
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            throw new Error(formattedError.message);
        }
    },

    async confirmEmail(token) {
        try {
            const response = await apiService.post(`${process.env.VUE_APP_API_URL}/api/v1/users/confirm-email`, { token }, { withAuth: false });
            return response?.data;
        } catch (e) {
            const formattedError = errorService.getFormattedError(e);
            const message = formattedError.statusCode === 404 ? i18n.t('confirmEmail.invalidToken') : formattedError.message;
            throw new Error(message);
        }
    },

    checkIfUserAllowed(user) {
        const allowedUserIds = (process.env.VUE_APP_ALLOWED_USERS || '').replace(/ /g, '').split(',').filter(Boolean).map(Number);

        if (!allowedUserIds.length) {
            return;
        }

        if (allowedUserIds.includes(user.id)) {
            return;
        }

        throw new Error(i18n.t('error.unauthorized'));
    },
};
