<template>
    <DataTable
        :header="$t('pagesList')"
        :fields="fields"
        :error="parentComponent.error"
        :items="parentComponent.items"
        :total-rows="parentComponent.requestData.max"
        :current-page.sync="parentComponent.tableParams.page"
        :per-page.sync="parentComponent.tableParams.perPage"
        :sort-by.sync="parentComponent.tableParams.sort"
        :sort-direction.sync="parentComponent.tableParams.order"
        :loading="parentComponent.loading"
        no-sort-reset
        no-local-sorting
    >
        <template #cell(url)="data">
            <b-link :href="data.item.url" target="_blank">
                {{ data.item.url }}
            </b-link>
        </template>

        <template #cell(categories)="data">
            <ul class="m-0 p-0">
                <li v-for="category in data.value" :key="category.id">
                    {{ category.name }}
                </li>
            </ul>
        </template>

        <template #cell(type)="data">
            {{ translateSpaceTypes(data.value) }}
        </template>

        <template #cell(status)="data">
            <CampaignSpaceStatusBadge :status="data.value" />
        </template>

        <template #cell(user)="data">
            <div>{{ data.item.publisher.name }}</div>
            <div>{{ data.item.publisher.company }}</div>
            <div>{{ roleTranslate(data.item.publisher.role) }}</div>
        </template>

        <template #cell(action)="data">
            <ButtonIcon size="sm" :to="createItemUrl(data.item)">
                {{ $t('show') }}
            </ButtonIcon>
        </template>
    </DataTable>
</template>



<script>
    import { roleTranslate } from '@/Role';
    import DataTable from '@/components/common/DataTable.vue';
    import CampaignSpaceStatusBadge from '@/components/common/CampaignSpaceStatusBadge.vue';

    export default {
        name: 'Results',
        components: {
            DataTable,
            CampaignSpaceStatusBadge,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {};
        },
        computed: {
            fields() {
                return [
                    {
                        key: 'id',
                        label: 'ID',
                        sortable: true,
                    },
                    {
                        key: 'name',
                        label: this.$t('space'),
                        sortable: true,
                    },
                    {
                        key: 'url',
                        label: this.$t('url'),
                        sortable: true,
                    },
                    {
                        key: 'categories',
                        label: this.$t('category'),
                    },
                    {
                        key: 'type',
                        label: this.$t('type'),
                    },
                    {
                        key: 'user',
                        label: this.$t('user'),
                    },
                    {
                        key: 'status',
                        label: this.$t('status'),
                    },
                    {
                        key: 'action',
                        label: '',
                    },
                ];
            },
            spaceTypes() {
                return this.$store.state.space.spaceTypes;
            },
        },
        watch: {
            fields: {
                immediate: true,
                handler() {
                    this.parentComponent.fields = this.fields;
                },
            },
        },
        methods: {
            roleTranslate,
            translateSpaceTypes(spaceId) {
                return this.spaceTypes?.[spaceId]?.label;
            },
            createItemUrl(item) {
                return {
                    name: 'WebpagesDetails',
                    params: { id: item.id, parentUrl: this.$route.fullPath },
                    query: {},
                };
            },
        },
        created() {
            this.$store.dispatch('space/fetchSpaceTypes');
        },
    };
</script>
