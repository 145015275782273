<template>
    <div>
        <PageHeader>{{ $t('generator.title2') }}</PageHeader>

        <div class="row">
            <div class="col-12">
                <div class="mb-4">
                    <p class="mb-1">
                        {{ $t('widgeterGeneratorSubheaderText') }}
                    </p>
                    <b-button-group>
                        <b-button
                            href="https://docs.getbuybox.com/generator-widzetu"
                            target="_blank"
                            variant="link"
                            class="d-flex align-items-center p-0 mr-4"
                        >
                            <feather type="file-text" class="mr-2" /> {{ $t('seeHowToUseTheGenerator') }}
                        </b-button>
                        <b-button
                            href="https://getbuybox.com/wygeneruj-widzet-produktowy-buy-box-i-wstaw-na-swoja-strone-www/"
                            target="_blank"
                            variant="link"
                            class="d-flex align-items-center p-0 mr-4"
                        >
                            <feather type="youtube" class="mr-2" /> {{ $t('watchVideo') }}
                        </b-button>
                    </b-button-group>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <card :header="$t('generator.cardProduct')" :sub-header="$t('generator.cardProductDescription')" :state="getCardState(1)">
                    <div class="card-inner">
                        <ProductSearch v-model="product" class="product-search" @input="setProduct" />
                    </div>
                </card>

                <card :header="$t('generator.cardSpace')" :sub-header="(currentStep >= 2) ? $t('generator.cardSpaceDescription') : ''" :state="getCardState(2)" :loading="sortTypesLoading">
                    <div class="d-flex gap-3">
                        <div class="card-inner d-flex gap-2 flex-grow-1">
                            <div class="flex-grow-1">
                                <SpaceProvider class="shadow-none">
                                    <select-field v-model="space" :options="spaceOptions" :clearable="false" />
                                </SpaceProvider>
                                <BuyboxSearch
                                    v-show="space && product"
                                    ref="buyboxSearch"
                                    v-model="buyboxId"
                                    :space-id="space || 0"
                                    :product-id="product && product.id || 0"
                                    @loading="loading => buyboxLoading = loading"
                                />
                            </div>
                        </div>
                        <div v-if="canShowWidgetSettingsButton" class="d-flex justify-content-end flex-column mb-3">
                            <b-button :to="{ name: 'WidgetSettings' }" variant="link" class="">
                                {{ $t('widgetSettings.addConfiguration') }}
                            </b-button>
                        </div>
                    </div>
                </card>

                <b-button v-if="!cardAppearanceShow" variant="link" class="mb-3" @click="cardAppearanceShow = true">
                    {{ $t('widgetSettings.showCardAppearanceBtn') }}
                </b-button>

                <card v-show="cardAppearanceShow" :header="$t('generator.cardAppearance')" :sub-header="(currentStep >= 3) ? $t('generator.cardAppearanceDescription') : ''" :state="getCardState(3)" :loading="pluginsLoading || buyboxLoading">
                    <div class="card-inner">
                        <select-field
                            v-model="widgetType"
                            :label="$t('generator.widgetType')"
                            :options="widgetTypeOptions"
                            row
                        />
                        <select-field
                            v-model="language"
                            :label="$t('generator.language')"
                            :options="languageOptions"
                            row
                        />
                        <text-field
                            v-model="productName"
                            :label="$t('generator.productName')"
                            :placeholder="$t('textLinks.optional')"
                            row
                        />
                        <text-field
                            v-model="productInfo"
                            :label="$t('generator.productInfo')"
                            :placeholder="$t('textLinks.optional')"
                            row
                        />
                        <color-field
                            v-model="leadColor"
                            :label="$t('generator.leadColor')"
                            :validation="$v.leadColor"
                            :placeholder="$t('textLinks.optional')"
                            row
                        />
                        <text-field
                            v-model="buttonLabel"
                            :label="$t('generator.buttonLabel')"
                            :maxlength="buttonLabelMaxLength"
                            :placeholder="$t('textLinks.optional')"
                            row
                        />
                        <select-field
                            v-model="shopStyle"
                            :label="$t('generator.shopStyle')"
                            :options="shopStyleOptions"
                            :placeholder="$t('textLinks.optional')"
                            row
                        />
                        <select-field
                            v-if="sortTypeOptions.length > 0"
                            v-model="sortType"
                            :label="$t('generator.sortType')"
                            :options="sortTypeOptions"
                            row
                        />
                        <text-field
                            v-model="rowCount"
                            :label="$t('generator.rowCount')"
                            type="number"
                            :placeholder="$t('textLinks.optional')"
                            row
                        />
                        <checkbox-field v-model="showProduct" variant="switch" :label="$t('generator.showProduct')" />
                        <checkbox-field v-model="showPrices" variant="switch" :label="$t('generator.showPrices')" />
                    </div>
                </card>

                <card :header="$t('generator.cardCode')" :sub-header="(currentStep >= 4) ? $t('generator.cardCodeDescription') : ''" :state="getCardState(4)">
                    <div class="card-inner">
                        <select-field
                            v-if="widgetType === 'popover' || widgetType === 'overlay'"
                            v-model="htmlTag"
                            :label="$t('generator.htmlTag')"
                            :options="htmlTagOptions"
                            row
                        />

                        <readonly-textarea :value="widgetCode" :auto-resize="true" class="mt-2" />

                        <text-field
                            v-model="abpar1"
                            class="mt-4"
                            :label="$t('textLinks.abpar', { number: 1 })"
                            :placeholder="$t('textLinks.optional')"
                            row
                        />
                        <text-field
                            v-model="abpar2"
                            :label="$t('textLinks.abpar', { number: 2 })"
                            :placeholder="$t('textLinks.optional')"
                            row
                        />
                        <text-field
                            v-model="abpar3"
                            :label="$t('textLinks.abpar', { number: 3 })"
                            :placeholder="$t('textLinks.optional')"
                            row
                        />

                        <button-default
                            class="mt-4"
                            variant="success"
                            icon="copy"
                            @click="copyWidgetCode()"
                        >
                            {{ $t('generator.copyCode') }}
                        </button-default>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import Card from '@/components/common/Card.vue';
    import PageHeader from '@/components/common/PageHeader.vue';
    import SelectField from '@/components/common/SelectField.vue';
    import SpaceProvider from '@/components/common/SpaceProvider.vue';
    import CheckboxField from '@/components/common/CheckboxField.vue';
    import ButtonDefault from '@/components/common/ButtonDefault.vue';
    import ReadonlyTextarea from '@/components/common/ReadonlyTextarea.vue';
    import TextField from '@/components/common/TextField.vue';
    import toastService from '@/services/toastService';
    import { generateId, isEmpty } from '@/utils/util';
    import validationService from '@/services/validationService';
    import ColorField from '@/components/common/ColorField.vue';
    import { getLanguageOptions, i18n } from '@/i18n/i18n';
    import ProductSearch from '@/components/common/ProductSearch.vue';
    import BuyboxSearch from '@/components/common/BuyboxSearch.vue';
    import { SortTypeToWidgetSort } from '@/services/pluginService';

    const BUTTON_LABEL_MAX_LENGTH = 15;

    export default {
        name: 'WidgetGenerator',
        components: {
            BuyboxSearch,
            ColorField,
            TextField,
            ReadonlyTextarea,
            ButtonDefault,
            CheckboxField,
            SpaceProvider,
            ProductSearch,
            SelectField,
            PageHeader,
            Card,
        },
        validations: {
            leadColor: {
                hexColor: validationService.hexColor,
            },
        },
        data() {
            return {
                widgetScriptUrl: process.env.VUE_APP_WIDGET_SCRIPT_URL,
                buttonLabelMaxLength: BUTTON_LABEL_MAX_LENGTH,
                product: undefined,
                space: undefined,
                buyboxId: undefined,
                buybox: undefined,
                widgetId: generateId(),
                widgetType: 'widget',
                widgetTypeOptions: [
                    {
                        value: 'widget',
                        text: i18n.t('widgetType.widget'),
                    },
                    {
                        value: 'popover',
                        text: i18n.t('widgetType.popover'),
                    },
                    {
                        value: 'overlay',
                        text: i18n.t('widgetType.overlay'),
                    },
                    {
                        value: 'logos-only',
                        text: i18n.t('widgetType.logosOnly'),
                    },
                ],
                languageOptions: getLanguageOptions(true),
                language: undefined,
                productInfo: '',
                productName: '',
                shopStyle: undefined,
                sortType: undefined,
                leadColor: undefined,
                buttonLabel: undefined,
                rowCount: undefined,
                showProduct: false,
                showPrices: false,
                abpar1: '',
                abpar2: '',
                abpar3: '',
                htmlTag: 'span',
                widgetPreviewData: {},
                buyboxLoading: false,
                cardAppearanceShow: false,
            };
        },
        computed: {
            canShowWidgetSettingsButton() {
                return this.space && !this.pluginsLoading && !this.buyboxLoading && this.$refs.buyboxSearch?.buyboxesRequest.result?.length;
            },
            currentStep() {
                if (this.product && this.space && !(this.pluginsLoading || this.buyboxLoading)) return 4;
                if (this.product && this.space && !this.pluginsLoading) return 3;
                if (this.product) return 2;
                return 1;
            },
            previewSubheader() {
                const noOffers = this.space && this.product && !this.buyboxId && !this.$refs.buyboxSearch?.buyboxesRequest.loading;
                const subheader = noOffers ? this.$t('generator.previewSubheaderNoOffers') : this.$t('generator.previewSubheader');
                return subheader;
            },
            sortTypeOptions() {
                if (this.sortTypesIds) {
                    return this.sortTypesIds.map(sortTypeId => ({
                        value: this.sortTypes[sortTypeId].value,
                        text: this.sortTypes[sortTypeId].label,
                    }));
                }
                return [];
            },
            bbType() {
                return this.widgetType === 'widget' ? undefined : this.widgetType;
            },
            bbPopoverEvent() {
                return this.widgetType === 'popover' ? 'click' : undefined;
            },
            bbLanguage() {
                return !isEmpty(this.language) && this.buybox?.language !== this.language ? this.language : undefined;
            },
            bbProductName() {
                return !isEmpty(this.productName) ? this.productName : undefined;
            },
            bbProductInfo() {
                return !isEmpty(this.productInfo) ? this.productInfo : undefined;
            },
            bbShopStyle() {
                return this.shopStyle !== this.buybox?.shopStyle ? this.shopStyle : undefined;
            },
            bbSort() {
                if (this.sortType === this.buybox?.sortType) {
                    return undefined;
                }
                return SortTypeToWidgetSort[this.sortType];
            },
            bbLeadColor() {
                if (this.$v.leadColor && this.$v.leadColor.$error) {
                    return undefined;
                }
                return !isEmpty(this.leadColor) && this.buybox?.leadColor !== this.leadColor ? this.leadColor : undefined;
            },
            bbButtonLabel() {
                if (this.$v.buttonLabel && this.$v.buttonLabel.$error) {
                    return undefined;
                }
                return !isEmpty(this.buttonLabel) && this.buybox?.buttonLabel !== this.buttonLabel ? this.buttonLabel : undefined;
            },
            bbRowCount() {
                return !isEmpty(this.rowCount) && this.buybox?.rowCount !== parseInt(this.rowCount, 10) ? this.rowCount : undefined;
            },
            bbShowProduct() {
                return this.showProduct !== this.buybox?.showProduct ? this.showProduct : undefined;
            },
            bbShowPrices() {
                return this.showPrices !== this.buybox?.showPrices ? this.showPrices : undefined;
            },
            bbAbpar1() {
                return !isEmpty(this.abpar1) ? this.abpar1 : undefined;
            },
            bbAbpar2() {
                return !isEmpty(this.abpar2) ? this.abpar2 : undefined;
            },
            bbAbpar3() {
                return !isEmpty(this.abpar3) ? this.abpar3 : undefined;
            },
            widgetCode() {
                if (!this.product) {
                    return undefined;
                }
                const attrs = {
                    class: 'bb-widget',
                    id: `buybox-${this.widgetId}`,
                    'data-bb-oid': this.product.offerId,
                };
                if (!this.buyboxId) {
                    attrs['bb-space-id'] = this.space;
                } else {
                    attrs['data-bb-id'] = this.buyboxId;
                }
                if (!isEmpty(this.bbType)) {
                    attrs['data-bb-type'] = this.bbType;
                }
                if (!isEmpty(this.bbPopoverEvent)) {
                    attrs['data-bb-popover-event'] = this.bbPopoverEvent;
                }
                if (!isEmpty(this.bbLanguage)) {
                    attrs['data-bb-language'] = this.bbLanguage;
                }
                if (!isEmpty(this.bbShopStyle)) {
                    attrs['data-bb-shop-style'] = this.bbShopStyle;
                }
                if (!isEmpty(this.bbSort)) {
                    attrs['data-bb-sort'] = this.bbSort;
                }
                if (!isEmpty(this.bbLeadColor)) {
                    attrs['data-bb-lead-color'] = this.bbLeadColor;
                }
                if (!isEmpty(this.bbButtonLabel)) {
                    attrs['data-bb-button-label'] = this.bbButtonLabel;
                }
                if (!isEmpty(this.bbRowCount)) {
                    attrs['data-bb-row-count'] = this.bbRowCount;
                }
                if (!isEmpty(this.bbShowProduct)) {
                    attrs['data-bb-show-extra'] = this.bbShowProduct;
                }
                if (!isEmpty(this.bbShowPrices)) {
                    attrs['data-bb-show-prices'] = this.bbShowPrices;
                }
                if (!isEmpty(this.bbProductName)) {
                    attrs['data-bb-name'] = this.bbProductName;
                }
                if (!isEmpty(this.bbProductInfo)) {
                    attrs['data-bb-info'] = this.bbProductInfo;
                }
                if (!isEmpty(this.bbAbpar1)) {
                    attrs['data-bb-p1'] = this.bbAbpar1;
                }
                if (!isEmpty(this.bbAbpar2)) {
                    attrs['data-bb-p2'] = this.bbAbpar2;
                }
                if (!isEmpty(this.bbAbpar3)) {
                    attrs['data-bb-p3'] = this.bbAbpar3;
                }

                const attrsStr = Object.keys(attrs).reduce((str, name) => `${str} ${name}="${attrs[name]}"`, '');
                const triggerBtn = ['popover', 'overlay'].includes(this.bbType) ? `<span style="text-decoration: underline; color: blue; cursor: pointer;">${this.$t('widgetPreview.show')}</span>` : '';

                // eslint-disable-next-line no-useless-escape
                return `<script src="${this.widgetScriptUrl}"><\/script>
<${this.htmlTag}${attrsStr}>${triggerBtn}</${this.htmlTag}>`;
            },
            ...mapState({
                spaces: state => state.space.spaces,
                plugins: state => state.plugin.plugins,
                pluginsLoading: state => state.plugin.loading.fetchPlugins,
                sortTypes: state => state.plugin.sortTypes,
                sortTypesIds: state => state.plugin.sortTypesIds,
                sortTypesLoading: state => state.plugin.loading.fetchSortTypes,
            }),
            ...mapGetters({
                spaceOptions: 'space/spaceOptions',
                shopStyleOptions: 'options/shopStyleOptions',
            }),
            htmlTagOptions() {
                return [
                    { value: 'div', text: 'div' },
                    { value: 'span', text: 'span' },
                    { value: 'a', text: 'a' },
                    { value: 'button', text: 'button' },
                ];
            },
            showWidgetPreview() {
                return !!this.product;
            },
        },
        watch: {
            product() {
                this.widgetId = generateId();
            },
            buyboxId(id) {
                if (id) {
                    this.onBuyboxIdSet();
                } else {
                    this.buybox = null;
                }
                this.initWidgetPreviewData();
            },
            widgetType: {
                handler: 'initWidgetPreviewData',
            },
            leadColor: {
                handler: 'initWidgetPreviewData',
            },
            buttonLabel: {
                handler: 'initWidgetPreviewData',
            },
            showProduct: {
                handler: 'initWidgetPreviewData',
            },
            showPrices: {
                handler: 'initWidgetPreviewData',
            },
            rowCount: {
                handler: 'initWidgetPreviewData',
            },
            productInfo: {
                handler: 'initWidgetPreviewData',
            },
            widgetPreviewData: {
                handler: 'saveWidgetPreviewData',
            },
        },
        methods: {
            setProduct() {
                this.space = undefined;
                this.buyboxId = undefined;
                this.widgetId = generateId();
                this.initWidgetPreviewData();
            },
            initWidgetPreviewData() {
                this.widgetPreviewData = {
                    ...this.widgetPreviewData,
                    'bb-id': this.buyboxId || undefined,
                    'bb-offer-id': this.product?.offerId,
                    'bb-type': this.widgetType || 'widget',
                    'bb-lead-color': this.leadColor,
                    'bb-button-label': this.buttonLabel,
                    'bb-show-extra': this.showProduct ?? true,
                    'bb-show-prices': this.showPrices ?? true,
                    'bb-row-count': Number.parseInt(this.rowCount) ?? 10,
                    'bb-info': this.productInfo,
                    product: this.product,
                    space: this.space,
                };
            },
            getCardState(cardNo) {
                if (cardNo > this.currentStep) {
                    return 'disabled';
                }
                if (cardNo < this.currentStep) {
                    return 'completed';
                }
                return 'default';
            },
            async onBuyboxIdSet() {
                await this.fetchPlugins({ spaceId: this.space });
                this.buybox = this.plugins[this.buyboxId] || {};
                this.setBuyboxFields();
            },
            setBuyboxFields() {
                this.widgetType = 'widget';
                this.language = this.buybox?.language ?? undefined;
                this.productInfo = '';
                this.productName = '';
                this.shopStyle = this.buybox?.shopStyle ?? undefined;
                this.sortType = this.buybox?.sortType ?? this.sortTypesIds?.[0];
                this.leadColor = this.buybox?.leadColor ?? undefined;
                this.buttonLabel = this.buybox?.buttonLabel ?? undefined;
                this.rowCount = this.buybox?.rowCount ?? undefined;
                this.showProduct = this.buybox?.showProduct ?? false;
                this.showPrices = this.buybox?.showPrices ?? false;
                this.abpar1 = '';
                this.abpar2 = '';
                this.abpar3 = '';
            },
            async copyWidgetCode() {
                if (this.widgetCode) {
                    await this.$copyText(this.widgetCode);
                    toastService.successToast(this.$t('generator.copied'));
                }
            },
            saveWidgetPreviewData() {
                const wpData = this.$localStorage.get('widget-preview', null);
                if (JSON.stringify(this.widgetPreviewData) !== JSON.stringify(wpData)) this.$localStorage.set('widget-preview', this.widgetPreviewData);
            },
            removeWidgetPreviewData() {
                this.$localStorage.remove('widget-preview');
            },
            onStorageUpdate(event) {
                if (event.key === 'buybox-widget-preview' && JSON.stringify(this.widgetPreviewData) !== event.newValue) {
                    this.updateData();
                }
            },
            updateData() {
                const wpData = this.$localStorage.get('widget-preview', null);
                if (wpData) {
                    this.widgetPreviewData = { ...this.widgetPreviewData, ...wpData };
                    this.productInfo = wpData['bb-info'];
                    this.widgetType = wpData['bb-type'];
                    this.showProduct = wpData['bb-show-extra'];
                    this.showPrices = wpData['bb-show-prices'];
                    this.leadColor = wpData['bb-lead-color'];
                    this.buttonLabel = wpData['bb-button-label'];
                    this.rowCount = wpData['bb-row-count'];
                    this.buyboxId = wpData['bb-id'] || undefined;
                    this.product = wpData.product || undefined;
                    this.space = wpData.space || null;
                }
            },
            openWidgetPreview() {
                const url = this.$router.resolve({ name: 'WidgetPreview' }).href;
                window.open(url, 'widget-preview');
            },
            ...mapActions({
                fetchPlugins: 'plugin/fetchPlugins',
                fetchSortTypes: 'plugin/fetchSortTypes',
            }),
        },
        created() {
            this.updateData();
        },
        mounted() {
            this.fetchSortTypes();
            this.$v.$touch();
            if (this.$route.query.product_tour_id) this.startTour();
            window.addEventListener('storage', this.onStorageUpdate);
        },
        beforeDestroy() {
            this.removeWidgetPreviewData();
            window.removeEventListener('storage', this.onStorageUpdate);
        },
    };
</script>

<style lang="scss" scoped>
    @import 'bootstrap/scss/functions';
    @import 'bootstrap/scss/variables';
    @import '@/design/_variables.scss';
    .widget-preview {
        position: sticky;
        top: $topbar-height + $grid-gutter-width;
        z-index: 2;
    }

    .product-search {
        display: flex;
        flex-direction: column-reverse;

        ::v-deep {
            .product-box {
                display: flex;
                align-items: flex-start;
            }
        }

        &.touched {
            ::v-deep {
                .selected-product {
                    display: none;
                }
            }
        }
    }

    .card-inner {
        max-width: 768px;
    }
</style>
