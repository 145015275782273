<template>
    <b-modal
        :id="`modal-form-${modalId}`"
        ref="modal"
        :title="modalTitle"
        size="lg"
        cancel-variant="light"
        ok-variant="primary"
        :cancel-title="$t('cancel')"
        :ok-title="$t('save')"
        :busy="submitting"
        static
        @ok.prevent="handleSubmit()"
        @show="resetData()"
        @hidden="resetData()"
    >
        <b-overlay :show="submitting" opacity="0" class="h-100">
            <b-form @submit.prevent>
                <FormValidator :validator="$v.form.name" form-group v-slot="slotProps" :label="$t('name')">
                    <b-form-input v-model="form.name" :placeholder="$t('name')" :state="slotProps.state" :disabled="submitting" />
                </FormValidator>
                <FormValidator :validator="$v.form.author" form-group v-slot="slotProps" :label="$t('author')">
                    <b-form-input v-model="form.author" :placeholder="$t('author')" :state="slotProps.state" :disabled="submitting" />
                </FormValidator>
                <FormValidator :validator="$v.form.description" form-group v-slot="slotProps" :label="$t('description')">
                    <b-form-textarea v-model="form.description" :placeholder="$t('description')" :state="slotProps.state" :disabled="submitting" />
                </FormValidator>
                <b-form-group :label="$t('source')">
                    <b-form-input v-model="form.source" :placeholder="$t('source')" :disabled="submitting" />
                </b-form-group>
                <b-form-group :label="$t('brand')">
                    <Multiselect
                        ref="formBrands"
                        v-model="form.brand"
                        :fetch-options-function="fetchBrands"
                        :parse-options-function="parseBrands"
                        :placeholder="$t('selectField.placeholder')"
                        :asynchronous="true"
                        :searchable="true"
                        :show-selected="true"
                        :emit-initial-value="true"
                        track-by="id"
                        label="name"
                        select-label=""
                        :disabled="submitting"
                        @loading="value => isBrandsLoading = value"
                        @search-change="value => brandsSearchText = value"
                    />
                </b-form-group>
                <b-form-group :label="$t('categories')">
                    <Multiselect
                        ref="formCategories"
                        v-model="form.categories"
                        :fetch-options-function="fetchCategories"
                        :parse-options-function="parseCategories"
                        :placeholder="$t('selectField.placeholder')"
                        :show-checkboxes="true"
                        :multiple="true"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :show-selected="true"
                        :asynchronous="true"
                        :searchable="true"
                        track-by="id"
                        label="name"
                        select-label=""
                        :disabled="submitting"
                        @loading="value => isCategoriesLoading = value"
                        @search-change="value => categoriesSearchText = value"
                    />
                </b-form-group>
            </b-form>
        </b-overlay>
    </b-modal>
</template>



<script>
    import { required } from 'vuelidate/lib/validators';
    import ModalFormMixin from '@/mixins/modalFormMixin';
    import FormValidator from '@/components/common/FormValidator.vue';
    import catalogService from '@/services/catalogService';
    import Multiselect from '@/components/common/Multiselect.vue';

    export default {
        name: 'ModalForm',
        mixins: [ModalFormMixin],
        components: {
            FormValidator,
            Multiselect,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                modalId: 'catalog-products',
                createAction: 'catalog/postProducts',
                updateAction: 'catalog/patchProducts',
                form: {
                    name: '',
                    author: '',
                    description: '',
                    source: '',
                    brand: null,
                    categories: null,
                },
                isBrandsLoading: false,
                brandsSearchText: '',
                isCategoriesLoading: false,
                categoriesSearchText: '',
            };
        },
        validations() {
            return {
                form: {
                    name: { required },
                    author: { required },
                    description: { required },
                },
            };
        },
        watch: {
            item() {
                if (this.item?.brand) this.form.brand = this.item.brand;
                if (this.item?.categories) this.form.categories = this.item.categories;
            },
        },
        methods: {
            getDataStructure(data) {
                return {
                    ...data,
                    brand: data.brand?.id,
                    categories: data.categories?.map(item => item.id),
                };
            },
            fetchBrands() {
                const requestParams = { name: this.brandsSearchText };
                return catalogService.getBrands({ requestParams });
            },
            parseBrands(data) {
                return data?.items || [];
            },
            fetchCategories() {
                const requestParams = { name: this.categoriesSearchText };
                return catalogService.getCategories({ requestParams });
            },
            parseCategories(data) {
                return data?.items || [];
            },
            refreshData() {
                this.parentComponent.fetchData();
            },
        },
    };
</script>
