<template>
    <VueApexcharts ref="chart" v-bind="$attrs" :options="merge({}, chartOptions)" />
</template>



<script>
    import VueApexcharts from 'vue-apexcharts';
    import merge from 'lodash/merge';
    import pl from 'apexcharts/dist/locales/pl.json';
    import en from 'apexcharts/dist/locales/en.json';
    import { stringToColour } from '@/utils/util';
    import { i18n, currentLocale, getLanguageFromLocale } from '@/i18n/i18n';

    const currentLang = getLanguageFromLocale(currentLocale);
    const chartLocales = [pl, en];

    const DATE_TIME_FORMAT = {
        hour: 'HH:mm',
        day: 'dd MMM yyyy',
        week: `w '${i18n.t('week').toLowerCase()}' yyyy`,
        month: 'MMM yyyy',
        quarter: 'QQQ yyyy',
        year: 'yyyy',
    };

    const RANGES = ['hour', 'day', 'week', 'month', 'quarter', 'year'];

    export default {
        name: 'Chart',
        components: {
            VueApexcharts,
        },
        props: {
            options: {
                type: Object,
                default: () => ({}),
            },
            loading: {
                type: Boolean,
                default: false,
            },
            range: {
                type: String,
                default: 'day',
                validator: value => RANGES.includes(value),
            },
        },
        data() {
            const baseChartOptions = {
                colors: ['#2e93fa', '#546e7a', '#d4526e', '#13d8aa', '#a5978b', '#4ecdc4', '#c7f464', '#81d4fa', '#546e7a', '#fd6a6a'],
                noData: {
                    text: i18n.t('noData'),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: '#ccc',
                        fontSize: '30px',
                        fontFamily: undefined,
                    },
                },
                chart: {
                    type: 'area',
                    width: '100%',
                    toolbar: {
                        show: false,
                    },
                    animations: {
                        speed: 500,
                        animateGradually: {
                            delay: 0,
                        },
                        dynamicAnimation: {
                            speed: 500,
                        },
                    },
                    events: {
                        click: (event, chartContext, config) => {
                            this.$emit('click', { event, chartContext, config }); // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                        },
                    },
                },
                markers: {
                    size: 6,
                },
                zoom: {
                    enabled: false,
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    type: 'category',
                    tickAmount: 'dataPoints',
                    tooltip: {
                        enabled: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                    labels: {
                        datetimeUTC: false,
                        formatter: (value, timestamp, opts) => this.$format.date(Number.parseInt(value) || 0, DATE_TIME_FORMAT[this.range]),
                    },
                },
                legend: {
                    height: 40,
                },
            };

            if (chartLocales?.length) baseChartOptions.chart.locales = chartLocales;
            if (currentLang) baseChartOptions.chart.defaultLocale = currentLang;

            return {
                baseChartOptions,
            };
        },
        computed: {
            chartComputedOptions() {
                const chartType = this.$attrs.type || this.options.type;
                // const strokeWidth = chartType === 'line' || chartType === 'area' ? 3 : 0;
                const options = {
                    colors: this.$attrs.series.length ? this.$attrs.series.map((item, index) => (item.name ? stringToColour(item.name) : this.baseChartOptions.colors[index])) : undefined,
                    chart: {
                        type: chartType,
                        defaultLocale: currentLang,
                    },
                    // stroke: {
                    //     width: strokeWidth,
                    // },
                };
                if (chartType === 'line') {
                    options.stroke = {
                        show: true,
                        curve: 'smooth',
                        width: 3,
                    };
                    options.fill = {
                        opacity: 0.5,
                        type: 'gradient',
                        gradient: {
                            type: 'vertical',
                            shadeIntensity: 0.5,
                            gradientToColors: undefined,
                            inverseColors: false,
                            opacityFrom: 0.3,
                            opacityTo: 0.05,
                            stops: [45, 100],
                        },
                    };
                }
                if (this.loading) {
                    options.noData = { ...this.baseChartOptions.noData, text: '' };
                }
                return options;
            },
            chartOptions() {
                return merge({}, this.baseChartOptions, this.chartComputedOptions, this.options);
            },
        },
        methods: {
            merge,
        },
    };
</script>
