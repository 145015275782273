<template>
    <div>
        <page-header>
            {{ $t('dashboard.title') }}
            <template #side>
                <ButtonIcon
                    class="mr-2"
                    icon="refresh-cw"
                    variant="link"
                    :disabled="loading"
                    :icon-animation="iconAnimation"
                    @click="reload"
                />
                <TimeRangeDropdown v-model="timeRange" :disabled="loading" :options="['today', 'yesterday', 'last7Days', 'currentWeek', 'last14Days', 'last30days', 'currentMonth', 'currentQuarter', 'currentYear']" />
            </template>
        </page-header>

        <div class="row">
            <div class="col-xl-3 col-md-6 mb-4">
                <WidgetStatChart
                    :title="$t('dashboard.impressions')"
                    :value="$format.number(impressions.impressions)"
                    :difference="$format.percent(percentDiff(impressions.impressions, impressions.impressions_compare))"
                    :data="getWidgetChartData('impressions')"
                    :loading="$store.getters['userStats/getLoading']('impressionsStats')"
                    :error="$store.getters['userStats/getError']('impressionsStats')"
                    :chart-color="chartOverview.impressions.color"
                    tooltip-formatter="round"
                />
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
                <WidgetStatChart
                    :title="$t('dashboard.clicks')"
                    :value="$format.number(clicks.clicks)"
                    :difference="$format.percent(percentDiff(clicks.clicks, clicks.clicks_compare))"
                    :data="getWidgetChartData('clicks')"
                    :loading="$store.getters['userStats/getLoading']('clicksStats')"
                    :error="$store.getters['userStats/getError']('clicksStats')"
                    :chart-color="chartOverview.clicks.color"
                    tooltip-formatter="round"
                />
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
                <WidgetStatChart
                    :title="$t('dashboard.transactions')"
                    :value="$format.number(orders.orders)"
                    :difference="$format.percent(percentDiff(orders.orders, orders.orders_compare))"
                    :data="getWidgetChartData('orders')"
                    :loading="$store.getters['userStats/getLoading']('ordersStats')"
                    :error="$store.getters['userStats/getError']('ordersStats')"
                    :chart-color="chartOverview.orders.color"
                    tooltip-formatter="round"
                />
            </div>
            <div class="col-xl-3 col-md-6 mb-4">
                <WidgetStatChart
                    :title="$t('dashboard.salesValue')"
                    :value="$format.currency(amount.amount)"
                    :difference="$format.percent(percentDiff(amount.amount, amount.amount_compare))"
                    :data="getWidgetChartData('amount')"
                    :loading="$store.getters['userStats/getLoading']('amountStats')"
                    :error="$store.getters['userStats/getError']('amountStats')"
                    :chart-color="chartOverview.amount.color"
                    tooltip-formatter="currency"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12 mb-4">
                <MultiChartCard
                    :data="chartOverview"
                    :height="276"
                    :loading="isChartOverviewLoading"
                    :options="chartOverviewOptions"
                    :title="$t('dashboard.overview')"
                    @currentChart="setCurrentChart"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12 mb-4">
                <WidgetRecentTransactions ref="widgetRecentTransactions" />
            </div>
        </div>

        <div class="row">
            <div class="col-xl-6 mb-4">
                <WidgetBestSpaces ref="widgetBestSpaces" :time-range="timeRange || {}" />
            </div>
            <div class="col-xl-6 mb-4">
                <WidgetBestProducts ref="widgetBestProducts" :time-range="timeRange || {}" />
            </div>
        </div>
    </div>
</template>

<script>
    import DashboardMixin from '@/views/common/dashboard/index.vue';

    export default {
        name: 'AdvertiserDashboard',
        mixins: [DashboardMixin],
        data() {
            return {};
        },
        computed: {
            loading() {
                return (
                    this.$store.getters['userStats/getLoading']('impressionsStats') ||
                    this.$store.getters['userStats/getLoading']('clicksStats') ||
                    this.$store.getters['userStats/getLoading']('ordersStats') ||
                    this.$store.getters['userStats/getLoading']('amountStats') ||
                    this.$store.getters['transactions/getLoading']('userTransactions') ||
                    this.$store.getters['userStats/getLoading']('spaceReport') ||
                    this.$store.getters['userStats/getLoading']('topProductsQuantity') ||
                    this.isChartOverviewLoading
                );
            },
            iconAnimation() {
                return this.loading ? 'spin' : undefined;
            },
            chartOverview() {
                return {
                    impressions: this.getChartItem(this.getChartData(this.impressions.chart, 'impressions'), this.$t('dashboard.impressions'), '#5369f8'),
                    clicks: this.getChartItem(this.getChartData(this.clicks.chart, 'clicks'), this.$t('dashboard.clicks'), '#f77e53'),
                    orders: this.getChartItem(this.getChartData(this.orders.chart, 'orders'), this.$t('dashboard.transactions'), '#43d39e'),
                    commission: this.getChartItem(this.getChartData(this.commission.chart, 'advertiser_commission'), this.$t('dashboard.commission'), '#ffbe0b'),
                    amount: this.getChartItem(this.getChartData(this.amount.chart, 'amount'), this.$t('dashboard.salesValue'), '#890070'),
                };
            },
        },
        methods: {
            fetchData(params = {}) {
                this.fetchImpressionsStats(params);
                this.fetchClicksStats(params);
                this.fetchOrdersStats(params);
                this.fetchCommissionStats(params);
                this.fetchAmountStats(params);
            },
            reload() {
                const params = { cache: false };
                this.fetchData(params);
                this.$refs.widgetRecentTransactions.fetchData(params);
                this.$refs.widgetBestSpaces.fetchData(params);
                this.$refs.widgetBestProducts.fetchData(params);
            },
        },
    };
</script>
