<template>
    <DataTable
        :header="$t('payouts')"
        :error="isRedirecting ? null : error"
        :items="items"
        :fields="fields"
        :total-rows="payouts.max"
        :per-page.sync="tableConfig.perPage"
        :sort-by.sync="tableConfig.sortBy"
        :sort-direction.sync="tableConfig.sortDirection"
        :current-page.sync="tableConfig.currentPage"
        :loading="loading"
        :busy-items="busyItems"
        no-sort-reset
        no-local-sorting
    >

        <template #cell(requestedAt)="data">
            {{ upperFirst($format.date(data.item.requestedAt, 'MMMM yyyy')) }}
        </template>

        <template #cell(amount)="data">
            {{ $format.currency(data.value) }}
        </template>

        <template #cell(status)="data">
            <TipboxStatusBadge :status="data.item.status" :label="getStatusLabel(data.item.status)" />
        </template>

    </DataTable>
</template>



<script>
    import upperFirst from 'lodash/upperFirst';
    import DataTable from '@/components/common/DataTable.vue';
    import TipboxStatusBadge from '@/components/common/TipboxStatusBadge.vue';

    export default {
        name: 'Payouts',
        components: {
            DataTable,
            TipboxStatusBadge,
        },
        inject: {
            parentComponent: {
                default: null,
            },
        },
        data() {
            return {
                tableConfig: {
                    perPage: 50,
                    currentPage: 1,
                    sortBy: 'requestedAt',
                    sortDirection: 'desc',
                },
                isRedirecting: false,
                fields: [
                    {
                        key: 'requestedAt',
                        label: this.$t('month'),
                    },
                    {
                        key: 'amount',
                        label: this.$t('amount'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                    {
                        key: 'status',
                        label: this.$t('status'),
                        tdClass: 'text-right text-nowrap',
                        thClass: 'text-right',
                    },
                ],
                busyItems: [],
            };
        },
        computed: {
            payouts() {
                return this.$store.getters['tipbox/getPayouts'];
            },
            items() {
                return this.payouts.items || [];
            },
            error() {
                return this.$store.getters['tipbox/getError']('payouts');
            },
            loading() {
                return this.$store.getters['tipbox/getLoading']('payouts');
            },
            isItemBusy() {
                return id => !!this.busyItems.find(itemId => itemId === id);
            },
            isItemsBusy() {
                return !!this.busyItems.length;
            },
            tableParams() {
                return {
                    page: this.tableConfig.currentPage,
                    perPage: this.tableConfig.perPage,
                    sort: this.tableConfig.sortBy,
                    order: this.tableConfig.sortDirection.toUpperCase(),
                };
            },
            requestParams() {
                return {
                    ...this.tableParams,
                };
            },
            statuses() {
                return this.$store.getters['tipbox/getPayoutsStatuses'] || {};
            },
        },
        watch: {
            requestParams: {
                immediate: true,
                handler: 'fetchData',
            },
            error() {
                const error = this.$store.getters['tipTransactions/getError']('transactions');
                if (error && error.message === 'api_errors.unprocessable_entity.policies_not_accepted') {
                    this.isRedirecting = true;
                    this.$router.push({ name: 'Tipbox' });
                } else {
                    this.isRedirecting = false;
                }
            },
        },
        methods: {
            upperFirst,
            fetchData() {
                this.$store.dispatch('tipbox/fetchPayouts', { requestParams: this.requestParams }).catch(error => {
                    if (error && error.message === 'api_errors.unprocessable_entity.policies_not_accepted' && !this.isRedirecting) {
                        this.isRedirecting = true;
                        this.$router.push({ name: 'Tipbox' });
                    } else {
                        this.isRedirecting = false;
                    }
                });
            },
            fetchStatuses() {
                this.$store.dispatch('tipbox/fetchPayoutsStatuses').catch(error => this.$toastr.error(this.$t(error.message), this.$t('errorOccurred')));
            },
            getStatusLabel(status) {
                return this.statuses?.find(item => item.value === status)?.label || '';
            },
        },
        created() {
            this.fetchStatuses();
        },
    };
</script>
